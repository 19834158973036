import { DndProvider } from "react-dnd";
import PageTemplate from "page/common/PageTemplate";
import React, { useContext, useEffect, useState } from "react";
import { HTML5Backend } from "react-dnd-html5-backend";
import "css/mobile/mainboard-setting.css";
import { Enums } from "components/builder/BuilderEnum";
import { Button, Col, Image, Row } from "react-bootstrap";

import mainBoardSample from "images/main_board_tmpl_1.png";
import { useDispatch, useSelector } from "react-redux";
import LayoutSettingService from "services/mobile/LayoutSettingService";
import User from "components/common/utils/UserUtils";
import {
  ObjectUtils,
  StringUtils,
} from "@alpha/com.bizentro.daaf.front.framework";
import { AppContext } from "components/common/AppContextProvider";
import { useNavigate } from "react-router-dom";
import UIReduxHelper from "components/builder/ui/editor/helper/UIReduxHelper";
import {
  updateEventOutput,
  updateEventWorkspace,
} from "components/builder/eventhandler/reducer/EventHandlerAction";
import ProgramService from "services/ui/ProgramService";
import { setMemo } from "components/builder/ui/reducers/UIBuilderAction";

const MainBoardSetting = () => {
  const breadcrum = [
    {
      name: "Main Board Setting",
      url: Enums.BuilderPath.UI.MAIN + "/" + Enums.BuilderPath.UI.LIST,
      active: true,
    },
  ];

  const LAYOUT_TYPE = "D"; //D : 메인화면 (Dash Board)

  const workspace = useSelector((state) => state.workspace);
  const [appLayoutMainBoard, setAppLayoutMainBoard] = useState({});
  const [customMainBoard, setCustomMainBoard] = useState({});
  const [mainBoardTmplList, setMainBoardTmplList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { component } = useContext(AppContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    //선택된 데이터 가져오기
    LayoutSettingService.getAppLayoutList({ appId: workspace.appId }, (res) => {
      let loginData =
        res.data.find((item) => item.layoutType === LAYOUT_TYPE) || {};
      setAppLayoutMainBoard(loginData);
    });

    //데이터 없을 경우 u_program 생성, loginCustomLayout 데이터 받아옴
    const tmpProgram = {
      appId: workspace.appId,
      moduleCd: workspace.moduleCd,
      appReleaseId: workspace.appReleaseId,
      tenantId: "*",
      coCd: "*",
      programId: "MOBILE_PROGRAM",
      programNm: "MOBILE_PROGRAM",
      templateSaveYn: "N",
      templateShareYn: "N",
      appApplyType: "Y",
      useYn: "Y",
      programType: LAYOUT_TYPE,
      insertUserId: User.getId(), //추후 이쪽 데이터 수정해야될 거 같음.
      updtUserId: User.getId(),
      builderType: "M",
    };
    LayoutSettingService.getProgramInfo(tmpProgram, (res) => {
      setCustomMainBoard(res.data[0]);
    });

    //템플릿 데이터 가져오기
    LayoutSettingService.getAllLayoutTmplList({}, (res) => {
      const tmpLoginTmplList = res.data.filter(
        (item) => item.layoutTmplType === LAYOUT_TYPE
      );
      setMainBoardTmplList(tmpLoginTmplList);
    });
  }, []);

  /**
   * app Layout 테이블에 초기 선택값이 없을 경우 => defaultYn 이 Y 인 tmpl으로 선택
   */
  const settingDefault = () => {
    const defaultLayout =
      mainBoardTmplList.find((item) => item.defaultYn === "Y") || {};
    saveMainBoardLayout(defaultLayout, "N");
  };

  /**
   * Custom Editor로 이동 시 실행 함수
   */
  const moveToCustomEditor = (program) => {
    setIsLoading(true);

    if (typeof program === "undefined") {
      const componentInfo = component.getPageComponent();
      componentInfo.programType = Enums.MobileProgramType.DASHBOARD_PROGRAM;
      componentInfo.builderType = Enums.BuilderType.MOBILE;
      UIReduxHelper.createTypePage(dispatch, componentInfo);
      updateEventWorkspace({});
      updateEventOutput("");
    } else {
      ProgramService.getProgram(
        program,
        (res) => {
          const { programContent, dataModelList, ...information } = res.data;
          const componentInfo = component.getPageComponent(); //Page Component
          const { memo, ...output } = JSON.parse(programContent);
          UIReduxHelper.loadTemplate(
            dispatch,
            componentInfo,
            JSON.stringify(output)
          );
          information.programContent = JSON.parse(programContent);
          dispatch(updateEventWorkspace({}));
          dispatch(updateEventOutput(""));
          UIReduxHelper.updateInformation(dispatch, information);
          if (memo) dispatch(setMemo(memo));
          else dispatch(setMemo([]));
        },
        () => {
          throw new Error("프로그램을 호출 중 오류가 발생하였습니다.");
        }
      );
    }
    navigate(
      Enums.BuilderPath.MOBILE.MAIN + "/" + Enums.BuilderPath.MOBILE.EDITOR
    );
  };

  /**
   * 저장
   * @param {*} item
   * @param {*} custom
   */
  const saveMainBoardLayout = (item, custom) => {
    const selectedData = { ...appLayoutMainBoard };
    if (custom === "Y") {
      // custom일 경우
      selectedData.layoutId = customMainBoard.programUid;
      selectedData.appId = workspace.appId;
      selectedData.customYn = "Y";
      selectedData.layoutType = LAYOUT_TYPE;
    } else {
      //커스텀 아닐 경우
      selectedData.layoutId = item.layoutTmplId;
      selectedData.appId = workspace.appId;
      selectedData.customYn = "N";
      selectedData.layoutType = LAYOUT_TYPE;
    }

    LayoutSettingService.saveAppLayoutData(selectedData, (res) => {
      if (StringUtils.isEmpty(selectedData.appLayoutId)) {
      }
      selectedData.appLayoutId = res.data.appLayoutId;
      setAppLayoutMainBoard(selectedData);
    });
  };

  const mainBoardTmplRender = (item) => {
    if (ObjectUtils.isEmpty(appLayoutMainBoard)) {
      settingDefault();
    }
    if (item !== "custom") {
      return (
        <Col
          className={
            appLayoutMainBoard.layoutId === item.layoutTmplId
              ? "mainboard-sample-selected"
              : "mainboard-sample"
          }
          onClick={(e) => saveMainBoardLayout(item, "N")}
        >
          <div className="sample-data">
            <Image
              src={mainBoardSample}
              style={{
                margin: "0 auto 0 auto",
                height: "80%",
                width: "auto",
              }}
            />
          </div>
          <div className="mainboard-label">{item.layoutTmplNm}</div>
        </Col>
      );
    } else {
      return (
        <Col
          className={
            appLayoutMainBoard.layoutId === customMainBoard.programUid
              ? "mainboard-sample-selected"
              : "mainboard-sample"
          }
          onClick={(e) => saveMainBoardLayout(customMainBoard, "Y")}
        >
          <div className="sample-data"></div>
          <div className="mainboard-label">사용자 Custom</div>
        </Col>
      );
    }
  };

  return (
    <React.Fragment>
      <DndProvider backend={HTML5Backend}>
        <div>
          <PageTemplate breadcrum={breadcrum}>
            <PageTemplate.Box boxClass="mb-0">
              <div className="dashboard-container">
                <Row className="ls-container">
                  <Col sm={2} style={{ marginLeft: "6rem" }}>
                    <div className="ls-header">메인 화면 템플릿 선택</div>
                    <div className="ls-description">
                      메인화면 화면 템플릿입니다. ‘Mobile Editor로 이동‘ 버튼을
                      통해 상세 설정이 가능합니다.
                    </div>
                  </Col>
                  <Col sm={7}>
                    <div className="ls-content">
                      <Row>
                        {mainBoardTmplList.map((item) => {
                          return mainBoardTmplRender(item);
                        })}
                        {!ObjectUtils.isEmpty(customMainBoard)
                          ? mainBoardTmplRender("custom")
                          : ""}
                      </Row>
                    </div>
                  </Col>
                  <Col
                    style={{
                      display: "flex",
                      alignItems: "end",
                      justifyContent: "",
                    }}
                  >
                    <Button
                      variant="outline-primary"
                      onClick={(e) => moveToCustomEditor(customMainBoard)}
                    >
                      Custom Editor로 이동
                    </Button>
                  </Col>
                </Row>
              </div>
            </PageTemplate.Box>
          </PageTemplate>
        </div>
      </DndProvider>
    </React.Fragment>
  );
};

export default MainBoardSetting;
