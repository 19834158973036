class Domain {
  domainId = null;
  domainCd = null;
  domainNm = null;
  description = null;
  dataType = null;
  dataLength = null;
  formType = null;
  decimals = null;
  roundingPolicy = null;
  negativeType = null;
  outputConversion = null;
  caseSensitive = null;
  entryType = null;
  entryRefTable = null;
  entryRefWhere = null;
  entryRefKey = null;
  entryRefValue = null;
  entryDisType = null;
  possibleEntryList = null;
  isNull = true;
  constructor(domain) {
    if (domain) {
      this.domainId = domain.domainId;
      this.domainCd = domain.domainCd;
      this.domainNm = domain.domainNm;
      this.description = domain.description;
      this.dataType = domain.dataType;
      this.dataLength = domain.dataLength;
      this.formType = domain.formType;
      this.decimals = domain.decimals;
      this.roundingPolicy = domain.roundingPolicy;
      this.negativeType = domain.negativeType;
      this.outputConversion = domain.outputConversion;
      this.caseSensitive = domain.caseSensitive;
      this.entryType = domain.entryType;
      this.entryRefTable = domain.entryRefTable;
      this.entryRefWhere = domain.entryRefWhere;
      this.entryRefKey = domain.entryRefKey;
      this.entryRefValue = domain.entryRefValue;
      this.entryDisType = domain.entryDisType;
      this.possibleEntryList = domain.possibleEntryList;
      this.isNull = false;
    } else {
      this.isNull = true;
    }
  }

  getDomainId() {
    return this.domainId;
  }

  setDomainId(value) {
    this.domainId = value;
  }

  getDomainCd() {
    return this.domainCd;
  }

  setDomainCd(value) {
    this.domainCd = value;
  }

  getDomainNm() {
    return this.domainNm;
  }

  setDomainNm(value) {
    this.domainNm = value;
  }

  getDescription() {
    return this.description;
  }

  setDescription(value) {
    this.description = value;
  }

  getDataType() {
    return this.dataType;
  }

  setDataType(value) {
    this.dataType = value;
  }

  getDataLength() {
    return this.dataLength;
  }

  setDataLength(value) {
    this.dataLength = value;
  }

  getFormType() {
    return this.formType;
  }

  setFormType(value) {
    this.formType = value;
  }

  getDecimals() {
    return this.decimals;
  }

  setDecimals(value) {
    this.decimals = value;
  }

  getRoundingPolicy() {
    return this.roundingPolicy;
  }

  setRoundingPolicy(value) {
    this.roundingPolicy = value;
  }

  getNegativeType() {
    return this.negativeType;
  }

  setNegativeType(value) {
    this.negativeType = value;
  }

  getOutputConversion() {
    return this.outputConversion;
  }

  setOutputConversion(value) {
    this.outputConversion = value;
  }

  getCaseSensitive() {
    return this.caseSensitive;
  }

  setCaseSensitive(value) {
    this.caseSensitive = value;
  }

  getEntryType() {
    return this.entryType;
  }

  setEntryType(value) {
    this.entryType = value;
  }

  getEntryRefTable() {
    return this.entryRefTable;
  }

  setEntryRefTable(value) {
    this.entryRefTable = value;
  }

  getEntryRefWhere() {
    return this.entryRefWhere;
  }

  setEntryRefWhere(value) {
    this.entryRefWhere = value;
  }

  getEntryRefKey() {
    return this.entryRefKey;
  }

  setEntryRefKey(value) {
    this.entryRefKey = value;
  }

  getEntryRefValue() {
    return this.entryRefValue;
  }

  setEntryRefValue(value) {
    this.entryRefValue = value;
  }

  getEntryDisType() {
    return this.entryDisType;
  }

  setEntryDisType(value) {
    this.entryDisType = value;
  }

  getPossibleEntryList() {
    return this.possibleEntryList;
  }

  setPossibleEntryList(value) {
    this.possibleEntryList = value;
  }

  toJSON() {
    return {
      domainId: this.domainId,
      domainCd: this.domainCd,
      domainNm: this.domainNm,
      description: this.description,
      dataType: this.dataType,
      dataLength: this.dataLength,
      formType: this.formType,
      decimals: this.decimals,
      roundingPolicy: this.roundingPolicy,
      negativeType: this.negativeType,
      outputConversion: this.outputConversion,
      caseSensitive: this.caseSensitive,
      entryType: this.entryType,
      entryRefTable: this.entryRefTable,
      entryRefWhere: this.entryRefWhere,
      entryRefKey: this.entryRefKey,
      entryRefValue: this.entryRefValue,
      entryDisType: this.entryDisType,
      possibleEntryList: this.possibleEntryList,
    };
  }
}

export default Domain;
