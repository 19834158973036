import React from "react";
import BuilderSidebarContextMenu from "../contextMenu/BuilderSidebarContextMenu";
import {
  AiFillEdit,
  AiOutlineFunction,
  AiOutlineUnorderedList,
} from "react-icons/ai";
import { Enums } from "../BuilderEnum";
import { TbReport } from "react-icons/tb";

class ProgramDesignMenu extends BuilderSidebarContextMenu {
  constructor(props) {
    super(props);
    this.state = {
      searchResultList: [],
      recentWorkList: [],
      dataList: [],
      dataLoading: false,
      searchTerm: "",
    };
    this.goToTechnicalDesignPage = this.goToTechnicalDesignPage.bind(this);
  }

  componentDidMount() {}

  onClickNewService() {
    // this.props.createNewService(null, this.props.eventHandler);
    this.goToTechnicalDesignPage(Enums.BuilderPath.PROGRAM_DESIGN.BUILDER);
  }

  goToTechnicalDesignPage = (url) => {
    this.goToPage(Enums.BuilderPath.PROGRAM_DESIGN.MAIN + "/" + url);
  };

  renderMenuContents() {
    return (
      <div>
        {this.renderTitle(TbReport, "Program Design Builder")}
        <div className="menu-list">
          {this.renderContextMenu(AiFillEdit, "Program Design Editor", (e) =>
            this.goToTechnicalDesignPage(
              Enums.BuilderPath.PROGRAM_DESIGN.BUILDER
            )
          )}
          {this.renderContextMenu(
            AiOutlineUnorderedList,
            "Program Design List",
            (e) =>
              this.goToTechnicalDesignPage(
                Enums.BuilderPath.PROGRAM_DESIGN.LIST
              )
          )}
        </div>
        <hr />
      </div>
    );
  }
}

ProgramDesignMenu.propTypes = {};

export default ProgramDesignMenu;
