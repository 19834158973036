import { Enums } from "components/builder/BuilderEnum";
import PageTemplate from "page/common/PageTemplate";
import React, { useContext, useEffect, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import "css/mobile/layout-setting.css";
import * as Fa from "react-icons/fa";
import { Button, Card, Col, Form, Image, Row } from "react-bootstrap";
import {
  ArrayUtils,
  JsonUtils,
  ObjectUtils,
  StringUtils,
} from "@alpha/com.bizentro.daaf.front.framework";
import UInputPopup from "components/common/element/UInputPopup";
import Popup from "components/common/Popup";
import IconPopup from "page/popup/IconPopup";
import LayoutSettingService from "services/mobile/LayoutSettingService";
import { useDispatch, useSelector } from "react-redux";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { MdOutlineImageSearch } from "react-icons/md";
import { BsSearch } from "react-icons/bs";
import Message from "@alpha/com.bizentro.daaf.front.framework/dist/component/Message";
import mnuSample1 from "images/menu_tmpl_1.png";
import User from "components/common/utils/UserUtils";
import ProgramService from "services/ui/ProgramService";
import { AppContext } from "components/common/AppContextProvider";
import UIReduxHelper from "components/builder/ui/editor/helper/UIReduxHelper";
import {
  updateEventOutput,
  updateEventWorkspace,
} from "components/builder/eventhandler/reducer/EventHandlerAction";
import { setMemo } from "components/builder/ui/reducers/UIBuilderAction";
import { useNavigate } from "react-router-dom";
import { setMobileScreenHeight } from "components/builder/mobile/reducers/MobileAction";

const LayoutSetting = () => {
  const breadcrum = [
    {
      name: "Layout Setting",
      url: Enums.BuilderPath.UI.MAIN + "/" + Enums.BuilderPath.UI.LIST,
      active: true,
    },
  ];
  const { component } = useContext(AppContext);
  const workspace = useSelector((state) => state.workspace);

  const [menuTmplList, setMenuTmplList] = useState([]); //메뉴 템플릿 리스트
  const [bottomNavList, setBottomNavList] = useState([]); //하단 네비게이션 리스트
  const [topNavTmplList, setTopNavTmplList] = useState([]); //상단 네비게이션 템플릿 리스트
  const [colorThemeList, setColorThemeList] = useState([]); //색상 테마 리스트

  const [selectedNav, setSelectedNav] = useState({}); //선택된 하단 nav card 정보
  const [navNum, setnavNum] = useState(-1); //선택된 하단 nav 번호
  const [colorCode, setColorCode] = useState(["#1d4791", "#6089d1"]); //변경할 color Code 데이터

  const [appLayoutMenu, setAppLayoutMenu] = useState({}); //선택된 메뉴 템플릿
  const [appLayoutTopNav, setAppLayoutTopNav] = useState({}); //선택된 상단 네비게이션
  const [appLayoutTheme, setAppLayoutTheme] = useState({}); //선택된 색상 테마
  const [customTopNav, setCustomTopNav] = useState({}); //사용자 커스텀 상단 네비게이션
  const [customTheme, setCustomTheme] = useState({}); //사용자 커스텀 색상 테마

  const [logo, setLogo] = useState({}); //상단 네비게이션 로고
  const [cssFile, setCssFile] = useState({}); //테마 css 파일

  const TOP_NAV = "T";
  const MENU = "M";
  const COLOR_THEME = "C";

  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  /**
   * 초기 데이터 불러오기
   */
  useEffect(() => {
    LayoutSettingService.getBottomNavList({ appId: workspace.appId }, (res) => {
      setBottomNavList(res.data);
    });
    //appLayoutList에 저장된 데이터 불러오기
    LayoutSettingService.getAppLayoutList({ appId: workspace.appId }, (res) => {
      let tmpTopNav = res.data.find((item) => item.layoutType === TOP_NAV);
      let tmpTheme = res.data.find((item) => item.layoutType === COLOR_THEME);
      let tmpMenu = res.data.find((item) => item.layoutType === MENU);

      setAppLayoutTopNav(tmpTopNav || "");
      if (!StringUtils.isEmpty(tmpTopNav)) {
        const tmpLogoData = JsonUtils.parseJson(tmpTopNav.customContent);
        if (!ObjectUtils.isEmpty(tmpLogoData)) {
          setLogo(tmpLogoData.logo);
        } else {
          setLogo({ previewUrl: "", fileName: "" });
        }
      }
      setAppLayoutTheme(tmpTheme || "");
      setAppLayoutMenu(tmpMenu || "");

      customDataSetting(TOP_NAV);
      customDataSetting(MENU);
      customDataSetting(COLOR_THEME);
    });

    //템플릿 불러오기
    LayoutSettingService.getAllLayoutTmplList({}, (res) => {
      const tmplList = res.data;
      const topNavList = tmplList.filter(
        (item) => item.layoutTmplType === TOP_NAV
      );
      setTopNavTmplList(topNavList);

      const themeList = tmplList.filter(
        (item) => item.layoutTmplType === COLOR_THEME
      );
      setColorThemeList(themeList);

      const menuList = tmplList.filter((item) => item.layoutTmplType === MENU);
      setMenuTmplList(menuList);
    });
    resetSelectedNav();
  }, []);

  useEffect(() => {
    setLsInitColor();
  }, [colorThemeList, customTheme]);

  /**
   * 커스텀 데이터 기본 세팅
   * @param {*} type
   */
  const customDataSetting = (type) => {
    const tmpProgram = {
      appId: workspace.appId,
      moduleCd: workspace.moduleCd,
      appReleaseId: workspace.appReleaseId,
      tenantId: "*",
      coCd: "*",
      programId: "MOBILE_PROGRAM",
      programNm: "MOBILE_PROGRAM",
      templateSaveYn: "N",
      templateShareYn: "N",
      appApplyType: "Y",
      useYn: "Y",
      programType: type,
      insertUserId: User.getId(),
      updtUserId: User.getId(),
      builderType: "M",
    };

    LayoutSettingService.getProgramInfo(tmpProgram, (res) => {
      if (type === TOP_NAV) setCustomTopNav(res.data[0]);
      if (type === COLOR_THEME) setCustomTheme(res.data[0]);
    });
  };

  /**
   * 로고 데이터 저장
   */
  const saveLogoData = (logoData) => {
    const tmpTopNav = { ...appLayoutTopNav };
    tmpTopNav.customContent = JSON.stringify({ logo: logoData });
    setAppLayoutTopNav(tmpTopNav);
    saveAppLayout(tmpTopNav, "topNav");
  };

  const saveCssFile = (cssFile) => {
    // const tmpTopNav = { ...appLayoutTopNav };
    // tmpTopNav.customContent = JSON.stringify({ logo: cssFile });
    // setAppLayoutTopNav(tmpTopNav);
    // saveAppLayout(tmpTopNav, "topNav");
  };

  // const uploadCssFile = (file) => {
  //   if(!file instanceof File){
  //     return;
  //   }
  //   let formData = new FormData();
  //   formData.append("file", file);
  // }

  /**
   * Custom Editor로 이동 시 실행 함수
   */
  const moveToCustomEditor = (program) => {
    setIsLoading(true);
    if (typeof program === "undefined") {
      const componentInfo = component.getPageComponent();
      componentInfo.programType = Enums.MobileProgramType.TOP_NAV;
      componentInfo.builderType = Enums.BuilderType.MOBILE;
      UIReduxHelper.createTypePage(dispatch, componentInfo);
      updateEventWorkspace({});
      updateEventOutput("");
    } else {
      ProgramService.getProgram(
        program,
        (res) => {
          const { programContent, dataModelList, ...information } = res.data;

          const componentInfo = component.getPageComponent(); //Page Component
          const { memo, ...output } = JSON.parse(programContent);
          UIReduxHelper.loadTemplate(
            dispatch,
            componentInfo,
            JSON.stringify(output)
          );
          information.programContent = JSON.parse(programContent);
          dispatch(updateEventWorkspace({}));
          dispatch(updateEventOutput(""));
          UIReduxHelper.updateInformation(dispatch, information);
          if (memo) dispatch(setMemo(memo));
          else dispatch(setMemo([]));
        },
        () => {
          throw new Error("프로그램을 호출 중 오류가 발생하였습니다.");
        }
      );
    }
    dispatch(setMobileScreenHeight(85));
    navigate(
      Enums.BuilderPath.MOBILE.MAIN + "/" + Enums.BuilderPath.MOBILE.EDITOR
    );
  };

  /**
   * 초기 테마 색 설정
   */
  const setLsInitColor = () => {
    if (
      !ObjectUtils.isEmpty(appLayoutTheme) &&
      !ObjectUtils.isEmpty(customTheme)
    ) {
      if (appLayoutTheme.layoutId === customTheme.programUid) {
        const selectedColor = JsonUtils.parseJson(customTheme.programContent);
        setColorCode([selectedColor.defaultColor, selectedColor.subColor]);
      } else if (
        appLayoutTheme.layoutId !== "" &&
        !ArrayUtils.isEmpty(colorThemeList)
      ) {
        const colorData = colorThemeList.filter(
          (item) => item.layoutTmplId === appLayoutTheme.layoutId
        )[0];
        if (!ObjectUtils.isEmpty(colorData)) {
          const selectedColor = JsonUtils.parseJson(colorData.tmplContent);
          setColorCode([selectedColor.defaultColor, selectedColor.subColor]);
        }
      }
    }
  };

  useEffect(() => {
    let navList = [...bottomNavList];
    navList[navNum] = selectedNav;
    setBottomNavList(navList);
  }, [selectedNav]);

  /**
   * 하단 네비게이션 설정  우측 데이터값 비우기
   */
  const resetSelectedNav = (num) => {
    const nav = {
      bottomNavId: "",
      label: "",
      icon: "",
      linkUrl: "",
    };

    for (let i = 0; i < bottomNavList.length; i++) {
      document.getElementById("card" + i).className = "navi-card";
    }

    setSelectedNav(nav);
    if (num) {
      setnavNum(num);
    } else {
      setnavNum(-1);
    }
  };

  /**
   * nav 카드 선택시 event
   * @param {*} idx
   */
  const navClicked = (idx) => {
    if (!ObjectUtils.isEmpty(document.getElementById("card" + idx))) {
      if (!StringUtils.equalsIgnoreType(navNum, "-1")) {
        let navList = [...bottomNavList];
        navList[navNum] = selectedNav;
        setBottomNavList(navList);
      }

      setnavNum(idx);
      document.getElementById("card" + idx).className = "navi-card-selected";
      for (let i = 0; i < bottomNavList.length; i++) {
        if (i !== idx) {
          document.getElementById("card" + i).className = "navi-card";
        }
      }
      setSelectedNav(bottomNavList[idx]);
    }
  };

  /**
   * icon popup 호출
   * @param {*} e
   */
  const openPopupIcon = (e) => {
    //if 선택된 navigation card가 없을 경우 -> 메세지 alert 후 return;
    if (navNum === -1) {
      Message.alert("선택된 네비게이션이 없습니다.", Enums.MessageType.WARN);
      return;
    }

    const popOptions = {
      effect: Popup.ScaleUp, //Effect.SlideFromTop(default)를 Effect.ScaleUp 로 변경
      style: {
        content: {
          height: "700px",
        },
      },
    };
    Popup.open(
      <IconPopup
        title="Icon 조회"
        callbackFnc={(icon) => {
          let tmpNav = { ...selectedNav };
          tmpNav.icon = icon;
          setSelectedNav(tmpNav);

          let navList = [...bottomNavList];
          navList[navNum] = tmpNav;
          setBottomNavList(navList);

          let el = document.getElementById("icon");
          el.value = icon;
        }}
      />,
      popOptions
    );
  };

  /**
   * nav 카드 삭제 event
   * @param {*} idx
   */
  const deleteCard = (idx) => {
    let navList = [...bottomNavList];
    navList.splice(idx, 1);
    setBottomNavList(navList);
    if (navNum === idx) {
      for (let i = 0; i < navList.length; i++) {
        document.getElementById("card" + i).className = "navi-card";
      }
    }

    resetSelectedNav();
  };

  /**
   * bottom nav bar - 카드 추가
   */
  const addCard = () => {
    const nav = {
      bottomNavId: "",
      label: "",
      icon: "",
      linkUrl: "",
      appId: workspace.appId,
      sortSeq: bottomNavList.length,
    };

    let navList = [...bottomNavList];
    navList.push(nav);
    setBottomNavList(navList);
    disableInput();
  };

  /**
   * 하단 네비게이션 수정 input 박스 disable 시키는 함수.
   */
  const disableInput = () => {};

  /**
   * bottom nav bar 카드 dnd 함수
   * @param {*} result
   * @returns
   */
  const handleDragEnd = (result) => {
    if (!result.destination) return;
    let sIdx = result.source.index;
    let dIdx = result.destination.index;

    if (sIdx !== dIdx) {
      const items = [...bottomNavList];
      const [reorderedItem] = items.splice(sIdx, 1);
      items.splice(dIdx, 0, reorderedItem);

      for (let i = 0; i < items.length; i++) {
        items[i]["sortSeq"] = i + 1;
      }
      setBottomNavList(items);
      resetSelectedNav();
      setnavNum(-1);
    }
  };

  const getListStyle = () => ({
    display: "flex",
  });

  /**
   * 메뉴 템플릿 클릭 이벤트
   * @param {*} idx
   */
  const onClickMnuTmpl = (idx) => {
    let tmpMenuTmpl = { ...appLayoutMenu };
    if (ObjectUtils.isEmpty(tmpMenuTmpl)) {
      tmpMenuTmpl = {
        appId: workspace.appId,
        layoutType: "M",
        customYn: "N",
      };
    }
    tmpMenuTmpl.layoutId = menuTmplList[idx].layoutTmplId;

    saveAppLayout(tmpMenuTmpl, "menuTmpl");
  };

  /**
   * 상단 네비게이션 클릭 이벤트
   * @param {*} idx
   */
  const onClickTopNav = (item) => {
    let tmpTopNav = { ...appLayoutTopNav };

    if (ObjectUtils.isEmpty(tmpTopNav)) {
      tmpTopNav = {
        appId: workspace.appId,
        layoutType: TOP_NAV,
        customYn: "N",
      };
    }
    if (item === "custom") {
      tmpTopNav.customYn = "Y";
      tmpTopNav.layoutId = customTopNav.programUid;
      saveAppLayout(tmpTopNav, "topNav");
    } else {
      tmpTopNav.layoutId = item.layoutTmplId;

      saveAppLayout(tmpTopNav, "topNav");
    }
  };

  /**
   * appLayout 저장 (메뉴 템플릿, 상단 네비게이션, 테마)
   * @param {*} body
   * @param {*} key
   */
  const saveAppLayout = (body, key) => {
    if (StringUtils.isEmpty(body.appId)) {
      body.appId = workspace.appId;
    }
    LayoutSettingService.saveAppLayoutData(body, (res) => {
      if (key === "menuTmpl") {
        setAppLayoutMenu(res.data);
      } else if (key === "topNav") {
        setAppLayoutTopNav(res.data);
      } else if (key === "colorTheme") {
        setAppLayoutTheme(res.data);
      }
    });
  };

  /**
   * 상단 네비게이션 render 함수
   * @param {*} tem
   * @returns
   */
  const topNavTemplateRender = (item) => {
    if (item !== "custom") {
      return (
        <>
          <div
            className="top-nav-template-box"
            onClick={() => onClickTopNav(item)}
          >
            <div
              className={
                appLayoutTopNav.layoutId === item.layoutTmplId
                  ? "top-nav-template-selected"
                  : "top-nav-template"
              }
            >
              <input
                src={logo.previewUrl ? logo.previewUrl : ""}
                type="image"
                alt=""
                className="top-nav-logo"
              ></input>
              <div className="top-nav-title">BIZENTRO</div>
              <div className="top-nav-right">
                <Fa.FaBars />
              </div>
            </div>
          </div>
        </>
      );
    } else {
      //custom일 경우
      return (
        <Col style={{ display: "flex", alignItems: "center" }}>
          <div
            className="top-nav-template-box"
            onClick={() => onClickTopNav("custom")}
          >
            {!ObjectUtils.isEmpty(customTopNav) ? (
              <div
                className={
                  appLayoutTopNav.layoutId === customTopNav.programUid
                    ? "top-nav-template-selected"
                    : "top-nav-template"
                }
              >
                custom topNav
              </div>
            ) : (
              <div className="top-nav-template">custom</div>
            )}
          </div>
        </Col>
      );
    }
  };

  /**
   * 색상 테마 render 함수
   * @param {*} item
   * @param {*} idx
   * @returns
   */
  const themeTemplateRender = (item, idx) => {
    if (item !== "custom") {
      const tmplContent = JsonUtils.parseJson(item.tmplContent);
      return (
        <Col
          key={idx}
          className={
            appLayoutTheme.layoutId === item.layoutTmplId
              ? "theme-box-selected"
              : "theme-box"
          }
          onClick={(e) => onChangeTheme(idx)}
        >
          <div className="theme-content-box">
            <div
              style={{
                backgroundColor: `${tmplContent.defaultColor}`,
              }}
              className="color-theme-title"
            ></div>
            <div
              style={{
                backgroundColor: `${tmplContent.subColor}`,
              }}
              className="color-theme-sub"
            />
          </div>
          <div className="theme-label">{item.layoutTmplNm}</div>
        </Col>
      );
    } else {
      if (ObjectUtils.isEmpty(customTheme)) return;
      return (
        <Col
          sm={3}
          key={item}
          className={
            appLayoutTheme.layoutId === customTheme.programUid
              ? "theme-custom-box-selected"
              : "theme-custom-box"
          }
          onClick={(e) => onChangeTheme("custom")}
        >
          <div className="theme-custom-content-box">
            <div>저장할 css 파일을 불러와주세요.</div>
            <div className="custom-content">
              <Form.Control value={cssFile ? cssFile.fileName : ""} readOnly />
              <label htmlFor="cssFileUpload" className="upload-btn">
                <MdOutlineImageSearch />
              </label>
            </div>
          </div>

          <div className="theme-label">사용자 custom</div>
        </Col>
      );
    }
  };

  /**
   * 테마 변경시
   * @param {*} theme
   */
  const onChangeTheme = (theme) => {
    let tmpTheme = { ...appLayoutTheme };
    if (ObjectUtils.isEmpty(tmpTheme)) {
      tmpTheme = {
        appId: workspace.appId,
        layoutType: "C",
        customYn: "N",
      };
    }
    let selectedTheme = {};
    let data = {};
    if (theme !== "custom") {
      selectedTheme = colorThemeList[theme];
      tmpTheme.layoutId = selectedTheme.layoutTmplId;
      data = JsonUtils.parseJson(selectedTheme.tmplContent);
    } else {
      selectedTheme = customTheme;
      tmpTheme.layoutId = customTheme.programUid;
      data = JsonUtils.parseJson(selectedTheme.programContent);
    }

    saveAppLayout(tmpTheme, "colorTheme");

    const newColor = [data.defaultColor, data.subColor];
    setColorCode(newColor);
  };

  /**
   * 테마 변경시 layout preview (미리보기) 변경
   */
  const applyColor = () => {
    const targetElement = document.querySelector(".layout-container");
    targetElement.style.setProperty("--default-color", colorCode[0]);
    targetElement.style.setProperty("--sub-color", colorCode[1]);
  };

  useEffect(() => {
    applyColor();
  }, [colorCode]);

  /**
   * 하단 네비게이션 저장 함수
   */
  const saveBottomNavBar = () => {
    if (!ObjectUtils.isEmpty(bottomNavList)) {
      LayoutSettingService.saveBottomNavList(
        { bottomNavList: bottomNavList },
        (res) => {
          Message.alert("저장되었습니다", Enums.MessageType.SUCCESS);
        }
      );
    } else {
      Message.alert("저장 중 오류가 발생하였습니다.", Enums.MessageType.ERROR);
    }
  };

  return (
    <React.Fragment>
      <DndProvider backend={HTML5Backend}>
        <div>
          <PageTemplate breadcrum={breadcrum}>
            <PageTemplate.Box boxClass="mb-0">
              <div className="layout-container">
                {/* 메뉴 템플릿 설정 */}
                <Row className="ls-container">
                  <Col sm={2}>
                    <div className="ls-header">메뉴 템플릿 선택</div>
                    <div className="ls-description">
                      모바일 빌더 화면의 기본 레이아웃입니다.
                    </div>
                  </Col>
                  <Col sm={8}>
                    <div className="ls-content">
                      <Row>
                        {!ArrayUtils.isEmpty(menuTmplList)
                          ? menuTmplList.map((item, idx) => {
                              return (
                                <Col
                                  className={
                                    appLayoutMenu.layoutId === item.layoutTmplId
                                      ? "layout-sample-selected"
                                      : "layout-sample"
                                  }
                                  key={"mnu" + idx}
                                  onClick={(e) => onClickMnuTmpl(idx)}
                                >
                                  <div className="layout-data">
                                    <Image
                                      src={mnuSample1}
                                      style={{
                                        margin: "0 auto 0 auto",
                                        height: "100%",
                                        width: "auto",
                                      }}
                                    />
                                  </div>
                                  <div className="layout-label">
                                    {item.layoutTmplNm}
                                  </div>
                                </Col>
                              );
                            })
                          : ""}
                      </Row>
                    </div>
                  </Col>
                </Row>
                {/* 하단 네비게이션 설정 */}
                <Row className="ls-container">
                  <Col sm={2}>
                    <div className="ls-header">하단 네비게이션 설정</div>
                    <div className="ls-description">
                      하단 네비게이션을 설정하세요.
                    </div>
                  </Col>
                  <Col sm={8}>
                    <div
                      className="ls-content"
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Col sm={8} style={{ display: "flex" }}>
                        <DragDropContext onDragEnd={handleDragEnd}>
                          <Droppable
                            droppableId={"droppable1"}
                            direction="horizontal"
                          >
                            {(provided, snapshot) => (
                              <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                style={getListStyle()}
                              >
                                {bottomNavList.map((item, idx) => {
                                  let IconTag = "";
                                  if (!StringUtils.isEmpty(item.icon)) {
                                    IconTag =
                                      Fa[
                                        StringUtils.convertKebabToPascal(
                                          item.icon
                                        )
                                      ];
                                  }

                                  return (
                                    <Draggable
                                      draggableId={`card-${idx}`}
                                      key={`card-${idx}`}
                                      index={idx}
                                    >
                                      {(provided, snapshot) => {
                                        return (
                                          <div
                                            key={`card-${idx}`}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            ref={provided.innerRef}
                                          >
                                            <Card
                                              className="navi-card"
                                              id={"card" + idx}
                                              onClick={(e) => {
                                                navClicked(idx);
                                              }}
                                            >
                                              <Card.Body>
                                                {IconTag !== "" ? (
                                                  <div className="navi-card-icon">
                                                    <IconTag />
                                                  </div>
                                                ) : (
                                                  ""
                                                )}

                                                <div className="navi-card-label">
                                                  {" "}
                                                  {item.label}
                                                </div>
                                              </Card.Body>
                                            </Card>
                                            <div style={{ width: 0 }}>
                                              <div
                                                className="nav-card-delBtn"
                                                onClick={(e) => deleteCard(idx)}
                                              >
                                                <Fa.FaMinus />
                                              </div>
                                            </div>
                                          </div>
                                        );
                                      }}
                                    </Draggable>
                                  );
                                })}
                                {provided.placeholder}
                              </div>
                            )}
                          </Droppable>
                        </DragDropContext>
                        {bottomNavList.length < 5 ? (
                          <Card
                            className="navi-card navi-card-plus"
                            onClick={(e) => addCard()}
                          >
                            <div className="navi-card-icon">
                              <Fa.FaPlus />
                            </div>
                          </Card>
                        ) : (
                          ""
                        )}
                      </Col>
                      <Col sm={4}>
                        <div className="navi-detail">
                          <Row className="navi-detail-row">
                            <Col sm={2}>
                              <Form.Label>Icon</Form.Label>
                            </Col>
                            <Col sm={10}>
                              <UInputPopup
                                id="icon"
                                defaultValue={StringUtils.defaultString(
                                  selectedNav.icon,
                                  ""
                                )}
                                // onBlur={onBlur}
                                onClick={openPopupIcon}
                                readonlyTp="input"
                              />
                            </Col>
                          </Row>
                          <Row className="navi-detail-row">
                            <Col sm={2}>
                              <Form.Label>Page</Form.Label>
                            </Col>
                            <Col sm={10}>
                              <Form.Control
                                className="form-control-sm"
                                value={selectedNav.linkUrl}
                                onChange={(e) => {
                                  const nav = { ...selectedNav };
                                  nav.linkUrl = e.target.value;
                                  setSelectedNav(nav);
                                }}
                                disabled={
                                  ObjectUtils.isEmpty(selectedNav) ||
                                  navNum === -1
                                }
                              ></Form.Control>
                            </Col>
                          </Row>
                          <Row className="navi-detail-row">
                            <Col sm={2}>
                              <Form.Label>Id</Form.Label>
                            </Col>
                            <Col sm={10}>
                              <Form.Control
                                className="form-control-sm"
                                value={selectedNav.bottomNavId}
                                onChange={(e) => {
                                  const nav = { ...selectedNav };
                                  nav.bottomNavId = e.target.value;
                                  setSelectedNav(nav);
                                }}
                                disabled={
                                  ObjectUtils.isEmpty(selectedNav) ||
                                  navNum === -1
                                }
                              ></Form.Control>
                            </Col>
                          </Row>
                          <Row className="navi-detail-row">
                            <Col sm={2}>
                              <Form.Label>Label</Form.Label>
                            </Col>
                            <Col sm={10}>
                              <Form.Control
                                className="form-control-sm"
                                value={selectedNav.label}
                                onChange={(e) => {
                                  const nav = { ...selectedNav };
                                  nav.label = e.target.value;
                                  setSelectedNav(nav);
                                }}
                                disabled={
                                  ObjectUtils.isEmpty(selectedNav) ||
                                  navNum === -1
                                }
                              ></Form.Control>
                            </Col>
                          </Row>
                        </div>
                        <div className="bottom-nav-save-btn">
                          <Button
                            variant="outline-success"
                            onClick={() => saveBottomNavBar()}
                          >
                            <Fa.FaSave />
                            저장
                          </Button>
                        </div>
                      </Col>
                    </div>
                  </Col>
                </Row>
                {/* 상단 네비게이션 설정 */}
                <Row className="ls-container">
                  <Col sm={2}>
                    <div className="ls-header">상단 네비게이션 설정</div>
                    <div className="ls-description">
                      상단 네비게이션을 설정하세요.
                    </div>
                  </Col>
                  <Col sm={10}>
                    <div
                      className="ls-content"
                      style={{
                        alignItems: "center",
                      }}
                    >
                      <Row>
                        <Col
                          xs={1}
                          style={{ textAlign: "center", marginLeft: "30px" }}
                        >
                          <Form.Label>Logo</Form.Label>
                        </Col>
                        <Col xs={4} style={{ marginLeft: "10px" }}>
                          <div className="filewrap">
                            <Row>
                              <Col>
                                <Form.Control
                                  value={logo ? logo.fileName : ""}
                                  readOnly
                                />
                              </Col>
                              <Col>
                                <label
                                  htmlFor="btnFileUpload"
                                  className="upload-btn"
                                >
                                  <MdOutlineImageSearch />
                                </label>
                              </Col>
                            </Row>
                            <input
                              type="file"
                              id="btnFileUpload"
                              name="btnFileUpload"
                              className="upload-input hide"
                              onChange={(e) => {
                                if (ObjectUtils.isEmpty(e.target.files)) return;
                                const reader = new FileReader();
                                const files = e.target.files[0];
                                reader.readAsDataURL(files);
                                reader.onload = (e) => {
                                  setLogo({
                                    fileName: files.name,
                                    previewUrl: e.target.result,
                                  });
                                  const tmpData = {
                                    fileName: files.name,
                                    previewUrl: e.target.result,
                                  };
                                  saveLogoData(tmpData);
                                };
                              }}
                            />
                          </div>
                        </Col>
                      </Row>

                      <Row>
                        {!ArrayUtils.isEmpty(topNavTmplList)
                          ? topNavTmplList.map((item, idx) => {
                              return (
                                <Col style={{ display: "flex" }} sm={6}>
                                  {topNavTemplateRender(item)}
                                </Col>
                              );
                            })
                          : ""}
                      </Row>
                      <Row>
                        {!ObjectUtils.isEmpty(customTopNav)
                          ? topNavTemplateRender("custom")
                          : ""}
                        <Col
                          xs={8}
                          style={{
                            display: "flex",
                            alignItems: "end",
                            marginLeft: "10px",
                          }}
                        >
                          <Button
                            variant="outline-primary"
                            onClick={(e) => moveToCustomEditor(customTopNav)}
                          >
                            Custom Editor로 이동
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
                {/* 테마 설정 */}
                <Row className="ls-container">
                  <Col sm={2}>
                    <div className="ls-header">테마 선택</div>
                    <div className="ls-description">
                      모바일 빌더 화면의 색상 테마입니다.
                    </div>
                  </Col>
                  <Col sm={10}>
                    <div
                      className="ls-content"
                      style={{
                        alignItems: "center",
                      }}
                    >
                      <Row style={{ justifyContent: "center" }}>
                        {!ObjectUtils.isEmpty(colorThemeList) ? (
                          <>
                            {colorThemeList.map((item, idx) => {
                              return themeTemplateRender(item, idx);
                            })}
                          </>
                        ) : (
                          ""
                        )}
                        {!ObjectUtils.isEmpty(colorThemeList) ? (
                          themeTemplateRender("custom")
                        ) : (
                          <></>
                        )}
                      </Row>
                    </div>
                  </Col>
                  <div>
                    <input
                      type="file"
                      id="cssFileUpload"
                      name="cssFileUpload"
                      className="upload-input hide"
                      onChange={(e) => {
                        if (ObjectUtils.isEmpty(e.target.files)) return;
                        const reader = new FileReader();
                        const files = e.target.files[0];
                        reader.readAsDataURL(files);
                        reader.onload = (e) => {
                          setCssFile({
                            fileName: files.name,
                            data: e.target.value,
                          });
                          const tmpData = {
                            fileName: files.name,
                            previewUrl: e.target.result,
                          };
                          saveCssFile(tmpData);
                        };
                      }}
                    />
                  </div>
                </Row>
                {/* 우측 미리보기 화면 */}
                <div className="ls-preview">
                  {!StringUtils.isEmpty(appLayoutTopNav.layoutId) ? (
                    <div className="ls-top-nav">
                      {topNavTemplateRender(
                        topNavTmplList.find(
                          (item) =>
                            item.layoutTmplId === appLayoutTopNav.layoutId
                        ) || "custom"
                      )}
                    </div>
                  ) : (
                    <div className="ls-top-nav"></div>
                  )}
                  {bottomNavList.length !== 0 ? (
                    <div className="ls-bottom-nav">
                      {bottomNavList.map((item, idx) => {
                        let IconTag = "";
                        if (!StringUtils.isEmpty(item.icon)) {
                          IconTag =
                            Fa[StringUtils.convertKebabToPascal(item.icon)];
                        }
                        return (
                          <div className="nav-item" key={"bottom" + idx}>
                            {IconTag !== "" ? (
                              <div className="nav-icon">
                                <IconTag />
                              </div>
                            ) : (
                              ""
                            )}

                            <div className="nav-label"> {item.label}</div>
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </PageTemplate.Box>
          </PageTemplate>
        </div>
      </DndProvider>
    </React.Fragment>
  );
};

export default LayoutSetting;
