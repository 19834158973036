import { Tooltip } from "@mui/material";
import { Enums } from "components/builder/BuilderEnum";
import { stopEvent } from "components/builder/ui/editor/handler/UIEditorEventHandler";
import {
  getInOutputEntity,
  getServiceInOutputEntity,
} from "components/builder/workflow/editor/render/WorkflowRenderUtils";
import Message from "components/common/Message";
import UModal from "components/common/modal/UModal";
import Popup from "components/common/Popup";
import ArrayUtils from "components/common/utils/ArrayUtils";
import StringUtils from "components/common/utils/StringUtils";
import produce from "immer";
import { Button, Col, Form, InputGroup, Row } from "react-bootstrap";
import { AiOutlineEllipsis, AiOutlineQuestionCircle } from "react-icons/ai";
import { BiRefresh } from "react-icons/bi";
import { MdDelete } from "react-icons/md";
import WorkflowService from "services/workflow/WorkflowService";
import { ReturnEntityDesc } from "../DescPopup";
import ProcessModal from "./process/ProcessModal";
import { START_PROCESS_DATA_TYPE } from "./process/StartProcess";
import ProcessDataBindingPopup from "./ProcessDataBindingPopup";
import WorkflowListPopup from "./WorkflowListPopup";
import WorkFlowProcessListPopup from "./WorkFlowProcessListPopup";

class ServicePopup extends ProcessModal {
  constructor(props) {
    super(props);
    this.onOpenWorkflowList = this.onOpenWorkflowList.bind(this);
    this.onAddInput = this.onAddInput.bind(this);
    this.getInputMapping = this.getInputMapping.bind(this);
    this.onDeleteInput = this.onDeleteInput.bind(this);
    this.onChangeInputField = this.onChangeInputField.bind(this);
    this.onChangeOutputField = this.onChangeOutputField.bind(this);
    this.setInOutEntity = this.setInOutEntity.bind(this);
    this.onRefreshService = this.onRefreshService.bind(this);
    this.onChangeOutputMapping = this.onChangeOutputMapping.bind(this);
    this.state = {
      serviceId: "",
      serviceUid: undefined,
      serviceName: "",
      inputMapping: [],
      outputMapping: [],
      inputDataType: START_PROCESS_DATA_TYPE.ENTITY,
      isLoading: false,
    };
  }

  async componentDidMount() {
    const state = { ...this.state, ...this.props.processInfo };
    if (this.props.processInfo) {
      const result = await getServiceInOutputEntity(this.props.processInfo);
      if (result) {
        const {
          inputList = [],
          inputDataType = START_PROCESS_DATA_TYPE.ENTITY,
          outputList = [],
        } = result;
        let inputMapping = inputList.map((input, index) => {
          return {
            inputId: input.inputId,
            inputNm: input.inputNm,
            value: "",
          };
        });
        const prevInputMapping = this.props.processInfo.inputMapping;
        if (prevInputMapping) inputMapping = prevInputMapping;

        const prevOutputMapping =
          this.props.processInfo.outputMapping || outputList;

        const outputMapping = prevOutputMapping.map((prevOutput) => {
          if (typeof prevOutput === "string") {
            return {
              entityNm: "",
              entityVariable: prevOutput,
              value: prevOutput,
            };
          } else if (StringUtils.isEmpty(prevOutput.value)) {
            const output = outputList.find(
              (om) => prevOutput.value === om.entityVariable
            );
            if (output) {
              return {
                entityNm: output.entityNm,
                entityVariable: output.entityVariable,
                value: output.entityVariable,
              };
            } else {
              return {
                entityNm: "",
                fieldId: "",
                entityVariable: "",
                value: prevOutput.entityVariable,
              };
            }
          } else {
            return {
              ...prevOutput,
            };
          }
        });

        state.inputDataType = inputDataType;
        state.inputMapping = inputMapping;
        state.outputMapping = outputMapping;
      }
    }
    this.setState(state);
  }

  renderProcessNm() {
    return <></>;
  }

  onValidationCheck() {
    if (!this.state.serviceUid) {
      Message.alert(
        "The Service selection is ambiguous. Please check the selection of the service.",
        Enums.MessageType.WARN
      );
      return false;
    }

    return true;
  }

  validateCommonParam() {
    if (this.onValidationCheck() === true) {
      this.onClickConfirm();
    }
  }

  getInputMapping(inputList = [], inputDataType, inputEntity) {
    let inputMapping = [];
    inputMapping = inputList.map((input, index) => {
      return {
        inputId: input.inputId,
        inputNm: input.inputNm,
        value: "",
      };
    });
    return inputMapping;
  }

  /**
   * 인아풋 세팅
   * @param {*} service
   */
  async setInOutEntity(serviceParam) {
    const result = await getServiceInOutputEntity(serviceParam);
    console.log(result);
    if (!result) return false;
    const { inputList, inputDataType, inputEntity, outputList, service } =
      result;

    let inputMapping = this.getInputMapping(
      inputList,
      inputDataType,
      inputEntity
    );
    const outputMapping = outputList.map((outputValue, index) => {
      if (typeof outputValue === "string") {
        return {
          entityNm: "",
          entityVariable: outputValue,
          value: outputValue,
        };
      } else {
        return {
          entityNm: outputValue.entityNm,
          entityVariable: outputValue.entityVariable,
          value: outputValue.entityVariable,
        };
      }
    });

    this.setState(
      produce(this.state, (draft) => {
        draft.serviceId = service.serviceId;
        draft.serviceName = service.serviceName;
        draft.serviceUid = service.serviceUid;
        draft.inputDataType = inputDataType;
        draft.inputMapping = inputMapping;
        draft.outputMapping = outputMapping;
      })
    );
  }

  onClickConfirm() {
    if (this.props.callbackFnc) {
      const state = { ...this.state };
      delete state.isLoading;
      this.props.callbackFnc(state);
    }
  }

  /**
   * 워크플로우 (서비스) 목록 팝업 호출
   */
  onOpenWorkflowList() {
    const callbackFnc = (service) => {
      if (service.serviceUid === this.props.workflow.serviceInfo.serviceUid)
        return this.WarnMessage("The same service cannot be selected.");
      this.setInOutEntity(service);
      Popup.close();
    };

    Popup.open(
      <WorkflowListPopup
        callbackFnc={callbackFnc}
        workspace={this.props.workspace}
      />,
      {
        style: {
          content: {
            width: "55%",
          },
        },
      }
    );
  }

  /**
   * Input 삭제
   * @param {*} e
   * @param {*} idx
   */
  onDeleteInput(e, idx) {
    stopEvent(e);
    this.setState(
      produce(this.state, (draft) => {
        draft.inputMapping.splice(idx, 1);
      })
    );
  }
  /**
   * 인풋추가
   * @param {*} e
   */
  onAddInput(e) {
    this.setState(
      produce(this.state, (draft) => {
        draft.inputMapping.push({
          inputId: "",
          inputNm: "",
          value: "",
        });
      })
    );
  }

  callbackDataBinding(args, value) {
    const { type, event, index } = args;
    if (type === START_PROCESS_DATA_TYPE.ENTITY) {
      this.setState(
        produce(this.state, (draft) => {
          draft.inputMapping.value = value;
        })
      );
    } else if (type === START_PROCESS_DATA_TYPE.ENTITY_FIELD) {
      this.setState(
        produce(this.state, (draft) => {
          draft.inputMapping[index].value = value;
        })
      );
    }
  }

  /**
   * 데이터 바인딩 팝업 열기
   * @param {*} e
   * @param {*} idx
   */
  onOpenInputDataBindingPopup(args) {
    const callbackFnc = (value) => {
      Popup.close();
      this.callbackDataBinding(args, value);
    };
    Popup.open(
      <ProcessDataBindingPopup
        workspace={this.props.workspace}
        workflow={this.props.workflow}
        nodes={this.props.nodes}
        edges={this.props.edges}
        callbackFnc={callbackFnc}
        compId={this.props.compId}
        showAll={true}
        getAccessibleEntityList={this.getAccessibleEntityList}
      />,
      {
        style: { content: { width: "800px" } },
      }
    );
  }

  /**
   * output Mapping Change 이벤트
   * @param {*} e
   * @param {*} idx
   * @param {*} value
   */
  onChangeOutputMapping = (e, idx, value) => {
    // 1. " . "  기준으로 split
    // 2. 인덱스 0 은 entity
    // 3. 인덱스 1 이 있는 경우 .get("  , ") 부분 분리

    const stringSplit = value.split(".");
    let entityVariable = "";
    let fieldId = "";
    if (!ArrayUtils.isEmpty(stringSplit)) {
      entityVariable = stringSplit[0];

      if (stringSplit[1] && !StringUtils.isEmpty(stringSplit[1])) {
        const regex = /get\("([^"]+)"\)/;
        const match = stringSplit[1].match(regex);
        if (match) {
          fieldId = match[1];
        }
      }
      this.setState(
        produce(this.state, (draft) => {
          draft.outputMapping[idx].entityVariable = entityVariable;
          draft.outputMapping[idx].fieldId = fieldId;
        })
      );
    }
  };

  /**
   * Entity Definition 목록 팝업 호출
   * @param {*} e
   * @param {*} paramNm
   */
  onOpenEntityList(e, idx) {
    stopEvent(e);
    const callbackFnc = (entity) => {
      Popup.close();
      this.setState(
        produce(this.state, (draft) => {
          draft.outputMapping[idx].entityVariable = entity.entityVariable;
        })
      );
    };

    Popup.open(
      <WorkFlowProcessListPopup
        callbackFnc={callbackFnc}
        workflow={this.props.workflow}
        nodes={this.props.nodes}
        edges={this.props.edges}
        iterator={this.props.iterator}
        compId={this.props.compId}
        getAccessibleEntityList={this.getAccessibleEntityList}
      />,
      {
        style: {
          content: {
            width: "550px",
          },
        },
      }
    );
  }

  onChangeInputField(e, idx) {
    this.setState(
      produce(this.state, (draft) => {
        draft.inputMapping[idx][e.target.id] = e.target.value;
      })
    );
  }

  onChangeOutputField(e, idx) {
    this.setState(
      produce(this.state, (draft) => {
        draft.outputMapping[idx][e.target.id] = e.target.value;
      })
    );
  }

  onRefreshService(e) {
    this.setInOutEntity({ serviceUid: this.state.serviceUid });
  }

  /**
   * CS 파일을 사용하여 만든 service 검색
   * incompleteYn이 적용된 경우에만 활성화 된다.
   */
  onClickServiceConnect = (e) => {
    const { tenantId, coCd, appReleaseId } = this.props.workspace;
    const state = { ...this.state };
    this.setState({
      isLoading: true,
    });
    WorkflowService.getWorkflowByServiceId(
      { ...this.state, tenantId, coCd, appReleaseId },
      async (res) => {
        if (!res.data)
          return Message.alert(
            "There is no service registered with the ID.",
            Enums.MessageType.WARN
          );
        // 호출한 service로 부터 inputList, outputList 호출
        const { inputDataType, inputList, outputList } = getInOutputEntity(
          res.data
        );
        // 이전기록 (파일에서 가져왔을때) 그대로 유지 후 merge 진행
        const prevInputMapping = [...state.inputMapping];
        const prevOutputMapping = [...state.outputMapping];

        state.isLoading = false;
        state.serviceId = res.data.serviceId;
        state.inputDataType = inputDataType;

        //파일에서 가져왔을때 데이터가 있으면 해당 데이터 넣고 없으면 공백 처리
        state.inputMapping = inputList.map((input, index) => {
          return {
            inputId: input.inputVariable,
            inputNm: input.description,
            value: prevInputMapping[index] ? prevInputMapping[index].value : "",
          };
        });
        state.outputMapping = outputList.map((outputValue, index) => {
          if (typeof outputValue === "string") {
            return {
              entityNm: "",
              entityVariable: outputValue,
              value: prevOutputMapping[index]
                ? prevOutputMapping[index]
                : outputValue,
            };
          } else {
            return {
              entityNm: outputValue.entityNm,
              entityVariable: outputValue.entityVariable,
              value: prevOutputMapping[index]
                ? prevOutputMapping[index].entityVariable
                : outputValue.entityVariable,
            };
          }
        });
        delete state.incompleteYn;
        this.setState(state);
      },
      () => {
        this.setState({
          isLoading: false,
        });
      }
    );
  };

  onOpenOutputDesc = () => {
    Popup.open(<ReturnEntityDesc />, {
      style: { content: { width: "550px" } },
    });
  };

  renderFooter() {
    return (
      <>
        {StringUtils.equalsIgnoreCase(this.state.incompleteYn, "Y") && (
          <UModal.Footer.ProgressButton
            side="left"
            onClick={this.onClickServiceConnect}
            variant="outline-success"
            doing={this.state.isLoading}
            doingText="Loading"
          >
            Connect Service
          </UModal.Footer.ProgressButton>
        )}

        <UModal.Footer.Button onClick={this.validateCommonParam}>
          Confirm
        </UModal.Footer.Button>
      </>
    );
  }

  renderBody() {
    return (
      <>
        <Row className="mb-3">
          <Col xs={2} className="col-label ">
            <Form.Label className="required">Select Service</Form.Label>
          </Col>
          <Col xs={5}>
            <InputGroup>
              <Form.Control
                value={this.state.serviceId}
                placeholder="Please select a Service."
                readOnly
                onChange={(e) => {}}
                size="sm"
              />
              {!StringUtils.isEmpty(this.state.serviceId) ? (
                <Tooltip
                  title="Update Service In & Output Info"
                  placement="top"
                >
                  <Button
                    size="sm"
                    variant="outline-success"
                    onClick={this.onRefreshService}
                  >
                    <BiRefresh size={20} />
                  </Button>
                </Tooltip>
              ) : (
                <></>
              )}

              <Button
                size="sm"
                variant="outline-secondary"
                onClick={this.onOpenWorkflowList}
              >
                <AiOutlineEllipsis size={20} />
              </Button>
            </InputGroup>
          </Col>
          <Col xs={5}>
            <Form.Control
              value={this.state.serviceName}
              onChange={(e) => {}}
              placeholder="Service Name"
            />
          </Col>
        </Row>
        <Row className="grid-title">
          <Col xs={10}>
            <Form.Label>
              Input Mapping
              <span style={{ color: "limegreen" }}> Before </span>
              Service Execution
            </Form.Label>
          </Col>

          <Col xs={2} className="fr">
            <Button variant="outline-primary" onClick={this.onAddInput}>
              Add Field
            </Button>
          </Col>
        </Row>
        <div className="grid-wrapper">
          <div className="grid-body">
            <Row>
              <Col className="header" xs={4}>
                Input Field ID
              </Col>
              <Col className="header" xs={4}>
                Input Field Name
              </Col>
              <Col className="header" xs={4}>
                Value
              </Col>
            </Row>
            {this.state.inputMapping.map((field, idx) => {
              return (
                <Row>
                  <Col xs={4} className="cell">
                    <Form.Control
                      size={"sm"}
                      id={"inputId"}
                      onChange={(e) => this.onChangeInputField(e, idx)}
                      value={field.inputId}
                    />
                  </Col>
                  <Col xs={4} className="cell">
                    <Form.Control
                      size={"sm"}
                      id={"inputNm"}
                      onChange={(e) => this.onChangeInputField(e, idx)}
                      value={field.inputNm}
                    />
                  </Col>
                  <Col xs={4} className="cell">
                    <InputGroup>
                      <Form.Control
                        size={"sm"}
                        id={"value"}
                        onChange={(e) => this.onChangeInputField(e, idx)}
                        value={field.value}
                      />
                      <Button
                        size="sm"
                        variant="outline-secondary"
                        name="value"
                        onClick={(event) =>
                          this.onOpenInputDataBindingPopup({
                            event,
                            type: START_PROCESS_DATA_TYPE.ENTITY_FIELD,
                            index: idx,
                          })
                        }
                      >
                        <AiOutlineEllipsis />
                      </Button>
                      <Button
                        variant="danger"
                        size="sm"
                        onClick={(e) => this.onDeleteInput(e, idx)}
                      >
                        <MdDelete />
                      </Button>
                    </InputGroup>
                  </Col>
                </Row>
              );
            })}
          </div>
        </div>

        <div className="grid-title">
          <div>
            <Form.Label>
              Output Mapping
              <span style={{ color: "tomato" }}> After </span>
              Service Execution
            </Form.Label>
            <span
              style={{
                marginLeft: "10px",
                textDecoration: "underline",
                cursor: "pointer",
              }}
              onClick={this.onOpenOutputDesc}
            >
              <AiOutlineQuestionCircle />
            </span>
          </div>
        </div>
        <div className="grid-wrapper">
          <div className="grid-body">
            <Row>
              <Col className="header" xs={3}>
                Entity Variables
              </Col>
              <Col className="header" xs={3}>
                Field ID
              </Col>
              <Col className="header" xs={3}>
                Entity Name
              </Col>
              <Col className="header" xs={3}>
                Return Entity
              </Col>
            </Row>
            {(this.state.outputMapping && this.state.outputMapping.length) >
            0 ? (
              <>
                {this.state.outputMapping.map((output, idx) => {
                  return (
                    <Row key={output.value}>
                      <Col className="cell" xs={3}>
                        <InputGroup>
                          <Form.Control
                            size={"sm"}
                            value={output.entityVariable}
                            id={"entityVariable"}
                            onChange={(e) => this.onChangeOutputField(e, idx)}
                          />
                          <Button
                            size="sm"
                            variant="outline-secondary"
                            name="value"
                            onClick={(event) => {
                              const rtList = this.returnNodeTypeList.filter(
                                (type) =>
                                  !StringUtils.includes(type, [
                                    Enums.WorkflowProcessType.SELECT_ENTITY,
                                    Enums.WorkflowProcessType
                                      .SELECT_ENTITY_BY_QUERY,
                                  ])
                              );
                              this.onOpenDataBindingPopup(event, idx, {
                                callback: this.onChangeOutputMapping,
                                processType: rtList,
                              });
                            }}
                          >
                            {" "}
                            <AiOutlineEllipsis />
                          </Button>
                        </InputGroup>
                      </Col>
                      <Col className="cell" xs={3}>
                        <Form.Control
                          size={"sm"}
                          value={output.fieldId}
                          id={"fieldId"}
                          onChange={(e) => this.onChangeOutputField(e, idx)}
                        />
                      </Col>
                      <Col className="cell" xs={3}>
                        <Form.Control
                          size={"sm"}
                          value={output.entityNm}
                          id={"entityNm"}
                          onChange={(e) => this.onChangeOutputField(e, idx)}
                        />
                      </Col>
                      <Col className="cell" xs={3}>
                        {/* 입력 후 포커스를 잃는 오류가 있어서 blur로 대체함. 오류 사유 불명 */}
                        <Form.Control
                          size={"sm"}
                          defaultValue={output.value}
                          onBlur={(e) =>
                            this.onChangeOutputField(
                              {
                                target: { id: "value", value: e.target.value },
                              },
                              idx
                            )
                          }
                        />
                      </Col>
                    </Row>
                  );
                })}
              </>
            ) : (
              <>
                <Row>
                  <Col xs={4} />
                  <Col
                    xs={4}
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <Form.Label>No Return Entity Found.</Form.Label>
                  </Col>
                  <Col xs={4} />
                </Row>
              </>
            )}
          </div>
        </div>
      </>
    );
  }
}

export default ServicePopup;
