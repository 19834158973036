import { Checkbox, FormControlLabel, Tooltip } from "@mui/material";
import { Enums } from "components/builder/BuilderEnum";
import ModuleSelector from "components/common/element/ModuleSelector";
import Message from "components/common/Message";
import Modal from "components/common/modal/UModal";
import UmodalTemplate from "components/common/modal/UModalTemplate";
import Popup from "components/common/Popup";
import ArrayUtils from "components/common/utils/ArrayUtils";
import CommonUtils from "components/common/utils/CommonUtils";
import ObjectUtils from "components/common/utils/ObjectUtils";
import StringUtils from "components/common/utils/StringUtils";
import User from "components/common/utils/UserUtils";
import NewWorkspacePopup from "page/popup/workspace/NewWorkspacePopup";
import { useEffect, useRef, useState } from "react";
import {
  ButtonGroup,
  Col,
  Form,
  InputGroup,
  Row,
  ToggleButton,
} from "react-bootstrap";
import { AiOutlineSetting } from "react-icons/ai";
import { FaTrash } from "react-icons/fa";
import AppService from "services/common/AppService";
import LocalService from "services/common/LocalService";
import SettingService from "services/common/SettingService";

function WorkspacePopup({ callback, goToSetting, ...props }) {
  const [applicationList, setApplicationList] = useState([]);
  const [module, setModule] = useState({});
  const [releaseList, setReleaseList] = useState([]);
  const [tenantList, setTenantList] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [prevWorkspaceList, setPrevWorkspaceList] = useState([]);
  const [autoPath, setAutoPath] = useState(false);
  const [preferWorkspaceList, setPreferWorkspaceList] = useState([]);
  const [appEnvList, setAppEnvList] = useState([]);

  //최근사용한 목록
  const [historyView, setHistoryView] = useState("prefer");

  const [appId, _setAppId] = useState("");
  const [moduleCd, _setModuleCd] = useState("");
  const [appReleaseId, _setAppReleaseId] = useState("");
  const [resource, _setResource] = useState("");
  const [tenantId, _setTenantId] = useState("");
  const [coCd, _setCoCd] = useState("");
  const [appEnv, _setAppEnv] = useState("");

  //팝업데이터 최초 로드시 프로미스 체인에서 state값이 제대로 전달되지 않아서 추가함
  const workspaceInfo = useRef({
    appId: "",
    moduleCd: "",
    appReleaseId: "",
    resource: "",
    tenantId: "*",
    coCd: "*",
  });

  const setAppId = (value) => {
    _setAppId(value);
    workspaceInfo.current["appId"] = value;
    //앱 환경 호출
    if (value) {
      AppService.getAppEnvList({ appId: value }, (res) => {
        if (res.data) {
          //개발 환경 우선
          const devList = res.data.filter((env) => {
            delete env.appMst;
            return env.envType === Enums.EnvType.DEV;
          });
          setAppEnvList(devList);
          if (!ArrayUtils.isEmpty(devList)) setAppEnv(devList[0].appEnvId);
        }
      });
    }
  };
  const setModuleCd = (value) => {
    _setModuleCd(value);
    workspaceInfo.current["moduleCd"] = value;
  };
  const setAppReleaseId = (value) => {
    _setAppReleaseId(value);
    workspaceInfo.current["appReleaseId"] = value;
  };
  const setResource = (value) => {
    _setResource(value);
    workspaceInfo.current["resource"] = value;
  };
  const setTenantId = (value) => {
    _setTenantId(value);
    workspaceInfo.current["tenantId"] = value;
  };
  const setCoCd = (value) => {
    _setCoCd(value);
    workspaceInfo.current["coCd"] = value;
  };
  const setAppEnv = (value) => {
    _setAppEnv(value);
    workspaceInfo.current["appEnv"] = value;
  };

  useEffect(() => {
    // 1. 기존에 실행했던 목록 호출 , 아이디 다르면 스토리지 삭제
    const prevWorkspaces = LocalService.get(
      Enums.LocalStorageName.WORKSPACE_HISTORY
    );
    const MyConfiguration = User.getConfiguration(
      Enums.ConfigurationDefine.GENERAL
    );
    if (
      MyConfiguration &&
      MyConfiguration[Enums.ConfigurationDefine.WORKSPACE]
    ) {
      setPreferWorkspaceList(
        MyConfiguration[Enums.ConfigurationDefine.WORKSPACE] || []
      );
    }

    getAppTypeList().then(() => {
      if (prevWorkspaces) {
        if (prevWorkspaces.list && prevWorkspaces.list.length > 0) {
          setHistoryView("recent");
          setPrevWorkspaceList(prevWorkspaces.list);
          //최근 작업한 워크 스페이스 자동세팅
          const body = {
            ...prevWorkspaces.list[0],
          };
          setAppId(prevWorkspaces.list[0].appId);
          setModuleCd(prevWorkspaces.list[0].moduleCd);
          getAppModule({
            moduleCd: prevWorkspaces.list[0].moduleCd,
            appId: prevWorkspaces.list[0].appId,
          })
            .then(getReleaseList(body))
            .then((list) => {
              setAppReleaseId(prevWorkspaces.list[0].appReleaseId);
            })
            .then(() => {
              if (
                StringUtils.equalsIgnoreType(
                  prevWorkspaces.list[0].tenantId,
                  "*"
                ) &&
                StringUtils.equalsIgnoreType(prevWorkspaces.list[0].coCd, "*")
              ) {
                setResource("s");
              } else {
                setResource("c");
              }
              return getTenantList(body);
            })
            .then((list) => {
              setTenantId(prevWorkspaces.list[0].tenantId);
              return getCocdList(body);
            })
            .then((list) => {
              setCoCd(prevWorkspaces.list[0].coCd);
            });
        } else {
          setHistoryView("prefer");
        }
      } else {
        setHistoryView("prefer");
      }
    });
  }, []);

  /**
   * Application option & module List 목록 세팅
   */
  const getAppTypeList = () => {
    return new Promise((resolve, reject) => {
      AppService.getAppList(
        {},
        (res) => {
          if (StringUtils.equalsIgnoreCase(User.getAuthType(), "S")) {
            setApplicationList(res.data);
          } else {
            const appIds = User.selectAvailableApps();
            const availList = [...res.data].filter(
              (a) =>
                appIds.indexOf(a.appId) > -1 ||
                CommonUtils.getAppConfig(a, "appAccessibleYn") === "Y"
            );
            setApplicationList(availList);
          }
          if (res.isError) {
            resolve(true);
          } else {
            resolve(false);
          }
        },
        reject
      );
    });
  };

  /**
   * 릴리즈 버전 목록 호출
   * @param {*} _body
   * @returns
   */
  const getReleaseList = (_body = {}) => {
    const body = {
      appId: _body.appId ? _body.appId : workspaceInfo.current.appId,
      moduleCd: _body.moduleCd
        ? _body.moduleCd
        : workspaceInfo.current.moduleCd,
      tenantId: _body.tenantId
        ? _body.tenantId
        : workspaceInfo.current.tenantId,
      coCd: _body.coCd ? _body.coCd : workspaceInfo.current.coCd,
    };

    //버전 목록 불러오기
    return new Promise((resolve, reject) => {
      AppService.getReleaseList(body, (res) => {
        setReleaseList(res.data);
        if (res.data.length === 1) {
          setAppReleaseId(res.data[0].appReleaseId);
          resolve(res.data);
        } else {
          resolve(res.data);
        }
      });
    });
  };

  //모듈 코드와 앱 아이디에 맞는 모듈 불러오기
  const getAppModule = (_body = {}) => {
    const body = {
      appId: _body.appId ? _body.appId : workspaceInfo.current.appId,
      moduleCd: _body.moduleCd
        ? _body.moduleCd
        : workspaceInfo.current.moduleCd,
    };
    return new Promise((resolve, reject) => {
      AppService.getAppModule(body, (res) => {
        setModule(res.data);
        resolve(res.data);
      });
    });
  };

  /**
   * 테넌트 목록 호출
   */
  const getTenantList = (_body = {}) => {
    const body = {
      appId: _body.appId ? _body.appId : workspaceInfo.current.appId,
      moduleCd: _body.moduleCd
        ? _body.moduleCd
        : workspaceInfo.current.moduleCd,
      appReleaseId: _body.appReleaseId
        ? _body.appReleaseId
        : workspaceInfo.current.appReleaseId,
    };
    return new Promise((resolve, reject) => {
      return AppService.getTenantList(body, (res) => {
        res.data.sort((a, b) => {
          if (a.tenantId === "*") {
            return -1;
          } else if (b.tenantId === "*") {
            return 1;
          }
        });
        setTenantList(res.data);
        resolve(res.data);
      });
    });
  };
  /**
   * 회사코드 목록 호출
   */
  const getCocdList = (_body = {}) => {
    const body = {
      appId: _body.appId ? _body.appId : workspaceInfo.current.appId,
      moduleCd: _body.moduleCd
        ? _body.moduleCd
        : workspaceInfo.current.moduleCd,
      appReleaseId: _body.appReleaseId
        ? _body.appReleaseId
        : workspaceInfo.current.appReleaseId,
      tenantId: _body.tenantId
        ? _body.tenantId
        : workspaceInfo.current.tenantId,
    };
    return new Promise((resolve, reject) => {
      return AppService.getCompanyList(body, (res) => {
        setCompanyList(res.data);
        resolve(res.data);
      });
    });
  };

  /**
   * 리소스 체인져
   * 리소스 바뀔때 기본값 채우는 함수
   * @param {*} e
   */
  const onChangeResource = (e) => {
    setResource(e.target.value);
    if (e.target.value === "s") {
      setCoCd("*");
      setTenantId("*");
    } else {
      //테넌트 목록 가져오기
      getTenantList();
    }
  };

  /**
   * App 이 바뀔때
   * @param {*} e
   */
  const onChangeAppId = (e) => {
    setAppId(e.target.value);
    setModuleCd("");
    setAppReleaseId("");
    setResource("S");
  };

  /**
   * 모듈 채인쳐
   * @param {*} e
   */
  const onChangeModule = (e) => {
    setModuleCd(e.target.value);
    setAppReleaseId("");
    if (!StringUtils.isEmpty(e.target.value)) {
      const body = {
        moduleCd: e.target.value,
        coCd: "*",
        tenantId: "*",
      };
      if (!ArrayUtils.isEmpty(prevWorkspaceList)) {
        body.coCd = prevWorkspaceList[0].coCd || "*";
        body.tenantId = prevWorkspaceList[0].coCd || "*";
      }
      //버전 목록 불러오기

      getAppModule({ moduleCd: e.target.value });
      getReleaseList(body)
        .then(() => {
          const coBody = {
            appId: workspaceInfo.current.appId,
            moduleCd: e.target.value,
            tenantId: "*",
            coCd: "*",
          };
          return getCocdList(coBody);
        })
        .then((re) => {
          if (!ArrayUtils.isEmpty(re) && re.length === 1) {
            setTenantId(re[0].tenantId);
            setCoCd(re[0].coCd);
          }
        });
    }
  };

  /**
   * 버전 체인져
   * @param {*} e
   */
  const onChangeVersion = (e, body = {}) => {
    setAppReleaseId(e.target.value);
    if (e.target.value) {
      //버전 선택시 표준 버전을 우선적으로 가져옴

      const _body = {
        appId: body.appId ? body.appId : workspaceInfo.current.appId,
        moduleCd: body.moduleCd
          ? body.moduleCd
          : workspaceInfo.current.moduleCd,
        appReleaseId: e.target.value,
        tenantId: "*",
      };
      AppService.getCompanyList(_body, (res) => {
        if (!ObjectUtils.isEmpty(body)) {
          if (body.tenantId === "*" && body.coCd === "*") {
            setResource("s");
            setCoCd("*");
            setTenantId("*");
          } else {
            setResource("c");
            setCoCd(body.coCd);
            setTenantId(body.tenantId);
          }
        } else {
          setResource("s");
          setCoCd("*");
          setTenantId("*");
        }

        setCompanyList(res.data);
        if (res.data.length === 0) {
          Message.alert(
            "There is no standard data for this version. Please contact the administrator.",
            Enums.MessageType.WARN
          );
        }
      });
    }
  };

  /**
   * 테넌트 체인져
   * @param {*} e
   */
  const onChangeTenant = (e) => {
    setTenantId(e.target.value);

    if (StringUtils.equalsIgnoreType("*", e.target.value)) {
      setCoCd("*");
    } else if (StringUtils.isEmpty(e.target.value)) {
      setCoCd("");
    } else {
      getCocdList({ tenantId: e.target.value });
    }
  };
  /**
   * 회사 코드 체인져
   * @param {*} e
   */
  const onChangeCoCd = (e) => {
    const companyCode = e.target.value;
    setCoCd(companyCode);
  };

  const onSetWorkspace = (workspace) => {
    if (callback) {
      //로컬스토리지에 저장
      callback(workspace);
      Popup.close();
    } else {
      Message.alert("Please set workspace callback Function.");
    }
  };
  /**
   * 워크 스페이스 선택
   * @param {*} e
   * @returns
   */
  const onSelectWorkspace = (e) => {
    if (e) e.preventDefault();

    if (!appId)
      return Message.alert(
        "Please select Application.",
        Enums.MessageType.INFO
      );
    if (!moduleCd)
      return Message.alert(
        "Please select module code.",
        Enums.MessageType.INFO
      );
    if (!appReleaseId)
      return Message.alert("Please select Version.", Enums.MessageType.INFO);
    if (StringUtils.equalsIgnoreCase(resource === "s")) {
    } else {
      if (!tenantId)
        return Message.alert(
          "Please select Tenant ID.",
          Enums.MessageType.INFO
        );
      if (!coCd)
        return Message.alert(
          "Please select Company Code.",
          Enums.MessageType.INFO
        );
    }
    const tenantMst = companyList.find(
      (c) =>
        StringUtils.equalsIgnoreType(c.appId, appId) &&
        StringUtils.equalsIgnoreType(c.moduleCd, moduleCd) &&
        StringUtils.equalsIgnoreType(c.appReleaseId, appReleaseId) &&
        StringUtils.equalsIgnoreType(c.tenantId, tenantId) &&
        StringUtils.equalsIgnoreType(c.coCd, coCd)
    );

    if (tenantMst) {
      const application = applicationList.find((a) =>
        StringUtils.equalsIgnoreType(a.appId, appId)
      );

      const release = releaseList.find((v) =>
        StringUtils.equalsIgnoreType(v.appReleaseId, appReleaseId)
      );
      const body = {
        tenantMstId: tenantMst.tenantMstId,
        appId,
        appNm: application.appNm,
        appGroupCd: application.appGroupCd,
        appGroupNm: application.appGroupNm,
        moduleCd,
        moduleNm: module.moduleNm,
        moduleOption: JSON.parse(module.moduleOption),
        appReleaseId,
        version: release.version,
        tenantId,
        tenantNm: tenantMst.tenantNm,
        coCd,
        coNm: tenantMst.coNm,
        config: application.config,
        appEnv: appEnvList?.find((app) =>
          StringUtils.equalsIgnoreType(app.appEnvId, appEnv)
        ),
      };
      onSetWorkspace(body);
      if (autoPath) {
        const serviceBody = {
          configurationCode: Enums.ConfigurationDefine.AUTO_PATH,
          configurationValue: JSON.stringify(body),
        };
        SettingService.saveUserConfig(serviceBody, (res) => {
          const key = res.data.configurationCode;
          const value = JSON.parse(res.data.configurationValue);
          User.overrideConfig(key, value);
        });
      }
    } else {
      return Message.alert(
        "There are unspecified selection values. Please refresh and try again.",
        Enums.MessageType.INFO
      );
    }
  };

  const newWorkspacePopupOpen = (e) => {
    if (!appId || !moduleCd || !appReleaseId) {
      return Message.alert(
        "Application, Module Code, Version is Required.",
        Enums.MessageType.ERROR
      );
    }
    const options = {
      effect: Popup.ScaleUp, //Effect.SlideFromTop(default)를 Effect.ScaleUp 로 변경
      style: {
        content: {
          width: "500px", //popup의 크기를 50% (default 60%)
        },
      },
    };
    const afterAddWorkspace = (tenantMst) => {
      setCoCd(tenantMst.coCd);
      setTenantId(tenantMst.tenantId);
      if (StringUtils.equalsIgnoreType("*", tenantMst.tenantId)) {
        //표준 모드 만들었을때
        setResource("s");
        setCompanyList([tenantMst]);
      } else {
        onChangeResource({ target: { value: "c" } });
        setCompanyList([tenantMst]);
      }
    };

    Popup.open(
      <NewWorkspacePopup
        appId={appId}
        appList={applicationList}
        moduleCd={moduleCd}
        releaseId={appReleaseId}
        releaseList={releaseList}
        callback={afterAddWorkspace}
      />,
      options
    );
  };

  const onDeleteQuickButton = (idx) => {
    const newList = [...prevWorkspaceList];
    newList.splice(idx, 1);
    setPrevWorkspaceList(newList);
    LocalService.set(Enums.LocalStorageName.WORKSPACE_HISTORY, {
      userId: User.getId(),
      list: newList,
    });
  };

  return (
    <Modal>
      <Modal.Header title="DAAF Builder Workspace" />
      <Modal.Body>
        <UmodalTemplate className="workspace">
          <Row className="mb-3">
            <Col xs={4}>
              <Form.Group>
                <Form.Label className="required">Application</Form.Label>
                <Form.Select value={appId} onChange={onChangeAppId}>
                  <option value="">Select APP</option>
                  {applicationList.map((app) => {
                    return (
                      <option value={app.appId} key={app.appId}>
                        {app.appNm}
                      </option>
                    );
                  })}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col xs={4}>
              <Form.Group>
                <Form.Label className="required">Module</Form.Label>
                <InputGroup>
                  <ModuleSelector
                    value={moduleCd}
                    onChange={onChangeModule}
                    type="u"
                    id={"moduleId"}
                    showEmpty
                    disabled={!appId}
                    appId={appId}
                    placeholder={
                      appId ? "Select Module" : "Please select Application."
                    }
                  />
                </InputGroup>
              </Form.Group>
            </Col>
            <Col xs={4}>
              <Form.Group>
                <Form.Label className="required">Version</Form.Label>
                <InputGroup>
                  <Form.Select
                    disabled={!moduleCd}
                    placeholder="Please select Module."
                    value={appReleaseId}
                    onChange={onChangeVersion}
                  >
                    {releaseList.length > 0 ? (
                      <>
                        <option value={""}>Select</option>
                        {releaseList.map((release) => {
                          return (
                            <option
                              value={release.appReleaseId}
                              key={release.appReleaseId}
                            >
                              {release.version}
                            </option>
                          );
                        })}
                      </>
                    ) : (
                      <option>No registered Version exists.</option>
                    )}
                  </Form.Select>
                </InputGroup>
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col xs={4}>
              <Form.Group>
                <Form.Label className="required">Resource Type</Form.Label>
                <InputGroup>
                  {/* 버전 선택시 표준 테넌트 목록을 가져오는데 없으면 비활성화 -> 표준테넌트 목록 없음 = 해당 버전 앱 실행 불가 */}
                  <Form.Select
                    value={resource}
                    onChange={onChangeResource}
                    disabled={!appReleaseId || companyList.length === 0}
                  >
                    <option value={"s"}>Standard</option>
                    <option value={"c"}>Custom</option>
                  </Form.Select>
                </InputGroup>
              </Form.Group>
            </Col>
            {StringUtils.equalsIgnoreCase(resource, "c") && (
              <>
                <Col xs={4}>
                  <Form.Group>
                    <Form.Label className="required">Tenant ID</Form.Label>
                    <InputGroup>
                      <Form.Select value={tenantId} onChange={onChangeTenant}>
                        <option value="">Select Tenant</option>
                        {tenantList.map((tenant) => {
                          return (
                            <option
                              value={tenant.tenantId}
                              key={tenant.tenantId}
                            >
                              {tenant.tenantId}({tenant.tenantNm})
                            </option>
                          );
                        })}
                      </Form.Select>
                    </InputGroup>
                  </Form.Group>
                </Col>
                <Col xs={4}>
                  <Form.Group>
                    <Form.Label className="required">Company Code</Form.Label>
                    <InputGroup>
                      <Form.Select
                        value={coCd}
                        onChange={onChangeCoCd}
                        disabled={
                          StringUtils.equalsIgnoreType(tenantId, "*") ||
                          StringUtils.isEmpty(tenantId)
                        }
                      >
                        <option value="">Select Company Code</option>
                        {companyList.map((co) => {
                          return (
                            <option value={co.coCd} key={co.coCd}>
                              {co.coCd}({co.coNm})
                            </option>
                          );
                        })}
                      </Form.Select>
                    </InputGroup>
                  </Form.Group>
                </Col>
              </>
            )}
            <Col xs={4}>
              <Form.Group>
                <Form.Label>Dev Enviroment</Form.Label>
                <InputGroup>
                  <Form.Select
                    value={appEnv}
                    onChange={(e) => setAppEnv(e.target.value)}
                    disabled={!appId}
                  >
                    <option value="">Select App Dev Enviroment</option>
                    {appEnvList.map((app) => {
                      return (
                        <option value={app.appEnvId} key={app.appEnvId}>
                          {app.envName}
                        </option>
                      );
                    })}
                  </Form.Select>
                </InputGroup>
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col xs={12} className="default-path-toggle">
              <Tooltip title={"Able to edit on Settings"} placement="left">
                <FormControlLabel
                  label={"Use as default and do not ask again"}
                  control={
                    <Checkbox
                      checked={autoPath}
                      onChange={(e) => setAutoPath(e.target.checked)}
                    />
                  }
                />
              </Tooltip>
            </Col>
          </Row>
          <Row className="mb-3 gy-1">
            <Col xs={12} className="history-list">
              <ButtonGroup>
                <ToggleButton
                  id="historyTab1"
                  name="historyTab1"
                  type="radio"
                  variant="outline-dark"
                  value="recent"
                  checked={historyView === "recent"}
                  onChange={(e) => setHistoryView(e.currentTarget.value)}
                  size="sm"
                >
                  Recently Used
                </ToggleButton>
                <ToggleButton
                  id="historyTab2"
                  name="historyTab2"
                  type="radio"
                  variant="outline-dark"
                  value="prefer"
                  checked={historyView === "prefer"}
                  onChange={(e) => setHistoryView(e.currentTarget.value)}
                  size="sm"
                >
                  Favorites
                </ToggleButton>
              </ButtonGroup>{" "}
              <span>Workspace ▼</span>
              {historyView === "prefer" && (
                <Tooltip title="Manage Favorites" placement="top">
                  <span
                    onClick={(e) => {
                      if (goToSetting) goToSetting(Enums.BuilderPath.SETTING);
                    }}
                    className="setting-btn"
                  >
                    <AiOutlineSetting size={20} />
                  </span>
                </Tooltip>
              )}
            </Col>
            {historyView === "recent"
              ? prevWorkspaceList.map((workspace, idx) => {
                  return (
                    <Col
                      xs={12}
                      key={workspace.tenantMstId}
                      className="workspace-list-contents"
                    >
                      <span>{idx + 1}&nbsp;.&nbsp;</span>
                      <span
                        className="quick-button"
                        onClick={(e) => onSetWorkspace(workspace)}
                      >
                        {AppService.getWorkspaceName(workspace)}
                      </span>
                      <div
                        style={{ marginLeft: "10px" }}
                        onClick={(e) => onDeleteQuickButton(idx)}
                      >
                        <FaTrash color="tomato" />
                      </div>
                    </Col>
                  );
                })
              : preferWorkspaceList.length > 0 &&
                preferWorkspaceList.map((workspace) => {
                  return (
                    <Col
                      xs={12}
                      key={workspace.tenantMstId}
                      className="workspace-list-contents"
                    >
                      <span>⭐&nbsp;.&nbsp;</span>
                      <span
                        className="quick-button"
                        onClick={(e) => onSetWorkspace(workspace)}
                      >
                        {AppService.getWorkspaceName(workspace)}
                      </span>
                    </Col>
                  );
                })}
          </Row>
        </UmodalTemplate>
      </Modal.Body>
      <Modal.Footer>
        <Modal.Footer.Button
          side="left"
          variant="success"
          onClick={newWorkspacePopupOpen}
        >
          Add New Workspace
        </Modal.Footer.Button>
        <Modal.Footer.Button onClick={onSelectWorkspace}>
          Confirm
        </Modal.Footer.Button>
      </Modal.Footer>
    </Modal>
  );
}

export default WorkspacePopup;
