import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from "@mui/lab";
import { CircularProgress } from "@mui/material";
import { Enums } from "components/builder/BuilderEnum";
import CommonUtils from "components/common/utils/CommonUtils";
import WorkflowHistoryAccordionContents from "components/builder/workflow/history/WorkflowHistoryAccordionContents";
import PageTemplate from "page/common/PageTemplate";
import React, { useEffect, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import { FaList } from "react-icons/fa";
import { FiMoreHorizontal } from "react-icons/fi";
import { MdOutlineUpdate } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import WorkflowService from "services/workflow/WorkflowService";
import styled from "styled-components";

const MoreButtonLine = styled.div`
  width: 100%;
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const LoadingTemp = styled.div`
  background-color: #3c3c3c55;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  z-index: 10;
`;

function WorkflowHistoryDetail() {
  const { serviceId, serviceUid, historyMstId } = useParams(); // => historyMstId 로 변경됨
  const navigate = useNavigate();

  const breadcrum = [
    { name: "Service History List", url: Enums.BuilderPath.HISTORY },
    { name: "Service History Detail", subName: serviceId, active: true },
  ];

  const [isLast, setIsLast] = useState(false);
  const [historyList, setHistoryList] = useState([]);
  const [displayList, setDisplayList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const pageNumber = useRef(0);

  useEffect(() => {
    pageNumber.current = 0;
    getHistoryDetailList(pageNumber.current, true);
    return () => {
      setHistoryList([]);
      setDisplayList([]);
    };
  }, [historyMstId]);

  const getHistoryDetailList = (pageNumber, init) => {
    const body = {
      historyMstId,
      pageNumber,
    };
    setIsLoading(true);
    WorkflowService.getHistoryDetailList(
      body,
      (res) => {
        const {
          data: { content: _historyList, last },
        } = res;

        if (_historyList.length > 0) {
          //separate with commit date in displayList
          let newList = [];
          if (init) {
            newList = [..._historyList];
          } else {
            newList = [...historyList, ..._historyList];
          }

          const wholeList = [];
          let tmpDate = "";
          let tmpList = [];
          newList.map((_program, idx) => {
            if (
              CommonUtils.getTZTime(_program.insertDt).get("y") ===
                CommonUtils.getTZTime(tmpDate).get("y") &&
              CommonUtils.getTZTime(_program.insertDt).get("month") ===
                CommonUtils.getTZTime(tmpDate).get("month") &&
              CommonUtils.getTZTime(_program.insertDt).get("d") ===
                CommonUtils.getTZTime(tmpDate).get("d")
            ) {
              // same day with tmpDate
              tmpList.push(_program);
            } else {
              // diff day with tmpDate
              if (tmpList.length > 0) {
                wholeList.push(tmpList);
              }
              tmpList = [];
              tmpList.push(_program);
              tmpDate = _program.insertDt;
            }

            if (idx === newList.length - 1 && tmpList.length > 0) {
              wholeList.push(tmpList);
            }
          });
          setHistoryList(_historyList);
          setDisplayList(wholeList);
        }
        setIsLast(last);
        setIsLoading(false);
      },
      (err) => {
        setIsLoading(false);
      }
    );
  };

  const onMoveToList = () => {
    navigate(Enums.BuilderPath.WORKFLOW_HISTORY);
  };

  const onClickNextPage = () => {
    pageNumber.current = pageNumber.current + 1;
    getHistoryDetailList(pageNumber.current);
  };

  return (
    <PageTemplate breadcrum={breadcrum}>
      <PageTemplate.Box boxClass="mb-0">
        <div className="box-control box-line">
          <Button variant="outline-dark" size="sm" onClick={onMoveToList}>
            <FaList /> List
          </Button>
        </div>
        {isLoading && (
          <LoadingTemp>
            <CircularProgress size={20} /> Loading...
          </LoadingTemp>
        )}
        <div style={{ maxHeight: "calc(100% - 100px)", overflow: "auto" }}>
          <Timeline align="left" position="right">
            {displayList.map((_dateProgram, idx) => {
              return (
                <TimelineItem key={idx}>
                  <TimelineOppositeContent
                    sx={{ flex: 0.1 }}
                    color="textSecondary"
                  >
                    <div style={{ marginTop: "7px" }}>
                      {CommonUtils.getDate(_dateProgram[0].insertDt)}
                    </div>
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineDot color="primary" style={{ fontSize: "larger" }}>
                      <MdOutlineUpdate />
                    </TimelineDot>
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>
                    {_dateProgram.map((_history) => {
                      return (
                        <WorkflowHistoryAccordionContents
                          service={_history}
                          key={_history.serviceHistoryId}
                          serviceUid={serviceUid}
                        />
                      );
                    })}
                  </TimelineContent>
                </TimelineItem>
              );
            })}
          </Timeline>
        </div>
        {!isLast && (
          <MoreButtonLine>
            <Button variant="outline-dark" onClick={onClickNextPage}>
              <FiMoreHorizontal /> More
            </Button>
          </MoreButtonLine>
        )}
      </PageTemplate.Box>
    </PageTemplate>
  );
}

export default WorkflowHistoryDetail;
