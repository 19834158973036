import {
  DaafMessage,
  DaafPopup,
} from "@alpha/com.bizentro.daaf.front.framework";
import { Grid } from "@mui/material";
import { Enums } from "components/builder/BuilderEnum";
import { AppContext } from "components/common/AppContextProvider";
import WijmoGrid from "components/common/element/WijmoGrid";
import Message from "components/common/Message";
import {
  ArrayUtils,
  ObjectUtils,
  StringUtils,
} from "components/common/utils/CommonUtils";
import { debounce } from "components/common/utils/InputUtils";
import User from "components/common/utils/UserUtils";
import produce from "immer";
import GlobalWorkflowPopup from "page/popup/setting/GlobalWorkflowPopup";
import { useContext, useEffect, useRef, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import SettingService from "services/common/SettingService";

function AppGloabalVariableManage() {
  const [selectedVariable, setSelectedVariable] = useState({});
  const [variableList, setVariableList] = useState([]);
  // const { appId } = useSelector((state) => state.workspace);
  const workspace = useSelector((state) => state.workspace);
  const entityVariableNameRef = useRef();
  const {
    workspace: { openPopup },
  } = useContext(AppContext);

  useEffect(() => {
    if (!ObjectUtils.isEmpty(workspace)) {
      getVariableList();
    }
  }, [workspace]);

  const onRowClick = (data) => {
    setSelectedVariable(data);
    if (entityVariableNameRef.current) {
      entityVariableNameRef.current.focus();
    }
  };

  const getVariableList = () => {
    SettingService.getVariableList({ appId: workspace.appId }, (res) => {
      const vList = [...res.data];
      const gloabalVariableObject = User.getGlobalVariable(
        workspace.tenantMstId
      );
      vList.forEach((v) => {
        v.jsVariable = `variable.${v.variableEntity}.${v.variableField}`;
        if (gloabalVariableObject) {
          if (
            gloabalVariableObject[v.variableEntity] &&
            gloabalVariableObject[v.variableEntity][v.variableField]
          ) {
            v.userValue =
              gloabalVariableObject[v.variableEntity][v.variableField];
          }
        }
      });

      setVariableList(vList);

      if (!ArrayUtils.isEmpty(vList)) {
        setSelectedVariable(vList[0]);
      }
    });
  };

  /**
   * 글로벌 서비스 목록 팝업 열기
   */
  const onClickServiceListPopup = () => {
    const callback = (varList) => {
      varList = varList.map((entity) => {
        const variableEntity = entity.entityVariable;
        return entity.entityFieldList.map((field) => {
          return {
            variable: `{${variableEntity}.${field.fieldId}}`,
            jsVariable: `variable.${variableEntity}.${field.fieldId}`,
            variableEntity: variableEntity,
            variableField: field.fieldId,
            variableDesc: field.fieldNm,
            service: field.service,
            variableId: StringUtils.getUuid(),
          };
        });
      });
      const vList = [];
      for (const vari of varList.flat()) {
        const isExist = variableList.findIndex(
          (v) => v.variable === vari.variable
        );
        if (isExist === -1) {
          vList.push(vari);
        }
      }
      setVariableList(vList);
      DaafPopup.close();
    };

    DaafPopup.open(
      <GlobalWorkflowPopup appId={workspace.appId} callback={callback} />,
      {
        style: { content: { width: "800px" } },
      }
    );
  };

  /**
   * 인풋값 전환
   * @param {*} e
   */
  const onChangeVariable = (e) => {
    const id = e.target.id;
    const value = e.target.value.trim();
    let variable = selectedVariable.variable;
    let jsVariable = selectedVariable.jsVariable;
    let variableEntity = selectedVariable.variableEntity;
    let variableField = selectedVariable.variableField;
    const index = variableList.findIndex(
      (v) => v.variableId === selectedVariable.variableId
    );
    if (StringUtils.equalsIgnoreCase(id, "variableEntity")) {
      variable = `{${value}.${variableField}}`;
      jsVariable = `variable.${value}.${variableField}`;
    } else if (StringUtils.equalsIgnoreCase(id, "variableField")) {
      variable = `{${variableEntity}.${value}}`;
      jsVariable = `variable.${variableEntity}.${value}`;
    }

    setSelectedVariable(
      produce(selectedVariable, (draft) => {
        draft.variable = variable;
        draft.jsVariable = jsVariable;
        draft[id] = value;
      })
    );
    debouceChange(e, index, variable);
  };

  /**
   * debounce change
   */
  const debouceChange = debounce((e, index, variable) => {
    const nVariableLise = [...variableList];
    nVariableLise[index][e.target.id] = e.target.value;
    nVariableLise[index].variable = variable;
    setVariableList(nVariableLise);
  }, 300);

  /**
   * 저장
   */
  const onClickSave = () => {
    if (ObjectUtils.isEmpty(workspace)) return;
    if (ArrayUtils.isEmpty(variableList))
      return DaafMessage.alert("No Variables in List for save", "warning");
    const vList = variableList.map((v) => {
      const obj = { ...v };
      if (typeof obj.variableId === "string") {
        obj.variableId = null;
      }
      obj.appId = workspace.appId;
      return obj;
    });

    SettingService.saveVariableList(
      { variableList: vList },
      (res) => {
        setVariableList(res.data);
        DaafMessage.alert("Saved Successfully.", "success");
      },
      (err) => {
        DaafMessage.alert(err.message, "error");
      }
    );
  };

  const onClickAdd = () => {
    const newVariable = {
      variableId: StringUtils.getUuid(),
      variable: "",
      variableEntity: "",
      variableField: "",
      variableDesc: "",
      appId: workspace.appId,
      userId: User.getId(),
    };
    setSelectedVariable(newVariable);
    setVariableList([...variableList, newVariable]);
    if (entityVariableNameRef.current) {
      entityVariableNameRef.current.focus();
    }
  };

  const onClickSingleSave = () => {
    const variable = { ...selectedVariable };
    const index = variableList.findIndex(
      (v) => v.variableId === selectedVariable.variableId
    );
    if (typeof variable.variableId === "string") {
      variable.variableId = null;
    }

    SettingService.saveVariable(variable, (res) => {
      const nvList = [...variableList];
      nvList[index] = res.data;
      setVariableList(nvList);
      setSelectedVariable(res.data);
      Message.alert("Saved Successfully", Enums.MessageType.SUCCESS);
    });
  };

  const onClickDelete = () => {
    const variableId = selectedVariable.variableId;
    const index = variableList.findIndex(
      (v) => v.variableId === selectedVariable.variableId
    );
    if (index > -1) {
      const nvList = [...variableList];
      if (typeof variableId !== "string") {
        Message.confirm("Are you sure to delete this Variable", () => {
          //DB에서 삭제
          SettingService.deleteVariable(
            selectedVariable,
            () => {
              //리스트에서 삭제
              nvList.splice(index, 1);
              setVariableList(nvList);
              setSelectedVariable({});
            },
            (err) => {
              Message.alert(err.message, "error");
            }
          );
        });
      } else {
        //리스트에서 삭제
        nvList.splice(index, 1);
        setVariableList(nvList);
        setSelectedVariable({});
      }
    } else {
      setSelectedVariable({});
    }
  };

  const onClickGetFromConnection = () => {
    const connection = User.getConnection(workspace.tenantMstId);
    if (!connection)
      return Message.alert("Connect server first", Enums.MessageType.INFO);
    const gloabalVariableObject = User.getGlobalVariable(workspace.tenantMstId);
    if (!gloabalVariableObject)
      return Message.alert(
        "No Data in connetion infomation",
        Enums.MessageType.INFO
      );
    const vList = [];

    for (const variableEntity in gloabalVariableObject) {
      for (const variableField in gloabalVariableObject[variableEntity]) {
        const variable = {
          variableId: StringUtils.getUuid(),
          variable: `{${variableEntity}.${variableField}}`,
          jsVariable: `variable.${variableEntity}.${variableField}`,
          variableEntity,
          variableField,
          variableDesc: "",
          appId: workspace.appId,
          userValue: gloabalVariableObject[variableEntity][variableField],
          userId: User.getId(),
        };
        const isExist = variableList.findIndex(
          (vari) => vari.variable === variable.variable
        );
        if (isExist === -1) {
          vList.push(variable);
        }
      }
    }

    setVariableList([...variableList, ...vList]);
  };

  const columns = [
    {
      field: "variable",
      headerName: "Global Variable",
      width: "*",
    },
    {
      field: "jsVariable",
      headerName: "Javascript Usage",
      width: "*",
    },
    {
      field: "variableEntity",
      headerName: "Entity Name",
      width: "*",
    },
    {
      field: "variableField",
      headerName: "Field Name",
      width: "*",
    },
    {
      field: "userValue",
      headerName: "User Specific value",
      width: "*",
    },
  ];

  return (
    <>
      {ObjectUtils.isEmpty(workspace) ? (
        <div className="workspace-empty-alert">
          <div className="alert-msg">Workspace configuration is required.</div>
          <Button onClick={() => openPopup()}>Open Popup</Button>
        </div>
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={7}></Grid>
          <Grid
            item
            xs={5}
            style={{
              gap: "5px",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              onClick={onClickGetFromConnection}
              variant="outline-primary"
            >
              Get From Connection Infomation
            </Button>
            <Button onClick={onClickServiceListPopup}>
              View Global Service List
            </Button>
            <Button onClick={onClickAdd} variant="outline-success">
              Add New Global Variable
            </Button>
          </Grid>
          <Grid item xs={8}>
            <WijmoGrid
              selectMode="Row"
              columns={columns}
              rows={variableList}
              style={{ height: "60vh" }}
              onRowClick={onRowClick}
              emptyMessage={"No Registered App Version"}
              selectedItem={selectedVariable}
            />
          </Grid>
          <Grid item xs={4} md={4}>
            <div className="application-detail" style={{ height: "60vh" }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Form.Label className="bold">Variable</Form.Label>
                  <Form.Control
                    value={selectedVariable.variable}
                    size={"sm"}
                    readOnly
                  />
                </Grid>
                <Grid item xs={12}>
                  <Form.Label className="bold">Javascript Variable</Form.Label>
                  <Form.Control
                    value={selectedVariable.jsVariable}
                    size={"sm"}
                    readOnly
                  />
                </Grid>
                <Grid item xs={12}>
                  <Form.Label>Entity Variable Name</Form.Label>
                  <Form.Control
                    value={selectedVariable.variableEntity}
                    id={"variableEntity"}
                    onChange={onChangeVariable}
                    size={"sm"}
                    ref={entityVariableNameRef}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Form.Label>Entity Field Variable Name</Form.Label>
                  <Form.Control
                    value={selectedVariable.variableField}
                    id={"variableField"}
                    onChange={onChangeVariable}
                    size={"sm"}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Form.Label>Variable Description</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={6}
                    value={selectedVariable.variableDesc || ""}
                    size={"md"}
                    id={"variableDesc"}
                    style={{ resize: "none" }}
                    onChange={onChangeVariable}
                  />
                </Grid>

                <Grid item xs={12}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      gap: "5px",
                    }}
                  >
                    <Button
                      variant="outline-primary"
                      onClick={onClickSingleSave}
                    >
                      Save Variable
                    </Button>
                    <Button variant="outline-danger" onClick={onClickDelete}>
                      Delete Variable
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      )}

      <div className="setting-button">
        <Button variant="primary" onClick={onClickSave}>
          Save Variables
        </Button>
      </div>
    </>
  );
}

export default AppGloabalVariableManage;
