import StringUtils from "components/common/utils/StringUtils";
import React, { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import DataModelMenu from "components/builder/entity/DataModelMenu";
import ProgramMenu from "components/builder/ui/ProgramMenu";
import MobileMenu from "components/builder/mobile/ProgramMenu";
import WorkflowMenu from "components/builder/workflow/WorkflowMenu";
import EventHandlerMenu from "components/builder/eventhandler/EventHandlerMenu";
import ProgramDesignMenu from "components/builder/programDesign/ProgramDesignMenu";

function ContextMenu({ isOpen, menu, contextMenuHandler, ...props }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const workspace = useSelector((state) => state.workspace);

  return (
    <div className={`contenxt-menu-wrapper ${isOpen ? "open" : "collapsed"}`}>
      {StringUtils.equalsIgnoreCase(menu.key, "EDITOR") && (
        <ProgramMenu
          menu={menu}
          navigate={navigate}
          dispatch={dispatch}
          workspace={workspace}
          contextHandler={contextMenuHandler}
        />
      )}
      {StringUtils.equalsIgnoreCase(menu.key, "MOBILE") && (
        <MobileMenu
          menu={menu}
          navigate={navigate}
          dispatch={dispatch}
          workspace={workspace}
          contextHandler={contextMenuHandler}
        />
      )}
      {StringUtils.equalsIgnoreCase(menu.key, "ENTITY") && (
        <DataModelMenu
          menu={menu}
          navigate={navigate}
          dispatch={dispatch}
          workspace={workspace}
          contextHandler={contextMenuHandler}
        />
      )}
      {StringUtils.equalsIgnoreCase(menu.key, "workflow") && (
        <WorkflowMenu
          menu={menu}
          navigate={navigate}
          dispatch={dispatch}
          workspace={workspace}
          contextHandler={contextMenuHandler}
        />
      )}
      {StringUtils.equalsIgnoreCase(menu.key, "eventhandler") && (
        <EventHandlerMenu
          menu={menu}
          navigate={navigate}
          dispatch={dispatch}
          workspace={workspace}
          contextHandler={contextMenuHandler}
        />
      )}
      {StringUtils.equalsIgnoreCase(menu.key, "ProgramDesign") && (
        <ProgramDesignMenu
          menu={menu}
          navigate={navigate}
          dispatch={dispatch}
          workspace={workspace}
          contextHandler={contextMenuHandler}
        />
      )}
    </div>
  );
}

export default ContextMenu;
