/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from "react";
import { BsPencilSquare } from "react-icons/bs";
import Popup from "components/common/Popup";
import Modal from "components/common/modal/UModal";
import styled from "styled-components";
import CodeMirror from "@uiw/react-codemirror";
import { javascript } from "@codemirror/lang-javascript";

import StringUtils from "components/common/utils/StringUtils";
import ArrayUtils from "components/common/utils/ArrayUtils";
import ObjectUtils from "components/common/utils/ObjectUtils";
import UmodalTemplate from "components/common/modal/UModalTemplate";
import { Button, Col, Form, Row } from "react-bootstrap";
import DataModelService from "services/datamodel/DataModelService";
import { MdLibraryAdd } from "react-icons/md";
import { FaTrash } from "react-icons/fa";
import produce from "immer";
import Message from "components/common/Message";
import UModalJavascriptEditor from "components/common/code/UModalJavascriptEditor";
import { useRef } from "react";

const MesFunPopupStyle = styled.div`
  .edit-source {
    border: 1px solid #ddd;
  }
`;

/**
 * PopupOpenConfigPopup : Popup 전처리
 *
 * 2022.04.28: init: donghoon
 * 2024.01.02 : Code Mirror 기능 사용 유지 위한 state -> Ref로 관리 하도록 수정
 * @resultMapping {*} props
 * @returns value
 */
const PopupPostHandleConfigPopup = (props) => {
  const {
    title,
    callbackFnc,
    item,
    entityId,
    setRetItems,
    onClickEventBuilder,
  } = props;

  const [popItems, setPopItems] = useState({});
  const [resultMapping, setResultMapping] = useState([]);
  // const [afterSubmit, setAfterSubmit] = useState("");
  const afterSubmitRef = useRef("");
  const [dataBindingList, setDataBindingList] = useState([]);

  const javascriptExt = [javascript(true)];
  const [extensions, setExtensions] = useState(javascriptExt);

  // closePopup: value값을 전달하고 popup 닫기
  const closePopup = (e) => {
    let retValue = { ...popItems };
    retValue.afterSubmit = afterSubmitRef.current;
    retValue.resultMapping = resultMapping;
    callbackFnc.call(this, retValue);
    Popup.close();
  };

  const onChangeAfterSubmit = (e) => {
    // setAfterSubmit(e.target.value);
    afterSubmitRef.current = e.target.value;
    onChangeSetRetItems(e);
  };

  useEffect(() => {
    let initItem = { ...(item || {}) };

    if (ObjectUtils.isEmpty(initItem.resultMapping)) {
      initItem.resultMapping = [{}];
    }
    setResultMapping(initItem.resultMapping);
    // setAfterSubmit(initItem.afterSubmit);
    afterSubmitRef.current = initItem.afterSubmit;
    setPopItems(initItem);

    selectDataBindingList(entityId);
  }, [entityId, item]);

  const onChangeResultMapping = (e, result, index) => {
    const newResultMapping = produce(resultMapping, (draft) => {
      if (StringUtils.isEmpty(e.target.value)) {
        delete draft[index][e.target.id];
      } else {
        draft[index] = { ...result, [e.target.id]: e.target.value };
      }
    });
    setResultMapping(newResultMapping);
    onChangeSetRetItems(e, "resultMapping", newResultMapping);
  };

  const onInsertClick = (e, result, index) => {
    const newResultMapping = produce(resultMapping, (draft) => {
      draft.splice(index + 1, 0, {});
    });
    setResultMapping(newResultMapping);
    onChangeSetRetItems(e, "resultMapping", newResultMapping);
  };

  const onDeleteClick = (e, result, index) => {
    const newResultMapping = produce(resultMapping, (draft) => {
      draft.splice(index, 1);
      if (draft.length === 0) {
        draft.splice(0, 0, {});
      }
    });
    setResultMapping(newResultMapping);
    onChangeSetRetItems(e, "resultMapping", newResultMapping);
  };

  /**
   * Data Model Entity Field List 조회(Data Binidng)
   * @param {*} entityId
   */
  const selectDataBindingList = (entityId) => {
    if (!StringUtils.isEmpty(entityId)) {
      DataModelService.getDataBindingList({ entityId: entityId }, (res) => {
        setDataBindingList(
          res.data ? res.data : [{ id: "none", text: "[none] None Data" }]
        );
      });
    }
  };

  /**
   * setRetItems : popupHandleConfigPopup에서 재 items 설정을 위해
   * @param {*} e
   * @param {*} pId
   * @param {*} pItem
   */
  const onChangeSetRetItems = (e, pId, pItem) => {
    if (setRetItems) {
      if (!StringUtils.isEmpty(pId)) {
        setRetItems.call(this, pId, pItem);
      } else {
        setRetItems.call(this, e.target.id, e.target.value);
      }
    }
  };

  return (
    <MesFunPopupStyle>
      <Modal>
        <Modal.Header title={title} />
        <Modal.Body>
          <UmodalTemplate>
            <Form.Group className="mb-3">
              <Form.Label>Output Mapping</Form.Label>
              <Col>
                <Row className="mb-2">
                  <Col>Output Column ID (From)</Col>
                  <Col>Data Binding Column (To)</Col>
                  <Col></Col>
                </Row>
                {!ArrayUtils.isEmpty(resultMapping)
                  ? resultMapping.map((mapping, index) => {
                      return (
                        <Row
                          // key 값을 통해 구분-> index로 설정시, 기존 index랑 중복 문제 발생 -> 난수 생성
                          key={Math.random()}
                          className="pb-2"
                          style={{ alignItems: "center" }}
                        >
                          <Col>
                            <input
                              type="text"
                              id="id"
                              className="form-control form-select-sm"
                              defaultValue={mapping.id}
                              onBlur={(e) =>
                                onChangeResultMapping(e, mapping, index)
                              }
                            />
                          </Col>
                          <Col>
                            <Form.Select
                              size="sm"
                              id="dataBinding"
                              onChange={(e) =>
                                onChangeResultMapping(e, mapping, index)
                              }
                              value={StringUtils.defaultString(
                                mapping.dataBinding
                              )}
                            >
                              <option value={""}>Select</option>
                              {!ArrayUtils.isEmpty(dataBindingList)
                                ? dataBindingList.map((option, optionIdx) => (
                                    <option key={optionIdx} value={option.id}>
                                      {option.text}
                                    </option>
                                  ))
                                : ""}
                            </Form.Select>
                          </Col>
                          <Col>
                            <Button
                              onClick={(e) => onInsertClick(e, mapping, index)}
                              size="sm"
                              className="mr-5"
                              variant={"outline-primary"}
                            >
                              <MdLibraryAdd size="16" />
                            </Button>
                            <Button
                              onClick={(e) => onDeleteClick(e, mapping, index)}
                              size="sm"
                              variant={"outline-danger"}
                            >
                              <FaTrash size="16" />
                            </Button>
                          </Col>
                        </Row>
                      );
                    })
                  : ""}
              </Col>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>User Post-processing Function</Form.Label>
              <UModalJavascriptEditor
                height="400px"
                defaultValue={StringUtils.defaultString(afterSubmitRef.current)}
                onClickEventBuilder={(e) => onClickEventBuilder("afterSubmit")}
                eventInfo={props.getEventInfo("afterSubmit")}
                onChange={(value, viewUpdate) => {
                  onChangeAfterSubmit({
                    target: { id: "afterSubmit", value: value },
                  });
                }}
              ></UModalJavascriptEditor>
            </Form.Group>
          </UmodalTemplate>
        </Modal.Body>
        <Modal.Footer>
          <Modal.Footer.Button
            type="button"
            className="btn btn-primary"
            onClick={closePopup}
          >
            <BsPencilSquare />
            &nbsp;Confirm
          </Modal.Footer.Button>
        </Modal.Footer>
      </Modal>
    </MesFunPopupStyle>
  );
};

export default PopupPostHandleConfigPopup;
