import {
  Box,
  Checkbox,
  Chip,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import { Enums } from "components/builder/BuilderEnum";
import { AppContext } from "components/common/AppContextProvider";
import Message from "components/common/Message";
import StringUtils from "components/common/utils/StringUtils";
import User from "components/common/utils/UserUtils";
import PageTemplate from "page/common/PageTemplate";
import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { FaEdit, FaList } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import AppService from "services/common/AppService";
import CodeService from "services/common/CodeService";
import UserService from "services/common/UserService";
import { MultiCheckSelectBox } from "./UserDetail";

function MyDetail() {
  const param = useParams();
  const navigate = useNavigate();
  const applicationList = useContext(AppContext).application.list;

  const [userId, setUserId] = useState("");
  const [userNm, setUserNm] = useState("");
  const [userEngNm, setUserEngNm] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [pwd, setPwd] = useState("");
  const [pwdConfirm, setPwdConfirm] = useState("");
  const [defaultLangCd, setDefaultLangCd] = useState("KO");
  const [useYn, setUseYn] = useState("");
  const [user, setUser] = useState({});

  const [isUserDev, setIsUserDev] = useState(true);
  const [isMine, setIsMine] = useState(true);

  useEffect(() => {
    UserService.getUser(
      {
        userMastRecId: User.getMastRecId(),
      },
      (res) => {
        if (res.data) {
          setUser(res.data);
          setUserId(res.data.userId);
          setUserNm(res.data.userNm);
          setUserEngNm(res.data.userEngNm);
          setUserEmail(res.data.userEmail);
          setPwd(res.data.pwd);
          setDefaultLangCd(res.data.defaultLangCd);
          setUseYn(res.data.useYn);
        } else {
          Message.confirm(
            "Cannot find user Info. This will take you back to the previous page",
            () => {
              navigate(-1);
            }
          );
        }
      }
    );
    return () => {};
  }, []);

  const onMoveToList = () => {
    if (isUserDev) {
      navigate(Enums.BuilderPath.USER.MAIN);
    } else {
      navigate(`${Enums.BuilderPath.USER.MAIN}/list/${param.appId}`);
    }
  };

  /**
   * 저장
   * @returns
   */
  const updateUser = () => {
    if (StringUtils.isEmpty(userId))
      return Message.alert("Enter ID", Enums.MessageType.WARN);
    if (StringUtils.isEmpty(userNm))
      return Message.alert("Enter Name", Enums.MessageType.WARN);
    if (StringUtils.isEmpty(userEmail))
      return Message.alert("Enter Email", Enums.MessageType.WARN);

    //비밀번호 수정한 경우에만 검증 단계
    if (!StringUtils.isEmpty(pwd)) {
      if (pwd !== pwdConfirm)
        return Message.alert("Password is Invalid.", Enums.MessageType.WARN);
      if (StringUtils.isEmpty(pwdConfirm))
        return Message.alert(
          "Please enter 'Confirm Password' Field",
          Enums.MessageType.WARN
        );
    }

    if (StringUtils.isEmpty(useYn))
      return Message.alert(
        "Please selece 'Usage Status' ",
        Enums.MessageType.WARN
      );

    const body = {
      ...user,
      userNm,
      userEngNm,
      userEmail,
      defaultLangCd,
      pwd,
      useYn,
    };

    Message.confirm("Would you like to modify the information?", () => {
      UserService.update(body, (res) => {
        const { isSuccess, message } = res.data;
        if (isSuccess === "Y") {
          Message.alert(
            "User Info has been modified.",
            Enums.MessageType.SUCCESS
          );
        } else {
          Message.alert(message, Enums.MessageType.ERROR);
        }
      });
    });
  };

  let breadcrum = [
    {
      name: "User Details",
      subName: userNm + "-" + userId,
      active: true,
    },
  ];

  return (
    <PageTemplate breadcrum={breadcrum}>
      <PageTemplate.Box boxClass="mb-0">
        <div className="box-control box-line button-group">
          <Button variant="outline-dark" size="sm" onClick={updateUser}>
            <FaEdit /> Edit
          </Button>{" "}
          <Button variant="outline-dark" size="sm" onClick={onMoveToList}>
            <FaList /> List
          </Button>
        </div>
        <Form>
          <Row className="mt-4">
            <Col sm="6" style={{ paddingRight: "20px" }}>
              <Form.Group className="mb-3">
                <Form.Label className="required">User ID</Form.Label>
                <Form.Control value={userId} readOnly={true} disabled={true} />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label className="required">User Name</Form.Label>
                <Form.Control
                  value={userNm}
                  onChange={(e) => setUserNm(e.currentTarget.value)}
                />
                <Form.Text></Form.Text>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label className="required">User English Name</Form.Label>
                <Form.Control
                  value={userEngNm}
                  onChange={(e) => setUserEngNm(e.currentTarget.value)}
                />
                <Form.Text></Form.Text>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label className="required">User Email</Form.Label>
                <Form.Control
                  value={userEmail}
                  onChange={(e) => setUserEmail(e.currentTarget.value)}
                />
                <Form.Text></Form.Text>
              </Form.Group>
              {isMine ? (
                <>
                  <Form.Group className="mb-3">
                    <Form.Label className="required">Password</Form.Label>
                    <Form.Control
                      value={pwd}
                      type="password"
                      onChange={(e) => setPwd(e.currentTarget.value)}
                    />
                    <Form.Text></Form.Text>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label className="required">
                      Confirm Password
                    </Form.Label>
                    <Form.Control
                      value={pwdConfirm}
                      type="password"
                      onChange={(e) => setPwdConfirm(e.currentTarget.value)}
                    />
                    <Form.Text></Form.Text>
                  </Form.Group>
                </>
              ) : null}
            </Col>

            <Col sm="6" style={{ paddingLeft: "20px" }}>
              <Form.Group className="mb-3">
                <Form.Label>Language</Form.Label>
                <Form.Select
                  value={defaultLangCd}
                  onChange={(e) => setDefaultLangCd(e.target.value)}
                >
                  <option value={"KO"}>Korean</option>
                  <option value={"EN"}>English</option>
                  <option value={"ZH"}>Chinese</option>
                  <option value={"JA"}>Japanese</option>
                  <option value={"VN"}>Vietnamese</option>
                </Form.Select>
                <Form.Text></Form.Text>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Usage Status</Form.Label>
                <Form.Select
                  value={useYn}
                  onChange={(e) => setUseYn(e.target.value)}
                >
                  <option value={""}>Select</option>
                  <option value={"Y"}>Yes</option>
                  <option value={"N"}>No</option>
                </Form.Select>
                <Form.Text></Form.Text>
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </PageTemplate.Box>
    </PageTemplate>
  );
}

export default MyDetail;
