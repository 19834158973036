import axios from "axios";
import { Enums } from "components/builder/BuilderEnum";
import config from "components/common/EnvConfig.js";
import Message from "components/common/Message";
import User from "components/common/utils/UserUtils";
import ConnectionService from "services/common/ConnectionService";
import StringUtils from "./utils/StringUtils";
import LocalStorageService from "services/common/LocalService";

class Api {
  /**
   * get
   * @param {String} url
   * @param {Map} data
   * @param {Function} callbackFnc
   */
  static get = (url, data, callbackFnc, errCallbackFnc, contentType) => {
    if (!data.userId) {
      data = {
        ...data,
        userId: User.getId(),
      };
    }

    const axiosConfig = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      params: data,
    };
    axios
      .get(config.baseUrl.server + url, axiosConfig)
      .then(function (response) {
        if (typeof callbackFnc === "function") {
          callbackFnc.call(this, response.data);
        }
        // response
      })
      .catch(function (error) {
        // an occured error
        if (typeof errCallbackFnc === "function") {
          Message.alert("An error occurred during API communication.", "ERROR");
          errCallbackFnc.call(this, error);
        }
      })
      .then(function () {
        // always run
      });
  };

  /**
   * post
   * @param {String} url
   * @param {Map} data
   * @param {Function} callbackFnc
   * @returns
   */
  static post = (url, data, callbackFnc, errCallbackFnc) => {
    if (!data.userId) {
      data = {
        ...data,
        userId: User.getId(),
      };
    }
    return axios
      .post(config.baseUrl.server + url, data)
      .then(function (response) {
        // response
        if (response.data.token?.newToken) {
          User.updateToken(response.data.token.newToken);
        }
        if (response.data.isError) {
          let tenantMstId = data.tenantMstId;
          if (!tenantMstId) {
            let locStg = LocalStorageService.get(
              Enums.LocalStorageName.WORKSPACE_HISTORY
            );
            if (locStg) {
              if (locStg && locStg.list.length > 0) {
                tenantMstId = locStg.list[0].tenantMstId;
              }
            }
          }
          const { flag, text } = ConnectionService.reponseValidator(
            response.data,
            tenantMstId
          );
          throw new Error(text);
        } else if (typeof callbackFnc === "function") {
          return callbackFnc.call(this, response.data);
        }
      })
      .catch(function (error) {
        if (!StringUtils.equalsIgnoreCase(error.message, "null")) {
          Message.alert(error.message, Enums.MessageType.ERROR);
        }
        console.log(error);
        // an occured error
        if (typeof errCallbackFnc === "function") {
          errCallbackFnc.call(this, error);
        }
      })
      .then(function () {
        // always run
      });
  };

  /**
   * 서버에 직접연결하는 경우
   * 토큰을 포함한 연결정보를 connectionInfo안에 담겨 있어야 한다.
   * @param {*} url
   * @param {*} data
   * @param {*} callbackFnc
   * @param {*} errCallbackFnc
   */
  static directPost = (url, data, callbackFnc, errCallbackFnc) => {
    if (!data.userId) {
      data = {
        ...data,
        userId: User.getId(),
      };
    }
    const { connectionInfo, ...param } = data;

    const { headers, url: baseUrl } =
      ConnectionService.setDirectDeployParam(connectionInfo);
    axios
      .post(`${baseUrl}api/daaf/builder${url}`, param, {
        headers,
      })
      .then((res) => {
        const { token, isError } = res.data;

        if (token?.newToken) {
          User.updateToken(token.newToken);
        }
        if (isError) {
          let tenantMstId = data.tenantMstId;
          if (!tenantMstId) {
            let locStg = LocalStorageService.get(
              Enums.LocalStorageName.WORKSPACE_HISTORY
            );
            if (locStg && locStg.list.length > 0) {
              tenantMstId = locStg.list[0].tenantMstId;
            }
          }
          const { flag, text } = ConnectionService.reponseValidator(
            res.data,
            tenantMstId
          );
          throw new Error(text);
        } else {
          callbackFnc(res.data);
        }
      })
      .catch((err) => {
        if (!StringUtils.equalsIgnoreCase(err.message, "null")) {
          Message.alert(err.message, Enums.MessageType.ERROR);
        }
        errCallbackFnc(err);
      });
  };

  /**
   * delete
   * @param {String} url
   * @param {Map} id
   * @param {Function} callbackFnc
   */
  static delete = (url, id, callbackFnc, errCallbackFnc, contentType) => {
    const axiosConfig = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    axios
      .delete(config.baseUrl.server + url + "/" + id, axiosConfig)
      .then(function (response) {
        if (typeof callbackFnc === "function") {
          callbackFnc.call(this, response.data);
        }
        // response
      })
      .catch(function (error) {
        // an occured error
        if (typeof errCallbackFnc === "function") {
          Message.alert(error.message, Enums.MessageType.ERROR);
          errCallbackFnc.call(this, error);
        }
      })
      .then(function () {
        // always run
      });
  };

  /**
   * post
   * @param {String} url
   * @param {Map} data
   * @param {Function} callbackFnc
   * @returns
   */
  static fileUpload = (url, data, callbackFnc, errCallbackFnc) => {
    return axios
      .post(config.baseUrl.server + url, data, {})
      .then(function (response) {
        // response
        if (response.data.isError) throw new Error(response.data.message);
        if (typeof callbackFnc === "function") {
          return callbackFnc.call(this, response.data);
        }
      })
      .catch(function (error) {
        // an occured error
        Message.alert(error.message, Enums.MessageType.ERROR);
        if (typeof errCallbackFnc === "function") {
          errCallbackFnc.call(this, error);
        }
      })
      .then(function () {
        // always run
      });
  };
}

export default Api;
