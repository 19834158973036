import Api from "components/common/Api";

class FileService {
  static upload(formData, callbackFnc, errCallbackFn) {
    if (formData) {
      formData.append("pathCode", "document");
    }
    Api.fileUpload("/common/file/upload", formData, callbackFnc, errCallbackFn);
  }
  static download(data, callbackFnc, errCallbackFn) {
    Api.get(`/common/file/download/${data.fileId}`, callbackFnc, errCallbackFn);
  }
  static deleteFile(data, callbackFnc, errCallbackFn) {
    Api.post(`/common/file/delete`, data, callbackFnc, errCallbackFn);
  }
  static getFileList(data, callbackFnc, errCallbackFn) {
    Api.post(`/common/file/getFileList`, data, callbackFnc, errCallbackFn);
  }
}
export default FileService;
