import React from "react";

import UIEditorChildRender from "components/builder/ui/editor/UIEditorChildRender";
import UIEditorDNDContainer from "components/builder/ui/editor/UIEditorDNDContainer";
import * as Enums from "components/builder/BuilderEnum";
import { ObjectUtils, StringUtils } from "components/common/utils/CommonUtils";
import { FooterPanel } from "components/builder/ui/uiComponents/UIComponentStyle";

const UIEditorFooter = (props) => {
  const { child, onDrop, templateComponents, pagePropertyValue, style } = props;

  const DNDContainer = props.dndContainer
    ? props.dndContainer
    : UIEditorDNDContainer;

  /**
   * Footer Penal Class
   * @returns
   */
  const getFooterPanelClass = () => {
    let pageClass = "ui-modal-footer";
    if (pagePropertyValue.programType === Enums.ProgramType.POPUP_PROGRAM) {
      const size = ObjectUtils.isEmpty(pagePropertyValue.popupOptions)
        ? "xl"
        : StringUtils.defaultString(pagePropertyValue.popupOptions.size, "xl");

      pageClass += size !== "fl" ? " modal-" + size : "";
    }
    return pageClass;
  };

  return (
    <FooterPanel
      className={getFooterPanelClass()}
      pagePropertyValue={pagePropertyValue}
      style={style}
    >
      <div className="editor-columns pass-focus">
        {child.map((row, index) => {
          const currentPath = `${index}`;
          return (
            <UIEditorChildRender
              key={row.compId}
              data={row}
              handleDrop={onDrop}
              templateComponents={templateComponents}
              path={currentPath}
              rootLocation={Enums.ComponentType.FOOTER}
              dndContainer={DNDContainer}
            />
          );
        })}
      </div>
    </FooterPanel>
  );
};
export default React.memo(UIEditorFooter);
