import React, { Component } from "react";
import Popup from "components/common/Popup";
import Modal from "components/common/modal/UModal";
import Message from "components/common/Message";

class SamplePopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputValue: {
        idValue: props.idValue,
        nameValue: props.nameValue,
        descValue: props.descValue,
      },
    };
    this.saveData = this.saveData.bind(this);
  }

  saveData = (event) => {
    event.preventDefault();

    Message.confirm("Would you like to save?", () => {
      Message.alert("Saved Successfully.", "info");
      this.props.callbackFnc.call(this, this.state.inputValue);
      Popup.close();
    });
  };

  handleInput = (e) => {
    const { name, value } = e.target;
    this.setState({
      inputValue: { ...this.state.inputValue, [name]: value },
    });
  };

  render() {
    return (
      <Modal>
        {/*
         * Modal.Header props
         *   - title
         */}
        <Modal.Header title={this.props.title} />
        <Modal.Body>
          <div className="row">
            <div className="col">
              This section contains the contents of the corresponding popup.
            </div>
          </div>
          <div className="row">
            <div className="col-1">ID</div>
            <div className="col-11">
              <input type="text" onChange={this.handleInput} name="idValue" />
            </div>
          </div>
          <div className="row">
            <div className="col-1">NAME</div>
            <div className="col-11">
              <input type="text" onChange={this.handleInput} name="nameValue" />
            </div>
          </div>
          <div className="row">
            <div className="col-1">DESC</div>
            <div className="col-11">
              <input type="text" onChange={this.handleInput} name="descValue" />
            </div>
          </div>
        </Modal.Body>

        {/*
         * Modal.Footer props
         *   - invisibleClose : Close 버튼을 보이지 않게 (default empty or false)
         *   - closeText : close 버튼명  (default "Close") -->  닫기,Close,취소,Cancel 을 상황에 따라 넣을수 있음.
         *   - ex closeText="취소"
         *
         * Popup 수동 Close
         * import Popup from "components/common/Popup";
         *   - Popup.close();
         *
         */}
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-primary"
            onClick={this.saveData}
          >
            Save
          </button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default SamplePopup;
