import Modal from "components/common/modal/UModal";
import UModalTemplate from "components/common/modal/UModalTemplate";
import React from "react";

function ConnectTypeDescPopup() {
  return (
    <Modal>
      <Modal.Header title="Connection Type Explanation" />
      <Modal.Body>
        <UModalTemplate>
          <p>
            <strong>1. Deploy by DAAF Proxy</strong>
            <br />
            Deploying to the target server through the DAAF API server.
          </p>
          <p>
            <strong>2. Deploy by Direct Connection</strong>
            <br />
            Deploying directly to the target server without going through the
            DAAF API server
          </p>
          <p>
            ※{" "}
            <font color="red">
              It is recommended to use this optPrivacy and securityion only when
              the deployment server needs to connect via VPN, as the "DAAF
              Framework automatic patch" feature on the deployment server is not
              operational.
            </font>
            <br />
            <br />※{" "}
            <font color="blue">
              To use direct connection, the following conditions must be
              satisfied:
            </font>
            <br />
            1) Deploy Server is HTTPs
            <br />
            2) Or if HTTP, the deployment server must be added as a source of
            insecure content in Chrome setting.
            <br />
          </p>
          <p>
            <span style={{ color: "tomato" }}>*</span> How to allow insecure
            content (HTTP) in Chrome settings.
            <br />
            <strong>
              {`. setting > Privacy and security > site settings > Additional content settings > insecure content `}
            </strong>
            <br />
            . In the unsafe content settings screen, <br />
            &emsp;- click the [Add] button where it says "Displaying unsafe
            content is not allowed."
            <br />
            &emsp;- site : Enter https://daaf.bizentro.net:443
            <br />
            . Click the registered Site
            <br />
            &emsp;- Insecure content - Allow
            <br />
          </p>
        </UModalTemplate>
      </Modal.Body>
    </Modal>
  );
}

export default ConnectTypeDescPopup;
