/* eslint-disable jsx-a11y/alt-text */
import React, { Fragment } from "react";

import Modal from "components/common/modal/UModal";

import UmodalTemplate from "components/common/modal/UModalTemplate";

const GridProgressCellTemplateGuidePopup = (props) => {
  return (
    <Modal>
      <Modal.Header title="Grid Progress Bar Cell Template Usage Guide" />
      <Modal.Body>
        <UmodalTemplate>
          <Fragment>
            <h4>Grid Progress Bar CellTemplate</h4>
            <div className="doc-page-wrap">
              <h5>▶ HTML Code Write Guide</h5>
              <div className="event-desc pt-1">
                Can change the cell style using HTML tags.
              </div>
              <br></br>
              e.g.) To display the progress status, include the width property
              (with a maximum progress status of 100).
              <code className="mt-2 pt-2">
                {`<div style="width: width: \${item.progressState / 100 * 100}%;"></div>`}
              </code>
              <br></br>
              e.g.) When displaying different colors based on the progress rate.
              <code className="mt-2 pt-2">
                {`<div style="{ item.progressState / 100 * 100 < 30 ? 
                \`background-color: #dc3545;\` :
                item.progressState / 100 * 100 < 50 ?
                \`background-color: #ffc107;\` :
                item.progressState / 100 * 100 < 70 ?
                \`background-color: #17a2b8;\` :
                item.progressState / 100 * 100 < 100 ?
                \`background-color: #498ff7;\` :
                \`background-color: #28a745;\` }">
                </div>`}
              </code>
              <br></br>
              e.g.) When you want to display date data.
              <code className="mt-2 pt-2">
                {`<div>\n\${item.utils.Date.yyyymmdd(new Date(item.startDate))} ~ \n\${item.utils.Date.yyyymmdd(new Date(item.endDate))}\n</div>`}
              </code>
              <br></br>
              <div className="event-code">
                function (col,row,value,text,item)
              </div>
              <div className="event-params pb-1">
                <span className="doc-title"> Parameters</span>
                <ul>
                  <li>
                    <span className="event-args-var">col</span>:
                    <span className="event-args-type">Object</span>-
                    <span className="event-args-name">
                      Current Column Object
                    </span>
                  </li>
                  <li>
                    <span className="event-args-var">row</span>:
                    <span className="event-args-type">Object</span>-
                    <span className="event-args-name">Current Row Object</span>
                  </li>
                  <li>
                    <span className="event-args-var">value</span>:
                    <span className="event-args-type">String</span>-
                    <span className="event-args-name">
                      The value binding on current Column
                    </span>
                  </li>
                  <li>
                    <span className="event-args-var">text</span>:
                    <span className="event-args-type">String</span>-
                    <span className="event-args-name">
                      The Text displayed on current Cell
                    </span>
                  </li>
                  <li>
                    <span className="event-args-var">item</span>:
                    <span className="event-args-type">Object:Map</span>-
                    <span className="event-args-name">
                      The Value Binding on current Row
                    </span>
                    <div className="event-args-desc">e.g.) item.userId</div>
                  </li>
                </ul>
              </div>
            </div>
          </Fragment>
        </UmodalTemplate>
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
};

export default GridProgressCellTemplateGuidePopup;
