import { TextField } from "@mui/material";
import { Enums } from "components/builder/BuilderEnum";
import BuilderHeaderComponent from "components/builder/BuilderHeaderComponent";
import { setDesign } from "components/builder/programDesign/reducer/ProgramDesignAction";
import { setProgramDesignSearchTerm } from "components/builder/ui/reducers/MenuAction";
import { AppContext } from "components/common/AppContextProvider";
import WijmoGrid from "components/common/element/WijmoGrid";
import Message from "components/common/Message";
import CommonUtils from "components/common/utils/CommonUtils";
import ObjectUtils from "components/common/utils/ObjectUtils";
import produce from "immer";
import PageTemplate from "page/common/PageTemplate";
import { useContext, useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { BsSearch } from "react-icons/bs";
import { RiDeleteBinLine } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ProgramDesignService from "services/programDesignService/ProgramDesignService";

/**
 * 프로그램 목록
 * @param {*} param0
 * @returns
 */
function ProgramDesignList({ ...props }) {
  const workspace = useSelector((state) => state.workspace);

  const [searchFormData, setSearchFormData] = useState({
    programId: "",
    programNm: "",
    requesterId: "",
    workerId: "",
  });

  const [designMstList, setDesignMstList] = useState([]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {} = useContext(AppContext);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getProgramDesignList();
  }, []);

  const breadcrum = [
    {
      name: "Program Design List",
      url: Enums.BuilderPath.PROGRAM_DESIGN.LIST,
      active: true,
    },
  ];

  const getProgramDesignList = () => {
    setIsLoading(true);
    const body = {
      appId: workspace.appId,
      tenantId: workspace.tenantId,
      coCd: workspace.coCd,
      programId: searchFormData.programId,
      programNm: searchFormData.programNm,
      requesterId: searchFormData.requesterId,
      workerId: searchFormData.workerId,
    };
    ProgramDesignService.getTechnicalDesignList(
      body,
      (res) => {
        if (!res?.isError) {
          setDesignMstList(res.data);
          setIsLoading(false);
        }
      },
      () => {
        setIsLoading(false);
      }
    );
  };

  const onSearch = () => {
    const body = {
      programId: searchFormData.programId,
      programNm: searchFormData.programNm,
      requesterId: searchFormData.requesterId,
      updtUserId: searchFormData.workerId,
    };
    dispatch(setProgramDesignSearchTerm(body));
    getProgramDesignList();
  };

  const deleteDesignMst = (row, i) => {
    Message.confirm(
      "Are you sure to delete program '" + row.programNm + "'?",
      (ok) => {
        const designMstId = row.designMstId;
        ProgramDesignService.deleteDesignMst(
          { designMstId: designMstId },
          (res) => {
            if (res.isError) {
              Message.alert("Delete Failed", Enums.MessageType.ERROR);
            } else {
              Message.alert("Delete Success", Enums.MessageType.SUCCESS);
              onSearch();
            }
          }
        );
      }
    );
  };

  const onChangeSearchForm = (e) => {
    const newFormData = { ...searchFormData };
    newFormData[e.target.id] = e.target.value;
    setSearchFormData(newFormData);
  };

  const columns = [
    {
      field: "tenantId",
      headerName: "Tenant ID",
      width: 120,
    },
    {
      field: "coCd",
      headerName: "Company Code",
      width: 120,
    },
    {
      field: "programId",
      headerName: "Program ID",
      width: 200,
    },
    {
      field: "programNm",
      headerName: "Program Name",
      width: 300,
    },
    {
      field: "programDesc",
      headerName: "Program Overview",
      width: "*",
    },
    {
      field: "requesterId",
      headerName: "Requester",
      width: 130,
    },
    {
      field: "developer",
      headerName: "Developer",
      width: 130,
    },
    {
      field: "updtUserId",
      headerName: "Update User",
      width: 130,
    },
    {
      field: "updtDt",
      headerName: "Update Date",
      width: 150,
      renderCell: (row) => `${CommonUtils.getDate(row.updtDt, "datetime")}`,
    },
    {
      field: "delete",
      headerName: "Delete",
      width: 50,
      renderCell: (row, i) => {
        return (
          <Button
            size="sm"
            variant="outline-danger"
            onClick={() => deleteDesignMst(row, i)}
          >
            <RiDeleteBinLine />
          </Button>
        );
      },
    },
  ];

  return (
    <>
      <BuilderHeaderComponent {...props.header} />
      <PageTemplate breadcrum={breadcrum}>
        <PageTemplate.Box boxClass="mb-0">
          {ObjectUtils.isEmpty(workspace) ? (
            <div className="workspace-empty-alert">
              <div className="alert-msg">
                Workspace configuration is required.
              </div>
              {/* <Button onClick={() => openPopup()}>Open Popup</Button> */}
            </div>
          ) : (
            <>
              <Form>
                <Row className="pb-3 pt-3">
                  <Col xs={2}>
                    <TextField
                      id="programId"
                      size={"small"}
                      label="Program ID"
                      placeholder="Enter Program ID..."
                      fullWidth={true}
                      onChange={onChangeSearchForm}
                      // onKeyDown={onKeyDown}
                      type={"number"}
                    />
                  </Col>
                  <Col xs={2}>
                    <TextField
                      id="programNm"
                      size={"small"}
                      label="Program Name"
                      placeholder="Enter Program Name..."
                      fullWidth={true}
                      onChange={onChangeSearchForm}
                      // onKeyDown={onKeyDown}
                    />
                  </Col>
                  <Col xs={2}>
                    <TextField
                      id="requesterId"
                      size="small"
                      label="Requester"
                      placeholder="Requester"
                      onChange={onChangeSearchForm}
                      // onKeyDown={onKeyDown}
                      fullWidth
                    />
                  </Col>
                  <Col xs={2}>
                    <TextField
                      id="workerId"
                      size="small"
                      label="Update User ID"
                      placeholder="Update User ID"
                      onChange={onChangeSearchForm}
                      // onKeyDown={onKeyDown}
                      fullWidth
                    />
                  </Col>
                  <Col xs={1}>
                    <Button variant="primary" onClick={onSearch}>
                      Search <BsSearch size="14" />
                    </Button>
                  </Col>
                </Row>
              </Form>
              <Row style={{ height: "calc(100% - 70px)" }}>
                <Col xs={12}>
                  <WijmoGrid
                    isLoading={isLoading}
                    columns={columns}
                    rows={designMstList}
                    onRowDoubleClick={(clickedRow) => {
                      dispatch(setDesign(clickedRow));
                      navigate(
                        Enums.BuilderPath.PROGRAM_DESIGN.MAIN +
                          "/" +
                          Enums.BuilderPath.PROGRAM_DESIGN.BUILDER
                      );
                    }}
                    getRowId={(row) => row.programUid}
                    style={{ height: "70vh" }}
                  />
                </Col>
              </Row>
            </>
          )}
        </PageTemplate.Box>
      </PageTemplate>
    </>
  );
}

export default ProgramDesignList;
