import React, { Component } from "react";
import PropTypes from "prop-types";
import Modal from "components/common/modal/UModal";
import UModalTemplate from "components/common/modal/UModalTemplate";
import StringUtils from "components/common/utils/StringUtils";
import { Col, Form, Row } from "react-bootstrap";
import Message from "components/common/Message";
import { Enums } from "components/builder/BuilderEnum";
import { stopEvent } from "components/builder/ui/editor/handler/UIEditorEventHandler";
import Popup from "components/common/Popup";
import WorkFlowProcessListPopup from "page/popup/workflow/WorkFlowProcessListPopup";
import produce from "immer";
import JsonUtils from "components/common/utils/JsonUtils";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import User from "components/common/utils/UserUtils";
import DataModelService from "services/datamodel/DataModelService";
import ObjectUtils from "components/common/utils/ObjectUtils";
import ProcessDataBindingPopup from "../ProcessDataBindingPopup";
import ArrayUtils from "components/common/utils/ArrayUtils";
import {
  getEntityPropertyValue,
  isWhoColumn as _isWhoColumn,
} from "components/builder/workflow/editor/render/WorkflowRenderUtils";

class ProcessModal extends Component {
  constructor(props) {
    super(props);
    this.renderModal = this.renderModal.bind(this);
    this.renderBody = this.renderBody.bind(this);
    this.onChangeParam = this.onChangeParam.bind(this);
    this.validateCommonParam = this.validateCommonParam.bind(this);
    this.onOpenEntityList = this.onOpenEntityList.bind(this);
    this.callbackEntityList = this.callbackEntityList.bind(this);
    this.onOpenDataBindingPopup = this.onOpenDataBindingPopup.bind(this);
    this._openDataBindingPopup = this._openDataBindingPopup.bind(this);
    this.callbackDataBinding = this.callbackDataBinding.bind(this);
    this.onValidationCheck = this.onValidationCheck.bind(this);
    this.renderProcessNm = this.renderProcessNm.bind(this);
    this.onChangeRadioButton = this.onChangeRadioButton.bind(this);
    this.getReferenceEntityFieldList =
      this.getReferenceEntityFieldList.bind(this);
    this.WarnMessage = this.WarnMessage.bind(this);
    this.getProcessNm = this.getProcessNm.bind(this);
    this.getTableListForCodeMirror = this.getTableListForCodeMirror.bind(this);
    this.renderFooter = this.renderFooter.bind(this);
    this.getAccessibleEntityList = this.getAccessibleEntityList.bind(this);
    this.isWhoColumn = this.isWhoColumn.bind(this);
    this.onKeyEnterDown = this.onKeyEnterDown.bind(this);
    this.state = {
      processNm: "",
    };
  }

  onKeyEnterDown(e) {
    if (e.key === "Enter") {
      this.validateCommonParam(e);
    }
  }

  /**
   * 상속하여 바꾸지 말것
   * @returns
   */
  validateCommonParam(e) {
    stopEvent(e);
    if (StringUtils.isEmpty(this.state.processNm)) {
      return Message.alert("Please enter process name", Enums.MessageType.WARN);
    }
    if (this.onValidationCheck() === true) {
      this.onClickConfirm();
    }
  }

  /**
   * 상속받은 곳에서 발리데이션 체크
   * @returns
   */
  onValidationCheck() {
    return true;
  }

  /**
   * 최종 확인 버튼
   */
  onClickConfirm() {
    if (this.props.callbackFnc) {
      this.props.callbackFnc(this.state);
    }
  }
  /**
   * 기본적인 내부 변수 체인지 이벤트
   * @param {*} e
   */
  onChangeParam(e) {
    this.setState({
      [e.target.id]: e.target.value,
    });
  }

  /**
   * 기본적인 라디오 버튼 체인지 이벤트
   * @param {*} e
   */
  onChangeRadioButton(e) {
    if (e.target.checked) {
      this.setState({
        [e.target.name]: e.target.id,
      });
    }
  }

  /**
   * Entity Definition 목록 팝업 호출
   * @param {*} e
   * @param {*} paramNm
   * @param {*} props
   * @param {String} props.fieldRequired
   * @param {Array} props.processTypes
   */
  onOpenEntityList(e, paramNm = "inputEntity", props = {}) {
    stopEvent(e);
    const callbackFnc = (entity) => {
      Popup.close();
      if (props && props.callback) {
        props.callback(entity, paramNm);
      } else {
        this.callbackEntityList(entity, paramNm);
      }
    };

    Popup.open(
      <WorkFlowProcessListPopup
        callbackFnc={callbackFnc}
        workflow={this.props.workflow}
        nodes={this.props.nodes}
        edges={this.props.edges}
        iterator={this.props.iterator}
        compId={this.props.compId}
        includeSelf={this.props.connector ? true : false}
        getAccessibleEntityList={this.getAccessibleEntityList}
        {...props}
      />,
      {
        style: {
          content: {
            width: "550px",
          },
        },
      }
    );
  }

  /**
   * 엔티티 리스트 콜백
   * @param {*} entity
   * @param {*} paramNm
   */
  callbackEntityList(entity, paramNm) {
    const { compId, entityVariable, entityNm, tableNm } = entity;
    const _Entity = {
      referenceCompId: compId,
      entityVariable: entityVariable,
      entityNm: entityNm,
    };
    //이터레이터는 본인이 아닌 본인 노드가 참조하고 있는 대상의 ID를 넘긴다.
    if (entity.processType === Enums.WorkflowNodeType.ITERATOR) {
      _Entity.referenceCompId = entity.referenceCompId;
      _Entity.entityVariable = entity.iteratorVariable;
      _Entity.entityNm = entity.iteratorNm;
    }
    this.setState(
      produce(this.state, (draft) => {
        draft[paramNm] = _Entity;

        if (paramNm === "inputEntity") {
          draft.targetTable = tableNm;
        }
      })
    );
  }

  /**
   * 데이터 바인딩 콜백
   * @param {*} e
   * @param {*} idx
   * @param {*} value
   */
  callbackDataBinding(e, idx, value) {}

  /**
   * 데이터 바인딩 팝업 열기
   * @param {*} e
   * @param {number} idx
   * @param {Object} props
   * @param {String} props.fieldId //Field ID
   * @param {Function} props.callback // 기본 콜백을 사용하지 않는 경우 정의
   */
  onOpenDataBindingPopup(e, idx, props) {
    const callbackFnc = (value) => {
      Popup.close();
      if (props && props.callback) {
        props.callback(e, idx, value);
      } else {
        this.callbackDataBinding(e, idx, value, props);
      }
    };
    this._openDataBindingPopup(callbackFnc, props);
  }

  _openDataBindingPopup(callbackFnc, props) {
    Popup.open(
      <ProcessDataBindingPopup
        workspace={this.props.workspace}
        workflow={this.props.workflow}
        nodes={this.props.nodes}
        edges={this.props.edges}
        callbackFnc={callbackFnc}
        compId={this.props.compId}
        getAccessibleEntityList={this.getAccessibleEntityList}
        {...props}
      />,
      {
        style: { content: { width: "800px" } },
      }
    );
  }

  /**
   * 참조하는 Entity Definition에서 정의된 Field List를 반환
   * @param {*} type
   * @returns {Array} entityFieldList
   */
  getReferenceEntityFieldList(type = "inputEntity") {
    let entityFieldList = [];
    if (!StringUtils.isEmpty(this.state[type]?.referenceCompId)) {
      const DataDefNode = JsonUtils.findNode(
        this.props.workflow,
        "compId",
        this.state[type].referenceCompId
      );
      if (DataDefNode.propertyValue.entityFieldList) {
        entityFieldList = DataDefNode.propertyValue.entityFieldList;
      }
    }
    return entityFieldList;
  }

  getProcessNm() {
    return this.state.processNm;
  }

  /**
   * Entity Filter Validation
   * @returns
   */
  validateFilter() {
    if (this.state.filter.length > 0) {
      const andList = this.state.filter.filter((f) => !f.or);
      for (const and of andList) {
        if (ObjectUtils.isEmpty(and)) {
          this.WarnMessage("There is an empty conditional statement.");
          return false;
        }
        for (const fieldId in and) {
          if (StringUtils.isEmpty(fieldId)) {
            this.WarnMessage("There are missing AND condition field IDs.");
            return false;
          } else if (StringUtils.isEmpty(and[fieldId])) {
            this.WarnMessage("Please select AND Condition.");
            return false;
          }
        }
      }
      const orList = this.state.filter.filter((f) => f.or);
      for (const or of orList) {
        const _orFilter = or.or;
        for (const _orField of _orFilter) {
          if (ObjectUtils.isEmpty(_orField)) {
            this.WarnMessage("There are empty Conditional statements.");
            return false;
          }
          for (const fieldId in _orField) {
            if (StringUtils.isEmpty(fieldId)) {
              this.WarnMessage("There are missing OR condition field IDs.");
              return false;
            } else if (StringUtils.isEmpty(_orField[fieldId])) {
              this.WarnMessage("Please select OR Condition.");
              return false;
            }
          }
        }
      }
      return true;
    } else {
      return true;
    }
  }
  /**
   * output Value를 가지고 있는 Process 목록
   */
  returnNodeTypeList = [
    Enums.WorkflowProcessType.ENTITY_DEFINITION,
    Enums.WorkflowProcessType.SELECT_ENTITY,
    Enums.WorkflowProcessType.SELECT_ENTITY_BY_QUERY,
    Enums.WorkflowProcessType.SERVICE,
    Enums.WorkflowProcessType.CALL_STORED_PROCEDURE,
    Enums.WorkflowProcessType.UNIERP_CONNECTOR,
    Enums.WorkflowProcessType.REST_API_CONNECTOR,
    Enums.WorkflowProcessType.STRING_TO_JSON,
    Enums.WorkflowProcessType.DATA_AGGREGATION,
    Enums.WorkflowProcessType.GET_MINOR,
    Enums.WorkflowProcessType.CALL_STORED_PROCEDURE,
  ];

  /**
   * 현재 위치로 부터 접근 가능한 엔티티 목록
   * @param {Array} processTypes 기본값으로 return value가 있는 프로세스만 반환한다.
   * @param {Boolean} includeSelf 자기 자신도 포함여부
   * @param {Boolean} showAll 전체 다 보여줄지 여부
   * @returns {Array}
   */
  getAccessibleEntityList(
    processTypes = this.returnNodeTypeList,
    includeSelf,
    showAll
  ) {
    /**
     * 1. 현재 노드를 찾는다.
     * 2. 현재 노드에서 연결된 커넥터 노드를 찾는다.
     * 3. 2번에서 검색된 노드의 processFrom을 검색한다.
     * 4. 3번에서 검색된 프로세스노드를 nextProcess라고 한다.
     *    4-1 . 검색된 프로세스들을 entityList에 담는다.
     * 5. 2번이 존재하지 않을때 1번의 processType 이 processEdge가 아니면 nextProcess를 Iterator라고 판단한다.
     * 6. Iterator의 자녀를 순회한다
     *    6-1. 1번부터 반복한다.
     */
    const service = ObjectUtils.isEmpty(this.props.workflow.service)
      ? this.props.workflow.output.service
      : this.props.workflow.service;
    let processList = service.child.process;
    let connectorList = service.child.connector;

    if (!ObjectUtils.isEmpty(this.props.iterator)) {
      processList = [...processList, ...this.props.iterator.child.process];
      connectorList = [
        ...connectorList,
        ...this.props.iterator.child.connector,
      ];
    }

    //현재 노드의 부모가 존재하는데, 검색 항목에 Iterator가 없으면 강제로 주입
    if (
      (this.props.parentNodeId || this.props.iterator) &&
      !processTypes.includes(Enums.WorkflowNodeType.ITERATOR)
    ) {
      processTypes.push(Enums.WorkflowNodeType.ITERATOR);
    }
    // compId
    const compId = this.props.compId;
    //접근 가능한 entity 목록
    let _entityList = [];
    //확인한 노드 목록. 재귀 조회시 중복방문 방지
    let checkedNodeList = {};
    /**
     * To CompId를 이용해서 From을 찾고, Definition 노드 정보를 가져옴
     * @param {*} to
     */
    const findFromAndDef = (toCompId) => {
      //toCompId로 향하는 connector 목록 검색
      const fromConnectorList = connectorList.filter(
        (con) => con.processTo === toCompId
      );

      if (!ArrayUtils.isEmpty(fromConnectorList)) {
        fromConnectorList.forEach((fCon) => {
          const nextProcess = processList.find(
            (pro) => pro.compId === fCon.processFrom
          );
          const isChecked = checkedNodeList[nextProcess.compId];
          if (!isChecked) {
            checkedNodeList[nextProcess.compId] = true;
            if (
              !_entityList.find((e) => e.compId === nextProcess.compId) &&
              StringUtils.includes(nextProcess.processType, processTypes) &&
              nextProcess.id !== compId
            ) {
              _entityList.push(nextProcess);
            }
            findFromAndDef(nextProcess.compId);
          }
        });
      } else {
        //connector가 없는 경우는 iterator라고 판단한다.
        //iterator의 경우 parentNode를 가지고 있다.
        const parentIteratorNode = JsonUtils.findNode(
          processList,
          "compId",
          toCompId
        );
        if (parentIteratorNode && parentIteratorNode.parentNode) {
          //이터레이터가 중복되어 들어간경우 상위 부모까지 찾는다.
          findFromAndDef(parentIteratorNode.parentNode);
          const _iteratorNode = JsonUtils.findNode(
            processList,
            "compId",
            parentIteratorNode.parentNode
          );
          if (!_entityList.find((e) => e.compId === _iteratorNode.compId)) {
            _entityList.push(_iteratorNode);
          }
        }
      }
    };
    /**
     * 목록에 보여줄 entity 생성
     */
    if (!StringUtils.isEmpty(compId)) {
      if (showAll) {
        _entityList = processList.filter(
          (process) =>
            StringUtils.includes(process.processType, processTypes) &&
            process.compId !== compId
        );
      } else {
        //연결된 항목이 있을 때는 연결된 노드만 노출
        findFromAndDef(compId);
        if (includeSelf) {
          const self = processList.find((n) => n.compId === compId);
          _entityList.push(self);
        }
      }
    } else {
      //연결 항목 없는 경우(최초 생성) 모든 Def 노드 노출
      if (this.props.parentNodeId) {
        findFromAndDef(this.props.parentNodeId);
        if (includeSelf) {
          const self = processList.find((n) => n.compId === compId);
          _entityList.push(self);
        }
      } else {
        if (this.props.iterator) {
          //Iterator를 순회하여 entity 목록에 추가
          const loopIterator = (iterator) => {
            //자체 iterator 값 추가
            _entityList.push(this.props.iterator);
            const iteratorNode = JsonUtils.findNode(
              processList,
              "compId",
              iterator.compId
            );
            const iteratorProcessList = iteratorNode.child.process;
            //iterator 내부에 definition 조회
            const definitionInIterator = iteratorProcessList.filter(
              (process) =>
                StringUtils.includes(process.processType, processTypes) &&
                process.compId !== compId &&
                process.parentNode === iterator.compId
            );
            _entityList = [...definitionInIterator];
            //중첩 Iterator의 경우 부모 까지 확인
            if (iterator.parentNode) {
              console.log("For nested iterators ");
              const parentsIteratorNode = JsonUtils.findNode(
                processList,
                "compId",
                iterator.parentNode
              );

              loopIterator(parentsIteratorNode);
            }
          };

          loopIterator(this.props.iterator);

          //iterator 바깥에 있는 def 찾기
          const definitionOutOfIterator = processList.filter(
            (process) =>
              StringUtils.includes(process.processType, processTypes) &&
              process.compId !== compId &&
              !process.parentNode
          );

          _entityList = _entityList.concat([
            ...definitionOutOfIterator.filter(
              (f) => !_entityList.find((e) => e.compId === f.compId)
            ),
          ]);
          // _entityList = [...definitionInIterator, ...definitionOutOfIterator];
        } else {
          _entityList = processList
            .filter(
              (process) =>
                StringUtils.includes(process.processType, processTypes) &&
                process.compId !== compId &&
                !process.parentNode
            )
            .map((n) => (n.data ? n.data.process : n));
        }
      }
    }
    //Def를 정렬 후 , 그외 프로세스에서 DEF 이외의 변수명이 있으면 추가
    let _defEntityList = _entityList.filter(
      (p) =>
        p.processType === Enums.WorkflowProcessType.ENTITY_DEFINITION &&
        p.propertyValue.taskType !== "setEntity"
    );
    let otherList = _entityList.filter(
      (p) => p.processType !== Enums.WorkflowProcessType.ENTITY_DEFINITION
    );
    const defEntityList = [];
    //중복 검사
    for (const def of _defEntityList) {
      if (
        !defEntityList.find(
          (d) =>
            d.propertyValue.entityVariable === def.propertyValue.entityVariable
        )
      ) {
        defEntityList.push(def);
      }
    }
    //def에 없는 항목만 유지
    let enableSelEntityList = otherList.map((s) => getEntityPropertyValue(s));
    enableSelEntityList = enableSelEntityList.flat();
    enableSelEntityList = enableSelEntityList.filter(
      (sentity) =>
        !defEntityList.find((defEntity) => {
          return (
            sentity.entityVariable === defEntity.propertyValue.entityVariable
          );
        })
    );

    const result = [
      ...defEntityList.map((de) => getEntityPropertyValue(de)).flat(),
      ...enableSelEntityList.filter(
        (f) => !defEntityList.find((e) => e.compId === f.compId)
      ),
    ];
    return result;
  }
  /**
   * 프로세스 명 입력부분
   * @returns
   */
  renderProcessNm() {
    return (
      <Row className="mb-3">
        <Col xs={2} className="col-label">
          <Form.Label className="required">Process Name</Form.Label>
        </Col>
        <Col xs={10}>
          <Form.Control
            placeholder="Please enter Process Name"
            value={this.getProcessNm()}
            onChange={this.onChangeParam}
            id={"processNm"}
          />
        </Col>
      </Row>
    );
  }

  WarnMessage(msg) {
    return Message.alert(msg, Enums.MessageType.WARN);
  }

  /**
   * whow Column 구분하는 함수
   * @param {*} columnNm
   * @returns
   */
  isWhoColumn(columnNm) {
    return _isWhoColumn(columnNm);
  }

  renderBody() {}

  /**
   * 코드미러에서 SQL 작성을 위한 테이블 목록 조회
   * @returns
   */
  getTableListForCodeMirror() {
    const connection = User.getConnection(this.props.workspace.tenantMstId);
    const workspace = this.props.workspace;
    if (!connection)
      return Message.alert(
        "Unable to load table list for query autocompletion due to server connection failure.",
        Enums.MessageType.WARN
      );

    const _method = (callback, data) => {
      callback(data, (res) => {
        if (!res.isError) {
          this.setState(
            produce(this.state, (draft) => {
              let schema = {};
              res.data.map((t) => {
                schema[t.tableNm] = [];
                if (t.dataModelEntityFields) {
                  schema[t.tableNm] = t.dataModelEntityFields?.map((f) => ({
                    label: f.columnNm,
                    type: f.pkYn === "Y" ? "keyword" : "text",
                    // info: f.logFieldNm ? f.logFieldNm : "",
                    info: f.columnType ? f.columnType : "",
                  }));
                }
              });
              draft.tableSchema = schema;
            })
          );
        }
      });
    };

    if (connection.connectionType === "direct") {
      const body = {
        connectionInfo: connection,
        moduleCd: workspace.moduleCd,
        searchModule: JSON.stringify(workspace.moduleOption),
        coCd: workspace.coCd,
        tenantId: workspace.tenantId,
      };
      _method(DataModelService.getDirectTableList, body);
    } else {
      const body = {
        accessToken: connection.token,
        ...connection,
        moduleCd: workspace.moduleCd,
        appId: workspace.appId,
        refresh: "Y",
      };
      _method(DataModelService.getTableList, body);
    }
  }

  /**
   * 예외처리
   * 사용하지 않음
   * @returns
   */
  renderException() {
    return (
      <>
        <Row className="mb-3">
          <Col xs={2} className="col-label">
            <Form.Label className="required">Exception Handling</Form.Label>
          </Col>
          <Col xs={10}>
            <BootstrapSwitchButton
              size="sm"
              onstyle="primary"
              offstyle="dark"
              onlabel="Yes"
              offlabel="No"
              width={100}
              checked={this.state.exception}
              id={"exception"}
              onChange={(flag) =>
                this.setState(
                  produce(this.state, (draft) => {
                    if (flag) {
                      draft.exception = {
                        target: "",
                        errorType: "",
                        message: {
                          code: "",
                          text: "",
                        },
                      };
                    } else {
                      delete draft.exception;
                    }
                  })
                )
              }
            />
          </Col>
        </Row>
        {this.state.exception && (
          <div className="exception">
            <Row className="mb-3 ">
              <Col xs={12} className="title">
                <Form.Label>Exception Handling</Form.Label>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col xs={2} className="col-label">
                <Form.Label className="required">
                  Exception Handling Target
                </Form.Label>
              </Col>
              <Col xs={10}>
                <Form.Select value={this.state.exception?.target}>
                  <option value="">Select</option>
                  {this.props.exceptionTargetList.map((error) => {
                    return (
                      <option value={error.codeDtlCd} key={error.codeDtlCd}>
                        {error.codeDtlNm}
                      </option>
                    );
                  })}
                </Form.Select>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col xs={2} className="col-label">
                <Form.Label className="required">Error Type</Form.Label>
              </Col>
              <Col xs={10}>
                <Form.Select value={this.state.exception?.errorType}>
                  <option value="">Select</option>
                  {this.props.errorTypeList.map((error) => {
                    return (
                      <option value={error.codeDtlCd} key={error.codeDtlCd}>
                        {error.codeDtlNm}
                      </option>
                    );
                  })}
                </Form.Select>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col xs={2} className="col-label">
                <Form.Label className="required">Error Message</Form.Label>
              </Col>
              <Col xs={3}>
                <Form.Control
                  value={this.state.exception?.message.code || ""}
                  placeholder="Message Code"
                />
              </Col>
              <Col
                xs={1}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                OR
              </Col>
              <Col xs={6}>
                <Form.Control
                  value={this.state.exception?.message.text || ""}
                  placeholder="Message Text"
                />
              </Col>
            </Row>
          </div>
        )}
      </>
    );
  }

  renderFooter() {
    return (
      <Modal.Footer.Button onClick={this.validateCommonParam}>
        Confirm
      </Modal.Footer.Button>
    );
  }

  /**
   * 모달 렌더링 부분
   * @returns
   */
  renderModal() {
    return (
      <Modal>
        <Modal.Header title={`Process - ${this.props.processType}`} />
        <Modal.Body>
          <UModalTemplate className={"workflow-modal-template"}>
            {this.renderProcessNm()}
            {this.renderBody()}
          </UModalTemplate>
        </Modal.Body>
        <Modal.Footer>{this.renderFooter()}</Modal.Footer>
      </Modal>
    );
  }

  render() {
    return this.renderModal();
  }
}

ProcessModal.propTypes = {};

export default ProcessModal;
