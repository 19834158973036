import { memo, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEdges, useNodes } from "reactflow";
import WorkflowReduxHelper from "../editor/helper/WorkflowReduxHelper";
import Popup from "components/common/Popup";
import ConditionPopup from "page/popup/workflow/ConditionPopup";
import { ModalWidth } from "../WorkflowBuilder";
import { stopEvent } from "components/builder/ui/editor/handler/UIEditorEventHandler";
import { Tooltip } from "@mui/material";
import { BsFillPinFill, BsPinAngle } from "react-icons/bs";
import { Enums } from "components/builder/BuilderEnum";
import { AiOutlineClose } from "react-icons/ai";
import { WorkflowContext } from "page/workflow";

/**
 *  Condition (IF 문) 노드
 */
export const ConditionNode = memo(
  ({
    data: { process, comment, isBundling },
    id,
    selected,
    parentNodeId,
    isChild,
    parentNode,
    addHandler,
    onAddBundle,
    onDeleteFromBundle,
    setBreakpoint,
    removeBreakpoint,
    isTracing,
    isBreakPoint,
    commentCheckMethod,
    inCommunication,
    breakpointType,
    breakpoints,
    debugProcess,
    addBundleControlButton,
    ...props
  }) => {
    const dispatch = useDispatch();
    const workflow = useSelector((state) => state.workflow);
    const workspace = useSelector((state) => state.workspace);
    const [isComment, setIsComment] = useState(false);
    const nodes = useNodes();
    const edges = useEdges();
    const {
      bundle: { bundlingMode },
    } = useContext(WorkflowContext); //번들링 관련

    useEffect(() => {
      setIsComment(commentCheckMethod());
    }, [comment]);

    const onOpenProcessPopup = (e) => {
      const callbackFnc = (callbackData) => {
        const body = {
          ...process,
          propertyValue: callbackData,
        };
        if (parentNode.type === Enums.WorkflowNodeType.ITERATOR && isChild) {
          WorkflowReduxHelper.updateIteratorNode(
            dispatch,
            [body],
            parentNode,
            workflow
          );
        } else {
          WorkflowReduxHelper.updateNodes(dispatch, [body], workflow);
        }

        Popup.close();
      };
      Popup.open(
        <ConditionPopup
          parentNodeId={parentNodeId}
          workspace={workspace}
          callbackFnc={callbackFnc}
          workflow={workflow.output}
          processInfo={process.propertyValue}
          processType={process.processType}
          nodes={nodes}
          edges={edges}
          compId={id}
        />,
        {
          keyDownEvent: false,
          style: {
            content: {
              width: ModalWidth[process.processType] || ModalWidth.other,
            },
          },
        }
      );
    };

    /**
     * 프로세스 삭제
     * @param {*} e
     */
    const onDeleteProcess = (e) => {
      stopEvent(e);
      WorkflowReduxHelper.deleteProcess(dispatch, process.compId, workflow);
    };

    const renderForDiamond = () => {
      return (
        <div>
          {inCommunication && isBreakPoint ? (
            <div className={`debug-info ${breakpointType} `}>
              <div style={{ fontSize: "13px", fontWeight: "normal" }}>
                Before
              </div>
              <div style={{ fontSize: "13px", fontWeight: "normal" }}>
                After
              </div>
            </div>
          ) : (
            <></>
          )}
          {addBundleControlButton(
            <div>
              <Tooltip title="Break Point" placement="top">
                {breakpoints.find((bp) => bp.compId === process.compId) ? (
                  <button
                    className={`workflow-condition-btn ${
                      isBreakPoint || isTracing
                        ? " condition-breakpoint-btn "
                        : ""
                    } `}
                    onClick={(e) =>
                      removeBreakpoint(e, { compId: process.compId })
                    }
                  >
                    <BsFillPinFill size={20} />
                  </button>
                ) : (
                  <button
                    className={`workflow-condition-btn ${
                      isBreakPoint || isTracing
                        ? " condition-breakpoint-btn "
                        : ""
                    } `}
                    onClick={(e) =>
                      setBreakpoint(e, {
                        compId: process.compId,
                        type: Enums.WorkflowNodeType.CONDITION,
                        processName: process.propertyValue.processNm,
                        processType: process.processType,
                      })
                    }
                  >
                    <BsPinAngle size={20} />
                  </button>
                )}
              </Tooltip>
              <button
                className={`workflow-condition-btn ${
                  isBreakPoint || isTracing ? " condition-breakpoint-btn " : ""
                }`}
                onClick={onDeleteProcess}
              >
                <AiOutlineClose size={20} />
              </button>
            </div>
          )}

          <div className="name" style={{ maxWidth: "200px" }}>
            {process.propertyValue.processNm}
          </div>
        </div>
      );
    };

    return (
      <div
        className={`workflow-node 
          ${isChild ? `${parentNodeId}_child` : ""} 
          `}
        style={{ position: "relative" }}
      >
        <div
          className={`selected-marker 
          ${selected ? " selected" : ""} 
          ${bundlingMode && isBundling ? " bundling" : ""} 
          ${isBreakPoint ? " condition-breakpoint " : ""}
          ${isTracing ? " traced" : ""}
          `}
        />

        <div
          className={`workflow-condition-node nodrag ${process.processType} `}
        />

        <div onDoubleClick={onOpenProcessPopup} className="condition-content">
          {renderForDiamond()}
        </div>
        {isComment && <div className="node-comment">Comment</div>}
        {addHandler()}
      </div>
    );
  }
);
