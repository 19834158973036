import React, { PureComponent, CSSProperties } from "react";
import UModalManager from "components/common/modal/UModalManager";
import * as Effect from "components/common/modal/Effect";
import { ObjectUtils, StringUtils } from "components/common/utils/CommonUtils";

class Popup extends PureComponent {
  /**
   * Popup open
   * @param {Component} component
   * @param {Object} options
   * @param {Boolean} options.onRequestClose
   * @param {Map} options.effect
   * @param {Boolean} options.keyDownEvent
   * @param {Object} options.style
   * @param {CSSProperties} options.style.content
   * @param {Boolean} options.disableDragging
   *   - onRequestClose : 팝업창 주변 클릭시 닫는걸 허용할지 여부
   *       boolean형 : true,false
   *       function형 : function의 return값에 따라 결정
   *   - onCloseCallback : 팝업창 close시 callback 함수
   *   - effect
   *   - keyDownEvent : default true
   *   - style
   *   - disableDragging : drag 가능 여부
   */
  static open = (component, options) => {
    let onRequestClose = () => false;
    let effect = Effect.SlideFromTop;
    if (!ObjectUtils.isEmpty(options)) {
      if (options.onRequestClose === true || options.onRequestClose === false) {
        onRequestClose = () => options.onRequestClose;
      } else if (typeof options.onRequestClose == "function") {
        onRequestClose = () => {
          options.onRequestClose.call(this);
          return true;
        };
      }

      if (!ObjectUtils.isEmpty(options.effect)) effect = options.effect;
    }

    let programId =
      component.props && StringUtils.defaultString(component.props.programId);
    UModalManager.open(
      programId,
      <UModalManager
        element={component}
        onRequestClose={onRequestClose}
        onCloseCallback={options.onCloseCallback}
        disableDragging={options.disableDragging}
        effect={effect}
        style={options.style}
        keyDownEvent={options.keyDownEvent}
      />
    );
  };

  /**
   * popup close
   * @param {function} callbackFunction
   */
  static close = (programId, callbackFunction) => {
    if (typeof callbackFunction == "function") {
      callbackFunction.call(this);
      UModalManager.close(programId);
    } else {
      UModalManager.close(programId);
    }
  };
}

export default Popup;
