import UIComponent from "components/builder/ui/uiComponents/UIComponent";
import { PropertiesHeader } from "components/builder/ui/uiComponents/UIComponentStyle";

import { ObjectUtils, StringUtils } from "components/common/utils/CommonUtils";
import UIComponentSection from "components/builder/ui/editor/UIComponentSection";
import { ComponentSavePopupButton } from "page/popup/ComponentSavePopup";

class LayoutComponent extends UIComponent {
  /**
   * 해당 Component의 Title
   * @param {*} child
   * @returns
   */
  renderComponentTitle = (child) => {
    return (
      <PropertiesHeader isMobile={this.props.mobile.isMobileEditor}>
        <div>
          {"Layout Components > "}
          {child}
        </div>

        <ComponentSavePopupButton
          getComponentCodeFromOutput={this.props.fn.getComponentCodeFromOutput}
          componentInfo={this.props.componentInfo}
        />
      </PropertiesHeader>
    );
  };

  /**
   *
   * @returns
   */
  getPropsStyle = (componentInfo) => {
    return ObjectUtils.isEmpty(componentInfo.propertyValue)
      ? {}
      : componentInfo.propertyValue.style || {};
  };

  /**
   * Editor의 component를 Redering
   * << Layout editor props>>
   *   - compId - 현재 component의 고유 ID
   *   - componentInfo - drag & drop시 생성된 component object
   *   - style - dragging style이 포함된 style (사용자가 정의한 style은 각 component에서 적절히 적용해야함)
   *   - event="renderEditor" - 요청 구분
   * @returns
   */
  renderEditor = () => {
    return (
      /**
       * UIComponentSection props
       * -item
       * -style
       * -className
       */
      <UIComponentSection
        item={this.props.componentInfo}
        style={{ ...this.props.style, ...this.state.propertyValue.style }}
        className={`editor-base draggable editor-layout ${StringUtils.defaultString(
          this.state.editorAttr.className,
          "builder-layout"
        )}`}
      >
        {this.props.children}
      </UIComponentSection>
    );
  };
}

export default LayoutComponent;
