import React, { useState, useRef } from "react";
import StringUtils from "components/common/utils/StringUtils";
import {
  Button,
  CloseButton,
  OverlayTrigger,
  Popover,
  Stack,
} from "react-bootstrap";

const UPopover = (props) => {
  const {
    buttonProps,
    buttonText,
    popoverPops,
    title,
    children,
    placement,
    isMobileEditor,
  } = props;
  const [popoverShow, setPopoverShow] = useState(false);
  const [popoverTarget, setPopoverTarget] = useState(null);
  const popoverRef = useRef();

  const defaultButtonProps = {
    variant: "outline-secondary",
    size: "sm",
    className: isMobileEditor
      ? "mobile-font-color builder-help"
      : "light-font-color builder-help",
  };

  const defaultPopoverProps = {
    placement: "bottom",
    containerPadding: 20,
  };

  const handleClick = (event) => {
    if (event === true || event === false) {
      setPopoverShow(event);
    }

    //setPopoverTarget(event.target);
  };

  const getPopover = () => {
    return (
      <Popover>
        <Popover.Header>
          <Stack
            as="span"
            direction="horizontal"
            className="align-items-baseline"
          >
            <h5 className="flex-grow-1 mb-0">{title}</h5>
            <CloseButton onClick={(e) => handleClick(false)} />
          </Stack>
        </Popover.Header>
        <Popover.Body>{children}</Popover.Body>
      </Popover>
    );
  };

  return (
    <div ref={popoverRef} style={props.style}>
      <OverlayTrigger
        trigger="click"
        overlay={getPopover()}
        show={popoverShow}
        container={popoverRef}
        rootClose
        onToggle={handleClick}
        {...{ ...defaultPopoverProps, ...popoverPops }}
      >
        <Button {...{ ...defaultButtonProps, ...buttonProps }}>
          {StringUtils.defaultString(buttonText, "Help")}
        </Button>
      </OverlayTrigger>
    </div>
  );
};

export default UPopover;
