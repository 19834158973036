import React from "react";
import { useContext } from "react";
import { AppContext } from "components/common/AppContextProvider";

const ContextExample = () => {
  /*
  Context의 Data를 접근하는 방법
    1. contextType으로 Context 접근 ==> Class component
    2. useContext로 Context 접근 ==> hook 사용이 가능한 function component
    3. Consumer로 Context 접근하는 벙법 (Class, function component)
 */

  /* 
   * Class형 Component 사용법 (contextType으로 Context 접근)
    ------ sample code -----------
    static contextType = AppContext;
    render() {
        const mst1Codes = this.context.code.getCodeList("Z0000"); //masterCode가 "Z0000" 인 코드 목록을 가져온다.
        <ul>
        {mst1Codes.map((code, index) => {
            return (
            <li key={code.codeDtlCd}>
                {code.codeDtlCd}:{code.codeDtlNm}
            </li>
            );
        })}
        </ul>
    }
 */

  /*
   * Function 형 Component 사용법 은 아래와 같다. (useContext로 Context 접근)
   */
  const mst1Codes = useContext(AppContext).code.getCodeList("Z0000");

  return (
    <div className="content">
      <h1>Access to Context as contextType or useContext</h1>
      <ul>
        {mst1Codes.map((code, index) => {
          return (
            <li key={code.codeDtlCd}>
              {code.codeDtlCd}:{code.codeDtlNm}
            </li>
          );
        })}

        {/* 아래는 Consumer를 이용해 rendering 하는 방법 */}
      </ul>

      <br />
      <h1>Access to Context as Consumer</h1>
      <ul>
        <AppContext.Consumer>
          {(agrs) => {
            return agrs.code.getCodeList("Z0000").map((code, index) => (
              <li key={code.codeDtlCd}>
                {code.codeDtlCd} : {code.codeDtlNm}
              </li>
            ));
          }}
        </AppContext.Consumer>
      </ul>
    </div>
  );
};

export default ContextExample;
