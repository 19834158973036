import { Grid } from "@mui/material";
import { AppContext } from "components/common/AppContextProvider";
import Popup from "components/common/Popup";
import MuiConfig from "components/common/config/MuiConfig";
import WijmoGrid from "components/common/element/WijmoGrid";
import ObjectUtils from "components/common/utils/ObjectUtils";
import TenantBatchApplyPopup from "page/popup/workspace/TenantBatchApplyPopup";
import TenantEditPopup from "page/popup/workspace/TenantEditPopup";
import { useContext, useState } from "react";
import { Button } from "react-bootstrap";
import AppService from "services/common/AppService";

function TenantManage() {
  const {
    application: { availableList: applicationList },
  } = useContext(AppContext);
  const [appId, setAppId] = useState(
    applicationList ? applicationList[0].appId : null
  );
  const [tenantMst, setTenantMst] = useState({
    tenantId: "",
    coCd: "",
    tenantNm: "",
    coNm: "",
  });

  const [tenantList, setTenantList] = useState([]);
  const [releaseList, setReleaseList] = useState([]);

  const columns = [
    {
      field: "appGroupNm",
      headerName: "App Group",
      width: 150,
    },
    {
      field: "appNm",
      headerName: "App Name",
      width: "*",
    },
  ];

  const tenantColumns = [
    {
      field: "tenantId",
      headerName: "Tenant ID",
    },
    {
      field: "tenantNm",
      headerName: "Tenant Name",
    },
    {
      field: "coCd",
      headerName: "Company Code",
    },
    {
      field: "coNm",
      headerName: "Company Name",
    },
  ];
  const moduleColumns = [
    {
      field: "moduleNm",
      headerName: "Module Name",
      width: "*",
    },
    {
      field: "version",
      headerName: "Version",
      width: "*",
    },
  ];

  const onSelecteApplication = (param) => {
    setAppId(param.appId);
    getAllReleaseList(param.appId);
    setReleaseList([]);
  };

  const getAllReleaseList = (_appId) => {
    AppService.getTenantDistinctList(
      {
        appId: _appId ? _appId : appId,
      },
      (res) => {
        let list = res.data.map((item) => ({
          ...item,
          id: item.tenantId + item.coCd,
          appId: _appId,
        }));
        list = list.filter((t) => t.tenantId !== "*");
        setTenantList(list);
      }
    );
  };
  const onSelectTenant = (param) => {
    setTenantMst(param);
    getAppmoduleMst(param);
    // setTenantMst(param.row);
    // getAppmoduleMst(param.row);
  };

  const getAppmoduleMst = ({
    appId: _appId,
    tenantId: _tenantId,
    coCd: _coCd,
  }) => {
    AppService.getAppModuleMst(
      {
        appId: _appId,
        tenantId: _tenantId ? _tenantId : tenantMst.tenantId,
        coCd: _coCd ? _coCd : tenantMst.coCd,
      },
      (res) => {
        setReleaseList(res.data);
      }
    );
  };

  const onOpenTenantAddModal = (e) => {
    if (e) e.preventDefault();

    const options = {
      effect: Popup.ScaleUp, //Effect.SlideFromTop(default)를 Effect.ScaleUp 로 변경
      style: {
        content: {
          width: "400px", //popup의 크기를 50% (default 60%)
        },
      },
    };

    Popup.open(
      <TenantBatchApplyPopup appId={appId} applicationList={applicationList} />,
      options
    );
  };

  const onOpenTenantEditModal = (e) => {
    if (e) e.preventDefault();
    const options = {
      effect: Popup.ScaleUp, //Effect.SlideFromTop(default)를 Effect.ScaleUp 로 변경
      style: {
        content: {
          width: "400px", //popup의 크기를 50% (default 60%)
        },
      },
    };

    Popup.open(
      <TenantEditPopup
        tenantMst={tenantMst}
        callback={() => {
          getAllReleaseList();
          setReleaseList([]);
        }}
        releaseList={releaseList}
      />,
      options
    );
  };

  return (
    <>
      <div className="setting-header">Tenant Workspace Management</div>
      <div className="setting-container">
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                fontSize: "larger",
                fontWeight: "bold",
              }}
            >
              Application List ▶
            </div>
          </Grid>
          <Grid item xs={4}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                fontSize: "larger",
                fontWeight: "bold",
              }}
            >
              Registered Tenant List ▶
            </div>
          </Grid>
          <Grid item xs={4}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                fontSize: "larger",
                fontWeight: "bold",
              }}
            >
              Module & Version
            </div>
          </Grid>
          <Grid item xs={4}>
            <WijmoGrid
              getRowId={(row) => row.appId}
              columns={columns}
              rows={applicationList}
              onRowClick={onSelecteApplication}
              emptyMessage="There are no accessible applications."
              pageSize={100}
              style={{ height: "50vh" }}
            />
          </Grid>
          <Grid item xs={4}>
            <WijmoGrid
              getRowId={(row) => row.id}
              columns={tenantColumns}
              rows={tenantList}
              {...MuiConfig.grid.options}
              emptyMessage={
                <>
                  <div>No application is selected, </div>
                  <div>or there is no data except Standard Tenant.</div>
                </>
              }
              pageSize={100}
              rowsPerPageOptions={[100]}
              onRowClick={onSelectTenant}
              style={{ height: "50vh" }}
            />
          </Grid>
          <Grid item xs={4}>
            <WijmoGrid
              getRowId={(row) => row.appReleaseId}
              columns={moduleColumns}
              rows={releaseList}
              {...MuiConfig.grid.options}
              emptyMessage="Select Tenant."
              pageSize={100}
              rowsPerPageOptions={[100]}
              style={{ height: "50vh" }}
            />
          </Grid>
        </Grid>
      </div>
      <div className="setting-button">
        <Button variant="success" onClick={onOpenTenantAddModal}>
          {" "}
          Add Tenant
        </Button>
        {!ObjectUtils.isEmptyValue(tenantMst) && (
          <Button variant="outline-secondary" onClick={onOpenTenantEditModal}>
            {" "}
            Edit Tenant
          </Button>
        )}
      </div>
    </>
  );
}

export default TenantManage;
