import React, { useRef } from "react";
import UserUtils from "components/common/utils/UserUtils";
import SunEditor from "suneditor-react";
import { Col, Row } from "react-bootstrap";

const Flowchart = ({ contents, ...props }) => {
  return (
    <>
      <Row>
        <Col>
          <div className="content-title">Flowchart</div>
        </Col>
      </Row>
      <Row>
        <Col>
          <SunEditor
            id={"flowchart"}
            lang={UserUtils.getLanguage().toLowerCase()}
            setContents={contents}
            onBlur={(e) => {
              props.onBlur(e);
            }}
            getSunEditorInstance={(editor) =>
              props.getSunEditorInstance(editor)
            }
            setDefaultStyle="font-family: 'Noto Sans KR', sans-serif;  min-height : 500px; max-height : 55vh"
            setOptions={{
              font: [
                "Noto Sans KR",
                "Arial",
                "Comic Sans MS",
                "Courier New",
                "Impact",
                "Georgia",
                "tahoma",
                "Trebuchet MS",
                "Verdana",
              ],
              fontSize: [
                8, 9, 10, 11, 12, 13, 14, 16, 18, 20, 22, 24, 26, 28, 36, 48,
                72,
              ],
              buttonList: [
                ["font", "fontSize", "formatBlock"],
                [
                  "bold",
                  "underline",
                  "italic",
                  "strike",
                  "fontColor",
                  "hiliteColor",
                ],
              ],
            }}
          />
        </Col>
      </Row>
    </>
  );
};

export default Flowchart;
