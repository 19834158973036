import { stopEvent } from "components/builder/ui/editor/handler/UIEditorEventHandler";
import ArrayUtils from "components/common/utils/ArrayUtils";
import { useEffect, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import { AiOutlineClose } from "react-icons/ai";
import { BiMinus, BiWindow } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { changeLogState, setCsServiceLog } from "../reducer/WorkflowAction";

function WorkflowCsLogConsole() {
  const { serviceInfo, CSFileLogViewMode, output } = useSelector(
    (state) => state.workflow
  );

  const [isShow, setIsShow] = useState(false);
  const consoleWrapperRef = useRef();
  const pageYRef = useRef();
  const titleHeight = useRef(30);
  const terminalRef = useRef();
  const gridBodyRef = useRef();
  const dispatch = useDispatch();

  useEffect(() => {
    if (CSFileLogViewMode) {
      setIsShow(true);
    }
  }, [CSFileLogViewMode]);

  useEffect(() => {
    if (!serviceInfo.convertLog) {
      onCloseConsole();
    }
  }, [output]);

  /**
   * 콘솔 닫기
   * @param {*} e
   */
  const onCloseConsole = (e) => {
    if (consoleWrapperRef.current.clientHeight > titleHeight.current) {
      consoleWrapperRef.current.style = `height:${titleHeight.current}px;transition:0.3s`;
      setTimeout(() => {
        setIsShow(false);
      }, 300);
    } else {
      setIsShow(false);
    }
  };

  /**
   * 최대화
   * @param {*} e
   */
  const onClickMaximum = (e) => {
    stopEvent(e);
    const windowHeight = window.innerHeight;
    consoleWrapperRef.current.style = `height:${
      windowHeight - 50
    }px;transition:0.3s`;
    terminalRef.current.style = `height:${
      windowHeight - 50 - 30
    }px;transition:0.3s`;
  };

  /**
   * 최소화
   * @param {*} e
   */
  const onClickMinimum = (e) => {
    stopEvent(e);
    consoleWrapperRef.current.style = `height:${titleHeight.current}px;transition:0.3s`;
    terminalRef.current.style = `height:${
      titleHeight.current - 30
    }px;transition:0.3s`;
  };

  const onDragStart = (e) => {
    e.dataTransfer.setDragImage(e.target, e.pageX, 0);
  };

  /**
   * 드래그 이벤트
   * @param {*} e
   * @returns
   */
  const onDragComponent = (e) => {
    e.stopPropagation();
    const windowHeight = window.innerHeight;
    if (pageYRef.current === e.pageY) return false;
    else if (e.pageY) {
      pageYRef.current = e.pageY;
      let componentHeight = windowHeight - e.pageY;
      if (windowHeight - e.pageY < titleHeight.current) {
        componentHeight = titleHeight.current;
      } else if (e.pageY <= 50) {
        componentHeight = windowHeight - 50; //헤더 높이 까지만 설정
      }

      consoleWrapperRef.current.style = `height:${componentHeight}px`;
      terminalRef.current.style = `height:${componentHeight - 30}px;`;
      gridBodyRef.current.style = `height:${
        componentHeight - 30 - 35
      }px;overflow-y:auto`;
    }
  };

  /**
   * 로그 확인 여부 변경 이벤트
   * @param {*} log
   * @param {*} index
   */
  const onChangeLog = (state, index) => {
    dispatch(changeLogState({ state, index }));
  };

  return (
    <div
      className={`debug-console-wrapper ${isShow ? "show" : "hidden"}`}
      ref={consoleWrapperRef}
    >
      <div
        className="header"
        onDragStart={onDragStart}
        onDrag={onDragComponent}
        draggable
      >
        <div>{`${serviceInfo.serviceName} Log`}</div>
        <div>
          <button onClick={onClickMinimum}>
            <BiMinus />
          </button>
          <button onClick={onClickMaximum}>
            <BiWindow />
          </button>
          <button onClick={onCloseConsole}>
            <AiOutlineClose />
          </button>
        </div>
      </div>
      <div ref={terminalRef} className="cs-log-console-grid">
        <div className="cs-log-console-grid-row row-header">
          <div>C# Line</div>
          <div>Log Type</div>
          <div>Log Message</div>
          <div>Confirm Status</div>
          <div>Editor</div>
        </div>
        <div ref={gridBodyRef} style={{ overflowY: "auto" }}>
          {!ArrayUtils.isEmpty(serviceInfo.convertLog) &&
            serviceInfo.convertLog.map((log = {}, index) => {
              return (
                <div key={index} className="cs-log-console-grid-row">
                  <div>{log.csLineNo}</div>
                  <div>{log.logType}</div>
                  <div>{log.message}</div>
                  <div>
                    <Form.Select
                      value={log.state}
                      size="sm"
                      onChange={(e) => onChangeLog(e.target.value, index)}
                    >
                      <option value={"Y"}>Yes</option>
                      <option value={"N"}>No</option>
                    </Form.Select>
                  </div>
                  <div>{log.updtUserId}</div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
}

export default WorkflowCsLogConsole;
