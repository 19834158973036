import LayoutComponent from "components/builder/ui/uiComponents/layout/LayoutComponent";
import {
  PropertyLable,
  PropertyValue,
} from "components/builder/ui/uiComponents/UIComponentStyle";
import { Accordion } from "react-bootstrap";
import React from "react";

import { ObjectUtils, StringUtils } from "components/common/utils/CommonUtils";
import UIComponentSection from "components/builder/ui/editor/UIComponentSection";
import USelectbox from "components/common/element/USelectbox";
import BootstrapSwitchButton from "bootstrap-switch-button-react";

class Block extends LayoutComponent {
  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      activeKey: [0],
    };
    this.displayList = [
      { id: "block", text: "block" },
      { id: "flex", text: "flex" },
      { id: "inline-block", text: "inline-block" },
      { id: "inline-flex", text: "inline-flex" },
    ];
  }

  displayChange = (e) => {
    if (e.target.value === "flex" || e.target.value === "inline-flex") {
      this.onChangePropertyStyle(
        "flexDirection",
        document.getElementById("flexDirection").value
      );
      this.onChangePropertyStyle(
        "alignContent",
        document.getElementById("alignContent").value
      );
      this.onChangePropertyStyle(
        "justifyContent",
        document.getElementById("justifyContent").value
      );
      this.onChangePropertyStyle(
        "alignItems",
        document.getElementById("alignItems").value
      );
    } else {
      this.onChangePropertyStyle("flexDirection", "");
      this.onChangePropertyStyle("alignContent", "");
      this.onChangePropertyStyle("justifyContent", "");
      this.onChangePropertyStyle("alignItems", "");
    }

    this.onChangeStyle(e);
  };

  /**
   * Properties tab panel을 Redering
   * @returns
   */
  renderPropertiesPanel = () => {
    return (
      <React.Fragment>
        {/* Title */}
        {this.renderComponentTitle("Block")}
        <Accordion defaultActiveKey={[0, 1]} alwaysOpen>
          <Accordion.Item eventKey={0}>
            <Accordion.Header>Basic Info</Accordion.Header>
            <Accordion.Body>
              <PropertyLable>ID</PropertyLable>
              <PropertyValue>
                <input
                  type="text"
                  id="id"
                  defaultValue={StringUtils.defaultString(
                    this.state.propertyValue.id
                  )}
                  className="form-control form-control-sm"
                  onBlur={this.onChange}
                />
              </PropertyValue>
              <PropertyLable>Button Group</PropertyLable>
              <PropertyValue>
                <BootstrapSwitchButton
                  id="isButtonGroup"
                  checked={StringUtils.defaultString(
                    this.state.propertyValue.isButtonGroup,
                    false
                  )}
                  size="sm"
                  onstyle="primary"
                  offstyle="dark"
                  onlabel="Yes"
                  offlabel="No"
                  onChange={(boolean) =>
                    this.onChange({
                      target: {
                        id: "isButtonGroup",
                        value: boolean,
                      },
                    })
                  }
                />
              </PropertyValue>
              <PropertyLable>Attributes</PropertyLable>
              <PropertyValue>
                display
                <USelectbox
                  id="display"
                  items={this.displayList}
                  options={{
                    matchId: "id",
                    matchNm: "text",
                  }}
                  defaultValue={StringUtils.defaultString(
                    this.state.propertyValue.style?.display
                  )}
                  onChange={this.displayChange}
                />
              </PropertyValue>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item
            eventKey={1}
            style={
              this.state.propertyValue.style?.display === "flex" ||
              this.state.propertyValue.style?.display === "inline-flex"
                ? {}
                : { display: "none" }
            }
          >
            <Accordion.Body>
              <PropertyLable />
              <PropertyValue>
                flex-direction
                <USelectbox
                  id="flexDirection"
                  items={[
                    { id: "row", text: "row" },
                    { id: "column", text: "column" },
                    { id: "row-reverse", text: "row-reverse" },
                    { id: "column-reverse", text: "column-reverse" },
                  ]}
                  options={{
                    matchId: "id",
                    matchNm: "text",
                  }}
                  defaultValue={StringUtils.defaultString(
                    this.state.propertyValue.style?.flexDirection
                  )}
                  onChange={this.onChangeStyle}
                />
              </PropertyValue>
              <PropertyLable />
              <PropertyValue>
                align-content
                <USelectbox
                  id="alignContent"
                  items={[
                    { id: "normal", text: "normal" },
                    { id: "center", text: "center" },
                    { id: "flex-start", text: "flex-start" },
                    { id: "flex-end", text: "flex-end" },
                    { id: "space-around", text: "space-around" },
                    { id: "space-between", text: "space-between" },
                    { id: "stretch", text: "stretch" },
                  ]}
                  options={{
                    matchId: "id",
                    matchNm: "text",
                  }}
                  defaultValue={StringUtils.defaultString(
                    this.state.propertyValue.style?.alignContent
                  )}
                  onChange={this.onChangeStyle}
                />
              </PropertyValue>
              <PropertyLable />
              <PropertyValue>
                justify-content
                <USelectbox
                  id="justifyContent"
                  items={[
                    { id: "normal", text: "normal" },
                    { id: "center", text: "center" },
                    { id: "flex-start", text: "flex-start" },
                    { id: "flex-end", text: "flex-end" },
                    { id: "space-around", text: "space-around" },
                    { id: "space-between", text: "space-between" },
                    { id: "space-evenly", text: "space-evenly" },
                  ]}
                  options={{
                    matchId: "id",
                    matchNm: "text",
                  }}
                  defaultValue={StringUtils.defaultString(
                    this.state.propertyValue.style?.justifyContent
                  )}
                  onChange={this.onChangeStyle}
                />
              </PropertyValue>
              <PropertyLable />
              <PropertyValue>
                align-items
                <USelectbox
                  id="alignItems"
                  items={[
                    { id: "normal", text: "normal" },
                    { id: "center", text: "center" },
                    { id: "flex-start", text: "flex-start" },
                    { id: "flex-end", text: "flex-end" },
                    { id: "stretch", text: "stretch" },
                    { id: "baseline", text: "baseline" },
                  ]}
                  options={{
                    matchId: "id",
                    matchNm: "text",
                  }}
                  defaultValue={StringUtils.defaultString(
                    this.state.propertyValue.style?.alignItems
                  )}
                  onChange={this.onChangeStyle}
                />
              </PropertyValue>
            </Accordion.Body>
          </Accordion.Item>

          {/* Style property */}
          {this.renderStylePanel("Block", 99)}
        </Accordion>
      </React.Fragment>
    );
  };

  /**
   * Editor의 component를 Redering
   * << Layout editor props>>
   *   - compId - 현재 component의 고유 ID
   *   - componentInfo - drag & drop시 생성된 component object
   *   - style - dragging style이 포함된 style (사용자가 정의한 style은 각 component에서 적절히 적용해야함)
   *   - event="renderEditor" - 요청 구분
   * @returns
   */
  renderEditor = () => {
    /**
     * UIComponentSection props
     * -item
     * -style
     * -className
     */
    return (
      <UIComponentSection
        item={this.props.componentInfo}
        style={{
          ...this.props.style,
          ...this.getPropsStyle(this.props.componentInfo),
        }}
        title="BLOCK"
        className={`editor-base draggable editor-container ${StringUtils.defaultString(
          this.state.editorAttr.className,
          "builder-block"
        )}`}
      >
        {this.props.children}
      </UIComponentSection>
    );
  };
}
export default Block;
