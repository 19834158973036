import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { MessageType } from "components/builder/BuilderEnum";
import { AppContext } from "components/common/AppContextProvider";
import Message from "components/common/Message";
import Popup from "components/common/Popup";
import WijmoGrid from "components/common/element/WijmoGrid";
import CommonUtils from "components/common/utils/CommonUtils";
import {
  AdminSettingButton,
  AdminSettingContents,
  AdminSettingSearchBar,
} from "components/setting/config/AdminSettingMain";
import AppManagerRegistPopup from "page/popup/app/AppManagerRegistPopup";
import AppRegisterPopup from "page/popup/app/AppRegisterPopup";
import { useContext, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { BiEditAlt } from "react-icons/bi";
import { RiDeleteBinLine } from "react-icons/ri";
import { useSelector } from "react-redux";
import AppService from "services/common/AppService";
import CodeService from "services/common/CodeService";
import styled from "styled-components";

const ComponentManageBox = styled(AdminSettingContents)`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
const SearchBar = styled(AdminSettingSearchBar)`
  display: flex;
  gap: 15px;
  max-height: 36px;
`;

function AppManage() {
  const {
    application: { setList },
  } = useContext(AppContext);
  const [appGroupCdList, setAppGroupCdList] = useState([]);
  const [appGroupCd, setAppGroupCd] = useState("");
  const [appNm, setAppNm] = useState("");
  const [appDesc, setAppDesc] = useState("");
  const [ApplicationList, setApplicationList] = useState([]);
  const [pageSize, setPageSize] = useState(20);

  const { tenantMstId } = useSelector((state) => state.workspace);

  useEffect(() => {
    CodeService.getCodeCombo({ codeMstCd: "Z0035" }, (res) => {
      setAppGroupCdList(res.data);
    });
    getAppList();

    return () => {
      setAppGroupCdList([]);
      setApplicationList([]);
    };
  }, []);

  const columns = [
    {
      field: "appId",
      headerName: "APP ID",
      headerAlign: "center",
      width: 150,
      align: "left",
    },
    {
      field: "appGroupNm",
      headerName: "App Group",
      headerAlign: "center",
      align: "left",
      width: 150,
    },
    {
      field: "appNm",
      headerName: "App Name",
      headerAlign: "center",
      width: "*",
    },
    {
      field: "appDesc",
      headerName: "App Description",
      headerAlign: "center",
      width: "*",
    },
    {
      field: "appManagerViewer",
      headerName: "Manager",
      headerAlign: "center",
      width: 150,
      align: "center",
      renderCell: (param) => {
        return (
          <Button size="sm" onClick={(e) => openAppMangerRegistry(e, param)}>
            Set Manager
          </Button>
        );
      },
    },
    {
      field: "updtUserId",
      headerName: "update User",
      headerAlign: "center",
      align: "left",
      width: 150,
    },
    {
      field: "updtDt",
      headerName: "update Date",
      headerAlign: "center",
      align: "center",
      width: 200,
      renderCell: (params) => {
        return <>{CommonUtils.getDate(params.updtDt)}</>;
      },
      // valueGetter: (params) => CommonUtils.getDate(params.row.updtDt),
    },
    {
      field: "update",
      headerName: "Update",
      headerAlign: "center",
      align: "center",
      width: 100,
      renderCell: (param) => {
        return (
          <Button
            size="sm"
            variant="outline-success"
            onClick={(e) => openAppRegistry(e, param)}
          >
            <BiEditAlt />
          </Button>
        );
      },
    },
    {
      field: "remark",
      headerName: "Delete",
      headerAlign: "center",
      align: "center",
      width: 100,
      renderCell: (param) => {
        return (
          <Button
            size="sm"
            variant="outline-danger"
            onClick={(e) => deleteApp(e, param)}
            // style={{width:'100%',height:'100%'}}
          >
            <RiDeleteBinLine />
          </Button>
        );
      },
    },
  ];

  const getAppList = (e) => {
    if (e) e.preventDefault();
    const body = {
      appGroupCd,
      appNm,
      appDesc,
    };
    AppService.getAppList(body, (res) => {
      setApplicationList(res.data);
      setList(res.data);
    });
  };

  const openAppRegistry = (e, app) => {
    if (e) e.preventDefault();

    const options = {
      effect: Popup.ScaleUp, //Effect.SlideFromTop(default)를 Effect.ScaleUp 로 변경
      style: {
        content: {
          width: "850px", //popup의 크기를 50% (default 60%)
        },
      },
    };
    const saveAfter = (body) => {
      getAppList();
    };

    Popup.open(
      <AppRegisterPopup
        app={app}
        appTypeList={appGroupCdList}
        saveCallback={saveAfter}
      />,
      options
    );
  };
  const openAppMangerRegistry = (e, app) => {
    if (e) e.preventDefault();

    const options = {
      effect: Popup.ScaleUp, //Effect.SlideFromTop(default)를 Effect.ScaleUp 로 변경
      style: {
        content: {
          width: "680px", //popup의 크기를 50% (default 60%)
        },
      },
    };

    Popup.open(<AppManagerRegistPopup app={app} />, options);
  };

  const deleteApp = (e, app) => {
    Message.confirm(`Are you sure to delete ${app.appNm} ?`, () => {
      AppService.deleteApplication(app, (res) => {
        Message.alert(res.message, MessageType.SUCCESS);
        getAppList();
      });
    });
  };

  return (
    <ComponentManageBox>
      <SearchBar>
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <FormControl size="small" fullWidth>
              <InputLabel id="app-label">APP Group</InputLabel>
              <Select
                labelId="app-label"
                label="APP Group"
                value={appGroupCd}
                onChange={(e) => setAppGroupCd(e.target.value)}
              >
                <MenuItem>select</MenuItem>
                {appGroupCdList.map((app) => {
                  return (
                    <MenuItem value={app.id} key={app.id}>
                      {app.text}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={2}>
            <TextField
              fullWidth
              size="small"
              label="APP Name"
              value={appNm}
              onChange={(e) => setAppNm(e.currentTarget.value)}
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              fullWidth
              size="small"
              label="APP Description"
              value={appDesc}
              onChange={(e) => setAppDesc(e.currentTarget.value)}
            />
          </Grid>
          <Grid item xs={1}>
            <Button>select</Button>
          </Grid>
        </Grid>
      </SearchBar>
      <WijmoGrid
        columns={columns}
        rows={ApplicationList}
        style={{ height: "60vh" }}
      />
      <AdminSettingButton>
        <Button variant="success" onClick={openAppRegistry}>
          New APP
        </Button>
      </AdminSettingButton>
    </ComponentManageBox>
  );
}

export default AppManage;
