//별도의 파라미터가 들어가지 않는 Desc 용 Popup만 모아놓은 js 파일입니다.

import Modal from "components/common/modal/UModal";
import UModalTemplate from "components/common/modal/UModalTemplate";

/**
 * Service Popup의 Return Entity 사용법에 대한 설명입니다.
 * @returns
 */
export const ReturnEntityDesc = () => {
  return (
    <Modal>
      <Modal.Header title="Return Entity Setting Help" />
      <Modal.Body>
        <UModalTemplate>
          <p>
            <strong>Return Entity Field Setting</strong>
          </p>
          <p>
            After the service is executed, the returned data can be applied to
            the desired entity or a specific field of the entity.
          </p>
          <p>
            1.Case of applying it directly to the entire entity: You just need
            to set the entity variable.
          </p>
          <p>
            2. Case of applying to an entity field : select the field when
            choosing the entity.
          </p>
          <p>
            <strong>Return Value setting</strong>
          </p>
          <p>
            After service executed, can use the whole or part of the returned
            data.
          </p>
          <p>
            1. Case of bring returned Data : Enter returned entity variable
            (Default auto enter)
          </p>
          <p>2. Case of using part of the returned Data Object</p>
          <p>
            The method for specifying the value of a specific field in a return
            value of the Map format is as follows.
          </p>
          <p>[EntityName].get("[Field Name]")</p>
          <code>
            e.g.) returnEntity.get("autoNo") or returnEntity.get("useYn") == "Y"
            ? "Yes" : "No"
          </code>
        </UModalTemplate>
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
};
