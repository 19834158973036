import { InputLabel, Select, TextField, Tooltip } from "@mui/material";
import { Enums } from "components/builder/BuilderEnum";
import {
  updateEventOutput,
  updateEventWorkspace,
} from "components/builder/eventhandler/reducer/EventHandlerAction";
import UIReduxHelper from "components/builder/ui/editor/helper/UIReduxHelper";
import { setProgramSearchTerm } from "components/builder/ui/reducers/MenuAction";
import { setMemo } from "components/builder/ui/reducers/UIBuilderAction";
import { AppContext } from "components/common/AppContextProvider";
import Message from "components/common/Message";
import MSelectbox from "components/common/element/MSelectbox";
import WijmoGrid from "components/common/element/WijmoGrid";
import CommonUtils, { ArrayUtils } from "components/common/utils/CommonUtils";
import ObjectUtils from "components/common/utils/ObjectUtils";
import User from "components/common/utils/UserUtils";
import PageTemplate from "page/common/PageTemplate";
import { useContext, useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { BiEditAlt } from "react-icons/bi";
import { BsSearch } from "react-icons/bs";
import { RiDeleteBinLine } from "react-icons/ri";
import { RxOpenInNewWindow } from "react-icons/rx";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import CodeService from "services/common/CodeService";
import LocalStorageService from "services/common/LocalService";
import ProgramService from "services/ui/ProgramService";

/**
 * 프로그램 목록
 * @param {*} param0
 * @returns
 */
function MobileProgramList({ ...props }) {
  const workspace = useSelector((state) => state.workspace);
  const programSearchTerm = useSelector(
    (state) => state.menu.programSearchTerm
  );
  const [programTypeList, setProgramTypeList] = useState([]);
  const [programList, setProgramList] = useState([]);
  const [SelectedProgram, setSelectedProgram] = useState();
  const [workerId, setWorkerId] = useState(programSearchTerm.workerId || "");
  const [programId, setProgramId] = useState(programSearchTerm.programId || "");
  const [programNm, setProgramNm] = useState(programSearchTerm.programNm || "");
  const [programType, setProgramType] = useState(
    programSearchTerm.programType || ""
  );
  const [pageSize, setPageSize] = useState(20);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const {
    workspace: { openPopup },
    component,
  } = useContext(AppContext);
  const breadcrum = [
    {
      name: "Mobile 프로그램 목록",
      url: Enums.BuilderPath.MOBILE.MAIN + "/" + Enums.BuilderPath.MOBILE.LIST,
      active: true,
    },
  ];

  const columns = [
    {
      field: "tenantId",
      headerName: "테넌트 ID",
      width: 130,
    },
    {
      field: "coCd",
      headerName: "회사 코드",
      width: 150,
    },
    { field: "programId", headerName: "프로그램 ID", width: 150 },

    {
      field: "programNm",
      headerName: "프로그램 명",
      width: "*",
    },
    {
      field: "description",
      headerName: "프로그램 설명",
      width: "*",
    },
    {
      field: "programTypeNm",
      headerName: "화면 구분",
      width: 130,
    },

    {
      field: "updtUserId",
      headerName: "최종 수정자",
      width: 150,
      // valueGetter: (params) => `${params.row.updtUserId}`,
    },
    {
      field: "updtDt",
      headerName: "최종 수정일",
      width: 150,
      renderCell: (params) =>
        `${CommonUtils.getDate(params.updtDt, "datetime")}`,
      // valueGetter: (params) =>
      //   `${CommonUtils.getDate(params.row.updtDt, "datetime")}`,
    },
    {
      field: "programEdit",
      headerName: "편집",
      headerAlign: "center",
      width: 80,
      align: "center",
      renderCell: (params) => {
        return (
          <Button
            size="sm"
            variant="outline-success"
            onClick={() => onSelectProgram(params)}
          >
            <BiEditAlt />
          </Button>
        );
      },
    },
    {
      field: "programDelete",
      headerName: "삭제",
      headerAlign: "center",
      width: 80,
      align: "center",
      renderCell: (params) => {
        return (
          <Button
            size="sm"
            variant="outline-danger"
            onClick={() => onDeleteProgram(params)}
          >
            <RiDeleteBinLine />
          </Button>
        );
      },
    },
    {
      field: "newTab",
      headerName: "새 탭",
      headerAlign: "center",
      width: 80,
      align: "center",
      renderCell: (params) => {
        return (
          <Tooltip title="새탭에서 열기">
            <Button
              size="sm"
              variant="outline-secondary"
              onClick={() => onOpenNewTab(params)}
            >
              <RxOpenInNewWindow />
            </Button>
          </Tooltip>
        );
      },
    },
  ];

  useEffect(() => {
    if (ArrayUtils.isEmpty(programTypeList)) {
      const comboParams = { codeMstCd: "Z0054" };
      CodeService.getCodeCombo(comboParams, (res) => {
        if (res) {
          setProgramTypeList(res.data);
        }
      });
    }
    getProgramList();
  }, []);

  const onKeyDown = (e) => {
    if (e.keyCode === 13) {
      onSearch();
    }
  };

  const onSearch = () => {
    const body = {
      programId: programId,
      programNm: programNm,
      workerId: workerId,
      programType: programType,
    };
    dispatch(setProgramSearchTerm(body));
    getProgramList();
  };

  const getProgramList = () => {
    setIsLoading(true);
    const body = {
      // moduleCd: moduleCd === "*" ? "" : moduleCd,
      appId: workspace.appId,
      moduleCd: workspace.moduleCd,
      appReleaseId: workspace.appReleaseId,
      tenantId: workspace.tenantId,
      coCd: workspace.coCd,
      builderType: "M",
      workerId,
      programId,
      programNm,
      pageSize,
      programType: programSearchTerm.programType
        ? programSearchTerm.programType
        : programType,
    };
    ProgramService.getProgramList(
      body,
      (res) => {
        if (!res?.isError) {
          const { data } = res;
          const list = ProgramService.filterByTenantIdAndCoCd(
            data,
            body.tenantId,
            body.coCd
          );
          setProgramList(list);
          setSelectedProgram();
          setIsLoading(false);
        }
      },
      () => {
        setIsLoading(false);
      }
    );
  };

  /**
   * 프로그램 선택
   * @param {*} program
   */
  const onSelectProgram = (program) => {
    setIsLoading(true);
    ProgramService.getProgram(
      program,
      (res) => {
        const { programContent, dataModelList, ...information } = res.data;
        const componentInfo = component.getPageComponent(); //Page Component
        const { memo, ...output } = JSON.parse(programContent);
        UIReduxHelper.loadTemplate(
          dispatch,
          componentInfo,
          JSON.stringify(output)
        );
        information.programContent = JSON.parse(programContent);
        dispatch(updateEventWorkspace({}));
        dispatch(updateEventOutput(""));
        UIReduxHelper.updateInformation(dispatch, information);
        if (memo) dispatch(setMemo(memo));
        else dispatch(setMemo([]));
        navigate(
          Enums.BuilderPath.MOBILE.MAIN + "/" + Enums.BuilderPath.MOBILE.EDITOR
        );
      },
      () => {
        throw new Error("프로그램을 호출 중 오류가 발생하였습니다.");
      }
    );
  };

  /**
   * 프로그램 삭제
   * @param {*} program
   */
  const onDeleteProgram = (program) => {
    Message.confirm(
      `'${program.programNm}' 프로그램을 정말 삭제하시겠습니까?`,
      () => {
        ProgramService.deleteProgram(
          {
            ...program,
            programContent: JSON.stringify(program.programContent),
            commitComment: "프로그램 삭제",
            committer: User.getMastRecId(),
            deleteYn: "Y",
          },
          () => {
            Message.alert(
              "프로그램을 삭제하였습니다.",
              Enums.MessageType.SUCCESS
            );
            getProgramList();
          }
        );
      }
    );
  };

  /**새탭에서 열기 */
  const onOpenNewTab = (program) => {
    //사용자 정보
    LocalStorageService.set(Enums.LocalStorageName.WORKSPACE, {
      userId: User.getId(),
      workspace: workspace,
    });
    const url = `${window.location.protocol}//${window.location.hostname}:${window.location.port}`;
    window.open(
      `${url}/newTabRedirect/program/${program.programUid}`,
      "_blank"
    );
  };

  return (
    <PageTemplate breadcrum={breadcrum}>
      <PageTemplate.Box boxClass="mb-0">
        {ObjectUtils.isEmpty(workspace) ? (
          <div className="workspace-empty-alert">
            <div className="alert-msg">워크 스페이스 설정이 필요 합니다.</div>
            <Button onClick={() => openPopup()}>팝업 열기</Button>
          </div>
        ) : (
          <>
            <Form>
              <Row className="pb-3 pt-3">
                <Col xs={2}>
                  <TextField
                    size={"small"}
                    label="프로그램 ID"
                    placeholder="프로그램 명 검색..."
                    value={programId}
                    fullWidth={true}
                    onChange={(e) => setProgramId(e.currentTarget.value)}
                    onKeyDown={onKeyDown}
                  />
                </Col>
                <Col xs={2}>
                  <TextField
                    size="small"
                    label="프로그램명"
                    placeholder="프로그램명"
                    value={programNm}
                    onChange={(e) => setProgramNm(e.currentTarget.value)}
                    onKeyDown={onKeyDown}
                    fullWidth
                  />
                </Col>
                <Col xs={2}>
                  <TextField
                    size="small"
                    label="최종 수정자 ID"
                    placeholder="최종 수정자 ID"
                    value={workerId}
                    onChange={(e) => setWorkerId(e.currentTarget.value)}
                    onKeyDown={onKeyDown}
                    fullWidth
                  />
                </Col>
                <Col xs={2}>
                  <MSelectbox
                    size="small"
                    fullWidth={true}
                    data={programTypeList}
                    options={{ matchCd: "id", matchNm: "text" }}
                  >
                    <InputLabel id="module-label">화면 구분</InputLabel>
                    <Select
                      name="programType"
                      labelId="module-label"
                      label="화면 구분"
                      fullWidth
                      color="primary"
                      value={programType}
                      onChange={(e) => setProgramType(e.target.value)}
                    ></Select>
                  </MSelectbox>
                </Col>
                <Col xs={1}>
                  <Button variant="primary" onClick={onSearch}>
                    검색 <BsSearch size="14" />
                  </Button>
                </Col>
              </Row>
            </Form>
            <Row style={{ height: "calc(100% - 70px)" }}>
              <Col xs={12}>
                <WijmoGrid
                  isLoading={isLoading}
                  columns={columns}
                  rows={programList}
                  onRowDoubleClick={(params) => onSelectProgram(params)}
                  getRowId={(row) => row.programUid}
                  rowsPerPageOptions={[pageSize]}
                  pageSize={pageSize}
                  style={{ height: "70vh" }}
                />
              </Col>
            </Row>
          </>
        )}
      </PageTemplate.Box>
    </PageTemplate>
  );
}

export default MobileProgramList;
