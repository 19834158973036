import React, { Component } from "react";
import PropTypes from "prop-types";
import CommandButton from "components/builder/CommandButton";
import { connect } from "react-redux";
import EntityReduxHelper from "./helper/EntityReduxHelper";
import {
  undo,
  redo,
  setClipboard,
  initCommand,
} from "components/builder/ui/reducers/CommandAction";
import JsonUtils from "components/common/utils/JsonUtils";
import StringUtils from "components/common/utils/StringUtils";
import ObjectUtils from "components/common/utils/ObjectUtils";
import { Enums } from "components/builder/BuilderEnum";
import Message from "components/common/Message";
import {
  initActivedEntity,
  updateDataModel,
} from "../reducers/EntityBuilderAction";
import { Button } from "react-bootstrap";

class EntityCommandButton extends CommandButton {
  constructor(props) {
    super(props);

    this.state = {
      tabType: "E",
      rightSideData: null,
    };
    this.findNodeAndsetClipboard = this.findNodeAndsetClipboard.bind(this);
    this.findEntityByFieldCompId = this.findEntityByFieldCompId.bind(this);
    this.makeNewElement = this.makeNewElement.bind(this);
    this.onClickCut = this.onClickCut.bind(this);
    this.onClickPaste = this.onClickPaste.bind(this);
    this.onClickCopy = this.onClickCopy.bind(this);
    this.count = React.createRef(0);
  }

  /**
   * 되돌리기
   */
  undo() {
    const { prev, undoIndex } = this.props.command;
    const newOutput = { ...prev.at(undoIndex) };
    this.props.undo(newOutput);
  }
  /**
   * 다시 하기
   */
  redo() {
    const { undoIndex, latest } = this.props.command;
    const newOutput = { ...latest.at(undoIndex + 1) };
    this.props.redo(newOutput);
  }

  makeNewElement = (node) => {
    const { ENTITY_FIELD, ENTITY } = Enums.EntityComponentType;
    if (StringUtils.equalsIgnoreCase(node.type, ENTITY_FIELD)) {
      let newField = { ...node, virtualYn: "Y", compId: StringUtils.getUuid() };
      return newField;
    } else if (StringUtils.equalsIgnoreCase(node.type, ENTITY)) {
      const distanceStep = this.count.current++;

      let newEntity = {
        ...node,
        physEntityNm: node.physEntityNm + `(${distanceStep + 1})`,
        virtualYn: "Y",
        compId: StringUtils.getUuid(),
        position: {
          x: node.position.x + 300 + distanceStep * 100,
          y: node.position.y + 100 + distanceStep * 100,
        },
        dataModelEntityFields: node.dataModelEntityFields.map((f) => {
          let newField = {
            ...f,
            virtualYn: "Y",
            compId: StringUtils.getUuid(),
          };
          return newField;
        }),
      };
      return newEntity;
    }
  };

  /**
   * 노드 검색 후 클립보드 복사
   * @returns
   */
  findNodeAndsetClipboard = () => {
    let currentNode;
    //1. 노드 찾기
    currentNode = JsonUtils.findNode(
      this.props.output,
      "compId",
      this.props.component.compId
    );
    //3. 리덕스에 담기
    this.props.setClipboard(currentNode);
    return currentNode;
  };

  /**
   * Comp Id로 엔티티 찾기
   * @param {*} findCompId
   * @returns
   */
  findEntityByFieldCompId = (findCompId) => {
    const { dataModelEntities } = this.props.output;
    let parentsEntity = null;
    let flag = false;
    for (const dtm of dataModelEntities) {
      for (const field of dtm.dataModelEntityFields) {
        if (StringUtils.equalsIgnoreCase(field.compId, findCompId)) {
          flag = true;
        }
      }
      if (flag) {
        parentsEntity = dtm;
        break;
      }
    }
    return parentsEntity;
  };

  /**
   * 잘라내기
   * @param {EventHandler} e
   * @returns
   */
  onClickCut(e) {
    e.preventDefault();
    if (
      ObjectUtils.isEmpty(this.props.component) ||
      StringUtils.equalsIgnoreCase(
        this.props.component.type,
        Enums.EntityComponentType.DATA_MODEL
      )
    )
      return Message.alert("Please select the Target", Enums.MessageType.INFO);
    //1. 리덕스에 담기
    this.findNodeAndsetClipboard();
    //2. 아웃풋에서 삭제
    if (
      StringUtils.equalsIgnoreCase(
        this.props.component.type,
        Enums.EntityComponentType.ENTITY
      )
    ) {
      this.props.removeEntity(this.props.output, this.props.component);
      const { dataModelEntities, ...outputAct } = this.props.output;
      //2-1. 포커싱 옮기기
      this.props.activateComponent(outputAct);
    } else if (
      StringUtils.equalsIgnoreCase(
        this.props.component.type,
        Enums.EntityComponentType.ENTITY_FIELD
      )
    ) {
      const thisEntity = this.findEntityByFieldCompId(
        this.props.component.compId
      );
      //2-1. 포커싱 옮기기
      this.props.activateComponent(thisEntity);
      this.props.removeEntityField(
        this.props.output,
        thisEntity,
        this.props.component.compId
      );
    }
  }

  /**
   * 복사하기
   * @param {EventHandler} e
   * @returns
   */
  onClickCopy(e) {
    e.preventDefault();
    if (
      ObjectUtils.isEmpty(this.props.component) ||
      StringUtils.equalsIgnoreCase(
        this.props.component.type,
        Enums.EntityComponentType.DATA_MODEL
      )
    )
      return Message.alert("Please select the Target", Enums.MessageType.INFO);
    //1. 리덕스에 담기
    this.findNodeAndsetClipboard();
  }

  /**
   * 붙여 넣기
   * @param {*} e
   * @returns
   */
  onClickPaste(e) {
    //엔티티 선택할때는 안으로 필드선택은 바로 아래로 페이지 선택시 선택 엔티티만 복사
    const { clipboard } = this.props.command;
    const { type } = clipboard;
    const { DATA_MODEL, ENTITY_FIELD, ENTITY } = Enums.EntityComponentType;

    const newElement = this.makeNewElement(clipboard);
    //엔티티인 경우
    if (StringUtils.equalsIgnoreCase(type, ENTITY)) {
      this.props.addNewEntity(this.props.output, newElement);
    } else if (
      //엔티티 필드인 경우
      StringUtils.equalsIgnoreCase(type, ENTITY_FIELD)
    ) {
      let thisEntity = {};
      switch (this.props.component.type) {
        case DATA_MODEL:
          return Message.alert(
            "Field values can only be pasted within an entity or table.",
            Enums.MessageType.ERROR
          );
        case ENTITY: //자녀 제일 아래에 입력
          thisEntity = JsonUtils.findNode(
            this.props.output,
            "compId",
            this.props.component.compId
          );
          const newEntity = {
            ...thisEntity,
            dataModelEntityFields: [
              ...thisEntity.dataModelEntityFields,
              newElement,
            ],
          };
          this.props.updateEntity(this.props.output, newEntity);
          break;
        case ENTITY_FIELD: //형제 노드 중 본인 아래에(오른쪽)에 입력
          thisEntity = this.findEntityByFieldCompId(
            this.props.component.compId
          );
          const targetIndex = thisEntity.dataModelEntityFields.findIndex((f) =>
            StringUtils.equalsIgnoreCase(f.compId, this.props.component.compId)
          );
          if (targetIndex > -1) {
            const nDtmFields = [...thisEntity.dataModelEntityFields];
            nDtmFields.splice(targetIndex + 1, 0, newElement);
            const newEntity = {
              ...thisEntity,
              dataModelEntityFields: [...nDtmFields],
            };
            this.props.updateEntity(this.props.output, newEntity);
          }
          break;
        default:
          break;
      }
    }
  }
  renderDataNm() {
    return (
      <div className="right side">
        <div className="program-id-label">
          <b>{this.props.output.dataModelNm}</b>
        </div>
        <Button
          className="list-button"
          variant="outline-dark"
          onClick={() =>
            this.props.navigate(
              Enums.BuilderPath.ENTITY.MAIN +
                "/" +
                Enums.BuilderPath.ENTITY.LIST
            )
          }
        >
          Data Model List
        </Button>
      </div>
    );
  }

  render() {
    return this.renderComponent();
  }
}

const dispatchNewBlankPage = (dispatch) => {
  return {
    removeEntity: (output, component) =>
      EntityReduxHelper.removeEntity(dispatch, output, component),
    activateComponent: (outputAct) =>
      EntityReduxHelper.activateComponent(dispatch, outputAct),
    removeEntityField: (output, thisEntity, compId) =>
      EntityReduxHelper.removeEntityField(dispatch, output, thisEntity, compId),
    addNewEntity: (output, newElement) =>
      EntityReduxHelper.addNewEntity(dispatch, output, newElement),
    updateEntity: (output, newEntity) =>
      EntityReduxHelper.updateEntity(dispatch, output, newEntity),
    updateDataModel: (newOutput) =>
      EntityReduxHelper.updateDataModel(dispatch, newOutput),
    initActivedEntity: () => EntityReduxHelper.initActivedEntity(dispatch),
    undo: (newOutput) => {
      dispatch(updateDataModel(newOutput));
      dispatch(undo());
      dispatch(initActivedEntity());
    },
    redo: (newOutput) => {
      dispatch(updateDataModel(newOutput));
      dispatch(redo());
      dispatch(initActivedEntity());
    },
    commandInit: () => {
      dispatch(initCommand());
    },
    setClipboard: (node) => dispatch(setClipboard(node)),
  };
};

export default connect((state) => {
  return {
    command: state.command,
    output: state.outputENT.output,
    component: state.activedENTComponent,
  };
}, dispatchNewBlankPage)(EntityCommandButton);
