import * as Enums from "components/builder/BuilderEnum";
import BuilderToolbarButton from "components/builder/BuilderToolbarButton";
import { AppContext } from "components/common/AppContextProvider";
import Popup from "components/common/Popup";
import SessionUtils from "components/common/utils/SessionUtils";
import User from "components/common/utils/UserUtils";
import produce from "immer";
import React from "react";
import { RiLogoutBoxRLine, RiUserSettingsLine } from "react-icons/ri";
import ConnectionService from "services/common/ConnectionService";

import { Tooltip as MTooltip } from "@mui/material";
import Message from "components/common/Message";
import ObjectUtils from "components/common/utils/ObjectUtils";
import StringUtils from "components/common/utils/StringUtils";
import {
  shortKeyBind,
  UserShortKey,
} from "components/setting/section/shortKey/ShortKeyConfig";
import ConnectionListPopup from "page/popup/ConnectionListPopup";
// import { ReactComponent as LogoIcon } from "images/svg/logo.svg";
import LogoIcon from "images/icon/daaf_logo.png";
import TotalSearchPopup from "page/popup/TotalSearchPopup";
import { FaCogs } from "react-icons/fa";
import { MdOutlineWorkspaces } from "react-icons/md";
import { RiArrowUpDownFill } from "react-icons/ri";
import AppService from "services/common/AppService";
import CodeService from "services/common/CodeService";
import LocalService from "services/common/LocalService";
import SettingService from "services/common/SettingService";
import UIReduxHelper from "./ui/editor/helper/UIReduxHelper";
import {
  setSearchConsoleCollapse,
  setSearchConsoleHidden,
  setSearchOptions,
  setSearchTerm,
} from "./ui/reducers/SearchConsoleAction";

/**
 * 각 BuilderHeader 를 만드는데 공통적으로 쓰이는 부분을 정리한 부모 클래스
 */
class BuilderHeader extends React.Component {
  constructor(props) {
    super(props);

    this.toolbarStyle = props.toolbarStyle || "menu";
    this.headerInit = this.headerInit.bind(this);
    this.logout = this.logout.bind(this);
    this.onChangeWorkspace = this.onChangeWorkspace.bind(this);
    this.setDropdownEvent = this.setDropdownEvent.bind(this);
    this.connect = this.connect.bind(this);
    this.connectList = this.connectList.bind(this);
    this.setting = this.setting.bind(this);
    this.search = this.search.bind(this);
    this.onWindowResize = this.onWindowResize.bind(this);
    this.mapKeyToHeader = this.mapKeyToHeader.bind(this);
    this.shortKeyConfig = User.getConfiguration(
      Enums.ConfigurationDefine.SHORT_KEY_SET
    );
    this.state = {
      showUserDropdown: false,
      showWorkspaceDropdown: false,
      prevWorkspace: [],
      showConnectDropdown: false,
      mini: false,
      auth: {},
    };

    this.rightSideRef = React.createRef();
    this.leftSideRef = React.createRef();
    this.renderHeader = this.renderHeader.bind(this);
    this.renderLeftSide = this.renderLeftSide.bind(this);
    this.renderRightSide = this.renderRightSide.bind(this);
    this.renderLeftCommonMenu = this.renderLeftCommonMenu.bind(this);
    this.unmountListener = this.unmountListener.bind(this);
    this.renderDivider = this.renderDivider.bind(this);
  }
  static contextType = AppContext;

  /**
   * 사용자 로그아웃
   * @returns
   */
  logout() {
    this.props.logout();
    window.sessionStorage.removeItem(SessionUtils.storageName);
    this.props.navigate(Enums.BuilderPath.LOGIN);
  }

  /**
   * 드롭다운 메뉴 이벤트 설정
   * @param {*} _state
   * @param {*} _buttonId
   * @param {*} editorScreenOnly
   * @returns
   */
  setDropdownEvent(_state, _buttonId, editorScreenOnly = true) {
    /**
     * @param {Event} event
     * @returns
     */
    const showDropdown = (event) => {
      event.preventDefault();

      const newState = {
        showUserDropdown: false,
        showWorkspaceDropdown: false,
        showConnectDropdown: false,
      };
      newState[_state] = !this.state[_state];

      if (newState[_state]) {
        this.setState(newState, () => {
          document.addEventListener("click", closeDropdown);
        });
      } else {
        this.setState(newState, () => {
          document.removeEventListener("click", closeDropdown);
        });
      }
    };
    /**
     * dropdown menu close event
     * @param {Event} event
     */
    const closeDropdown = (event) => {
      const {
        target: {
          dataset: { buttonId },
        },
      } = event;
      if (
        buttonId !== _buttonId &&
        this.dropdownMenu != null &&
        !this.dropdownMenu.contains(event.target)
      ) {
        const newState = produce(this.state, (draft) => {
          draft[_state] = false;
        });
        this.setState(newState, () => {
          document.removeEventListener("click", closeDropdown);
        });
      }
    };

    return showDropdown;
  }

  gotoDocument() {
    const aTag = document.createElement(`a`);
    const userInfo = StringUtils.encJson(User.getAll());

    //사용자 정보 말아서 queryString으로 전송 qs는 최대 2048자 전송가능
    // aTag.setAttribute(
    //   "href",
    //   process.env.REACT_APP_DOC_URL + `/redirect?userEnc=${userInfo}}`
    // );

    // 토큰 사용 또는 비번 정보 확인 방식
    aTag.setAttribute(
      "href",
      process.env.REACT_APP_DOC_URL +
        `/redirect?userId=${User.getId()}&userNumber=${SessionUtils.get("pwd")}`
    );
    aTag.setAttribute("target", "_blank");
    aTag.click();
  }

  /**
   * 상속 받는 각 헤더에서 키매핑하는 함수
   * @param {*} event
   */
  mapKeyToHeader = (event) => {};

  /**
   * 키업 이벤트
   * @param {*} event
   */
  handleKeyUp = (event) => {
    this.mapKeyToHeader(event);
    //가이드 문서 이동
    shortKeyBind(event, Enums.ShortKeyDefine.DOCUMENT, this.gotoDocument);
    //신규 연결
    shortKeyBind(event, Enums.ShortKeyDefine.NEW_CON, this.connect);
    //통합 검색
    shortKeyBind(event, Enums.ShortKeyDefine.SEARCH, this.search);
  };

  componentDidMount() {
    this.headerInit();
  }

  /**
   * 부모 클래스에서 진행하는 헤더 초기화
   * @returns
   */
  headerInit() {
    const userConnection = User.getConfiguration(
      Enums.ConfigurationDefine.CONNECTION
    );
    if (!ObjectUtils.isEmpty(this.props.workspace)) {
      const isNotConnected = ObjectUtils.isEmpty(
        User.getConnection(this.props.workspace.tenantMstId)
      );
      if (ObjectUtils.isEmpty(this.context.connection)) return false;
      if (
        !this.context.connection.isConnecting &&
        isNotConnected &&
        userConnection &&
        userConnection.autoConnection
      ) {
        //자동 연결
        const body = {
          connectionId: userConnection.autoConnectionTarget,
          systemUserPw: userConnection.autoConnectionPw,
        };
        //자동 연결 셋
        this.context.connection.setIsConnecting(true);
        if (!StringUtils.isEmpty(userConnection.autoConnectionTarget)) {
          ConnectionService.connect(
            body,
            (res) => {
              //자동 연결 셋팅중 false로 전환
              this.context.connection.setIsConnecting(false);
              const { flag, text } = ConnectionService.reponseValidator(
                res,
                this.props.workspace.tenantMstId
              );
              if (flag) {
                Message.alert(
                  "Server Auto Connection Completed",
                  Enums.MessageType.SUCCESS
                );

                ConnectionService.getConnection(body, (conRes) => {
                  const userConnection = {
                    ...conRes.data,
                    ...res.data,
                  };
                  User.setConnection(
                    this.props.workspace.tenantMstId,
                    userConnection
                  );
                  this.forceUpdate();
                });
              } else {
                //자동연결 실패시 연결 팝업 호출
                this.context.connection.openPopup(body);
                //연결이 잘못되었기 때문에 자동연결설정 해제
                const _body = {
                  configurationCode: Enums.ConfigurationDefine.CONNECTION,
                  configurationValue: JSON.stringify({
                    ...userConnection,
                    autoConnection: false,
                  }),
                };
                SettingService.saveUserConfig(_body, (res) => {
                  const key = res.data.configurationCode;
                  const value = JSON.parse(res.data.configurationValue);
                  User.overrideConfig(key, value);
                  Message.alert(
                    "Auto-connection has been disabled due to incorrect settings.",
                    Enums.MessageType.SUCCESS
                  );
                });
              }
            },
            (err) => {
              this.context.connection.setIsConnecting(false);
              //자동연결 실패시 연결 팝업 호출
              this.context.connection.openPopup(body);
              //연결이 잘못되었기 때문에 자동연결설정 해제
              const _body = {
                configurationCode: Enums.ConfigurationDefine.CONNECTION,
                configurationValue: JSON.stringify({
                  ...userConnection,
                  autoConnection: false,
                }),
              };
              SettingService.saveUserConfig(_body, (res) => {
                const key = res.data.configurationCode;
                const value = JSON.parse(res.data.configurationValue);
                User.overrideConfig(key, value);
                Message.alert(
                  "Auto-connection has been disabled due to incorrect settings.",
                  Enums.MessageType.SUCCESS
                );
              });
            }
          );
        }
      }
    }
    this.onWindowResize();
    window.addEventListener("keyup", this.handleKeyUp);
    window.addEventListener("resize", this.onWindowResize);

    /**
     * 워크스페이스 이전 목록
     */
    let prevWorkspaceList = [];
    const prevWorkspace = LocalService.get(
      Enums.LocalStorageName.WORKSPACE_HISTORY
    );
    if (prevWorkspace) prevWorkspaceList = prevWorkspace.list;
    if (this.props.workspace?.appId) {
      CodeService.getCodeCombo({ codeMstCd: "Z0019" }, (res) => {
        const myAuth = res.data.find(
          (d) => d.id === User.getAuthType(this.props.workspace?.appId)
        );
        this.setState(
          produce(this.state, (draft) => {
            draft.auth = myAuth;
            draft.prevWorkspace = prevWorkspaceList;
          })
        );
      });
    } else {
      this.setState({
        prevWorkspace: prevWorkspaceList,
      });
    }
  }

  componentWillUnmount() {
    this.unmountListener();
  }

  /**
   * 컴포넌트 닫힐때 이벤트
   */
  unmountListener() {
    this.setState({
      showConnectDropdown: false,
      showUserDropdown: false,
      showWorkspaceDropdown: false,
    });
    window.removeEventListener("keyup", this.handleKeyUp);
    window.removeEventListener("resize", this.onWindowResize);
  }

  /**
   * 윈도우 사이즈 바뀔때 이벤트
   * @param {*} e
   */
  onWindowResize(e) {
    //left, right side bar parent width
    if (this.rightSideRef.current && this.leftSideRef.current) {
      const parentsWidth = this.rightSideRef.current.parentElement.clientWidth;
      if (parentsWidth < 1450) {
        this.setState({
          ...this.state,
          mini: true,
        });
        this.rightSideRef.current.style = "width:200px";
        this.leftSideRef.current.style = `width:${parentsWidth * 0.7}px`;
      } else {
        this.setState({
          ...this.state,
          mini: false,
        });
        this.rightSideRef.current.style = "width:400px;";
        this.leftSideRef.current.style = `width:${parentsWidth - 440}px`;
      }
    }
  }

  /**
   * 워크스페이스 드롭다운 메뉴에서 누를떄
   * @param {*} e
   * @param {*} workspace
   */
  onChangeWorkspace = (e, workspace) => {
    if (e) e.preventDefault();
    this.context.workspace.setPrevList(workspace);
    this.props.setWorkspace(workspace);
  };

  /**
   * 연결 드롭다운 메뉴 클릭
   * @param {*} event
   * @param {*} _connection
   * @returns
   */
  connect(event, _connection) {
    this.setState({ showConnectDropdown: false });
    if (event) event.preventDefault();
    return this.context.connection.openPopup(_connection);
  }

  /**
   * +연결더보기 버튼 클릭하여, 연결 목록 팝업을 호출
   * @param {*} event
   */
  connectList(event) {
    this.setState({ showConnectDropdown: false });
    //팝업창 열기
    const popupTitle = "Connection List";
    const options = {
      effect: Popup.ScaleUp, //Effect.SlideFromTop(default)를 Effect.ScaleUp 로 변경
      style: {
        content: {
          width: "50%", //popup의 크기를 50% (default 60%)
        },
      },
    };
    Popup.open(
      <ConnectionListPopup
        title={popupTitle}
        callbackFnc={(e, d) => {
          Popup.close();
          this.connect(e, d);
        }}
      />,
      options
    );
    if (event) event.preventDefault();
  }

  /**
   * 개인 설정 버튼
   * @param {*} e
   * @returns
   */
  setting(e) {
    if (e) e.preventDefault();

    return Message.alert("Coming Soon.", Enums.MessageType.INFO);
  }

  /**
   * 통합 검색기능
   * @param {*} e
   */
  search(e) {
    if (e) e.preventDefault();
    // return Message.alert("개발 예정 중인 기능입니다.", Enums.MessageType.INFO);
    const options = {
      effect: Popup.ScaleUp, //Effect.SlideFromTop(default)를 Effect.ScaleUp 로 변경
      style: {
        content: {
          width: "35%", //popup의 크기를 50% (default 60%)
        },
      },
    };

    const callbackFnc = (type, list, searchTerm, searchOptions) => {
      Popup.close();
      this.props.setSearchConsoleHidden(false);
      this.props.setSearchConsoleCollpased(false);
      this.props.setSearchType(type);
      this.props.setSearchTerm(searchTerm);
      this.props.setTotalSearchList(list);
      this.props.setSearchOptions(searchOptions);
    };
    Popup.open(
      <TotalSearchPopup
        setTotalSearchList={callbackFnc}
        workspace={this.props.workspace}
        searchConsole={this.props.searchConsole || {}}
      />,
      options
    );
  }

  /**
   * 컨텍스트 메뉴에 구분선
   * @returns
   */
  renderDivider() {
    return (
      <hr
        style={{
          margin: "0 8px",
          opacity: "unset",
          backgroundColor: "#4b4b4b",
        }}
      />
    );
  }

  /**
   * 상속 받은 헤더에서 입력받은 헤더 컴포넌트 그리는 곳
   * @returns
   */
  renderHeader() {
    return <></>;
  }

  /**
   * 왼쪽 메뉴
   * @returns
   */
  renderLeftSide() {
    return (
      <div className="navbar-left" ref={this.leftSideRef}>
        <ul className={`nav navbar-nav`}>
          {this.renderHeader()}
          {this.renderLeftCommonMenu()}
        </ul>
      </div>
    );
  }

  /**
   * 왼쪽 공통 메뉴
   * @returns
   */
  renderLeftCommonMenu() {
    const WorkspaceDropdownEvent = this.setDropdownEvent(
      "showWorkspaceDropdown",
      "workspace",
      false
    );
    const ConnectDropdownEvent = this.setDropdownEvent(
      "showConnectDropdown",
      "connect",
      false
    );
    return (
      <>
        <li>
          <BuilderToolbarButton
            style={this.toolbarStyle}
            buttonId="connect"
            onClick={ConnectDropdownEvent}
            connected={!ObjectUtils.isEmpty(this.context.connection?.Info)}
            className={
              ObjectUtils.isEmpty(this.props.workspace) ? "disabled" : ""
            }
            iconOnly={this.state.mini}
            tooltipTitle={"Connection Setting"}
          />
          {this.state.showConnectDropdown ? (
            <div
              className="toolbar-dropdown"
              ref={(element) => {
                this.dropdownMenu = element;
              }}
            >
              <ul>
                <li
                  className="dropdown-item"
                  role="button"
                  onClick={this.connect}
                >
                  New Connection
                  <span>{UserShortKey(Enums.ShortKeyDefine.NEW_CON)}</span>
                </li>
                {this.context.connection.list.map((_connection, idx) => {
                  const connecting = StringUtils.equalsIgnoreType(
                    this.context.connection.Info?.connectionId,
                    _connection.connectionId
                  );
                  let expired = false;
                  if (connecting)
                    expired = User.getConnection(
                      this.props.workspace.tenantMstId,
                      "expired"
                    );
                  if (idx < 5) {
                    return (
                      <li
                        className="dropdown-item"
                        role="button"
                        onClick={(e) => this.connect(e, _connection)}
                        key={_connection.connectionId}
                        style={{
                          color: connecting
                            ? expired
                              ? "tomato"
                              : "lime"
                            : "white",
                        }}
                      >
                        {_connection.connectionNm}
                        {connecting &&
                          (expired ? " - Expired" : " - Connectioned")}
                      </li>
                    );
                  }
                })}
                {this.context.connection.list.length > 5 && (
                  <li
                    className="dropdown-item"
                    role="button"
                    onClick={this.connectList}
                  >
                    + More
                  </li>
                )}
              </ul>
            </div>
          ) : null}
        </li>
        <li>
          <BuilderToolbarButton
            style={this.toolbarStyle}
            buttonId="workspace"
            text=" Workspace"
            icon={<MdOutlineWorkspaces />}
            onClick={() => this.context.workspace.openPopup()}
            iconOnly={this.state.mini}
            tooltipTitle={"Workspace Setting"}
          />
        </li>
        <li>
          {!ObjectUtils.isEmpty(this.props.workspace) && (
            <>
              <BuilderToolbarButton
                style={this.toolbarStyle}
                text={` ${AppService.getWorkspaceName(this.props.workspace)}`}
                onClick={WorkspaceDropdownEvent}
                icon={<RiArrowUpDownFill />}
                buttonId="workspace"
                className="workspace-spaceName"
                tooltipTitle={`${AppService.getWorkspaceName(
                  this.props.workspace
                )}`}
              />

              {this.state.showWorkspaceDropdown ? (
                <div
                  className="toolbar-dropdown"
                  ref={(element) => {
                    this.dropdownMenu = element;
                  }}
                >
                  <ul>
                    {this.state.prevWorkspace.map((workspace) => {
                      return (
                        <li
                          className="dropdown-item"
                          role="button"
                          onClick={(e) => this.onChangeWorkspace(e, workspace)}
                          key={workspace.tenantMstId}
                          style={{
                            color: StringUtils.equalsIgnoreType(
                              this.props.workspace.tenantMstId,
                              workspace.tenantMstId
                            )
                              ? "lime"
                              : "white",
                          }}
                        >
                          <div>{AppService.getWorkspaceName(workspace)}</div>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              ) : null}
            </>
          )}
        </li>
      </>
    );
  }

  /**
   * 헤더 오른쪽 공통 부분 렌더링
   * @returns
   */
  renderRightSide() {
    const UserDropdownEvent = this.setDropdownEvent(
      "showUserDropdown",
      "user",
      false
    );
    return (
      <>
        <div className="navbar-right" ref={this.rightSideRef}>
          <ul className="nav navbar-nav">
            {!ObjectUtils.isEmpty(this.props.workspace) && (
              <Tooltip
                title={`Search ${UserShortKey(Enums.ShortKeyDefine.SEARCH)}`}
                mini={this.state.mini}
              >
                <li>
                  <BuilderToolbarButton
                    buttonId="search"
                    style={this.toolbarStyle}
                    onClick={this.search}
                    iconOnly={this.state.mini}
                  />
                </li>
              </Tooltip>
            )}
            <Tooltip
              title={`Guide Docs ${UserShortKey(
                Enums.ShortKeyDefine.DOCUMENT
              )}`}
              mini={this.state.mini}
            >
              <li>
                <BuilderToolbarButton
                  buttonId="document"
                  style={this.toolbarStyle}
                  onClick={this.gotoDocument}
                  iconOnly={this.state.mini}
                />
              </li>
            </Tooltip>
            <li>
              <BuilderToolbarButton
                className="user-button"
                buttonId="user"
                style={this.toolbarStyle}
                text={`${SessionUtils.get("userNm")} ${
                  !ObjectUtils.isEmpty(this.state.auth)
                    ? `( ${this.state.auth.text} )`
                    : ""
                }`}
                onClick={UserDropdownEvent}
                iconOnly={this.state.mini}
              />
              {this.state.showUserDropdown ? (
                <div
                  className="toolbar-dropdown"
                  ref={(element) => {
                    this.dropdownMenu = element;
                  }}
                >
                  <ul>
                    <li
                      className="dropdown-item"
                      role="button"
                      onClick={this.logout}
                    >
                      <div style={{ width: "100px" }}>
                        <RiLogoutBoxRLine size="18" />
                        &nbsp;Logout
                      </div>

                      <span>{UserShortKey(Enums.ShortKeyDefine.LOGOUT)}</span>
                    </li>
                    <li
                      className="dropdown-item"
                      role="button"
                      onClick={() =>
                        this.props.navigate(
                          Enums.BuilderPath.USER.MAIN +
                            "/" +
                            Enums.BuilderPath.USER.MY_INFO
                        )
                      }
                    >
                      <div style={{ width: "100px" }}>
                        <RiUserSettingsLine size="18" color="white" />
                        &nbsp;User Info
                      </div>
                      <span>{UserShortKey(Enums.ShortKeyDefine.MY_INFO)}</span>
                    </li>
                    <li
                      className="dropdown-item"
                      role="button"
                      onClick={this.setting}
                    >
                      <div style={{ width: "100px" }}>
                        <FaCogs size="18" color="white" />
                        &nbsp;Settings
                      </div>
                      <span>{UserShortKey(Enums.ShortKeyDefine.SETTING)}</span>
                    </li>
                  </ul>
                </div>
              ) : null}
            </li>
          </ul>
        </div>
      </>
    );
  }

  render() {
    return (
      <div className="main-header">
        <div className="logo">
          <span className="logo-mini">
            <img src={LogoIcon} alt="DaaF Builder"></img> Daaf Builder
          </span>
        </div>
        <div className="navbar navbar-static-top">
          {this.renderLeftSide()}
          {this.renderRightSide()}
        </div>
      </div>
    );
  }
}
export default BuilderHeader;

export const HeaderMapStateToProps = (state) => {
  return {
    searchConsole: state.searchConsole,
  };
};

export const HeaderMapDispatchToProps = (dispatch) => {
  return {
    /**
     * 통합 검색 목록 설정
     * @param {*} list
     * @returns
     */
    setTotalSearchList: (list) =>
      UIReduxHelper.setTotalSearchList(dispatch, list),
    /**
     * 통합 검색 타입 설정
     * @param {*} type
     * @returns
     */
    setSearchType: (type) => UIReduxHelper.setTotalSearchType(dispatch, type),
    /**
     * 통합검색 콘솔 숨김 | 표시
     * @param {*} flag
     * @returns
     */
    setSearchConsoleHidden: (flag) => dispatch(setSearchConsoleHidden(flag)),
    /**
     * 통합검색 콘솔 표시
     * @param {*} flag
     * @returns
     */
    setSearchConsoleCollpased: (flag) =>
      dispatch(setSearchConsoleCollapse(flag)),
    /**
     * 검색어 설정
     * @param {*} searchTerm
     * @returns
     */
    setSearchTerm: (searchTerm) => dispatch(setSearchTerm(searchTerm)),
    /**
     * 통합 검색 옵션 설정
     * @param {*} searchOptions
     * @returns
     */
    setSearchOptions: (searchOptions) =>
      dispatch(setSearchOptions(searchOptions)),
  };
};

export const Tooltip = (props) => {
  if (props.mini) {
    return (
      <MTooltip title={props.title} placement={props.placement}>
        {props.children}
      </MTooltip>
    );
  } else {
    return props.children;
  }
};
