import { Tooltip } from "@mui/material";
import BuilderHeader from "components/builder/BuilderHeader";
import BuilderToolbarButton from "components/builder/BuilderToolbarButton";
import React, { Component, useContext, useState } from "react";
import { AiOutlineAppstore } from "react-icons/ai";
import { BsFileEarmarkCode } from "react-icons/bs";
import { CgTemplate } from "react-icons/cg";
import { FaLaptopCode } from "react-icons/fa";
import { MdOutlineSettings } from "react-icons/md";
import { SiWebcomponentsdotorg } from "react-icons/si";

class AdminSettingHeader extends BuilderHeader {
  constructor(props) {
    super(props);
    this.state = {
      toolbarStyle: this.props.toolbarStyle || "menu",
    };
    this.onClickMenu = this.onClickMenu.bind(this);
  }

  // static contextType = AdminSettingContext;

  onClickMenu = (menu) => {
    this.props.setselectedMenu(menu);
  };

  /**
   * 왼쪽 메뉴
   * @returns
   */
  renderLeftSide() {
    return (
      <div className="navbar-left" ref={this.leftSideRef}>
        <ul className={`nav navbar-nav`}>{this.renderHeader()}</ul>
      </div>
    );
  }

  renderHeader() {
    return (
      <>
        <Tooltip title="Application Management" placement="bottom">
          <li>
            <BuilderToolbarButton
              icon={<AiOutlineAppstore size={18} />}
              text=" Application"
              buttonId="appManage"
              style={this.toolbarStyle}
              onClick={(e) =>
                this.onClickMenu(this.props.AdminMenus.APP_MANAGE)
              }
              iconOnly={this.state.iconMiniMode}
            />
          </li>
        </Tooltip>
        <Tooltip title="Component Management" placement="bottom">
          <li>
            <BuilderToolbarButton
              icon={<SiWebcomponentsdotorg size={18} />}
              text=" Component"
              buttonId="empty"
              style={this.toolbarStyle}
              onClick={(e) =>
                this.onClickMenu(this.props.AdminMenus.COMPONENT_MANAGE)
              }
              iconOnly={this.state.iconMiniMode}
            />
          </li>
        </Tooltip>
        <Tooltip title="Major Code Management" placement="bottom">
          <li>
            <BuilderToolbarButton
              style={this.toolbarStyle}
              text=" Code"
              buttonId="majorCodeManager"
              icon={<BsFileEarmarkCode size={18} />}
              onClick={(e) =>
                this.onClickMenu(this.props.AdminMenus.MAJOR_CODE_MANAGE)
              }
              iconOnly={this.state.iconMiniMode}
            />
          </li>
        </Tooltip>
        <Tooltip title="System Code Management" placement="bottom">
          <li>
            <BuilderToolbarButton
              style={this.toolbarStyle}
              text=" System Code"
              buttonId="systemCodeManager"
              icon={<FaLaptopCode size={18} />}
              onClick={(e) =>
                this.onClickMenu(this.props.AdminMenus.SYSTEM_CODE_MANAGE)
              }
              iconOnly={this.state.iconMiniMode}
            />
          </li>
        </Tooltip>
        <Tooltip title="System Environment Management" placement="bottom">
          <li>
            <BuilderToolbarButton
              style={this.toolbarStyle}
              text=" System Environment"
              buttonId="systemEnvelopment"
              icon={<MdOutlineSettings size={18} />}
              onClick={(e) =>
                this.onClickMenu(this.props.AdminMenus.SYSTEM_ENV_MANAGE)
              }
              iconOnly={this.state.iconMiniMode}
            />
          </li>
        </Tooltip>
        <Tooltip title="Template Management" placement="bottom">
          <li>
            <BuilderToolbarButton
              style={this.toolbarStyle}
              text=" Template"
              buttonId="templateManger"
              icon={<CgTemplate size={18} />}
              onClick={(e) =>
                this.onClickMenu(this.props.AdminMenus.TEMPLATE_MANAGE)
              }
              iconOnly={this.state.iconMiniMode}
            />
          </li>
        </Tooltip>
        <Tooltip title="DB Patch" placement="bottom">
          <li>
            <BuilderToolbarButton
              style={this.toolbarStyle}
              text="DB Patch"
              buttonId="dbPatchInsertion"
              icon={<CgTemplate size={18} />}
              onClick={(e) =>
                this.onClickMenu(this.props.AdminMenus.DB_PATCH_INSERT)
              }
              iconOnly={this.state.iconMiniMode}
            />
          </li>
        </Tooltip>
      </>
    );
  }
}
export default AdminSettingHeader;
