import { Enums } from "components/builder/BuilderEnum";
import Message from "components/common/Message";
import Modal from "components/common/modal/UModal";
import UmodalTemplate from "components/common/modal/UModalTemplate";
import Popup from "components/common/Popup";
import ArrayUtils from "components/common/utils/ArrayUtils";
import StringUtils from "components/common/utils/StringUtils";
import React from "react";
import { useState } from "react";
import { Form } from "react-bootstrap";

function ServiceEntityAddPopup({
  entityType,
  onOk,
  dataModelEntities,
  ...props
}) {
  const [tableNm, setTableNm] = useState("");
  const [physEntityNm, setPhysEntityNm] = useState("");
  const [logEntityNm, setLogEntityNm] = useState("");

  const onSave = () => {
    const body = {
      tableNm: tableNm.trim(),
      physEntityNm: physEntityNm.trim(),
      logEntityNm: logEntityNm.trim(),
      serviceInout: entityType,
    };
    if (StringUtils.isEmpty(tableNm)) {
      return Message.alert(
        "Please enter Entity Name.",
        Enums.MessageType.ERROR
      );
    }
    if (StringUtils.isEmpty(logEntityNm)) {
      return Message.alert(
        "Please enter Entity Logical Name.",
        Enums.MessageType.ERROR
      );
    }

    if (
      ArrayUtils.isArray(dataModelEntities) &&
      !ArrayUtils.isEmpty(dataModelEntities)
    ) {
      const dupEntity = dataModelEntities.find(
        (entity) => entity.tableNm === tableNm
      );
      if (dupEntity) {
        return Message.alert(
          "The entity name already exists.",
          Enums.MessageType.ERROR
        );
      }
    }

    if (onOk) {
      onOk(body);
      Popup.close();
    }
  };

  const onEnterKeyPress = (e) => {
    if (e.keyCode === 13) {
      onSave();
    }
  };

  return (
    <Modal>
      <Modal.Header title={`Add Service ${entityType} Entity`} />
      <Modal.Body>
        <UmodalTemplate>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label className="required">Entity Name</Form.Label>
              <Form.Control
                value={tableNm}
                onChange={(e) => {
                  setTableNm(e.currentTarget.value);
                  setPhysEntityNm(e.currentTarget.value);
                }}
                onKeyDown={onEnterKeyPress}
              />
            </Form.Group>
            {/* <Form.Group className="mb-3">
              <Form.Label>Entity 물리 명</Form.Label>
              <Form.Control value={physEntityNm} disabled />
            </Form.Group> */}
            <Form.Group className="mb-3">
              <Form.Label className="required">Entity Logical Name</Form.Label>
              <Form.Control
                value={logEntityNm}
                onChange={(e) => setLogEntityNm(e.currentTarget.value)}
                onKeyDown={onEnterKeyPress}
              />
            </Form.Group>
          </Form>
        </UmodalTemplate>
      </Modal.Body>
      <Modal.Footer>
        <Modal.Footer.Button onClick={onSave}>Save</Modal.Footer.Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ServiceEntityAddPopup;
