import { connect } from "react-redux";
import StandardGridCell from "components/builder/ui/uiComponents/grid/StandardGridCell";

class GridCell extends StandardGridCell {}

export default connect(
  (state) => {
    return {
      workspace: state.workspace,
    };
  },
  (dispatch) => {
    return {};
  }
)(GridCell);
