import Api from "components/common/Api";

class LayoutSettingService {
  static requestMapping = "/mobile";

  /**
   * LayoutSetting 페이지 Bottom Navigation List 가져오기
   * @param {*} data
   * @param {*} callbackFnc
   */
  static getBottomNavList(data, callbackFnc) {
    Api.post(this.requestMapping + "/getBottomNavList", data, callbackFnc);
  }

  /**
   * LayoutSetting 페이지 template 전부 가져오기 (메뉴, 상단 네비게이션, 테마)
   * @param {*} data
   * @param {*} callbackFnc
   */
  static getAllLayoutTmplList(data, callbackFnc) {
    Api.post(this.requestMapping + "/getAllLayoutTmplList", data, callbackFnc);
  }

  /**
   * LayoutSetting 페이지 저장된 정보 가져오기
   * @param {*} data
   * @param {*} callbackFnc
   */
  static getAppLayoutList(data, callbackFnc) {
    Api.post(this.requestMapping + "/getAppLayoutList", data, callbackFnc);
  }

  /**
   * LayoutSetting bottomNavList 저장
   * @param {*} data
   * @param {*} callbackFnc
   */
  static saveBottomNavList(data, callbackFnc) {
    Api.post(this.requestMapping + "/saveBottomNavList", data, callbackFnc);
  }

  /**
   * LayoutSetting TopNav data 저장
   * @param {*} data
   * @param {*} callbackFnc
   */
  static saveAppLayoutData(data, callbackFnc) {
    Api.post(this.requestMapping + "/saveAppLayoutData", data, callbackFnc);
  }

  /**
   * layoutType에 따른 custom 데이터 불러오기
   * @param {*} data
   * @param {*} callbackFnc
   */
  static getProgramInfo(data, callbackFnc) {
    Api.post(this.requestMapping + "/getProgramInfo", data, callbackFnc);
  }

  /**
   * appId 에 따른 모바일 커스텀 프로그램 list 불러오기 (D, L, T)
   * @param {*} data
   * @param {*} callbackFnc
   */
  static getCustomProgramList(data, callbackFnc) {
    Api.post(this.requestMapping + "/getCustomProgramList", data, callbackFnc);
  }

  /**
   * custom 데이터 저장하기
   * @param {*} data
   * @param {*} callbackFnc
   */
  static saveProgramInfo(data, callbackFnc) {
    Api.post(this.requestMapping + "/saveProgramInfo", data, callbackFnc);
  }
}
export default LayoutSettingService;
