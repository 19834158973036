import { Enums } from "components/builder/BuilderEnum";
import {
  initActive,
  initOutput,
} from "components/builder/ui/reducers/UIBuilderAction";
import Message from "components/common/Message";
import SessionUtils from "components/common/utils/SessionUtils";
import User from "components/common/utils/UserUtils";
import UserRegisterPopup from "page/popup/user/UserRegisterPopup";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import LocalStorageService from "services/common/LocalService";
import SettingService from "services/common/SettingService";
// import Popup from "components/common/Popup";
import { DaafLogin } from "@alpha/com.bizentro.daaf.front.framework";
import Popup from "components/common/Popup";

class WithoutNaviLogin extends DaafLogin {
  constructor(props) {
    super(props);
  }

  getUserRegisterButtonText = () => {
    return "Request Registration";
  };

  getLoginButtonText = () => {
    return "Login";
  };

  /**
   * @override
   * 등록요청 클릭
   */
  onClickUserRegister = () => {
    //팝업창 열기
    const options = {
      effect: Popup.ScaleUp, //Effect.SlideFromTop(default)를 Effect.ScaleUp 로 변경
      style: {
        content: {
          width: "30%", //popup의 크기를 50% (default 60%)
        },
      },
    };
    Popup.open(<UserRegisterPopup joinRequest={true} />, options);
  };

  onLoginCallback = (res) => {
    const { isSuccess, message } = res.data;
    //자동로그인
    if (isSuccess === "Y") {
      if (this.state.isIdSave) {
        LocalStorageService.set(
          Enums.LocalStorageName.AUTO_LOGIN_ID,
          this.state.userId
        );
      } else {
        LocalStorageService.remove(Enums.LocalStorageName.AUTO_LOGIN_ID);
      }
      // return console.log(res.data);
      SessionUtils.set(res.data);
      SettingService.getUserConfig({}, (res) => {
        const userConfig = {};
        res.data.map((config) => {
          const key = config.configurationCode;
          const value = JSON.parse(config.configurationValue);
          userConfig[key] = value;
        });
        User.setConfiguration(userConfig);
      });
      this.props.login();
      this.props.navigate(
        Enums.BuilderPath.UI.MAIN + "/" + Enums.BuilderPath.UI.EDITOR
      );
    } else {
      Message.alert(message, Enums.MessageType.ERROR);
    }
  };
}

WithoutNaviLogin.propTypes = {};

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const login = () => {
    dispatch(initOutput());
    dispatch(initActive());
  };

  return (
    <WithoutNaviLogin navigate={navigate} login={login} isIdSaveAct={true} />
  );
};

export default Login;
