import React from "react";
import ProcessModal from "./process/ProcessModal";
import { Col, Form, FormGroup, Row, Tooltip } from "react-bootstrap";
import { stopEvent } from "components/builder/ui/editor/handler/UIEditorEventHandler";
import StringUtils from "components/common/utils/StringUtils";
import Message from "components/common/Message";
import { Enums } from "components/builder/BuilderEnum";
import produce from "immer";
import { ObjectUtils } from "@alpha/com.bizentro.daaf.front.framework";
import {
  getEntityPropertyValue,
  getSourceEntity,
} from "components/builder/workflow/editor/render/WorkflowRenderUtils";
const PARAM_TYPE = {
  MAP: "Map",
  LIST: "List",
  HTTP_SERVELT_REQUEST: "HttpServeltRequest",
};

const DEFAULT_INPUT_SET = [
  {
    paramVariable: "request",
    paramType: PARAM_TYPE.HTTP_SERVELT_REQUEST,
  },
  {
    paramVariable: "requestParam",
    paramType: PARAM_TYPE.MAP,
  },
];

class ConditionPopup extends ProcessModal {
  constructor(props) {
    super(props);
    this.state = {
      processNm: "",
      inputRefList: DEFAULT_INPUT_SET,
      condition: {
        filter: "",
      },
    };
  }

  componentDidMount() {
    if (this.props.processInfo) {
      this.setState(this.props.processInfo);
    }
    const beforeConn = this.props.edges.filter(
      (item) => item.target === this.props.compId
    );
    if (!ObjectUtils.isEmpty(beforeConn)) {
      const beforeProc = this.props.edges.filter(
        (item) => item.target === beforeConn[0].source
      );

      let entityList = [...this.state.inputRefList];
      const sourceEntityList = getSourceEntity(
        beforeProc[0].source,
        this.props.workflow
      );
      entityList = [...entityList, ...this.getInputType(sourceEntityList)];

      this.setState({ inputRefList: entityList });
    }
  }

  /**
   * 이전에 연결된 소스 엔티티를 Input Param으로 전환
   * @returns
   */
  getInputType = (sourceList) => {
    const result = [];
    for (const process of sourceList) {
      if (StringUtils.includes(process.processType, this.returnNodeTypeList)) {
        const _entityPropertyValues = getEntityPropertyValue(process);
        for (const _entity of _entityPropertyValues) {
          const obj = {};
          obj.paramName = _entity.entityNm;
          obj.paramVariable = _entity.entityVariable;
          obj.paramType = PARAM_TYPE.MAP;
          if (_entity.parameterType) {
            if (_entity.parameterType === "multi") {
              obj.paramType = PARAM_TYPE.LIST;
            }
          }

          switch (_entity.processType) {
            case Enums.WorkflowProcessType.SELECT_ENTITY:
              if (
                !StringUtils.equalsIgnoreType(_entity.maxDataCount, 1) &&
                !_entity.filterOption.primaryFilter
              )
                obj.paramType = PARAM_TYPE.LIST;
              break;
            case Enums.WorkflowProcessType.DATA_AGGREGATION:
              if (_entity.groupColumnList.length > 0)
                obj.paramType = PARAM_TYPE.LIST;
              break;
            case Enums.WorkflowProcessType.SELECT_ENTITY_BY_QUERY:
              obj.paramType = PARAM_TYPE.LIST;
              break;
            case Enums.WorkflowProcessType.CALL_STORED_PROCEDURE:
              if (_entity.returnType === "multi")
                obj.paramType = PARAM_TYPE.LIST;
              break;
            default:
              break;
          }
          if (
            !ObjectUtils.isEmpty(obj) &&
            !result.find((r) => r.paramVariable === obj.paramVariable)
          ) {
            result.push(obj);
          }
        }
      }
    }
    return result;
  };

  validateCommonParam(e) {
    stopEvent(e);
    if (StringUtils.isEmpty(this.state.processNm)) {
      return Message.alert(
        "Please enter Condition Name.",
        Enums.MessageType.WARN
      );
    }
    if (this.onValidationCheck()) {
      this.onClickConfirm();
    }
  }

  onClickConfirm() {
    if (this.props.callbackFnc) {
      let body = {
        processNm: this.state.processNm,
        condition: this.state.condition,
      };
      this.props.callbackFnc(body);
    }
  }

  /**
   * Param Input 클릭
   * @param {*} param
   */
  onClickParam = (param) => {
    if (StringUtils.isEmpty(this.state.condition.filter)) {
      this.setState(
        produce(this.state, (draft) => {
          draft.condition.filter = param.paramVariable;
        })
      );
    } else {
      const splited = this.state.condition.filter.split(".");
      splited.push(param.paramVariable);
      this.setState(
        produce(this.state, (draft) => {
          draft.condition.filter = splited.join(".");
        })
      );
    }
  };

  onChangeValue = (e) => {
    this.setState(
      produce(this.state, (draft) => {
        draft.condition[e.target.id] = e.target.value;
      })
    );
  };

  /**
   *
   * @returns
   */
  renderProcessNm() {
    return (
      <Row className="mb-3">
        <Col xs={2} className="col-label">
          <Form.Label className="required">Condition Name</Form.Label>
        </Col>
        <Col xs={10}>
          <Form.Control
            placeholder="Please enter Condition Name."
            value={this.state.processNm}
            onChange={this.onChangeParam}
            id={"processNm"}
          />
        </Col>
      </Row>
    );
  }

  onValidationCheck() {
    return true;
  }

  renderBody() {
    return (
      <>
        <Row>
          <Col xs={2} className="col-label">
            <Form.Label>Input</Form.Label>
          </Col>
          <Col>
            <div className="condition-input-wrapper mb-3">
              {this.state.inputRefList.map((item) => {
                const disabled = StringUtils.includes(item.paramVariable, [
                  "request",
                  "requestParam",
                ]);
                return (
                  <div
                    className={`condition-input ${disabled ? "disabled" : ""}`}
                    key={item.paramVariable}
                    onClick={(e) => this.onClickParam(item)}
                  >
                    <div className="param-name">{item.paramName}</div>
                    <div className="param-name">{item.paramVariable}</div>
                    <div className="param-type">{item.paramType}</div>
                  </div>
                );
              })}
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={2} className="col-label">
            <Form.Label className="required">Condition</Form.Label>
          </Col>
          <Col>
            <FormGroup className="mb-3">
              <Form.Control
                value={this.state.condition.filter}
                id="filter"
                onChange={this.onChangeValue}
                ref={this.filterRef}
              />
            </FormGroup>
          </Col>
        </Row>
      </>
    );
  }
}

export default ConditionPopup;
