import WijmoGrid from "components/common/element/WijmoGrid";
import CommonUtils from "components/common/utils/CommonUtils";
import {
  AdminSettingButton,
  AdminSettingContents,
} from "components/setting/config/AdminSettingMain";
import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import SystemService from "services/common/SystemService";
import styled from "styled-components";

const EnvBox = styled(AdminSettingContents)``;

const columns = [
  {
    field: "envName",
    headerName: "Environment Name",
    width: 150,
  },
  {
    field: "envType",
    headerName: "Env Type",
    width: 150,
  },
  {
    field: "tenantId",
    headerName: "Tenant ID",
    width: 150,
  },
  {
    field: "coCd",
    headerName: "Company Code",
    width: 150,
  },
  {
    field: "applicationKind",
    headerName: "App Type",
    width: 150,
  },
  {
    field: "applicationUrl",
    headerName: "App URL",
    width: 150,
  },

  {
    field: "datasourceName",
    headerName: "DataSource Name",
    width: 150,
  },
  {
    field: "dbHost",
    headerName: "DB Host",
    width: 150,
  },
  {
    field: "dbName",
    headerName: "DB Name",
    width: 150,
  },
  {
    field: "dbPasswordIntYn",
    headerName: "DB PW Integer Status",
    width: 200,
  },
  {
    field: "dbType",
    headerName: "DB Type",
    width: 150,
  },
  {
    field: "dbUserName",
    headerName: "DB User Name",
    width: 150,
  },
  {
    field: "dbDesc",
    headerName: "DB Description",
    width: 150,
  },
  {
    field: "ipAddr",
    headerName: "IP Address",
    width: 150,
  },
  {
    field: "jdbcConnOption",
    headerName: "JDBC Connection Option",
    width: 150,
  },
  {
    field: "updtUserId",
    headerName: "Update User",
    width: 150,
  },
  {
    field: "updtDt",
    headerName: "Update Date",
    width: 200,
    renderCell: (params) => `${CommonUtils.getDate(params.updtDt, "datetime")}`,
    // valueGetter: (params) =>
    //   `${CommonUtils.getDate(params.row.updtDt, "datetime")}`,
  },
];

function SystemEnvManage() {
  const [envList, setEnvList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pageSize, setPageSize] = useState(20);
  useEffect(() => {
    getEnvList();
    return () => {
      setEnvList([]);
    };
  }, []);

  const getEnvList = () => {
    setIsLoading(true);
    SystemService.getEnvList({}, (res) => {
      setIsLoading(false);
      setEnvList(res.data);
    });
  };

  return (
    <>
      <EnvBox>
        <WijmoGrid
          isLoading={isLoading}
          columns={columns}
          rows={envList}
          pageSize={pageSize}
          rowsPerPageOptions={[pageSize]}
          style={{ height: "70vh" }}
        />
      </EnvBox>
      <AdminSettingButton>
        <Button>Add</Button>
        <Button>Delete</Button>
        <Button>Submit</Button>
      </AdminSettingButton>
    </>
  );
}

export default SystemEnvManage;
