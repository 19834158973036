import React, { Component } from "react";
import { vscodeDark } from "@uiw/codemirror-theme-vscode";
import { xcodeDark } from "@uiw/codemirror-theme-xcode";
import { githubDark } from "@uiw/codemirror-theme-github";
import { eclipse } from "@uiw/codemirror-theme-eclipse";
import { BsArrowsFullscreen, BsXSquare } from "react-icons/bs";
import { SessionUtils, StringUtils } from "components/common/utils/CommonUtils";
import CodeMirror from "@uiw/react-codemirror";
import { javascript } from "@codemirror/lang-javascript";
import { json } from "@codemirror/lang-json";
import { java } from "@codemirror/lang-java";
import { html } from "@codemirror/lang-html";
import { css } from "@codemirror/lang-css";
import { Form } from "react-bootstrap";
import { BsBoxArrowUpRight } from "react-icons/bs";
import * as Effect from "components/common/modal/Effect";
import Popup from "../Popup";
import Message from "../Message";
import { Enums } from "components/builder/BuilderEnum";

class UModalCodeEditor extends Component {
  constructor(props) {
    super(props);
    this.extensions = null;

    this.state = {
      editorTheme: null,
      isFullScreen: false,
      openDocument: false,
    };
  }

  //default editor theme
  DEFAULT_EDITOR_THEME = "vscode";

  //default editor height
  DEFAULT_EDITOR_HEIGHT = "500px";

  //default editor height
  DEFAULT_EDITOR_EXTNSION = "javascript";

  //editor theme list
  EDITOR_THEMES = {
    vscode: { name: "vscode Dark theme", theme: vscodeDark },
    xcode: { name: "xcode Dark theme", theme: xcodeDark },
    eclipse: { name: "eclipse theme", theme: eclipse },
    github: { name: "github Dark theme", theme: githubDark },
  };

  //allowed editor extention
  EDITOR_EXTENSIONS = {
    javascript: [javascript({ jsx: true })],
    java: [java({ jsx: true })],
    json: [json({ jsx: true })],
    html: [html({ jsx: true })],
    css: [css({ jsx: true })],
    template: [html({ jsx: true })],
  };

  /**
   * 최초 로드시
   */
  componentDidMount = () => {
    //최상위 componentDidMount는 initialize에서 구현하세요.
    this.initialize();
  };

  /**
   * Code Editor 관련 초기 화
   * 하위 class에서 componentDidMount를 overidding 할 경우 최상위 initialize를 호출해주세요
   */
  initialize = () => {
    this.setState({
      editorTheme: SessionUtils.get("editorTheme", "theme"),
    });
  };

  /**
   * Code 값 변경시
   * @param {String} changedValue
   */
  changeValue = (changedValue) => {
    if (this.props.onChange) {
      this.props.onChange(changedValue);
    } else {
      return Message.alert(
        "The onChange event is not set.",
        Enums.MessageType.WARN
      );
    }
  };

  /**
   * 해당(Event,Template 등) 코드에 대한 상세 메뉴얼 (도움말 문서)를 띄운다.
   * @param event
   */
  clickDocumentDetail = (Document) => {
    this.setState({ openDocument: true });
    let effect = { ...Effect.SlideFromRight }; //Effect.SlideFromTop(default)를 Effect.ScaleUp 로 변경

    //팝업창을 오른쪽 끝에 붙이기 위해 이동해야하는 x좌표의 %를 계산한다.
    //공식 : (50% - (0.5 * 창크기(%))) / 창 크기(%) ==> 우측 이동 횟수 * 100%  ==> ((50-0.5x)/x)*100%
    //ex) 창 크기가 40%일 경우 translateX 의 값은 : 50 - (0.5 * 40) => (30 / 40) * 100% ==> 75% translateX(75%)
    const width = 40;
    let translateX = ((50 - 0.5 * width) / width) * 100;
    effect.end.transform = "translateX(" + translateX + "%)";

    const options = {
      effect: Effect.SlideFromRight, //Effect.SlideFromTop(default)를 Effect.ScaleUp 로 변경
      style: {
        content: {
          width: width + "%", //popup의 크기를 50% (default 60%)
        },
      },
      onCloseCallback: () => {
        this.setState({ openDocument: false });
      },
      disableDragging: true, //modal의 effect를 적용 하기 위해서는 dragable을 비활성화 시켜야함...
    };
    Popup.open(<Document eventInfo={{ ...this.props.eventInfo }} />, options);
  };
  /**
   * collapse, expand icon
   * @returns icon
   */
  renderResizeButton = () => {
    return this.state.isFullScreen ? (
      <BsXSquare
        title="Close Full Screen"
        size={20}
        className="editor-btn"
        onClick={() => this.setState({ isFullScreen: false })}
      />
    ) : (
      <BsArrowsFullscreen
        title="Full Screen"
        size={16}
        className="editor-btn"
        onClick={() => this.setState({ isFullScreen: true })}
      />
    );
  };

  /**
   * API 문서 연결 버튼
   * 필요시 각 Editor에서 override해서 필요 문서를 link 한다.
   */
  renderApiButton = () => {};

  /**
   * collapse, expand icon
   * @returns icon
   */
  renderToolbarButtons = (activedTabKey) => {
    return (
      <div className="code-toolbar">
        {activedTabKey === "eventBuilder" ? (
          <BsBoxArrowUpRight
            className="editor-btn"
            size={20}
            onClick={this.props.onClickEventBuilder}
          />
        ) : (
          <Form.Select
            size="sm"
            style={{ width: "150px" }}
            value={StringUtils.defaultString(this.state.editorTheme)}
            onChange={this.changeTheme}
          >
            <option value="">Default theme</option>
            {Object.keys(this.EDITOR_THEMES).map((key, idx) => {
              return (
                <option value={key} key={key}>
                  {this.EDITOR_THEMES[key].name}
                </option>
              );
            })}
          </Form.Select>
        )}
        {this.renderApiButton()}
        {this.renderResizeButton()}
      </div>
    );
  };

  /**
   * theme를 변경한다.
   * @param {*} e
   */
  changeTheme = (e) => {
    SessionUtils.set({ editorTheme: e.target.value }, "theme");
    this.setState({ editorTheme: e.target.value });
  };

  /**
   * Theme setting 정보를 가져온다.
   * @returns
   */
  getThemeSettings = () => {
    return this.EDITOR_THEMES[this.state.editorTheme]
      ? this.EDITOR_THEMES[this.state.editorTheme].theme
      : this.EDITOR_THEMES[this.DEFAULT_EDITOR_THEME].theme;
  };

  /**
   * get Code value
   * @returns
   */
  getCodeValue = () => {
    return this.props.defaultValue;
  };

  /**
   * code mirror options
   * @returns options
   */
  getCodeMirrorOptions = () => {
    let options = {
      height: StringUtils.defaultString(
        this.props.height,
        this.DEFAULT_EDITOR_HEIGHT
      ),
      value: this.getCodeValue(),
      theme: this.getThemeSettings(),
      autoFocus: StringUtils.defaultString(this.props.autoFocus, false),
      className: "edit-source",
      placeholder: this.props.placeholder,
    };

    if (this.extensions) {
      options.extensions = this.extensions;
    }
    return options;
  };

  /**
   * Code Mirror를 화면에 표시
   * @returns
   */
  renderCodeMirror = () => {
    return (
      <CodeMirror
        {...this.getCodeMirrorOptions()}
        onChange={(value, viewUpdate) => {
          this.changeValue(value);
        }}
      />
    );
  };
}
export default UModalCodeEditor;
