import {
  DaafPopup,
  StringUtils,
} from "@alpha/com.bizentro.daaf.front.framework";
import { Enums } from "components/builder/BuilderEnum";
import WijmoGrid from "components/common/element/WijmoGrid";
import Message from "components/common/Message";
import Modal from "components/common/modal/UModal";
import UmodalTemplate from "components/common/modal/UModalTemplate";
import Popup from "components/common/Popup";

import { stopEvent } from "components/builder/ui/editor/handler/UIEditorEventHandler";
import produce from "immer";
import { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { MdDelete } from "react-icons/md";
import AppService from "services/common/AppService";
import AppEnvAddPopup from "./AppEnvAddPopup";

function AppRegisterPopup({ app, appTypeList = [], saveCallback, ...props }) {
  const [appNm, setAppNm] = useState(app ? app.appNm : "");
  const [appDesc, setAppDesc] = useState(app ? app.appDesc : "");
  const [appGroupCd, setAppGroupCd] = useState(app ? app.appGroupCd : "");

  const [appEnvList, setAppEnvList] = useState(app?.appEnvList || []);
  const [config, setConfig] = useState(app ? app.config : []);
  const [configDefault, setConfigDefault] = useState([]);

  useEffect(() => {
    getAppDefaultConfig();
  }, []);

  const appEnvColumns = [
    { field: "envName", headerName: "환경명" },
    {
      field: "host",
      headerName: "접속 URL",
    },
    { field: "runtimeHost", headerName: "Runtime Host" },
    { field: "envType", headerName: "환경 유형" },
    {
      field: "remark",
      headerName: "비고",
      renderCell: (data, index) => {
        return (
          <Button
            size="sm"
            variant="outline-danger"
            onClick={(e) => {
              stopEvent(e);
              const envList = [...appEnvList];
              envList[index].useYn = "N";
              setAppEnvList(envList);
            }}
          >
            <MdDelete />
          </Button>
        );
      },
    },
  ];

  useEffect(() => {
    if (app) getAppEnvList();
  }, []);

  /**
   * App저장
   * @param {*} e
   */
  const onSave = (e) => {
    if (e) e.preventDefault();

    const body = {
      ...app,
      appNm,
      appDesc,
      appGroupCd,
      config,
      useYn: "Y",
      appEnvList,
    };

    AppService.saveApplication(body, (res) => {
      Popup.close();
      Message.alert(`App ${appNm} is Registered.`, Enums.MessageType.SUCCESS);
      if (saveCallback) {
        saveCallback(res.data);
      }
    });
  };
  /**
   * 환경 추가
   */
  const editEnv = (env, index) => {
    const callback = (appEnvData) => {
      if (index !== undefined) {
        setAppEnvList(
          produce(appEnvList, (draft) => {
            draft[index] = {
              ...draft[index],
              ...appEnvData,
            };
          })
        );
      } else {
        setAppEnvList([...appEnvList, appEnvData]);
      }
      DaafPopup.close();
    };

    DaafPopup.open(<AppEnvAddPopup appEnv={env} callback={callback} />, {
      style: { content: { width: "650px" } },
    });
  };

  const getAppEnvList = () => {
    AppService.getAppEnvList({ appId: app.appId }, (res) => {
      setAppEnvList(res.data || []);
    });
  };
  const getAppDefaultConfig = () => {
    AppService.getAppDefaultConfig({}, (res) => {
      setConfigDefault(res.data);
    });
  };

  const setConfigValue = (value, index) => {
    setConfig(
      produce(config, (draft) => {
        draft[index].configValue = value;
      })
    );
  };

  const renderConfigOption = (con, index) => {
    //설정 호출
    const optionDefaultConfiguration = configDefault.find(
      (c) => c.configCd === con.configCd
    );
    //설정 없으면 리턴 처리
    if (!optionDefaultConfiguration) return <></>;

    //기 설정된 값 확인
    const configValue = config.find(
      (conf) => conf.configCd === con.configCd
    )?.configValue;

    if (
      StringUtils.equalsIgnoreCase(
        optionDefaultConfiguration.inputType,
        "select"
      )
    ) {
      const inputOption = JSON.parse(optionDefaultConfiguration.inputOption);
      return (
        <Form.Select
          id={optionDefaultConfiguration.configCd}
          value={configValue}
          defaultValue={optionDefaultConfiguration.configValue}
          onChange={(e) => setConfigValue(e.target.value, index)}
        >
          {inputOption.map((option, index) => {
            return (
              <option value={option.value} key={index}>
                {option.label}
              </option>
            );
          })}
        </Form.Select>
      );
    } else if (
      StringUtils.equalsIgnoreCase(
        optionDefaultConfiguration.inputType,
        "input"
      )
    ) {
      return (
        <Form.Control
          id={optionDefaultConfiguration.configCd}
          value={configValue || optionDefaultConfiguration.configValue}
          disabled
          onChange={(e) => setConfigValue(e.target.value, index)}
        />
      );
    }
  };

  return (
    <Modal>
      <Modal.Header title="New Application" />
      <Modal.Body>
        <UmodalTemplate>
          <Row>
            <Form.Group as={Col} xs={12} className="mb-3">
              <Form.Label className="required">Application Type</Form.Label>
              <Form.Select
                value={appGroupCd}
                onChange={(e) => setAppGroupCd(e.target.value)}
              >
                <option>Select</option>
                {appTypeList.map((app) => {
                  return (
                    <option value={app.id} key={app.id}>
                      {app.text}
                    </option>
                  );
                })}
              </Form.Select>
            </Form.Group>
            <Form.Group as={Col} xs={12} className="mb-3">
              <Form.Label className="required">Application Name</Form.Label>
              <Form.Control
                value={appNm}
                onChange={(e) => setAppNm(e.target.value)}
              />
            </Form.Group>

            <Form.Group as={Col} xs={12} className="mb-3">
              <Form.Label>Application Description</Form.Label>
              <Form.Control
                as={"textarea"}
                rows={3}
                style={{ resize: "none" }}
                value={appDesc}
                onChange={(e) => setAppDesc(e.target.value)}
              />
            </Form.Group>
            {config.map((con, index) => {
              return (
                <Form.Group
                  as={Col}
                  xs={12}
                  className="mb-3"
                  key={con.appConfigDefaultId}
                >
                  <Form.Label
                    className={
                      StringUtils.equalsIgnoreCase(con.notNullYn, "Y")
                        ? "required"
                        : ""
                    }
                  >
                    {con.configDesc}
                  </Form.Label>
                  {renderConfigOption(con, index)}
                  {/* <Form.Select
                    id={con.configCd}
                    value={con.configValue}
                    onChange={(e) => setConfigValue(e.target.value)}
                  >
                    <option value="N">허가된 인원만 접근</option>
                    <option value="Y">모두에게 허용</option>
                  </Form.Select> */}
                </Form.Group>
              );
            })}
          </Row>
          {StringUtils.equalsIgnoreCase(config?.trdUseYn, "Y") && (
            <>
              <Row className="mb-3">
                <Col xs={10} />
                <Col xs={2}>
                  <Button size="sm" onClick={(e) => editEnv()}>
                    환경 추가
                  </Button>
                </Col>
              </Row>
              <WijmoGrid
                columns={appEnvColumns}
                rows={appEnvList.filter((env) => env.useYn !== "N")}
                emptyMessage={"작업환경을 추가해주세요."}
                onRowDoubleClick={(env, e, index) => editEnv(env, index)}
              />
            </>
          )}
        </UmodalTemplate>
      </Modal.Body>
      <Modal.Footer>
        <Modal.Footer.Button onClick={onSave}>Save</Modal.Footer.Button>
      </Modal.Footer>
    </Modal>
  );
}

export default AppRegisterPopup;
