import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { RiDeleteBinLine } from "react-icons/ri";
import styled from "styled-components";
import SunEditor from "suneditor-react";
import User, { default as UserUtils } from "components/common/utils/UserUtils";
import "css/builder/programDesign/technicalSpecification.css";
import ArrayUtils from "components/common/utils/ArrayUtils";
import ObjectUtils from "components/common/utils/ObjectUtils";
import produce from "immer";
import { debounce } from "lodash";
import { setDesign } from "../reducer/ProgramDesignAction";
import { useDispatch } from "react-redux";
import StringUtils from "components/common/utils/StringUtils";

export const ScrollDiv = styled.div`
  max-height: calc(100vh - 300px);
  overflow-x: hidden;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background: #ccc;
  }
`;
const DetailSpecification = ({
  designMst,
  flag,
  setDesignMst,
  findMultilang,
  ...props
}) => {
  const editor = useRef([]);

  const [designMultilang, setDesignMultilang] = useState([]);
  const [tdTitleList, setTdTitleList] = useState([]);
  const [tdDescList, setTdDescList] = useState([]);
  const [tdSqlList, setTdSqlList] = useState([]);
  const [tdMsgList, setTdMsgList] = useState([]);
  const [tdModiList, setTdModiList] = useState([]);
  const [editorContent, setEditorContent] = useState({});

  const dispatch = useDispatch();

  const DTL = {
    TITLE: "td_title",
    DESC: "td_desc",
    SQL: "td_sql",
    MSG: "td_message",
    MODI: "td_modification",
  };

  useEffect(() => {
    setDesignMultilang(designMst.designMultilang || []);
    const tmpTdTitleList = designMst.designMultilang?.filter(
      (item) =>
        item.multilangCd.includes("td") && item.multilangCd.includes("title")
    );
    setTdTitleList(tmpTdTitleList || []);
    const tmpTdDescList = designMst.designMultilang?.filter(
      (item) =>
        item.multilangCd.includes("td") && item.multilangCd.includes("desc")
    );
    setTdDescList(tmpTdDescList || []);
    const tmpTdSqlList = designMst.designMultilang?.filter(
      (item) =>
        item.multilangCd.includes("td") && item.multilangCd.includes("sql")
    );
    setTdSqlList(tmpTdSqlList || []);
    const tmpTdMsgList = designMst.designMultilang?.filter((item) =>
      item.multilangCd.includes(DTL.MSG)
    );
    setTdMsgList(tmpTdMsgList || []);
    const tmpTdModiList = designMst.designMultilang?.filter((item) =>
      item.multilangCd.includes(DTL.MODI)
    );
    setTdModiList(tmpTdModiList || []);
  }, [designMst]);

  useEffect(() => {
    if (editorContent !== undefined) {
      onChangeDesignMultilang(editorContent);
    }
  }, [editorContent]);

  /**
   * redux 패치
   */
  const mstDebounce = debounce((newDesignMst) => {
    dispatch(setDesign(newDesignMst));
  }, 200);

  /**
   * designMst 변경
   * @param {*} key
   * @param {*} value
   */
  const onChangeDesignMst = (key, value) => {
    if (ObjectUtils.isObject(designMst)) {
      const newDesignMst = produce(designMst, (draft) => {
        draft[key] = value;
      });
      setDesignMst(newDesignMst);
      mstDebounce(newDesignMst);
    }
  };
  /**
   * designMultilang 변경
   * @param {*} key
   * @param {*} value
   */
  const onChangeDesignMultilang = (contentItem) => {
    if (!ObjectUtils.isEmpty(contentItem)) {
      let tmpItem = { ...contentItem.item };
      tmpItem.multilangText = contentItem.content;
      const tmpDesignMultiLang = [
        ...designMultilang.filter(
          (item) => item.multilangCd !== tmpItem.multilangCd
        ),
        tmpItem,
      ];
      setDesignMultilang(tmpDesignMultiLang);
      onChangeDesignMst("designMultilang", tmpDesignMultiLang);
    }
  };

  /**
   *  새로운 specification set 추가 함수
   * @param {*} e
   */
  const addSpecification = (e) => {
    let lastIdx = 1;
    if (!ArrayUtils.isEmpty(tdTitleList)) {
      const lastItem = tdTitleList.at(tdTitleList.length - 1);
      var regex = /[^0-9]/g;
      lastIdx = parseInt(lastItem.multilangCd.replace(regex, "")) + 1;
    }

    const newTitle = createNewData(DTL.TITLE, lastIdx);
    const newDesc = createNewData(DTL.DESC, lastIdx);
    const newSql = createNewData(DTL.SQL, lastIdx);
    const newMsg = createNewData(DTL.MSG, lastIdx);
    const newModi = createNewData(DTL.MODI, lastIdx);

    const tmpTitleList = [...tdTitleList, newTitle];
    const tmpDescList = [...tdDescList, newDesc];
    const tmpSqlList = [...tdSqlList, newSql];
    const tmpMsgList = [...tdMsgList, newMsg];
    const tmpModiList = [...tdModiList, newModi];
    setTdTitleList(tmpTitleList);
    setTdDescList(tmpDescList);
    setTdSqlList(tmpSqlList);
    setTdMsgList(tmpMsgList);
    setTdModiList(tmpModiList);

    let tmpDesignMultiLang = [
      ...designMultilang,
      newTitle,
      newDesc,
      newSql,
      newMsg,
      newModi,
    ];
    setDesignMultilang(tmpDesignMultiLang);
    onChangeDesignMst("designMultilang", tmpDesignMultiLang);
  };

  const createNewData = (key, lastIdx) => {
    let multilangCd = key + lastIdx;
    const newData = {
      designMstId: designMst.designMstId,
      langCd: User.getLanguage().toLowerCase(),
      insertUserId: User.getId(),
      updtUserId: User.getId(),
      multilangCd: multilangCd,
    };
    return newData;
  };

  /**
   * specification title 변경될 때 실행되는 함수
   * @param {*} e
   * @param {*} multilangCd
   */
  const onChangeTitle = (e, multilangCd) => {
    let tmpMultiLang = [...designMultilang];
    let tmpItem = {
      ...tmpMultiLang.find((item) => item.multilangCd === multilangCd),
    };
    tmpItem.multilangText = e.target.value;
    const idx = tmpMultiLang.findIndex((item) =>
      StringUtils.equalsIgnoreCase(item.multilangCd, multilangCd)
    );
    if (idx !== -1) {
      tmpMultiLang.splice(idx, 1, tmpItem);
      setDesignMultilang(tmpMultiLang);
      onChangeDesignMst("designMultilang", tmpMultiLang);
    }
  };

  /**
   * specification set 삭제 함수
   * @param {*} e
   * @param {*} idx
   */
  const deleteBtn = (e, idx) => {
    const tmpMultilangList = designMst.designMultilang.filter(
      (item) =>
        item.multilangCd !== "td_title" + idx &&
        item.multilangCd !== "td_desc" + idx &&
        item.multilangCd !== "td_sql" + idx &&
        item.multilangCd !== "td_msg" + idx &&
        item.multilangCd !== "td_modi" + idx
    );

    setDesignMultilang(tmpMultilangList);
    onChangeDesignMst("designMultilang", tmpMultilangList);
  };

  /**
   * editor blur - designMst.designMultilang 데이터가 변경되어도 초기값만 가지고 있어 로직 수정
   * @param {*} e
   *
   */
  const onBlurEditor = (e, item, idx) => {
    const data = {
      item: item,
      idx: idx,
    };
    if (item.multilangCd.includes("desc")) {
      data.content = editor.current[idx].desc.getContents();
    } else if (item.multilangCd.includes("sql")) {
      data.content = editor.current[idx].sql.getContents();
    } else if (item.multilangCd.includes(DTL.MSG)) {
      data.content = editor.current[idx].msg.getContents();
    } else if (item.multilangCd.includes(DTL.MODI)) {
      data.content = editor.current[idx].modi.getContents();
    }

    setEditorContent(data);
  };

  const getSunEditorInstance = (sunEditor, idx, key) => {
    let data = {};
    if (typeof editor.current[idx] !== "undefined") {
      data = editor.current[idx];
    }
    data[key] = sunEditor;
    editor.current[idx] = data;
  };

  return (
    <React.Fragment>
      <Row>
        <Col>
          <div className="content-title">Detail Specification</div>
        </Col>
        <Col>
          <Button
            className="add-spec-btn"
            size="sm"
            variant="outline-primary"
            onClick={(e) => addSpecification()}
          >
            Add Specification
          </Button>
        </Col>
      </Row>
      <ScrollDiv>
        {!ArrayUtils.isEmpty(tdTitleList)
          ? tdTitleList.map((item) => {
              var regex = /[^0-9]/g;
              const idx = parseInt(item.multilangCd?.replace(regex, ""));
              const descData = tdDescList.find(
                (desc) => desc.multilangCd === DTL.DESC + idx
              );
              const sqlData = tdSqlList.find(
                (desc) => desc.multilangCd === DTL.SQL + idx
              );
              const msgData = tdMsgList.find(
                (desc) => desc.multilangCd === DTL.MSG + idx
              );
              const modiData = tdModiList.find(
                (desc) => desc.multilangCd === DTL.MODI + idx
              );
              return (
                <div>
                  <Row className="mt-3">
                    <Col sm={4}>
                      <Form.Control
                        placeholder="Enter Title"
                        defaultValue={item?.multilangText}
                        onChange={(e) => onChangeTitle(e, item.multilangCd)}
                      ></Form.Control>
                    </Col>
                    <Col>
                      {idx !== 1 ? (
                        <Button
                          className="del-btn"
                          size="sm"
                          variant="outline-danger"
                          onClick={(e) => {
                            deleteBtn(e, idx);
                          }}
                        >
                          <RiDeleteBinLine />
                        </Button>
                      ) : (
                        ""
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={5}>
                      <div className="detail-content-title">Description</div>
                      <Row>
                        <Col>
                          <SunEditor
                            id={""}
                            lang={UserUtils.getLanguage().toLowerCase()}
                            setContents={descData?.multilangText}
                            onBlur={(e) => onBlurEditor(e, descData, idx)}
                            getSunEditorInstance={(sunEditor) =>
                              getSunEditorInstance(sunEditor, idx, "desc")
                            }
                            setDefaultStyle="font-family: 'Noto Sans KR', sans-serif;  min-height : 800px; max-height : 55vh"
                            setOptions={{
                              font: [
                                "Noto Sans KR",
                                "Arial",
                                "Comic Sans MS",
                                "Courier New",
                                "Impact",
                                "Georgia",
                                "tahoma",
                                "Trebuchet MS",
                                "Verdana",
                              ],
                              fontSize: [
                                8, 9, 10, 11, 12, 13, 14, 16, 18, 20, 22, 24,
                                26, 28, 36, 48, 72,
                              ],
                              buttonList: [
                                ["font", "fontSize", "formatBlock"],
                                [
                                  "bold",
                                  "underline",
                                  "italic",
                                  "strike",
                                  "fontColor",
                                  "hiliteColor",
                                ],
                              ],
                            }}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={7}>
                      <div className="detail-content-title">SQL</div>
                      <Row>
                        <Col>
                          <SunEditor
                            id={""}
                            lang={UserUtils.getLanguage().toLowerCase()}
                            setContents={sqlData?.multilangText}
                            onBlur={(e) => onBlurEditor(e, sqlData, idx)}
                            getSunEditorInstance={(sunEditor) =>
                              getSunEditorInstance(sunEditor, idx, "sql")
                            }
                            setDefaultStyle="font-family: 'Noto Sans KR', sans-serif;  height : 200px; "
                            setOptions={{
                              font: [
                                "Noto Sans KR",
                                "Arial",
                                "Comic Sans MS",
                                "Courier New",
                                "Impact",
                                "Georgia",
                                "tahoma",
                                "Trebuchet MS",
                                "Verdana",
                              ],
                              fontSize: [
                                8, 9, 10, 11, 12, 13, 14, 16, 18, 20, 22, 24,
                                26, 28, 36, 48, 72,
                              ],
                              buttonList: [
                                ["font", "fontSize", "formatBlock"],
                                [
                                  "bold",
                                  "underline",
                                  "italic",
                                  "strike",
                                  "fontColor",
                                  "hiliteColor",
                                ],
                              ],
                            }}
                          />
                        </Col>
                      </Row>
                      <div className="detail-content-title">Message</div>
                      <Row>
                        <Col>
                          <SunEditor
                            // id={tdMessage?.multilangCd}
                            lang={UserUtils.getLanguage().toLowerCase()}
                            setContents={msgData?.multilangText || ""}
                            onBlur={(e) => {
                              onBlurEditor(e, msgData, idx);
                            }}
                            getSunEditorInstance={(sunEditor) =>
                              getSunEditorInstance(sunEditor, idx, "msg")
                            }
                            setDefaultStyle="font-family: 'Noto Sans KR', sans-serif;  height : 200px;"
                            setOptions={{
                              font: [
                                "Noto Sans KR",
                                "Arial",
                                "Comic Sans MS",
                                "Courier New",
                                "Impact",
                                "Georgia",
                                "tahoma",
                                "Trebuchet MS",
                                "Verdana",
                              ],
                              fontSize: [
                                8, 9, 10, 11, 12, 13, 14, 16, 18, 20, 22, 24,
                                26, 28, 36, 48, 72,
                              ],
                              buttonList: [
                                ["font", "fontSize", "formatBlock"],
                                [
                                  "bold",
                                  "underline",
                                  "italic",
                                  "strike",
                                  "fontColor",
                                  "hiliteColor",
                                ],
                              ],
                            }}
                          />
                        </Col>
                      </Row>
                      <div className="detail-content-title">
                        Modification Source
                      </div>
                      <Row>
                        <Col>
                          <SunEditor
                            // id={tdMessage?.multilangCd}
                            lang={UserUtils.getLanguage().toLowerCase()}
                            setContents={modiData?.multilangText || ""}
                            onBlur={(e) => {
                              onBlurEditor(e, modiData, idx);
                            }}
                            getSunEditorInstance={(sunEditor) =>
                              getSunEditorInstance(sunEditor, idx, "modi")
                            }
                            setDefaultStyle="font-family: 'Noto Sans KR', sans-serif; height : 200px;"
                            setOptions={{
                              font: [
                                "Noto Sans KR",
                                "Arial",
                                "Comic Sans MS",
                                "Courier New",
                                "Impact",
                                "Georgia",
                                "tahoma",
                                "Trebuchet MS",
                                "Verdana",
                              ],
                              fontSize: [
                                8, 9, 10, 11, 12, 13, 14, 16, 18, 20, 22, 24,
                                26, 28, 36, 48, 72,
                              ],
                              buttonList: [
                                ["font", "fontSize", "formatBlock"],
                                [
                                  "bold",
                                  "underline",
                                  "italic",
                                  "strike",
                                  "fontColor",
                                  "hiliteColor",
                                ],
                              ],
                            }}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
              );
            })
          : ""}
      </ScrollDiv>
    </React.Fragment>
  );
};

export default DetailSpecification;
