/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import { BsPencilSquare } from "react-icons/bs";
import Popup from "components/common/Popup";
import Modal from "components/common/modal/UModal";
import styled from "styled-components";
import "bootstrap/dist/css/bootstrap.min.css";
import produce from "immer";

import {
  PropertyLable,
  PropertyValue,
} from "components/builder/ui/uiComponents/UIComponentStyle";
import StringUtils from "components/common/utils/StringUtils";
import { Col, Form, Row } from "react-bootstrap";
import ArrayUtils from "components/common/utils/ArrayUtils";
import DataModelService from "services/datamodel/DataModelService";
import DefaultInputPopup from "page/popup/DefaultInputPopup";
import UInputPopup from "components/common/element/UInputPopup";

const GridToolbarBtnPopupStyle = styled.div`
  .table-bottom {
    border-bottom: 1px solid #c9d3dd;
  }
  .table-header {
    border-bottom: 2px solid #c9d3dd;
    border-top: 1px solid #dadada;
    height: 35px;
    align-items: center;
    background: #f5f5f5;
    margin-left: 0;
    margin-right: 0;
  }
`;

const ProcedureConfigPopup = (props) => {
  const { title, callbackFnc, readonly, procedureName, entityId } = props;

  //codemirror

  const [procedureOptions, setProcedureOptions] = useState(
    props.procedureOptions || {}
  );
  const [dataBindingList, setDataBindingList] = useState([]);

  useEffect(() => {
    if (ArrayUtils.isEmpty(dataBindingList)) selectDataBindingList(entityId);
  }, []);

  const onChangeProcDataBinding = (e, arg, index) => {
    let newArg = { ...arg };
    //id를 setting해준다.
    newArg.dataBinding = StringUtils.substringAfter(e.target.value, ".");
    changeProcArgs(e, newArg, index);
  };

  const onChangeProcArgs = (e, arg, index) => {
    let newArg = { ...arg, [e.target.name]: e.target.value };
    changeProcArgs(e, newArg, index);
  };

  const changeProcArgs = (e, arg, index) => {
    const newArguments = produce(procedureOptions.arguments, (draft) => {
      draft[index] = { ...arg };
    });
    setProcedureOptions({
      ...procedureOptions,
      ...{ arguments: newArguments },
    });
  };

  /**
   * closePopup: value값을 전달하고 popup 닫기
   * @param {*} e
   */
  const closePopup = (e) => {
    if (callbackFnc) {
      callbackFnc.call(this, procedureOptions);
    }
    Popup.close();
  };

  /**
   * Data Model Entity Field List 조회(Data Binidng)
   * @param {*} entityId
   */
  const selectDataBindingList = (entityId) => {
    if (!StringUtils.isEmpty(entityId)) {
      DataModelService.getDataBindingList({ entityId: entityId }, (res) => {
        setDataBindingList(
          res.data ? res.data : [{ id: "none", text: "[none] None Data" }]
        );
      });
    }
  };
  /**
   * Default popup
   * @param {*} e
   */
  const openDefaultPopup = (e, argument, index) => {
    //팝업창 열기
    const options = {
      effect: Popup.ScaleUp, //Effect.SlideFromTop(default)를 Effect.ScaleUp 로 변경
      style: {
        content: {
          width: "45%",
        },
      },
    };

    let id = StringUtils.defaultString(e.target.id, e.currentTarget.formTarget);

    Popup.open(
      <DefaultInputPopup
        id={id}
        onChooseDefaultValue={(result) => {
          //set element value
          let el = document.getElementById(result.target.id);
          el.value = result.target.value;
          let eventObj = new Event("input", { target: el, bubbles: true });
          el.dispatchEvent(eventObj);
          onChangeProcArgs(
            {
              target: {
                name: "defaultValue",
                value: el.value,
              },
            },
            argument,
            index
          );
        }}
      />,
      options
    );
    if (e) e.preventDefault();
  };

  return (
    <GridToolbarBtnPopupStyle>
      <Modal>
        <Modal.Header title={title} />
        <Modal.Body className="popup-panel">
          <div className="w-full flex-wrap">
            <PropertyLable requried="true" className="float-left">
              Stored Procedure Name
            </PropertyLable>
            <PropertyValue className="float-left">
              <input
                type="text"
                id="procedureName"
                readOnly={true}
                defaultValue={StringUtils.defaultString(procedureName)}
                className="form-control form-control-sm"
              />
            </PropertyValue>
          </div>
          <div className="w-full">
            <PropertyValue className="float-left w-full">
              <Form.Group className="mb-3 table-bottom">
                <Col>
                  <Row className="mb-2 table-header">
                    <Col>Argument</Col>
                    <Col style={{ flexGrow: "0.5" }}>Type</Col>
                    <Col style={{ flexGrow: "0.5" }}>Mode</Col>
                    <Col>Data Binding</Col>
                    <Col>Fixed Value</Col>
                  </Row>
                  {!ArrayUtils.isEmpty(procedureOptions.arguments)
                    ? procedureOptions.arguments.map((argument, index) => {
                        return (
                          <Row
                            key={index}
                            className="pb-2"
                            style={{ alignItems: "center" }}
                          >
                            <Col>
                              <input
                                type="text"
                                className="form-control form-select-sm"
                                defaultValue={argument.name}
                                readOnly={true}
                              />
                            </Col>
                            <Col style={{ flexGrow: "0.5" }}>
                              <input
                                type="text"
                                className="form-control form-select-sm"
                                defaultValue={argument.type}
                                readOnly={true}
                              />
                            </Col>
                            <Col style={{ flexGrow: "0.5" }}>
                              <input
                                type="text"
                                className="form-control form-select-sm"
                                defaultValue={argument.mode}
                                readOnly={true}
                              />
                            </Col>
                            <Col>
                              {argument.name !== "tenantId" &&
                              argument.name !== "coCd" ? (
                                <Form.Select
                                  size="sm"
                                  id={`dataBinding${index}`}
                                  name="dataBinding"
                                  disabled={readonly}
                                  onChange={(e) =>
                                    onChangeProcDataBinding(e, argument, index)
                                  }
                                  value={StringUtils.defaultString(
                                    argument.dataBinding
                                  )}
                                >
                                  <option value={""}>Select</option>
                                  {!ArrayUtils.isEmpty(dataBindingList)
                                    ? dataBindingList.map(
                                        (option, optionIdx) => (
                                          <option
                                            key={optionIdx}
                                            value={option.id}
                                          >
                                            {option.text}
                                          </option>
                                        )
                                      )
                                    : ""}
                                </Form.Select>
                              ) : (
                                "N/A"
                              )}
                            </Col>
                            <Col>
                              {argument.name !== "tenantId" &&
                              argument.name !== "coCd" &&
                              argument.mode !== "OUT" ? (
                                <UInputPopup
                                  name="defaultValue"
                                  id={`defaultValue${index}`}
                                  defaultValue={StringUtils.defaultString(
                                    argument.defaultValue
                                  )}
                                  readOnly={readonly}
                                  onClick={(e) =>
                                    openDefaultPopup(e, argument, index)
                                  }
                                  onChange={(e) =>
                                    onChangeProcArgs(e, argument, index)
                                  }
                                />
                              ) : (
                                // <input
                                //   type="text"
                                //   id="defaultValue"
                                //   readOnly={readonly}
                                //   className="form-control form-select-sm"
                                //   defaultValue={StringUtils.defaultString(
                                //     argument.defaultValue
                                //   )}
                                //   onChange={(e) =>
                                //     onChangeProcArgs(e, argument, index)
                                //   }
                                // />
                                ""
                              )}
                            </Col>
                          </Row>
                        );
                      })
                    : ""}
                </Col>
              </Form.Group>
            </PropertyValue>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Modal.Footer.Button onClick={closePopup}>
            <BsPencilSquare />
            &nbsp;Confirm
          </Modal.Footer.Button>
        </Modal.Footer>
      </Modal>
    </GridToolbarBtnPopupStyle>
  );
};

export default ProcedureConfigPopup;
