import WijmoGrid from "components/common/element/WijmoGrid";
import CommonUtils from "components/common/utils/CommonUtils";
import {
  AdminSettingButton,
  AdminSettingContents,
} from "components/setting/config/AdminSettingMain";
import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import ComponentService from "services/common/ComponentService";
import styled from "styled-components";

const columns = [
  {
    field: "componentMstNm",
    headerName: "Component Group",
    width: 150,
  },
  {
    field: "componentClass",
    headerName: "Component Category",
    width: "2*",
  },
  {
    field: "componentType",
    headerName: "Component Type",
    width: 150,
  },
  {
    field: "componentDtlNm",
    headerName: "Component Name",
    width: "*",
  },
  {
    field: "updtUserId",
    headerName: "Update User",
    width: 150,
    align: "left",
  },
  {
    field: "updtDt",
    headerName: "Update Date",
    width: 300,
    renderCell: (params) => `${CommonUtils.getDate(params.updtDt, "datetime")}`,
    // valueGetter: (params) =>
    //   `${CommonUtils.getDate(params.row.updtDt, "datetime")}`,
  },
];

const ComponentManageBox = styled(AdminSettingContents)`
  width: 100%;
`;

function ComponentManage() {
  const [componentList, setComponentList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pageSize, setPageSize] = useState(20);

  useEffect(() => {
    getComponentList();
    return () => {
      setComponentList([]);
    };
  }, []);

  const getComponentList = () => {
    setIsLoading(true);
    ComponentService.getComponentList({}, (res) => {
      const data = res.data.map((mst) => {
        const { componentDtl, ...mstInfo } = mst;
        if (mst.componentDtl) {
          return mst.componentDtl.map((dtl) => {
            const obj = {
              ...mstInfo,
              ...dtl,
            };
            return obj;
          });
        }
      });
      setIsLoading(false);
      setComponentList(data.flat());
    });
  };

  return (
    <>
      <ComponentManageBox>
        <WijmoGrid
          isLoading={isLoading}
          columns={columns}
          rows={componentList}
          pageSize={pageSize}
          rowsPerPageOptions={[pageSize]}
          style={{ height: "70vh" }}
        />
      </ComponentManageBox>
      <AdminSettingButton>
        <Button>Edit</Button>
      </AdminSettingButton>
    </>
  );
}

export default ComponentManage;
