import React, { memo } from "react";

const BuilderLeftMenuPanel = memo((props) => {
  return (
    <div className={`left-component`}>
      {props.children}
    </div>
  );
});

export default BuilderLeftMenuPanel;
