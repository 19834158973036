import React, { useState, useEffect, CSSProperties } from "react";
import styled from "styled-components";
import produce from "immer";
import { MdOutlineLibraryAdd } from "react-icons/md";
import { AiOutlineDelete, AiOutlinePlus } from "react-icons/ai";
import CodeMirror from "@uiw/react-codemirror";
import { json } from "@codemirror/lang-json";
import { Enums } from "components/builder/BuilderEnum";
import { Tooltip } from "@mui/material";
import Message from "../Message";
import JsonUtils from "components/common/utils/JsonUtils";
import ArrayUtils from "components/common/utils/ArrayUtils";
import ObjectUtils from "components/common/utils/ObjectUtils";
import StringUtils from "components/common/utils/StringUtils";
import CodeMirrorWrapper from "components/common/element/CodeMirrorWrapper";

const BuilderWrapper = styled.div`
  min-width: 600px;
  width: 100%;
  max-height: 600px;
  overflow: auto;
  padding: 15px;
`;

const BuilderRow = styled.div`
  min-width: 600px;
  display: flex;
  border-radius: 5px;

  &:hover {
    background-color: #e1e1e1;
  }
`;
const StepWrapper = styled.div`
  /* width: 15px; */
  ${(props) => {
    if (props.step && props.step > 0) {
      return {
        // width:
        paddingLeft: `${props.step * 15}px`,
      };
    }
  }}
  /* height:40px; */
  & .step {
    width: 10px;
    height: 100%;
    & div:first-child {
      height: 50%;
      border-left: 2px dotted;
      border-bottom: 2px dotted;
    }

    & div:last-child {
      height: 50%;
      border-left: 2px dotted;
    }

    &.last {
      & div:last-child {
        height: 50%;
        border: none;
      }
    }
  }
`;

const ObjectWrapper = styled.div`
  display: grid;
  grid-template-columns: 4fr 3fr 5fr 2fr;
  gap: 15px;
  padding: 2px;
  width: 100%;
`;

const BuilderCol = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Select = styled.select`
  width: 100%;
  border-color: lightgray;
  border-radius: 3px;
  width: 100%;
  height: 100%;
  padding: 4px;
  &:focus {
    box-shadow: 0px 0px 7px dodgerblue;
  }
`;

const Input = styled.input`
  width: 100%;
  border: 1px solid lightgray;
  border-radius: 3px;
  padding-left: 20px;
  padding: 4px;
  &:focus {
    box-shadow: 0px 0px 7px dodgerblue;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Button = styled.button`
  border: none;
  background-color: transparent;
  color: dodgerblue;
  font-size: 14px;
  &:hover {
    font-weight: bold;
  }
`;

const EmptyList = styled.div`
  width: 100%;
  min-height: 100px;
  background: #3c3c3c55;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const newObjForm = () => {
  return {
    compId: StringUtils.getUuid(),
    key: "",
    value: "",
    type: DATA_TYPE.STRING,
  };
};

const newArrForm = (index) => {
  return {
    compId: StringUtils.getUuid(),
    key: index,
    // value: [{ ...newObjForm() }],
    value: "",
    subType: DATA_TYPE.ARRAY_INDEX,
    type: DATA_TYPE.STRING,
  };
};

const DATA_TYPE = {
  STRING: "string",
  ARRAY: "array",
  ARRAY_INDEX: "arrayIndex",
  OBJECT: "object",
  DATE_TIME: "dateTime",
};

/**
 * @param {{viewMode,keyLabel:String,valueLabel:String,addButtonLabel:String, isAddFieldButton:Boolean, isViewModeChangeButton:Boolean, style:CSSProperties, onChange:Function,emptyMessage:String, value : Object, editorConfig}} props
 * - viewMode : Builder || Editor
 * - keyLabel : JSON key의 라벨
 * - valueLabel : JSON value 라벨
 * - addButtonLabel : 필드 추가 버튼 라벨 (default 필드 추가)
 * - isAddFieldButton : 필드 추가 버튼 여부
 * - isViewModeChangeButton : viewMode 전환 버튼 여부
 * - style : builder Wrapper 스타일
 * - onChange : return value
 * - emptyMessage : JSON 데이터가 없을 때 Builder에서 보여지는 메세지
 * @returns
 */
function JSONBuilder({
  viewMode = "BUILDER",
  isViewModeChangeButton = true,
  ...props
}) {
  const [_viewMode, setViewMode] = useState(viewMode);
  const [builderData, setBuilderData] = useState([]);
  const [editorData, setEditorData] = useState("");

  /**
   * 뷰모드가 바뀔때
   */
  useEffect(() => {
    if (StringUtils.equalsIgnoreCase(viewMode, "builder")) {
      // setViewMode("editor");
      const _builderlist = JSONToBuilderArray(props.value);
      setBuilderData(_builderlist);
      setViewMode("builder");
    } else if (StringUtils.equalsIgnoreCase(viewMode, "editor")) {
      setEditorData(JSON.stringify(props.value, null, 2));
      setViewMode(viewMode);
    }
  }, [viewMode]);

  /**
   * 빌더 데이터가 바뀌게 되면 외부에 데이터에 적용한다.
   */
  useEffect(() => {
    if (builderData.length > 0) {
      try {
        const _data = BuilderArrayToJSON(builderData);
        props.onChange(_data);
      } catch (error) {
        console.error("BuilderArray To JSON Error");
      }
    }
  }, [builderData]);

  /**
   * 외부에서 JSON 데이터가 입력된 경우 builder에서 작성된 데이터와 다르면 외부 데이터를 우선으로 적용한다.
   */
  useEffect(() => {
    // if (
    //   JSON.stringify(props.value) !==
    //   JSON.stringify(BuilderArrayToJSON(builderData))
    // ) {
    //   setBuilderData(JSONToBuilderArray(props.value));
    // }
  }, [props.value]);

  /**
   * JSON 에서 빌더에서 사용되는 컴포넌트 형태로 변경
   * @param {*} obj
   * @param {*} list
   */
  const JSONToBuilderArray = (obj) => {
    const list = [];

    const _getType = (value) => {
      let type = "";
      if (value === null || value === undefined) {
        type = DATA_TYPE.STRING;
      } else if (typeof value === DATA_TYPE.STRING) {
        if (
          String(value).length === 24 &&
          StringUtils.indexOf(value, "T") === 10 &&
          StringUtils.indexOf(value, "Z") === 23
        ) {
          type = DATA_TYPE.DATE_TIME;
        } else {
          type = DATA_TYPE.STRING;
        }
      } else if (ArrayUtils.isArray(value)) {
        type = DATA_TYPE.ARRAY;
      } else if (ObjectUtils.isObject(value)) {
        type = DATA_TYPE.OBJECT;
      } else {
        type = DATA_TYPE.STRING;
      }
      return type;
    };

    const _classifyType = (key, value, _list) => {
      const body = {
        compId: StringUtils.getUuid(),
        key: key,
        value: null,
        type: _getType(value),
      };
      if (value === null) {
        body.value = value;
      } else if (typeof value === DATA_TYPE.STRING) {
        body.value = value;
      } else if (ArrayUtils.isArray(value)) {
        body.value = setArrChild(value);
      } else if (ObjectUtils.isObject(value)) {
        body.value = setObjChild(value);
      } else {
        body.value = value;
      }
      _list.push(body);
    };

    /**
     * Arr 타입 변환
     * @param {*} objValue
     * @param {*} _childList
     * @returns
     */
    const setArrChild = (objValue, _childList = []) => {
      for (const [index, item] of objValue.entries()) {
        let arrList = [];
        let type = DATA_TYPE.STRING;
        if (typeof item === "string") {
          arrList = item;
        } else if (ArrayUtils.isArray(item)) {
          setArrChild(item, arrList);
          type = DATA_TYPE.ARRAY;
        } else if (ObjectUtils.isObject(item)) {
          setObjChild(item, arrList);
          type = DATA_TYPE.OBJECT;
        }
        _childList.push({
          compId: StringUtils.getUuid(),
          key: index,
          value: arrList,
          type: type,
          subType: DATA_TYPE.ARRAY_INDEX,
        });
      }

      return _childList;
    };

    /**
     * object 타입 변환
     * @param {*} objValue
     * @param {*} _childList
     * @returns
     */
    const setObjChild = (objValue, _childList = []) => {
      for (const cKey in objValue) {
        _classifyType(cKey, objValue[cKey], _childList);
      }
      return _childList;
    };

    if (!ObjectUtils.isEmpty(obj)) {
      for (const key in obj) {
        _classifyType(key, obj[key], list);
      }
    }
    return list;
  };

  /**
   * 시간 데이터의 오차를 일치 시키도록 하는 함수
   * BuilderArrayToJSON에서 사용
   * @param {*} type
   * @param {*} value
   * @returns
   */
  const _stringOrDateTime = (type, value) => {
    if (type === DATA_TYPE.STRING) {
      return value;
    } else if (type === DATA_TYPE.DATE_TIME) {
      //년 월 일  시간 : 분 : 초 로 쪼개기 .000Z로 데이트타임 구분할것
      if (!StringUtils.isEmpty(value)) {
        const _date = new Date(value);
        const _Y = _date.getFullYear();
        const _M = _date.getMonth();
        const _D = _date.getDate();
        const _H = _date.getHours();
        const _MI = _date.getMinutes();
        const _S = _date.getSeconds();

        const _Ad = new Date();
        _Ad.setFullYear(_Y);
        _Ad.setMonth(_M);
        _Ad.setDate(_D);
        _Ad.setHours(_H);
        _Ad.setMinutes(_MI);
        _Ad.setSeconds(_S);
        _Ad.setMilliseconds(0);
        return _Ad.toISOString();
      } else {
        return "";
      }
    }
  };

  /**
   * 빌더에서 사용되는 컴포넌트 (Array)를 JSON 데이터로 변경
   * @param {*} objects
   * @returns
   */
  const BuilderArrayToJSON = (objects, isArrayChild = false) => {
    let data = {};

    if (!ObjectUtils.isObject(objects)) return false;

    if (isArrayChild) {
      data = [];

      for (const obj of objects) {
        if (obj.type === DATA_TYPE.STRING || obj.type === DATA_TYPE.DATE_TIME) {
          data.push(_stringOrDateTime(obj.type, obj.value));
        } else if (obj.type === DATA_TYPE.ARRAY) {
          data.push(BuilderArrayToJSON(obj.value, true));
        } else if (obj.type === DATA_TYPE.OBJECT) {
          data.push(BuilderArrayToJSON(obj.value));
        }
      }
    } else {
      for (const obj of objects) {
        if (obj.type === DATA_TYPE.STRING || obj.type === DATA_TYPE.DATE_TIME) {
          data[obj.key] = _stringOrDateTime(obj.type, obj.value);
        } else if (obj.type === DATA_TYPE.ARRAY) {
          data[obj.key] = obj.value.map((item) => {
            if (
              StringUtils.includesIgnoreCase(item.type, [
                DATA_TYPE.STRING,
                DATA_TYPE.DATE_TIME,
              ])
            ) {
              return item.value;
            } else if (item.type === DATA_TYPE.ARRAY) {
              return BuilderArrayToJSON(item.value, true);
            } else if (item.type === DATA_TYPE.OBJECT) {
              return BuilderArrayToJSON(item.value);
            }
          });
        } else if (obj.type === DATA_TYPE.OBJECT) {
          const pkey = StringUtils.isEmpty(obj.key) ? "_tempKey" : obj.key;
          if (!data[pkey]) data[pkey] = {};
          for (const k in obj.value) {
            const { value, type } = obj.value[k];
            const ckey = StringUtils.isEmpty(obj.value[k].key)
              ? "_tempKey"
              : obj.value[k].key;
            if (type === DATA_TYPE.STRING || type === DATA_TYPE.DATE_TIME) {
              data[pkey][ckey] = _stringOrDateTime(type, value);
            } else if (
              StringUtils.includes(type, [DATA_TYPE.ARRAY, DATA_TYPE.OBJECT])
            ) {
              data[pkey][ckey] = BuilderArrayToJSON(value);
            }
          }
        }
      }
    }
    return data;
  };

  if (props.children) {
    return <>{props.children}</>;
  } else {
    return (
      <>
        <Builder
          {...props}
          show={StringUtils.equalsIgnoreCase(_viewMode, "Builder")}
          builderData={builderData}
          setBuilderData={setBuilderData}
          setViewMode={setViewMode}
          isViewModeChangeButton={isViewModeChangeButton}
        />
        <Editor
          {...props}
          editorData={editorData}
          show={StringUtils.equalsIgnoreCase(_viewMode, "Editor")}
          setViewMode={setViewMode}
          isViewModeChangeButton={isViewModeChangeButton}
        />
      </>
    );
  }
}

const Builder = ({
  builderData = [],
  onChange = () => {},
  keyLabel,
  valueLabel,
  callback,
  isAddFieldButton = true,
  isViewModeChangeButton = false,
  setViewMode,
  show,
  setBuilderData,
  ...props
}) => {
  /**
   * 오브젝트 타입 변환
   * @param {*} e
   */
  const onAddObject = (e) => {
    setBuilderData([...builderData, { ...newObjForm() }]);
  };

  /**
   * input Change 이벤트
   * @param {*} e
   * @param {*} object
   * @param {*} type
   */
  const onChangeObject = (e, object, type) => {
    const newObj = produce(builderData, (draft) => {
      const newOne = JsonUtils.findNode(draft, "compId", object.compId);
      newOne[e.target.id] = e.target.value;
      if (type) {
        newOne.type = type;
      }
    });
    setBuilderData(newObj);
  };

  /**
   * 인풋 추가 array, object 동일
   * @param {*} e
   * @param {*} parentsCompId
   */
  const onAddArr = (e, parentsCompId, type) => {
    const newObj = produce(builderData, (draft) => {
      if (parentsCompId) {
        const obj = JsonUtils.findNode(draft, "compId", parentsCompId);
        if (type === DATA_TYPE.ARRAY) {
          const _form = newArrForm(obj.value.length ? obj.value.length : 0);
          obj.value.push(_form);
        } else {
          obj.value.push({ ...newObjForm() });
        }
      } else {
        draft.push({ ...newObjForm() });
      }
    });
    setBuilderData(newObj);
  };
  /**
   * 인풋 삭제
   * @param {*} e
   * @param {*} compId
   */
  const onDeleteArr = (e, compId) => {
    const newObj = produce(builderData, (draft) => {
      JsonUtils.removeNode(draft, "compId", compId);
    });

    const cleanupArr = (arr) => {
      return arr.filter((a) => a);
    };
    const result = cleanupArr(newObj);
    setBuilderData(result);
  };

  return (
    <BuilderWrapper
      style={{ ...props.style, display: show ? "block" : "none" }}
    >
      <ButtonWrapper>
        {isAddFieldButton && (
          <Button onClick={onAddObject}>
            {props.addButtonLabel || "+ Add Field"}
          </Button>
        )}
        {isViewModeChangeButton && (
          <Button
            style={{ color: "green" }}
            onClick={(e) => setViewMode("Editor")}
          >
            Editor Viewer
          </Button>
        )}
      </ButtonWrapper>
      {builderData.length > 0 ? (
        builderData.map((_obj, index) => {
          return (
            <BuilderObject
              key={_obj.compId}
              object={_obj}
              step={0}
              onChangeObject={onChangeObject}
              onAddArr={onAddArr}
              onDeleteArr={onDeleteArr}
              lastObject={index === builderData.length - 1}
              keyLabel={keyLabel}
              valueLabel={valueLabel}
              prevKey={[_obj.key]}
              setBuilderData={setBuilderData}
              {...props}
            />
          );
        })
      ) : (
        <EmptyList>{props.emptyMessage || "Please add Field."}</EmptyList>
      )}
    </BuilderWrapper>
  );
};

const BuilderObject = ({
  object,
  step,
  parentsType,
  parentsCompId,
  prevKey = [],
  onChangeObject,
  onAddArr,
  onDeleteArr,
  lastObject,
  builderConfig,
  keyLabel,
  valueLabel,
  ...props
}) => {
  const onChangeType = (e) => {
    const value = e.target.value;
    switch (value) {
      case DATA_TYPE.DATE_TIME:
      case DATA_TYPE.STRING:
        onChangeObject({ target: { id: "value", value: "" } }, object, value);
        break;
      case DATA_TYPE.ARRAY:
        onChangeObject(
          {
            target: {
              id: "value",
              value: [newArrForm(0)],
            },
          },
          object,
          value
        );
        break;
      case DATA_TYPE.OBJECT:
        onChangeObject(
          { target: { id: "value", value: [newObjForm()] } },
          object,
          value
        );
        break;
      default:
        break;
    }
  };

  const getDateValue = (time) => {
    if (StringUtils.isEmpty(time)) return "";
    const _timeChanger = (time) => {
      return time >= 10 ? time : `0${time}`;
    };
    const _ISOdate = new Date(time);
    const _date = new Date(
      _ISOdate.getTime() + _ISOdate.getTimezoneOffset() * 60000
    );
    // const currentTzTime = moment(dateTime).add(_date.getTimezoneOffset(), "m");
    const _Y = _date.getFullYear();
    const _M = _timeChanger(_date.getMonth() + 1);
    const _D = _timeChanger(_date.getDate());
    const _H = _timeChanger(_date.getHours());
    const _MI = _timeChanger(_date.getMinutes());
    const _S = _timeChanger(_date.getSeconds());
    const _dateValue = `${_Y}-${_M}-${_D} ${_H}:${_MI}:${_S}`;
    return _dateValue;
  };

  return (
    <>
      <BuilderRow>
        {step > 0 ? (
          <StepWrapper step={step}>
            {parentsCompId ? (
              <div className={`step ${lastObject ? "last" : ""}`}>
                <div></div>
                <div></div>
              </div>
            ) : (
              <></>
            )}
          </StepWrapper>
        ) : (
          <></>
        )}

        <ObjectWrapper>
          <BuilderCol>
            {object.subType === DATA_TYPE.ARRAY_INDEX ? (
              <Input
                placeholder={keyLabel}
                value={object.key}
                onChange={(e) => {}}
                disabled
                id="key"
              />
            ) : builderConfig?.key ? (
              <builderConfig.key
                {...props}
                placeholder={keyLabel || "Enter Key"}
                value={object.key}
                onChange={(e) => onChangeObject(e, object)}
                changeObject={onChangeObject}
                id="key"
                prevKey={prevKey}
                jsonDataSet={object}
              />
            ) : (
              <Input
                placeholder={keyLabel || "Enter Key"}
                value={object.key}
                onChange={(e) => onChangeObject(e, object)}
                id="key"
              />
            )}
          </BuilderCol>
          <BuilderCol>
            <Select value={object.type} onChange={onChangeType} id={"type"}>
              <option value={DATA_TYPE.STRING}>String</option>
              <option value={DATA_TYPE.DATE_TIME}>DateTime</option>
              <option value={DATA_TYPE.ARRAY}>Array</option>
              <option value={DATA_TYPE.OBJECT}>Map(Object)</option>
            </Select>
          </BuilderCol>
          <BuilderCol>
            {builderConfig?.value && object.type === DATA_TYPE.STRING ? (
              <builderConfig.value
                placeholder={valueLabel || "Enter Value"}
                value={object.value}
                onChange={(e) => onChangeObject(e, object)}
                changeObject={onChangeObject}
                id="value"
                prevKey={prevKey}
                jsonDataSet={object}
                {...props}
              />
            ) : object.type === DATA_TYPE.STRING ? (
              <Input
                placeholder={valueLabel || "Enter Value"}
                value={object.value}
                onChange={(e) => onChangeObject(e, object)}
                id="value"
              />
            ) : object.type === DATA_TYPE.DATE_TIME ? (
              <Input
                placeholder={"YYYY-MM-DD HH:MI:SS"}
                defaultValue={getDateValue(object.value)}
                onBlur={(e) => onChangeObject(e, object)}
                id="value"
              />
            ) : (
              <></>
            )}
          </BuilderCol>
          <BuilderCol>
            {object.type === DATA_TYPE.ARRAY ||
            object.type === DATA_TYPE.OBJECT ? (
              <Tooltip title="Add sub-level">
                <Button
                  style={{ color: "lime" }}
                  onClick={(e) => onAddArr(e, object.compId, object.type)}
                >
                  <AiOutlinePlus />
                </Button>
              </Tooltip>
            ) : (
              <></>
            )}
            <Tooltip title="Add same level">
              <Button onClick={(e) => onAddArr(e, parentsCompId, object.type)}>
                <MdOutlineLibraryAdd />
              </Button>
            </Tooltip>
            <Tooltip title="delete">
              <Button
                style={{ color: "tomato" }}
                onClick={(e) => onDeleteArr(e, object.compId)}
              >
                <AiOutlineDelete />
              </Button>
            </Tooltip>
          </BuilderCol>
        </ObjectWrapper>
      </BuilderRow>
      {object.type === DATA_TYPE.OBJECT || object.type === DATA_TYPE.ARRAY ? (
        <>
          {object.value.map((childObject, index) => {
            return (
              <BuilderObject
                {...props}
                step={step + 1}
                parentsType={object.type}
                parentsCompId={object.compId}
                prevKey={[...prevKey, childObject.key]}
                key={childObject.compId}
                object={childObject}
                onAddArr={onAddArr}
                onDeleteArr={onDeleteArr}
                onChangeObject={onChangeObject}
                lastObject={index === object.value.length - 1}
                builderConfig={builderConfig}
                keyLabel={keyLabel}
                valueLabel={valueLabel}
              />
            );
          })}
        </>
      ) : object.subType === DATA_TYPE.ARRAY_INDEX &&
        StringUtils.includesIgnoreCase(object.type, [
          DATA_TYPE.ARRAY,
          DATA_TYPE.OBJECT,
        ]) ? (
        <>
          {object.value.map((childObject, _index) => {
            return (
              <BuilderObject
                {...props}
                step={step + 1}
                parentsType={object.type}
                parentsCompId={object.compId}
                prevKey={[...prevKey, childObject.key]}
                key={childObject.compId}
                object={childObject}
                onAddArr={onAddArr}
                onDeleteArr={onDeleteArr}
                onChangeObject={onChangeObject}
                lastObject={_index === object.value.length - 1}
                builderConfig={builderConfig}
                keyLabel={keyLabel}
                valueLabel={valueLabel}
              />
            );
          })}
        </>
      ) : (
        <></>
      )}
    </>
  );
};

const Editor = ({
  value,
  onChange,
  callback,
  isViewModeChangeButton = false,
  setViewMode,
  show,
  ...props
}) => {
  const [viewSource, setViewSource] = useState("");

  useEffect(() => {
    if (ObjectUtils.isEmpty(value)) {
      setViewSource("");
    } else {
      const _pValueSource = JSON.stringify(value, null, 2);
      if (JSON.stringify(_pValueSource) !== viewSource) {
        setViewSource(JSON.stringify(value, null, 2));
      }
    }
  }, [value]);

  const onBlur = () => {
    try {
      if (StringUtils.isEmpty(viewSource)) {
        onChange({});
      } else {
        onChange(JSON.parse(viewSource));
      }
    } catch (error) {
      Message.alert("Data Format is not valid.", Enums.MessageType.WARN);
    }
  };

  const _validate = () => {
    try {
      if (StringUtils.isEmpty(viewSource)) {
        onChange({});
      } else {
        onChange(JSON.parse(viewSource));
      }
      Message.alert("This is Valid Data", Enums.MessageType.SUCCESS);
    } catch (error) {
      Message.alert("Data Format is Invalid.", Enums.MessageType.WARN);
    }
  };

  const changeViewSource = (source) => {
    setViewSource(source);
  };
  return (
    <BuilderWrapper
      style={{ ...props.style, display: show ? "block" : "none" }}
    >
      <ButtonWrapper>
        <Button onClick={_validate}>Validate JSON</Button>
        {isViewModeChangeButton && (
          <Button
            onClick={(e) => setViewMode("Builder")}
            style={{ color: "green" }}
          >
            Builder Viewer
          </Button>
        )}
      </ButtonWrapper>

      <code>
        {`Make sure to enclose the key in double quotes (" "). 
After writing, please check Validation.
    Valid : {"sampleKey":"sampleValue"}
    InValid : {sampleKey: "sampleValue"}`}
      </code>
      <div className="codeMirror-area">
        <CodeMirrorWrapper>
          <CodeMirror
            value={viewSource}
            className="source-container"
            height="400px"
            extensions={[json(true)]}
            autoFocus={false}
            onBlur={(e) => onBlur()}
            onChange={(value, viewUpdate) =>
              changeViewSource(value, viewUpdate)
            }
            {...props.editorConfig}
          />
        </CodeMirrorWrapper>
      </div>
    </BuilderWrapper>
  );
};
export default Object.assign(JSONBuilder, {
  Builder,
  Editor,
});
