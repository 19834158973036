import Message from "components/common/Message";
import Modal from "components/common/modal/UModal";
import { useEffect, useState } from "react";
// import UTextarea from "components/common/element/UTextarea";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import * as Enums from "components/builder/BuilderEnum";
import UmodalTemplate from "components/common/modal/UModalTemplate";
import JsonUtils from "components/common/utils/JsonUtils";
import ObjectUtils from "components/common/utils/ObjectUtils";
import StringUtils from "components/common/utils/StringUtils";
import User from "components/common/utils/UserUtils";
import produce from "immer";
import { Col, Form, Row } from "react-bootstrap";

function SaveDesignPopup(props) {
  const {
    title,
    callbackFnc,
    information,
    saveAs,
    updatePageByProps,
    workspace,
  } = props;

  const [isSaving, setIsSaving] = useState(false);
  const [programNm, setProgramNm] = useState("");
  const [description, setdescription] = useState("");
  const [expandSaveDetail, setExpandSaveDetail] = useState(true);

  useEffect(() => {
    if (information) {
      setProgramNm(information.programNm);
      setdescription(information.description);
    }
    if (saveAs) {
      setProgramNm("");
    }
  }, []);

  const onChangeProgramName = (e) => {
    let value = e.currentTarget.value;
    setProgramNm(value);
  };
  const onChangeProgramDesc = (e) => {
    let value = e.currentTarget.value;
    setdescription(value);
  };

  /**
   * 저장 경로 (Workspace render)
   * @returns
   */
  const renderSavePath = () => {
    return (
      <Form.Group className="mb-3">
        <Form.Label
          onClick={(e) => setExpandSaveDetail(!expandSaveDetail)}
          style={{ cursor: "pointer" }}
        >
          Check Save Path {expandSaveDetail ? "▲" : "▼"}
        </Form.Label>
        {expandSaveDetail ? (
          <>
            <Row>
              <Form.Group className="mb-3" as={Col}>
                <Form.Label>Application</Form.Label>
                <Form.Control disabled defaultValue={workspace.appNm} />
              </Form.Group>
              <Form.Group className="mb-3" as={Col}>
                <Form.Label>Module</Form.Label>
                <Form.Control disabled defaultValue={workspace.moduleNm} />
              </Form.Group>
              <Form.Group className="mb-3" as={Col}>
                <Form.Label>Version</Form.Label>
                <Form.Control disabled defaultValue={workspace.version} />
              </Form.Group>
            </Row>
            <Row>
              <Form.Group as={Col} className="mb-3">
                <Form.Label>Tenant ID</Form.Label>
                <Form.Control disabled defaultValue={workspace.tenantId} />
              </Form.Group>

              <Form.Group as={Col}>
                <Form.Label>Company Code</Form.Label>
                <Form.Control disabled defaultValue={workspace.coCd} />
              </Form.Group>
            </Row>
          </>
        ) : (
          <></>
        )}
      </Form.Group>
    );
  };

  /**
   * 공통 인풋 입력
   * @returns
   */
  const renderCommonInput = () => {
    return (
      <>
        <Form.Group className="mb-3">
          <Form.Label className="required">Program Name</Form.Label>
          <input
            type="text"
            id="programNm"
            name="programNm"
            className="form-control"
            onChange={onChangeProgramName}
            value={programNm}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Program Description</Form.Label>
          <textarea
            id="description"
            className="form-control form-control-sm"
            onChange={onChangeProgramDesc}
            defaultValue={description}
            rows={3}
            style={{ resize: "none" }}
          />
        </Form.Group>
      </>
    );
  };

  const inputValueValidation = () => {
    if (StringUtils.isEmpty(programNm)) {
      Message.alert("Please set Program Name.", Enums.MessageType.WARN);
      return false;
    }

    return {
      appId: workspace.appId,
      moduleCd: workspace.moduleCd,
      appReleaseId: workspace.appReleaseId,
      tenantId: workspace.tenantId,
      coCd: workspace.coCd,
      programNm,
      description,
      useYn: "Y",
      committer: User.getMastRecId(),
      commitComment: "",
      releaseCommentYn: "",
    };
  };

  const saveData = (event) => {
    if (event) event.preventDefault();
    const inputValue = inputValueValidation();
    if (inputValue) {
      SaveTransaction(setIsSaving, callbackFnc);
    }
  };
  const SaveTransaction = (setIsLoading, callback) => {
    const data = {
      programNm: programNm,
      description: description,
    };

    setIsLoading(true);
    setTimeout(() => {
      callback.call(data, inputValueValidation(), () => setIsLoading(false));
    }, 1000);
  };

  return (
    <Modal>
      {/*
       * Modal.Header props
       *   - title
       */}
      <Modal.Header title={title} />
      <Modal.Body>
        <Form>
          <UmodalTemplate>
            {renderSavePath()}
            {renderCommonInput()}
          </UmodalTemplate>
        </Form>
      </Modal.Body>
      <Modal.Footer
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Modal.Footer.ProgressButton
          onClick={saveData}
          variant="primary"
          doing={isSaving}
          doingText={"Saving..."}
        >
          Save Program
        </Modal.Footer.ProgressButton>
      </Modal.Footer>
    </Modal>
  );
}

export default SaveDesignPopup;
