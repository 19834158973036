import { Enums } from "components/builder/BuilderEnum";
import { AppContext } from "components/common/AppContextProvider";
import Message from "components/common/Message";
import MuiConfig from "components/common/config/MuiConfig";
import WijmoGrid from "components/common/element/WijmoGrid";
import CommonUtils, {
  ObjectUtils,
  StringUtils,
} from "components/common/utils/CommonUtils";
import StyleUtils from "components/common/utils/StyleUtils";
import { SettingContext } from "components/setting/SettingMenu";
import { useContext, useEffect, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import SettingService from "services/common/SettingService";

function StyleList() {
  const { style: styleRedux } = useSelector((state) => state.setting);
  //목록 더블 클릭시 커스텀 스타일로 이동
  const { setMenu } = useContext(SettingContext);
  const {
    workspace: { Info: workspace, openPopup: workspaceOpen },
  } = useContext(AppContext);

  const dispatch = useDispatch();
  const [selectedModule, setSelectedModule] = useState(
    styleRedux.moduleCd ? styleRedux.moduleCd : ""
  );
  const styleList = useRef([]);
  const [dpList, setDpList] = useState([]);
  const [selectedStyle, setSelectedStyle] = useState({});
  //검색
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    getStyleList(selectedModule);
  }, []);

  const columns = [
    {
      field: "No",
      headerName: "Number",
      align: "center",
      width: 150,
    },
    {
      field: "styleNm",
      headerName: "Style Name",
      width: "*",
    },
    {
      field: "updtUserId",
      headerName: "Update User",
      align: "left",
      width: "*",
    },
    {
      field: "updtDt",
      headerName: "Last Update Date",
      align: "left",
      width: 200,
      renderCell: (params) => CommonUtils.getDate(params.insertDt, "datetime"),
      // valueGetter: (params) =>
      //   CommonUtils.getDate(params.row.insertDt, "datetime"),
    },
    {
      field: "defaultYn",
      headerName: "Default Theme",
      align: "center",
      renderCell: (params) =>
        StringUtils.equalsIgnoreType(params.defaultYn, "Y") ? "Y" : "N",
      // valueGetter: (params) =>
      //   StringUtils.equalsIgnoreType(params.row.defaultYn, "Y") ? "Y" : "N",
    },
    {
      field: "remark",
      headerName: "Remarks",
      align: "center",
      renderCell: (params) =>
        styleRedux.apply &&
        StringUtils.equalsIgnoreType(params.styleId, styleRedux.styleId)
          ? "Applying"
          : "",
      // valueGetter: (params) =>
      //   styleRedux.apply &&
      //   StringUtils.equalsIgnoreType(params.row.styleId, styleRedux.styleId)
      //     ? "적용 중"
      //     : "",
    },
  ];

  /**
   * 스타일 목록 호출
   * @param
   */
  const getStyleList = () => {
    SettingService.getStyleList({ ...workspace }, (res) => {
      const list = res.data.map((s, idx) => {
        const obj = { ...s };
        obj.No = idx + 1;
        return obj;
      });
      styleList.current = list;
      setDpList(list);
    });
  };

  const onApplyStyle = () => {
    if (ObjectUtils.isEmpty(selectedStyle)) {
      return Message.alert("Please select style.", Enums.MessageType.INFO);
    } else {
      SettingService.getStyle({ styleId: selectedStyle.styleId }, (res) => {
        //적용
        StyleUtils.ApplyStyle(dispatch, {
          ...res.data,
          styleCode: JSON.parse(res.data.styleCode),
          apply: true,
        });
      });
    }
  };
  const onCellClick = (row) => {
    setSelectedStyle(row);
  };
  const onCellDoubleClick = (row) => {
    console.log("styleId", selectedStyle.styleId);
    SettingService.getStyle({ styleId: row.styleId }, (res) => {
      //리덕스
      StyleUtils.ApplyStyle(dispatch, {
        ...res.data,
        styleCode: JSON.parse(res.data.styleCode),
      });
      setMenu("styleCustom");
    });
  };

  /**
   * 스타일 삭제
   */
  const onDeleteStyle = () => {
    if (ObjectUtils.isEmpty(selectedStyle)) {
      Message.alert("Please select style.", Enums.MessageType.ERROR);
    } else {
      Message.confirm(
        `Are you sure to delete ${selectedStyle.styleNm} ?`,
        () => {
          SettingService.deleteStyle(
            { styleId: selectedStyle.styleId },
            (res) => {
              Message.alert(res.message, Enums.MessageType.SUCCESS);
              const sList = [...styleList.current];
              sList.splice(
                sList.findIndex((s) => s.styleId === selectedStyle.styleId),
                1
              );
              const dList = [...dpList];
              dList.splice(
                dList.findIndex((s) => s.styleId === selectedStyle.styleId),
                1
              );
              styleList.current = sList;
              setDpList(dList);
              setSelectedStyle({});
            }
          );
        }
      );
    }
  };

  const onKeyDown = (e) => {
    if (e.keyCode === 13) {
      onSearchList();
    }
  };

  const onSearchList = (e) => {
    if (styleList.current.length > 0) {
      const list = styleList.current.filter(
        (s) => StringUtils.indexOf(s.styleNm, searchTerm) > -1
      );
      setDpList(list);
    }
  };

  const onApplyOffStyle = (e) => {
    StyleUtils.ApplyOffStyle(dispatch);
  };

  return (
    <>
      <div className="setting-header">Style List</div>
      {!ObjectUtils.isEmpty(workspace) ? (
        <>
          <div className="setting-container list">
            <div className="search-bar">
              <span>Style Name </span>
              <input
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                onKeyDown={onKeyDown}
              />
              <Button size="sm" onClick={onSearchList}>
                Search
              </Button>
            </div>
            <WijmoGrid
              columns={columns}
              rows={dpList}
              onRowClick={onCellClick}
              onRowDoubleClick={onCellDoubleClick}
              getRowId={(row) => row.styleId}
              {...MuiConfig.grid.options}
              pageSize={10}
              rowsPerPageOptions={[10]}
              style={{ height: "55vh" }}
            />
          </div>
          <div className="setting-button split">
            <div>
              <Button onClick={onApplyStyle}>Apply Style</Button>
              {styleRedux.apply && (
                <Button onClick={onApplyOffStyle} variant={"outline-secondary"}>
                  Cancel Style Application
                </Button>
              )}
            </div>
            <div>
              <Button variant="outline-danger" onClick={onDeleteStyle}>
                Delete
              </Button>
            </div>
          </div>
        </>
      ) : (
        <div className="workspace-empty-alert">
          <div className="alert-msg">Workspace setting Required </div>
          <Button onClick={workspaceOpen}>Open Popup</Button>
        </div>
      )}
    </>
  );
}

export default StyleList;
