import TreeItem, { treeItemClasses } from "@mui/lab/TreeItem";
import {
  PropertyLable,
  PropertyValue,
} from "components/builder/ui/uiComponents/UIComponentStyle";
import MiscComponent from "components/builder/ui/uiComponents/misc/MiscComponent";
import USelectbox from "components/common/element/USelectbox";
import produce from "immer";
import React from "react";
import { Accordion } from "react-bootstrap";
import { BiCommentDetail } from "react-icons/bi";

import TreeView from "@mui/lab/TreeView";
import {
  ArrayUtils,
  JsonUtils,
  ObjectUtils,
  StringUtils,
} from "components/common/utils/CommonUtils";
import { FiMinusSquare, FiPlusSquare } from "react-icons/fi";

import { Collapse } from "@mui/material";
import { alpha, styled } from "@mui/material/styles";
import { animated, useSpring } from "@react-spring/web";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import * as Enums from "components/builder/BuilderEnum";
import { AppContext } from "components/common/AppContextProvider";
import Message from "components/common/Message";
import Popup from "components/common/Popup";
import UElementList from "components/common/element/UElementList";
import PopupHandleConfigPopup from "page/popup/PopupHandleConfigPopup";
import AccordionBody from "react-bootstrap/esm/AccordionBody";
import AccordionHeader from "react-bootstrap/esm/AccordionHeader";
import AccordionItem from "react-bootstrap/esm/AccordionItem";
import UIComponentSection from "../../editor/UIComponentSection";
import TreeEventTabPopup from "page/popup/TreeEventTabPopup";
import TreeEventPopup from "page/popup/TreeEventPopup";

class Tree extends MiscComponent {
  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      targets: [],
      componentOperator: {},
    };
    this.btnChange = this.btnChange.bind(this);
    this.onChangeTreeOptions = this.onChangeTreeOptions.bind(this);
    this.openPopupEvent = this.openPopupEvent.bind(this);
  }
  static contextType = AppContext;

  /**
   * BootstrapSwitchButton Change Event
   * @param {*} pId
   * @param {*} pValue
   */
  btnChange = (pId, pValue) => {
    this.onChangePropertyValue(pId, pValue);
  };

  onChangeIdValue = (pId, pVal) => {
    this.onChangeTreeOptions({ target: { id: pId, value: pVal } });
  };

  onChangeTreeOptions = (event) => {
    let optionId = event.target.id;
    let optionValue = { [optionId]: event.target.value };

    const newPropertyValue = produce(this.state.propertyValue, (draft) => {
      draft.treeOptions = optionValue;
    });
    this.setState(
      {
        propertyValue: newPropertyValue,
      },
      () => {
        this.props.fn.updateProperty(this.state.propertyValue);
      }
    );
  };

  componentDidUpdate = (prevProps, prevState) => {};
  componentDidMount = (prevProps, prevState) => {
    if (this.props.event === "renderPropertiesPanel") {
      this.setComponentOperator();
    }
  };
  /**
   * Event 상세 Popup 연결
   * @param {*} e
   */
  openPopupEvent = (e) => {
    // debugger;
    const targetId = e.currentTarget.formTarget || e.currentTarget.id;
    const id = StringUtils.substringBefore(targetId, "_");
    const index = StringUtils.substringAfter(targetId, "_");

    let treeOptions = this.state.propertyValue.treeOptions;
    let items = !ObjectUtils.isEmpty(treeOptions)
      ? treeOptions.treeEvent.slice()
      : [{}];
    let item = items[index];
    if (StringUtils.isEmpty(item.eventType)) {
      return Message.alert(
        "Event is a Required Field.",
        Enums.MessageType.ERROR
      );
    }

    /**
     * 팝업 데이터 콜백 함수
     * PopupHandleConfigPopup , MesFunTabPopup
     * 전처리 후처리가 탭으로 나뉘어진 팝업에 사용
     * 이벤트 워크스페이스를 삭제하는 로직을 공유함
     * @param {*} backItems - 콜백 값(파라미터)
     * @param {*} target - 대상 컴포넌트
     * @returns
     */
    const popupCallback = (backItems, target) => {
      return new Promise((resolve, reject) => {
        let data = { ...target, ...backItems };
        JsonUtils.cleanup(data); //"" 공백값 제거
        if (
          !ObjectUtils.isEmpty(backItems.eventWorkspace) &&
          ((target["beforeSubmit"] &&
            target["beforeSubmit"] !== backItems.beforeSubmit) ||
            (target["afterSubmit"] &&
              target["afterSubmit"] !== backItems.afterSubmit))
        ) {
          this.showEventChangeConfirmMessage(
            () => {
              data = produce(data, (draft) => {
                if (backItems.beforeSubmit)
                  delete draft.eventWorkspace.beforeSubmit;
                if (backItems.afterSubmit)
                  delete draft.eventWorkspace.afterSubmit;
              });
              resolve(data);
            },
            () => resolve()
          );
        } else {
          resolve(data);
        }
      });
    };

    if (item.eventType === "TREE_CLICK_P") {
      const sizeList = this.context.code.getCodeList("Z0021");
      const positionList = this.context.code.getCodeList("Z0013");

      const options = {
        effect: Popup.ScaleUp, //Effect.SlideFromTop(default)를 Effect.ScaleUp 로 변경
        style: {
          content: {
            width: "50%",
          },
        },
      };

      Popup.open(
        <PopupHandleConfigPopup
          workspace={this.context.workspace.Info}
          title="Open Popup Setting"
          id={id}
          fieldType="json"
          item={item}
          data={{
            size: sizeList,
            position: positionList,
          }}
          entityId={this.state.propertyValue.dataModelEntityId}
          callbackFnc={(backItems) => {
            popupCallback(backItems, item).then((data) => {
              if (!ObjectUtils.isEmpty(data)) {
                items[index] = data;
                this.onChangeIdValue("treeEvent", items);
              }
            });
          }}
          {...this.setEventBuilderProps("treeEvent", item)}
        />,
        options
      );
    } else if (item.eventType === "TREE_CLICK_C") {
      const options = {
        effect: Popup.ScaleUp, //Effect.SlideFromTop(default)를 Effect.ScaleUp 로 변경
        style: {
          content: {
            width: "50%",
          },
        },
      };

      Popup.open(
        <TreeEventPopup
          title="Tree Click Event(Custom)"
          item={item}
          id={id}
          callbackFnc={(backItems) => {
            popupCallback(backItems, item).then((data) => {
              if (!ObjectUtils.isEmpty(data)) {
                items[index] = data;
                this.onChangeIdValue("treeEvent", items);
              }
            });
          }}
          {...this.setEventBuilderProps("treeEvent", item)}
        />,
        options
      );
    } else if (item.eventType === "TREE_CLICK_B") {
      const options = {
        effect: Popup.ScaleUp, //Effect.SlideFromTop(default)를 Effect.ScaleUp 로 변경
        style: {
          content: {
            width: "50%",
          },
        },
      };

      Popup.open(
        <TreeEventTabPopup
          title="Tree Click Event(Binding)"
          item={item}
          id={id}
          entityId={this.state.propertyValue.dataModelEntityId}
          callbackFnc={(backItems) => {
            popupCallback(backItems, item).then((data) => {
              if (!ObjectUtils.isEmpty(data)) {
                items[index] = data;
                this.onChangeIdValue("treeEvent", items);
              }
            });
          }}
          codeList={this.context.code}
          isShowOperator={true}
          {...this.setEventBuilderProps("treeEvent", item)}
        />,
        options
      );
    } else if (item.eventType === "TREE_LOAD") {
      //TREE_LOAD : 트리 로드
      const options = {
        effect: Popup.ScaleUp, //Effect.SlideFromTop(default)를 Effect.ScaleUp 로 변경
        style: {
          content: {
            width: "50%",
          },
        },
      };

      Popup.open(
        <TreeEventPopup
          title="Tree Load Event"
          item={item}
          id={id}
          callbackFnc={(backItems) => {
            popupCallback(backItems, item).then((data) => {
              if (!ObjectUtils.isEmpty(data)) {
                items[index] = data;
                this.onChangeIdValue("treeEvent", items);
              }
            });
          }}
          {...this.setEventBuilderProps("treeEvent", item)}
        />,
        options
      );
    } else if (item.eventType === "TREE_CLICK_Q") {
      //트리 쿼리시 이벤트
      const options = {
        effect: Popup.ScaleUp, //Effect.SlideFromTop(default)를 Effect.ScaleUp 로 변경
        style: {
          content: {
            width: "50%",
          },
        },
      };

      this.componentOperator = this.context.code.getCodeList("Z0026");
      Popup.open(
        <TreeEventTabPopup
          title="Tree Click Event(Query)"
          item={item}
          isShowOperator={this.componentOperator}
          entityId={this.state.propertyValue.dataModelEntityId}
          id={id}
          callbackFnc={(backItems) => {
            popupCallback(backItems, item).then((data) => {
              if (!ObjectUtils.isEmpty(data)) {
                items[index] = data;
                this.onChangeIdValue("treeEvent", items);
              }
            });
          }}
          {...this.setEventBuilderProps("treeEvent", item)}
        />,
        options
      );
    } else if (
      item.eventType === "TREE_DRAG_S" ||
      item.eventType === "TREE_DRAG_O" ||
      item.eventType === "TREE_DRAG_D"
    ) {
      //TREE_LOAD : 트리 drag start
      const options = {
        effect: Popup.ScaleUp, //Effect.SlideFromTop(default)를 Effect.ScaleUp 로 변경
        style: {
          content: {
            width: "50%",
          },
        },
      };
      let dragType = "";
      if (item.eventType.includes("_S")) {
        dragType = "Start";
      } else if (item.eventType.includes("_O")) {
        dragType = "Over";
      } else if (item.eventType.includes("_D")) {
        dragType = "Drop";
      }
      Popup.open(
        <TreeEventPopup
          title={`Tree Drag ${dragType} Event`}
          item={item}
          id={id}
          callbackFnc={(backItems) => {
            popupCallback(backItems, item).then((data) => {
              if (!ObjectUtils.isEmpty(data)) {
                items[index] = data;
                this.onChangeIdValue("treeEvent", items);
              }
            });
          }}
          {...this.setEventBuilderProps("treeEvent", item)}
        />,
        options
      );
    }
  };

  /**
   * Event빌더 팝업 열기위한 프롭스
   * @param {*} type event
   * @param {*} item 이벤트 정보 컨테이너
   * @returns
   */
  setEventBuilderProps = (type, item, pId) => {
    const eventType = item?.eventType || item?.id;
    let eventWorkspace = item.eventWorkspace || {};

    if (StringUtils.equalsIgnoreCase(type, "treeEvent")) {
      let eventInfo = this.props.componentInfo.componentEvent.find(
        (event, index) => event.eventHandleCd === eventType
      );
      let targetType = "";
      let targetObject = this.state.propertyValue.treeOptions.treeEvent.find(
        (e) => e.eventType === eventType
      );
      if (targetObject) targetType = targetObject.type;

      const getEventInfo = (eventType) => {
        return {
          eventWorkspace: eventWorkspace[eventType],
          compId: this.props.componentInfo.compId,
          eventType:
            Enums.EventHandlerEventType[eventType] ||
            Enums.EventHandlerEventType.USR_EVENT_FUNCTION,
          builderEventType: item.eventType || eventType,
          eventCd: eventInfo.eventCd,
          targetType: targetType || "tree",
          componentEventType: eventType,
          eventCategory: type,
          programType: this.props.output.page.propertyValue?.programType || "M",
        };
      };

      return {
        eventWorkspace: !ObjectUtils.isEmpty(eventWorkspace)
          ? eventWorkspace
          : null,
        getEventInfo,
        onClickEventBuilder: (_type) => {
          this.props.fn.onClickEventBuilder(() => getEventInfo(_type));
        },
      };
    }
  };

  /**
   * Properties tab panel을 Redering
   * @returns
   */
  renderPropertiesPanel = () => {
    const treeOptions = this.state.propertyValue.treeOptions || {};
    // this.setState({ treeOptions: treeOptions });

    let newTargets = [{ id: "none", text: "None Data" }];
    newTargets = newTargets.concat(
      this.getEventTargetNode(
        [Enums.ComponentType.FORM, Enums.ComponentType.GRID],
        [Enums.FormType.SEARCH],
        false
      )
    );
    if (JSON.stringify(this.state.targets) !== JSON.stringify(newTargets)) {
      this.setState({ targets: newTargets });
    }

    return (
      <React.Fragment>
        {/* Title */}
        {this.renderComponentTitle("Tree")}
        <Accordion defaultActiveKey={[0, 1, 2, 3, 4]} alwaysOpen>
          <Accordion.Item eventKey={0}>
            <Accordion.Header>Basic Info</Accordion.Header>
            <Accordion.Body>
              <PropertyLable requried="true">ID</PropertyLable>
              <PropertyValue>
                <input
                  type="text"
                  id="id"
                  defaultValue={StringUtils.defaultString(
                    this.state.propertyValue.id
                  )}
                  className="form-control form-control-sm"
                  onBlur={this.onChange}
                  onChange={this.onChangeTreeOptions}
                />
              </PropertyValue>
              <PropertyLable requried="true">Data Entity</PropertyLable>
              <PropertyValue>
                <USelectbox
                  type="search"
                  id="dataModelEntityId"
                  defaultValue={StringUtils.defaultString(
                    this.state.propertyValue.dataModelEntityId
                  )}
                  onChange={this.onChange}
                  url="/datamodel/getDataModelEntityList"
                  params={{
                    dataModelId: this.getDataModel(),
                  }}
                  options={{
                    // isChoose: false,
                    matchId: "id",
                    matchNm: "text",
                    beforeChkFn: () => {
                      if (StringUtils.isEmpty(this.getDataModel())) {
                        Message.alert(
                          "Please register the Data Model on the Page first.",
                          Enums.MessageType.ERROR
                        );
                        return false;
                      }
                      return true;
                    },
                  }}
                />
                {/* <ButtonGroup>
                  <input
                    type="text"
                    id="dataModelEntityNm"
                    defaultValue={StringUtils.defaultString(
                      this.state.propertyValue.dataModelEntityNm
                    )}
                    className="form-control form-control-sm"
                    readOnly={true}
                  />
                  <button
                    id="entityPopup"
                    onClick={this.openPopupButton}
                    className="btn btn-sm btn-outline-secondary light-font-color"
                    style={{ width: "100px" }}
                  >
                    Param 설정
                  </button>
                </ButtonGroup> */}
              </PropertyValue>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey={1}>
            <Accordion.Header>Column Setting</Accordion.Header>
            <Accordion.Body>
              <PropertyLable>Upper Column</PropertyLable>
              <PropertyValue>
                <USelectbox
                  type="entityField"
                  id="parentIdColumn"
                  defaultValue={StringUtils.defaultString(
                    this.state.propertyValue.parentIdColumn
                  )}
                  onChange={this.onChange}
                  entityId={this.state.propertyValue.dataModelEntityId}
                  options={{
                    matchId: "id",
                    matchNm: "text",
                    beforeChkFn: () => {
                      if (
                        StringUtils.isEmpty(
                          this.state.propertyValue.dataModelEntityId
                        )
                      ) {
                        return false;
                      }
                      return true;
                    },
                  }}
                />
              </PropertyValue>
              <PropertyLable>Level</PropertyLable>
              <PropertyValue>
                <USelectbox
                  type="entityField"
                  id="level"
                  defaultValue={StringUtils.defaultString(
                    this.state.propertyValue.level
                  )}
                  onChange={this.onChange}
                  entityId={this.state.propertyValue.dataModelEntityId}
                  options={{
                    matchId: "id",
                    matchNm: "text",
                    beforeChkFn: () => {
                      if (
                        StringUtils.isEmpty(
                          this.state.propertyValue.dataModelEntityId
                        )
                      ) {
                        return false;
                      }
                      return true;
                    },
                  }}
                />
              </PropertyValue>
              <PropertyLable>Value Column</PropertyLable>
              <PropertyValue>
                <USelectbox
                  type="entityField"
                  id="idColumn"
                  defaultValue={StringUtils.defaultString(
                    this.state.propertyValue.idColumn
                  )}
                  onChange={this.onChange}
                  entityId={this.state.propertyValue.dataModelEntityId}
                  options={{
                    matchId: "id",
                    matchNm: "text",
                    beforeChkFn: () => {
                      if (
                        StringUtils.isEmpty(
                          this.state.propertyValue.dataModelEntityId
                        )
                      ) {
                        return false;
                      }
                      return true;
                    },
                  }}
                />
              </PropertyValue>
              <PropertyLable>Text Column</PropertyLable>
              <PropertyValue>
                <USelectbox
                  type="entityField"
                  id="textColumn"
                  defaultValue={StringUtils.defaultString(
                    this.state.propertyValue.textColumn
                  )}
                  onChange={this.onChange}
                  entityId={this.state.propertyValue.dataModelEntityId}
                  options={{
                    matchId: "id",
                    matchNm: "text",
                    beforeChkFn: () => {
                      if (
                        StringUtils.isEmpty(
                          this.state.propertyValue.dataModelEntityId
                        )
                      ) {
                        return false;
                      }
                      return true;
                    },
                  }}
                />
              </PropertyValue>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey={2}>
            <Accordion.Header>Other Options</Accordion.Header>
            <Accordion.Body>
              {/* <PropertyLable className="w-33p">체크박스 표시</PropertyLable> */}
              <PropertyLable className="w-33p">Disable</PropertyLable>
              <PropertyLable className="w-33p">Draggable</PropertyLable>
              <PropertyLable className="w-33p">Auto Collapse</PropertyLable>
              {/* <PropertyLable className="w-33p">조회 표시</PropertyLable> */}
              {/* <PropertyValue className="w-33p">
                <BootstrapSwitchButton
                  id="checkBoxFlag"
                  checked={StringUtils.defaultString(
                    this.state.propertyValue.checkBoxFlag
                  )}
                  size="sm"
                  onstyle="primary"
                  offstyle="dark"
                  onlabel="Yes"
                  offlabel="No"
                  onChange={(value) => this.btnChange("checkBoxFlag", value)}
                />
              </PropertyValue> */}
              <PropertyValue className="w-33p">
                <BootstrapSwitchButton
                  id="readOnlyFlag"
                  checked={StringUtils.defaultString(
                    this.state.propertyValue.readOnlyFlag
                  )}
                  size="sm"
                  onstyle="primary"
                  offstyle="dark"
                  onlabel="Yes"
                  offlabel="No"
                  onChange={(value) => this.btnChange("readOnlyFlag", value)}
                />
              </PropertyValue>
              <PropertyValue className="w-33p">
                <BootstrapSwitchButton
                  id="dragFlag"
                  checked={StringUtils.defaultString(
                    this.state.propertyValue.dragFlag
                  )}
                  size="sm"
                  onstyle="primary"
                  offstyle="dark"
                  onlabel="Yes"
                  offlabel="No"
                  onChange={(value) => this.btnChange("dragFlag", value)}
                />
              </PropertyValue>
              <PropertyValue className="w-33p">
                <BootstrapSwitchButton
                  id="autoCollapse"
                  checked={StringUtils.defaultString(
                    this.state.propertyValue.autoCollapse
                  )}
                  size="sm"
                  onstyle="primary"
                  offstyle="dark"
                  onlabel="Yes"
                  offlabel="No"
                  onChange={(value) => this.btnChange("autoCollapse", value)}
                />
              </PropertyValue>
              <PropertyLable className="w-33p">Data Initial Load</PropertyLable>
              <PropertyLable className="w-33p"></PropertyLable>
              <PropertyLable className="w-33p"></PropertyLable>
              <PropertyValue className="w-33p">
                <BootstrapSwitchButton
                  id="dataInitialLoad"
                  checked={StringUtils.defaultString(
                    this.state.propertyValue.dataInitialLoad
                  )}
                  size="sm"
                  onstyle="primary"
                  offstyle="dark"
                  onlabel="Yes"
                  offlabel="No"
                  onChange={(value) => this.btnChange("dataInitialLoad", value)}
                />
              </PropertyValue>
              <PropertyLable className="w-33p"></PropertyLable>
              <PropertyLable className="w-33p"></PropertyLable>
              {/* <PropertyValue className="w-33p">
                <BootstrapSwitchButton
                  id="searchFlag"
                  checked={StringUtils.defaultString(
                    this.state.propertyValue.searchFlag
                  )}
                  size="sm"
                  onstyle="primary"
                  offstyle="dark"
                  onlabel="Yes"
                  offlabel="No"
                  onChange={(value) => this.btnChange("searchFlag", value)}
                />
              </PropertyValue> */}
            </Accordion.Body>
          </Accordion.Item>
          <AccordionItem eventKey={4}>
            <AccordionHeader>Event</AccordionHeader>
            <AccordionBody>
              <UElementList
                isDisplay={true}
                bodyStyle={{ maxHeight: "225px", minHeight: "38px" }}
                id="treeEvent"
                data={treeOptions.treeEvent}
                onBlur={(pId, pValue) => {
                  this.onChangeIdValue(pId, pValue);
                }}
                cols={[
                  {
                    label: "Event",
                    type: "select",
                    className: "w-40p",
                    id: "eventType",
                    requried: true,
                    onChange: (changeItem, changeValue) => {
                      let ent = this.props.componentInfo.componentEvent;
                      ent = ent.filter(
                        (e) => e["eventHandleCd"] === changeValue
                      )[0];
                      if (ent.allowMultipleYn === "N") {
                        if (
                          !ObjectUtils.isEmpty(treeOptions.treeEvent) &&
                          treeOptions.treeEvent.filter(
                            (e) => changeValue === e.eventType
                          ).length > 0
                        ) {
                          Message.alert(
                            "Event's [" +
                              ent.eventHandleNm +
                              "]is Single Input Field",
                            Enums.MessageType.ERROR
                          );
                          return { ...changeItem, eventType: "" };
                        }
                      }

                      if (changeValue.includes("TREE_DRAG")) {
                        if (this.state.propertyValue.dragFlag === false) {
                          Message.alert(
                            "This event only can be selected when Draggable is 'Yes'",
                            Enums.MessageType.ERROR
                          );
                          return { ...changeItem, eventType: "" };
                        }
                      }

                      /**
                       *  - TREE_LOAD - Target이 불필요함( None으로 표시) | TREE_LOAD
                       *  - Tree 클릭 (팝업) - Target이 불필요함( None으로 표시) | TREE_CLICK_P
                       *  - Tree 클릭 (사용자 정의) - Target이 불필요함( None으로 표시) | TREE_CLICK_C
                       *  - Tree dnd - Target이 불필요함( None으로 표시) | TREE_DRAG_S,O,E
                       */
                      if (
                        StringUtils.equalsIgnoreCase(changeValue, "TREE_LOAD")
                      ) {
                        changeItem.target = "tree";
                      } else if (
                        StringUtils.equalsIgnoreCase(
                          changeValue,
                          "TREE_CLICK_P"
                        ) ||
                        StringUtils.equalsIgnoreCase(
                          changeValue,
                          "TREE_CLICK_C"
                        ) ||
                        changeValue.includes("TREE_DRAG")
                      ) {
                        changeItem.target = "none";
                      }

                      return changeItem;
                    },
                    settings: {
                      type: "static",
                      items: this.props.componentInfo.componentEvent,
                      options: {
                        matchId: "eventHandleCd",
                        matchNm: "eventHandleNm",
                      },
                    },
                  },
                  {
                    label: "Target",
                    type: "select",
                    className: "w-40p",
                    id: "target",
                    requried: true,
                    readonly: (item, index) => {
                      return (
                        StringUtils.isEmpty(item.eventType) ||
                        [
                          "TREE_LOAD",
                          "TREE_CLICK_P",
                          "TREE_CLICK_C",
                          "TREE_DRAG_S",
                          "TREE_DRAG_O",
                          "TREE_DRAG_D",
                        ].indexOf(item.eventType) !== -1
                      );
                    },
                    onChange: (rowData, colData, comboItem) => {
                      rowData.type = comboItem.type;
                      return rowData;
                    },
                    settings: {
                      type: "static",
                      items: this.state.targets,
                      options: {
                        matchId: "id",
                        matchNm: "text",
                        filter: (
                          codeItem,
                          codeIndex,
                          selectProps,
                          allItems
                        ) => {
                          let idArr = selectProps.id.split("_");
                          let index = idArr[idArr.length - 1];
                          let rowData = ArrayUtils.isEmpty(
                            treeOptions.treeEvent
                          )
                            ? {}
                            : treeOptions.treeEvent[index];
                          let eventType = rowData.eventType;

                          if (
                            [
                              "TREE_LOAD",
                              "TREE_CLICK_B",
                              "TREE_CLICK_Q",
                            ].indexOf(eventType) !== -1
                          ) {
                            return !(
                              codeItem.type === "tree" &&
                              codeItem.id === treeOptions.treeId
                            );
                          } else if (eventType === "TREE_CLICK_P") {
                          }
                          return true;
                        },
                      },
                    },
                  },
                  {
                    label: "Details",
                    type: "button",
                    id: "btnInfo",
                    icon: BiCommentDetail,
                    className: "w-10p",
                    isShowIcon: true,
                    isShowLabel: false,
                    settings: {
                      readonlyTp: "input",
                      onClick: (e) => this.openPopupEvent(e),
                    },
                  },
                ]}
              />
            </AccordionBody>
          </AccordionItem>

          {/* Style property */}
          {this.renderStylePanel("Tree", 9)}
        </Accordion>
      </React.Fragment>
    );
  };

  onChangeEditor = (e) => {
    this.onChangePropertyValue("text", e.target.innerHTML);
  };

  TransitionComponent = (props) => {
    const style = useSpring({
      from: {
        opacity: 0,
        transform: "translate3d(20px,0,0)",
      },
      to: {
        opacity: props.in ? 1 : 0,
        transform: `translate3d(${props.in ? 0 : 20}px,0,0)`,
      },
    });
    return (
      <animated.div style={style}>
        <Collapse {...props} />
      </animated.div>
    );
  };

  /**
   * Editor의 component를 Redering
   * <<Component editor props>>
   *   - compId - 현재 component의 고유 ID
   *   - componentInfo - drag & drop시 생성된 component object
   *   - isFocus - 현재 해당 component에 foucs 되어있는지 여부 (선택되어졌는지 여부)
   *   - onClick - component Click시 호출되는 event 함수
   *   - event="renderEditor" - 요청 구분
   * @returns
   */
  renderEditor = () => {
    const StyledTreeItem = styled((props) => (
      <TreeItem {...props} TransitionComponent={this.TransitionComponent} />
    ))(({ theme }) => ({
      [`& .${treeItemClasses.iconContainer}`]: {
        "& .close": {
          opacity: 0.3,
        },
      },
      [`& .${treeItemClasses.group}`]: {
        marginLeft: 15,
        paddingLeft: 18,
        borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
      },
    }));
    return (
      <UIComponentSection
        item={this.props.componentInfo}
        style={{ ...this.props.style, width: "100%" }}
        title="Tree"
        className={`editor-form editor-base draggable component-hover ${StringUtils.defaultString(
          this.state.editorAttr.className
        )}`}
      >
        <TreeView
          aria-label="customized"
          defaultExpanded={["1"]}
          defaultCollapseIcon={<FiMinusSquare />}
          defaultExpandIcon={<FiPlusSquare />}
          sx={{ height: 264, flexGrow: 1, overflowY: "auto" }}
        >
          <StyledTreeItem nodeId="1" label="Main">
            <StyledTreeItem nodeId="2" label="Hello" />
            <StyledTreeItem nodeId="3" label="Subtree with children">
              <StyledTreeItem nodeId="6" label="Hello" />
              <StyledTreeItem nodeId="7" label="Sub-subtree with children">
                <StyledTreeItem nodeId="9" label="Child 1" />
                <StyledTreeItem nodeId="10" label="Child 2" />
                <StyledTreeItem nodeId="11" label="Child 3" />
              </StyledTreeItem>
              <StyledTreeItem nodeId="8" label="Hello" />
            </StyledTreeItem>
            <StyledTreeItem nodeId="4" label="World" />
            <StyledTreeItem nodeId="5" label="Something something" />
          </StyledTreeItem>
        </TreeView>
      </UIComponentSection>
    );
  };
}
export default Tree;
