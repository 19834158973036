import Api from "components/common/Api";

class SettingService {
  static saveStyle(data, callbackFnc) {
    Api.post("/common/saveStyle", data, callbackFnc);
  }

  static getStyleList(data, callbackFnc) {
    Api.post("/common/getStyleList", data, callbackFnc);
  }
  static deleteStyle(data, callbackFnc) {
    Api.post("/common/deleteStyle", data, callbackFnc);
  }
  static getStyle(data, callbackFnc) {
    Api.post("/common/getStyle", data, callbackFnc);
  }
  static saveUserConfig(data, callbackFnc) {
    Api.post("/common/saveUserConfig", data, callbackFnc);
  }
  static getUserConfig(data, callbackFnc) {
    Api.post("/common/getUserConfig", data, callbackFnc);
  }
  static deleteUserConfig(data, callbackFnc) {
    Api.post("/common/deleteUserConfig", data, callbackFnc);
  }
  static getDefaultStyle(data, callbackFnc) {
    Api.post("/common/getDefaultStyle", data, callbackFnc);
  }

  static getBuidlerDefaultStyle(data, callbackFnc) {
    Api.post("/common/getBuidlerDefaultStyle", data, callbackFnc);
  }

  /**
   * 앱별 전역 변수 목록 설정 저장
   * @param {*} data
   * @param {*} callbackFnc
   * @param {*} errCallback
   */
  static saveVariableList(data, callbackFnc, errCallback) {
    Api.post("/common/saveVariableList", data, callbackFnc, errCallback);
  }

  /**
   * 앱별 전역 변수 설정 저장
   * @param {*} data
   * @param {*} callbackFnc
   * @param {*} errCallback
   */
  static saveVariable(data, callbackFnc, errCallback) {
    Api.post("/common/saveVariable", data, callbackFnc, errCallback);
  }

  /**
   * 앱별 전역 변수 목록 호출
   * @param {*} data
   * @param {*} callbackFnc
   * @param {*} errCallback
   */
  static getVariableList(data, callbackFnc, errCallback) {
    Api.post("/common/getVariableList", data, callbackFnc, errCallback);
  }

  /**
   * 전역변수 삭제
   * @param {*} data
   * @param {*} callbackFnc
   * @param {*} errCallback
   */
  static deleteVariable(data, callbackFnc, errCallback) {
    Api.post("/common/deleteVariable", data, callbackFnc, errCallback);
  }
}
export default SettingService;
