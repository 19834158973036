import React, { createRef } from "react";

import * as Enums from "components/builder/BuilderEnum";
import UITemplateHelper from "components/builder/ui/editor/helper/UITemplateHelper";
import { TemplateCheckbox } from "components/builder/ui/uiComponents/UIComponentStyle";
import LayoutTemplate from "components/builder/ui/uiComponents/template/LayoutTemplate";
import Message from "components/common/Message";
import Popup from "components/common/Popup";
import Modal from "components/common/modal/UModal";
import {
  ArrayUtils,
  JsonUtils,
  ObjectUtils,
  StringUtils,
} from "components/common/utils/CommonUtils";
import DataModelService from "services/datamodel/DataModelService";

import USelectbox from "components/common/element/USelectbox";
import { Button, Form, InputGroup, Overlay, Popover } from "react-bootstrap";
import { FaAngleRight, FaMinus } from "react-icons/fa";

import { SelectionMode } from "@grapecity/wijmo.grid";
import WijmoGrid from "components/common/element/WijmoGrid";
import NamingUtils from "components/common/utils/NamingUtils";
import Field from "entity/Field.entity";
import TrdService from "services/trd/TrdService";
import { camelCase } from "lodash";

class GridLayoutTemplate extends LayoutTemplate {
  constructor(props) {
    super(props);
    this.state = {
      inputValue: {
        gridId: "",
        dataModelId: this.props.dataModelId || "",
        serviceUid: this.props.serviceUid || null,
        headerTitle: this.defaultValues.headerTitle || false,
        gridDisplayMode: "E",
        containButtons: !ArrayUtils.isEmpty(this.defaultValues.buttons),
        dataModelEntityId: this.props.dataModelEntityId || "",
        entityVariable: this.props.entityVariable || "",
        isDataStudioElement: this.props.isDataStudioElement || false,
        dataModelInfo: null,
      },
      fieldCol: [
        {
          field: "name",
          headerName: "Header ID",
          width: 115,
          headerAlign: "center",
        },
        {
          field: "title",
          headerName: "Title",
          width: 110,
          headerAlign: "center",
        },
        {
          field: "dataType",
          headerName: "Type",
          width: "*",
          headerAlign: "center",
        },
        // { field: "compId", headerName: "compId", hide: true },
      ],
      gridCol: [
        {
          field: "remove",
          headerName: "Delete",
          width: 50,
          sortable: false,
          headerAlign: "center",
          align: "center",
          renderCell: (params) => {
            return (
              <Button
                size="sm"
                onClick={(e) => this.onDelete(e, params)}
                className="btn btn-outline-secondary btn-sm btn-remove"
                style={{
                  padding: "0px 3px 0px 3px",
                }}
              >
                <FaMinus />
              </Button>
            );
          },
        },
        {
          field: "name",
          headerName: "Header ID",
          width: 115,
          sortable: false,
          headerAlign: "center",
        },
        {
          field: "title",
          headerName: "Title",
          width: 110,
          sortable: false,
          headerAlign: "center",
        },
        {
          field: "dataType",
          headerName: "Type",
          width: "*",
          sortable: false,
          headerAlign: "center",
        },
        // { field: "compId", headerName: "compId", hide: true },
      ],
      checkedField: [],
      fieldList: [],
      gridCells: [],
    };

    this.createLayout = this.createLayout.bind(this);
    this.onChange = this.onChange.bind(this);
    this.getfieldList = this.getfieldList.bind(this);
    this.getWorkflowFieldList = this.getWorkflowFieldList.bind(this);
    this.getfieldListWithElement = this.getfieldListWithElement.bind(this);
    this.onDelete = (e, params) => {
      let compId = params.compId;
      let gridCells = [...this.state.gridCells];
      if (!ArrayUtils.isEmpty(gridCells)) {
        let sameIndex;
        gridCells.map((item, index) => {
          if (compId === item.compId) {
            sameIndex = index;
          }
        });

        if (!ObjectUtils.isEmpty(sameIndex)) {
          gridCells.splice(sameIndex, 1);
          this.setState({ gridCells: gridCells });
        }
      }
    };
    this.onChangeDisplayMode = this.onChangeDisplayMode.bind(this);
    this.onClickGridArrowButton = this.onClickGridArrowButton.bind(this);
    this.renderFieldGrid = this.renderFieldGrid.bind(this);
    this.buttonCheckBox = createRef();
  }
  popoverRef = React.createRef();

  async componentDidMount() {
    let propsState = { ...this.state };
    if (StringUtils.isEmpty(this.state.inputValue.gridId)) {
      let newGridId = NamingUtils.getComponentId(
        Enums.ComponentType.GRID,
        this.output.page,
        {},
        "Grid"
      );

      let newInputValue = { ...this.state.inputValue };
      newInputValue.gridId = newGridId;
      propsState.inputValue = newInputValue;
    }
    /**
     * Studio Trd 정보로 만든 Data Model에서 DND 된 그리드는 자동으로 필드 목록을 가져오도록 한다.
     */
    if (
      this.props.isDataStudioElement &&
      this.props.dataModelId &&
      this.props.entityId
    ) {
      propsState.inputValue.dataModelId = this.props.dataModelId;
      propsState.inputValue.dataModelEntityId = this.props.entityId;
      propsState.fieldList = await this.getfieldListWithElement(
        this.props.entityId
      );
    } else if (this.props.entityVariable) {
      propsState.fieldList = await this.getWorkflowFieldList(propsState);
      propsState.inputValue.serviceUid =
        this.props.componentInfo.editorAttr.serviceUid;
      propsState.inputValue.entityVariable =
        this.props.componentInfo.editorAttr.entityVariable;
      delete propsState.inputValue.dataModelEntityId;
    }

    this.setState(propsState);
  }
  /**
   * Validation
   * @returns
   */
  validation = () => {
    if (StringUtils.isEmpty(this.state.inputValue.gridId)) {
      Message.alert("Please enter Grid ID", Enums.MessageType.ERROR);
      return false;
    }
    //이미 동일한 Grid ID가 있는지 여부 체크

    if (
      !ObjectUtils.isEmpty(
        JsonUtils.findNode(this.output, "gridId", this.state.inputValue.gridId)
      )
    ) {
      Message.alert(
        "The same Grid ID already exists.",
        Enums.MessageType.ERROR
      );
      return false;
    }
    return true;
  };

  /**
   * on Change
   * @param {Event} event
   */
  onChange = async (event) => {
    const fieldId = StringUtils.defaultString(
      event.target.name,
      event.target.id
    );
    const newState = {
      ...this.state,
      inputValue: { ...this.state.inputValue, [fieldId]: event.target.value },
    };

    if (StringUtils.equalsIgnoreCase("dataModelEntityId", fieldId)) {
      newState.inputValue.entityType = event.target._data.entityType || "TABLE";
      if (
        !ObjectUtils.isEmpty(event.target._data) &&
        (StringUtils.equalsIgnoreCase(
          event.target._data.entityType,
          "PROCEDURE"
        ) ||
          StringUtils.equalsIgnoreCase(
            event.target._data.entityType,
            "FUNCTION"
          ))
      ) {
        // 파라미터 목록 정의
        const insertOption = {};
        insertOption.arguments = JSON.parse(
          event.target._data.parameterList
        ).map((_param) => {
          let type =
            _param.parameterType.indexOf("int") > -1 ||
            _param.parameterType.indexOf("number") > -1 ||
            _param.parameterType.indexOf("long") > -1 ||
            _param.parameterType.indexOf("double") > -1 ||
            _param.parameterType.indexOf("float") > -1
              ? "INTEGER"
              : "STRING";
          return {
            mode: "IN",
            name: _param.parameterName,
            type: type,
          };
        });
        // 엔티티가 프로시져 타입일때 파라미터 이름 정의
        insertOption.procedureName = event.target._data.tableNm;
        newState.inputValue.insertOption = insertOption;
      } else {
        delete newState.inputValue.insertOption;
        delete newState.inputValue.procedureName;
      }
      const fieldList = await this.getfieldList(event.target.value);
      newState.fieldList = fieldList;
    }
    this.setState(newState);
  };

  /**
   * Create Layout
   * @param {Event} event
   * @returns
   */
  createLayout = (event) => {
    /* dataModel validation */
    if (!this.validation()) return false;

    //grid
    let newGrid = UITemplateHelper.getGridTemplate(this.templateComponents);

    // let gridColumOption = { ...newGrid.propertyValue.gridColumns };
    delete newGrid.propertyValue.gridColumns; //default column option 삭제
    newGrid.propertyValue.gridOptions.gridId = this.state.inputValue.gridId;
    newGrid.propertyValue.gridOptions.dataModelEntityId =
      this.state.inputValue.dataModelEntityId;
    newGrid.propertyValue.gridOptions.dataModelInfo =
      this.state.inputValue.dataModelInfo;

    //엔티티타입, 파라미터 리스트 추가
    if (this.state.inputValue.entityType)
      newGrid.propertyValue.gridOptions.entityType =
        this.state.inputValue.entityType;
    if (this.state.inputValue.procedureName)
      newGrid.propertyValue.gridOptions.procedureName =
        this.state.inputValue.procedureName;
    if (this.state.inputValue.insertOption)
      newGrid.propertyValue.gridOptions.insertOption =
        this.state.inputValue.insertOption;

    //Grid 유형
    newGrid.propertyValue.gridOptions.displayMode =
      this.state.inputValue.gridDisplayMode;

    //title 추가
    if (this.state.inputValue.headerTitle === true) {
      newGrid.propertyValue.gridOptions.title = "Grid Title";
    }
    //버튼 영역포함
    let defaultAdditionalOptions =
      newGrid.propertyValue.gridOptions.additionalOptions || {};

    defaultAdditionalOptions.toolbarOptions = {
      ...(defaultAdditionalOptions.toolbarOptions || {}),
      ...{
        buttons: this.state.inputValue.containButtons
          ? this.defaultValues.buttons
          : [],
      },
    };
    newGrid.propertyValue.gridOptions.additionalOptions =
      defaultAdditionalOptions;

    // let _this = this;
    let gridCells = [...this.state.gridCells];
    if (ArrayUtils.isEmpty(gridCells)) {
      const compId = StringUtils.getUuid();
      let newGrid = UITemplateHelper.getGridTemplate(this.templateComponents);
      let gridColumOption = { ...newGrid.propertyValue.gridColumns };
      if (!gridColumOption.compId) gridColumOption.compId = compId;
      if (!gridColumOption.name) gridColumOption.name = "New-" + compId;
      if (!gridColumOption.gridId)
        gridColumOption.gridId = this.state.inputValue.gridId;
      if (!gridColumOption.componentClass)
        gridColumOption.componentClass = "grid/GridHeader";
      gridCells = new Array(gridColumOption);
    } else {
      gridCells = gridCells.map((item, index) => {
        item.gridId = this.state.inputValue.gridId;
        return item;
      });
    }
    newGrid.propertyValue.gridOptions.columns = gridCells;

    //isDataStudioElement 추가
    newGrid.propertyValue.gridOptions.isDataStudioElement =
      this.props.isDataStudioElement;
    //TrdTableMstId 추가
    newGrid.propertyValue.gridOptions.tableMstId = this.props.tableMstId;

    /**
     * 더블클릭하여 Workflow를 이용한 그리드를 수정하는 경우,
     * service 추적을 위해서 serviceUid와 entityVariable를 가져감
     */
    newGrid.editorAttr.serviceUid = this.state.inputValue.serviceUid;
    newGrid.editorAttr.entityVariable = this.state.inputValue.entityVariable;

    this.callbackFnc.call(this, newGrid);
    Popup.close();
  };

  handleClick = (event) => {
    this.setState({
      popoverShow: !this.state.popoverShow,
      popoverTarget: event.target,
    });
  };

  /**
   * 데이터 모델 ENTITY에 따라 필드 정보를 불러온다.
   * @param {*} entityId
   * @return {Promise}
   */
  getfieldList = (entityId) => {
    return new Promise((resolve, reject) => {
      if (!StringUtils.isEmpty(entityId)) {
        let newGrid = UITemplateHelper.getGridTemplate(this.templateComponents);
        let gridColumOption = { ...newGrid.propertyValue.gridColumns };
        let _this = this;
        DataModelService.getDataModelEntityFieldList({ entityId }, (result) => {
          if (ArrayUtils.isEmpty(result.data)) {
            Message.alert(
              "The selected Data Entity does not have any column information.",
              Enums.MessageType.ERROR
            );
            this.setState({
              fieldList: [],
              gridCells: [],
            });
          } else {
            let gridCells = [];
            /* grid column  구성 */
            result.data.map((column, index) => {
              let gridCell = {
                ...gridColumOption,
              };
              gridCell.compId = StringUtils.getUuid();
              gridCell.gridId = _this.state.inputValue.gridId;
              gridCell.name = column.physFieldNm;
              gridCell.data = column.physFieldNm;
              gridCell.title = column.logFieldNm;
              gridCell.dataType = column.uiDisplayType;
              gridCell.editType = column.readonlyYn === "Y" ? "E" : "R";
              gridCell.required = column.requiredYn === "Y" ? true : false;
              gridCell.sortOrder = index + 1;
              gridCells.push(gridCell);
            });
            resolve(gridCells);
          }
        });
      } else {
        resolve([]);
      }
    });
  };

  /**
   * DataModel Field와 Element 정보를 가져옴
   * @param {*} entityId
   * @returns {Promise}
   */
  getfieldListWithElement = (entityId) => {
    const tableMstId = this.props.tableMstId;
    const entityElementList = this.props.entityElementList;

    let newGrid = UITemplateHelper.getGridTemplate(this.templateComponents);
    let gridColumOption = { ...newGrid.propertyValue.gridColumns };
    let _this = this;
    return new Promise((resolve, reject) => {
      DataModelService.getDataModelEntityFieldList({ entityId }, (result) => {
        if (ArrayUtils.isEmpty(result.data)) {
          Message.alert(
            "선택하신 Data Entity에 Column 정보가 존재 하지 않습니다.",
            Enums.MessageType.ERROR
          );
          resolve([]);
        } else {
          let gridCells = [];
          /* grid column  구성 */
          result.data.map((column, index) => {
            let gridCell = {
              ...gridColumOption,
            };
            const trdTableField = entityElementList.find((field) =>
              StringUtils.equalsIgnoreCase(
                field.element.elementCd,
                column.physFieldNm
              )
            );
            const field = new Field({
              entityField: column,
              trdTableField: trdTableField,
            });

            gridCell.compId = StringUtils.getUuid();
            gridCell.gridId = _this.state.inputValue.gridId;
            gridCell.name = field.getPhysNm();
            gridCell.data = field.getPhysNm();
            gridCell.title = field.getLogNm();

            gridCell.editType = field.getReadonlyYn() === "Y" ? "E" : "R";
            gridCell.required = field.getRequiredYn() === "Y" ? true : false;
            gridCell.sortOrder = index + 1;
            gridCell.dataType = "text";
            /**
             * Z_CODE_MST Code Z0011
             * DataType 규칙
             * 1. Domain을 사용하고 number 타입인 경우 numeric
             * 2. Domain을 사용하고 number 타입 + 포맷 금액일 경우 numeric(0)
             * 3. Domain을 사용하고 number 타입 + 포맷 수량일 경우 numeric(1)
             * 4. Domain을 사용하고 number 타입 + 포맷 단가일 경우 numeric(2)
             * 5. Domain을 사용하고 number 타입 + 포맷 환율일 경우 numeric(3)
             *   - 위의 포맷의 경우 런타임에서 처리한다. gridOption에 elementId를 같이 넣어 둔다.
             * 6. possibleEntry가 있는 경우 Select 콤보(일반)
             * 7. Date 타입인 경우 datetime
             * 8. 나머지 text
             * */
            //Data Type 변환
            this.convertDataTypeToDisplayType(gridCell, field);
            // select(combo)일때 data ref or static data setting
            if (gridCell.dataType === "select") {
              this.convertSelectDataInfo(gridCell, field);
            }

            //elementId 넣어두기
            gridCell.elementId = field.getElement().getElementId();
            if (field.trdTableField && field.trdTableField.refTableId) {
              gridCell.refTableId = field.trdTableField.refTableId;
              gridCell.refFieldId = field.trdTableField.refFieldId;
            }
            gridCell.isDataStudioElement = true;
            gridCells.push(gridCell);
          });
          resolve(gridCells);
        }
      });
    });
  };

  /**
   * Entity Field 또는 Element 의 DataType을 grid DataType에 맞게 변환하는 로직
   * @param {*} gridCell
   * @param {Field} field
   */
  convertDataTypeToDisplayType = (gridCell, field) => {
    const dataCase = Enums.getDataCase(field.getDomain().getDataType());
    if (
      StringUtils.equalsIgnoreCase(field.getDomain().getEntryDisType(), "C")
    ) {
      gridCell.dataType = "select";
    } else if (
      StringUtils.includesIgnoreCase(dataCase, [
        Enums.CaseType.Number,
        Enums.CaseType.Decimal,
      ])
    ) {
      if (
        StringUtils.includes(field.getDomain().getFormType(), [
          "0", //금액
          "1", //수량
          "2", //단가
          "3", //환율
        ])
      ) {
        gridCell.dataType = `numeric(${field.getDomain().getFormType()})`; // 소수점 있는 숫자 타입의 경우
        gridCell.integerPart =
          parseInt(field.getDomain().getDataLength()) -
          parseInt(field.getDomain().getDecimals());
        gridCell.decimalPart = parseInt(field.getDomain().getDecimals());
      } else {
        gridCell.dataType = "number"; // integer 같은 소수점 없는 숫자의 경우
      }
      gridCell.align = "right";
    } else if (StringUtils.equalsIgnoreCase(dataCase, Enums.CaseType.Date)) {
      if (StringUtils.equalsIgnoreCase(dataCase, "timestamp")) {
        gridCell.dataType = "datetime";
      } else if (StringUtils.equalsIgnoreCase(dataCase, "date")) {
        gridCell.dataType = "date";
      } else {
        gridCell.dataType = "datetime";
      }
    }
  };

  /**
   * Element나 Domain의 PossibleEntry와 Table Ref Info를 grid cell 에 맞는 데이터로 변환하는 로직(Element 우선)
   * @param {*} gridCell
   * @param {Field} field
   */
  convertSelectDataInfo = (gridCell, field) => {
    const entryType =
      field.getElement().getEntryType() ||
      field.getElement().getDomain().getEntryType();
    if (entryType === "entry") {
      gridCell.searchTp = "STATIC";
      const comboMapping = (possibleEntryList) => {
        const data = possibleEntryList.map((item) => {
          return { code: item.entryValue, text: item.entryText };
        });
        return !ArrayUtils.isEmpty(data) && data;
      };
      const elementPEL = comboMapping(
        field.getElement().getPossibleEntryList()
      );
      const domainPEL = comboMapping(
        field.getElement().getDomain().getPossibleEntryList()
      );
      gridCell.comboItems = elementPEL || domainPEL;
    } else if (entryType === "table") {
      gridCell.searchTp = "REF";
      gridCell.entryRefTable =
        field.getElement().getEntryRefTable() ||
        field.getElement().getDomain().getEntryRefTable();
      gridCell.entryRefWhere =
        field.getElement().getEntryRefWhere() ||
        field.getElement().getDomain().getEntryRefWhere();
      gridCell.idColumn =
        field.getElement().getEntryRefKey() ||
        field.getElement().getDomain().getEntryRefKey();
      gridCell.textColumn =
        field.getElement().getEntryRefValue() ||
        field.getElement().getDomain().getEntryRefValue();
    }
  };

  /**
   * Datamodel field 및 Element와 Columns값을 셋팅하는 함수
   * @param {*} propsState
   * @returns
   */

  getWorkflowFieldList = (propsState) => {
    // trd service 에서 refFieldList를 불러온다.
    let newGrid = UITemplateHelper.getGridTemplate(this.templateComponents);
    let gridColumOption = { ...newGrid.propertyValue.gridColumns };
    const refFieldIds = this.props.workflowEntityFieldList.map((item) => {
      if (!StringUtils.isEmpty(item.refFieldId)) {
        return item.refFieldId;
      }
    });

    return new Promise((resolve, reject) => {
      TrdService.getTableFieldList({
        tableFieldIdList: refFieldIds || [],
        isWorkflow: true,
      }).then((res) => {
        if (res.isError) {
          console.log("Reference Column is not found! is Error!");
          return resolve([]);
        } else {
          let refFieldList = [];
          refFieldList = res.data;

          const convertFields = this.props.workflowEntityFieldList.map(
            (field, index) => {
              let gridCell = {
                ...gridColumOption,
              };
              const newField = new Field({
                entityField: field,
                element: field.element,
              });
              gridCell.compId = StringUtils.getUuid();
              gridCell.gridId = propsState.inputValue.gridId;
              gridCell.name = field.fieldId;
              gridCell.data = field.fieldId;
              gridCell.title = field.element?.elementNm || field.fieldId;
              gridCell.dataType = "text";
              this.convertDataTypeToDisplayType(gridCell, newField);
              gridCell.editType = field.option.updatableYn === "Y" ? "E" : "R";
              gridCell.required =
                field.option.requiredYn === "Y" ? true : false;
              gridCell.sortOrder = index + 1;
              gridCell.elementId = field.element?.elementId;
              if (field && field.refTableId) {
                gridCell.refTableId = field.refTableId;
                gridCell.refFieldId = field.refFieldId;
              }
              const refField = refFieldList.find((item) =>
                StringUtils.equalsIgnoreCase(item.fieldId, field.refFieldId)
              );
              if (!ObjectUtils.isEmpty(refField) && field.refFieldId) {
                gridCell.refFieldTableNm = refField.trdTableMst.tablePhysicalNm;
                gridCell.refFieldNm = refField.element.elementCd;
                gridCell.currencyCodeColumn = camelCase(
                  refField.element.elementCd
                );
              }

              if (gridCell.dataType === "select") {
                this.convertSelectDataInfo(gridCell, newField);
              }

              return gridCell;
            }
          );
          return resolve(convertFields);
        }
      });
    });
  };

  /**
   * Grid 유형이 변경되었을 때
   * @param {*} e
   */
  onChangeDisplayMode = (e) => {
    // 조회용 Grid 일 때
    if (e.target.value === "D") {
      this.buttonCheckBox.current.checked = false;
      this.setState({
        inputValue: {
          ...this.state.inputValue,
          containButtons: false,
          gridDisplayMode: e.target.value,
        },
      });
    }
    // 입력용 Grid 일 때
    else {
      this.buttonCheckBox.current.checked = true;
      this.setState({
        inputValue: {
          ...this.state.inputValue,
          containButtons: true,
          gridDisplayMode: e.target.value,
        },
      });
    }
  };

  /**
   * Field List와 선택된 Field List 사이의 버튼 이벤트
   * @param {*} e
   */
  onClickGridArrowButton = (e) => {
    let fields = [...this.state.fieldList];
    let cells = [...this.state.gridCells];
    let chkItem = [...this.state.checkedField];
    if (!ArrayUtils.isEmpty(chkItem) && !ObjectUtils.isEmpty(fields)) {
      const willAddField = fields.filter(
        (item) =>
          chkItem.indexOf(item.compId) > -1 &&
          cells.findIndex((c) => c.data === item.data) === -1
      );

      cells = cells.concat(willAddField);
      this.setState({
        fieldList: fields,
        gridCells: cells,
      });
    }
  };

  /**
   * 필드 선택하는 그리드 렌더링
   * @returns
   */
  renderFieldGrid = () => {
    if (
      !StringUtils.isEmpty(this.state.inputValue.dataModelEntityId) ||
      !StringUtils.isEmpty(this.state.inputValue.entityVariable)
    ) {
      return (
        <div>
          <div className="row p-10" style={{ height: "25px" }}>
            <div style={{ width: "47%" }}>
              <Form.Label htmlFor="dataModelEntityField">
                Data Entity Field List
              </Form.Label>
            </div>
            <div style={{ width: "6%" }}></div>
            <div style={{ width: "47%" }}>
              <Form.Label htmlFor="dataModelEntityField">
                적용할 Field List
              </Form.Label>
            </div>
          </div>
          <div className="row p-10">
            <div style={{ width: "47%" }}>
              <WijmoGrid
                getRowId={(row) => row.compId}
                rows={this.state.fieldList}
                columns={this.state.fieldCol}
                headerFilter={false}
                allowPinning="None"
                rowCount={this.state.fieldList.length}
                pageSize={100}
                headerHeight={30}
                rowHeight={30}
                selectMode={SelectionMode.RowRange}
                selectionModel={this.state.checkedField}
                headersVisibility="Column"
                scrollbarSize={500}
                style={{ height: "40vh" }}
                sx={{
                  fontSize: "13px",
                  userSelect: "none",
                  ".MuiDataGrid-root": {
                    height: "calc(100% - 20px)",
                  },
                  border: "1px solid rgba(224, 224, 224, 1)",
                }}
                hideFooter={true}
                isCheckBox={true}
                onClickCheckItem={(arr) => {
                  let selected = [];
                  arr.map((data) => {
                    selected = [...selected, data.compId];
                  });
                  this.setState({ checkedField: selected });
                }}
                checkingIfRowClick={true}
              />
            </div>
            <div style={{ width: "6%", marginTop: "100px" }}>
              <Button
                variant="outline-secondary"
                onClick={this.onClickGridArrowButton}
                size="sm"
                id="leftToRight"
              >
                <FaAngleRight></FaAngleRight>
              </Button>
              <br />
            </div>
            <div style={{ width: "47%" }}>
              <WijmoGrid
                getRowId={(row) => row.compId}
                rows={this.state.gridCells}
                columns={this.state.gridCol}
                headerFilter={false}
                allowPinning="None"
                emptyMessage="Please add Field"
                rowCount={this.state.gridCells.length}
                pageSize={100}
                // selectMode={SelectionMode.Row}
                selectMode={SelectionMode.None}
                headersVisibility="Column"
                style={{ height: "40vh" }}
                headerHeight={30}
                rowHeight={30}
                scrollbarSize={500}
                sx={{
                  fontSize: "13px",
                  userSelect: "none",
                  ".MuiDataGrid-root": {
                    height: "calc(100% - 20px)",
                  },
                  border: "1px solid rgba(224, 224, 224, 1)",
                }}
                hideFooter={true}
                // style={{ height: "calc(100% - 20px)" }}
              />
            </div>
          </div>
        </div>
      );
    } else {
      return <></>;
    }
  };

  /**
   * render column config
   * @returns
   */
  renderSelectDataModel = () => {
    return (
      <React.Fragment>
        <div className="row p-10">
          <div className="col-12">
            <Form.Label htmlFor="dataModelEntityId">Grid ID</Form.Label>
            <input
              type="text"
              id="gridId"
              defaultValue={this.state.inputValue.gridId}
              className="form-control form-control-sm"
              onBlur={this.onChange}
            />
          </div>
        </div>
        {/* Studio Element가 아닐때만 나온다. */}
        {!this.props.isDataStudioElement && (
          <div className="row p-10">
            <div className="col-12">
              <Form.Label htmlFor="dataModelEntityId">Data Entity</Form.Label>
              <InputGroup>
                <USelectbox
                  type="search"
                  id="dataModelEntityId"
                  defaultValue={StringUtils.defaultString(
                    this.state.inputValue.dataModelEntityId
                  )}
                  onChange={(e) => {
                    this.onChange(e);
                  }}
                  url="/datamodel/getDataModelEntityList"
                  params={{
                    dataModelId: this.state.inputValue.dataModelId,
                  }}
                  options={{
                    matchId: "id",
                    matchNm: "text",
                  }}
                />
                <div ref={this.popoverRef}>
                  <Button
                    variant="outline-secondary"
                    onClick={this.handleClick}
                    size="sm"
                  >
                    Help
                  </Button>

                  <Overlay
                    show={this.state.popoverShow}
                    target={this.state.popoverTarget}
                    placement="top"
                    container={this.popoverRef}
                    containerPadding={20}
                  >
                    <Popover id="popover-contained">
                      <Popover.Header as="h3">
                        How to load Data Model
                      </Popover.Header>
                      <Popover.Body>
                        ▣ Select Data Model on Page Properties.
                      </Popover.Body>
                    </Popover>
                  </Overlay>
                </div>
              </InputGroup>
            </div>
          </div>
        )}
        {this.renderFieldGrid()}
      </React.Fragment>
    );
  };

  /**
   * Title, button 등이 포함된 Grid layout을 생성
   * @returns
   */
  renderLayoutTemplatePop = () => {
    return (
      <Modal>
        <Modal.Header title="Grid Layout Settings" />
        <Modal.Body>
          {this.renderSelectDataModel()}
          <div className="row p-10">
            <div className="col2">
              <Form.Label>Grid Type</Form.Label>
              <InputGroup>
                <USelectbox
                  type="common"
                  id="displayMode"
                  items={[
                    { id: "D", text: "ReadOnly Grid" },
                    { id: "E", text: "Editable Grid" },
                  ]}
                  options={{
                    matchId: "id",
                    matchNm: "text",
                  }}
                  defaultValue={StringUtils.defaultString(
                    this.state.inputValue.gridDisplayMode,
                    "E"
                  )}
                  onChange={(e) => this.onChangeDisplayMode(e)}
                />
              </InputGroup>
            </div>
          </div>
          <div className="row p-10">
            <div className="col2">
              <TemplateCheckbox
                type="checkbox"
                name="headerTitle"
                className="xmall-input"
                defaultChecked={this.state.inputValue.headerTitle}
                onChange={this.onChangeCheckbox}
              />
              Include Grid title
            </div>
          </div>
          {!ObjectUtils.isEmpty(this.defaultValues.buttons.length > 0) ? (
            <div className="row p-10">
              <div className="col2">
                <TemplateCheckbox
                  ref={this.buttonCheckBox}
                  type="checkbox"
                  name="containButtons"
                  className="xmall-input"
                  defaultChecked={this.state.inputValue.containButtons}
                  onChange={this.onChangeCheckbox}
                />
                Include Grid Button
              </div>
            </div>
          ) : (
            ""
          )}
        </Modal.Body>
        <Modal.Footer>{this.renderFooter()}</Modal.Footer>
      </Modal>
    );
  };

  /**
   * Grid 단독 component을 생성
   * @returns
   */
  renderComponentTemplatePop = () => {
    return (
      <Modal>
        <Modal.Header title="Init Grid" />
        <Modal.Body>{this.renderSelectDataModel()}</Modal.Body>
        <Modal.Footer>{this.renderFooter()}</Modal.Footer>
      </Modal>
    );
  };

  /**
   * Template render
   * @returns
   */
  renderTemplate = () => {
    //Title, button 등이 포함된 Grid layout을 생성
    if (this.props.event === "template") {
      return this.renderLayoutTemplatePop();

      //Grid 단독 component을 생성
    } else if (this.props.event === "component") {
      return this.renderComponentTemplatePop();
    }
  };
}
export default GridLayoutTemplate;
