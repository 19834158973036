import styled, { css } from "styled-components";
import * as Enums from "components/builder/BuilderEnum";
import { NumberUtils, StringUtils } from "components/common/utils/CommonUtils";
import { Col, Form, Row } from "react-bootstrap";
import React from "react";

export const PropertiesHeader = styled.div`
  font-size: 14px;
  margin: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const PropertyLable = styled.div`
  width: 25%;

  ${({ requried }) => {
    return requried
      ? `:after {
      content: ' *';
    }`
      : null;
  }}
`;

export const PropertyValue = styled.div`
  width: 75%;
  padding: 4px 0px 4px 4px;
`;

export const PropertyButtonGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 6px;
`;

export const PropertyFieldSet = styled.div`
  width: 100%;
`;
export const PropertyFieldSetContents = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

export const PropertyValueSet = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 6px;
  padding-bottom: 6px;
`;

export const EditorPanel = styled.div`
  ${({ programType, pagePropertyValue }) => {
    let reducedHeight =
      Enums.Style.HEADER_HEIGHT + Enums.Style.EDITOR_TAB_HEIGHT;

    // //popup일 경우 footer와 popup 크기를 반경
    // if (programType === Enums.ProgramType.POPUP_PROGRAM) {
    //   let popupOptions = pagePropertyValue.popupOptions || {};

    //   //footer가 있을 경우 높이에 footer 반영
    //   if (popupOptions.displayFooter !== false) {
    //     reducedHeight += Enums.Style.FOOTER_HEIGHT;
    //   }
    // }

    return css`
      height: calc(100vh - ${reducedHeight}px);
    `;
  }}
`;

export const FilterPanel = styled.div`
  ${({ programType, pagePropertyValue }) => {
    let reducedHeight =
      Enums.Style.HEADER_HEIGHT + Enums.Style.EDITOR_TAB_HEIGHT;

    return css`
      height: calc(100vh - ${reducedHeight}px);
    `;
  }}
`;

export const FooterPanel = styled.div`
  ${({ pagePropertyValue }) => {
    return css`
      height: ${Enums.Style.FOOTER_HEIGHT}px;
      max-height: ${Enums.Style.FOOTER_HEIGHT}px;
    `;
  }}
`;

export const TemplateCheckbox = styled.input`
  vertical-align: middle;
  margin-right: 8px;
  transform: scale(1.2);
`;

export const InputGropuWithLabel = (props) => {
  const { propertyValue, children, defaultLabel } = props;

  let labelStyle = {};
  let inputStyle = {};
  //label 직접 입력일 경우 css로 width추가
  if (propertyValue.isLabelWidthCustom === true) {
    labelStyle.width = propertyValue.customLabelWidth + "%";
    inputStyle.width = 100 - propertyValue.customLabelWidth + "%";
  }

  let labelWidth =
    propertyValue.layoutType === "N"
      ? 0
      : StringUtils.defaultString(
          propertyValue.labelWidth,
          Enums.Style.LABEL_DEFAULT
        );
  let inputWidth = NumberUtils.isNumeric(labelWidth, "1")
    ? 12 - Number(labelWidth)
    : 4;

  let labelClass = "";
  if (propertyValue.layoutType === "E") labelClass = "save-form-label";
  else if (propertyValue.layoutType === "D") labelClass = "search-form-label";
  else labelClass = "form-depend-label";

  if (propertyValue.isRequired === true) labelClass += " required";

  return (
    <Form.Group as={Row} className="form-group">
      {propertyValue.layoutType !== "N" ? (
        <Form.Label
          className={labelClass + " col-" + labelWidth}
          style={labelStyle}
        >
          {StringUtils.defaultString(propertyValue.formLabel, defaultLabel)}
        </Form.Label>
      ) : (
        ""
      )}
      <Col className={`input-col col-${inputWidth}`} style={inputStyle}>
        {children}
      </Col>
    </Form.Group>
  );
};

/**
 * event button list
 * @param {*} props
 */
export const ListWidget = (props) => {
  const { title, desc, children, target } = props;
  return (
    <div className="list-group-item">
      <div className="widget-content p-0">
        <div className="widget-content-wrapper">
          <div className="widget-content-left me-3">{children}</div>
          <div className="widget-content-left">
            <div className="widget-heading">{title}</div>
            <div className="widget-subheading">{desc}</div>
            <div>{target}</div>
          </div>
        </div>
      </div>
    </div>
  );
};
