import { Tooltip } from "@mui/material";
import { stopEvent } from "components/builder/ui/editor/handler/UIEditorEventHandler";
import Popup from "components/common/Popup";
import { memo, useContext, useEffect, useState } from "react";
import { AiOutlineClose, AiOutlineEdit } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { useEdges, useNodes } from "reactflow";
import WorkflowReduxHelper from "../editor/helper/WorkflowReduxHelper";
import { ModalWidth } from "../WorkflowBuilder";
import CodePopup from "page/popup/workflow/CodePopup";
import { BsCodeSlash } from "react-icons/bs";
import { Button } from "react-bootstrap";
import { Enums } from "components/builder/BuilderEnum";
import { WorkflowContext } from "page/workflow";

/**
 * Code 타입
 * @param {data:Object,id:String,selected:Boolean}
 */
export const CodeNode = memo(
  ({
    data: { process, comment, isBundling },
    id,
    selected,
    parentNodeId,
    isChild,
    parentNode,
    addHandler,
    onAddBundle,
    onDeleteFromBundle,
    setBreakpoint,
    removeBreakpoint,
    isTracing,
    isBreakPoint,
    commentCheckMethod,
    inCommunication,
    breakpointType,
    breakpoints,
    debugProcess,
    addBundleControlButton,
    ...props
  }) => {
    const dispatch = useDispatch();
    const workflow = useSelector((state) => state.workflow);
    const workspace = useSelector((state) => state.workspace);
    const [isComment, setIsComment] = useState(false);
    const {
      bundle: { bundlingMode },
    } = useContext(WorkflowContext); //번들링 관련

    const nodes = useNodes();
    const edges = useEdges();

    // const isTarget = connectionNodeId && connectionNodeId !== id;
    // const targetHandleStyle = { zIndex: isTarget ? 3 : 1 };
    useEffect(() => {
      setIsComment(commentCheckMethod());
    }, [comment]);

    const onOpenProcessPopup = (e) => {
      const callbackFnc = (callbackData) => {
        const body = {
          ...process,
          propertyValue: callbackData,
        };
        if (parentNode.type === Enums.WorkflowNodeType.ITERATOR && isChild) {
          WorkflowReduxHelper.updateIteratorNode(
            dispatch,
            [body],
            parentNode,
            workflow
          );
        } else {
          WorkflowReduxHelper.updateNodes(dispatch, [body], workflow);
        }

        Popup.close();
      };
      Popup.open(
        <CodePopup
          parentNodeId={parentNodeId}
          workspace={workspace}
          callbackFnc={callbackFnc}
          workflow={workflow.output}
          processInfo={process.propertyValue}
          processType={process.processType}
          nodes={nodes}
          edges={edges}
          compId={id}
        />,
        {
          keyDownEvent: false,
          style: {
            content: {
              width: ModalWidth[process.processType] || ModalWidth.other,
            },
          },
        }
      );
    };

    const onDeleteProcess = (e) => {
      stopEvent(e);
      WorkflowReduxHelper.deleteProcess(dispatch, process.compId, workflow);
    };

    return (
      <>
        {inCommunication && isBreakPoint ? (
          <div className={`debug-info ${breakpointType}`}>
            <div>Before</div>
            <div>After</div>
          </div>
        ) : (
          <></>
        )}
        <div
          className={`workflow-node ${isChild ? `${parentNodeId}_child` : ""} ${
            comment && ` comment`
          } ${bundlingMode && isBundling ? " bundling" : ""}`}
        >
          <div
            className={`workflow-process-node-wrapper ${
              selected ? "selected" : ""
            }
              ${isTracing ? " traced " : ""} 
              ${debugProcess?.compId === id ? " border-blink " : ""} 
              `}
          >
            {isComment && (
              <div className="node-comment" onDoubleClick={onOpenProcessPopup}>
                Comment
              </div>
            )}
            <div className="header">
              <div className="title">
                <span>
                  <BsCodeSlash size={22} />
                </span>
                <span className="name"> {process.propertyValue.processNm}</span>
              </div>
              {addBundleControlButton(
                <div>
                  <Tooltip title="Edit">
                    <button
                      style={{ color: "limegreen" }}
                      onClick={onOpenProcessPopup}
                    >
                      <AiOutlineEdit size={20} />
                    </button>
                  </Tooltip>
                  <button onClick={onDeleteProcess}>
                    <AiOutlineClose size={20} />
                  </button>
                </div>
              )}
            </div>
            <div
              className="workflow-process-node"
              onDoubleClick={onOpenProcessPopup}
            >
              <div className="code-node-wrapper">
                <div className="code-type">
                  {String(process.propertyValue.codeType).toUpperCase()}
                </div>
                <Button
                  variant="primary"
                  className="service-edit-button"
                  onClick={onOpenProcessPopup}
                  size="sm"
                >
                  {`[ Code Details ]`}
                </Button>
              </div>
              {/* 코드 설명 같은거 넣을수도 있음 */}
              {/* <div className="process-type">Process - {process.processType}</div> */}
            </div>
          </div>
          {addHandler()}
        </div>
      </>
    );
  }
);
