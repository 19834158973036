import { Tooltip } from "@mui/material";
import { Enums } from "components/builder/BuilderEnum";
import ModuleSelector from "components/common/element/ModuleSelector";
import Message from "components/common/Message";
import Modal from "components/common/modal/UModal";
import UmodalTemplate from "components/common/modal/UModalTemplate";
import Popup from "components/common/Popup";
import StringUtils from "components/common/utils/StringUtils";
import User from "components/common/utils/UserUtils";
import React, { useEffect } from "react";
import { useState } from "react";
import {
  Button,
  Col,
  Form,
  FormGroup,
  FormLabel,
  InputGroup,
  Row,
} from "react-bootstrap";
import AppService from "services/common/AppService";

function NewWorkspacePopup({
  appId,
  moduleCd,
  releaseId,
  appList,
  releaseList,
  callback,
  ...props
}) {
  const [expandWorkspace, setExpandWorkspace] = useState(false);
  const [pModuleCd, setpModuleCd] = useState(moduleCd || "");
  const [pAppId, setpAppId] = useState(appId || "");
  const [pReleaseId, setpReleaseId] = useState(releaseId);
  const [tenantId, setTenantId] = useState("");
  const [tenantNm, setTenantNm] = useState("");
  const [coCd, setCoCd] = useState("");
  const [coNm, setCoNm] = useState("");

  const [pAppList, setAppList] = useState(appList || []);
  const [versionList, setVersionList] = useState(releaseList || []);
  const [tenantList, setTenantList] = useState([]);

  const [tenantSelectMode, setTenantSelectMode] = useState(false);

  const onChangeModule = (e) => {
    setpModuleCd(e.target.value);
    //버전 목록 불러오기
    AppService.getReleaseList({ appId: pAppId, moduleCd: pModuleCd }, (res) => {
      setVersionList(res.data);
    });
  };

  useEffect(() => {
    getTenantList();
  }, []);

  const getTenantList = () => {
    const body = {
      appId: pAppId,
      moduleCd: pModuleCd,
      appReleaseId: pReleaseId,
    };

    AppService.getTenantList(body, (res) => {
      let list = res.data.map((item) => {
        let obj = { ...item };
        obj.lebel = item.tenantId;
        return obj;
      });
      setTenantList(list);
    });
  };

  const onSaveTenant = (e) => {
    if (e) e.preventDefault();
    const body = {
      appId: pAppId,
      moduleCd: pModuleCd,
      appReleaseId: pReleaseId,
      tenantId,
      tenantNm,
      coCd,
      coNm,
    };

    if (!body.appId || !body.moduleCd || !body.appReleaseId) {
      setExpandWorkspace(true);
      return Message.alert(
        "There are missing parts in the workspace location settings.",
        Enums.MessageType.ERROR
      );
    }

    if (!tenantId) {
      return Message.alert("Please enter Tenant ID.");
    }
    if (!coCd) {
      return Message.alert("Please enter Company Code.");
    }

    if (
      tenantId === "*" &&
      coCd === "*" &&
      !(
        StringUtils.equalsIgnoreCase(User.getAuthType(), "AM") ||
        StringUtils.equalsIgnoreCase(User.getAuthType(), "S")
      )
    ) {
      return Message.alert(
        "Only administrators or app managers can create the standard version.",
        Enums.MessageType.INFO
      );
    }

    AppService.saveTenant(body, (res) => {
      if (res.isError) {
        return Message.alert(res.message, Enums.MessageType.ERROR);
      } else {
        Popup.close();
        if (callback) {
          callback(res.data);
        }
      }
    });
  };

  /**
   * 테넌트 ID 입력
   * @param {*} e
   */
  const onChangeTenantId = (e) => {
    const value = e.target.value;
    setTenantId(value);

    const tenant = tenantList.find((t) => t.tenantId === value);
    if (tenant) {
      setTenantNm(tenant.tenantNm);
    }

    if (StringUtils.equalsIgnoreType(value, "*")) {
      setCoCd("*");
      setCoNm("Standard");
      setTenantNm("Standard");
    }
  };

  const onChangeCoCd = (e) => {
    setCoCd(e.target.value);
    if (StringUtils.equalsIgnoreType(e.target.value, "*")) {
      setCoNm("Standard");
    }
  };

  return (
    <Modal>
      <Modal.Header title="Add New Workspace" />
      <Modal.Body>
        <UmodalTemplate>
          <Row className="mb-3">
            <FormGroup as={Col} xs={12}>
              <FormLabel onClick={(e) => setExpandWorkspace(!expandWorkspace)}>
                Workspace Location Setting {expandWorkspace ? "▲" : "▼"}
              </FormLabel>
            </FormGroup>
          </Row>
          {expandWorkspace && (
            <>
              <Row className="mb-5 gy-3">
                <FormGroup as={Col} xs={12}>
                  <FormLabel className="required">Select Application</FormLabel>
                  <Form.Select
                    value={pAppId}
                    onChange={(e) => setpAppId(e.target.value)}
                  >
                    {pAppList.map((app) => {
                      return <option value={app.appId}>{app.appNm}</option>;
                    })}
                  </Form.Select>
                </FormGroup>
                <FormGroup as={Col} xs={12}>
                  <FormLabel className="required">Select Module</FormLabel>
                  <ModuleSelector
                    type="u"
                    appId={pAppId}
                    value={pModuleCd}
                    onChange={onChangeModule}
                    showEmpty
                    placeholder={
                      pAppId ? "Select Module" : "Please select App."
                    }
                  />
                </FormGroup>
                <FormGroup as={Col} xs={12}>
                  <FormLabel className="required">Select Version</FormLabel>
                  <Form.Select
                    disabled={!pModuleCd}
                    value={pReleaseId}
                    onChange={(e) => setpReleaseId(e.target.value)}
                  >
                    {versionList.length === 0 ? (
                      <option value="">There is no version registered.</option>
                    ) : (
                      <>
                        <option value="">Select Version</option>
                        {versionList.map((release) => {
                          return (
                            <option
                              value={release.appReleaseId}
                              key={release.appReleaseId}
                            >
                              {release.version}
                            </option>
                          );
                        })}
                      </>
                    )}
                  </Form.Select>
                </FormGroup>
              </Row>
            </>
          )}

          <Row className="mb-3">
            <FormGroup as={Col} xs={6}>
              <FormLabel className="required">Tenant ID</FormLabel>
              <InputGroup>
                {tenantSelectMode ? (
                  <>
                    <Form.Select
                      placeholder="Enter Tenant ID"
                      value={tenantId}
                      onChange={onChangeTenantId}
                    >
                      <option value="">Select ID</option>
                      {tenantList.map((tenant) => {
                        return (
                          <option value={tenant.tenantId} key={tenant.tenantId}>
                            {tenant.tenantId}
                          </option>
                        );
                      })}
                    </Form.Select>
                    <Tooltip title="Enter Directly" placement="top">
                      <Button onClick={() => setTenantSelectMode(false)}>
                        Enter
                      </Button>
                    </Tooltip>
                  </>
                ) : (
                  <>
                    <Form.Control
                      placeholder="Enter Tenant ID"
                      value={tenantId}
                      onChange={onChangeTenantId}
                      maxLength={11}
                    />
                    <Tooltip title="Select from existing list" placement="top">
                      <Button onClick={() => setTenantSelectMode(true)}>
                        Select
                      </Button>
                    </Tooltip>
                  </>
                )}
              </InputGroup>
            </FormGroup>

            <FormGroup as={Col} xs={6}>
              <FormLabel className="required">Tenant Name</FormLabel>
              <Form.Control
                placeholder="Enter Tenant Name"
                value={tenantNm}
                onChange={(e) => setTenantNm(e.target.value)}
                disabled={tenantSelectMode}
              />
            </FormGroup>
          </Row>
          <Row>
            <FormGroup as={Col} xs={6}>
              <FormLabel className="required">Company Code (CO CD)</FormLabel>
              <Form.Control
                placeholder="Enter Company Code"
                value={coCd}
                onChange={onChangeCoCd}
                disabled={StringUtils.equalsIgnoreType(tenantId, "*")}
                maxLength={10}
              />
            </FormGroup>

            <FormGroup as={Col} xs={6}>
              <FormLabel className="required">Company Name</FormLabel>
              <Form.Control
                placeholder="Enter Company Name"
                value={coNm}
                onChange={(e) => setCoNm(e.target.value)}
              />
            </FormGroup>
          </Row>
        </UmodalTemplate>
      </Modal.Body>
      <Modal.Footer>
        <Modal.Footer.Button onClick={onSaveTenant}>Save</Modal.Footer.Button>
      </Modal.Footer>
    </Modal>
  );
}

export default NewWorkspacePopup;
