import { Tooltip } from "@mui/material";
import CommandButton from "components/builder/CommandButton";
import * as Event from "components/builder/ui/editor/handler/UIEditorEventHandler";
import {
  redo,
  setClipboard,
  undo,
} from "components/builder/ui/reducers/CommandAction";
import {
  addMemo,
  deleteMemo,
  initActive,
  setBuilderTreeNodeIds,
  setTreeOpen,
  updateMemo,
  updateOutput,
} from "components/builder/ui/reducers/UIBuilderAction";
import { AppContext } from "components/common/AppContextProvider";
import Message from "components/common/Message";
import CommonUtils from "components/common/utils/CommonUtils";
import JsonUtils from "components/common/utils/JsonUtils";
import ObjectUtils from "components/common/utils/ObjectUtils";
import StringUtils from "components/common/utils/StringUtils";
import User from "components/common/utils/UserUtils";
import produce from "immer";
import moment from "moment/moment";
import React from "react";
import { Button, ButtonGroup, ToggleButton } from "react-bootstrap";
import { AiOutlineClose, AiOutlinePlus } from "react-icons/ai";
import { BiNotepad } from "react-icons/bi";
import { BsCodeSlash, BsDiagram3, BsPencilSquare } from "react-icons/bs";
import { GrLinkPrevious } from "react-icons/gr";
import { connect } from "react-redux";
import { Enums } from "../BuilderEnum";
import CommandButtonComponent from "../CommandButtonComponent";
import UIReduxHelper from "./editor/helper/UIReduxHelper";
import UITemplateHelper from "./editor/helper/UITemplateHelper";

class UICommandButton extends CommandButton {
  constructor(props) {
    super(props);
    this.onClickBuilderTree = this.onClickBuilderTree.bind(this);
    // this.findNodeAndsetClipboard = this.findNodeAndsetClipboard.bind(this);
    this.onOpenMemoList = this.onOpenMemoList.bind(this);
    this.onAddMemo = this.onAddMemo.bind(this);
    this.renderMemoComponent = this.renderMemoComponent.bind(this);
    this.onChangeMemoState = this.onChangeMemoState.bind(this);
    this.onClickPage = this.onClickPage.bind(this);
    this.state = {
      tabType: "E",
      rightSideData: null,
      memo: {
        open: false,
        detail: false,
        edit: false,
        detailContents: { contents: "", author: "", date: "", index: -1 },
      },
    };
    this.memoTextRef = React.createRef();
    this.clickTarget = React.createRef();
  }

  static contextType = AppContext;

  onClickBuilderTree = (e) => {
    this.props.setTreeOpen(!this.props.treeOpen);
  };
  /**
   * 오른쪽 데이터 내용 입력
   * @param {*} dataComponent
   * @returns
   */
  setRightSideData() {
    return ObjectUtils.isEmpty(this.props.output["page"]) ||
      ObjectUtils.isEmpty(this.props.output["page"]["propertyValue"])
      ? ""
      : this.props.output["page"]["propertyValue"]["programId"];
  }

  undo() {
    const { prev, undoIndex } = this.props.command;
    const newOutput = { ...prev.at(undoIndex) };
    this.props.undo(newOutput);
  }
  redo() {
    const { undoIndex, latest } = this.props.command;
    const newOutput = { ...latest.at(undoIndex + 1) };
    this.props.redo(newOutput);
  }

  // findNodeAndsetClipboard = () => {
  //   let currentNode;
  //   //그리드
  //   if (this.props.component.type === Enums.ComponentType.GRID_HEADER) {
  //     const compId = this.props.component.compId.split("-")[1];
  //     currentNode = JsonUtils.findNode(this.props.output, "compId", compId);
  //     const updateIdNode = produce(currentNode, (draftNew) => {
  //       draftNew.type = Enums.ComponentType.GRID_HEADER;
  //     });
  //     this.props.setClipboard(updateIdNode);
  //   } else {
  //     //1. 노드 찾기
  //     currentNode = JsonUtils.findNode(
  //       this.props.output,
  //       "compId",
  //       this.props.component.compId
  //     );
  //     //3. 리덕스에 담기
  //     this.props.setClipboard(currentNode);
  //   }

  //   return currentNode;
  // };

  onCut(e) {
    if (
      ObjectUtils.isEmpty(this.props.component) ||
      this.props.component.compId.indexOf("page") > -1
    )
      return Message.alert("Please select a component", Enums.MessageType.INFO);

    Event.cutNode(
      e,
      this.props.component,
      this.props.output,
      this.props.treeNodeIds,
      this.context,
      this.props.dispatch
    );
  }

  onCopy(e) {
    e.preventDefault();
    if (
      ObjectUtils.isEmpty(this.props.component) ||
      this.props.component.compId.indexOf("page") > -1
    )
      return Message.alert(
        "Please select a component.",
        Enums.MessageType.INFO
      );
    Event.copyNode(
      e,
      this.props.component,
      this.props.output,
      this.props.dispatch
    );
  }

  renderAdditionalButton() {
    return <CommandButtonComponent />;
  }

  onPaste(e) {
    const { clipboard } = this.props.command;
    const components = this.context.component.getComponentList("B");
    if (Object.keys(clipboard).length === 0) {
      return Message.alert("ClipBoard is Empty.", Enums.MessageType.WARN);
    }
    //1. 원하는 공간 선택
    const currentNode = JsonUtils.findNode(
      this.props.output,
      "compId",
      this.props.component.compId
    );
    const pasteComponent = produce(clipboard, (draft) => {
      JsonUtils.updateCompId(draft);
    });
    if (
      StringUtils.equalsIgnoreType(
        clipboard.type,
        Enums.ComponentType.GRID_HEADER
      )
    ) {
      //클립 보드에 있는 것이 그리드 셀인 경우
      if (
        StringUtils.equalsIgnoreType(currentNode.type, Enums.ComponentType.GRID)
      ) {
        const { type, ...cellClipboard } = pasteComponent;
        const currentPropertyValue = { ...currentNode.propertyValue };
        const newPropertyValue = produce(currentPropertyValue, (draft) => {
          draft.gridOptions.columns = [
            ...draft.gridOptions.columns,
            cellClipboard,
          ];
        });

        const changedOutput = produce(this.props.output, (draft) => {
          const targetNode = JsonUtils.overrideNode(
            draft,
            "compId",
            currentNode.compId,
            "propertyValue",
            newPropertyValue
          );
          if (ObjectUtils.isEmpty(targetNode)) {
            console.log("Could not find target node !!!!");
            return false;
          }
        });
        this.props.updateOutput(changedOutput);
      } else if (
        //선택한 컴포넌트가 그리드 해드인 경우 오른쪽 자리로 붙여넣기
        StringUtils.equalsIgnoreType(
          clipboard.type,
          Enums.ComponentType.GRID_HEADER
        )
      ) {
        const { type, ...cellClipboard } = pasteComponent;
        //부모 그리드
        const parentComponent = JsonUtils.findNode(
          this.props.output,
          "compId",
          this.props.treeNodeIds.at(-2)
        );
        //1. 부모 그리드에서 현재 선택한 셀의 위치 확인
        const currentIndex =
          parentComponent.propertyValue.gridOptions.columns.findIndex((col) =>
            StringUtils.equalsIgnoreType(
              col.compId,
              this.props.component.propertyValue.compId
            )
          );
        const currentPropertyValue = { ...parentComponent.propertyValue };
        //칼럼 사이 공간 만들어서 셀 주입
        const newPropertyValue = produce(currentPropertyValue, (draft) => {
          draft.gridOptions.columns = [
            ...draft.gridOptions.columns.slice(0, currentIndex + 1),
            cellClipboard,
            ...draft.gridOptions.columns.slice(currentIndex + 1),
          ];
        });
        const changedOutput = produce(this.props.output, (draft) => {
          const targetNode = JsonUtils.overrideNode(
            draft,
            "compId",
            parentComponent.compId,
            "propertyValue",
            newPropertyValue
          );
          if (ObjectUtils.isEmpty(targetNode)) {
            console.log("Could not find target node !!!!");
            return false;
          }
        });
        this.props.updateOutput(changedOutput);
      } else {
        return Message.alert(
          "Grid cells only can be pasted into the grid.",
          Enums.MessageType.WARN
        );
      }
    } else {
      if (
        StringUtils.equalsIgnoreType(
          currentNode.type,
          Enums.ComponentType.GRID
        ) ||
        StringUtils.equalsIgnoreType(
          this.props.component.type,
          Enums.ComponentType.GRID_HEADER
        ) ||
        StringUtils.equalsIgnoreType(
          this.props.component.type,
          Enums.ComponentType.GRID_CELL
        )
      ) {
        return Message.alert(
          "Only cells can be pasted into the grid.",
          Enums.MessageType.WARN
        );
      } else if (currentNode) {
        const newChild = currentNode.child
          ? [...currentNode.child, pasteComponent]
          : [pasteComponent];
        //2. 공간 내부의 레벨에 붙여넣기
        const compId = currentNode.compId;
        const changedOutput = produce(this.props.output, (draft) => {
          const targetNode = JsonUtils.overrideNode(
            draft,
            "compId",
            compId,
            "child",
            newChild
          );
          if (ObjectUtils.isEmpty(targetNode)) {
            console.log("Could not find target node !!!!");
            return false;
          }
        });
        this.props.updateOutput(changedOutput);
        this.props.clickComponent(
          e,
          pasteComponent,
          components,
          this.props.component
        );
      } else {
        return Message.alert("No Component Selected.", Enums.MessageType.WARN);
      }
    }
  }

  onOpenMemoList() {
    this.setState(
      produce(this.state, (draft) => {
        draft.memo.open = !this.state.memo.open;
      })
    );
  }

  onAddMemo(e) {
    Event.stopEvent(e);
    const newMemo = {
      author: User.getId(),
      date: moment().toDate().getTime(),
      contents: "",
      index: this.props.memo.length || 0,
    };
    this.setState(
      produce(this.state, (draft) => {
        draft.memo.detail = true;
        draft.memo.edit = true;
        draft.memo.detailContents = { ...newMemo };
      })
    );
    this.props.addMemo(newMemo);
  }

  onChangeMemoState(obj) {
    this.setState(
      produce(this.state, (draft) => {
        for (const key in obj) {
          if (ObjectUtils.isObject(obj[key])) {
            for (const kk in obj[key]) {
              draft.memo[key][kk] = obj[key][kk];
            }
          } else {
            draft.memo[key] = obj[key];
          }
        }
      })
    );
  }

  onClickPage(e) {
    const components = this.props.component;
    const output = this.props.output;
    const templateComponents = UITemplateHelper.getTemplateComponents(
      this.context.component
    );
    const pageTemplate = UITemplateHelper.getTemplate(
      templateComponents,
      Enums.ComponentType.PAGE
    );

    this.props.handlePageClick(e, components, output, pageTemplate);
  }

  renderMemoComponent(component) {
    return (
      <div
        className={`memo-body ${this.state.memo.open ? "show" : "hidden"}`}
        ref={this.clickTarget}
      >
        {this.state.memo.detail ? (
          <>
            <div className="detail-body">
              <div className="detail-header">
                <button
                  onClick={(e) => {
                    Event.stopEvent(e);
                    this.onChangeMemoState({ detail: false });
                  }}
                >
                  <GrLinkPrevious />
                </button>
                <button
                  onClick={(e) => {
                    Event.stopEvent(e);
                    this.onChangeMemoState({ detail: false, open: false });
                  }}
                >
                  <AiOutlineClose />
                </button>
              </div>
              {this.state.memo.edit ? (
                <div className="detail-contents">
                  <textarea
                    ref={this.memoTextRef}
                    defaultValue={this.state.memo.detailContents.contents}
                  />
                </div>
              ) : (
                <div
                  className="detail-contents"
                  onDoubleClick={(e) => this.onChangeMemoState({ edit: true })}
                >
                  {this.state.memo.detailContents.contents}
                </div>
              )}

              <div className="detail-info">
                <div>{this.state.memo.detailContents.author}</div>
                <div>
                  {CommonUtils.getDate(this.state.memo.detailContents.date)}
                </div>
                {this.state.memo.edit ? (
                  <>
                    <div>
                      <Button
                        size="sm"
                        variant="outline-primary"
                        onClick={(e) => {
                          this.props.updateMemo({
                            ...this.state.memo.detailContents,
                            contents: this.memoTextRef.current.value,
                          });
                          this.onChangeMemoState({
                            edit: false,
                            detailContents: {
                              contents: this.memoTextRef.current.value,
                            },
                          });
                        }}
                      >
                        submit
                      </Button>
                    </div>
                    <div>
                      <Button
                        size="sm"
                        variant="outline-danger"
                        onClick={(e) => {
                          this.onChangeMemoState({ edit: false });
                        }}
                      >
                        cancel
                      </Button>
                    </div>
                  </>
                ) : (
                  <div style={{ display: "flex", gap: "5px" }}>
                    <Button
                      size="sm"
                      variant="outline-success"
                      onClick={(e) => {
                        this.onChangeMemoState({ edit: true });
                      }}
                    >
                      edit
                    </Button>
                    <Button
                      size="sm"
                      variant="outline-danger"
                      onClick={(e) => {
                        Message.confirm(
                          "Are you sure to delete the memo?",
                          () => {
                            this.props.deleteMemo(
                              this.state.memo.detailContents
                            );
                            this.onChangeMemoState({
                              detail: false,
                              detailContents: {},
                            });
                          }
                        );
                      }}
                    >
                      delete
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="header">
              <div>{component} Memo List</div>
              <div>
                <Tooltip title="Add Memo">
                  <button
                    variant="outline-dark"
                    size="sm"
                    onClick={this.onAddMemo}
                  >
                    <AiOutlinePlus />
                  </button>
                </Tooltip>
                <button
                  onClick={(e) => {
                    Event.stopEvent(e);
                    this.onChangeMemoState({ detail: false, open: false });
                  }}
                >
                  <AiOutlineClose />
                </button>
              </div>
            </div>
            <div className="list">
              {this.props.memo && this.props.memo.length > 0 ? (
                [...this.props.memo]
                  .sort((a, b) => (a.date > b.date ? -1 : 1))
                  .map((memo, idx) => {
                    return (
                      <div
                        className="memo-contents"
                        key={idx}
                        onClick={(e) => {
                          Event.stopEvent(e);
                          this.setState(
                            produce(this.state, (draft) => {
                              draft.memo.detail = true;
                              draft.memo.edit = false;
                              draft.memo.detailContents = memo;
                            })
                          );
                        }}
                      >
                        <div className="memo">{memo.contents}</div>
                        <div className="memo-info">
                          <span>{memo.author}</span>
                          <span>{CommonUtils.getDate(memo.date)}</span>
                        </div>
                      </div>
                    );
                  })
              ) : (
                <div>There are no registered memos.</div>
              )}
            </div>
          </>
        )}
      </div>
    );
  }

  /**
   * 오른쪽
   * @returns
   */
  renderDataNm() {
    const component = this.setRightSideData();
    return (
      <>
        <div className="program-id-label" onClick={this.onClickPage}>
          {component}
        </div>
        <div>
          <Tooltip title="Memo List" placement="top">
            <Button
              size="sm"
              variant="outline-dark"
              onClick={this.onOpenMemoList}
            >
              <BiNotepad />
            </Button>
          </Tooltip>
        </div>
        {this.renderMemoComponent(component)}
      </>
    );
  }

  renderTabButton() {
    return (
      <ButtonGroup>
        <Button
          key={3}
          id="editorTab3"
          name="editorTab3"
          variant="outline-dark"
          size="sm"
          onClick={this.onClickBuilderTree}
        >
          <BsDiagram3 size="14" /> Structure
        </Button>
        <ToggleButton
          key={1}
          id="editorTab1"
          name="editorTab1"
          type="radio"
          variant="outline-dark"
          value="E"
          size="sm"
          checked={this.state.tabType === "E"}
          onChange={this.onChangeTabType}
          style={{ fontSize: "smaller" }}
        >
          <BsPencilSquare size="14" /> Editor
        </ToggleButton>
        <ToggleButton
          key={2}
          id="editorTab2"
          name="editorTab2"
          type="radio"
          variant="outline-dark"
          value="S"
          size="sm"
          checked={this.state.tabType === "S"}
          onChange={this.onChangeTabType}
          style={{ fontSize: "smaller" }}
        >
          <BsCodeSlash size="14" /> Code
        </ToggleButton>
      </ButtonGroup>
    );
  }

  render() {
    return this.renderComponent();
  }
}

export default connect(
  (state) => {
    return {
      command: state.command,
      treeOpen: state.activedUIComponent.treeOpen,
      output: state.outputUI.output,
      memo: state.outputUI.memo,
      component: state.activedUIComponent.component,
      treeNodeIds: state.activedUIComponent.treeNodeIds,
    };
  },
  (dispatch) => ({
    dispatch,
    setTreeOpen: (flag) => dispatch(setTreeOpen(flag)),
    undo: (output) => {
      dispatch(undo());
      dispatch(updateOutput(output));
      dispatch(initActive());
    },
    redo: (output) => {
      dispatch(redo());
      dispatch(updateOutput(output));
      dispatch(initActive());
    },
    setClipboard: (body) => {
      dispatch(setClipboard(body));
    },
    clickComponent: (e, parentNode, components, component) => {
      Event.clickComponent(e, parentNode, components, component, dispatch);
    },
    handlePageClick: (e, components, output, pageTemplate) => {
      Event.handlePageClick(e, dispatch, components, output, pageTemplate);
    },
    removeNode: (e, currentNode, output, components, pageCompnent) => {
      Event.removeNode(
        e,
        currentNode,
        output,
        components,
        pageCompnent,
        dispatch
      );
    },
    setBuilderTreeNodeIds: (newTreeNodeIds) => {
      dispatch(setBuilderTreeNodeIds(newTreeNodeIds));
    },
    updateOutput: (output) => {
      UIReduxHelper.updateOutput(output, dispatch);
    },
    addMemo: (data) => {
      dispatch(addMemo(data));
    },
    updateMemo: (data) => {
      const memoData = {
        ...data,
        date: moment().toDate().getTime(),
        userId: User.getId(),
      };
      dispatch(updateMemo(memoData));
    },
    deleteMemo: (data) => {
      dispatch(deleteMemo(data));
    },
  })
)(UICommandButton);
