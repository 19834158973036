import { InputLabel, Select, TextField } from "@mui/material";
import { Enums } from "components/builder/BuilderEnum";
import { AppContext } from "components/common/AppContextProvider";
import MuiConfig from "components/common/config/MuiConfig";
import MSelectbox from "components/common/element/MSelectbox";
import WijmoGrid from "components/common/element/WijmoGrid";
import CommonUtils, { ObjectUtils } from "components/common/utils/CommonUtils";
import StringUtils from "components/common/utils/StringUtils";
import produce from "immer";
import PageTemplate from "page/common/PageTemplate";
import { useCallback, useContext, useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { BsSearch } from "react-icons/bs";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import WorkflowService from "services/workflow/WorkflowService";

const columns = [
  { field: "tenantId", headerName: "Tenant ID", width: 150 },
  { field: "coCd", headerName: "Company Code", width: 150 },
  {
    field: "serviceId",
    headerName: "Service ID",
    width: "*",
  },
  {
    field: "serviceName",
    headerName: "Service 명",
    width: "2*",
  },
  {
    field: "status",
    headerName: "Status",
    width: 120,
    renderCell: (params) => {
      const { status } = params;
      if (StringUtils.equalsIgnoreCase(status, "R")) {
        return "Deleted";
      } else if (StringUtils.equalsIgnoreCase(status, "A")) {
        return "In Use";
      }
    },
  },
  {
    field: "updtUserId",
    headerName: "Update User",
    width: 250,
  },
  {
    field: "lastUpdtDt",
    headerName: "Update Date",
    width: 300,
    renderCell: (params) => `${CommonUtils.getDate(params.updtDt, "dateTime")}`,
  },
];

/**
 * Workflow 이력 목록을 조회하는 컴포넌트
 * @returns
 */
function WorkflowHistory() {
  const workspace = useSelector((state) => state.workspace);
  const {
    code: { getCodeList },
    workspace: { openPopup },
  } = useContext(AppContext); //프로그램 유형

  const navigate = useNavigate();

  const [searchTerm, setSearchTerm] = useState({
    serviceId: "",
    serviceName: "",
    status: "A",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [serviceHistoryList, setServiceHistoryList] = useState([]);
  const [pageSize, setPageSize] = useState(20);

  useEffect(() => {
    getWorkflowHistory();
  }, []);

  const breadcrum = [
    {
      name: "Service History ",
      url: Enums.BuilderPath.WORKFLOW_HISTORY,
      active: true,
    },
  ];

  const onKeyDown = (e) => {
    if (e.keyCode === 13) {
      getWorkflowHistory();
    }
  };

  const onMoveToDetail = useCallback((service) => {
    const { serviceId, serviceUid, historyMstId } = service;
    navigate(`${serviceId}/${serviceUid}/${historyMstId}`);
  }, []);

  const getWorkflowHistory = () => {
    setIsLoading(true);
    const body = {
      appId: workspace.appId,
      moduleCd: workspace.moduleCd,
      appReleaseId: workspace.appReleaseId,
      tenantId: workspace.tenantId,
      coCd: workspace.coCd,
      ...searchTerm,
      status: searchTerm.status === "*" ? "" : searchTerm.status,
    };
    WorkflowService.getServiceHistoryList(
      body,
      (res) => {
        setIsLoading(false);
        setServiceHistoryList(res.data);
      },
      () => setIsLoading(false)
    );
  };

  const onChangeSearchTerm = (e) => {
    setSearchTerm(
      produce(searchTerm, (draft) => {
        draft[e.target.id || e.target.name] = e.target.value;
      })
    );
  };

  return (
    <PageTemplate breadcrum={breadcrum}>
      <PageTemplate.Box boxClass="mb-0">
        {ObjectUtils.isEmpty(workspace) ? (
          <>
            <div className="workspace-empty-alert">
              <div className="alert-msg">
                Workspace configuration is required.
              </div>
              <Button onClick={() => openPopup()}>Open Popup</Button>
            </div>
          </>
        ) : (
          <>
            <Form>
              <Row className="pb-3 pt-3">
                <Col>
                  <TextField
                    size="small"
                    label="Service ID"
                    placeholder="Service ID"
                    fullWidth={true}
                    onKeyDown={onKeyDown}
                    id={"serviceId"}
                    value={searchTerm.serviceId}
                    onChange={onChangeSearchTerm}
                  />
                </Col>

                <Col>
                  <TextField
                    size="small"
                    label="Service Name"
                    placeholder="Service Name"
                    fullWidth={true}
                    onKeyDown={onKeyDown}
                    id={"serviceName"}
                    value={searchTerm.serviceName}
                    onChange={onChangeSearchTerm}
                  />
                </Col>
                <Col>
                  <MSelectbox
                    size="small"
                    fullWidth={true}
                    data={[
                      { id: "*", text: "All" },
                      { id: "A", text: "In Use" },
                      { id: "R", text: "Deleted" },
                    ]}
                    options={{ matchCd: "id", matchNm: "text" }}
                  >
                    <InputLabel id="module-label">Status</InputLabel>
                    <Select
                      name="status"
                      labelId="module-label"
                      label="Status"
                      fullWidth
                      color="primary"
                      value={searchTerm.status}
                      onChange={onChangeSearchTerm}
                    ></Select>
                  </MSelectbox>
                </Col>
                <Col>
                  <Button variant="primary" onClick={getWorkflowHistory}>
                    Search <BsSearch size="14" />
                  </Button>
                </Col>
              </Row>
            </Form>
            <Row style={{ height: "calc(100% - 70px)" }}>
              <Col xs={12}>
                <WijmoGrid
                  getRowId={(row) => row.historyMstId}
                  rows={serviceHistoryList}
                  columns={columns}
                  onRowClick={(data, event) => onMoveToDetail(data)}
                  {...MuiConfig.grid.options}
                  rowsPerPageOptions={[pageSize]}
                  pageSize={pageSize}
                  isLoading={isLoading}
                  style={{ height: "60vh" }}
                />
              </Col>
            </Row>
          </>
        )}
      </PageTemplate.Box>
    </PageTemplate>
  );
}

export default WorkflowHistory;
