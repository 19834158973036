import { FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import { Enums } from "components/builder/BuilderEnum";
import { AppContext } from "components/common/AppContextProvider";
import Message from "components/common/Message";
import ModuleSelector from "components/common/element/ModuleSelector";
import WijmoGrid from "components/common/element/WijmoGrid";
import ArrayUtils from "components/common/utils/ArrayUtils";
import StringUtils from "components/common/utils/StringUtils";
import User from "components/common/utils/UserUtils";
import {
  AppConfig,
  AppConfigDesc,
  AppConfigName,
  AppConfigSwitch,
  AppDesc,
} from "components/setting/SettingMenu";
import {
  ContentsItem,
  ContentsLabel,
  ContentsRow,
} from "components/setting/composition/Contents";
import { useContext, useEffect, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import { MdDelete } from "react-icons/md";
import AppService from "services/common/AppService";
import SettingService from "services/common/SettingService";

function General() {
  const MyConfiguration = User.getConfiguration(
    Enums.ConfigurationDefine.GENERAL
  );
  const { application } = useContext(AppContext);
  const [defaultWorkspace, setDefaultWorkspace] = useState("");
  const [generalConfig, setGeneralConfig] = useState({
    preferWorkspaceList:
      MyConfiguration && MyConfiguration[Enums.ConfigurationDefine.WORKSPACE]
        ? MyConfiguration[Enums.ConfigurationDefine.WORKSPACE]
        : [],
  });
  const [appId, setAppId] = useState("");
  const [moduleCd, setModuleCd] = useState("");
  const [appReleaseId, setAppReleaseId] = useState("");
  const [tenantId, setTenantId] = useState("");
  const [coCd, setCoCd] = useState("");

  const [preferWorkspaceYn, setPreferWorkspaceYn] = useState(
    ArrayUtils.isEmpty(generalConfig.preferWorkspaceList) ? false : true
  );

  const preferWorkspaceAppModule = useRef();
  const [releaseList, setReleaseList] = useState([]);
  const [tenantList, setTenantList] = useState([]);
  const [companyList, setCompanyList] = useState([]);

  useEffect(() => {
    let autoPath = User.getConfiguration(Enums.ConfigurationDefine.AUTO_PATH);
    if (autoPath) {
      setDefaultWorkspace(AppService.getWorkspaceName(autoPath));
    }
  }, []);

  const onClickDefaultWorkspace = () => {
    SettingService.deleteUserConfig(
      {
        configurationCode: Enums.ConfigurationDefine.AUTO_PATH,
      },
      (res) => {
        setDefaultWorkspace("");
        const config = User.getConfiguration();
        delete config[Enums.ConfigurationDefine.AUTO_PATH];
        User.setConfiguration(config);
      }
    );
  };

  const preferWorkspaceColumns = [
    {
      field: "spaceName",
      headerName: "Path",
      width: 800,
      renderCell: (param) => {
        return AppService.getWorkspaceName(param);
      },
    },
    {
      field: "remark",
      headerName: "Remarks",
      width: "*",
      align: "center",
      renderCell: (param) => {
        return (
          <Button
            size="sm"
            variant="outline-danger"
            onClick={(e) => onDeletePreferWorkspace(e, param)}
          >
            Delete
          </Button>
        );
      },
    },
  ];

  const onChangeAppId = (e) => {
    setAppId(e.target.value);
    setAppReleaseId("");
    setTenantId("");
    setCoCd("");
  };

  // const onChangePreferWorkspace = (e) => {
  //   setPreferWorkspace({
  //     ...preferWorkspace,
  //     [e.target.id || e.target.name]: e.target.value,
  //   });
  // };
  /**
   * 모듈 채인쳐
   * @param {*} e
   */
  const onChangeModule = (e) => {
    setModuleCd(e.target.value);
    setAppReleaseId("");
    if (!StringUtils.isEmpty(e.target.value)) {
      //버전 목록 불러오기
      getReleaseList({ moduleCd: e.target.value });
      getAppModule({ moduleCd: e.target.value });
    }
  };
  const getReleaseList = (_body) => {
    const body = {
      appId: _body.appId ? _body.appId : appId,
      moduleCd: _body.moduleCd ? _body.moduleCd : moduleCd,
    };
    //버전 목록 불러오기
    return new Promise((resolve, reject) => {
      AppService.getReleaseList(body, (res) => {
        setReleaseList(res.data);
        if (res.data.length === 1) {
          setAppReleaseId(res.data[0].appReleaseId);
          onVersionChange(
            { target: { value: res.data[0].appReleaseId } },
            _body
          );
        }
        resolve(res.data);
      });
    });
  };

  //모듈 코드와 앱 아이디에 맞는 모듈 불러오기
  const getAppModule = (_body) => {
    const body = {
      appId: _body.appId ? _body.appId : appId,
      moduleCd: _body.moduleCd ? _body.moduleCd : moduleCd,
    };
    return new Promise((resolve, reject) => {
      AppService.getAppModule(body, (res) => {
        preferWorkspaceAppModule.current = res.data;
        resolve(res.data);
      });
    });
  };
  /**
   * 버전 체인져
   * @param {*} e
   */
  const onVersionChange = (e, _body) => {
    if (e.target.value) {
      //버전 선택시 표준 버전을 우선적으로 가져옴
      const body = {
        appId: _body.appId ? _body.appId : appId,
        moduleCd: _body.moduleCd ? _body.moduleCd : moduleCd,
        appReleaseId: e.target.value,
        tenantId: "*",
      };
      AppService.getCompanyList(body, (res) => {
        setTenantId("*");
        setCoCd("*");
        setAppReleaseId(e.target.value);
        getTenantList(body);
        setCompanyList(res.data);
        if (res.data.length === 0) {
          Message.alert(
            "There is no standard data for this version. Please contact the Administrator.",
            Enums.MessageType.WARN
          );
        }
      });
    } else {
      setAppReleaseId("");
    }
  };
  /**
   * 테넌트 목록 호출
   */
  const getTenantList = (_body = {}) => {
    const body = {
      appId: _body.appId ? _body.appId : appId,
      moduleCd: _body.moduleCd ? _body.moduleCd : moduleCd,
      appReleaseId: _body.appReleaseId ? _body.appReleaseId : appReleaseId,
    };
    return new Promise((resolve, reject) => {
      return AppService.getTenantList(body, (res) => {
        res.data.sort((a, b) => {
          if (a.tenantId === "*") {
            return -1;
          } else if (b.tenantId === "*") {
            return 1;
          }
        });
        setTenantList(res.data);
        resolve(res.data);
      });
    });
  };

  /**
   * 테넌트 체인져
   * @param {*} e
   */
  const onChangeTenant = (e) => {
    getCocdList({ tenantId: e.target.value });
    setTenantId(e.target.value);
    setCoCd("*");
  };

  /**
   * 회사코드 목록 호출
   */
  const getCocdList = (_body = {}) => {
    const body = {
      appId: _body.appId ? _body.appId : appId,
      moduleCd: _body.moduleCd ? _body.moduleCd : moduleCd,
      appReleaseId: _body.appReleaseId ? _body.appReleaseId : appReleaseId,
      tenantId: _body.tenantId ? _body.tenantId : tenantId,
    };
    return new Promise((resolve, reject) => {
      return AppService.getCompanyList(body, (res) => {
        setCompanyList(res.data);
        resolve(res.data);
      });
    });
  };

  const onAddPreferWorkspace = () => {
    if (!appId)
      return Message.alert(
        "Please select Application.",
        Enums.MessageType.INFO
      );
    if (!moduleCd)
      return Message.alert(
        "Please select Module Code.",
        Enums.MessageType.INFO
      );
    if (!appReleaseId)
      return Message.alert("Please select Version.", Enums.MessageType.INFO);
    if (!tenantId)
      return Message.alert("Please select TenantId.", Enums.MessageType.INFO);
    if (!coCd)
      return Message.alert(
        "Please select Company Code.",
        Enums.MessageType.INFO
      );
    const tenantMst = companyList.find(
      (c) =>
        StringUtils.equalsIgnoreType(c.appId, appId) &&
        StringUtils.equalsIgnoreType(c.moduleCd, moduleCd) &&
        StringUtils.equalsIgnoreType(c.appReleaseId, appReleaseId) &&
        StringUtils.equalsIgnoreType(c.tenantId, tenantId) &&
        StringUtils.equalsIgnoreType(c.coCd, coCd)
    );
    if (tenantMst) {
      const app = application.availableList.find((a) =>
        StringUtils.equalsIgnoreType(a.appId, appId)
      );

      const release = releaseList.find((v) =>
        StringUtils.equalsIgnoreType(v.appReleaseId, appReleaseId)
      );
      const module = preferWorkspaceAppModule.current;
      const body = {
        tenantMstId: tenantMst.tenantMstId,
        appId,
        appNm: app.appNm,
        appGroupCd: app.appGroupCd,
        moduleCd,
        moduleNm: module.moduleNm,
        moduleOption: JSON.parse(module.moduleOption),
        appReleaseId,
        version: release.version,
        tenantId,
        tenantNm: tenantMst.tenantNm,
        coCd,
        coNm: tenantMst.coNm,
      };
      if (generalConfig.preferWorkspaceList.length + 1 > 10) {
        return Message.alert(
          "Up to 10 can be registered.",
          Enums.MessageType.INFO
        );
      } else if (
        generalConfig.preferWorkspaceList.findIndex((w) =>
          StringUtils.equalsIgnoreType(w.tenantMstId, body.tenantMstId)
        ) > -1
      ) {
        return Message.alert(
          "This Workspace is already Workspace.",
          Enums.MessageType.INFO
        );
      } else {
        const newBody = {
          ...generalConfig,
          preferWorkspaceList: [...generalConfig.preferWorkspaceList, body],
        };
        setGeneralConfig(newBody);
        onSaveGeneralConfig(newBody);
      }
    } else {
      return Message.alert(
        "There are unselected values. Please check the details.",
        Enums.MessageType.INFO
      );
    }
  };

  const onSaveGeneralConfig = (body) => {
    const serviceBody = {
      configurationCode: Enums.ConfigurationDefine.GENERAL,
      configurationValue: JSON.stringify(body),
    };
    SettingService.saveUserConfig(serviceBody, (res) => {
      const key = res.data.configurationCode;
      const value = JSON.parse(res.data.configurationValue);
      User.overrideConfig(key, value);
    });
  };

  const onDeletePreferWorkspace = (e, workspace) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    const newList = [...generalConfig.preferWorkspaceList];
    const deleteIndex = newList.findIndex((w) =>
      StringUtils.equalsIgnoreType(w.tenantMstId, workspace.tenantMstId)
    );
    newList.splice(deleteIndex, 1);
    const newBody = {
      ...generalConfig,
      preferWorkspaceList: newList,
    };
    setGeneralConfig(newBody);
    onSaveGeneralConfig(newBody);
  };

  return (
    <>
      <div className="setting-header">General Settings</div>
      <div className="setting-container" style={{ height: "100%" }}>
        <AppConfig>
          <AppConfigName>Frequently used Workspace settings</AppConfigName>
          <AppConfigDesc>
            <AppDesc>
              This feature allows you to set the Favorite workspace that will be
              displayed when there is no workspace history.
            </AppDesc>
            <AppConfigSwitch>
              <BootstrapSwitchButton
                checked={preferWorkspaceYn}
                onChange={setPreferWorkspaceYn}
              />
            </AppConfigSwitch>
          </AppConfigDesc>

          <AppConfigDesc>
            <Grid container spacing={2}>
              <Grid
                item
                xs={1}
                style={{ display: "flex", alignItems: "center" }}
              >
                Path Setting
              </Grid>
              <Grid item xs={2}>
                <FormControl fullWidth size="small">
                  <InputLabel id="applabel">Application</InputLabel>
                  <Select
                    label="Application"
                    labelId="applabel"
                    id="appId"
                    name="appId"
                    size="small"
                    value={appId}
                    onChange={onChangeAppId}
                  >
                    {application.availableList.map((app) => {
                      return (
                        <MenuItem value={app.appId} key={app.appId}>
                          {app.appNm}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={2}>
                <ModuleSelector
                  value={moduleCd || ""}
                  onChange={onChangeModule}
                  type="m"
                  id={"moduleId"}
                  name="moduleId"
                  showEmpty
                  disabled={!appId}
                  appId={appId}
                  placeholder={appId ? "Select Module" : "Select Application."}
                />
              </Grid>
              <Grid item xs={2}>
                <FormControl fullWidth size="small">
                  <InputLabel id="versionlabel">Version</InputLabel>
                  <Select
                    disabled={!moduleCd}
                    labelId={"versionlabel"}
                    label="Version"
                    value={appReleaseId}
                    id="appReleaseId"
                    name="appReleaseId"
                    onChange={onVersionChange}
                  >
                    <MenuItem value={""}>
                      {releaseList.length > 0
                        ? "Select"
                        : "No Registed Version"}
                    </MenuItem>
                    {releaseList.map((release) => {
                      return (
                        <MenuItem
                          value={release.appReleaseId}
                          key={release.appReleaseId}
                        >
                          {release.version}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={2}>
                <FormControl fullWidth size="small">
                  <InputLabel id="tenantlabel">Tenant</InputLabel>
                  <Select
                    disabled={!appReleaseId}
                    label="Tenant"
                    labelId="tenantlabel"
                    name="tenantId"
                    value={tenantId}
                    onChange={onChangeTenant}
                  >
                    <MenuItem value="">Tenant</MenuItem>
                    {tenantList.map((tenant) => {
                      return (
                        <MenuItem value={tenant.tenantId} key={tenant.tenantId}>
                          {tenant.tenantId}({tenant.tenantNm})
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={2}>
                <FormControl fullWidth size="small">
                  <InputLabel id="coCdlabel"> Company Code</InputLabel>
                  <Select
                    labelId="coCdlabel"
                    label="Company Code"
                    value={coCd}
                    disabled={
                      !tenantId || StringUtils.equalsIgnoreType(tenantId, "*")
                    }
                    onChange={(e) => setCoCd(e.target.value)}
                  >
                    <MenuItem value="">Company Code</MenuItem>
                    {companyList.map((co) => {
                      return (
                        <MenuItem value={co.coCd} key={co.coCd}>
                          {co.coCd}({co.coNm})
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid
                item
                xs={1}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <Button onClick={onAddPreferWorkspace}>Add</Button>
              </Grid>
            </Grid>
          </AppConfigDesc>
          {/* generalConfig.preferWorkspaceList */}
          {generalConfig.preferWorkspaceList.map((ws, index) => {
            return (
              <AppConfigDesc hover={true}>
                <AppDesc>
                  {index + 1} . {AppService.getWorkspaceName(ws)}
                </AppDesc>
                <AppConfigSwitch>
                  <Button
                    size="sm"
                    variant="outline-danger"
                    onClick={(e) => onDeletePreferWorkspace(e, ws)}
                  >
                    <MdDelete />
                  </Button>
                </AppConfigSwitch>
              </AppConfigDesc>
            );
          })}
        </AppConfig>
        <AppConfig>
          <AppConfigName>Default Workspace </AppConfigName>
          <AppConfigDesc>
            <AppDesc>
              {defaultWorkspace
                ? defaultWorkspace
                : "There is no default Workspace."}
            </AppDesc>
            <AppConfigSwitch>
              {defaultWorkspace && (
                <Button
                  variant="outline-danger"
                  onClick={onClickDefaultWorkspace}
                  size="sm"
                >
                  <MdDelete />
                </Button>
              )}
            </AppConfigSwitch>
          </AppConfigDesc>
        </AppConfig>
      </div>
    </>
  );
}

export default General;
