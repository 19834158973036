import React, { useRef } from "react";
import { useDrag } from "react-dnd";
import produce from "immer";

import UIEditorChildRender from "components/builder/ui/editor/UIEditorChildRender";
import * as Enums from "components/builder/BuilderEnum";
import UIEditorDNDContainer from "components/builder/ui/editor/UIEditorDNDContainer";
import { ArrayUtils } from "components/common/utils/CommonUtils";

import Form from "components/builder/ui/uiComponents/form/Form";

const style = {};
const UIEditorForm = (props) => {
  const { data, handleDrop, path, templateComponents, rootLocation } = props;
  const ref = useRef(null);

  const item = produce(data, (draft) => {
    draft.path = path;
    draft.rootLocation = rootLocation;
  });
  const [{ isDragging }, drag] = useDrag({
    item: item,
    type: item.type,
    componentType: item.componentType,
    //canDrag: canDrag
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0 : 1;
  drag(ref);

  const DNDContainer = props.dndContainer
    ? props.dndContainer
    : UIEditorDNDContainer;

  return (
    <div ref={ref} className="whole-wrap">
      <Form
        style={{ ...style, opacity }}
        componentInfo={item}
        event="renderEditor"
      >
        {/* editor-flex-column : display:flex, direction:colunmn ==> 상-하 row 추가 */}
        <div className="editor-flex-column pass-focus">
          {!ArrayUtils.isEmpty(item.child)
            ? item.child.map((child, index) => {
                const currentPath = `${path}-${index}`;
                return (
                  <React.Fragment key={index}>
                    <DNDContainer
                      data={{
                        location: Enums.ComponentType.FORM,
                        rootLocation: rootLocation,
                        path: currentPath,
                        childrenCount: item.child.length,
                        //Data Studio Element에서 부모격 되는 component의 성질을 파악해서 컴포넌트 추가/수정이 되기 때문에 부모 격되는 컴포넌트의 정보를 넘겨줌
                        propertyValue: data.propertyValue,
                        compId: data.compId,
                      }}
                      onDrop={handleDrop}
                      templateComponents={templateComponents}
                      className="horizontal-drag"
                    />
                    <UIEditorChildRender
                      key={child.compId}
                      data={child}
                      handleDrop={handleDrop}
                      templateComponents={templateComponents}
                      path={currentPath}
                      dndContainer={DNDContainer}
                      rootLocation={rootLocation}
                    />
                  </React.Fragment>
                );
              })
            : ""}
          <DNDContainer
            data={{
              location: Enums.ComponentType.FORM,
              rootLocation: rootLocation,
              path: `${path}-${
                ArrayUtils.isEmpty(item.child) ? 0 : item.child.length
              }`,
              childrenCount: ArrayUtils.isEmpty(item.child)
                ? 0
                : item.child.length,
              propertyValue: data.propertyValue,
            }}
            onDrop={handleDrop}
            templateComponents={templateComponents}
            className={`horizontal-drag ${
              ArrayUtils.isEmpty(item.child) ? "horizontal-drag-full" : ""
            }`}
          />
        </div>
      </Form>
    </div>
  );
};

export default React.memo(UIEditorForm);
