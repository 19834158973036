import React, { useRef } from "react";
import { useDrag } from "react-dnd";
import produce from "immer";

import UIEditorChildRender from "components/builder/ui/editor/UIEditorChildRender";
import UIEditorDNDContainer from "components/builder/ui/editor/UIEditorDNDContainer";
import * as Enums from "components/builder/BuilderEnum";
import { ArrayUtils } from "components/common/utils/CommonUtils";

import Row from "components/builder/ui/uiComponents/layout/Row";

const style = {};
const UIEditorRow = (props) => {
  const { data, handleDrop, path, templateComponents, rootLocation } = props;
  const ref = useRef(null);

  const item = produce(data, (draft) => {
    draft.path = path;
    draft.rootLocation = rootLocation;
  });
  const [{ isDragging }, drag] = useDrag({
    item: item,
    type: item.type,
    componentType: item.componentType,
    //canDrag: canDrag
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0 : 1;
  drag(ref);

  const DNDContainer = props.dndContainer
    ? props.dndContainer
    : UIEditorDNDContainer;

  return (
    <div ref={ref} className="whole-wrap a">
      <Row
        style={{ ...style, opacity }}
        componentInfo={item}
        event="renderEditor"
      >
        <div className="editor-columns pass-focus">
          {!ArrayUtils.isEmpty(item.child)
            ? item.child.map((column, index) => {
                const currentPath = `${path}-${index}`;
                return (
                  <React.Fragment key={index}>
                    {index === 0 ? (
                      <DNDContainer
                        data={{
                          location: Enums.ComponentType.ROW,
                          rootLocation: rootLocation,
                          path: currentPath,
                          childrenCount: item.child.length,
                          propertyValue: data.propertyValue,
                          compId: data.compId,
                        }}
                        onDrop={handleDrop}
                        templateComponents={templateComponents}
                        className="horizontal-drag hide"
                      />
                    ) : (
                      ""
                    )}
                    <UIEditorChildRender
                      key={column.compId}
                      data={column}
                      handleDrop={handleDrop}
                      templateComponents={templateComponents}
                      path={currentPath}
                      dndContainer={DNDContainer}
                      rootLocation={rootLocation}
                      isLast={item.child.length === index + 1}
                    />
                  </React.Fragment>
                );
              })
            : ""}
          <DNDContainer
            data={{
              location: Enums.ComponentType.ROW,
              rootLocation: rootLocation,
              path: `${path}-${
                ArrayUtils.isEmpty(item.child) ? 0 : item.child.length
              }`,
              childrenCount: ArrayUtils.isEmpty(item.child)
                ? 0
                : item.child.length,
              propertyValue: data.propertyValue,
              compId: data.compId,
            }}
            onDrop={handleDrop}
            templateComponents={templateComponents}
            className={`horizontal-drag ${
              ArrayUtils.isEmpty(item.child) ? "horizontal-drag-full" : "hide"
            }`}
          />
        </div>
      </Row>
    </div>
  );
};
export default React.memo(UIEditorRow);
