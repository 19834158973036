import ActionType from "components/builder/BuilderActionType";
import produce from "immer";

const initialState = {
  isMobileEditor: true,
};

export default function MobileReducer(state = initialState, action) {
  switch (action.type) {
    case ActionType.MOBILE.IS_MOBILE_EDITOR:
      return produce(state, (draft) => {
        draft.isMobileEditor = action.payload;
      });
    case ActionType.MOBILE.SCREEN_WIDTH:
      return produce(state, (draft) => {
        draft.screenWidth = action.payload;
      });
    case ActionType.MOBILE.SCREEN_HEIGHT:
      return produce(state, (draft) => {
        draft.screenHeight = action.payload;
      });
    case ActionType.MOBILE.SET_APP_LAYOUT:
      return produce(state, (draft) => {
        draft.appLayout = action.payload;
      });
    default:
      return state;
  }
}
