import { InputLabel, Select, TextField } from "@mui/material";
import { Enums } from "components/builder/BuilderEnum";
import BuilderHeaderComponent from "components/builder/BuilderHeaderComponent";
import { AppContext } from "components/common/AppContextProvider";
import MSelectbox from "components/common/element/MSelectbox";
import WijmoGrid from "components/common/element/WijmoGrid";
import CommonUtils, { ArrayUtils } from "components/common/utils/CommonUtils";
import ObjectUtils from "components/common/utils/ObjectUtils";
import produce from "immer";
import PageTemplate from "page/common/PageTemplate";
import { useContext, useEffect, useRef, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { BsSearch } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import CodeService from "services/common/CodeService";
import EventhandlerService from "services/eventhandler/EventhandlerService";

/**
 * 프로그램 목록
 * @param {*} param0
 * @returns
 */
function EventHandlerList({ ...props }) {
  const workspace = useSelector((state) => state.workspace);
  const [programTypeList, setProgramTypeList] = useState([]);
  const [searchTerm, setSearchTerm] = useState({
    eventHandlerNm: "",
    programType: null,
    targetType: "",
    eventType: "",
    useYn: "Y",
  });
  const [fncList, setFncList] = useState([]);

  const dispatch = useDispatch();

  const targetTypeList = useRef([
    { id: "", text: "All" },
    { id: "all", text: "Common" },
    { id: "form", text: "Form" },
    { id: "grid", text: "Grid" },
  ]);
  const eventTypeList = useRef([
    { id: "", text: "Select" },
    { id: "before", text: "Pre-processing" },
    { id: "after", text: "Post-processing" },
    { id: "userFnc", text: "User Function" },
    { id: "userWrapFnc", text: "User Function Rendering" },
    { id: "load", text: "Load" },
  ]);
  const {
    workspace: { openPopup },
    component,
  } = useContext(AppContext);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (ArrayUtils.isEmpty(programTypeList)) {
      const comboParams = { codeMstCd: "Z0028" };
      CodeService.getCodeCombo(comboParams, (res) => {
        if (res) {
          setProgramTypeList(res.data);
        }
        onSearch();
      });
    }
  }, []);

  const breadcrum = [
    {
      name: "Event List",
      url: Enums.BuilderPath.EVENT_HANDLER.LIST,
      active: true,
    },
  ];

  /**
   * 이벤트 체인져
   * @param {*} e
   */
  const onChangeValue = (e) => {
    setSearchTerm(
      produce(searchTerm, (draft) => {
        draft[e.target.id || e.target.name] = e.target.value;
      })
    );
  };

  /**
   * 엔터키 검색
   * @param {*} e
   */
  const onKeyDown = (e) => {
    if (e.keyCode === 13) {
      onSearch();
    }
  };

  /**
   * 목록 호출
   */
  const onSearch = () => {
    setIsLoading(true);
    EventhandlerService.getFncCustomList(
      { ...workspace, ...searchTerm },
      (res) => {
        setFncList(res.data);
        setIsLoading(false);
      },
      () => {
        setIsLoading(false);
      }
    );
  };

  const columns = [
    {
      field: "tenantId",
      headerName: "Tenant Id",
      width: 130,
    },
    {
      field: "coCd",
      headerName: "Company Code",
      width: 130,
    },
    {
      field: "eventHandleNm",
      headerName: "Event Name",
      width: "*",
    },
    {
      field: "eventHandleDesc",
      headerName: "Event Description",
      width: "*",
    },
    {
      field: "programType",
      headerName: "Program Type",
      width: 200,
      renderCell: (param) => {
        if (param.programType === "A") {
          return "Common";
        } else {
          let t = programTypeList.find((p) => p.id === param.programType);
          return t.text;
        }
      },
    },
    {
      field: "targetType",
      headerName: "Target Type",
      width: 200,
      renderCell: (param) => {
        const t = targetTypeList.current.find((t) => t.id === param.targetType);
        return t.text;
      },
    },
    {
      field: "eventType",
      headerName: "Event Type",
      width: 200,
      renderCell: (param) => {
        const e = eventTypeList.current.find((e) => e.id === param.eventType);
        return e.text;
      },
    },
    {
      field: "updtUserId",
      headerName: "Update User",
      width: 200,
    },
    {
      field: "updtDt",
      headerName: "Update Date",
      width: 200,
      renderCell: (param) => {
        return `${CommonUtils.getDate(param.updtDt, "dateTime")}`;
      },
    },
  ];

  return (
    <>
      <BuilderHeaderComponent {...props.header} />
      <PageTemplate breadcrum={breadcrum}>
        <PageTemplate.Box boxClass="mb-0">
          {ObjectUtils.isEmpty(workspace) ? (
            <div className="workspace-empty-alert">
              <div className="alert-msg">
                Workspace configuration is required.
              </div>
              <Button onClick={() => openPopup()}>Open Popup</Button>
            </div>
          ) : (
            <>
              <Form>
                <Row className="pb-3 pt-3">
                  <Col xs={2}>
                    <TextField
                      size={"small"}
                      label="Event Name"
                      placeholder="Search Event Name..."
                      id="eventHandlerNm"
                      value={searchTerm.eventHandlerNm}
                      fullWidth={true}
                      onChange={onChangeValue}
                      onKeyDown={onKeyDown}
                    />
                  </Col>
                  <Col xs={2}>
                    <MSelectbox
                      size="small"
                      fullWidth={true}
                      data={[
                        { id: null, text: "All" },
                        { id: "A", text: "Common" },
                        ...programTypeList,
                      ]}
                      options={{ matchCd: "id", matchNm: "text" }}
                    >
                      <InputLabel id="module-label">Program Type</InputLabel>
                      <Select
                        name="programType"
                        labelId="module-label"
                        label="Program Type"
                        fullWidth
                        color="primary"
                        value={searchTerm.programType}
                        onChange={onChangeValue}
                      ></Select>
                    </MSelectbox>
                  </Col>
                  <Col xs={2}>
                    <MSelectbox
                      size="small"
                      fullWidth={true}
                      data={targetTypeList.current}
                    >
                      <InputLabel id="module-label">Target Type</InputLabel>
                      <Select
                        name="targetType"
                        labelId="module-label"
                        label="Event Type"
                        fullWidth
                        id={"targetType"}
                        color="primary"
                        value={searchTerm.targetType}
                        onChange={onChangeValue}
                      ></Select>
                    </MSelectbox>
                  </Col>
                  <Col xs={2}>
                    <MSelectbox
                      size="small"
                      fullWidth={true}
                      data={eventTypeList.current}
                    >
                      <InputLabel id="module-label">Event Type</InputLabel>
                      <Select
                        name="eventType"
                        labelId="module-label"
                        label="Event Type"
                        fullWidth
                        id={"eventType"}
                        color="primary"
                        value={searchTerm.eventType}
                        onChange={onChangeValue}
                      ></Select>
                    </MSelectbox>
                  </Col>

                  <Col xs={1}>
                    <Button variant="primary" onClick={onSearch}>
                      Search <BsSearch size="14" />
                    </Button>
                  </Col>
                </Row>
              </Form>
              <Row style={{ height: "calc(100% - 70px)" }}>
                <Col xs={12}>
                  <WijmoGrid
                    isLoading={isLoading}
                    columns={columns}
                    rows={fncList}
                    // onRowDoubleClick={(params) => onSelectProgram(params)}
                    getRowId={(row) => row.programUid}
                    style={{ height: "70vh" }}
                  />
                </Col>
              </Row>
            </>
          )}
        </PageTemplate.Box>
      </PageTemplate>
    </>
  );
}

export default EventHandlerList;
