import WorkflowBuilder from "components/builder/workflow/WorkflowBuilder";
import BundleDetailMain from "components/builder/workflow/components/BundleDetailMain";
import "css/builder/workflow.css";
import { ReactFlowProvider } from "reactflow";

function WorkflowMain() {
  return (
    <>
      <ReactFlowProvider>
        <WorkflowBuilder />
      </ReactFlowProvider>
      <BundleDetailMain />
    </>
  );
}

export default WorkflowMain;
