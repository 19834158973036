import Blockly from "blockly";

Blockly.FieldDropdown.prototype.doClassValidation_ = function (newValue) {
  const options = this.getOptions(true);
  const isValueValid = options.some((option) => option[1] === newValue);

  if (!isValueValid) {
    if (this.sourceBlock_) {
      let showValue = newValue;
      if (this.name === "dataEntityCombo") {
        showValue = newValue.split(",")[0];
      }
      options.push([showValue, newValue]);
      this.menuGenerator_ = options;
      //   console.warn(
      //     "Cannot set the dropdown's value to an unavailable option." +
      //       " Block type: " +
      //       this.sourceBlock_.type +
      //       ", Field name: " +
      //       this.name +
      //       ", Value: " +
      //       newValue
      //   );
      return newValue;
    }
    return null;
  }
  return newValue;
};

// /** Create the dropdown editor. */
Blockly.FieldDropdown.prototype.dropdownCreate = function () {
  const block = this.getSourceBlock();
  if (!block) {
    throw new Blockly.UnattachedFieldError();
  }
  const menu = new Blockly.Menu();
  menu.setRole(Blockly.utils.aria.Role.LISTBOX);
  this.menu_ = menu;

  if (
    this.name === "gridColumnCombo" ||
    this.name === "comboIdCombo" ||
    this.name === "btnCombo" ||
    this.name === "componentCombo" ||
    this.name === "gridBtnCombo"
  ) {
    // drop down 메뉴에 input 태그 추가
    const div = document.createElement("div");
    const input = document.createElement("input");
    input.style.height = "25px";
    input.style.width = "100px";
    input.setAttribute("placeholder", "Direct Input");

    // drop down 메뉴에 추가 버튼 생성
    const button = document.createElement("button");
    button.style.height = "25px";
    button.style.width = "25px";
    makeButtonIcon(button);

    div.appendChild(input);
    div.appendChild(button);

    const menuItem = new Blockly.MenuItem(div);
    menuItem.setRole(Blockly.utils.aria.Role.OPTION);
    menuItem.setRightToLeft(block.RTL);
    menu.addChild(menuItem);

    const dropdown = this;

    button.addEventListener("click", function () {
      Blockly.DropDownDiv.hideIfOwner(dropdown, true);
      menuItem.opt_value = input.value;

      if (input.value !== "") {
        // 직접 입력으로 생성한 menu item을 dropdown에 저장
        dropdown.createOption = [[input.value, input.value]];

        // drop down option에 직접 입력으로 생성한 menu item 추가 및 선택
        const dropdownOptions = [...dropdown.getOptions()];
        dropdownOptions.push([input.value, input.value]);
        dropdown.menuGenerator_ = dropdownOptions;
        dropdown.onItemSelected_(dropdown.menu, menuItem);
        if (dropdown.getSourceBlock().updateBlockDropdownContext) {
          dropdown
            .getSourceBlock()
            .updateBlockDropdownContext(
              [[input.value, input.value]],
              dropdown.name
            );
        }
      }
    });
  }

  const options = this.getOptions(false);

  this.selectedMenuItem = null;
  for (let i = 0; i < options.length; i++) {
    const [label, value] = options[i];
    const content = (() => {
      if (typeof label === "object") {
        // Convert ImageProperties to an HTMLImageElement.
        const image = new Image(label["width"], label["height"]);
        image.src = label["src"];
        image.alt = label["alt"] || "";
        return image;
      }
      return label;
    })();
    const menuItem = new Blockly.MenuItem(content, value);
    menuItem.setRole(Blockly.utils.aria.Role.OPTION);
    menuItem.setRightToLeft(block.RTL);
    menuItem.setCheckable(true);
    menu.addChild(menuItem);
    menuItem.setChecked(value === this.value_);
    if (value === this.value_) {
      this.selectedMenuItem = menuItem;
    }
    menuItem.onAction(this.handleMenuActionEvent, this);
  }
};

// button icon 만들기
const makeButtonIcon = (button) => {
  const svg = Blockly.utils.dom.createSvgElement(
    Blockly.utils.Svg.SVG,
    {
      width: "12.5px",
      height: "12.5px",
      xmlns: "http://www.w3.org/2000/svg",
      viewBox: "3 3 24 24",
      fill: "none",
    },
    button
  );

  Blockly.utils.dom.createSvgElement(
    Blockly.utils.Svg.PATH,
    {
      d: "M11.25 12.75V18H12.75V12.75H18V11.25H12.75V6H11.25V11.25H6V12.75H11.25Z",
      fill: "#1C274C",
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
    },
    svg
  );

  return svg;
};
