import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Tooltip,
} from "@mui/material";
import { Enums } from "components/builder/BuilderEnum";
import { AppContext } from "components/common/AppContextProvider";
import Message from "components/common/Message";
import Popup from "components/common/Popup";
import CommonUtils, { ArrayUtils } from "components/common/utils/CommonUtils";
import ObjectUtils from "components/common/utils/ObjectUtils";
import StringUtils from "components/common/utils/StringUtils";
import User from "components/common/utils/UserUtils";
import PageTemplate from "page/common/PageTemplate";
import FieldComparePopup from "page/popup/FieldComparePopup";
import React, { useContext, useEffect, useState } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import {
  AiFillFolder,
  AiFillFolderOpen,
  AiOutlineCheckCircle,
  AiOutlineExclamationCircle,
  AiOutlineFileDone,
} from "react-icons/ai";
import { FaArrowAltCircleRight } from "react-icons/fa";
import { MdExpandMore } from "react-icons/md";
import { useSelector } from "react-redux";
import DataModelService from "services/datamodel/DataModelService";
import WijmoGrid from "components/common/element/WijmoGrid";
import { SelectionMode } from "@grapecity/wijmo.grid";

const breadcrum = [
  {
    name: "Entity Batch Change",
    url: Enums.BuilderPath.ENTITY.MAIN + "/" + Enums.BuilderPath.ENTITY.PATCH,
    active: true,
  },
];

function EntityPatchMain() {
  const workspace = useSelector((state) => state.workspace);
  const {
    workspace: { openPopup },
    connection: { Info, setInfo, list, openPopup: openConnectionPopup },
  } = useContext(AppContext);
  const [isConnected, setIsConnected] = useState(false);
  const [expanded, setExpanded] = useState("step1");

  const [isLoading, setIsLoading] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [dataModelList, setDataModelList] = useState([]);
  const [DPTableList, setDPTableList] = useState([]);
  const [selectedEntity, setSelectedEntity] = useState({});
  const [patchingDataModelList, setPatchingDataModelList] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [checkedDataModel, setCheckedDataModel] = useState([]);

  const [isOverwriting, setIsOverwriting] = useState(false);
  const [isMerging, setIsMerging] = useState(false);
  const [isRelationKeep, setIsRelationKeep] = useState(true);

  useEffect(() => {
    if (ObjectUtils.isEmpty(Info)) {
      setIsConnected(false);
      setExpanded("step1");
    } else {
      setIsConnected(true);
      setExpanded("step2");
      if (ArrayUtils.isEmpty(dataModelList)) onLoadEntity();
    }
  }, [Info]);

  const onConnection = (e) => {
    //팝업창 열기
    openConnectionPopup();
  };

  /**
   * 엔티티 목록 호출
   * DataModel에서 쓰이는 것과 동일한 방식
   * @returns
   */
  const onLoadEntity = () => {
    const connection = User.getConnection(workspace.tenantMstId);
    if (connection?.expired)
      return Message.alert("Token Expired.", Enums.MessageType.ERROR);
    if (!connection?.token) {
      return Message.alert(
        "Authentication server token is not validated.\n Please reconnect.",
        Enums.MessageType.ERROR
      );
    }
    setIsLoading(true);
    const _method = (callback, data) => {
      callback(
        data,
        (res) => {
          if (!res.isError) {
            const tableList = res.data.filter(
              (_en) =>
                StringUtils.equalsIgnoreCase(_en.entityType, "TABLE") ||
                StringUtils.equalsIgnoreCase(_en.entityType, "VIEW") ||
                StringUtils.equalsIgnoreCase(_en.entityType, "SYNONYM")
            );
            setDPTableList(tableList || []);
            setDataModelList(tableList || []);
            setIsLoading(false);
          }
        },
        (err) => {
          setIsLoading(false);
        }
      );
    };

    if (connection.connectionType === "direct") {
      const body = {
        connectionInfo: connection,
        moduleCd: workspace.moduleCd,
        searchModule: JSON.stringify(workspace.moduleOption),
        coCd: workspace.coCd,
        tenantId: workspace.tenantId,
      };
      _method(DataModelService.getDirectTableList, body);
    } else {
      const body = {
        accessToken: connection.token,
        ...connection,
        moduleCd: workspace.moduleCd,
        appId: workspace.appId,
        refresh: "Y",
      };
      _method(DataModelService.getTableList, body);
    }
  };

  /**
   * 엔티티 클릭
   * @param {*} e
   * @param {*} entity
   */
  const onSelectEntity = (e, entity) => {
    if (e) e.preventDefault();
    if (
      !ObjectUtils.isEmpty(selectedEntity) &&
      selectedEntity.tableNm === entity.tableNm
    ) {
      setSelectedEntity({});
    } else {
      //필드 목록 가져옴
      const connection = User.getConnection(workspace.tenantMstId);
      setIsDataLoading(true);

      const _method = (callback, data) => {
        callback(
          data,
          (res) => {
            entity.dataModelEntityFields = res.data.dataModelEntityFields;
            entity.relation = res.data.relation;
            setSelectedEntity(entity);
            onLoadDataModel(entity);
            setIsDataLoading(false);
          },
          () => {
            setIsDataLoading(false);
            Message.alert(
              "An error occurred while loading fields for the patch.",
              Enums.MessageType.WARN
            );
          }
        );
      };

      const body = {
        tenantId: Info.tenantId,
        coCd: Info.coCd,
        moduleCd: Info.moduleCd,
        tableName: entity.tableNm,
        baseDbName: entity.baseDbName,
        entityType: entity.entityType,
      };
      if (connection.connectionType === "direct") {
        body.connectionInfo = connection;
        _method(DataModelService.getDirectDataModelFieldListOnly, body);
      } else {
        body.accessToken = connection.token;
        body.host = connection.host;
        body.protocol = connection.protocol;
        body.tableNm = entity.tableNm;
        _method(DataModelService.getDataModelFieldListOnly, body);
      }
    }
  };

  const columns = [
    {
      field: "dataModelNm",
      headerName: "Data Model Name",
      width: 150,
      headerAlign: "center",
    },
    {
      field: "description",
      headerName: "Description",
      width: 150,
      headerAlign: "center",
    },
    {
      field: "updtUserId",
      headerName: "Update User",
      width: 150,
      headerAlign: "center",
    },
    {
      field: "updtDt",
      headerName: "Update Date",
      width: 150,
      headerAlign: "center",
      renderCell: (param) => CommonUtils.getDate(param.updtDt, "datetime"),
    },
    {
      field: "fieldCompare",
      headerName: "Compare",
      width: 100,
      headerAlign: "center",
      align: "center",
      renderCell: (param) => {
        return (
          <Button
            size="sm"
            variant="outline-success"
            onClick={(e) => onCompareField(e, param)}
          >
            Compare
          </Button>
        );
      },
    },
    {
      field: "remark",
      headerName: "Remarks",
      width: "*",
      headerAlign: "center",
      align: "center",
      renderCell: (param) => {
        const thisEntity = param.dataModelEntities.find(
          (e) => e.tableNm === selectedEntity.tableNm
        );
        if (thisEntity) {
          const virtualColumns = thisEntity.dataModelEntityFields.filter(
            (f) => f.virtualYn === "Y"
          );

          if (ArrayUtils.isEmpty(virtualColumns)) {
            //가상 칼럼 없음
            return <></>;
          } else {
            //가상 칼럼 있음
            return <>Recommend Merge</>;
          }
        }
      },
    },
    {
      field: "action",
      headerName: "Select",
      width: 150,
      headerAlign: "center",
      renderCell: (param) => {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              width: "100%",
            }}
          >
            <Button
              size="sm"
              onClick={(e) => onSingleOverwrite(e, param, isRelationKeep)}
              variant="outline-primary"
              disabled={isOverwriting}
            >
              Overwrite
            </Button>
            <Button
              size="sm"
              onClick={(e) => onSingleMerge(e, param, isRelationKeep)}
              disabled={isMerging}
            >
              Merge
            </Button>
          </div>
        );
      },
    },
  ];

  const onSingleOverwrite = (e, _dataModel, _relationKeep) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    let msg = "";
    if (!_relationKeep)
      msg +=
        "The existing relations will be reset, and the entity settings and fields will be overwritten.\n Do you want to proceed?";
    Message.confirm(msg, () => overwriteEntity([_dataModel], _relationKeep));
  };
  const onSingleMerge = (e, _dataModel, _relationKeep) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    let msg = "";
    if (!_relationKeep) msg += "The existing relations will be reset,";
    msg += "and fields will be merged.\n Do you want to proceed?";
    Message.confirm(msg, () => mergeEntity([_dataModel], _relationKeep));
  };

  /**
   * 선택된 엔티티를 사용하는 데이터 모델목록 호출
   * @param {*} _entity
   */
  const onLoadDataModel = (_entity) => {
    const body = {
      ...workspace,
      tableNm: _entity.tableNm || selectedEntity.tableNm,
    };
    setIsDataLoading(true);
    DataModelService.getDataModelListByEntityNm(
      body,
      (res) => {
        setPatchingDataModelList(res.data);
        setIsDataLoading(false);
      },
      () => {
        setIsDataLoading(false);
      }
    );
  };

  const onChangeSearchInput = (e) => {
    const value = e.currentTarget.value;
    setSearchInput(value);
    const _dataModel = [...dataModelList];
    const filtered = _dataModel.filter((d) =>
      String(d.tableNm).toLowerCase().includes(String(value).toLowerCase())
    );
    setDPTableList(filtered);
  };

  /**
   * 그리드에서 선택된 로우의 데이터 모델 반환
   * @returns
   */
  const getTargetDataModel = () => {
    const targetDataModel = [];
    for (const dm of patchingDataModelList) {
      if (
        checkedDataModel.findIndex(
          (dataModelId) => dataModelId === dm.dataModelId
        ) > -1
      ) {
        targetDataModel.push(dm);
      }
    }
    return targetDataModel;
  };
  const getTargetEntity = (_dataModel) => {
    const entityList = [];
    for (const dm of _dataModel) {
      const targetEntity = dm.dataModelEntities.find(
        (_entity) => _entity.tableNm === selectedEntity.tableNm
      );
      entityList.push(targetEntity);
    }

    return entityList;
  };

  /**
   * 변환한 엔티티 저장 로직
   * @param {Array} entityList
   * @param {Function} setPatching
   */
  const updateEntityList = (entityList, setPatching) => {
    DataModelService.updateDataModelEntities(
      { entityList },
      (res) => {
        Message.alert(res.message, Enums.MessageType.SUCCESS);
        setPatching(false);
        onLoadDataModel(selectedEntity);
      },
      () => {
        setPatching(false);
      }
    );
  };

  /**
   * 덮어쓰기 로직
   * 단일 실행은 배열에 데이터모델을 1개만 넣어서 수행
   * @param {*} _dataModels
   * @param {*} _relationKeep
   */
  const overwriteEntity = (_dataModels, _relationKeep) => {
    setIsOverwriting(true);
    const entityList = getTargetEntity(_dataModels);
    // 2-1 해당 엔티티에 데이터 덮어쓰기
    // * 포지션, entityId는 보존
    const patchedEntityList = [];
    for (const entity of entityList) {
      const patchedEntity = {
        ...entity,
        dataModelEntityFields: [...selectedEntity.dataModelEntityFields],
        position: entity.position,
        entityId: entity.entityId,
      };
      if (_relationKeep) patchedEntity.relation = entity.relation;
      else patchedEntity.relation = JSON.stringify([]);

      patchedEntityList.push(patchedEntity);
    }
    // 3. 해당 엔티티포괄 저장
    updateEntityList(patchedEntityList, setIsOverwriting);
  };

  /**
   * 병합 로직
   * 단일 실행은 배열에 데이터모델을 1개만 넣어서 수행
   * @param {*} _dataModels
   * @param {*} _relationKeep
   */
  const mergeEntity = (_dataModels, _relationKeep) => {
    //2. 데이터 모델에서 엔티티 추출
    setIsMerging(true);
    const entityList = getTargetEntity(_dataModels);
    // 2-1 엔티티 순회
    // relation만 초기화
    const patchedEntityList = [];
    for (const entity of entityList) {
      //2-1-1 가상칼럼 별도 추출
      const virtualColumn = entity.dataModelEntityFields.filter(
        (field) => field.virtualYn === "Y"
      );

      const patchedEntity = {
        ...entity,
        position: entity.position,
        entityId: entity.entityId,
        dataModelEntityFields: [
          ...selectedEntity.dataModelEntityFields,
          ...virtualColumn,
        ],
      };
      if (_relationKeep) patchedEntity.relation = entity.relation;
      else patchedEntity.relation = JSON.stringify([]);

      patchedEntityList.push(patchedEntity);
    }
    // 3. 해당 엔티티포괄 저장
    updateEntityList(patchedEntityList, setIsMerging);
  };

  /**
   * 선택한 데이터 모델 덮어쓰기
   * @param {*} e
   */
  const onEntityListOverwrite = (e) => {
    if (e) e.preventDefault();
    if (checkedDataModel.length === 0)
      return Message.alert(
        "Data Model is not selected",
        Enums.MessageType.INFO
      );

    let msg = "";
    if (!isRelationKeep) msg += "The existing relations will be reset,";
    msg +=
      "and the entity settings and fields will be overwritten.\n Do you want to proceed?";

    Message.confirm(msg, () => {
      setIsOverwriting(true);
      // 1. 데이터 모델 추출
      const targetDataModel = getTargetDataModel();
      overwriteEntity(targetDataModel, isRelationKeep);
    });
  };

  /**
   * 선택한 데이터 모델 병합
   * @param {*} e
   */
  const onEntityListMerge = (e) => {
    if (e) e.preventDefault();
    if (checkedDataModel.length === 0)
      return Message.alert(
        "Data Model is not selected.",
        Enums.MessageType.INFO
      );
    let msg = "";
    if (!isRelationKeep) msg += "The existing relations will be reset, ";
    msg += "and fields will be merged.\n Do you want to proceed?";
    Message.confirm(msg, () => {
      // 1. 데이터 모델 추출
      const targetDataModel = getTargetDataModel();
      mergeEntity(targetDataModel, isRelationKeep);
    });
  };

  /**
   * 필드 비교하는 팝업 오픈
   * @param {*} e
   * @param {*} _dataModel
   */
  const onCompareField = (e, _dataModel) => {
    if (e) e.stopPropagation();
    const thisEntity = _dataModel.dataModelEntities.find((e) =>
      StringUtils.equalsIgnoreCase(e.tableNm, selectedEntity.tableNm)
    );
    if (thisEntity) {
      const options = {
        effect: Popup.ScaleUp, //Effect.SlideFromTop(default)를 Effect.ScaleUp 로 변경
        style: {
          content: {
            width: "40%", //popup의 크기를 50% (default 60%)
          },
        },
      };
      Popup.open(
        <FieldComparePopup
          entity={selectedEntity}
          targetEntity={thisEntity}
          dataModelNm={_dataModel.dataModelNm}
          onMerge={(e, _relationKeep) =>
            onSingleMerge(e, _dataModel, _relationKeep)
          }
          onOverwrite={(e, _relationKeep) =>
            onSingleOverwrite(e, _dataModel, _relationKeep)
          }
          relationKeep={isRelationKeep}
        />,
        options
      );
    }
  };

  return (
    <PageTemplate breadcrum={breadcrum}>
      <PageTemplate.Box boxClass="mb-0">
        {ObjectUtils.isEmpty(workspace) ? (
          <>
            <div className="workspace-empty-alert">
              <div className="alert-msg">
                Workspace configuration is required.
              </div>
              <Button onClick={() => openPopup()}>Open Popup</Button>
            </div>
          </>
        ) : (
          <div className="patch-wrapper">
            <Accordion expanded={expanded === "step1"}>
              <AccordionSummary
                aria-controls="panel1a-content"
                id="panel1a-header"
                expandIcon={<MdExpandMore />}
              >
                <div
                  className={`step-title step1 ${
                    isConnected ? "connected" : ""
                  }`}
                >
                  {isConnected ? (
                    <AiOutlineCheckCircle color="limegreen" size={20} />
                  ) : (
                    <AiOutlineExclamationCircle color="tomato" size={20} />
                  )}

                  <span>STEP 1 </span>
                  <span>Server Connection</span>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <div className="step1-desc">
                  <span>Please </span>
                  <Button onClick={onConnection}>Connect to Server</Button>
                  <span>
                    that has the entities (or tables) you wish to modify.
                  </span>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "step2"}
              disabled={expanded !== "step2"}
            >
              <AccordionSummary
                aria-controls="panel1a-content"
                id="panel1a-header"
                expandIcon={<MdExpandMore />}
              >
                <div className="step-title">
                  <AiOutlineFileDone size={20} color={"#0096cc"} />
                  <span>STEP 2</span>
                  <span>Dynamic Entity Selection</span>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <div className="step2-desc">
                  <div className="desc">
                    <div className="entity">
                      <div className="title">Sequence</div>
                      <div
                        className={`step _1 ${
                          ObjectUtils.isEmpty(selectedEntity) ? "" : "done"
                        }`}
                      >
                        1. Select Entity
                      </div>
                      <div
                        className={`step _2 ${
                          checkedDataModel.length > 0 ? "done" : ""
                        } `}
                      >
                        2. Select Data Model to Modify and Overwrite, Merge
                      </div>
                    </div>
                    <div className="arrow"></div>
                    <div className="data-model">
                      <div className="input">
                        <div>Selected Entity</div>
                        <div>
                          <strong>{selectedEntity.tableNm}</strong>
                        </div>
                      </div>
                      {!ObjectUtils.isEmpty(selectedEntity) && (
                        <div className="action">
                          <FormControlLabel
                            control={
                              <Checkbox
                                id={"relationKeep"}
                                checked={isRelationKeep}
                                onChange={(e) =>
                                  setIsRelationKeep(e.target.checked)
                                }
                                size="small"
                              />
                            }
                            label={"maintain Relation setting"}
                          />

                          <Tooltip
                            title={
                              <>
                                Reset and update the entity settings and all
                                fields.
                              </>
                            }
                            placement="top"
                          >
                            <span>
                              <Button
                                variant="outline-primary"
                                onClick={onEntityListOverwrite}
                                disabled={isOverwriting || isMerging}
                              >
                                {isOverwriting ? (
                                  <>
                                    <CircularProgress
                                      color="inherit"
                                      size={13}
                                    />
                                    Entity Overwritting...
                                  </>
                                ) : (
                                  "Overwrite Entity "
                                )}
                              </Button>
                            </span>
                          </Tooltip>
                          <Tooltip
                            title={
                              <>
                                Update Field and maintain settings except entity
                                Relation.
                                <br />
                                <br /> Virtual column will be maintained.
                              </>
                            }
                            placement="top"
                          >
                            <span>
                              <Button
                                onClick={onEntityListMerge}
                                disabled={isMerging || isOverwriting}
                              >
                                {isMerging ? (
                                  <>
                                    <CircularProgress
                                      color="inherit"
                                      size={13}
                                    />
                                    Merging Entity...
                                  </>
                                ) : (
                                  "Merge Entity"
                                )}
                              </Button>
                            </span>
                          </Tooltip>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="process">
                    <div className="entity-wrapper">
                      <Form>
                        <InputGroup>
                          <Form.Control
                            placeholder="Enter Entity Name..."
                            value={searchInput}
                            onChange={onChangeSearchInput}
                            onKeyDown={(e) =>
                              e.keyCode === 13 ? e.preventDefault() : e
                            }
                          />
                          {isLoading ? (
                            <Button variant="outline-dark" disabled>
                              <CircularProgress color="inherit" size={13} />{" "}
                              Loading...
                            </Button>
                          ) : (
                            <Button
                              variant="outline-dark"
                              onClick={onLoadEntity}
                            >
                              Sync Data
                            </Button>
                          )}
                        </InputGroup>
                      </Form>

                      <div className="entity-list custom-scroll">
                        {isLoading ? (
                          <div className="empty">Loading Data...</div>
                        ) : (
                          DPTableList.map((item, idx) => {
                            const selected = StringUtils.equalsIgnoreCase(
                              selectedEntity.tableNm,
                              item.tableNm
                            );
                            return (
                              <div
                                key={item.tableNm}
                                className={`entity ${
                                  selected ? "selected" : ""
                                }`}
                                onClick={(e) => onSelectEntity(e, item)}
                              >
                                {selected ? (
                                  <AiFillFolderOpen size={20} />
                                ) : (
                                  <AiFillFolder size={20} />
                                )}
                                {item.tableNm}
                              </div>
                            );
                          })
                        )}
                      </div>
                    </div>
                    <div className="arrow">
                      <FaArrowAltCircleRight size={60} color="#0096cc" />
                    </div>
                    <div className="data-model-list">
                      {!ObjectUtils.isEmpty(selectedEntity) ? (
                        <WijmoGrid
                          isLoading={isDataLoading}
                          columns={columns}
                          rows={patchingDataModelList}
                          isCheckBox={true}
                          selectionModel={checkedDataModel}
                          style={{ height: `calc(50vh + 25px)` }}
                          selectMode={SelectionMode.RowRange}
                          onClickCheckItem={(arr) => {
                            let selected = [];
                            arr.map((data) => {
                              selected = [...selected, data.dataModelId];
                            });
                            setCheckedDataModel(selected);
                          }}
                        />
                      ) : (
                        <div className="empty">Select Entity to Patch</div>
                      )}
                    </div>
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
          </div>
        )}
      </PageTemplate.Box>
    </PageTemplate>
  );
}

export default EntityPatchMain;
