import React, { useEffect, useState } from "react";
import Modal from "components/common/modal/UModal";
import StringUtils from "components/common/utils/StringUtils";
import Message from "components/common/Message";
import * as Enums from "components/builder/BuilderEnum";
import USelectbox from "components/common/element/USelectbox";
import Popup from "components/common/Popup";
import { useRef } from "react";
import User from "components/common/utils/UserUtils";
import { Button, Col, Form, InputGroup, Row } from "react-bootstrap";
import UmodalTemplate from "components/common/modal/UModalTemplate";
import ConnectionService from "services/common/ConnectionService";
import { Tooltip } from "@mui/material";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import ConnectTypeDescPopup from "./ConnectTypeDescPopup";

function ConnectPopup(props) {
  const {
    title,
    callbackFnc,
    connection,
    disconnection,
    deleteConnectionFnc,
    workspace,
    connectionList,
  } = props;

  const [connectionId, setConnectionId] = useState("");
  const [connectionNm, setConnectionNm] = useState("");
  const [protocol, setProtocol] = useState("HTTP");
  const [host, setHost] = useState("");
  const [connectionType, setConnectionType] = useState("proxy");
  const [runtimeProtocol, setRuntimeProtocol] = useState("HTTP");
  const [runtimeHost, setRuntimeHost] = useState("");
  const [systemUserId, setSystemUserId] = useState("");
  const [systemUserPw, setSystemUserPw] = useState("");
  const [tenantId, setTenantId] = useState("*");
  const [coCd, setCoCd] = useState("*");
  const [isConnecting, setIsConnecting] = useState(false);
  const [connected, setConnected] = useState(
    Number(User.getConnection(workspace.tenantMstId, "connectionId")) ===
      Number(connection?.connectionId)
  );

  const connectionNmRef = useRef();
  const systemUserPwRef = useRef();

  useEffect(() => {
    if (connection) {
      setConnectionId(connection.connectionId);
      setConnectionNm(connection.connectionNm);
      setProtocol(connection.protocol);
      setHost(connection.host);
      setRuntimeProtocol(connection.runtimeProtocol);
      setRuntimeHost(connection.runtimeHost);
      setSystemUserId(connection.userId);
      setTenantId(connection.tenantId);
      setCoCd(connection.coCd);
      setConnectionType(connection.connectionType || "proxy");
      systemUserPwRef.current.focus();
    } else {
      connectionNmRef.current.focus();
    }
  }, []);

  const onKeyDown = (e) => {
    if (e.keyCode === 13) {
      onSaveConnect();
    }
  };

  const validationCheck = () => {
    if (StringUtils.isEmpty(tenantId) || StringUtils.isEmpty(coCd)) {
      Message.alert(
        "Please enter Tenant ID and Company Code.",
        Enums.MessageType.WARN
      );
      return false;
    }
    if (StringUtils.isEmpty(protocol)) {
      Message.alert("Please enter Schema", Enums.MessageType.WARN);
      return false;
    }
    if (StringUtils.isEmpty(host)) {
      Message.alert("Please enter Deploy Server Host", Enums.MessageType.WARN);
      return false;
    }
    if (StringUtils.isEmpty(connectionType)) {
      Message.alert(
        "Please select Deploy Server connection Type.",
        Enums.MessageType.WARN
      );
      return false;
    }
    if (StringUtils.isEmpty(systemUserId)) {
      Message.alert("Please enter Access User ID", Enums.MessageType.WARN);
      return false;
    }
    if (StringUtils.isEmpty(systemUserPw)) {
      Message.alert("Please enter Access User PW", Enums.MessageType.WARN);
      return false;
    }
    return {
      tenantId,
      coCd,
      protocol,
      host,
      runtimeProtocol,
      connectionType,
      runtimeHost,
      systemUserId,
      systemUserPw,
      connectionId: connectionId,
    };
  };

  const onTestConnect = () => {
    //테스트만 실행
    const body = validationCheck();
    let _testMethod = () => {};
    if (body) {
      setIsConnecting(true);
      if (body.connectionType === "proxy") {
        _testMethod = ConnectionService.test;
      } else if (body.connectionType === "direct") {
        _testMethod = ConnectionService.directConnectTest;
      }
    }

    _testMethod(
      body,
      (res) => {
        const { flag, text } = ConnectionService.reponseValidator(
          res,
          workspace.tenantMstId
        );
        if (flag) Message.alert(text, Enums.MessageType.SUCCESS);
        else Message.alert(text, Enums.MessageType.ERROR);
        setIsConnecting(false);
      },
      (err) => {
        console.log(err);
        setIsConnecting(false);
      }
    );
  };

  const onSaveConnect = () => {
    //연결 및 DB 저장
    if (StringUtils.isEmpty(connectionNm)) {
      Message.alert("Please set the Connection Name", Enums.MessageType.WARN);
      return false;
    }
    const body = validationCheck();

    if (body) {
      setIsConnecting(true);
      body.connectionNm = connectionNm;
      callbackFnc(body, () => {
        setIsConnecting(false);
      });
    }
  };
  const onDisConnect = (e) => {
    disconnection(e);
    Popup.close();
  };
  const onDeleteConnection = (e) => {
    Message.confirm(
      "Are you sure to delete all connection information?",
      () => {
        deleteConnectionFnc(e, connection);
      }
    );
  };

  const onChangeConnection = (e) => {
    const selectedConnection = connectionList.find((c) =>
      StringUtils.equalsIgnoreType(c.connectionId, e.target.value)
    );
    if (!selectedConnection) {
      setConnectionId("");
      return false;
    }

    setConnectionId(selectedConnection.connectionId);
    setConnectionNm(selectedConnection.connectionNm);
    setProtocol(selectedConnection.protocol);
    setHost(selectedConnection.host);
    setSystemUserId(selectedConnection.userId);
    setTenantId(selectedConnection.tenantId);
    setCoCd(selectedConnection.coCd);
    setRuntimeProtocol(selectedConnection.runtimeProtocol);
    setRuntimeHost(selectedConnection.runtimeHost);
    setConnectionType(selectedConnection.connectionType || "proxy");

    systemUserPwRef.current.focus();
  };

  return (
    <Modal>
      <Modal.Header title={title} />
      <Modal.Body>
        <Form>
          <UmodalTemplate>
            {connectionList && connectionList.length > 0 && (
              <Form.Group className="mb-3">
                <Form.Label>Connection List</Form.Label>
                <Form.Select value={connectionId} onChange={onChangeConnection}>
                  <option value="">Select</option>
                  {connectionList.map((_connection) => {
                    return (
                      <option
                        value={_connection.connectionId}
                        key={_connection.connectionId}
                      >
                        {_connection.connectionNm}
                        {""}
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        HOST : &nbsp;
                        {_connection.host}
                      </option>
                    );
                  })}
                </Form.Select>
              </Form.Group>
            )}

            <Form.Group className="mb-3">
              <Form.Label className="required">Connection Name</Form.Label>
              <input
                type="text"
                id="connectionNm"
                name="connectionNm"
                className="form-control"
                onChange={(e) => setConnectionNm(e.target.value)}
                value={connectionNm}
                ref={connectionNmRef}
                onKeyDown={onKeyDown}
              />
            </Form.Group>
            <Row>
              <Col xs={3}>
                <Form.Label className="required">Deploy Server </Form.Label>
              </Col>
              <Col xs={9} />
            </Row>
            <Row className="mb-3">
              <Col xs={2}>
                <USelectbox
                  id="protocol"
                  onChange={(e) => setProtocol(e.target.value)}
                  defaultValue={StringUtils.defaultString(protocol, "HTTP")}
                  items={[
                    { id: "HTTP", text: "HTTP" },
                    { id: "HTTPS", text: "HTTPS" },
                  ]}
                  options={{
                    matchCd: "id",
                    matchNm: "text",
                  }}
                  size="m"
                />
              </Col>
              <Col xs={6}>
                <input
                  type="text"
                  id="host"
                  name="host"
                  className="form-control"
                  onChange={(e) => setHost(e.target.value)}
                  value={host}
                  onKeyDown={onKeyDown}
                />
              </Col>
              <Col xs={4}>
                <InputGroup>
                  <USelectbox
                    id="connectionType"
                    onChange={(e) => {
                      setConnectionType(e.target.value);
                    }}
                    defaultValue={StringUtils.defaultString(
                      connectionType,
                      "proxy"
                    )}
                    items={[
                      { id: "proxy", text: "DAAF Proxy" },
                      { id: "direct", text: "Direct Connection" },
                    ]}
                    options={{
                      matchCd: "id",
                      matchNm: "text",
                    }}
                    size="m"
                  />

                  <Button
                    size={"sm"}
                    variant="outline-secondary"
                    onClick={(e) => {
                      Popup.open(<ConnectTypeDescPopup />, {
                        style: { content: { width: "550px" } },
                      });
                    }}
                  >
                    <AiOutlineQuestionCircle />
                  </Button>
                </InputGroup>
              </Col>
            </Row>
            <Row>
              <Col xs={3}>
                <Form.Label>Runtime Server</Form.Label>
              </Col>
              <Col xs={9}></Col>
            </Row>
            <Row className="mb-3">
              <Col xs={2}>
                <USelectbox
                  id="protocol"
                  onChange={(e) => setRuntimeProtocol(e.target.value)}
                  defaultValue={StringUtils.defaultString(
                    runtimeProtocol,
                    "HTTP"
                  )}
                  items={[
                    { id: "HTTP", text: "HTTP" },
                    { id: "HTTPS", text: "HTTPS" },
                  ]}
                  options={{
                    matchCd: "id",
                    matchNm: "text",
                  }}
                  size="m"
                />
              </Col>
              <Col xs={10}>
                <InputGroup>
                  <Form.Control
                    type="text"
                    id="host"
                    name="host"
                    className="form-control"
                    onChange={(e) => setRuntimeHost(e.target.value)}
                    value={runtimeHost}
                    onKeyDown={onKeyDown}
                  />
                  <Tooltip
                    title={
                      <>
                        Enter HTTPS Runtime URL to use Workflow Debugging
                        process.
                      </>
                    }
                    placement="top"
                  >
                    <Button size={"sm"} variant="outline-secondary">
                      <AiOutlineQuestionCircle />
                    </Button>
                  </Tooltip>
                </InputGroup>
              </Col>
            </Row>
            <Form.Group className="mb-3">
              <Form.Label className="required">Access User ID</Form.Label>
              <input
                type="text"
                id="systemUserId"
                name="systemUserId"
                className="form-control"
                onChange={(e) => setSystemUserId(e.target.value)}
                value={systemUserId}
                onKeyDown={onKeyDown}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label className="required">Access User PW</Form.Label>
              <input
                type="password"
                id="systemUserPw"
                name="systemUserPw"
                className="form-control"
                onChange={(e) => setSystemUserPw(e.target.value)}
                value={systemUserPw}
                ref={systemUserPwRef}
                onKeyDown={onKeyDown}
              />
            </Form.Group>
            {/* <Row>
              <Form.Group as={Col}>
                <Form.Label className="required">테넌트 ID</Form.Label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="테넌트 ID"
                  value={tenantId}
                  onChange={(e) => setTenantId(e.currentTarget.value)}
                  onKeyDown={onKeyDown}
                />
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label className="required">회사 코드</Form.Label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="회사 코드"
                  value={coCd}
                  onChange={(e) => setCoCd(e.currentTarget.value)}
                  onKeyDown={onKeyDown}
                />
              </Form.Group>
            </Row> */}
          </UmodalTemplate>
        </Form>
      </Modal.Body>
      <Modal.Footer
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Modal.Footer.ProgressButton
          variant="secondary"
          onClick={onTestConnect}
          doing={isConnecting}
          doingText={"Connecting..."}
          side="left"
        >
          Connection Test
        </Modal.Footer.ProgressButton>
        {connection ? (
          connected ? (
            disconnection ? (
              <Modal.Footer.Button
                variant="danger"
                side="left"
                onClick={onDisConnect}
              >
                Disconnection
              </Modal.Footer.Button>
            ) : (
              <></>
            )
          ) : deleteConnectionFnc ? (
            <Modal.Footer.ProgressButton
              variant="danger"
              side="left"
              onClick={onDeleteConnection}
            >
              Delete Connection
            </Modal.Footer.ProgressButton>
          ) : (
            <></>
          )
        ) : (
          <></>
        )}

        <Modal.Footer.ProgressButton
          onClick={onSaveConnect}
          doing={isConnecting}
          doingText={"Connecting..."}
          // disabled={connected && User.getConnection("token")}
        >
          Save {"&"} Connect
        </Modal.Footer.ProgressButton>
      </Modal.Footer>
    </Modal>
  );
}

export default ConnectPopup;
