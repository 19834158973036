import React, { useState } from "react";
import Modal from "components/common/modal/UModal";
import { Button, Col, Form, InputGroup, Row } from "react-bootstrap";
import Popup from "components/common/Popup";
import StringUtils from "components/common/utils/StringUtils";
import Message from "components/common/Message";
import * as Enums from "components/builder/BuilderEnum";
import UserService from "services/common/UserService";
import UmodalTemplate from "components/common/modal/UModalTemplate";

/**
 * 회원 등록
 * **/
function UserRegisterPopup(props) {
  const { callbackFnc, joinRequest } = props;
  const [userId, setUserId] = useState("");
  const [userNm, setuserNm] = useState("");
  const [userEngNm, setUserEngNm] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [pwd, setPwd] = useState("");
  const [pwdConfirm, setPwdConfirm] = useState("");
  const [defaultLangCd, setDefaultLangCd] = useState("KO");
  const [IdDupCheck, setIdDupCheck] = useState(false);

  const onRegister = (requestYn) => {
    if (StringUtils.isEmpty(userId))
      return Message.alert("Please enter User ID.", Enums.MessageType.WARN);
    if (StringUtils.isEmpty(userNm))
      return Message.alert("Please enter User Name.", Enums.MessageType.WARN);
    if (StringUtils.isEmpty(userEmail))
      return Message.alert("Please enter User Email.", Enums.MessageType.WARN);
    if (pwd !== pwdConfirm)
      return Message.alert("Password is Invalid.", Enums.MessageType.WARN);
    if (StringUtils.isEmpty(pwdConfirm))
      return Message.alert(
        "Please enter 'Confirm Password' Field",
        Enums.MessageType.WARN
      );

    const body = {
      userId,
      userNm,
      userEngNm,
      userEmail,
      pwd,
      defaultLangCd,
      requestYn,
    };
    if (joinRequest) {
      Message.confirm(
        "Proceeding with user registration and usage request.",
        () => {
          UserService.register(body, (res) => {
            const { isSuccess, message } = res.data;
            if (isSuccess === "Y") {
              Message.alert(
                "The request has been completed. Please wait for the approval of the person in charge or the administrator",
                Enums.MessageType.SUCCESS
              );
              Popup.close();
            } else {
              Message.alert(message, Enums.MessageType.ERROR);
            }
          });
        }
      );
    } else {
      Message.confirm("Would you like to register the user?", () => {
        UserService.register(body, (res) => {
          const { isSuccess, message } = res.data;
          if (isSuccess === "Y") {
            Message.alert(message, Enums.MessageType.SUCCESS);
            Popup.close();
            if (callbackFnc) {
              callbackFnc();
            }
          } else {
            Message.alert(message, Enums.MessageType.ERROR);
          }
        });
      });
    }
  };

  const dupCheck = () => {
    if (IdDupCheck) {
      //중복조회가 다된 경우
      setIdDupCheck((prev) => !prev);
    } else {
      // 중복조회 해야하는 경우
      if (StringUtils.isEmpty(userId)) {
        return Message.alert("Please enter ID", Enums.MessageType.WARN);
      }

      UserService.checkDuplicate({ userId }, (res) => {
        if (res.data) {
          Message.confirm(
            `"${userId}" This User ID is available. Would you like to use it?`,
            () => {
              setIdDupCheck(true);
            }
          );
        } else {
          setIdDupCheck(false);
          Message.alert("A Duplicate User ID exists.", Enums.MessageType.INFO);
        }
      });
    }
  };

  return (
    <Modal>
      <Modal.Header title={"Add New User"} />
      <Modal.Body>
        <Form>
          <UmodalTemplate>
            <Form.Group>
              <Form.Label className="required">User ID</Form.Label>
              <Row>
                <Col xs={12}>
                  <InputGroup>
                    <Form.Control
                      value={userId}
                      onChange={(e) => setUserId(e.currentTarget.value)}
                      readOnly={IdDupCheck}
                    />
                    <Button
                      variant={IdDupCheck ? "secondary" : "primary"}
                      onClick={dupCheck}
                    >
                      {IdDupCheck ? "Change ID" : "Duplicate Check"}
                    </Button>
                  </InputGroup>
                </Col>
              </Row>
            </Form.Group>
            <Form.Group>
              <Form.Label className="required">User Name</Form.Label>
              <Form.Control
                value={userNm}
                onChange={(e) => setuserNm(e.currentTarget.value)}
              />
              <Form.Text></Form.Text>
            </Form.Group>
            <Form.Group>
              <Form.Label>User English Name</Form.Label>
              <Form.Control
                value={userEngNm}
                onChange={(e) => setUserEngNm(e.currentTarget.value)}
              />
              <Form.Text></Form.Text>
            </Form.Group>
            <Form.Group>
              <Form.Label className="required">User Email</Form.Label>
              <Form.Control
                value={userEmail}
                onChange={(e) => setUserEmail(e.currentTarget.value)}
              />
              <Form.Text></Form.Text>
            </Form.Group>
            <Form.Group>
              <Form.Label className="required">Password</Form.Label>
              <Form.Control
                value={pwd}
                onChange={(e) => setPwd(e.currentTarget.value)}
                type={"password"}
              />
              <Form.Text></Form.Text>
            </Form.Group>
            <Form.Group>
              <Form.Label className="required">Password Confirm</Form.Label>
              <Form.Control
                value={pwdConfirm}
                type={"password"}
                onChange={(e) => setPwdConfirm(e.currentTarget.value)}
              />
              <Form.Text></Form.Text>
            </Form.Group>
            <Form.Group>
              <Form.Label>Language</Form.Label>
              <Form.Select
                value={defaultLangCd}
                onChange={(e) => setDefaultLangCd(e.target.value)}
              >
                <option value={"KO"}>Korean</option>
                <option value={"EN"}>English</option>
                <option value={"CN"}>Chinese</option>
                <option value={"JP"}>Japanese</option>
                <option value={"BN"}>Vietnamese</option>
              </Form.Select>
              <Form.Text></Form.Text>
            </Form.Group>
          </UmodalTemplate>
        </Form>
      </Modal.Body>
      <Modal.Footer closeText={"Cancel"}>
        {joinRequest ? (
          <Modal.Footer.Button
            onClick={() => onRegister("Y")}
            variant={"primary"}
          >
            Registration Request
          </Modal.Footer.Button>
        ) : (
          <Modal.Footer.Button
            onClick={() => onRegister("N")}
            variant={"primary"}
          >
            Regist
          </Modal.Footer.Button>
        )}
      </Modal.Footer>
    </Modal>
  );
}

export default UserRegisterPopup;
