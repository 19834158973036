/* eslint-disable jsx-a11y/alt-text */
import Popup from "components/common/Popup";
import Modal from "components/common/modal/UModal";
import { useEffect, useState } from "react";
import { BsPencilSquare } from "react-icons/bs";
import styled from "styled-components";

import { Checkbox } from "@mui/material";
import UModalJavascriptEditor from "components/common/code/UModalJavascriptEditor";
import UmodalTemplate from "components/common/modal/UModalTemplate";
import ArrayUtils from "components/common/utils/ArrayUtils";
import ObjectUtils from "components/common/utils/ObjectUtils";
import StringUtils from "components/common/utils/StringUtils";
import produce from "immer";
import { useRef } from "react";
import { Button, Col, Form, Row, Tab, Tabs } from "react-bootstrap";
import { FaTrash } from "react-icons/fa";
import { MdLibraryAdd } from "react-icons/md";
import DataModelService from "services/datamodel/DataModelService";
import { render } from "@testing-library/react";

const MesFunPopupStyle = styled.div`
  .edit-source {
    border: 1px solid #ddd;
  }
`;

/**
 * TreeEventTabPopup : Tree Click Event Popup
 *
 * 2024.01.03 create : 김민호
 *
 * @param {*} props
 * @returns value
 */
const TreeEventTabPopup = (props) => {
  const {
    title,
    callbackFnc,
    item,
    entityId,
    id,
    data,
    setRetItems,
    workspace,
    onClickEventBuilder,
    codeList,
    isShowOperator,
  } = props;

  const [popItems, setPopItems] = useState({});
  const [dataBindingList, setDataBindingList] = useState([]);
  const [params, setParams] = useState([]);

  const dataPreRef = useRef();
  const dataPostRef = useRef();

  const initPopup = () => {
    dataPreRef.current = item.beforeSubmit;
    dataPostRef.current = item.afterSubmit;

    selectDataBindingList(entityId);
    let initItem = { ...(item || {}) };

    if (ObjectUtils.isEmpty(initItem.params)) {
      initItem.params = [{}];
    }
    setParams(initItem.params);
  };

  /**
   * Data Model Entity Field List 조회(Data Binidng)
   * @param {*} entityId
   */
  const selectDataBindingList = (entityId) => {
    if (!StringUtils.isEmpty(entityId)) {
      DataModelService.getDataBindingList({ entityId: entityId }, (res) => {
        setDataBindingList(
          res.data ? res.data : [{ id: "none", text: "[none] None Data" }]
        );
      });
    }
  };

  const onChangeDataBinding = (e, param, index) => {
    let newParam = { ...param };
    changeParam(e, newParam, index);
  };

  const changeParam = (e, param, index) => {
    const newParams = produce(params, (draft) => {
      draft[index] = { ...param, [e.target.id]: e.target.value };
    });
    setParams(newParams);
    // onChangeSetRetItems(e, "params", newParams);
  };

  // 파라미터 추가
  const onInsertClick = (e, param, index) => {
    const newParams = produce(params, (draft) => {
      draft.splice(index + 1, 0, {});
    });
    setParams(newParams);
  };

  // 파라미터 제거
  const onDeleteClick = (e, param, index) => {
    const newParams = produce(params, (draft) => {
      draft.splice(index, 1);
      if (draft.length === 0) {
        draft.splice(0, 0, {});
      }
    });
    setParams(newParams);
  };

  // closePopup: value값을 전달하고 popup 닫기
  const closePopup = (e) => {
    let retValue = { ...popItems };
    retValue.beforeSubmit = dataPreRef.current;
    retValue.afterSubmit = dataPostRef.current;
    retValue.params = params;
    const _closePopup = () => {
      callbackFnc.call(this, retValue);
      Popup.close();
    };

    _closePopup();
  };

  useEffect(() => {
    initPopup();
  }, [entityId, item]);

  return (
    <MesFunPopupStyle>
      <Modal>
        <Modal.Header title={title} />
        <Modal.Body>
          <Tabs justify defaultActiveKey="tab-pre" id="toolbar-tabs">
            <Tab eventKey="tab-pre" title="Pre-processing" id="tab-pre">
              <UmodalTemplate>
                {item.eventType === "TREE_CLICK_Q" ? (
                  <div>
                    <Row>
                      <Form.Group className="mb-3">
                        <Form.Label>Input Mapping</Form.Label>
                        <Row className="mb-2">
                          <Col>Add Parameter</Col>
                          {isShowOperator ? <Col>Search Condition</Col> : ""}
                          <Col>Fixed Value</Col>
                          <Col>Required</Col>
                          <Col></Col>
                        </Row>
                        {!ArrayUtils.isEmpty(params)
                          ? params.map((param, index) => {
                              return (
                                <Row
                                  key={index}
                                  className="pb-2"
                                  style={{ alignItems: "center" }}
                                >
                                  <Col>
                                    <Form.Select
                                      size="sm"
                                      id="parameterId"
                                      onChange={(e) =>
                                        onChangeDataBinding(e, param, index)
                                      }
                                      value={StringUtils.defaultString(
                                        param.parameterId
                                      )}
                                    >
                                      <option value={""}>Select</option>
                                      {!ArrayUtils.isEmpty(dataBindingList)
                                        ? dataBindingList.map(
                                            (option, optionIdx) => (
                                              <option
                                                key={optionIdx}
                                                value={option.id}
                                              >
                                                {option.text}
                                              </option>
                                            )
                                          )
                                        : ""}
                                    </Form.Select>
                                  </Col>
                                  <Col>
                                    <Form.Select
                                      size="sm"
                                      id="searchOperator"
                                      onChange={(e) =>
                                        onChangeDataBinding(e, param, index)
                                      }
                                      value={StringUtils.defaultString(
                                        param.searchOperator
                                      )}
                                    >
                                      <option value={""}>Select</option>
                                      {!ArrayUtils.isEmpty(isShowOperator)
                                        ? isShowOperator.map(
                                            (option, optionIdx) => (
                                              <option
                                                key={optionIdx}
                                                value={option.codeDtlCd}
                                              >
                                                {option.codeDtlNm}
                                              </option>
                                            )
                                          )
                                        : ""}
                                    </Form.Select>
                                  </Col>
                                  <Col>
                                    <input
                                      type="text"
                                      id="default"
                                      className="form-control form-select-sm"
                                      defaultValue={param.default}
                                      onChange={(e) =>
                                        onChangeDataBinding(e, param, index)
                                      }
                                    />
                                  </Col>
                                  <Col>
                                    <Checkbox
                                      id="required"
                                      value="Y"
                                      defaultChecked={
                                        param.required === "Y" ? true : false
                                      }
                                      onClick={(e) =>
                                        onChangeDataBinding(e, param, index)
                                      }
                                    />
                                  </Col>
                                  <Col>
                                    <Button
                                      onClick={(e) => {
                                        onInsertClick(e, param, index);
                                      }}
                                      size="sm"
                                      className="mr-5"
                                      variant={"outline-primary"}
                                    >
                                      <MdLibraryAdd size="16" />
                                    </Button>
                                    <Button
                                      onClick={(e) => {
                                        onDeleteClick(e, param, index);
                                      }}
                                      size="sm"
                                      variant={"outline-danger"}
                                    >
                                      <FaTrash size="16" />
                                    </Button>
                                  </Col>
                                </Row>
                              );
                            })
                          : ""}
                      </Form.Group>
                    </Row>
                  </div>
                ) : (
                  ""
                )}
                <Form.Group className="mb-3">
                  <Form.Label>Pre-processing Function</Form.Label>
                  <UModalJavascriptEditor
                    height="420px"
                    defaultValue={StringUtils.defaultString(item.beforeSubmit)}
                    eventInfo={props.getEventInfo("beforeSubmit")}
                    onClickEventBuilder={() => onClickEventBuilder("treeEvent")}
                    onChange={(value) => {
                      dataPreRef.current = value;
                    }}
                    callbackFnc={callbackFnc}
                  ></UModalJavascriptEditor>
                </Form.Group>
              </UmodalTemplate>
            </Tab>
            <Tab eventKey="tab-post" title="Post-processing" id="tab-post">
              <UmodalTemplate>
                <Form.Label>Post-processing Function</Form.Label>
                <Form.Group className="mb-3">
                  <UModalJavascriptEditor
                    height="420px"
                    defaultValue={StringUtils.defaultString(item.afterSubmit)}
                    eventInfo={props.getEventInfo("afterSubmit")}
                    onClickEventBuilder={() => onClickEventBuilder("treeEvent")}
                    onChange={(value) => {
                      dataPostRef.current = value;
                    }}
                    callbackFnc={callbackFnc}
                  ></UModalJavascriptEditor>
                </Form.Group>
              </UmodalTemplate>
            </Tab>
          </Tabs>
        </Modal.Body>
        <Modal.Footer>
          <Modal.Footer.Button
            type="button"
            className="btn btn-primary"
            onClick={closePopup}
          >
            <BsPencilSquare />
            &nbsp;Confirm
          </Modal.Footer.Button>
        </Modal.Footer>
      </Modal>
    </MesFunPopupStyle>
  );
};

export default TreeEventTabPopup;
