import { Autocomplete, TextField } from "@mui/material";
import UModal from "components/common/modal/UModal";
import UModalTemplate from "components/common/modal/UModalTemplate";
import ObjectUtils from "components/common/utils/ObjectUtils";
import ContentsLoading from "page/common/ContentsLoading";
import { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import TrdService from "services/trd/TrdService";

const TrdTableListPopup = ({
  title,
  workspace,
  trdList = [],
  connection,
  callbackFnc,
  envId,
  type = "table",
  ...props
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedTrd, setSelectedTrd] = useState();
  const [trdTableList, setTrdTableList] = useState([]);
  const [selectedTable, setSelectedTable] = useState({});

  const entityDefinition = {};

  const getTrdTable = (trdId) => {
    setIsLoading(true);
    TrdService.getTrd({ appEnvId: envId, trdId })
      .then((res) => {
        if (res.data.tableList.length > 0) {
          setTrdTableList(res.data.tableList);
        } else {
          setTrdTableList([]);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onChangeTrd = (e, trd) => {
    if (!ObjectUtils.isEmpty(trd)) {
      setSelectedTrd(trd);
      getTrdTable(trd.trdId);
    }
  };

  const onRowClick = (table) => {
    setSelectedTable(table);
  };

  const onRowDoubleClick = (table) => {
    if (callbackFnc) {
      // field set
      callbackFnc(table);
    }
  };

  const tableMapping = (tableData) => {
    return tableData.map((field, index) => entityMapping(field));
  };

  const entityMapping = (fieldData) => {
    return fieldData;
  };

  const onSearch = (e) => {};

  return (
    <UModal>
      <UModal.Header title={title} />
      <UModal.Body>
        <UModalTemplate className={"database-wrapper"}>
          <Row className="mb-3">
            <Col className="mb-3" xs={12}>
              <Form.Label className="required">Select TRD</Form.Label>
              <Autocomplete
                disablePortal
                id="trd"
                isOptionEqualToValue={(option, value) =>
                  option.trdId === value.trdId
                }
                onChange={onChangeTrd}
                size="small"
                options={trdList.map((trd) => {
                  return { ...trd, label: `${trd.trdUid} | ${trd.trdNm}` };
                })}
                renderInput={(params) => (
                  <TextField {...params} label="Enter TRD Name..." />
                )}
              />
            </Col>
            <Form.Label>Table List</Form.Label>
            <Col xs={9}>
              <Form.Control
                placeholder={
                  ObjectUtils.isEmpty(selectedTrd)
                    ? "Please select TRD."
                    : "Please enter Table Name to Search."
                }
                disabled={ObjectUtils.isEmpty(selectedTrd)}
                onChange={onSearch}
              />
            </Col>
            <Col xs={3}>
              <Button variant="outline-dark">Search</Button>
            </Col>
          </Row>

          <div className="database-list-wrapper">
            {isLoading && <ContentsLoading show={true} type="circle" />}
            {trdTableList.length > 0 ? (
              trdTableList.map((table, index) => {
                return (
                  <div
                    className={`entity ${
                      selectedTable.tablePhysicalNm === table.tablePhysicalNm
                        ? "selected"
                        : ""
                    }`}
                    key={table.tablePhysicalNm}
                    onClick={(e) => onRowClick(table)}
                    onDoubleClick={(e) => onRowDoubleClick(table)}
                  >
                    {table.tablePhysicalNm}
                  </div>
                );
              })
            ) : (
              <div className="loading">No Data Found.</div>
            )}
          </div>
        </UModalTemplate>
      </UModal.Body>
      <UModal.Footer>
        <UModal.Footer.Button onClick={(e) => callbackFnc(selectedTable)}>
          Confirm
        </UModal.Footer.Button>
      </UModal.Footer>
    </UModal>
  );
};

export default TrdTableListPopup;
