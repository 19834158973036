/* eslint-disable jsx-a11y/alt-text */
import React, { Fragment } from "react";

import Modal from "components/common/modal/UModal";

import UmodalTemplate from "components/common/modal/UModalTemplate";

const ValidationValueSettingGuide = (props) => {
  return (
    <Modal>
      <Modal.Header title={props.title} />
      <Modal.Body>
        <UmodalTemplate>
          <Fragment>
            <h4 id="html">1. Text (String) Type</h4>
            <p>
              The validation rules and usage for the Text (String) Type are as
              follows.
            </p>
            <table className="doc-table" style={{ width: "100%" }}>
              <thead>
                <tr>
                  <th style={{ width: "33%" }}>
                    <div>Validation Contents</div>
                  </th>
                  <th style={{ width: "33%" }}>
                    <div>Fixed Value Direct Input</div>
                  </th>
                  <th style={{ width: "33%" }}>
                    <div>Compare Target Element Setting </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div>Minimum Input Byte</div>
                  </td>
                  <td>
                    <font color="blue">Possible</font>
                    <br />
                    Must enter at least 30 bytes. <br></br>
                    <code> Validation Value : 30</code>
                  </td>
                  <td>
                    <div>
                      <font color="red">Impossible</font>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div>Maximum Input byte</div>
                  </td>
                  <td>
                    <font color="blue">Possible</font>
                    <br />
                    Possible to enter a maximum of 30 bytes. <br></br>
                    <code> Validation Value : 30</code>
                  </td>
                  <td>
                    <div>
                      <font color="red">Impossible</font>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div>Matches the value of the specified element</div>
                  </td>
                  <td>
                    <font color="red">Impossible</font>
                  </td>
                  <td>
                    <div>
                      <font color="blue">Possible</font>
                      <br />
                      Match between 'New Password' and 'Confirm New Password'
                      <br></br>
                      <code>
                        Validation Value : <br />
                        1. #confirmPassword /*Same Form*/ <br />
                        2. #SaveForm #confirmPassword <br />
                        3. Possible to use Other JQuery Select
                      </code>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div>
                      Validation Check for Resident registeration number
                    </div>
                  </td>
                  <td>
                    <font color="red">Impossible</font>
                  </td>
                  <td>
                    <div>
                      <font color="red">Impossible</font>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <br />
            <h4 id="html">2. Number Type</h4>
            <p>
              The validation rules and usage for the Number Type are as follows.
            </p>
            <table className="doc-table" style={{ width: "100%" }}>
              <thead>
                <tr>
                  <th style={{ width: "33%" }}>
                    <div>Validation Contents</div>
                  </th>
                  <th style={{ width: "33%" }}>
                    <div>Fixed Value Direct Input</div>
                  </th>
                  <th style={{ width: "33%" }}>
                    <div>Compare Target Element Setting</div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div>Minimum number input length</div>
                  </td>
                  <td>
                    <font color="blue">Possible</font>
                    <br />
                    You must enter a number of at least 3 digits (1000).
                    <br></br>
                    <code> Validation Value : 3</code>
                  </td>
                  <td>
                    <div>
                      <font color="red">Impossible</font>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div>Maximum Number Input Length</div>
                  </td>
                  <td>
                    <font color="blue">Possible</font>
                    <br />
                    You can enter a maximum of 2 digits (up to 99).<br></br>
                    <code> Validation Value : 2</code>
                  </td>
                  <td>
                    <div>
                      <font color="red">Impossible</font>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="pt-1">
                      A value greater than the specified value or the value of
                      the element
                    </div>
                    <div className="pt-1">
                      A value that is greater than or equal to the specified
                      value or the value of the element
                    </div>
                    <div className="pt-1">
                      A value that is less than the specified value or the value
                      of the element
                    </div>
                    <div className="pt-1">
                      A value that is less than or equal to the specified value
                      or the value of the element
                    </div>
                  </td>
                  <td>
                    <font color="blue">Possible</font>
                    <br />
                    Must enter a value of 100 or greater.
                    <br />
                    <code>Validation Value : 100</code>
                  </td>
                  <td>
                    <div>
                      <font color="blue">Possible</font>
                      <br />
                      Cannot exceed the available stock quantity.
                      <br></br>
                      <code>
                        Validation Value : <br />
                        1. #invQty /*Same Form*/ <br />
                        2. #InvForm #invQty
                        <br />
                        3. Possible to use Other JQuery Select
                      </code>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <br />
            <h4 id="html">3. Date Type</h4>
            <p>
              The validation rules and usage for the Date Type are as follows.
            </p>
            <table className="doc-table" style={{ width: "100%" }}>
              <thead>
                <tr>
                  <th style={{ width: "33%" }}>
                    <div>Validation Contents</div>
                  </th>
                  <th style={{ width: "33%" }}>
                    <div>Fixed Value Direct Input</div>
                  </th>
                  <th style={{ width: "33%" }}>
                    <div>Compare Target Element Setting</div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div className="pt-1">
                      A date after the specified value or the value of the
                      element
                    </div>
                    <div className="pt-1">
                      A date before the specified value or the value of the
                      element
                    </div>
                    <div className="pt-1">
                      A date equal to or after the specified value or the value
                      of the element
                    </div>
                    <div className="pt-1">
                      A date equal to or before the specified value or the value
                      of the element
                    </div>
                  </td>
                  <td>
                    <font color="blue">Possible</font>
                    <br />
                    (+-)[Number]d : Based on <font color="green">Today</font>
                    (+-)[Number] days later
                    <code>
                      {" "}
                      10d : 10days later <br />
                      -15d : 15days before <br />
                    </code>
                    (+-)[number]m : Based on<font color="green">Today</font>
                    (+-)[number]months later
                    <code>
                      {" "}
                      2m : 2months later <br />
                      -6m : 6months before <br />
                    </code>
                    (+-)[number]y : Based on <font color="green">Today</font>
                    (+-)[number]years later
                    <code>
                      {" "}
                      1y : 1years later <br />
                      -2y : 2years before <br />
                    </code>
                    today
                    <br />
                    <code>Validation Value : today</code>
                  </td>
                  <td>
                    <div>
                      <font color="blue">Possible</font>
                      <br />
                      You must enter a date after the order date.
                      <br></br>
                      <code>
                        Validation Value : <br />
                        1. #orderDate /*Same Form*/ <br />
                        2. #OrderForm #orderDate <br />
                        3. Possible to use Other JQuery Select
                      </code>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </Fragment>
        </UmodalTemplate>
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
};

export default ValidationValueSettingGuide;
