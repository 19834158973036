import CryptoJS from "crypto-js";

class StringUtils {
  /**
   * check null or undefined
   * @param {Stromg} string
   * @returns
   */
  static isUndefined = (string) => {
    return string == null || typeof string == "undefined";
  };

  /**
   * isEmpty
   * @param {Stromg} string
   * @returns
   */
  static isEmpty = (string) => {
    return string === "" || StringUtils.isUndefined(string);
  };

  /**
   * defaultString
   * @param {String} value
   * @param {String} replaceString
   * @returns
   */
  static defaultString = (value, replaceStr) => {
    return StringUtils.isEmpty(value)
      ? StringUtils.isEmpty(replaceStr)
        ? ""
        : replaceStr
      : value;
  };

  /**
   * equals ignore type
   * @param {*} value1
   * @param {*} value2
   * @returns
   */
  static equalsIgnoreType = (value1, value2) => {
    return (
      !StringUtils.isUndefined(value1) &&
      !StringUtils.isUndefined(value2) &&
      value1.toString() === value2.toString()
    );
  };

  /**
   * equals ignore case sensitive
   * @param {String} value1
   * @param {String} value2
   * @returns
   */
  static equalsIgnoreCase = (value1, value2) => {
    return (
      !StringUtils.isUndefined(value1) &&
      !StringUtils.isUndefined(value2) &&
      value1.toString().toUpperCase() === value2.toString().toUpperCase()
    );
  };

  /**
   * 특정 문자 이후 String return
   * @param {String} str
   * @param {String} char
   * @returns
   */
  static substringAfter(str, char, isFirst) {
    return isFirst === true
      ? str.substring(str.indexOf(char) + 1)
      : str.substring(str.lastIndexOf(char) + 1);
  }

  /**
   * 특정 문자 이전 String return
   * @param {String} str
   * @param {String} char
   * @returns
   */
  static substringBefore(str, char) {
    return str.substring(0, str.lastIndexOf(char));
  }

  /**
   * 대분자 변경
   * @param {String} str
   * @returns
   */
  static toUpperCase(str) {
    return StringUtils.isEmpty(str) ? "" : str.toUpperCase();
  }

  /**
   * indexof
   * @param {String} str
   * @param {String} char
   * @returns
   */
  static indexOf(str, char) {
    return StringUtils.isEmpty(str) ? -1 : str.indexOf(char);
  }

  /**
   * beginWith
   * @param {String} str
   * @param {String} char
   * @returns
   */
  static beginWith(str, char) {
    return StringUtils.indexOf(str, char) === 0;
  }

  /**
   * get last character
   * @param {String} str
   * @returns
   */
  static lastChar(str) {
    return StringUtils.isEmpty(str) ? "" : str.substring(str.length - 1);
  }

  /**
   * Random String ID
   * @returns random ID
   */
  static getUuid = () => {
    return "u" + Math.random().toString(36).substring(2, 15);
  };

  /**
   * convert Kebap to Pascal
   * @param {*} str
   * @returns
   */
  static convertKebabToPascal = (str) => {
    if (StringUtils.isEmpty(str)) {
      return str;
    }

    return str
      .replace(/([0-9])([a-z])/g, "$1-$2")
      .split("-")
      .map(function (word, index) {
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      })
      .join("");
  };

  /**
   * Json을 암호화 하는 메서드
   * @param {Object} object
   * @returns {String}
   */
  static encJson = (object) => {
    const ENCRYPTION_KEY =
      process.env.REACT_APP_ENCRYPTION_KEY || "automationplatformfrombizentro!"; // Must be 256 bits (32 characters)
    let encJson = CryptoJS.AES.encrypt(
      JSON.stringify(object),
      ENCRYPTION_KEY
    ).toString();
    //       base64  ,   ：          utf8    ，    base64
    let encData = CryptoJS.enc.Base64.stringify(
      CryptoJS.enc.Utf8.parse(encJson)
    );
    return encData;
  };

  /**
   * String을 JSON 데이터로 복호화 하는 함수
   * @param {String} StringData
   * @returns {Object || null}
   */
  static decData = (StringData) => {
    const ENCRYPTION_KEY = process.env.REACT_APP_ENCRYPTION_KEY;
    let decData = CryptoJS.enc.Base64.parse(StringData).toString(
      CryptoJS.enc.Utf8
    );
    //
    let decJson = CryptoJS.AES.decrypt(decData, ENCRYPTION_KEY).toString(
      CryptoJS.enc.Utf8
    );
    if (decJson) {
      return JSON.parse(decJson);
    } else {
      return null;
    }
  };

  /**
   * 특정 문자가 비교 배열안에 있는지 없는지 확인하는 함수
   *  - 다중 체크에 사용
   *  - ex) str === target1 || str === target2
   *  - ====> StringUtils.includes(str1,[target1,target2])
   * @param {String} str
   * @param {[String]} strArr
   * @returns
   */
  static includes(str, strArr) {
    if (!str) return false;
    if (strArr instanceof Array) {
      return strArr.includes(str);
    } else {
      console.error("The array format for StringUtils.includes is incorrect.");
      return false;
    }
  }

  /**
   * 문자열 포함 비교 , 대소문자 무시
   * @param {String} str
   * @param {[String]} strArr
   * @returns
   */
  static includesIgnoreCase(str, strArr) {
    if (!str) return false;
    if (strArr instanceof Array) {
      const lowerStrArr = strArr.map((s) => s.toLowerCase());
      const lowerStr = str.toLowerCase();
      return this.includes(lowerStr, lowerStrArr);
    } else {
      console.error("The array format for StringUtils.includes is incorrect.");
      return false;
    }
  }

  /**
   * 파라미터를 순환하여 String 타입은 전부 Trim 하는 함수
   * @param {*} data
   * @return {*}
   */
  static trimAll(data) {
    if (typeof data === "string") {
      return String(data).trim();
    } else if (typeof data === "object") {
      for (const k in data) {
        if (typeof data[k] === "string") {
          data[k] = String(data[k]).trim();
        } else if (typeof data === "object") {
          data[k] = this.trimAll(data[k]);
        }
      }
      return data;
    } else {
      return data;
    }
  }

  /**
   *
   * @param {String} str
   */
  static changeCamelToSpace(str) {
    return str.replace(/([A-Z])/g, " $1").replace(/^./, function (str) {
      return str.toUpperCase();
    });
  }
}

export default StringUtils;
