import React, { PureComponent } from "react";
import { Accordion } from "react-bootstrap";
import { Scrollbar } from "smooth-scrollbar-react";
import DraggableComponent from "components/builder/ui/DraggableComponent";
import { AppContext } from "components/common/AppContextProvider";
import StringUtils from "components/common/utils/StringUtils";

class BasicComponentsTab extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }
  static contextType = AppContext;

  render() {
    const componentList = this.context.component.getComponentList("B", true);
    const height = this.props.height;
    return (
      <Scrollbar
        className="custom-class"
        plugins={{
          overscroll: {
            effect: "glow",
          },
        }}
      >
        <div className="basic-component-panel">
          <Accordion
            style={{
              paddingTop: "1px",
              height: `${
                StringUtils.equalsIgnoreCase(this.props.trdUseYn, "Y") &&
                "calc(100vh - 50px - 53px)"
              }`,
            }}
            defaultActiveKey={[0, 1, 2, 3, 4]}
            alwaysOpen
          >
            {componentList.map((compMst, mstIdx) => (
              <Accordion.Item eventKey={mstIdx} key={compMst.componentMstId}>
                <Accordion.Header>{compMst.componentMstNm}</Accordion.Header>
                <Accordion.Body>
                  {compMst.componentDtl.map((compDtl, dtlIdx) =>
                    compDtl.displayYn === "Y" ? (
                      <DraggableComponent
                        key={compDtl.componentDtlId}
                        data={compDtl}
                      />
                    ) : (
                      ""
                    )
                  )}
                </Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>
        </div>
      </Scrollbar>
    );
  }
}
export default BasicComponentsTab;
