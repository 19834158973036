import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { RiDeleteBin5Line } from "react-icons/ri";
import { MdDriveFolderUpload } from "react-icons/md";
import { IconButton } from "@mui/material";
import CommonUtils from "./utils/CommonUtils";
import Message from "./Message";

/**
 *
 * @param {*} param0
 * @returns
 */
function FileDropZone({ onChangeFile, fileList, removeFile, ...props }) {
  const onDrop = useCallback(
    (acceptedFiles) => {
      onChangeFile([...fileList, ...acceptedFiles]);
    },
    [fileList]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const onRemoveFile = (e, idx) => {
    e.preventDefault();
    e.stopPropagation();
    const newFileList = [...fileList];
    newFileList.splice(idx, 1);
    onChangeFile(newFileList);
  };

  const onRemoveFileInDB = (e, file) => {
    e.preventDefault();
    e.stopPropagation();

    Message.confirm("Are you sure to permanently delete the Saved File?", () => {
      removeFile(file);
    });
  };

  return (
    <>
      <div className="dropzone-wrapper" {...getRootProps()}>
        <input {...getInputProps()} />
        {isDragActive ? (
          <div className="drag-active" style={{}}>
            <MdDriveFolderUpload size={35} />
            여기에 파일을 놓으세요
          </div>
        ) : (
          <div className="dropzone-file-list">
            <div className="title">
              업로드 파일 목록
              <br />
              (파일을 드래그 하거나 클릭하세요)
            </div>
            <div
              style={{
                display: "flex",
                gap: "5px",
                flexWrap: "wrap",
                flexDirection: "column",
              }}
            >
              {fileList.map((file, idx) => {
                return (
                  <span key={file.fileId || file.name} className="item">
                    {file.fileOriginalNm || file.name}(
                    {CommonUtils.getBytesFormat(file.fileSize || file.size)}){" "}
                    <IconButton
                      toolTipText={"삭제"}
                      style={{ color: "tomato" }}
                      onClick={(e) =>
                        file.fileId
                          ? onRemoveFileInDB(e, file)
                          : onRemoveFile(e, idx)
                      }
                    >
                      <RiDeleteBin5Line size={13} />
                    </IconButton>
                    {file.fileId ? "" : "(업로드 예정)"}
                  </span>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default FileDropZone;
