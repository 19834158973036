/* eslint-disable jsx-a11y/alt-text */
import React, { Fragment } from "react";

import Modal from "components/common/modal/UModal";

import UmodalTemplate from "components/common/modal/UModalTemplate";

const GridCellTemplateGuidePopup = (props) => {
  return (
    <Modal>
      <Modal.Header title="Grid Cell Template Usage Guide" />
      <Modal.Body>
        <UmodalTemplate>
          <Fragment>
            <h5>Grid CellTemplate</h5>
            <div className="doc-page-wrap">
              <div className="event-desc pt-1">
                You can change the cell style using HTML tags.
              </div>
              <br></br>
              e.g.) Apply different styles based on the current cell's value.
              <code className="mt-2 pt-2">
                {`<span class=\${value > 40000 ? "big-val" : "small-val"}>\${text}</span>`}
              </code>
              e.g.) If there is an attachment number, display the [View] button
              in the cell.
              <code className="mt-2 pt-2">
                {`\${item.atchmnflNo != null ? '<div class="btn-group center"><button type="button" class="btn btn-default mr-2 text-center btnViewFile" data-lang="btnViewFile">보기</button></div>':''}`}
              </code>
              e.g.) Display the attachment name + [Upload File Button] in the
              cell.
              <code className="mt-2 pt-2">
                {`\${text} <button type="button" class="wj-btn wj-btn-glyph wj-right" tabindex="-1" data-lang-id="btnFileSearch"><i class="fa fa-upload"></i></button>`}
              </code>
              <div className="event-code">
                function (col,row,value,text,item)
              </div>
              <div className="event-params pb-1">
                <span className="doc-title"> Parameters</span>
                <ul>
                  <li>
                    <span className="event-args-var">col</span>:
                    <span className="event-args-type">Object</span>-
                    <span className="event-args-name">
                      Current Column Object
                    </span>
                  </li>
                  <li>
                    <span className="event-args-var">row</span>:
                    <span className="event-args-type">Object</span>-
                    <span className="event-args-name">Current Row Object</span>
                  </li>
                  <li>
                    <span className="event-args-var">value</span>:
                    <span className="event-args-type">String</span>-
                    <span className="event-args-name">
                      Current Column Binding Value
                    </span>
                  </li>
                  <li>
                    <span className="event-args-var">text</span>:
                    <span className="event-args-type">String</span>-
                    <span className="event-args-name">
                      Text displayed in the current cell.
                    </span>
                  </li>
                  <li>
                    <span className="event-args-var">item</span>:
                    <span className="event-args-type">Object:Map</span>-
                    <span className="event-args-name">
                      Values bound to the current row.
                    </span>
                    <div className="event-args-desc">e.g.) item.userId</div>
                  </li>
                </ul>
              </div>
            </div>
          </Fragment>
        </UmodalTemplate>
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
};

export default GridCellTemplateGuidePopup;
