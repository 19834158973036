/*!
 * Builder Redux Action for react v.17
 *
 * 아래의 Redux에서 관리되는 정보에 대한 Action
 *   - 현재 Activation 되어있는 Component/Layout에 대한 감지
 *   - 현재 작업중인 Builder output에 대한 정보
 *
 *   Author: Bizentro
 *   Date: 2021-04
 */

import BuilderAction from "components/builder/BuilderActionType";

/**
 * UI Builder output을 갱신한다.
 * @param {*} output
 * @returns
 */
export const updateOutput = (output) => {
  return { type: BuilderAction.UI.UPDATE_OUTPUT, output: output };
};

/**
 * 신규 페이지 생성 (new output)
 * @param {*} output
 * @returns
 */
export const createPage = (output) => {
  return {
    type: BuilderAction.UI.CREATE_PAGE,
    output: output,
    information: null,
  };
};

/**
 * 모바일 output 이동
 * @param {*} output
 * @returns
 */
export const setUIOutput = (output) => {
  return {
    type: BuilderAction.UI.SET_UI_OUTPUT,
    output: output,
  };
};

/**
 * 모바일 output 이동
 * @param {*} output
 * @returns
 */
export const setMobileOutput = (output) => {
  return {
    type: BuilderAction.MOBILE.SET_MOBILE_OUTPUT,
    output: output,
  };
};

/**
 * 모바일 output 이동
 * @param {*} output
 * @returns
 */
export const getUIOutput = (output) => {
  return {
    type: BuilderAction.UI.GET_UI_OUTPUT,
    output: output,
  };
};

/**
 * 모바일 output 이동
 * @param {*} output
 * @returns
 */
export const getMobileOutput = (output) => {
  return {
    type: BuilderAction.MOBILE.GET_MOBILE_OUTPUT,
    output: output,
  };
};

/**
 * 선택된 또는 신규 추가된 Component를 활성화 시킨다.
 * @param {*} component
 * @returns
 */
export const activateComponent = (component) => {
  return { type: BuilderAction.UI.ACTIVATE_UI, component: component };
};

/**
 * 활성화된 Component의 propertyValue를 update 한다.
 * @param {*} propertyValues
 * @returns
 */
export const updateActivateProps = (propertyValues) => {
  return {
    type: BuilderAction.UI.UPDATE_ACTIVATE_PROPS_UI,
    propertyValue: propertyValues,
  };
};
/**
 * 활성화된 Component의 EditorAttr을 update 한다.
 * @param {*} propertyValues
 * @returns
 */
export const updateActivateEditorAttr = (editorAttr) => {
  return {
    type: BuilderAction.UI.UPDATE_ACTIVATE_EDITOR_ATTR,
    payload: editorAttr,
  };
};
/**
 * 불러온 프로그램 정보를 Redux에 별도로 저장한다.
 * @param {*} information
 * @returns
 * **/
export const updateInformation = (information) => {
  return {
    type: BuilderAction.UI.UPDATE_PROGRAM_INFORMATION,
    information,
  };
};
/**
 * 페이지 초기화
 * @returns
 * **/
export const initOutput = () => {
  return {
    type: BuilderAction.UI.INIT_OUTPUT,
  };
};
/**
 * 선택한 UI 초기화
 * @returns
 * **/
export const initActive = () => {
  return {
    type: BuilderAction.UI.INIT_ACTIVE_UI,
  };
};

/**
 * 빌더의 트리에 펼쳐지는 아이디 리스트를 적용한다.
 */
export const setBuilderTreeNodeIds = (treeNodeIds) => {
  return {
    type: BuilderAction.UI.SET_BUILDER_TREE_NODE_ID,
    payload: treeNodeIds,
  };
};
/**
 * 문서구조 오픈
 * @param {*} flag
 * @returns
 */
export const setTreeOpen = (flag) => {
  return {
    type: BuilderAction.UI.SET_TREE_OPEN,
    payload: flag,
  };
};

export const addMemo = (memo) => {
  return {
    type: BuilderAction.UI.ADD_PROGRAM_MEMO,
    payload: memo,
  };
};
export const updateMemo = (memo) => {
  return {
    type: BuilderAction.UI.UPDATE_PROGRAM_MEMO,
    payload: memo,
  };
};
export const deleteMemo = (memo) => {
  return {
    type: BuilderAction.UI.DELETE_PROGRAM_MEMO,
    payload: memo,
  };
};
export const setMemo = (memo) => {
  return {
    type: BuilderAction.UI.SET_PROGRAM_MEMO,
    payload: memo,
  };
};
