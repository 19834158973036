import React, { useRef } from "react";
import { useDrag } from "react-dnd";
import produce from "immer";

import UIEditorDNDContainer from "components/builder/ui/editor/UIEditorDNDContainer";
import * as Enums from "components/builder/BuilderEnum";
import UIEditorChildRender from "components/builder/ui/editor/UIEditorChildRender";
import {
  ArrayUtils,
  ObjectUtils,
  NumberUtils,
} from "components/common/utils/CommonUtils";

import Column from "components/builder/ui/uiComponents/layout/Column";
// import { borderRight } from "@mui/system";
import { useSelector } from "react-redux";

const UIEditorColumn = (props) => {
  const { data, handleDrop, path, templateComponents, rootLocation, isLast } =
    props;

  const ref = useRef(null);

  const { isMobileEditor, screenWidth } = useSelector((state) => state.mobile);

  const item = produce(data, (draft) => {
    draft.path = path;
    draft.rootLocation = rootLocation;
  });
  const [{ isDragging }, drag] = useDrag({
    item: item,
    type: item.type,
    componentType: item.componentType,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0 : 1;
  drag(ref);

  const propertyValue = ObjectUtils.isEmpty(item.propertyValue)
    ? {}
    : item.propertyValue;

  //editor의 column flex layout이기 때문에 상위 div에 같이 반영해줘야함
  let parentStyle = {}; //alignSelf: "center" };
  let columnSyle = { ...opacity };

  //desktop width는 적용해준다.
  // if (NumberUtils.isNumeric(propertyValue.widthLaptop)) {
  //   parentStyle.width = (Number(propertyValue.widthLaptop) * 100) / 12 + "%";
  // }

  if (isMobileEditor) {
    if (
      Number(screenWidth) >= 768 &&
      NumberUtils.isNumeric(propertyValue.widthTablet)
    ) {
      parentStyle.width = (Number(propertyValue.widthTablet) * 100) / 12 + "%";
    } else if (
      Number(screenWidth) < 768 &&
      NumberUtils.isNumeric(propertyValue.widthPhone)
    ) {
      parentStyle.width = (Number(propertyValue.widthPhone) * 100) / 12 + "%";
    }
  } else if (NumberUtils.isNumeric(propertyValue.widthLaptop)) {
    parentStyle.width = (Number(propertyValue.widthLaptop) * 100) / 12 + "%";
  }

  if (propertyValue.style) {
    //&& propertyValue.style.width) {
    columnSyle = { ...columnSyle, ...propertyValue.style }; //.width = propertyValue.style.width;

    //runtime에 적용되는 flex 관련 설정은 editor에 적용 안되도록 한다.
    //delete columnSyle.width;
    delete columnSyle.minWidth;
    delete columnSyle.maxWidth;
    delete columnSyle.display;
    delete columnSyle.flex;
    delete columnSyle.justifyContent;

    //상위 tag에 width 관련 css를 같이 적용 해준다.
    if (propertyValue.style.width) {
      parentStyle.width = propertyValue.style.width;
    }
    if (propertyValue.style.minWidth) {
      parentStyle.minWidth = propertyValue.style.minWidth;
    }
    if (propertyValue.style.maxWidth) {
      parentStyle.maxWidth = propertyValue.style.maxWidth;
    }
  }
  /*
  if (isLast === true) {
    columnSyle = { ...columnSyle, ...{ borderRight: "1px solid #bcb0b0" } };
  }
  */

  const DNDContainer = props.dndContainer
    ? props.dndContainer
    : UIEditorDNDContainer;
  return (
    <div ref={ref} className="whole-wrap" style={parentStyle}>
      <Column
        style={columnSyle}
        isLast={isLast}
        componentInfo={item}
        event="renderEditor"
      >
        {!ArrayUtils.isEmpty(item.child)
          ? item.child.map((child, index) => {
              const currentPath = `${path}-${index}`;
              return (
                <React.Fragment key={index}>
                  <DNDContainer
                    data={{
                      location: Enums.ComponentType.COLUMN,
                      rootLocation: rootLocation,
                      path: currentPath,
                      childrenCount: item.child.length,
                      propertyValue: data.propertyValue,
                      compId: data.compId,
                    }}
                    onDrop={handleDrop}
                    templateComponents={templateComponents}
                  />

                  <UIEditorChildRender
                    key={child.compId}
                    data={child}
                    handleDrop={handleDrop}
                    templateComponents={templateComponents}
                    path={currentPath}
                    dndContainer={DNDContainer}
                    rootLocation={rootLocation}
                  />
                </React.Fragment>
              );
            })
          : ""}
        <DNDContainer
          data={{
            location: Enums.ComponentType.COLUMN,
            rootLocation: rootLocation,
            path: `${path}-${
              ArrayUtils.isEmpty(item.child) ? 0 : item.child.length
            }`,
            propertyValue: data.propertyValue,
            compId: data.compId,
            childrenCount: ArrayUtils.isEmpty(item.child)
              ? 0
              : item.child.length,
          }}
          onDrop={handleDrop}
          templateComponents={templateComponents}
        />
      </Column>
    </div>
  );
};
export default React.memo(UIEditorColumn);
