import { Checkbox, FormControlLabel } from "@mui/material";
import { Enums } from "components/builder/BuilderEnum";
import { stopEvent } from "components/builder/ui/editor/handler/UIEditorEventHandler";
import Popup from "components/common/Popup";
import Modal from "components/common/modal/UModal";
import User from "components/common/utils/UserUtils";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Button, Form } from "react-bootstrap";
import LocalStorageService from "services/common/LocalService";

function SaveProcedureWarnPopup({ callback, ...props }) {
  //   const [autoSave, setAutoSave] = useState("N");
  const [agree, setAgree] = useState("N");

  const onClickAutoSave = (e) => {
    e.stopPropagation();
    setAgree(e.target.checked ? "Y" : "N");
  };

  const onClickConfirm = (e) => {
    stopEvent(e);
    if (agree === "Y") {
      const userId = User.getId();
      const body = {
        userId,
        agree,
      };
      LocalStorageService.set(Enums.LocalStorageName.PROCEDURE_WARN, body);
    }
    if (callback) {
      callback();
    }
  };

  return (
    <Modal>
      <Modal.Header />
      <Modal.Body>
        <p>
          <span style={{ color: "tomato", fontWeight: "bold" }}>
            After late July
          </span>
        </p>
        <p>
          Procedure function is
          <span style={{ color: "tomato" }}>not supported</span>
          in User transaction.
        </p>
        <p>
          <span className="blink">Please use workflow function.</span>{" "}
        </p>
        <p>Would you like to continue?</p>
      </Modal.Body>
      <Modal.Footer closeText={"cancel"}>
        <Modal.Footer.Button onClick={onClickConfirm}>
          confirm and proceed
        </Modal.Footer.Button>
      </Modal.Footer>
    </Modal>
  );
}

export default SaveProcedureWarnPopup;
