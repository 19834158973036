import { Tooltip } from "@mui/material";
import { Enums } from "components/builder/BuilderEnum";
import Popup from "components/common/Popup";
import ArrayUtils from "components/common/utils/ArrayUtils";
import { lazy, memo, Suspense, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEdges, useNodes } from "reactflow";
import WorkflowReduxHelper from "../editor/helper/WorkflowReduxHelper";

/**
 * 프로세스 엣지 타입 (시작, 종료)
 * @param {data:Object,id:String}
 */
export const ProcessEdge = memo(
  ({
    data: { process },
    id,
    selected,
    findReferCompId,
    parentNodeId,
    isChild,
    parentNode,
    addHandler,
    onAddBundle,
    onDeleteFromBundle,
    setBreakpoint,
    removeBreakpoint,
    isTracing,
    isBreakPoint,
    commentCheckMethod,
    inCommunication,
    breakpointType,
    breakpoints,
    debugProcess,
    ...props
  }) => {
    const dispatch = useDispatch();
    // const connectionNodeId = useStore(connectionNodeIdSelector);
    const workflow = useSelector((state) => state.workflow);
    const workspace = useSelector((state) => state.workspace);
    const nodes = useNodes();
    const edges = useEdges();

    const [isInvalid, setIsInvalid] = useState(false);

    useEffect(() => {
      validateReturnData();
    }, [nodes]);

    /**
     * 종료 프로세스의 경우
     * 엔티티들이 중간에 삭제되었는지 안되었느지 확인해야 한다.
     * 유효하지 않은 JSON return 데이터 확인 기능 추가
     */
    const validateReturnData = () => {
      if (process.processType === "EndProcess") {
        // returnObject validate 필요
        const { returnObject = [] } = process.propertyValue;
        const rObjMap = {};
        returnObject.forEach((rObj) => {
          rObjMap[rObj.entityVariable] = false;
        });

        const checkedProcessIdList = [];
        const find = (process) => {
          const connectors = workflow.output.service.child.connector.filter(
            (c) => c.processTo === process.compId
          );
          checkedProcessIdList.push(process.compId);
          if (ArrayUtils.isEmpty(connectors)) {
            return false;
          }
          connectors.forEach((con) => {
            const nextProcess = workflow.output.service.child.process.find(
              (node) => con.processFrom === node.compId
            );
            //outputEntity
            if (nextProcess?.propertyValue?.outputEntity) {
              rObjMap[
                nextProcess?.propertyValue.outputEntity.entityVariable
              ] = true;
            }
            //entityDefinition
            if (nextProcess?.propertyValue?.entityVariable) {
              rObjMap[nextProcess.propertyValue?.entityVariable] = true;
            }
            //restApiConnector
            if (nextProcess?.propertyValue?.entityVariable) {
              rObjMap[nextProcess.propertyValue?.entityVariable] = true;
            }
            if (nextProcess?.propertyValue?.bodyEntity) {
              rObjMap[nextProcess.propertyValue?.bodyEntity] = true;
            }
            if (nextProcess?.propertyValue?.outputHeaderEntity) {
              rObjMap[
                nextProcess.propertyValue?.outputHeaderEntity.entityVariable
              ] = true;
            }
            if (nextProcess?.propertyValue?.responseCode) {
              rObjMap[
                nextProcess.propertyValue?.responseCode.entityVariable
              ] = true;
            }

            if (nextProcess) {
              if (checkedProcessIdList.indexOf(nextProcess.compId) === -1)
                return find(nextProcess);
            } else {
              return false;
            }
          });
        };

        find({ compId: process.compId });
        //return Object가 전부 true가 아니면 invalid 표시한다.
        if (Object.keys(rObjMap).every((key) => rObjMap[key])) {
          setIsInvalid(false);
        } else {
          setIsInvalid(true);
        }
      }
    };

    const onOpenProcessPopup = (e) => {
      const callbackFnc = (callbackData) => {
        const body = {
          ...process,
          propertyValue: callbackData,
        };
        WorkflowReduxHelper.updateNodes(dispatch, [body], workflow);
        Popup.close();
      };

      const ProcessDetailPopup = lazy(() =>
        import("page/popup/workflow/process/" + process.processType)
      );
      if (ProcessDetailPopup) {
        Popup.open(
          <Suspense fallback={<div></div>}>
            <ProcessDetailPopup
              workspace={workspace}
              // connectionPopupOpen={connectionPopupOpen}
              callbackFnc={callbackFnc}
              workflow={workflow.output}
              processInfo={process.propertyValue}
              processType={process.processType}
              nodes={nodes}
              edges={edges}
              compId={id}
            />
          </Suspense>,
          {
            style: {
              content: {
                width: "50%",
              },
            },
          }
        );
      }
    };

    const renderPanel = (Component) => {
      if (isInvalid) {
        return (
          <Tooltip placement="top" title={"Please redefine the Return Object"}>
            {Component}
          </Tooltip>
        );
      } else {
        return Component;
      }
    };

    return (
      <div className={`workflow-node `}>
        {renderPanel(
          <div
            className={`workflow-process-edge-wrapper ${
              isTracing ? " traced " : ""
            } ${isInvalid ? "invalid" : ""}`}
            onDoubleClick={onOpenProcessPopup}
          >
            {process.propertyValue.processNm}
          </div>
        )}

        {addHandler()}
      </div>
    );
  }
);
