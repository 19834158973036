import { Checkbox, FormControlLabel } from "@mui/material";
import { Enums } from "components/builder/BuilderEnum";
import { stopEvent } from "components/builder/ui/editor/handler/UIEditorEventHandler";
import Popup from "components/common/Popup";
import Modal from "components/common/modal/UModal";
import UModalTemplate from "components/common/modal/UModalTemplate";
import User from "components/common/utils/UserUtils";
import React, { useState } from "react";
import { Button } from "react-bootstrap";
import LocalStorageService from "services/common/LocalService";

function RunWorkflowQuestPopup({ callback, ...props }) {
  const [autoSave, setAutoSave] = useState("N");

  const onClickAutoSave = (e) => {
    e.stopPropagation();
    setAutoSave(e.target.checked ? "Y" : "N");
  };

  const onClickConfirm = (e) => {
    stopEvent(e);
    if (autoSave === "Y") {
      const userId = User.getId();
      const body = {
        userId,
        autoSave,
      };
      LocalStorageService.set(
        Enums.LocalStorageName.WORKFLOW_AUTO_DEPLOY,
        body
      );
    }
    Popup.close();
    if (callback) {
      callback();
    }
  };

  return (
    <Modal>
      <Modal.Header />
      <Modal.Body>
        <UModalTemplate>
          <p>There is no deploy history after saving (modifying),</p>
          <p> or there are differences from the deployed version.</p>
          <p>Would you like to deploy?</p>

          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={autoSave === "Y" ? true : false}
                  onClick={onClickAutoSave}
                />
              }
              label="Do not ask again( Auto Deploy )"
            />
          </div>
        </UModalTemplate>
      </Modal.Body>
      <Modal.Footer closeText="No">
        <Modal.Footer.Button onClick={onClickConfirm}>Yes</Modal.Footer.Button>
      </Modal.Footer>
    </Modal>
  );
}

export default RunWorkflowQuestPopup;
