import React, { Fragment, useEffect, useRef, useState } from "react";

import Modal from "components/common/modal/UModal";

import UmodalTemplate from "components/common/modal/UModalTemplate";
import { Card, Form } from "react-bootstrap";
import Popup from "components/common/Popup";
import { BsPencilSquare } from "react-icons/bs";

const GridNumberingFormatPopup = ({ title, defaultValue, callbackFnc }) => {
  const [clickIndex, setClickIndex] = useState(defaultValue);

  useEffect(() => {
    if (defaultValue != null) {
      formatType.forEach((item, index) => {
        if (item.id === defaultValue) {
          setClickIndex(index);
        }
      });
    }
  }, []);

  const formatType = [
    {
      id: "onlyNumber",
      title: "Format displayed as a number",
      text: `1st Level: 1, 2, 3\n2nd Level: 1), 2), 3)\n3rd Level: (1), (2), (3)\n4th Level: ①, ②, ③\n\n\n\n\n`,
      renderChild: () => {
        return (
          <Form.Control
            as="textarea"
            defaultValue={`1.\n\t1)\n\t\t(1)\n\t\t\t①`}
            readOnly={true}
            style={{ height: "100px" }}
          />
        );
      },
    },
    {
      id: "numberAndEng",
      title: "Format displayed as number & english",
      text: `1st Level: 1, 2, 3\n2nd Level: a, b, c\n3rd Level: 1), 2), 3)\n4th Level: a), b), c)
      5th Level: (1), (2), (3)\n6th Level: (a), (b), (c)\n7th Level: ①, ②, ③\n8th Level: ⓐ, ⓑ, ⓒ`,
      renderChild: () => {
        return (
          <Form.Control
            as="textarea"
            defaultValue={`1.\n\ta.\n\t\t1)\n\t\t\ta)`}
            readOnly={true}
            style={{ height: "100px" }}
          />
        );
      },
    },
    {
      id: "seperateNum",
      title: "Separate numbers for each level transition",
      text: `1st Level: 1, 2, 3\n2nd Level: 1.1, 2.1, 3.1\n3rd Level: 1.1.1, 1.1.2, 1.1.3\n4th Level: 1.1.1.1, 1.1.1.2, 1.1.1.3\n\n\n\n\n`,
      renderChild: () => {
        return (
          <Form.Control
            as="textarea"
            defaultValue={`1.\n\t1.1\n\t\t1.1.1\n\t\t\t1.1.1.1`}
            readOnly={true}
            style={{ height: "100px" }}
          />
        );
      },
    },
  ];

  /**
   * item 클릭
   * @param {*} index
   */
  const onClickItem = (index) => {
    if (clickIndex === index) {
      setClickIndex(null);
    } else {
      setClickIndex(index);
    }
  };

  /**
   * Popup Close
   */
  const closePopup = (e) => {
    callbackFnc(clickIndex !== null ? formatType[clickIndex].id : null);

    Popup.close();
  };

  return (
    <Modal>
      <Modal.Header title={title} />
      <Modal.Body>
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          {formatType.map((item, index) => {
            return (
              <Card
                key={index}
                style={{
                  flex: "1 0 21%",
                  marginRight: "0.3em",
                  boxShadow:
                    index === clickIndex
                      ? "0px 0px 0px 3px rgba(0,144,237,.4)"
                      : null,
                }}
                onClick={(e) => onClickItem(index)}
              >
                <Card.Body>
                  <Card.Title>{item.title}</Card.Title>
                  <Card.Text style={{ whiteSpace: "pre-line" }}>
                    {item.text}
                  </Card.Text>
                  <div>
                    {item.renderChild()}
                    <Form.Check
                      style={{ display: "flex", justifyContent: "center" }}
                      type={"checkbox"}
                      id={item.id}
                      checked={index === clickIndex ? true : false}
                      onClick={(e) => onClickItem(index)}
                    />
                  </div>
                </Card.Body>
              </Card>
            );
          })}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Modal.Footer.Button onClick={closePopup}>
          <BsPencilSquare />
          &nbsp;Confirm
        </Modal.Footer.Button>
      </Modal.Footer>
    </Modal>
  );
};

export default GridNumberingFormatPopup;
