import React, { useEffect } from "react";
import Popup from "components/common/Popup";
import Modal from "components/common/modal/UModal";
import UModalTemplate from "components/common/modal/UModalTemplate";
import { Col, Form, Row } from "react-bootstrap";
import CodeMirror from "@uiw/react-codemirror";
import { sql } from "@codemirror/lang-sql";
import { useState } from "react";
import DbPatchService from "services/common/DbPatchService";
import Message from "components/common/Message";
import { Enums } from "components/builder/BuilderEnum";
import CommonUtils from "components/common/utils/CommonUtils";
import CodeMirrorWrapper from "components/common/element/CodeMirrorWrapper";
import { SlideFromBottom } from "components/common/modal/Effect";

function DbPatchRegisterPopup({
  dbPatch,
  appVersionList = [],
  saveCallback,
  ...props
}) {
  debugger;
  const output = props.output;
  const [appVersion, setAppVersion] = useState(
    dbPatch ? dbPatch.params.appVersion : ""
  );
  const [dbPatchNo, setDbPatchNo] = useState(
    dbPatch ? dbPatch.params.dbPatchNo : ""
  );
  const [patchNotes, setPatchNotes] = useState(
    dbPatch ? dbPatch.params.patchNotes : ""
  );
  const [patchSql, setPatchSql] = useState(
    dbPatch ? dbPatch.params.patchSql : ""
  );
  const [patchBeginDate, setPatchBeginDate] = useState(
    dbPatch ? CommonUtils.getDate(dbPatch.params.patchBeginDate) : "2023-01-02"
  );
  const [databaseType, setDatabaseType] = useState(
    dbPatch ? dbPatch.params.databaseType : "MYSQL"
  );

  useEffect(() => {
    if (dbPatch == null) {
      getMaxDbPatchNo();
    }
  }, []);

  const getMaxDbPatchNo = (e) => {
    DbPatchService.getMaxDbPatchNo({}, (res) => {
      setDbPatchNo(res.data.dbPatchNo + 1);
    });
  };

  const changeViewSource = (source) => {
    setPatchSql(source);
  };

  const onSave = (e) => {
    if (e) e.preventDefault();
    const body = {
      ...dbPatch,
      appVersion,
      dbPatchNo,
      databaseType,
      patchBeginDate,
      patchNotes,
      patchSql,
    };

    if (appVersion === "") {
      return Message.alert("Please select App version", Enums.MessageType.WARN);
    }

    DbPatchService.saveDbPatch(body, (res) => {
      debugger;
      if (res.data === 1) {
        //popup 저장 성공
        Message.alert(`Saved Successfully.`, Enums.MessageType.SUCCESS);
      } else {
        //popup 저장 실패
        Message.alert(`Fail to Save.`, Enums.MessageType.ERROR);
      }

      Popup.close();

      //dbPatchInsert 화면에서 데이터 재조회
      if (saveCallback) {
        saveCallback(res.data);
      }
    });
  };

  return (
    <Modal>
      <Modal.Header title="Framework Patch Details" />
      <Modal.Body>
        <UModalTemplate>
          <Row>
            <Form.Group as={Col} xs={12} className="mb-3">
              <Form.Label className="required">App Version</Form.Label>
              <Form.Select
                value={appVersion}
                onChange={(e) => setAppVersion(e.target.value)}
              >
                <option>Select</option>
                {appVersionList.map((dbPatch) => {
                  return (
                    <option value={dbPatch.appVersion} key={dbPatch.appVersion}>
                      {dbPatch.appVersion}
                    </option>
                  );
                })}
              </Form.Select>
            </Form.Group>
            <Form.Group as={Col} xs={12} className="mb-3">
              <Form.Label className="required">Patch No.</Form.Label>
              <Form.Control
                value={dbPatchNo}
                onChange={(e) => setDbPatchNo(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <Form.Group as={Col} xs={12} className="mb-3">
              <Form.Label className="required">DB Type</Form.Label>
              <Form.Select
                value={databaseType}
                onChange={(e) => setDatabaseType(e.target.value)}
              >
                <option value={"MYSQL"}>MYSQL</option>
                <option value={"MSSQL"}>MSSQL</option>
              </Form.Select>
            </Form.Group>
            <Form.Group as={Col} xs={12} className="mb-3">
              <Form.Label className="required">Patch Begin Date</Form.Label>
              <Form.Control
                type="date"
                value={patchBeginDate}
                onChange={(e) => setPatchBeginDate(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <Form.Group as={Col} xs={12} className="mb-3">
              <Form.Label>Patch Content</Form.Label>
              <Form.Control
                value={patchNotes}
                onChange={(e) => setPatchNotes(e.target.value)}
                as={"textarea"}
                rows={2}
                style={{ resize: "none" }}
              />
            </Form.Group>
            <Row className="mb-3">
              <Form.Label>Query</Form.Label>
              {/* <Form.Control
                value={patchSql}
                onChange={(e) => setPatchSql(e.target.value)}
                as={"textarea"}
                rows={15}
                style={{ resize: "none" }}
              /> */}
              <Col style={{ minHeight: "400px" }}>
                <CodeMirrorWrapper>
                  <CodeMirror
                    value={patchSql}
                    height="300px"
                    className="source-container"
                    extensions={[sql(true)]}
                    autoFocus={false}
                    onChange={(value, viewUpdate) =>
                      changeViewSource(value, viewUpdate)
                    }
                  />
                </CodeMirrorWrapper>
              </Col>
            </Row>
          </Row>
        </UModalTemplate>
      </Modal.Body>
      <Modal.Footer>
        <Modal.Footer.Button onClick={onSave}>Save</Modal.Footer.Button>
      </Modal.Footer>
    </Modal>
  );
}

export default DbPatchRegisterPopup;
