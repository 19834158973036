import { Enums } from "components/builder/BuilderEnum";
import BuilderHeaderComponent from "components/builder/BuilderHeaderComponent";
import Message from "components/common/Message";
import StringUtils from "components/common/utils/StringUtils";
import User from "components/common/utils/UserUtils";
import AdminSettingHeader from "components/setting/config/AdminSettingHeader";
import AppManage from "components/setting/config/section/AppManage";
import ComponentManage from "components/setting/config/section/ComponentManage";
import DbPatchInsert from "components/setting/config/section/DbPatchInsert";
import MajorCodeManage from "components/setting/config/section/MajorCodeManage";
import SystemCodeManage from "components/setting/config/section/SystemCodeManage";
import SystemEnvManage from "components/setting/config/section/SystemEnvManage";
import TemplateManage from "components/setting/config/section/TemplateManage";
import React, { createContext, useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

const AdminMenus = {
  APP_MANAGE: {
    key: "APP_MANAGE",
    name: "Application Management",
    component: AppManage,
  },
  COMPONENT_MANAGE: {
    key: "COMPONENT_MANAGE",
    name: "Component Management",
    component: ComponentManage,
  },
  MAJOR_CODE_MANAGE: {
    key: "MAJOR_CODE_MANAGE",
    name: "Code Management",
    component: MajorCodeManage,
  },
  SYSTEM_CODE_MANAGE: {
    key: "SYSTEM_CODE_MANAGE",
    name: "System Code Management",
    component: SystemCodeManage,
  },
  SYSTEM_ENV_MANAGE: {
    key: "SYSTEM_ENV_MANAGE",
    name: "System Env Management",
    component: SystemEnvManage,
  },
  TEMPLATE_MANAGE: {
    key: "TEMPLATE_MANAGE",
    name: "Template Management",
    component: TemplateManage,
  },
  DB_PATCH_INSERT: {
    key: "DB_PATCH_INSERT",
    name: "DB Patch",
    component: DbPatchInsert,
  },
};

export const AdminSettingContext = createContext({
  AdminMenus: {},
  selectedMenu: {},
  setselectedMenu: () => {},
});

function AdminSettingPage(props) {
  const navigate = useNavigate();
  const [selectedMenu, setselectedMenu] = useState(AdminMenus.APP_MANAGE);
  // const [selectedMenu, setselectedMenu] = useState(AdminMenus.DB_PATCH_INSERT);
  const location = useLocation();
  const [currentPath, setCurrentPath] = useState();

  useEffect(() => {
    const CurrentPath = location.pathname.split("/")[2];
    setCurrentPath(CurrentPath);
    return () => {};
  }, [location]);

  useEffect(() => {
    if (!StringUtils.equalsIgnoreCase(User.getAuthType(), "S")) {
      alert("System Manager Only");
      Message.alert("System Manager Only", Enums.MessageType.WARN);
      navigate(-1);
    }
  }, []);

  const contextValue = {
    AdminMenus,
    selectedMenu,
    setselectedMenu,
  };
  if (StringUtils.equalsIgnoreCase(User.getAuthType(), "S")) {
    return (
      <AdminSettingContext.Provider value={contextValue}>
        {StringUtils.equalsIgnoreCase(
          Enums.BuilderPath.ADMIN.SETTING,
          currentPath
        ) ? (
          <AdminSettingHeader
            navigate={navigate}
            {...props.header}
            selectedMenu={selectedMenu}
            setselectedMenu={setselectedMenu}
            AdminMenus={AdminMenus}
          />
        ) : (
          <BuilderHeaderComponent {...props.header} />
        )}
        <Outlet />
      </AdminSettingContext.Provider>
    );
  } else {
    return (
      <div
        style={{
          width: "100%",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        Access denied
      </div>
    );
  }
}

export default AdminSettingPage;
