import { Enums } from "components/builder/BuilderEnum";
import Message from "components/common/Message";
import ArrayUtils from "components/common/utils/ArrayUtils";
import StringUtils from "components/common/utils/StringUtils";
import User from "components/common/utils/UserUtils";
import React, { useEffect, useState } from "react";
import { Button, OverlayTrigger, Popover } from "react-bootstrap";
import SettingService from "services/common/SettingService";
import {
  defaultShortKeySet,
  EditorShortKeyName,
  KeyCodeSet,
  TopNavShortKeyName,
} from "components/setting/section/shortKey/ShortKeyConfig";
import ShortKeyItem from "components/setting/section/shortKey/ShortKeyItem";

function Short() {
  const [keySet, setKeySet] = useState(defaultShortKeySet);

  useEffect(() => {
    const keyset = User.getConfiguration(
      Enums.ConfigurationDefine.SHORT_KEY_SET
    );
    if (keyset) setKeySet({ ...defaultShortKeySet, ...keyset });
  }, []);

  const onSetKey = (keyName, code) => {
    const newKeySet = { ...keySet };
    newKeySet[keyName] = code;
    setKeySet(newKeySet);
  };

  const onSaveKey = (e) => {
    e.preventDefault();
    let pass = true;
    for (let keyArr of Object.values(keySet)) {
      if (!ArrayUtils.isEmpty(keyArr)) {
        const dupCheck = Object.values(keySet).filter((ks) =>
          StringUtils.equalsIgnoreType(ks, keyArr)
        );
        if (dupCheck.length > 1) {
          pass = false;
          break;
        }
      }
    }
    if (!pass) {
      return Message.alert(
        "There are duplicate shortcuts. Please check the shortcuts.",
        Enums.MessageType.ERROR
      );
    }
    const body = {
      configurationCode: Enums.ConfigurationDefine.SHORT_KEY_SET,
      configurationValue: JSON.stringify(keySet),
    };

    SettingService.saveUserConfig(body, (res) => {
      const key = res.data.configurationCode;
      const value = JSON.parse(res.data.configurationValue);
      User.overrideConfig(key, value);
      Message.alert("The shortcut has been set.", Enums.MessageType.SUCCESS);
    });
  };

  const onInitKey = (e) => {
    Message.confirm("Do you want to reset the shortcut?", () => {
      setKeySet(defaultShortKeySet);
    });
  };

  const ShortKeyInfo = (
    <Popover
      title="Possible ShortCut"
      style={{ border: "1px solid lightgray" }}
    >
      <div
        style={{
          width: "100%",
          background: "#3c3c3c",
          color: "white",
          padding: "5px 10px",
        }}
      >
        Possible ShortCut
      </div>
      <div
        style={{
          width: "250px",
          padding: "3px",
          display: "flex",
          gap: "3px",
          overflow: "auto",
          flexWrap: "wrap",
        }}
      >
        {Object.values(KeyCodeSet).map((char) => (
          <div
            style={{
              borderRadius: "5px",
              background: "lightgray",
              padding: "3px",
              minWidth: "30px",
              height: "30px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              border: "2px outset lightgray",
            }}
            key={char}
          >
            {char}
          </div>
        ))}
      </div>
    </Popover>
  );
  return (
    <>
      <div className="setting-header">ShortCut Setting</div>
      <div className="setting-container shortkey">
        <span
          style={{ color: "#3c3c3caa", fontSize: "smaller", padding: "3px 0" }}
        >
          <OverlayTrigger
            trigger={"click"}
            placement="right"
            overlay={ShortKeyInfo}
          >
            <Button variant="outline-secondary" size="sm">
              Show Possible ShortCut
            </Button>
          </OverlayTrigger>
          <br />
          The Ctrl key has been excluded due to conflicts with system events.
          <br />
          Please be aware of events that may occur due to conflicts with browser
          and desktop shortcuts.
          <br />
          e.g.) ALT + F4 (Exit) etc.. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>

        <h4>Builder</h4>
        <div className="keyset">
          <div className="header">
            <div>Function Name</div>
            <div>ShortCut</div>
          </div>

          {Object.keys(TopNavShortKeyName).map((key) => {
            return (
              <ShortKeyItem
                key={key}
                name={TopNavShortKeyName[key]}
                keyMappingName={key}
                keyCode={keySet[key] || defaultShortKeySet[key]}
                onSetKey={onSetKey}
              />
            );
          })}
        </div>
        <h4>Editor</h4>
        <div className="keyset">
          <div className="header">
            <div>Function Name</div>
            <div>ShortCut</div>
          </div>
          {Object.keys(EditorShortKeyName).map((key) => {
            return (
              <ShortKeyItem
                key={key}
                name={EditorShortKeyName[key]}
                keyMappingName={key}
                keyCode={keySet[key] || defaultShortKeySet[key]}
                onSetKey={onSetKey}
              />
            );
          })}
        </div>
      </div>
      <div className="setting-button">
        <Button onClick={onSaveKey}>Save</Button>
        <Button variant="outline-success" onClick={onInitKey}>
          Reset
        </Button>
      </div>
    </>
  );
}

export default Short;
