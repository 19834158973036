import WijmoGrid from "components/common/element/WijmoGrid";
import Modal from "components/common/modal/UModal";
import UModalTemplate from "components/common/modal/UModalTemplate";
import CommonUtils from "components/common/utils/CommonUtils";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Form } from "react-bootstrap";
import ProgramService from "services/ui/ProgramService";
import WorkflowService from "services/workflow/WorkflowService";

const WorkflowReferencePopup = ({ serviceInfo, ...props }) => {
  const [usingProgramList, setUsingProgramList] = useState([]);
  const [usingServiceList, setUsingServiceList] = useState([]);

  useEffect(() => {
    getProgramList();
    getServiceList();
  }, []);

  const getProgramList = () => {
    // "serviceUid": 314
    ProgramService.getWorkflowUsingProgramList(
      {
        serviceUid: serviceInfo.serviceUid,
        appReleaseId: serviceInfo.appReleaseId,
      },
      (res) => {
        setUsingProgramList(res.data);
      }
    );
  };

  const getServiceList = () => {
    // "serviceUid": 380
    WorkflowService.getWorkflowUsingService(
      {
        serviceUid: serviceInfo.serviceUid,
        appReleaseId: serviceInfo.appReleaseId,
      },
      (res) => {
        setUsingServiceList(res.data);
      }
    );
  };

  const programGridColumn = [
    { field: "programId", headerName: "Program ID", width: 150 },
    {
      field: "programNm",
      headerName: "Program Name",
      width: "*",
    },

    {
      field: "updtUserId",
      headerName: "Update User",
      width: 150,
    },
    {
      field: "updtDt",
      headerName: "Update Date",
      width: 150,
      renderCell: (params) =>
        `${CommonUtils.getDate(params.updtDt, "datetime")}`,
    },
  ];

  const workflowGridColumn = [
    {
      field: "serviceUid",
      headerName: "Service UID",
      width: 120,
    },
    {
      field: "serviceId",
      headerName: "Service ID",
      width: 150,
    },
    {
      field: "serviceName",
      headerName: "Service Name",
      width: "*",
    },
    {
      field: "updtUserId",
      headerName: "Update User",
      width: 130,
    },
    {
      field: "updtDt",
      headerName: "Update Date",
      width: 150,
      renderCell: (params) =>
        `${CommonUtils.getDate(params.updtDt, "datetime")}`,
    },
  ];

  return (
    <Modal>
      <Modal.Header title="Referenced Program List" />
      <Modal.Body>
        <UModalTemplate>
          <Form.Label>
            List of programs referenced by workflow execution
          </Form.Label>
          <WijmoGrid
            columns={programGridColumn}
            rows={usingProgramList}
            style={{ height: "300px" }}
            headersVisibility={"Column"}
          />
          <Form.Label>List of Workflow referenced by Service</Form.Label>
          <WijmoGrid
            columns={workflowGridColumn}
            rows={usingServiceList}
            style={{ height: "300px" }}
            headersVisibility={"Column"}
          />
        </UModalTemplate>
      </Modal.Body>
      <Modal.Footer>
        <Modal.Footer.Button>Confirm</Modal.Footer.Button>
      </Modal.Footer>
    </Modal>
  );
};

export default WorkflowReferencePopup;
