import React, { useEffect, useRef, useState } from "react";
import CodeMirror from "@uiw/react-codemirror";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import { json } from "@codemirror/lang-json";
import { useDispatch, useSelector } from "react-redux";
import EntityReduxHelper from "components/builder/entity/editor/helper/EntityReduxHelper";

const EntityCodeMirror = (props) => {
  const dispatch = useDispatch();

  const output = useSelector((state) => state.outputENT.output);

  const [sourceViewOptions, setSourceViewOptions] = useState({
    containEditorAttr: false,
  });
  // const [viewSource, setViewSource] = useState(JSON.stringify(output, null, 2));
  const codeText = useRef(JSON.stringify(output, null, 2));

  const changeViewSource = (value) => {
    if (sourceViewOptions.containEditorAttr) {
      try {
        codeText.current = value;
        // EntityReduxHelper.updateDataModel(dispatch, JSON.parse(value));
      } catch (e) {
        console.log("Json parsing Error");
      }
    }
  };

  useEffect(() => {
    // dispatch(setSidebarCollapse(true));
    return () => {
      EntityReduxHelper.updateDataModel(dispatch, JSON.parse(codeText.current));
      // dispatch(setSidebarCollapse(false));
    };
  }, []);

  return (
    <div className="edit-source">
      <div className="edit-source-options">
        <BootstrapSwitchButton
          checked={sourceViewOptions.containEditorAttr}
          size="sm"
          onstyle="primary"
          offstyle="dark"
          onlabel="Edit On"
          offlabel="Edit Off"
          width={120}
          onChange={(containEditorAttr, e) => {
            setSourceViewOptions({
              ...sourceViewOptions,
              containEditorAttr: containEditorAttr,
            });
          }}
        />
      </div>
      <CodeMirror
        value={codeText.current}
        className="source-container"
        height="calc(100vh - 30px - 50px - 32px)"
        extensions={[json(true)]}
        autoFocus={false}
        editable={sourceViewOptions.containEditorAttr}
        onChange={(value, viewUpdate) => changeViewSource(value, viewUpdate)}
      />
    </div>
  );
};

export default EntityCodeMirror;
