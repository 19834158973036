import * as Enums from "components/builder/BuilderEnum";
import MuiConfig from "components/common/config/MuiConfig";
import ModuleSelector from "components/common/element/ModuleSelector";
import WijmoGrid from "components/common/element/WijmoGrid";
import Modal from "components/common/modal/UModal";
import UModalTemplate from "components/common/modal/UModalTemplate";
import CommonUtils, { StringUtils } from "components/common/utils/CommonUtils";
import User from "components/common/utils/UserUtils";
import { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { BsSearch } from "react-icons/bs";
import { RxOpenInNewWindow } from "react-icons/rx";
import LocalStorageService from "services/common/LocalService";
import ProgramService from "services/ui/ProgramService";
import WorkflowService from "services/workflow/WorkflowService";

function WorkflowListPopupLoad({ workspace, callbackFnc, ...props }) {
  const [isLoading, setIsLoading] = useState(false);
  const [serviceList, setServiceList] = useState([]);

  const [serviceName, setServiceName] = useState("");
  const [pageSize, setPageSize] = useState(20);
  const [selectedRow, setSelectedRow] = useState({});
  const [serviceId, setServiceId] = useState("");
  const [serviceUid, setServiceUid] = useState();
  const [workerId, setWorkerId] = useState("");
  const [selectedModule, setSelectedModule] = useState(
    workspace.moduleCd || ""
  );

  const columns = [
    {
      field: "moduleNm",
      headerName: "Module",
      width: 120,
    },
    {
      field: "tenantId",
      headerName: "Tenant ID",
      width: 120,
    },
    {
      field: "coCd",
      headerName: "CoCd",
      width: 120,
    },
    {
      field: "serviceUid",
      headerName: "UID",
      width: 90,
    },
    {
      field: "serviceId",
      headerName: "Service ID",
      width: 200,
    },
    {
      field: "serviceName",
      headerName: "Service Name",
      width: 210,
    },
    {
      field: "updtUserId",
      headerName: "Update User",
      width: 140,
    },
    {
      field: "updtDt",
      headerName: "Update Date",
      width: 160,
      renderCell: (params) =>
        `${CommonUtils.getDate(params.updtDt, "datetime")}`,
    },
    {
      field: "newTab",
      headerName: "New Tab",
      headerAlign: "center",
      width: 90,
      align: "center",
      renderCell: (params) => {
        return (
          <Button
            size="sm"
            variant="outline-secondary"
            onClick={() => onOpenNewTab(params)}
          >
            <RxOpenInNewWindow />
          </Button>
        );
      },
    },
  ];

  useEffect(() => {
    onSearch();
  }, []);

  /**새탭에서 열기
   * @param {*} workflow
   */
  const onOpenNewTab = (workflow) => {
    //사용자 정보
    LocalStorageService.set(Enums.LocalStorageName.WORKSPACE, {
      userId: User.getId(),
      workspace: workspace,
    });
    const url = `${window.location.protocol}//${window.location.hostname}:${window.location.port}`;
    window.open(
      `${url}/newTabRedirect/workflow/${workflow.serviceUid}`,
      "_blank"
    );
  };

  const onSearch = () => {
    const body = {
      appId: workspace.appId,
      tenantId: workspace.tenantId,
      coCd: workspace.coCd,
      appReleaseId: workspace.appReleaseId,
      serviceUid,
      serviceName,
      serviceId,
      workerId,
    };
    if (!StringUtils.isEmpty(selectedModule)) {
      body.moduleCd = selectedModule;
    }

    WorkflowService.getServiceList(
      body,
      (res) => {
        if (!res?.isError) {
          const { data } = res;
          const list = ProgramService.filterByTenantIdAndCoCd(
            data,
            body.tenantId,
            body.coCd,
            "serviceId"
          );
          setIsLoading(false);
          setServiceList(list);
        }
      },
      () => {
        setIsLoading(false);
      }
    );
  };

  const onClickConfirm = (e) => {
    const body = {
      serviceUid: selectedRow.serviceUid,
      serviceId: selectedRow.serviceId,
      serviceName: selectedRow.serviceName,
    };
    if (callbackFnc) {
      callbackFnc(body);
    }
  };

  const onRowDoubleClick = (param) => {
    const body = {
      serviceUid: param.serviceUid,
      serviceId: param.serviceId,
      serviceName: param.serviceName,
    };
    if (callbackFnc) {
      callbackFnc(body);
    }
  };

  const onKeyDown = (e) => {
    if (e.keyCode === 13) {
      onSearch();
    }
  };

  return (
    <Modal>
      <Modal.Header title="Load Workflow" />
      <Modal.Body>
        <UModalTemplate>
          <Row className="pb-3 pt-3">
            <Col xs={2}>
              <ModuleSelector
                size="small"
                id={"module"}
                value={selectedModule}
                onChange={(e) => setSelectedModule(e.target.value)}
                appId={workspace.appId}
                type={"u"}
                showEmpty
              />
            </Col>
            <Col xs={2}>
              <Form.Control
                label="Service UID"
                placeholder="Service UID"
                value={serviceUid}
                fullWidth={true}
                onChange={(e) => setServiceUid(e.currentTarget.value)}
                onKeyDown={onKeyDown}
              />
            </Col>
            <Col xs={2}>
              <Form.Control
                label="Service ID"
                placeholder="Service ID"
                value={serviceId}
                fullWidth={true}
                onChange={(e) => setServiceId(e.currentTarget.value)}
                onKeyDown={onKeyDown}
              />
            </Col>
            <Col xs={2}>
              <Form.Control
                label="Service Name"
                placeholder="Service Name"
                value={serviceName}
                onChange={(e) => setServiceName(e.currentTarget.value)}
                onKeyDown={onKeyDown}
                fullWidth
              />
            </Col>
            <Col xs={2}>
              <Form.Control
                label="Update User ID"
                placeholder="Update User ID"
                value={workerId}
                onChange={(e) => setWorkerId(e.currentTarget.value)}
                onKeyDown={onKeyDown}
                fullWidth
              />
            </Col>
            <Col xs={1}>
              <Button variant="primary" onClick={onSearch}>
                Search <BsSearch size="12" />
              </Button>
            </Col>
          </Row>
          <Row>
            <Col style={{}}>
              <WijmoGrid
                isLoading={isLoading}
                columns={columns}
                rows={serviceList}
                getRowId={(row) => row.serviceUid}
                onRowClick={(param) => {
                  setSelectedRow(param);
                }}
                onRowDoubleClick={onRowDoubleClick}
                {...MuiConfig.grid.options}
                rowsPerPageOptions={[pageSize]}
                pageSize={pageSize}
                style={{ height: "50vh" }}
              />
            </Col>
          </Row>
        </UModalTemplate>
      </Modal.Body>
      <Modal.Footer>
        {props.remove && (
          <Modal.Footer.Button
            side={"left"}
            onClick={props.remove}
            variant="danger"
          >
            Cancel Apply
          </Modal.Footer.Button>
        )}

        <Modal.Footer.Button onClick={onClickConfirm}>
          Load Workflow
        </Modal.Footer.Button>
      </Modal.Footer>
    </Modal>
  );
}

export default WorkflowListPopupLoad;
