import {
  TextField as MInput,
  Select as MSelect,
  InputLabel as MInputLabel,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Autocomplete,
  TextField,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { BsSearch } from "react-icons/bs";
import Popup from "components/common/Popup";
import User from "components/common/utils/UserUtils";
import UserService from "services/common/UserService";
import * as Enums from "components/builder/BuilderEnum";
import MuiConfig from "components/common/config/MuiConfig";
import MSelectbox from "components/common/element/MSelectbox";
import { AppContext } from "components/common/AppContextProvider";
import CommonUtils, {
  ArrayUtils,
  StringUtils,
} from "components/common/utils/CommonUtils";
import { ImUserPlus } from "react-icons/im";
import PageTemplate from "page/common/PageTemplate";
import UserRegisterPopup from "page/popup/user/UserRegisterPopup";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Message from "components/common/Message";
import CodeService from "services/common/CodeService";
import WijmoGrid from "components/common/element/WijmoGrid";

function UserList() {
  const param = useParams();
  const navigate = useNavigate();
  const appList = useContext(AppContext).application.list;
  const app = appList.find((a) =>
    StringUtils.equalsIgnoreType(a.appId, param.appId)
  );

  const [gridData, setGridData] = useState([]);
  //검색 옵션
  const [userNm, setUserNm] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userAuthType, setUserAuthType] = useState(["*"]);

  const [authList, setAuthList] = useState([]);

  const [selectedAuth, setSelectedAuth] = useState("");
  const [selectedUser, setSelectedUser] = useState([]);
  const [userList, setUserList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const columns = [
    { field: "userId", headerName: "ID", align: "left", width: "*" },
    {
      field: "userNm",
      headerName: "Name",
      renderCell: (params) => {
        return params.userEngNm
          ? `${params.userNm}(${params.userEngNm})`
          : params.userNm;
      },
      // valueGetter: (params) =>
      //   `${
      //     params.row.userEngNm
      //       ? `${params.row.userNm}(${params.row.userEngNm})`
      //       : params.row.userNm
      //   }`,
      width: 300,
    },
    {
      field: "userEmail",
      headerName: "Email",
      width: "*",
    },

    {
      field: "userAuthType",
      headerName: "Authorization",
      width: 150,
      renderCell: (params) => {
        const thisAuth = params.userAuths.find((a) =>
          StringUtils.equalsIgnoreType(a.appId, param.appId)
        );
        const disabled = authList.find((a) =>
          StringUtils.equalsIgnoreCase(a.id, thisAuth.userAuthType)
        ).disabled;
        if (disabled) {
          return <span>{thisAuth.userAuthName}</span>;
        } else {
          return (
            <Form.Select
              size="sm"
              value={thisAuth.userAuthType}
              onClick={(e) => e.stopPropagation()}
              onChange={(e) => onChangeAuth(e, params)}
            >
              {authList.map((auth) => {
                if (!(auth.disabled || auth.id === "S")) {
                  return (
                    <option
                      value={auth.id}
                      key={auth.id}
                      disabled={auth.disabled}
                    >
                      {auth.text}
                    </option>
                  );
                }
              })}
            </Form.Select>
          );
        }
      },
    },
    {
      field: "insertDt",
      headerName: "Insert Date",
      renderCell: (params) => `${CommonUtils.getDate(params.insertDt)}`,
      // valueGetter: (params) => `${CommonUtils.getDate(params.row.insertDt)}`,
    },
    {
      field: "defaultLangCd",
      headerName: "Possible Module",
      description: "Possible Module",
      width: "*",
      renderCell: (params) => {
        const thisAuth = params.userAuths.find((a) =>
          StringUtils.equalsIgnoreType(a.appId, param.appId)
        );
        if (thisAuth && thisAuth.userAuthType === "D") {
          return thisAuth.availableModuleCodes.map((m) => (
            <Button
              size="sm"
              key={m.userAvailableModuleId}
              variant="outline-success"
            >
              {m.moduleNm}
            </Button>
          ));
        } else {
          return <span>{thisAuth.userAuthName}</span>;
        }
      },
    },
    {
      field: "useYn",
      headerName: "User Status",
      renderCell: (params) => <UserStateButton user={params} />,
      align: "center",
    },
    {
      field: "remark",
      headerName: "Manage",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <>
          <Button
            size="sm"
            variant="outline-danger"
            onClick={(e) => onDeleteUser(e, params)}
          >
            Delete
          </Button>
        </>
      ),
    },
  ];

  useEffect(() => {
    const appId = param.appId;
    CodeService.getCodeCombo({ codeMstCd: "Z0019" }, (res) => {
      let limitIndex = res.data.findIndex(
        (c) => c.id === User.getAuthType(appId)
      );
      const _authList = res.data.map((auth, idx) => {
        const obj = { ...auth };
        if (idx > limitIndex) {
          obj.disabled = true;
        }
        return obj;
      });
      res.data = res.data.filter(
        (a) => !StringUtils.equalsIgnoreCase("s", a.id)
      );
      setAuthList(_authList);
      if (
        !StringUtils.equalsIgnoreCase(
          CommonUtils.getAppConfig(app, "appAccessibleYn"),
          "Y"
        )
      ) {
        getUserList({ userAuthTypes: _authList.map((a) => a.id) });
      }
    });
  }, []);

  //회원 목록 조회
  const getUserList = ({ userAuthTypes }) => {
    setIsLoading(true);
    const body = {
      userNm,
      userEmail,
      appId: param.appId,
      userAuthTypes: userAuthTypes
        ? userAuthTypes
        : userAuthType[0] === "*"
        ? authList.map((i) => i.id)
        : userAuthType,
    };
    UserService.getUserList(body, (res) => {
      let appUser = res.data || [];
      setGridData(appUser);
      UserService.getAllUserList({ userAuthTypes }, (userRes) => {
        setIsLoading(false);
        let userList = userRes.data ? [...userRes.data] : [];
        if (userList.length > 0) {
          userList = userList.filter(
            (u) => !appUser.find((gd) => gd.userId === u.userId)
          );
        }
        setUserList(
          userList.map((user) => {
            const obj = { ...user, label: `${user.userId} | ${user.userNm}` };
            return obj;
          })
        );
      });
    });
  };

  /**
   * 엔터키 조회
   * @param {*} event
   */
  const onKeyDownSearch = (e) => {
    if (e.keyCode === 13) {
      getUserList();
    }
  };

  /**
   * DataGrid row 클릭, userDetail
   * @param {*} user
   */
  const onDetailUser = (user) => {
    const userApp = user.userAuths.find((u) =>
      StringUtils.equalsIgnoreType(u.appId, app.appId)
    );

    const disabled = authList.find((a) =>
      StringUtils.equalsIgnoreCase(a.id, userApp.userAuthType)
    );
    if (disabled) {
      return Message.alert(
        "Has No Authorization to view",
        Enums.MessageType.ERROR
      );
    } else
      navigate(
        `${Enums.BuilderPath.USER.MAIN}/detail/${
          param.appId
        }/${StringUtils.encJson(user.userMastRecId)}`
      );
  };

  /**
   * 사용자 등록 팝업 열기
   */
  const onPopupRegister = () => {
    const options = {
      effect: Popup.ScaleUp, //Effect.SlideFromTop(default)를 Effect.ScaleUp 로 변경
      style: {
        content: {
          width: "30%", //popup의 크기를 50% (default 60%)
        },
      },
    };
    Popup.open(<UserRegisterPopup />, options);
  };
  /**
   * 사용자 추가
   * @param {*} e
   * @returns
   */
  const onAddUser = (e) => {
    if (e) e.preventDefault();
    if (ArrayUtils.isEmpty(selectedUser)) {
      return Message.alert("Please select User", Enums.MessageType.INFO);
    }
    if (StringUtils.isEmpty(selectedAuth))
      return Message.alert(
        "Please select Authorization",
        Enums.MessageType.INFO
      );

    const dup = selectedUser.reduce((ac, cu) => {
      return (
        ac ||
        (gridData.find((user) => user.userId === cu.userId) ? true : false)
      );
    }, false);

    if (dup) {
      return Message.alert("User already registered.", Enums.MessageType.INFO);
    } else {
      Message.confirm(`Add ${selectedUser.length} users`, () => {
        const body = {
          appUserList: selectedUser.map((item) => ({
            ...item,
            userAuths: [
              {
                appId: param.appId,
                userAuthType: selectedAuth,
              },
            ],
            requestYn: "N",
            createdYn: "Y",
          })),
        };
        UserService.updateList(body, (res) => {
          Message.alert("Saved Successfully.", Enums.MessageType.SUCCESS);
          getUserList({});
          setSelectedUser([]);
        });
      });
    }
  };

  /**
   * 사용자 담당 권한 변경
   * @param {*} e
   * @param {*} user
   */
  const onChangeAuth = (e, user) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    const auth = e.target.value;

    UserService.update(
      { ...user, appId: param.appId, userAuthType: auth },
      (res) => {
        //회원 정보 덮어 쓰기
        const userIndex = gridData.findIndex((user) =>
          StringUtils.equalsIgnoreType(
            user.userMastRecId,
            res.data.userMastRecId
          )
        );
        const newGridData = [...gridData];
        newGridData.splice(userIndex, 1, res.data);
        setGridData(newGridData);
        Message.alert("Changed Successfully.", Enums.MessageType.SUCCESS);
      }
    );
  };

  /**
   * 사용자 담당 삭제 ===> 삭제후 대상 사용자의 권한(담당 Application)이 없으면 사용자 완전 삭제 알림창을 띄운다.
   * @param {*} e
   * @param {*} user
   */
  const onDeleteUser = (e, user) => {
    e.stopPropagation();
    e.preventDefault();
    Message.confirm("Delete Authorization?", () => {
      const body = {
        appUserList: [
          {
            ...user,
            deletedYn: "Y",
          },
        ],
      };
      UserService.updateList(body, (res) => {
        const resultUser = res.data[0];
        const userIndex = gridData.findIndex((user) =>
          StringUtils.equalsIgnoreType(
            user.userMastRecId,
            resultUser.userMastRecId
          )
        );
        const newGridData = [...gridData];
        newGridData.splice(userIndex, 1);
        setGridData(newGridData);
        if (ArrayUtils.isEmpty(resultUser.userAuths)) {
          Message.confirm(
            "The user no longer has any authorizations. Do you want to permanently delete this user?",
            () => {
              UserService.delete(resultUser, (res) => {
                getUserList({});
                Message.alert(
                  "The User has been deleted.",
                  Enums.MessageType.SUCCESS
                );
              });
            }
          );
        }
      });
    });
  };

  const breadcrum = [
    {
      name: `${
        appList.find((a) => StringUtils.equalsIgnoreType(a.appId, param.appId))
          .appNm
      } User List`,
      active: true,
    },
  ];

  return (
    <PageTemplate breadcrum={breadcrum}>
      <PageTemplate.Box boxClass="mb-0">
        <Form>
          <Row className="pb-3 pt-3 box-line">
            <Col>
              <MInput
                id="entity"
                label="Name"
                type="text"
                fullWidth
                size="small"
                className="xmall-input"
                color="primary"
                placeholder="Name"
                value={userNm}
                onChange={(e) => {
                  setUserNm(e.currentTarget.value);
                }}
                onKeyDown={onKeyDownSearch}
              />
            </Col>
            <Col>
              <MInput
                id="description"
                label="E-mail"
                type="text"
                fullWidth
                size="small"
                className="xmall-input"
                color="primary"
                placeholder="User Email"
                value={userEmail}
                onChange={(e) => setUserEmail(e.currentTarget.value)}
                onKeyDown={onKeyDownSearch}
              />
            </Col>
            <Col>
              <MSelectbox
                fullWidth
                size="small"
                data={[
                  { id: "*", text: "Search All" },
                  ...authList.filter(
                    (auth) => !(auth.disabled || auth.id === "S")
                  ),
                ]}
              >
                <MInputLabel id="auth-label">Authorization</MInputLabel>
                <MSelect
                  name="moduleCd"
                  labelId="auth-label"
                  label="Authorization"
                  fullWidth
                  className="xmall-select"
                  color="primary"
                  value={userAuthType[0]}
                  onChange={(e) => setUserAuthType([e.target.value])}
                ></MSelect>
              </MSelectbox>
            </Col>
            <Col>
              <Button variant="primary" onClick={getUserList}>
                Search <BsSearch size="14" />
              </Button>
            </Col>
          </Row>
        </Form>
        {StringUtils.equalsIgnoreCase(
          CommonUtils.getAppConfig(app, "appAccessibleYn"),
          "Y"
        ) ? (
          <></>
        ) : (
          <Row
            className="mt-3 mb-3"
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Col
              xs={1}
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              Add new Manager
            </Col>
            <Col xs={6}>
              <Autocomplete
                multiple
                id="size-small-outlined-multi"
                size="small"
                options={userList}
                getOptionLabel={(option) => option.label}
                value={selectedUser}
                onChange={(e, user) => setSelectedUser(user)}
                isOptionEqualToValue={(option, value) =>
                  option.userMastRecId === value.userMastRecId
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select User to add this Application."
                    placeholder="select user"
                  />
                )}
              />
            </Col>
            <Col xs={1}>
              <FormControl fullWidth size={"small"}>
                <InputLabel id="authLabel">Authorization</InputLabel>
                <Select
                  label="Authorization"
                  labelId="authLabel"
                  value={selectedAuth}
                  onChange={(e) => setSelectedAuth(e.target.value)}
                >
                  <MenuItem value="">Select</MenuItem>
                  {authList.map((auth) => {
                    return (
                      <MenuItem
                        value={auth.id}
                        key={auth.id}
                        disabled={auth.disabled}
                      >
                        {auth.text}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Col>
            <Col xs={2} style={{ display: "flex", gap: "5px" }}>
              <Button onClick={onAddUser}>Add</Button>
              <Button variant="outline-success" onClick={onPopupRegister}>
                <ImUserPlus /> Add new User
              </Button>
            </Col>
          </Row>
        )}

        <Row>
          <Col
            style={{
              minHeight: "650px",
            }}
          >
            <WijmoGrid
              isLoading={isLoading}
              getRowId={(row) => row.userMastRecId}
              rows={gridData}
              columns={columns}
              onRowDoubleClick={(data) => onDetailUser(data)}
              {...MuiConfig.grid.options}
              pageSize={20}
              rowsPerPageOptions={[20]}
              style={{ height: "60vh" }}
              emptyMessage={
                StringUtils.equalsIgnoreCase(
                  CommonUtils.getAppConfig(app, "appAccessibleYn"),
                  "Y"
                ) ? (
                  "An application for all users cannot have individual user management."
                ) : (
                  <>
                    <div>There are no users in the application.</div>
                    <div>
                      Please register a developer or responsible person.
                    </div>
                  </>
                )
              }
            />
          </Col>
        </Row>
      </PageTemplate.Box>
    </PageTemplate>
  );
}

export default UserList;

const UserStateButton = ({ user, ...props }) => {
  const [btnVariants, setBtnVariants] = useState("outline-primary");
  const [text, setText] = useState("In Use");

  useEffect(() => {
    if (StringUtils.equalsIgnoreCase(user.useYn, "Y")) {
      setBtnVariants("outline-primary");
      setText("In Use");
    } else if (StringUtils.equalsIgnoreCase(user.requestYn, "Y")) {
      setBtnVariants("outline-warning");
      setText("Pending Approval");
    } else {
      setBtnVariants("outline-danger");
      setText("Unused");
    }
  }, [user]);

  const onMouseOver = () => {
    if (StringUtils.equalsIgnoreCase(user.requestYn, "Y")) {
      setText("Approve to Join");
    }
  };
  const onMouseOut = () => {
    if (StringUtils.equalsIgnoreCase(user.requestYn, "Y")) {
      setText("Pending Approval");
    }
  };

  const onClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (StringUtils.equalsIgnoreCase(user.requestYn, "Y")) {
      Message.confirm("Do you want to approve the membership?", () => {
        const { userId } = user;
        UserService.approvalUser({ userId }, (res) => {
          if (!res.isError) {
            if (res.data) {
              Message.alert(
                "Approved Successfully.",
                Enums.MessageType.SUCCESS
              );
            } else {
              Message.alert(
                "An error has occured. Refreshing the list.",
                Enums.MessageType.INFO
              );
            }
          }
        });
      });
    }
  };

  return (
    <Button
      variant={btnVariants}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
      onClick={onClick}
      size="sm"
    >
      {text}
    </Button>
  );
};
