import { Accordion, Button, Form } from "react-bootstrap";
import React from "react";
import { RiDeleteRow, RiInsertRowBottom } from "react-icons/ri";
import { BiDetail } from "react-icons/bi";
import { Box } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import * as Fa from "react-icons/fa";

import LayoutComponent from "components/builder/ui/uiComponents/layout/LayoutComponent";
import {
  ListWidget,
  PropertyLable,
  PropertyValue,
  PropertyValueSet,
} from "components/builder/ui/uiComponents/UIComponentStyle";
import {
  StringUtils,
  ArrayUtils,
  JsonUtils,
  ObjectUtils,
} from "components/common/utils/CommonUtils";
import UIComponentSection from "components/builder/ui/editor/UIComponentSection";
import MuiConfig from "components/common/config/MuiConfig";
import USelectbox from "components/common/element/USelectbox";
import UITemplateHelper from "components/builder/ui/editor/helper/UITemplateHelper";
import Popup from "components/common/Popup";
import TabDetailConfigPopup from "page/popup/TabDetailConfigPopup";
import { AppContext } from "components/common/AppContextProvider";
import UTextarea from "components/common/element/UTextarea";
import produce from "immer";
import { Enums } from "components/builder/BuilderEnum";

class TabContainer extends LayoutComponent {
  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      childComp: [],
    };
  }
  static contextType = AppContext;

  /**
   * determine re-rendering
   * @param {*} nextProps
   * @param {*} nextState
   * @returns
   */
  shouldComponentUpdate(nextProps, nextState) {
    //tab 변경시 editor refresh 허용
    if (nextProps.event === "renderEditor") {
      return (
        super.shouldComponentUpdate(nextProps, nextState) ||
        this.props.activedTab !== nextProps.activedTab
      );
    } else if (nextProps.event === "renderPropertiesPanel") {
      return super.shouldComponentUpdate(nextProps, nextState);
    }
  }

  //load후 실행
  componentDidMount() {
    if (this.props.event === "renderPropertiesPanel") {
      this.setState({ childComp: this.props.fn.getChild() });
    }
  }

  /**
   * element 추가
   * @param {*} element
   */
  onInsertCompClick = (element) => {
    let defaultTabProps = {
      //id: StringUtils.getUuid(),
      id: this.state.propertyValue.id + "t" + (this.state.childComp.length + 1),
      display: true,
    };
    this.props.fn.addComponent(defaultTabProps, "A", element);
  };

  /**
   * element 삭제
   * @param {*} element
   */
  onDeleteCompClick = (element) => {
    this.props.fn.deleteComponent(element);
  };

  /**
   * 신규 element
   */
  onNewCompClick = () => {
    const componentContext = this.context.component;
    /* template 용 표준 base components - e.g) page, form, row, col... */
    const templateComponents =
      UITemplateHelper.getTemplateComponents(componentContext);

    const inputTemplate = UITemplateHelper.getTabTemplate(templateComponents);
    this.onInsertCompClick(inputTemplate);
  };

  /**
   * Tab 상세 설정 버튼 클릭
   * @param {*} event
   */
  onTabDetailClick = (element) => {
    const options = {
      keyDownEvent: false,
      effect: Popup.ScaleUp, //Effect.SlideFromTop(default)를 Effect.ScaleUp 로 변경
      style: {
        content: {
          width: "50%",
        },
      },
    };

    const eventWorkspace = element.propertyValue.eventWorkspace || {};
    const eventName = "displayFnc";
    const getEventInfo = () => {
      return {
        eventWorkspace: eventWorkspace[eventName] || {},
        compId: element.compId,
        eventCd: "tab.display",
        eventType: Enums.EventHandlerEventType.USR_EVENT_FUNCTION,
        builderEventType: eventName,
        targetType: "all",
        originalOutput: element.propertyValue[eventName] || "",
        programType: this.props.output.page.propertyValue?.programType || "M",
      };
    };
    Popup.open(
      <TabDetailConfigPopup
        workspace={this.context.workspace.Info}
        title="Tab Detail Setting"
        item={element.propertyValue}
        containerProps={this.state.propertyValue}
        callbackFnc={(backItems) => {
          if (
            !ObjectUtils.isEmpty(eventWorkspace[eventName]) &&
            element.propertyValue[eventName].replace("\n", "") !==
              backItems[eventName].replaceAll("\n", "")
          ) {
            //함수가 달라지는 경우에 이벤트워크스페이스가 있으면 초기화
            this.showEventChangeConfirmMessage(
              () => {
                delete backItems.eventWorkspace;
                this.props.fn.updateProperty(
                  { ...backItems },
                  { ...element },
                  true
                );
              },
              () => {
                this.props.fn.updateProperty(
                  { ...backItems },
                  { ...element },
                  true
                );
              }
            );
          } else {
            this.props.fn.updateProperty(
              { ...backItems },
              { ...element },
              true
            );
          }
        }}
        getEventInfo={getEventInfo}
        setEventBuilder={() =>
          this.props.fn.onClickEventBuilder(getEventInfo())
        }
      />,
      options
    );
  };

  /**
   * Tab 정보 변경
   * @param {*} event
   * @param {*} element
   * @param {*} index
   */
  onChangeTab = (event, element) => {
    let propertyValue = {
      ...element.propertyValue,
      ...{ [event.target.id]: event.target.value },
    };
    this.props.fn.updateProperty(propertyValue, element);
  };

  /**
   * Properties tab panel을 Redering
   * @returns
   */
  renderPropertiesPanel = () => {
    return (
      <React.Fragment>
        {/* Title */}
        {this.renderComponentTitle("Tab Container")}
        <Accordion defaultActiveKey={[0, 1, 2]} alwaysOpen>
          <Accordion.Item eventKey={0}>
            <Accordion.Header>Basic Info</Accordion.Header>
            <Accordion.Body>
              <PropertyLable>ID</PropertyLable>
              <PropertyValue>
                <input
                  type="text"
                  id="id"
                  defaultValue={StringUtils.defaultString(
                    this.state.propertyValue.id
                  )}
                  className="form-control form-control-sm"
                  onBlur={this.onChange}
                />
              </PropertyValue>
              <PropertyLable>Tab Program</PropertyLable>
              <PropertyValue>
                <USelectbox
                  id="tabType"
                  defaultValue={StringUtils.defaultString(
                    this.state.propertyValue.tabType
                  )}
                  items={[
                    { id: "A", text: "Integrated Step Program" },
                    { id: "S", text: "Individual Step Program" },
                  ]}
                  options={{ matchCd: "id", matchNm: "text" }}
                  onChange={this.onChange}
                />
              </PropertyValue>
              <PropertyLable>Tab Location</PropertyLable>
              <PropertyValue>
                <USelectbox
                  id="position"
                  onChange={this.onChange}
                  defaultValue={StringUtils.defaultString(
                    this.state.propertyValue.position,
                    "top"
                  )}
                  type="common"
                  mstCd="Z0031"
                />
              </PropertyValue>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey={1}>
            <Accordion.Header>Tabs</Accordion.Header>
            <Accordion.Body>
              <PropertyValue className="w-full">
                {!ArrayUtils.isEmpty(this.state.childComp) ? (
                  this.state.childComp.map((element, index) => {
                    return (
                      <PropertyValueSet key={index}>
                        <input
                          type="text"
                          id="name"
                          placeholder="New Tab"
                          defaultValue={StringUtils.defaultString(
                            element.propertyValue.name
                          )}
                          className="form-control form-control-sm"
                          onBlur={(e) => this.onChangeTab(e, element)}
                        />
                        <Button
                          onClick={() => this.onTabDetailClick(element)}
                          size="sm"
                          variant={"outline-secondary"}
                          className={
                            this.props.mobile.isMobileEditor
                              ? "mobile-font-color"
                              : "light-font-color"
                          }
                        >
                          <BiDetail size="20" />
                        </Button>
                        <Button
                          onClick={() => this.onInsertCompClick(element)}
                          size="sm"
                          variant={"outline-secondary"}
                          className={
                            this.props.mobile.isMobileEditor
                              ? "mobile-font-color"
                              : "light-font-color"
                          }
                        >
                          <RiInsertRowBottom size="20" />
                        </Button>
                        <Button
                          onClick={() => this.onDeleteCompClick(element)}
                          size="sm"
                          variant={"outline-secondary"}
                          className={
                            this.props.mobile.isMobileEditor
                              ? "mobile-font-color"
                              : "light-font-color"
                          }
                        >
                          <RiDeleteRow size="20" />
                        </Button>
                      </PropertyValueSet>
                    );
                  })
                ) : (
                  <Button
                    onClick={() => this.onNewCompClick()}
                    size="sm"
                    variant={"outline-secondary"}
                    className={
                      this.props.mobile.isMobileEditor
                        ? "mobile-font-color"
                        : "light-font-color"
                    }
                  >
                    <RiDeleteRow size="20" /> Add New
                  </Button>
                )}
              </PropertyValue>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey={2}>
            <Accordion.Header>Event</Accordion.Header>
            <Accordion.Body>
              <div className="event-list-props">
                <div className="event-list-group">
                  <ListWidget
                    title="Before Tab Change"
                    desc="Executes before changing the Tab. If false is returned, the Tab cannot be changed."
                  >
                    {this.renderEventTextArea(
                      "beforeChange",
                      "Custom Pre-processing for Tab",
                      {
                        eventCd: "tab.change",
                        eventType: "before",
                        returnType: "boolean",
                      }
                    )}
                  </ListWidget>
                  <ListWidget
                    title="After Step Change Tab"
                    desc="Executes after changing the Tab."
                  >
                    {this.renderEventTextArea(
                      "onChange",
                      "Custom Post-processing for Step",
                      {
                        eventCd: "tab.change",
                        eventType: "after",
                      }
                    )}
                  </ListWidget>
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>

          {/* Style property */}
          {this.renderStylePanel("Row", 3)}
        </Accordion>
      </React.Fragment>
    );
  };

  /**
   * Editor의 component를 Redering
   * << Layout editor props>>
   *   - compId - 현재 component의 고유 ID
   *   - componentInfo - drag & drop시 생성된 component object
   *   - style - dragging style이 포함된 style (사용자가 정의한 style[propertyValue.style]은 각 component에서 적절히 적용해야함)
   *   - event="renderEditor" - 요청 구분
   * @returns
   */
  renderEditor = () => {
    let tabConfig = { ...MuiConfig.tabContainer };
    let position = JsonUtils.defaultString(
      this.props.componentInfo.propertyValue,
      "position",
      "top"
    );

    /**
     * UIComponentSection props
     * -item
     * -style
     * -className
     */
    return (
      <UIComponentSection
        item={this.props.componentInfo}
        style={{
          ...this.props.style,
          ...this.getPropsStyle(this.props.componentInfo),
        }}
        className={`editor-base draggable editor-tab ${StringUtils.defaultString(
          this.state.editorAttr.className,
          "builder-tab"
        )}`}
        title="TAB"
      >
        <Box sx={{ ...tabConfig.container[position].sx }}>
          <Tabs
            orientation={
              position === "left" || position === "right"
                ? "vertical"
                : "horizontal"
            }
            variant="scrollable"
            value={this.props.activedTab}
            onChange={this.props.onTabChange}
            aria-label="Tab"
            {...tabConfig.tabs[position]}
          >
            {!ArrayUtils.isEmpty(this.props.componentInfo.child)
              ? this.props.componentInfo.child.map((tab, tabindex) => {
                  const tabProps = tab.propertyValue || {};
                  const IconTag =
                    Fa[StringUtils.convertKebabToPascal(tabProps.icon)];

                  return (
                    <Tab
                      key={tab.compId}
                      className={
                        StringUtils.defaultString(tabProps.id, tab.compId) ===
                        this.props.activedTab
                          ? "editor-actived-tab"
                          : ""
                      }
                      icon={IconTag ? <IconTag size="16" /> : ""}
                      iconPosition={
                        IconTag
                          ? StringUtils.defaultString(
                              tabProps.iconPosition,
                              "start"
                            )
                          : ""
                      }
                      label={StringUtils.defaultString(
                        tabProps.name,
                        "New Tab"
                      )}
                      value={StringUtils.defaultString(tabProps.id, tab.compId)}
                      {...tabConfig.tab[position]}
                    />
                  );
                })
              : ""}
          </Tabs>
          {position === "left" || position === "right"
            ? this.props.children
            : ""}
        </Box>
        {position !== "left" && position !== "right" ? this.props.children : ""}
      </UIComponentSection>
    );
  };
}
export default TabContainer;
