/*!
 * Builder UI Builder Reducers for react v.17
 *
 * UI Builder reducer만 취합 관리
 * - state 명 subfix 는 UI (API Builder : API, Report Builder : RPT)
 *
 *   Author: Bizentro
 *   Date: 2021-04
 */

import DetectActivedComponent from "components/builder/ui/reducers/DetectActivedComponent";
import UIBuilderOutputReducer from "components/builder/ui/reducers/UIBuilderOutputReducer";
import MenuReducer from "components/builder/ui/reducers/MenuReducer";
import CommandReducer from "components/builder/ui/reducers/CommandReducer";
import SettingReducer from "components/builder/ui/reducers/SettingReducer";
import WorckspaceReducer from "components/builder/ui/reducers/WorckspaceReducer";
import SearchConsoleReducer from "components/builder/ui/reducers/SearchConsoleReducer";
import WorkflowReducer from "components/builder/workflow/reducer/WorkflowReducer";
import WorkflowDebugReducer from "components/builder/workflow/reducer/WorkflowDebugReducer";
import EventHandlerReducer from "components/builder/eventhandler/reducer/EventHandlerReducer";
import MobileReducer from "components/builder/mobile/reducers/MobileReducer";
import TechnicalDesignReducer from "components/builder/programDesign/reducer/ProgramDesignReducer";

const UIBuilderReducer = {
  activedUIComponent: DetectActivedComponent,
  outputUI: UIBuilderOutputReducer,
  menu: MenuReducer,
  command: CommandReducer,
  setting: SettingReducer,
  workspace: WorckspaceReducer,
  searchConsole: SearchConsoleReducer,
  workflow: WorkflowReducer,
  workflowDebug: WorkflowDebugReducer,
  eventHandler: EventHandlerReducer,
  mobile: MobileReducer,
  technicalDesign: TechnicalDesignReducer,
};

export default UIBuilderReducer;
