import {
  CommonUtils,
  DaafModal,
  DaafModalTemplate,
  DaafPopup,
} from "@alpha/com.bizentro.daaf.front.framework";
import axios from "axios";
import WijmoGrid from "components/common/element/WijmoGrid";
import { useEffect, useState } from "react";
import TrdService from "services/trd/TrdService";

const TrdListPopup = ({ workspace, ...props }) => {
  const [trdList, setTrdList] = useState();
  const [selectedTrd, setSelectedTrd] = useState(null);

  useEffect(() => {
    getTrdList();
  }, []);

  const getTrdList = async () => {
    const trdResult = await TrdService.getTrdList({
      appEnvId: workspace.appEnv.appEnvId,
    });
    setTrdList(trdResult.data);
  };

  const columns = [
    { field: "trdUid", headerName: "TRD UID" },
    { field: "trdNm", headerName: "TRD Name" },
    { field: "updtUserId", headerName: "Update User" },
    {
      field: "updtDt",
      headerName: "Update Date",
      renderCell: (data) => {
        return <div>{CommonUtils.getDate(data.updtDt)}</div>;
      },
    },
  ];

  const onClickConfirm = (data) => {
    if (props.callback) {
      props.callback(data);
    }
    DaafPopup.close();
  };

  return (
    <DaafModal>
      <DaafModal.Header title="TRD List" />
      <DaafModal.Body>
        <DaafModalTemplate>
          <WijmoGrid
            columns={columns}
            rows={trdList}
            headersVisibility={"Column"}
            style={{ height: "380px" }}
            onRowDoubleClick={(data) => onClickConfirm(data)}
            onRowClick={(data) => setSelectedTrd(data)}
          />
        </DaafModalTemplate>
      </DaafModal.Body>
      <DaafModal.Footer>
        <DaafModal.Footer.Button onClick={() => onClickConfirm(selectedTrd)}>
          Confirm
        </DaafModal.Footer.Button>
      </DaafModal.Footer>
    </DaafModal>
  );
};

export default TrdListPopup;
