import { Enums } from "components/builder/BuilderEnum";
import Message from "components/common/Message";
import ArrayUtils from "components/common/utils/ArrayUtils";
import CommonUtils from "components/common/utils/CommonUtils";
import JsonUtils from "components/common/utils/JsonUtils";
import StringUtils from "components/common/utils/StringUtils";
import produce from "immer";
import { reject } from "lodash";
import { Button, Col, Form, Row } from "react-bootstrap";
import { FaMinus, FaTrash } from "react-icons/fa";
import { MdLibraryAdd } from "react-icons/md";
import { Position, internalsSymbol } from "reactflow";
import WorkflowService from "services/workflow/WorkflowService";

/**
 * Workflow를 렌더링 할때 필요한 유틸 메서드 모음
 * 각 프로세스 또는 엔티티의 데이터 추출 함수 포함
 */

// returns the position (top,right,bottom or right) passed node compared to
function getParams(nodeA, nodeB) {
  const centerA = getNodeCenter(nodeA);
  const centerB = getNodeCenter(nodeB);

  const horizontalDiff = Math.abs(centerA.x - centerB.x);
  const verticalDiff = Math.abs(centerA.y - centerB.y);

  let position;

  // when the horizontal difference between the nodes is bigger, we use Position.Left or Position.Right for the handle
  if (horizontalDiff > verticalDiff) {
    position = centerA.x > centerB.x ? Position.Left : Position.Right;
  } else {
    // here the vertical difference between the nodes is bigger, so we use Position.Top or Position.Bottom for the handle
    position = centerA.y > centerB.y ? Position.Top : Position.Bottom;
  }

  const [x, y] = getHandleCoordsByPosition(nodeA, position);
  return [x, y, position];
}

function getHandleCoordsByPosition(node, handlePosition) {
  // all handles are from type source, that's why we use handleBounds.source here
  const handle = node[internalsSymbol].handleBounds.source.find(
    (h) => h.position === handlePosition
  );

  let offsetX = handle.width / 2;
  let offsetY = handle.height / 2;

  // this is a tiny detail to make the markerEnd of an edge visible.
  // The handle position that gets calculated has the origin top-left, so depending which side we are using, we add a little offset
  // when the handlePosition is Position.Right for example, we need to add an offset as big as the handle itself in order to get the correct position
  switch (handlePosition) {
    case Position.Left:
      offsetX = 0;
      break;
    case Position.Right:
      offsetX = handle.width;
      break;
    case Position.Top:
      offsetY = 0;
      break;
    case Position.Bottom:
      offsetY = handle.height;
      break;
    default:
      break;
  }

  const x = node.positionAbsolute.x + handle.x + offsetX;
  const y = node.positionAbsolute.y + handle.y + offsetY;

  return [x, y];
}

function getNodeCenter(node) {
  return {
    x: node.positionAbsolute.x + node.width / 2,
    y: node.positionAbsolute.y + node.height / 2,
  };
}

// returns the parameters (sx, sy, tx, ty, sourcePos, targetPos) you need to create an edge
export function getEdgeParams(source, target) {
  const [sx, sy, sourcePos] = getParams(source, target);
  const [tx, ty, targetPos] = getParams(target, source);

  return {
    sx,
    sy,
    tx,
    ty,
    sourcePos,
    targetPos,
  };
}

/**
 * 같은 Entity Alias를 사용하고 있는 다른 프로세스가 있는지 확인하여 Boolean으로 리턴
 * 겹칠경우 : true
 * @param {*} newEntityVariable
 * @param {*} workflow
 * @param {*} compId
 * @returns
 */
export const isDuplicateEntityVariable = (
  newEntityVariable,
  workflow,
  compId
) => {
  const process = workflow.service.serviceContent.process;

  const dupCheck = process.find((p) => {
    if (compId && compId !== p.compId)
      if (
        StringUtils.equalsIgnoreCase(
          p.processType,
          Enums.WorkflowProcessType.ENTITY_DEFINITION
        )
      ) {
        return p.entityVariable === newEntityVariable;
      } else {
        return (
          p.inputEntity.entityVariable === newEntityVariable ||
          p.outputEntity.entityVariable === newEntityVariable
        );
      }
  });

  if (dupCheck) {
    Message.alert(
      `The same Entity variable is being used in Process ${dupCheck.processType} ${dupCheck.processNm}`,
      Enums.MessageType.WARN
    );
    return true;
  } else {
    return false;
  }
};

/**
 * Process에서 사용되는 Where 절의 AndOption 컴포넌트
 * @param {{entityFieldList:[],operatorList:[],onDeleteFilter:[],index:Number,filter:Object,onChange:Function}} param0
 * @returns
 */
export const AndOption = ({
  entityFieldList,
  operatorList,
  onDeleteFilter,
  index,
  filter,
  onChange,
  ...props
}) => {
  const onChangeAndOption = (e) => {
    onChange(e, index);
  };

  return (
    <Row className="mb-1">
      <Col xs={2} />
      <Col xs={10}>
        <Row>
          <Col xs={1}>{index !== 0 && (filter.if ? "AND*" : "AND")}</Col>
          <Col xs={4} style={{ padding: 0 }}>
            <Form.Select
              id="column"
              value={filter.column}
              onChange={onChangeAndOption}
            >
              <option value="">선택</option>
              {ArrayUtils.isArray(entityFieldList) &&
                entityFieldList.map((field) => {
                  return (
                    <option value={field.fieldId} key={field.fieldId}>
                      {field.fieldId}
                      {field.columnNm ? ` [ ${field.columnNm} ]` : ""}
                    </option>
                  );
                })}
            </Form.Select>
          </Col>
          <Col xs={4}>
            <Form.Select
              id="operator"
              value={filter.operator}
              onChange={onChangeAndOption}
            >
              <option value="">선택</option>
              {operatorList.map((option, optionIdx) => {
                return (
                  <option key={optionIdx} value={option.codeDtlCd}>
                    {option.codeDtlNm}
                  </option>
                );
              })}
            </Form.Select>
          </Col>
          <Col>
            <Button variant="outline-danger" size="sm">
              <FaTrash size="16" onClick={onDeleteFilter} />
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

/**
 * Process에서 사용되는 Where 절의 AndOption 컴포넌트
 * @param {{filter:Object,operatorList:[], onAddFilter:Function, onDeleteOrFilter:Function, index:Number,onDeleteFilter:Function,onChange:Function,entityFieldList:[]}} param0
 * @returns
 */
export const OrOption = ({
  filter,
  operatorList,
  onAddFilter,
  onDeleteOrFilter,
  index,
  onDeleteFilter,
  onChange,
  entityFieldList,
  ...props
}) => {
  const onChangeOrOption = (e, idx) => {
    onChange(e, index, idx);
  };

  return (
    <Row className="mb-1">
      <Col xs={2} />
      <Col xs={10}>
        <Row>
          <Col xs={1}>{index !== 0 && "AND"}</Col>
          <Col
            xs={10}
            style={{
              border: "1px solid lightgray",
              paddingTop: "10px",
              paddingBottom: "10px",
              borderRadius: "5px",
            }}
          >
            <Row className="mb-1">
              <Col xs={12}>
                <strong>
                  OR{" "}
                  <Button
                    variant="outline-danger"
                    size="sm"
                    onClick={onDeleteFilter}
                  >
                    <FaMinus size={10} />
                  </Button>
                </strong>
              </Col>
            </Row>
            {filter.or.map((_f, idx) => {
              return (
                <Row key={idx} className="mb-1">
                  <Col xs={5}>
                    <Form.Select
                      id="column"
                      value={_f.column}
                      onChange={(e) => onChangeOrOption(e, idx)}
                    >
                      <option value="">Select</option>
                      {entityFieldList.map((field) => {
                        return (
                          <option value={field.fieldId} key={field.fieldId}>
                            {field.fieldId}
                            {field.columnNm ? ` [ ${field.columnNm} ]` : ""}
                          </option>
                        );
                      })}
                    </Form.Select>
                  </Col>
                  <Col xs={5}>
                    <Form.Select
                      id="operator"
                      value={_f.operator}
                      onChange={(e) => onChangeOrOption(e, idx)}
                    >
                      <option value="">Select</option>
                      {operatorList.map((option, optionIdx) => {
                        return (
                          <option key={optionIdx} value={option.codeDtlCd}>
                            {option.codeDtlNm}
                          </option>
                        );
                      })}
                    </Form.Select>
                  </Col>
                  <Col xs={2} style={{ display: "flex", gap: "5px" }}>
                    <Button
                      variant="outline-primary"
                      size="sm"
                      onClick={onAddFilter}
                    >
                      <MdLibraryAdd size="16" />
                    </Button>
                    <Button
                      variant="outline-danger"
                      size="sm"
                      onClick={(e) => onDeleteOrFilter(index, idx)}
                    >
                      <FaTrash size={16} />
                    </Button>
                  </Col>
                </Row>
              );
            })}
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

/**
 * 해당 엔티티의 아웃풋 엔티티를 뽑는 로직
 * @param {*} process
 * @param {*} param1
 * @returns
 */
export const getEntityPropertyValue = (_entity) => {
  let entity = {
    ...CommonUtils.deepCopy(_entity),
    ..._entity.propertyValue,
  };
  const {
    processType,
    processNm,
    entityVariable,
    entityNm,
    tableNm,
    ...propertyValue
  } = entity;

  switch (processType) {
    case Enums.WorkflowProcessType.ENTITY_DEFINITION:
      return [
        {
          compId: entity.compId,
          processNm: processNm,
          entityNm: entityNm,
          entityVariable: entityVariable,
          tableNm: tableNm,
          processType,
          ...propertyValue,
        },
      ];
    case Enums.WorkflowProcessType.STRING_TO_JSON:
      return [
        {
          compId: entity.compId,
          processNm: processNm,
          entityNm: entityNm,
          entityVariable: entityVariable,
          processType,
          ...propertyValue,
        },
      ];
    case Enums.WorkflowProcessType.DATA_AGGREGATION:
    case Enums.WorkflowProcessType.SELECT_ENTITY:
    case Enums.WorkflowProcessType.SELECT_ENTITY_BY_QUERY:
      return [
        {
          compId: entity.compId,
          processNm: processNm,
          entityNm: entity.outputEntity.entityNm,
          entityVariable: entity.outputEntity.entityVariable,
          processType,
          ...propertyValue,
        },
      ];
    case Enums.WorkflowNodeType.SERVICE:
      const outputMapping = entity.outputMapping || [];
      const vList = [];
      for (const output of outputMapping) {
        vList.push({
          compId: entity.compId,
          processNm: entity.serviceName,
          entityNm: output?.entityNm,
          entityVariable: output?.entityVariable, //사용자가 작성한 Variable
          serviceEntityVariable: output?.value, //서비스에서 실제 뱉어내는 Variable
          processType,
          ...propertyValue,
        });
      }
      return vList;

    case Enums.WorkflowProcessType.UNIERP_CONNECTOR:
    case Enums.WorkflowProcessType.REST_API_CONNECTOR:
      const headerOutput = entity.outputHeaderEntity;
      const bodyOutput = entity.outputEntity;
      const responseOutput = entity.responseCode;
      return [
        {
          compId: entity.compId,
          processNm: processNm,
          entityNm: headerOutput?.entityNm,
          entityVariable: headerOutput?.entityVariable,
          processType,
          ...propertyValue,
        },
        {
          compId: entity.compId,
          processNm: processNm,
          entityNm: bodyOutput?.entityNm,
          entityVariable: bodyOutput?.entityVariable,
          processType,
          ...propertyValue,
        },
        {
          compId: entity.compId,
          processNm: processNm,
          entityNm: responseOutput?.entityNm,
          entityVariable: responseOutput?.entityVariable,
          processType,
          ...propertyValue,
        },
      ];
    case Enums.WorkflowNodeType.ITERATOR:
      return [
        {
          compId: entity.compId,
          processNm: entity.processNm,
          entityNm: entity.iteratorNm,
          entityVariable: entity.iteratorVariable,
          tableNm: entity.editorAttr?.tableNm,
          referenceCompId: entity.editorAttr?.referenceCompId,
          processType,
          ...propertyValue,
        },
      ];
    case Enums.WorkflowProcessType.CALL_STORED_PROCEDURE:
      const { parameterList } = entity.propertyValue;
      if (ArrayUtils.isEmpty(parameterList)) return [];
      const outputList = parameterList.filter(
        (p) => p.parameterInOut === "OUT"
      );
      if (ArrayUtils.isEmpty(outputList)) return [];
      let pList = [];
      for (const outParam of outputList) {
        if (!StringUtils.isEmpty(outParam.entityVariable)) {
          pList.push({
            compId: entity.compId,
            processNm: entity.propertyValue.processNm,
            entityNm: outParam.entityFieldVariable,
            entityVariable: outParam.entityVariable,
            processType,
            ...propertyValue,
          });
        }
      }
      return pList;
    default:
      return [
        {
          compId: entity.compId,
          processNm: propertyValue?.processNm,
          entityNm: propertyValue?.entityNm,
          entityVariable: propertyValue?.entityVariable,
          processType,
          ...propertyValue,
        },
      ];
  }
};

/**
 * 커넥터의 ProcessFrom , processTo를 업데이트함
 * @param {*} connector
 * @param {*} compIdMap key value 형태로 oldCompId : newCompId 구조
 * @returns
 */
export const updateConnectorFromTo = (connector, compIdMap) => {
  for (let k in connector) {
    if (typeof connector[k] === "object") {
      updateConnectorFromTo(connector[k], compIdMap);
    } else {
      if ("processFrom" === k || "processTo" === k) {
        connector[k] = compIdMap[connector[k]];
      }
    }
  }
  return connector;
};

/**
 * 서비스 조회 후 서비스의 output 엔티티 리턴
 * @param {*} service
 * @returns {Array}
 */
export const getServiceOutputEntity = (service) => {
  const { serviceId } = service;
  if (!serviceId) return console.error("serviceId is Null");

  return new Promise((resolve, reject) => {
    WorkflowService.getService(service, (res) => {
      const service = JSON.parse(res.data.serviceContent);
      const end = JsonUtils.findNode(
        service,
        "processType",
        Enums.WorkflowProcessType.END_PROCESS
      );
      if (!end) {
        reject("There is no End Process for the service.");
      } else {
        resolve(
          end.propertyValue.returnObject ||
            end.propertyValue.returnVariable ||
            []
        );
      }
    });
  });
};

/**
 * 서비스의 인풋 엔티티 목록 반환
 * @param {*} service
 * @returns
 */
export const getServiceInputEntity = (service) => {
  const { serviceId } = service;
  if (!serviceId) return console.error("serviceId is Null");
  return new Promise((resolve, reject) => {
    WorkflowService.getService(service, (res) => {
      const service = JSON.parse(res.data.serviceContent);
      const start = JsonUtils.findNode(
        service,
        "processType",
        Enums.WorkflowProcessType.START_PROCESS
      );
      if (!start) {
        reject("There is no Start Process for the service.");
      } else {
        const {
          propertyValue: { inputList },
        } = start;
        resolve(inputList || []);
      }
    });
  });
};

/**
 * 서비스에서 StartProcess와 EndProcess를 찾아서
 * inputData와 outputData 를 추출하는 함수
 * @param {*} service
 */
export const getInOutputEntity = (service) => {
  const serviceContent = JSON.parse(service.serviceContent);
  let inputList = [];
  let outputList = [];
  let inputDataType = null;
  const start = JsonUtils.findNode(
    serviceContent,
    "processType",
    "StartProcess"
  );
  if (!start) {
    reject("There is no Start Process for the service.");
  } else {
    inputList = start.propertyValue.inputList;
    inputDataType = start.propertyValue.dataType;
  }
  const end = JsonUtils.findNode(serviceContent, "processType", "EndProcess");
  if (!end) {
    reject("There is no End Process for the service.");
  } else {
    outputList =
      end.propertyValue.returnObject || end.propertyValue.returnVariable || [];
  }

  return {
    inputDataType,
    inputList,
    outputList,
  };
};

/**
 * 서비스의 Input Entity List 와 Ouput Entity를 반환
 * @param {*} service
 * @returns
 */
export const getServiceInOutputEntity = (service) => {
  const { serviceUid } = service;
  if (!serviceUid) {
    Message.alert("Service UID is Empty", Enums.MessageType.ERROR);
    return null;
  }
  return new Promise((resolve, reject) => {
    WorkflowService.getService(
      service,
      (res) => {
        resolve({ ...getInOutputEntity(res.data), service: res.data });
      },
      (err) => {
        reject(err);
      }
    );
  });
};

/**
 * 서비스 엔티티의 정의 항목 검색
 * @param {*} serviceUid
 * @param {*} entityVariable
 * @returns
 */
export const getServiceEntityDefinition = (serviceUid, entityVariable) => {
  if (!serviceUid) return console.error("serviceUid is Null");

  return new Promise((resolve, reject) => {
    WorkflowService.getService({ serviceUid: serviceUid }, (res) => {
      const serviceData = WorkflowService.setData(res.data);
      const {
        serviceContent: {
          service: {
            child: { process: serviceProcess, connector: serviceConnector },
          },
        },
      } = serviceData;
      //EndProcess에서 역추적
      const end = JsonUtils.findNode(
        serviceProcess,
        "processType",
        Enums.WorkflowProcessType.END_PROCESS
      );
      const findResource = (_to) => {
        const conInfo = serviceConnector.filter((c) => c.processTo === _to);
        for (const _con of conInfo) {
          const source = serviceProcess.find(
            (c) => c.compId === _con.processFrom
          );
          let entityPropertyValue = getEntityPropertyValue(source);
          for (const ePvalue of entityPropertyValue) {
            if (ePvalue.entityVariable === entityVariable) {
              if (
                StringUtils.includes(ePvalue.processType, [
                  Enums.WorkflowProcessType.SELECT_ENTITY,
                  Enums.WorkflowProcessType.SELECT_ENTITY_BY_QUERY,
                ])
              ) {
                const _sourceEntity = serviceProcess.find(
                  (c) =>
                    c.compId ===
                    (ePvalue.outputEntity.referenceCompId ||
                      ePvalue.inputEntity.referenceCompId)
                );
                resolve({
                  ..._sourceEntity.propertyValue,
                  processType: Enums.WorkflowNodeType.SERVICE,
                });
              } else if (
                ePvalue.processType ===
                Enums.WorkflowProcessType.ENTITY_DEFINITION
              ) {
                resolve(ePvalue);
              } else {
                resolve(ePvalue);
              }
              break;
            } else {
              findResource(ePvalue.compId);
            }
          }
        }
      };
      findResource(end.compId);
    });
  }).catch((err) => {
    console.log(err);
    reject(err);
  });
};

/**
 * 커넥터 포지션을 지정하는 함수
 * @param {{sourceNode,targetNode,connection,connector}} connection
 * @returns
 */
export const getConnectorPosition = ({
  sourceNode,
  targetNode,
  connection,
  connector,
}) => {
  // source :  "ua53sjlem006", sourceHandle :  "c" ,target :  "ubh6gs5ftzeb", targetHandle :  "b"
  const connectorCompId = connector.compId;

  const Handler = {
    TOP: "a",
    LEFT: "b",
    RIGHT: "c",
    BOTTOM: "d",
  };

  const TARGET_POSITION = {
    T: "top",
    TL: "topLeft",
    TR: "topRight",
    L: "left",
    R: "right",
    BL: "bottomLeft",
    B: "bottom",
    BR: "bottomRight",
  };

  const edgeDetailInfo = {
    from: {
      source: sourceNode.compId,
      target: connectorCompId,
      sourceHandle: connection.sourceHandle,
      targetHandle: connection.targetHandle,
    },
    to: {
      source: connectorCompId,
      target: targetNode.compId,
      sourceHandle: connection.sourceHandle,
      targetHandle: connection.targetHandle,
    },
  };

  /**
   * 노드의 사이즈를 정의함
   * @param {*} node
   * @param {*} position
   * @returns
   */
  const getSize = (node, position) => {
    const _node = {
      width: 323,
      height: 111,
      position,
      XCenter: 0,
      YCenter: 0,
      XMax: 0,
      YMax: 0,
      isInclude: () => {},
      handlerPosition: {
        TOP: {},
        BOTTOM: {},
        LEFT: {},
        RIGHT: {},
      },
    };
    if (node.style) {
      _node.width = node.style.width;
      _node.height = node.style.height;
    }

    _node.XCenter = position.x + _node.width / 2;
    _node.YCenter = position.y + _node.height / 2;
    _node.XMax = position.x + _node.width;
    _node.YMax = position.y + _node.height;

    /**
     * 특정 포지션이 해당 노드 안에 포함 들어가있는지 확인
     * @param {*} _position
     * @returns
     */
    _node.isInclude = (_position) => {
      if (!_position) return false;
      else {
        if (
          position.x <= _position.x &&
          _node.XMax >= _position.x &&
          position.y <= _position.y &&
          _node.YMax >= _position.y
        ) {
          return true;
        } else {
          return false;
        }
      }
    };
    _node.handlerPosition.a = {
      x: position.x + _node.width / 2,
      y: position.y,
    };
    _node.handlerPosition.d = {
      x: position.x + _node.width / 2,
      y: position.y + _node.height,
    };
    _node.handlerPosition.b = {
      x: position.x,
      y: position.y + _node.height / 2,
    };
    _node.handlerPosition.c = {
      x: position.x + _node.width,
      y: position.y + _node.height / 2,
    };

    return _node;
  };

  /**
   * 소스 노드로 부터 타겟노드의 위치를 추정함
   * @param {*} sourcePosition
   * @param {*} targetPosition
   * @returns
   */
  const getTargetDirection = (sourcePosition, targetPosition) => {
    const { position: _sp, XMax: _sXMax, YMax: _sYMax } = sourcePosition;
    const { position: _tp, XMax: _tXMax, YMax: _tYMax } = targetPosition;
    if (_tp.x >= _sp.x && _tXMax <= _sXMax) {
      //소스 노드 가로 범위 안의 경우
      if (_sp.y >= _tYMax) {
        return TARGET_POSITION.T;
      } else if (_sYMax <= _tp.y) {
        return TARGET_POSITION.B;
      }
    } else if (_tp.x <= _sp.x) {
      if (_sp.y >= _tYMax) {
        return TARGET_POSITION.TL;
      } else if (_tp.y >= _sp.y && _tYMax <= _sYMax) {
        return TARGET_POSITION.L;
      } else if (_sYMax <= _tp.y) {
        return TARGET_POSITION.BL;
      } else {
        return TARGET_POSITION.L;
      }
    } else if (_tp.x >= _sXMax) {
      if (_sp.y >= _tYMax) {
        return TARGET_POSITION.TR;
      } else if (_sYMax >= _tYMax && _sp.y <= _tp.y) {
        return TARGET_POSITION.R;
      } else if (_sYMax <= _tp.y) {
        return TARGET_POSITION.BR;
      } else {
        return TARGET_POSITION.R;
      }
    } else {
      return "center";
    }
  };

  const CONNECTOR_SIZE = {
    width: 173,
    height: 51,
  };
  const NO_PROPS_CONNECTOR_SIZE = {
    width: 83,
    height: 51,
  };

  //1. 현재 노드의 위치와 사이즈, 범위를 포함하는 구성 오브젝트를 만든다.
  const SOURCE_NODE = getSize(sourceNode, sourceNode.position);
  //2. 타겟 노드의 위치와 사이즈, 범위를 포함하는 구성 오브젝트를 만든다.
  const TARGET_NODE = getSize(targetNode, targetNode.position);
  //3. 타겟 노드가 소스노드의 어느 위치에 해당하는지 구한다.
  // const TargetDirection = getTargetDirection(SOURCE_NODE, TARGET_NODE);

  const connectorPosition = {
    x:
      (SOURCE_NODE.handlerPosition[connection.sourceHandle].x +
        TARGET_NODE.handlerPosition[connection.targetHandle].x) /
      2,
    y:
      (SOURCE_NODE.handlerPosition[connection.sourceHandle].y +
        TARGET_NODE.handlerPosition[connection.targetHandle].y) /
      2,
  };
  const isNoPropertyConnector = StringUtils.isEmpty(
    connector.propertyValue.connectorNm
  );

  /**
   * 포지션이 겹칠때 가장 가까운 테두리로 위치 이동
   * @param {*} node
   * @param {*} target
   * @returns
   */
  const adjustPosition = (node, target) => {
    const { position: _nodePosition } = node;
    const { position: _targetPosition, XMax, YMax } = target;

    const gap = [
      {
        key: "top",
        value: Math.abs(
          Math.abs(_targetPosition.y) - Math.abs(_nodePosition.y)
        ),
      },
      {
        key: "bottom",
        value: Math.abs(Math.abs(YMax) - Math.abs(_nodePosition.y)),
      },
      {
        key: "left",
        value: Math.abs(
          Math.abs(_targetPosition.x) - Math.abs(_nodePosition.x)
        ),
      },
      {
        key: "right",
        value: Math.abs(Math.abs(XMax) - Math.abs(_nodePosition.x)),
      },
    ];

    gap.sort((a, b) => (a.value > b.value ? 1 : -1));
    switch (gap[0].key) {
      case "top":
        node.position.y -= 50;
        break;
      case "left":
        node.position.x -= 50;
        break;
      case "right":
        node.position.x += 50;
        break;
      case "bottom":
        node.position.y += 50;
        break;
      default:
        break;
    }
    return target.isInclude(node.position)
      ? adjustPosition(node, target)
      : node;
  };

  /**
   * 커넥터에 핸들러 부착
   * @param {*} _connector
   */
  const setHandle = (_connector) => {
    connectorPosition.x = connectorPosition.x - _connector.width / 2;
    connectorPosition.y = connectorPosition.y - _connector.height / 2;
    let _connectorNode = getSize({ style: _connector }, connectorPosition);
    if (TARGET_NODE.isInclude(connectorPosition)) {
      //타겟 노드에 커넥션이 겹쳐 버리면
      _connectorNode = adjustPosition(_connectorNode, TARGET_NODE);
    }
    if (SOURCE_NODE.isInclude(connectorPosition)) {
      //타겟 노드에 커넥션이 겹쳐 버리면
      _connectorNode = adjustPosition(_connectorNode, SOURCE_NODE);
    }

    const soToCo = getTargetDirection(SOURCE_NODE, _connectorNode);
    const coToTg = getTargetDirection(_connectorNode, TARGET_NODE);
    if (soToCo.includes(TARGET_POSITION.T)) {
      edgeDetailInfo.from.targetHandle = Handler.BOTTOM;
    } else if (soToCo.includes(TARGET_POSITION.B)) {
      edgeDetailInfo.from.targetHandle = Handler.TOP;
    } else if (soToCo === TARGET_POSITION.L) {
      edgeDetailInfo.from.targetHandle = Handler.RIGHT;
    } else if (soToCo === TARGET_POSITION.R) {
      edgeDetailInfo.from.targetHandle = Handler.LEFT;
    }

    if (coToTg.includes(TARGET_POSITION.T)) {
      edgeDetailInfo.to.sourceHandle = Handler.TOP;
    } else if (coToTg.includes(TARGET_POSITION.B)) {
      edgeDetailInfo.to.sourceHandle = Handler.BOTTOM;
    } else if (coToTg === TARGET_POSITION.L) {
      edgeDetailInfo.to.sourceHandle = Handler.LEFT;
    } else if (coToTg === TARGET_POSITION.R) {
      edgeDetailInfo.to.sourceHandle = Handler.RIGHT;
    }
  };

  //상호간 오차 범위내에 있으면 그대로 두고 그외의 범위는 커넥터의 핸들을 임의 조정
  if (isNoPropertyConnector) {
    setHandle(NO_PROPS_CONNECTOR_SIZE);
  } else {
    // connectorPosition.x = connectorPosition.x - CONNECTOR_SIZE.width / 2;
    setHandle(CONNECTOR_SIZE);
  }

  return { position: connectorPosition, edgeDetailInfo };
};

/**
 * whow Column 구분하는 함수
 * @param {*} columnNm
 * @returns
 */
export const isWhoColumn = (columnNm) => {
  return StringUtils.includesIgnoreCase(columnNm, Enums.WhoColumns);
};
/**
 * whw field(Field ID of Column) 구분하는 함수
 * @param {*} fieldId
 * @returns
 */
export const isWhoColumnField = (fieldId) => {
  return StringUtils.includesIgnoreCase(fieldId, [
    "coCd",
    "tenantId",
    "insrtUserId",
    "insrtDt",
    "updtUserId",
    "updtDt",
    "pgmId",
    "spNm",
    "ipAddr",
    "clientId",
  ]);
};

/**
 * Workflow Object 에서 sourceId를 사용하는 프로세스 찾기
 * @param {*} sourceId
 * @param {*} workflow
 * @returns
 */
export const getSourceEntity = (sourceId, workflow) => {
  const sourceEntityList = [];
  const {
    service: {
      child: { process, connector },
    },
  } = workflow;

  /**
   * 2. 재귀
   * @param {*} process
   */
  const findProcessLoop = (process) => {
    const targetProcessId = process.compId;
    //2-1. connector에서 source노드를 processTo로 하는 커넥터 찾기
    const toConnectors = connector.filter(
      (c) => c.processTo === targetProcessId
    );
    //2-2. 2-1 에서 찾은 Connector의 processFrom으로 하는 Process 찾기
    if (toConnectors && toConnectors.length > 0) {
      for (const con of toConnectors) {
        const fromProcess = workflow.service.child.process.find(
          (p) => p.compId === con.processFrom
        );
        // 기존에 확인한건지 다시 확인 후 없으면 추가
        // 확인안하면 무한루프 발생
        if (
          fromProcess &&
          !sourceEntityList.find((se) => se.compId === fromProcess.compId)
        ) {
          sourceEntityList.push(fromProcess);
          //2-3. 반복
          findProcessLoop(fromProcess);
        }
      }
    }
  };

  const findIteratorSourceNode = (compId) => {
    // IteratorCompIdList
    let returnValue = {
      parentProcessList: [],
      thisNode: {},
    };
    const findIterator = (processList, parentProcessList = []) => {
      for (const _process of processList) {
        if (StringUtils.equalsIgnoreCase(_process.compId, compId)) {
          returnValue.parentProcessList = parentProcessList;
          returnValue.thisNode = _process;
        } else if (
          StringUtils.equalsIgnoreCase(
            _process.processType,
            Enums.WorkflowNodeType.ITERATOR
          )
        ) {
          parentProcessList.push(_process);
          findIterator(_process.child.process, parentProcessList);
        }
      }
    };
    findIterator(process);
    return returnValue;
  };

  //1. source의 노드 찾기
  const sourceProcess = process.find((p) => p.compId === sourceId);
  if (sourceProcess) {
    sourceEntityList.push(sourceProcess);
    findProcessLoop(sourceProcess);
  } else {
    /**
     * Iterator에 소속된 Process인 경우
     * 1. 해당 이터레이터의 DEF 찾기
     * X 2. 부모 프로세스에서 DEF 찾기
     * X 3. 부모도 Iterator에 소속된 프로세스 이면 재귀
     *
     * 자기 Iterator 안에서만 조회
     */

    const findIteratorProcessLoop = (parentNode, targetNode) => {
      //2-1. connector에서 source노드를 processTo로 하는 커넥터 찾기
      const toConnectors = parentNode.child.connector.filter(
        (c) => c.processTo === targetNode.compId
      );
      //2-2. 2-1 에서 찾은 Connector의 processFrom으로 하는 Process 찾기
      if (toConnectors && toConnectors.length > 0) {
        toConnectors.map((con) => {
          const fromProcess = parentNode.child.process.find(
            (p) =>
              p.compId === con.processFrom &&
              p.type === Enums.WorkflowNodeType.PROCESS
          );
          if (fromProcess) {
            sourceEntityList.push(fromProcess);
            //2-3. 반복
            findIteratorProcessLoop(parentNode, fromProcess);
          }
        });
      }
    };

    const { parentProcessList = [], thisNode } =
      findIteratorSourceNode(sourceId);
    sourceEntityList.push(thisNode);
    const parentNode = parentProcessList[parentProcessList.length - 1];
    findIteratorProcessLoop(parentNode, thisNode);
  }
  return sourceEntityList;
};

/**
 * Source Node로 부터 targetNode까지 있는 노드 배열 구하기
 * 중간에 뻗어나온 노드들이 멈춘경우에는 중간에 포함한다.
 * @param {*} sourceNode
 * @param {*} targetNode
 * @returns
 */
export const getNodeBetweenList = (sourceNode, targetNode, nodes) => {
  //target까지 이어진 노드 리스트
  let betweenNodeList = {};
  //한번 탐색했었던 node
  const checkedNodeList = {};
  //배열로 해도 되지만 indexing이 필요없기 때문에 전부 key:value 형태로 생성
  let end = false;

  /**
   * Source노드로 부터 찾기
   * @param {*} sNode
   * @param {Object} nodeListOnPath
   */
  const findNextConnectorAndProcess = (sNode, nodeListOnPath = {}) => {
    if (end) return false;
    checkedNodeList[sNode.id] = true;

    //커넥터가 아니면
    if (
      !StringUtils.includes(sNode.type, [
        Enums.WorkflowNodeType.CONNECTOR,
        Enums.WorkflowNodeType.PROCESS_EDGE,
      ])
    ) {
      //프로세스를 from으로 하는 커넥터 찾기
      const connectorFromSource = nodes.filter(
        (n) =>
          n.type === Enums.WorkflowNodeType.CONNECTOR &&
          n.data.connector.processFrom === sNode.id
      );
      //커넥터가 없는 경우는 종료 프로세스와 마찬가지이기 때문에 between노드 목록에 추가한다.
      if (ArrayUtils.isEmpty(connectorFromSource)) {
        if (!betweenNodeList[sNode.id]) {
          betweenNodeList = {
            ...betweenNodeList,
            ...nodeListOnPath,
          };
        }
      } else {
        //커넥터는 프로세스에서 여러개가 나올수 있으니 루프 탐색
        connectorFromSource.forEach((con) => {
          //경로 저장
          if (!checkedNodeList[con.id]) {
            findNextConnectorAndProcess(con, {
              ...nodeListOnPath,
              [con.id]: con,
            });
          } else {
            //성공까지 이어진 노드리스트 목록에 찾고자 하는 id가 있으면 묶음으로 이어지기 때문에
            // 기존에 정의한 nodeListOnPath와 기 정의된 betweenNodeList를 합친다.
            if (betweenNodeList[con.id]) {
              betweenNodeList = { ...betweenNodeList, ...nodeListOnPath };
            }
          }
        });
      }
    } else if (
      StringUtils.equalsIgnoreCase(sNode.type, Enums.WorkflowNodeType.CONNECTOR)
    ) {
      //커넥터가 바라보는 프로세스 찾기
      const sourceFromConnector = nodes.find(
        (n) => n.id === sNode.data.connector.processTo
      );
      if (!sourceFromConnector) return false;
      //프로세스를 경로 변수에 넣기
      if (sourceFromConnector.id === targetNode.id) {
        nodeListOnPath[sourceFromConnector.id] = sourceFromConnector;
        betweenNodeList = { ...betweenNodeList, ...nodeListOnPath };
      } else {
        if (!checkedNodeList[sourceFromConnector.id]) {
          findNextConnectorAndProcess(sourceFromConnector, {
            ...nodeListOnPath,
            [sourceFromConnector.id]: sourceFromConnector,
          });
        } else {
          if (betweenNodeList[sourceFromConnector.id]) {
            betweenNodeList = { ...betweenNodeList, ...nodeListOnPath };
          }
        }
      }
    }
  };
  findNextConnectorAndProcess(sourceNode, { [sourceNode.id]: sourceNode });
  //리턴되는 것은 배열형태로 한다.
  return Object.values(betweenNodeList);
};

/**
 * 선택된 노드들이 움직일때
 * 하위 노드에서 종료 노드까지 포지션을 반영하여
 * 같이 움직일 수 있도록 하위노드들을 반환한다.
 * @param {*} changedNode
 * @param {*} workflow
 */
export const getLowerNodeChanged = (changedNodeList, workflow, nodes) => {
  /**
   * 1. workflow output에서 changedNodeList[0] 에 해당하는 노드를 검색한다.
   * 2. 1번에서 나온 노드와 changedNodeList[0]의 포지션을 비교한다. 증감된 x,y 값 도출
   * 3. workflow output에서 1번 노드에서 종료 노드까지 연결된 모든 노드를 찾는다.
   * 4. 3번에서 나온 노드 배열에 2번에서의 포지션 변경값을 반영한다.
   * 5. 4번의 결과값을 리턴한다.
   *
   * ** 그룹 안의 노드의 경우 그룹을 이동시키고 수정된 그룹은 중복 수정되지 않도록 한다.
   */

  const bundleChildMap = {};

  if (workflow.output.bundle) {
    workflow.output.bundle.forEach((bundle) => {
      bundle.propertyValue.nodeList.forEach((child) => {
        bundleChildMap[child.bundleCompId] = bundle;
      });
    });
  }

  const prevOutput = workflow.output;
  //1. 바뀐 노드들 중 첫번째 것을 구한다. 없으면 리턴
  const changedNode = changedNodeList[0];
  if (!changedNode) return [];
  // workflow output에서 검색
  const beforeChangeNode = JsonUtils.findNode(
    prevOutput,
    "compId",
    changedNode.compId
  );
  //2. 포지션 구하기 changedPosition
  // 2-1 . 그룹(번들) 내부의 자녀 노드일경우 번들 이동
  // if(bundleChildMap[changedNode.compId]){

  // }else{

  // }
  const changedPosition = {
    x: changedNode.position.x - beforeChangeNode.position.x,
    y: changedNode.position.y - beforeChangeNode.position.y,
  };
  //함수 변수 타입에 맞게 변현
  const changedSourceNode = nodes.find(
    (node) => node.id === changedNode.compId
  );
  const endProcessNode = nodes.find(
    (node) =>
      node.type === Enums.WorkflowNodeType.PROCESS_EDGE &&
      node.data.process.processType === "EndProcess"
  );

  // 바뀐 노드에서 종료 노드까지 구하기
  const nodeListToEndProcess = getNodeBetweenList(
    changedSourceNode,
    endProcessNode,
    nodes
  );

  //포지션 반영하기
  const positionUpdatedNodeList = nodeListToEndProcess.map((node) => {
    let nodeObj = null;
    if (node.data.connector) {
      //커넥터
      nodeObj = {
        ...node.data.connector,
        position: {
          x: node.position.x + changedPosition.x,
          y: node.position.y + changedPosition.y,
        },
      };
    } else {
      //프로세스
      nodeObj = {
        ...node.data.process,
        position: {
          x: node.position.x + changedPosition.x,
          y: node.position.y + changedPosition.y,
        },
      };
    }

    return nodeObj;
  });
  //노드 목록 반환
  return positionUpdatedNodeList;
};

/**
 * Data를 탐색하여 일치하는 키가 있는 경우 value를 교체 한다.
 * @param {Object} data 탐색할 데이터
 * @param {Object} keyObject 데이터를 바꿀 타겟 key 셋 {compId :'aaa', referenceCompId : 'aaa'}
 * @param {Object} changeObject 데이터를 바꿀 key value 셋
 * @returns
 */
export const findAndChange = (data, keyObject, changeObject) => {
  if (!data) return false;

  let cpData = CommonUtils.deepCopy(data);
  const recursive = (lData) => {
    if (ArrayUtils.isArray(lData)) {
      lData.forEach((data) => recursive(data));
    } else if (typeof lData === "object" && lData !== null) {
      for (const key in lData) {
        if (
          lData.hasOwnProperty(key) &&
          keyObject.hasOwnProperty(key) &&
          keyObject[key] === lData[key]
        ) {
          for (const changeKey in changeObject) {
            if (changeObject[changeKey]) {
              // 키가 찾는 키와 일치하면 값을 변경
              if (lData[changeKey]) lData[changeKey] = changeObject[changeKey];
            }
          }
        } else {
          recursive(lData[key]);
        }
      }
    }
  };
  recursive(cpData);
  return cpData;
};
