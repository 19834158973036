import React, { useEffect, useState } from "react";
import Modal from "components/common/modal/UModal";

import DataModelService from "services/datamodel/DataModelService";
import CodeService from "services/common/CodeService";
import CommonUtils, { StringUtils } from "components/common/utils/CommonUtils";

import { Button, Col, Form, Row } from "react-bootstrap";
import { BsSearch } from "react-icons/bs";
import ProgramService from "services/ui/ProgramService";
import WijmoGrid from "components/common/element/WijmoGrid";
import UModalTemplate from "components/common/modal/UModalTemplate";
import LocalStorageService from "services/common/LocalService";
import { Enums } from "components/builder/BuilderEnum";
import User from "components/common/utils/UserUtils";
import { RxOpenInNewWindow } from "react-icons/rx";
import MuiConfig from "components/common/config/MuiConfig";

function DataModelListPopup({
  title,
  callbackFnc,
  params,
  workspace,
  isSharedCall = "N",
  ...props
}) {
  const [selectedRow, setSelectedRow] = useState({});
  const [gridData, setGridData] = useState([]);
  const [pageSize, setPageSize] = useState(20);
  const [dataModelNm, setDataModelNm] = useState("");
  const [dataModelId, setDataModelId] = useState("");
  const [description, setDescription] = useState("");

  const onClickConfirm = (e) => {
    const body = {
      insertUserId: selectedRow.insertUserId,
      insertDt: selectedRow.insertDt,
      updtUserId: selectedRow.updtUserId,
      updtDt: selectedRow.updtDt,
      userId: selectedRow.userId,
      ...selectedRow,
    };
    if (callbackFnc) {
      callbackFnc(body);
    }
  };

  const onRowDoubleClick = (param) => {
    const body = {
      insertUserId: param.insertUserId,
      insertDt: param.insertDt,
      updtUserId: param.updtUserId,
      updtDt: param.updtDt,
      userId: param.userId,
      pageSize,
      ...param,
    };
    if (callbackFnc) {
      callbackFnc(body);
    }
  };

  const onKeyPress = (event) => {
    if (event.key === "Enter") {
      search();
    }
  };

  const search = () => {
    /* Grid data */
    const body = {
      ...workspace,
      appId: workspace.appId,
      tenantId: workspace.tenantId,
      coCd: workspace.coCd,
      dataModelId,
      dataModelNm,
      description,
    };
    DataModelService.getDataModelList(body, (res) => {
      const list = ProgramService.filterByTenantIdAndCoCd(
        res.data,
        workspace.tenantId,
        workspace.coCd,
        "dataModelNm"
      );
      setGridData(list);
    });
  };

  /**새탭에서 열기
   * @param {*} datamodel
   */
  const onOpenNewTab = (datamodel) => {
    //사용자 정보
    LocalStorageService.set(Enums.LocalStorageName.WORKSPACE, {
      userId: User.getId(),
      workspace: workspace,
    });
    const url = `${window.location.protocol}//${window.location.hostname}:${window.location.port}`;
    window.open(
      `${url}/newTabRedirect/datamodel/${datamodel.dataModelId}`,
      "_blank"
    );
  };

  useEffect(() => {
    /* Module Combo 조회*/
    let comboParams = { codeMstCd: "Z0000" };
    CodeService.getCodeCombo(comboParams, (res) => {
      search();
    });
  }, []);

  const columns = [
    { field: "tenantId", headerName: "Tenant ID", width: 110 },
    { field: "coCd", headerName: "Company Code", width: 100 },
    { field: "dataModelId", headerName: "Data Model UID", width: 100 },
    { field: "dataModelNm", headerName: "Data Model Name", width: "*" },
    { field: "description", headerName: "Data Model Description", width: "*" },
    { field: "dataModelTypeNm", headerName: "Data Model Type", width: 150 },
    { field: "updtUserId", headerName: "Update User", width: 150 },
    {
      field: "updtDt",
      headerName: "Update Date",
      width: 150,
      align: "center",
      renderCell: (params) => CommonUtils.getDate(params.updtDt, "datetime"),
    },
    {
      field: "newTab",
      headerName: "New Tab",
      headerAlign: "center",
      width: 80,
      align: "center",
      renderCell: (params) => {
        return (
          <Button
            size="sm"
            variant="outline-secondary"
            onClick={() => onOpenNewTab(params)}
          >
            <RxOpenInNewWindow />
          </Button>
        );
      },
    },
  ];

  return (
    <Modal>
      <Modal.Header title="Load DataModel" />
      <Modal.Body>
        <UModalTemplate>
          <Row className="pb-3 pt-3">
            <Col xs={2}>
              <Form.Control
                label="Data Model UID"
                placeholder="Search Data Model UID..."
                value={dataModelId}
                fullWidth={true}
                onChange={(e) => setDataModelId(e.currentTarget.value)}
                onKeyPress={onKeyPress}
              />
            </Col>
            <Col xs={2}>
              <Form.Control
                label="Data Model Name"
                placeholder="Data Model Name"
                value={dataModelNm}
                onChange={(e) => setDataModelNm(e.currentTarget.value)}
                onKeyPress={onKeyPress}
                fullWidth={true}
              />
            </Col>
            <Col xs={2}>
              <Form.Control
                label="Data Model Description"
                placeholder="Data Model Description"
                value={description}
                onChange={(e) => setDescription(e.currentTarget.value)}
                onKeyPress={onKeyPress}
                fullWidth
              />
            </Col>
            <Col xs={1}>
              <Button variant="primary" onClick={search}>
                Search <BsSearch size="14" />
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <WijmoGrid
                getRowId={(row) => row.dataModelId}
                rows={gridData}
                columns={columns}
                onRowClick={(param) => {
                  setSelectedRow(param);
                }}
                onRowDoubleClick={onRowDoubleClick}
                {...MuiConfig.grid.options}
                rowsPerPageOptions={[pageSize]}
                pageSize={pageSize}
                style={{ height: "50vh" }}
              />
            </Col>
          </Row>
        </UModalTemplate>
      </Modal.Body>
      <Modal.Footer>
        {props.remove && (
          <Modal.Footer.Button
            side={"left"}
            onClick={props.remove}
            variant="danger"
          >
            Cancel Application
          </Modal.Footer.Button>
        )}

        <Modal.Footer.Button onClick={onClickConfirm}>
          Load DataModel
        </Modal.Footer.Button>
      </Modal.Footer>
    </Modal>
  );
}

export default DataModelListPopup;
