import React, { useContext, useEffect, useState } from "react";
import StringUtils from "components/common/utils/StringUtils";
import { AppContext } from "components/common/AppContextProvider";
import { Form } from "react-bootstrap";
import ArrayUtils from "components/common/utils/ArrayUtils";
import Api from "components/common/Api";
import ObjectUtils from "components/common/utils/ObjectUtils";
import DataModelService from "services/datamodel/DataModelService";

/**
 *  history: 22.03.08 init
 *
 *  > USelectbox 사용방법 예제
 *      <USelectbox id="select" value="this.state.propertyValue.select" onChange={this.onChange} />
 *
 *  > Prors 항목
 *    - id      : selectbox ID(필수)
 *    - onChange: change event(필수)
 *    - type    : Default static  | static = 고정 / common = 공통 / search = 조회
 *    - value   : Default ""      | 값
 *    - options : Default {}      | selectbox에 사용될 options
 *    - items   : Defailt []      | 고정일때 사용될 items
 *    - mstCd   :                 | 공통일때 사용될 Master Code
 *    - params  : Default {}      | 조회일때 사용될 전달 값
 *    - url     : Default ""      | 조회일때 사용될 접근 URL
 */
const USelectbox = (props) => {
  const {
    type,
    defaultValue,
    id,
    options,
    items,
    mstCd,
    params,
    url,
    onChange,
    name,
    addItems,
    extIds,
    entityId,
    size,
    disabled,
    ...attrProps
  } = props;
  const [pInfo, setPInfo] = useState({
    pType: type,
    pValue: defaultValue || "",
    pId: id,
    pOptions: options || {},
    pItems: ArrayUtils.isEmpty(items) ? [] : items,
    pMstCd: mstCd,
    pParams: params || {},
    pUrl: url || "",
    pOnChange: onChange,
    pName: name,
    pAddItems: addItems,
  });
  const { pType, pId, pOptions, pMstCd, pParams, pUrl } = pInfo;
  const [value, setValue] = useState(pInfo.pValue);
  const [matchCd, setMatchCd] = useState(
    pOptions.matchCd || pOptions.matchId || "codeDtlCd"
  );
  const [matchNm, setMatchNm] = useState(pOptions.matchNm || "codeDtlNm");
  // const [items, setItems] = useState(pInfo.pItems || []);
  const codeList = useContext(AppContext).code;

  //init: 최초실행
  const initSelectBox = () => {
    //type별로 items 조회 구조 변경
    if (pType === "common") {
      selectboxCommonMst(pMstCd, pOptions);
    } else if (pType === "commonMst") {
      selectboxCommonList(pOptions);
    } else if (pType === "search") {
      selectboxSearchData(pOptions);
    } else if (pType === "entityField") {
      selectDataBindingList(entityId, pOptions);
    }
  };

  const eventChangeIdVal = (val) => {
    eventChangeFn({ target: { id: pId, value: val } });
  };

  //eventChangeFn: select의 값이 변경되었을 때 실행
  const eventChangeFn = (e) => {
    // setPInfo({
    //   ...pInfo,
    //   pValue: e.target.value,
    // });
    setValue(e.target.value);

    if (!StringUtils.isEmpty(pInfo.pOnChange)) {
      //item[matchCd]가 integer일 경우, String으로 변환하여 비교한다.
      const item =
        pInfo.pItems.filter(
          (item) => String(item[matchCd]) === String(e.target.value)
        )[0] || {};

      onChange(
        {
          target: {
            name: e.target.name,
            id: e.target.id,
            value: item[matchCd],
            text: item[matchNm],
            _data: item,
          },
        },
        item,
        e
      );

      // pInfo.pOnChange.call(
      //   this,
      //   {
      //     target: {
      //       name: e.target.name,
      //       id: e.target.id,
      //       value: item[matchCd],
      //       text: item[matchNm],
      //       _data: item,
      //     },
      //   },
      //   item,
      //   e
      // );
    }
  };

  /**
   * 공통코드 combo
   * Selectbox - Code
   * @param {String} pMstCd
   * @param {Map} pOptions
   * @returns
   */
  const selectboxCommonMst = (pMstCd, pOptions) => {
    const list = ObjectUtils.isEmpty(codeList)
      ? []
      : codeList.getCodeList(pMstCd);
    setPInfo({
      ...pInfo,
      pItems: list,
    });
    // setItems(codeList.getCodeList(pMstCd));
  };

  /**
   * 공통코드 Master combo
   * Selectbox - Code
   * @param {Map} options
   * @returns
   */
  const selectboxCommonList = (pOptions) => {
    setPInfo({
      ...pInfo,
      pItems: codeList,
    });
    // setItems(codeList);
  };

  /**
   * 조회 Select Data
   * @param {Map} pOptions
   */
  const selectboxSearchData = (pOptions) => {
    if (
      !ObjectUtils.isEmpty(pOptions) &&
      !ObjectUtils.isEmpty(pOptions["beforeChkFn"]) &&
      !pOptions.beforeChkFn.call(this)
    ) {
      return;
    }
    //pParams, pUrl 사용
    //db접근하여 select
    if (!StringUtils.isEmpty(pUrl)) {
      Api.post(pUrl, pParams, (res) => {
        if (res.data) {
          let data = res.data.slice();

          if (matchCd.indexOf(".") !== -1) {
            const idArr = matchCd.split(".");
            data.map((d, i) => {
              d["pParams"] = pInfo.pParams;

              if (StringUtils.isEmpty(d[idArr[0]])) {
                d[idArr[0]] = d["pParams"][idArr[0]];
              }
              d[matchCd] = d[idArr[0]] + "." + d[idArr[1]];
            });
          }

          setPInfo({
            ...pInfo,
            pItems: data,
          });
          // setItems(data);

          if (!StringUtils.isEmpty(pOptions.callbackFn)) {
            pOptions.callbackFn.call(this, data);
          }
        }
      });
    }
  };

  /**
   * Data Model Entity Field List 조회(Data Binidng)
   * @param {*} entityId
   */
  const selectDataBindingList = (entityId, pOptions) => {
    if (
      !ObjectUtils.isEmpty(pOptions) &&
      !ObjectUtils.isEmpty(pOptions["beforeChkFn"]) &&
      !pOptions.beforeChkFn.call(this)
    ) {
      return;
    }

    if (!StringUtils.isEmpty(entityId)) {
      DataModelService.getDataBindingList({ entityId: entityId }, (res) => {
        if (
          !ObjectUtils.isEmpty(pOptions) &&
          !ObjectUtils.isEmpty(pOptions["afterCallBackFn"])
        ) {
          pOptions["afterCallBackFn"].call(this, res);
        }
        setPInfo({
          ...pInfo,
          pItems: res.data ? res.data : [{}],
        });
      });
    } else {
      setPInfo({
        ...pInfo,
        pItems: [{}],
      });
    }
  };
  //최초 실행
  useEffect(() => {
    initSelectBox();
  }, []);

  //defaultValue가 변경될 때 실행
  useEffect(() => {
    setValue(props.defaultValue);
  }, [props.defaultValue]);

  //items가 변경될 때 실행
  useEffect(() => {
    if (!ArrayUtils.isEmpty(props.items)) {
      let eItems = props.items.slice();
      if (
        !ObjectUtils.isEmpty(props.options) &&
        !ObjectUtils.isEmpty(props.options.filter)
      ) {
        //filter.call( codeItem, codeIndex, selectProps, allItems )
        eItems = eItems.filter((codeItem, codeIndex) =>
          props.options.filter.call(
            this,
            codeItem,
            codeIndex,
            props,
            props.items
          )
        );
      }

      // setItems(props.items);
      setPInfo({
        ...pInfo,
        pItems: eItems,
      });
    }
  }, [props.items]);

  /*
  //addItems가 변경될 때 실행
  useEffect(() => {
    if (!ArrayUtils.isEmpty(props.addItems)) {
      setPInfo({
        ...pInfo,
        pItems: props.addItems,
      });
    }
  }, [props.addItems]);
*/
  //addItems가 변경될 때 실행
  useEffect(() => {
    if (!StringUtils.isEmpty(props.entityId)) {
      let dataModelOption = props.pOptions;

      //이전에 선택한 Entity field가 존재 하지 않을 경우 (DataModel 에서 해당 Entity의 field를 삭제시)
      //Entity 조회 후 callback 함수를 통해 경고 메시지를 Combo item에 삽입한다.
      if (!StringUtils.isEmpty(value)) {
        if (!dataModelOption) {
          dataModelOption = {};
        }
        dataModelOption.afterCallBackFn = (res) => {
          const warningItem = {
            [matchCd]: value,
            [matchNm]: "※ No results found.",
          };

          if (res && res.data) {
            let matchedItems = res.data.filter(
              (item, index) => item[matchCd] === value
            );
            //이전에 선택된 value가 combo item에 존재 하지 않을 경우
            if (matchedItems.length === 0) {
              res.data.push(warningItem);
            }
          } else {
            if (!res) {
              res = { data: warningItem };
            } else {
            }
            res.data = [warningItem];
          }
        };
      }

      selectDataBindingList(props.entityId, dataModelOption);
    }
  }, [props.entityId]);

  return (
    <Form.Select
      size={size || "sm"}
      value={value}
      id={pId}
      onChange={eventChangeFn}
      name={pInfo.pName}
      disabled={disabled}
      {...attrProps}
    >
      {StringUtils.isEmpty(pOptions.isChoose) || pOptions.isChoose ? (
        <option value="">{`${
          StringUtils.isEmpty(pOptions.chooseText)
            ? pType === "entityField"
              ? "No Matching Found"
              : "select"
            : pOptions.chooseText
        }`}</option>
      ) : (
        ""
      )}
      {!ArrayUtils.isEmpty(pInfo.pAddItems)
        ? pInfo.pAddItems.map((code, index) => {
            let displayNm =
              code[matchNm] != null ? code[matchNm] : code[matchCd];

            if (!ObjectUtils.isEmpty(pOptions.displayModify)) {
              displayNm = eval(pOptions.displayModify);
            }
            return (
              <option key={StringUtils.getUuid()} value={code[matchCd]}>
                {displayNm}
              </option>
            );
          })
        : ""}
      {!ArrayUtils.isEmpty(pInfo.pItems)
        ? pInfo.pItems.map((code, index) => {
            if (
              ArrayUtils.isEmpty(extIds) ||
              extIds.indexOf(code[matchCd]) < 0
            ) {
              let displayNm =
                code[matchNm] != null ? code[matchNm] : code[matchCd];

              if (!ObjectUtils.isEmpty(pOptions.displayModify)) {
                displayNm = eval(pOptions.displayModify);
              }
              return (
                <option key={index} value={code[matchCd]}>
                  {displayNm}
                </option>
              );
            }
          })
        : ""}
    </Form.Select>
  );
};

export default USelectbox;
