import axios from "axios";

const {
  BaseService,
  ArrayUtils,
  StringUtils,
  DaafMessage,
} = require("@alpha/com.bizentro.daaf.front.framework");

class ElementService extends BaseService {
  static url = process.env.REACT_APP_DATA_STUDIO_API_URL + "/element";

  static sendPost = async (api, param) => {
    return (await axios.post(this.url + api, param)).data;
  };

  /**
   * Element 목록 호출
   * @param {*} data
   * @returns
   */
  static getElementList = async (data) => {
    return this.sendPost("/getElementContainsDomainList", data);
  };

  /**
   * Element Tab에서 사용하는 ElementList 호출
   * @param {Object} data
   * @param {Array} data.elementIds
   * @returns
   */
  static getElementListByElementIds = async (data) => {
    return this.sendPost("/getElementListByElementIds", data);
  };

  /**
   * Element 호출
   * @param {*} data
   * @returns
   */
  static getElement = async (data) => {
    return this.sendPost("/getElement", data);
  };
}

export default ElementService;
