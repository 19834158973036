import React, { Component } from "react";
import PropTypes from "prop-types";
import BuilderSidebarContextMenu from "../contextMenu/BuilderSidebarContextMenu";
import { FaDatabase } from "react-icons/fa";
import {
  AiFillEdit,
  AiOutlineFileAdd,
  AiOutlineFileSync,
  AiOutlineHistory,
  AiOutlineUnorderedList,
} from "react-icons/ai";
import { Enums } from "../BuilderEnum";
import { Form } from "react-bootstrap";
import ComponentService from "services/datamodel/DataModelService";
import ProgramService from "services/ui/ProgramService";
import User from "components/common/utils/UserUtils";
import { setDataModelEditable } from "page/dataModel/DataModelList";
import {
  activateComponent,
  updateDataModel,
} from "./reducers/EntityBuilderAction";
import Message from "components/common/Message";
import { connect } from "react-redux";
import EntityReduxHelper from "./editor/helper/EntityReduxHelper";
import { RxOpenInNewWindow } from "react-icons/rx";
import LocalStorageService from "services/common/LocalService";
import StringUtils from "components/common/utils/StringUtils";

class DataModelMenu extends BuilderSidebarContextMenu {
  constructor(props) {
    super(props);
    this.goToPage = this.goToPage.bind(this);
    this.openNewBlank = this.openNewBlank.bind(this);
    this.state = {
      searchResultList: [],
      recentWorkList: [],
      dataList: [],
      dataLoading: false,
      searchTerm: "",
    };
  }

  componentDidMount() {
    this.getRecentMenuList(Enums.LocalStorageName.DATAMODEL_HISTORY);
  }

  /**
   * 데이터 모델 호출 및 편집 모드
   * @param {*} event
   * @param {*} dataModel
   */
  onLoadData(event, dataModel) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    this.setState({
      dataLoading: true,
    });
    ComponentService.getDataModel(
      {
        dataModelId: dataModel.dataModelId,
      },
      (res) => {
        const dataModel = setDataModelEditable(res.data);
        this.dispatch(updateDataModel(dataModel));
        this.dispatch(activateComponent(dataModel));
        this.setState({
          dataLoading: false,
        });
        this.goToPage(Enums.BuilderPath.ENTITY.CREATE);
      },
      () =>
        this.setState({
          dataLoading: false,
        })
    );
  }

  /**
   * 데이터 목록 불러옴
   * @returns {Promise}
   */
  onLoadDataList() {
    //검색 후보 목록 호출
    this.setState({
      dataLoading: true,
    });
    return new Promise((resolve, reject) => {
      ComponentService.getDataModelList(
        { ...this.props.workspace },
        (res) => {
          if (!res?.isError) {
            const { data } = res;
            const list = ProgramService.filterByTenantIdAndCoCd(
              data,
              this.props.workspace.tenantId,
              this.props.workspace.coCd,
              "dataModelNm"
            );
            resolve([...list]);
          }
        },
        () => {
          this.setState({
            dataLoading: false,
          });
        }
      );
    });
  }

  //신규 모델 생성
  openNewBlank() {
    const initDtm = () => {
      const newDm = {
        dataModelNm: "",
        moduleCd: this.props.workspace.moduleCd,
        description: "",
        dataModelType: "D",
        apiUrl: "",
        entity: "",
        compId: StringUtils.getUuid(),
        type: Enums.EntityComponentType.DATA_MODEL,
        dataModelEntities: [],
        useYn: "Y",
      };
      this.props.createDataModel(newDm);
      this.goToPage(Enums.BuilderPath.ENTITY.CREATE);
    };

    if (this.props.output.dataModelEntities?.length > 0) {
      Message.confirm(
        "There is a program in progress. \n Unsaved changes will be deleted. Continue?",
        () => {
          initDtm();
        }
      );
    } else {
      initDtm();
    }
  }

  onLoadProgram(e, _dm, cb) {
    ComponentService.getDataModel(
      _dm,
      (res) => {
        const dataModel = setDataModelEditable(res.data);
        this.props.updateDataModel(dataModel);
        this.props.updateActivedComponent(dataModel);
        this.goToPage(Enums.BuilderPath.ENTITY.CREATE);
        if (cb) cb();
      },
      () => {
        if (cb) cb();
      }
    );
  }

  /**
   * 해당 페이지 이동
   * @param {String} url
   */
  goToPage(url) {
    this.navigate(Enums.BuilderPath.ENTITY.MAIN + "/" + url);
    this.menuClose();
  }

  /**새탭에서 열기 */
  onOpenNewTab = (e, datamodel) => {
    e.stopPropagation();
    //사용자 정보
    LocalStorageService.set(Enums.LocalStorageName.WORKSPACE, {
      userId: User.getId(),
      workspace: this.workspace,
    });
    const url = `${window.location.protocol}//${window.location.hostname}:${window.location.port}`;
    window.open(
      `${url}/newTabRedirect/datamodel/${datamodel.dataModelId}`,
      "_blank"
    );
  };

  renderMenuContents() {
    return (
      <div>
        {this.renderTitle(FaDatabase, "Data Model Builder")}
        <div className="menu-list">
          {this.renderContextMenu(AiFillEdit, "Data Model Editor", (e) =>
            this.goToPage(Enums.BuilderPath.ENTITY.CREATE)
          )}
          {this.renderContextMenu(AiOutlineFileAdd, "New Data Model", (e) =>
            this.openNewBlank()
          )}
          {this.renderContextMenu(
            AiOutlineUnorderedList,
            "Data Model List",
            (e) => this.goToPage(Enums.BuilderPath.ENTITY.LIST)
          )}
          {this.renderContextMenu(
            AiOutlineFileSync,
            "Data Model Batch Update",
            (e) => this.goToPage(Enums.BuilderPath.ENTITY.PATCH)
          )}
          {this.renderContextMenu(
            AiOutlineHistory,
            "Data Model Update List",
            (e) => this.goToPage(Enums.BuilderPath.ENTITY.HISTORY)
          )}
        </div>
        <hr />
        <div className="sub-title">Search Data Model</div>
        <div className="search-area">
          <Form.Control
            className="work-search-input"
            placeholder="Enter Data Model Name."
            onChange={(e) =>
              this.onSearchData(e, ["dataModelNm", "dataModelId"])
            }
            value={this.state.searchTerm}
          />
        </div>
        <div className="sub-list">
          {this.state.searchResultList.map((work) => {
            return (
              <div
                className="work"
                key={work.dataModelId}
                onClick={(e) => this.onLoadData(e, work)}
              >
                <div className="col-2">{work.dataModelNm}</div>
                <div className="col-1">
                  <button
                    type="button"
                    onClick={(e) => this.onOpenNewTab(e, work)}
                  >
                    <RxOpenInNewWindow />
                  </button>
                </div>
              </div>
            );
          })}
        </div>
        <div className="sub-title">Recently Modified Data Model</div>
        <div className="sub-list">
          {this.renderRecentMenuList(Enums.LocalStorageName.DATAMODEL_HISTORY)}
        </div>
      </div>
    );
  }
}

const dispatchNewBlankPage = (dispatch) => {
  return {
    updateDataModel: (dataModel) => {
      EntityReduxHelper.updateDataModel(dispatch, dataModel);
    },
    removeDataModel: () => {
      EntityReduxHelper.removeDataModel(dispatch);
    },
    createDataModel: (newDataModel) => {
      EntityReduxHelper.openNewModel(dispatch, newDataModel);
    },
    updateActivedComponent: (id, value) => {
      EntityReduxHelper.activateComponentProperty(dispatch, { id, value });
    },
  };
};

export default connect((state) => {
  return {
    output: state.outputENT.output,
    workspace: state.workspace,
  };
}, dispatchNewBlankPage)(DataModelMenu);
