/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from "react";
import { BsPencilSquare } from "react-icons/bs";
import Popup from "components/common/Popup";
import Modal from "components/common/modal/UModal";
import SunEditor from "suneditor-react";

import StringUtils from "components/common/utils/StringUtils";
import ObjectUtils from "components/common/utils/ObjectUtils";

/**
 * ExtendTextEditorPopup : textarea을 확장해서 입력할 text editor Popup
 *
 * 2022.03.11: init: donghoon
 *
 * @param {*} props
 * @returns value
 */
const ExtendTextEditorPopup = (props) => {
  const { title, defaultValue, callbackFnc, setValue } = props;
  const [popVal, setPopVal] = useState("");

  // closePopup: value값을 전달하고 popup 닫기
  const closePopup = (e) => {
    let val = StringUtils.isEmpty(popVal) ? "" : popVal;

    if (!ObjectUtils.isEmpty(setValue)) {
      setValue(val);
    }
    if (!ObjectUtils.isEmpty(callbackFnc)) {
      callbackFnc.call(this, val);
    }
    Popup.close();
  };
  //initPopup: popup open 하고 실행
  const initPopup = () => {
    setPopVal(defaultValue); //popup Value 값 변경
  };
  const onChangeEditor = (value) => {
    setPopVal(value);
  };
  useEffect(() => {
    initPopup();
  }, []);

  return (
    <Modal>
      <Modal.Header title={title} />
      <Modal.Body style={{ overflowX: "hidden" }}>
        <SunEditor
          lang={"ko"}
          setDefaultStyle="font-family: 'Noto Sans KR', sans-serif;  height : 600px; max-height : 55vh"
          autoFocus={true}
          setContents={defaultValue}
          onChange={onChangeEditor}
          setOptions={{
            font: [
              "Noto Sans KR",
              "Arial",
              "Comic Sans MS",
              "Courier New",
              "Impact",
              "Georgia",
              "tahoma",
              "Trebuchet MS",
              "Verdana",
            ],
            fontSize: [
              8, 9, 10, 11, 12, 13, 14, 16, 18, 20, 22, 24, 26, 28, 36, 48, 72,
            ],
            buttonList: [
              ["font", "fontSize", "formatBlock"],
              [
                "bold",
                "underline",
                "italic",
                "strike",
                "fontColor",
                "hiliteColor",
              ],
              [
                "align",
                "outdent",
                "indent",
                "horizontalRule",
                "list",
                "lineHeight",
                "table",
              ],
              [
                "paragraphStyle",
                "blockquote",
                "subscript",
                "superscript",
                "textStyle",
                "removeFormat",
              ],
              ["link", "image", "codeView", "preview"],
              ["undo", "redo"],
            ],
          }}
        />
      </Modal.Body>
      <Modal.Footer>
        <Modal.Footer.Button onClick={closePopup}>
          <BsPencilSquare />
          &nbsp;Confirm
        </Modal.Footer.Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ExtendTextEditorPopup;
