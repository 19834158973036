import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";

/**
 * Program Save, deploy에 사용되는 팝업의 HOC 입니다.
 * @returns
 */
function withProgramSavePopup(Component) {
  return (properties) => {
    const {
      title,
      callbackFnc,
      information,
      output,
      saveAs,
      updatePageByProps,
      workspace,
      callbackDeployFnc,
      connection,
      ...props
    } = properties;
    const [programId, setProgramId] = useState("");
    const [programNm, setProgramNm] = useState("");
    const [description, setdescription] = useState("");
    const [expandSaveDetail, setExpandSaveDetail] = useState(true);

    useEffect(() => {
      if (information) {
        setProgramId(information.programId);
        setProgramNm(information.programNm);
        setdescription(information.description);
      }
      if (output.page.propertyValue) {
        const { propertyValue: pageInfo } = output.page;
        setProgramId(pageInfo?.programId);
        setProgramNm(pageInfo?.programName);
        setdescription(pageInfo?.programDesc);
      }
      if (saveAs) {
        setProgramId("");
      }
    }, []);

    /**
     * 저장 경로 (Workspace render)
     * @returns
     */
    const renderSavePath = () => {
      return (
        <Form.Group className="mb-3">
          <Form.Label
            onClick={(e) => setExpandSaveDetail(!expandSaveDetail)}
            style={{ cursor: "pointer" }}
          >
            Check Save Path {expandSaveDetail ? "▲" : "▼"}
          </Form.Label>
          {expandSaveDetail ? (
            <>
              <Row>
                <Form.Group className="mb-3" as={Col}>
                  <Form.Label>Application</Form.Label>
                  <Form.Control disabled defaultValue={workspace.appNm} />
                </Form.Group>
                <Form.Group className="mb-3" as={Col}>
                  <Form.Label>Module</Form.Label>
                  <Form.Control disabled defaultValue={workspace.moduleNm} />
                </Form.Group>
                <Form.Group className="mb-3" as={Col}>
                  <Form.Label>Version</Form.Label>
                  <Form.Control disabled defaultValue={workspace.version} />
                </Form.Group>
              </Row>
              <Row>
                <Form.Group as={Col} className="mb-3">
                  <Form.Label>Tenant ID</Form.Label>
                  <Form.Control disabled defaultValue={workspace.tenantId} />
                </Form.Group>

                <Form.Group as={Col}>
                  <Form.Label>Company Code</Form.Label>
                  <Form.Control disabled defaultValue={workspace.coCd} />
                </Form.Group>
              </Row>
            </>
          ) : (
            <></>
          )}
        </Form.Group>
      );
    };

    const onChangeProgramId = (e) => {
      let value = e.currentTarget.value.toUpperCase().split(" ").join("");
      setProgramId(value);
    };
    const onChangeProgramName = (e) => {
      let value = e.currentTarget.value;
      setProgramNm(value);
    };
    const onChangeProgramDesc = (e) => {
      let value = e.currentTarget.value;
      setdescription(value);
    };

    /**
     * 공통 인풋 입력
     * @returns
     */
    const renderCommonInput = () => {
      return (
        <>
          <Form.Group className="mb-3">
            <Form.Label className="required">Program ID</Form.Label>
            <input
              type="text"
              id="programId"
              name="programId"
              className="form-control"
              onChange={onChangeProgramId}
              value={programId}
              disabled={saveAs ? false : information ? true : false}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label className="required">Program Name</Form.Label>
            <input
              type="text"
              id="programNm"
              name="programNm"
              className="form-control"
              onChange={onChangeProgramName}
              value={programNm}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Program Description</Form.Label>
            <textarea
              id="description"
              className="form-control form-control-sm"
              onChange={onChangeProgramDesc}
              defaultValue={description}
              rows={3}
              style={{ resize: "none" }}
            />
          </Form.Group>
        </>
      );
    };

    return (
      <Component
        {...properties}
        programId={programId}
        programNm={programNm}
        description={description}
        renderSavePath={renderSavePath}
        renderCommonInput={renderCommonInput}
      />
    );
  };
}

export default withProgramSavePopup;
