import CommonUtils, {
  ArrayUtils,
  StringUtils,
} from "components/common/utils/CommonUtils";
import { debounce } from "components/common/utils/InputUtils";
import ObjectUtils from "components/common/utils/ObjectUtils";
import {
  default as User,
  default as UserUtils,
} from "components/common/utils/UserUtils";
import produce from "immer";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import SunEditor from "suneditor-react";
import { setDesign } from "../reducer/ProgramDesignAction";
import "css/builder/programDesign/functionalSpecifications.css";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { RiDeleteBinLine } from "react-icons/ri";

export const ScrollDiv = styled.div`
  padding-left: 100px;
  padding-right: 100px;
  max-height: calc(100vh - 200px);
  overflow-x: hidden;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background: #ccc;
  }
`;

const FunctionalSpecification = ({
  designMst,
  flag,
  setDesignMst,
  findMultilang,
  ...props
}) => {
  const [designMultilang, setDesignMultilang] = useState([]);
  const editor = useRef([]);
  const [fdTitleList, setFdTitleList] = useState([]);
  const [fdContentList, setFdContentList] = useState([]);
  const [editorContent, setEditorContent] = useState({});

  const workspace = useSelector((state) => state.workspace);

  const dispatch = useDispatch();

  const FUNC = {
    KEY_T: "fd_key_title",
    KEY_C: "fd_key_content",
    OTHER_T: "fd_other_title",
    OTHER_C: "fd_other_content",
    ADD_T: "fd_add_title",
    ADD_C: "fd_add_content",
    KEY: "fd_key",
    OTHER: "fd_other",
    ADD: "fd_add",
  };

  useEffect(() => {
    if (editorContent !== undefined) {
      onChangeDesignMultilang(editorContent);
    }
  }, [editorContent]);

  useEffect(() => {
    if (!ArrayUtils.isEmpty(designMst.designMultilang)) {
      setDesignMultilang(designMst.designMultilang || []);
      const tmpFdTitleList = designMst.designMultilang?.filter(
        (item) =>
          item.multilangCd.includes("fd") && item.multilangCd.includes("title")
      );
      setFdTitleList(tmpFdTitleList || []);
      const tmpFdContentList = designMst.designMultilang?.filter(
        (item) =>
          item.multilangCd.includes("fd") &&
          item.multilangCd.includes("content")
      );
      setFdContentList(tmpFdContentList || []);
    } else {
      setFdTitleList([]);
      setFdContentList([]);
      // editor.current.forEach((item) => {
      //   if (typeof item !== "undefined") {
      //     item.setContents("");
      //   }
      // });
    }
  }, [designMst]);

  useEffect(() => {
    if (flag) {
      setDesignMst({});
      setDesignMultilang([]);
    }
  }, [flag]);

  /**
   * editor blur - designMst.designMultilang 데이터가 변경되어도 초기값만 가지고 있어 로직 수정
   * @param {*} e
   */
  const onBlurEditor = (e, item, idx) => {
    const data = {
      item: item,
      idx: idx,
      content: editor.current[idx].getContents(),
    };

    setEditorContent(data);
  };

  /**
   * designMultilang 변경
   * @param {*} key
   * @param {*} value
   */
  const onChangeDesignMultilang = (contentItem) => {
    if (!ObjectUtils.isEmpty(contentItem)) {
      let tmpItem = { ...contentItem.item };
      tmpItem.multilangText = contentItem.content;

      const index2 = designMultilang.findIndex(
        (item) => item.multilangCd === tmpItem.multilangCd
      );
      const tmpDesignMultiLang = [...designMultilang];
      if (index2 !== -1) {
        tmpDesignMultiLang.splice(index2, 1, tmpItem);
      }

      setDesignMultilang(tmpDesignMultiLang);
      onChangeDesignMst("designMultilang", tmpDesignMultiLang);
    }
  };

  /**
   * title바꿀 경우
   * @param {*} e
   * @param {*} multilangCd
   */
  const onChangeTitle = (e, multilangCd) => {
    let tmpMultiLang = [...designMultilang];
    let tmpItem = {
      ...tmpMultiLang.find((item) => item.multilangCd === multilangCd),
    };
    tmpItem.multilangText = e.target.value;
    const idx = tmpMultiLang.findIndex((item) =>
      StringUtils.equalsIgnoreCase(item.multilangCd, multilangCd)
    );
    if (idx !== -1) {
      tmpMultiLang.splice(idx, 1, tmpItem);
      setDesignMultilang(tmpMultiLang);
      onChangeDesignMst("designMultilang", tmpMultiLang);
    }
  };

  /**
   * designMst 변경
   * @param {*} key
   * @param {*} value
   */
  const onChangeDesignMst = (key, value) => {
    if (ObjectUtils.isObject(designMst)) {
      const newDesignMst = produce(designMst, (draft) => {
        draft[key] = value;
      });
      setDesignMst(newDesignMst);
      mstDebounce(newDesignMst);
    }
  };

  /**
   * redux 패치
   */
  const mstDebounce = debounce((newDesignMst) => {
    dispatch(setDesign(newDesignMst));
  }, 200);

  const getSunEditorInstance = (sunEditor, idx) => {
    editor.current[idx] = sunEditor;
  };

  const addSpecification = (key) => {
    let lastIdx = 1;
    if (!ArrayUtils.isEmpty(fdTitleList)) {
      const lastItem = fdTitleList.at(fdTitleList.length - 1);
      var regex = /[^0-9]/g;
      lastIdx = parseInt(lastItem.multilangCd.replace(regex, "")) + 1;
    }

    const newTitle = {
      designMstId: designMst.designMstId,
      langCd: User.getLanguage().toLowerCase(),
      insertUserId: User.getId(),
      updtUserId: User.getId(),
    };
    const newContent = {
      designMstId: designMst.designMstId,
      langCd: User.getLanguage().toLowerCase(),
      insertUserId: User.getId(),
      updtUserId: User.getId(),
    };
    if (key === FUNC.KEY) {
      newTitle.multilangCd = FUNC.KEY_T + lastIdx;
      newContent.multilangCd = FUNC.KEY_C + lastIdx;
    } else if (key === FUNC.OTHER) {
      newTitle.multilangCd = FUNC.OTHER_T + lastIdx;
      newContent.multilangCd = FUNC.OTHER_C + lastIdx;
    } else {
      newTitle.multilangCd = FUNC.ADD_T + lastIdx;
      newContent.multilangCd = FUNC.ADD_C + lastIdx;
    }
    let tmpTitleList = [...fdTitleList, newTitle];
    let tmpContentList = [...fdContentList, newContent];

    setFdTitleList(tmpTitleList);
    setFdContentList(tmpContentList);

    let tmpDesignMultiLang = [...designMultilang, newTitle, newContent];
    setDesignMultilang(tmpDesignMultiLang);
    onChangeDesignMst("designMultilang", tmpDesignMultiLang);
  };

  const deleteBtn = (e, key, idx) => {
    const tmpMultilangList = designMst.designMultilang.filter(
      (item) =>
        item.multilangCd !== key + "_title" + idx &&
        item.multilangCd !== key + "_content" + idx
    );

    setDesignMultilang(tmpMultilangList);
    onChangeDesignMst("designMultilang", tmpMultilangList);
  };

  return (
    <React.Fragment>
      {/* 기본 내용 */}
      {/* <Row>
        <Col>
          <Card style={{ backgroundColor: "rgb(237 242 250)" }}>
            <Card.Body>
              <Card.Text style={{ textAlign: "center" }}>
                Functional Specification
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row> */}
      <ScrollDiv>
        <div>
          <Row>
            <Col>
              <div className="content-title">1. Key Features</div>
            </Col>
            <Col>
              <Button
                className="add-btn"
                size="sm"
                variant="outline-primary"
                onClick={(e) => addSpecification(FUNC.KEY)}
              >
                Add Specification
              </Button>
            </Col>
          </Row>
          {!ArrayUtils.isEmpty(fdContentList)
            ? fdContentList.map((item) => {
                var regex = /[^0-9]/g;
                const idx = parseInt(item.multilangCd.replace(regex, ""));

                if (item.multilangCd.includes("key"))
                  return (
                    <>
                      <Row>
                        <Col sm={4}>
                          <Form.Control
                            placeholder="Enter Title"
                            defaultValue={
                              fdTitleList.find(
                                (title) =>
                                  title.multilangCd === FUNC.KEY_T + idx
                              )?.multilangText
                            }
                            onChange={(e) => onChangeTitle(e, FUNC.KEY_T + idx)}
                          ></Form.Control>
                        </Col>
                        <Col>
                          {idx !== 1 ? (
                            <Button
                              className="del-btn"
                              size="sm"
                              variant="outline-danger"
                              onClick={(e) => {
                                deleteBtn(e, FUNC.KEY, idx);
                              }}
                            >
                              <RiDeleteBinLine />
                            </Button>
                          ) : (
                            ""
                          )}
                        </Col>
                      </Row>
                      <Row style={{ marginBottom: "10px" }}>
                        <Col>
                          <SunEditor
                            id={item.multilangCd}
                            lang={UserUtils.getLanguage().toLowerCase()}
                            setContents={item.multilangText}
                            onBlur={(e) => onBlurEditor(e, item, idx)}
                            getSunEditorInstance={(sunEditor) =>
                              getSunEditorInstance(sunEditor, idx)
                            }
                            setDefaultStyle="font-family: 'Noto Sans KR', sans-serif;  height : 300px"
                            setOptions={{
                              font: [
                                "Noto Sans KR",
                                "Arial",
                                "Comic Sans MS",
                                "Courier New",
                                "Impact",
                                "Georgia",
                                "tahoma",
                                "Trebuchet MS",
                                "Verdana",
                              ],
                              fontSize: [
                                8, 9, 10, 11, 12, 13, 14, 16, 18, 20, 22, 24,
                                26, 28, 36, 48, 72,
                              ],
                              buttonList: [
                                ["font", "fontSize", "formatBlock"],
                                [
                                  "bold",
                                  "underline",
                                  "italic",
                                  "strike",
                                  "fontColor",
                                  "hiliteColor",
                                  "fullScreen",
                                ],
                              ],
                            }}
                          />
                        </Col>
                      </Row>
                    </>
                  );
              })
            : ""}
        </div>
        <div>
          <Row>
            <Col>
              <div className="content-title">2. Other Features</div>
            </Col>
            <Col>
              <Button
                className="add-btn"
                size="sm"
                variant="outline-primary"
                onClick={(e) => addSpecification(FUNC.OTHER)}
              >
                Add Specification
              </Button>
            </Col>
          </Row>
          {!ArrayUtils.isEmpty(fdContentList)
            ? fdContentList.map((item) => {
                var regex = /[^0-9]/g;
                const idx = parseInt(item.multilangCd.replace(regex, ""));

                if (item.multilangCd.includes("other"))
                  return (
                    <>
                      <Row>
                        <Col sm={4}>
                          <Form.Control
                            placeholder="Enter Title"
                            defaultValue={
                              fdTitleList.find(
                                (title) =>
                                  title.multilangCd === FUNC.OTHER_T + idx
                              ).multilangText
                            }
                            onChange={(e) =>
                              onChangeTitle(e, FUNC.OTHER_T + idx)
                            }
                          ></Form.Control>
                        </Col>
                        <Col>
                          {idx !== 1 ? (
                            <Button
                              className="del-btn"
                              size="sm"
                              variant="outline-danger"
                              onClick={(e) => {
                                deleteBtn(e, FUNC.OTHER, idx);
                              }}
                            >
                              <RiDeleteBinLine />
                            </Button>
                          ) : (
                            ""
                          )}
                        </Col>
                      </Row>
                      <Row style={{ marginBottom: "10px" }}>
                        <Col>
                          <SunEditor
                            id={item.multilangCd}
                            lang={UserUtils.getLanguage().toLowerCase()}
                            setContents={item.multilangText}
                            onBlur={(e) => onBlurEditor(e, item, idx)}
                            getSunEditorInstance={(sunEditor) =>
                              getSunEditorInstance(sunEditor, idx)
                            }
                            setDefaultStyle="font-family: 'Noto Sans KR', sans-serif;  height : 300px"
                            setOptions={{
                              font: [
                                "Noto Sans KR",
                                "Arial",
                                "Comic Sans MS",
                                "Courier New",
                                "Impact",
                                "Georgia",
                                "tahoma",
                                "Trebuchet MS",
                                "Verdana",
                              ],
                              fontSize: [
                                8, 9, 10, 11, 12, 13, 14, 16, 18, 20, 22, 24,
                                26, 28, 36, 48, 72,
                              ],
                              buttonList: [
                                ["font", "fontSize", "formatBlock"],
                                [
                                  "bold",
                                  "underline",
                                  "italic",
                                  "strike",
                                  "fontColor",
                                  "hiliteColor",
                                  "fullScreen",
                                ],
                              ],
                            }}
                          />
                        </Col>
                      </Row>
                    </>
                  );
              })
            : ""}
        </div>
        <div>
          <Row>
            <Col>
              <div className="content-title">3. Additional Features</div>
            </Col>
            <Col>
              <Button
                className="add-btn"
                size="sm"
                variant="outline-primary"
                onClick={(e) => addSpecification(FUNC.ADD)}
              >
                Add Specification
              </Button>
            </Col>
          </Row>
          {!ArrayUtils.isEmpty(fdContentList)
            ? fdContentList.map((item) => {
                var regex = /[^0-9]/g;
                const idx = parseInt(item.multilangCd.replace(regex, ""));

                if (item.multilangCd.includes("add"))
                  return (
                    <>
                      <Row>
                        <Col sm={4}>
                          <Form.Control
                            placeholder="Enter Title"
                            defaultValue={
                              fdTitleList.find(
                                (title) =>
                                  title.multilangCd === FUNC.ADD_T + idx
                              ).multilangText
                            }
                            onChange={(e) => onChangeTitle(e, FUNC.ADD_T + idx)}
                          ></Form.Control>
                        </Col>
                        <Col>
                          <Button
                            className="del-btn"
                            size="sm"
                            variant="outline-danger"
                            onClick={(e) => {
                              deleteBtn(e, FUNC.ADD, idx);
                            }}
                          >
                            <RiDeleteBinLine />
                          </Button>
                        </Col>
                      </Row>
                      <Row style={{ marginBottom: "10px" }}>
                        <Col>
                          <SunEditor
                            id={item.multilangCd}
                            lang={UserUtils.getLanguage().toLowerCase()}
                            setContents={item.multilangText}
                            onBlur={(e) => onBlurEditor(e, item, idx)}
                            getSunEditorInstance={(sunEditor) =>
                              getSunEditorInstance(sunEditor, idx)
                            }
                            setDefaultStyle="font-family: 'Noto Sans KR', sans-serif;  height : 300px"
                            setOptions={{
                              font: [
                                "Noto Sans KR",
                                "Arial",
                                "Comic Sans MS",
                                "Courier New",
                                "Impact",
                                "Georgia",
                                "tahoma",
                                "Trebuchet MS",
                                "Verdana",
                              ],
                              fontSize: [
                                8, 9, 10, 11, 12, 13, 14, 16, 18, 20, 22, 24,
                                26, 28, 36, 48, 72,
                              ],
                              buttonList: [
                                ["font", "fontSize", "formatBlock"],
                                [
                                  "bold",
                                  "underline",
                                  "italic",
                                  "strike",
                                  "fontColor",
                                  "hiliteColor",
                                  "fullScreen",
                                ],
                              ],
                            }}
                          />
                        </Col>
                      </Row>
                    </>
                  );
              })
            : ""}
        </div>
      </ScrollDiv>
    </React.Fragment>
  );
};

export default FunctionalSpecification;
