import Blockly from "blockly";
import JsonUtils from "components/common/utils/JsonUtils";
import Api from "components/common/Api";
import StringUtils from "components/common/utils/StringUtils";
import * as Enums from "components/builder/BuilderEnum";
import ArrayUtils from "components/common/utils/ArrayUtils";

/**
 * Blockly Extension link: https://developers.google.com/blockly/guides/create-custom-blocks/extensions#extensions
 */

export const comboType = [
  "gridIdCombo",
  "gridColumnCombo",
  "formIdCombo",
  "comboIdCombo",
  "dataEntityCombo",
  "btnCombo",
  "componentCombo",
  "gridBtnCombo",
  "setProgramId",
];

export const parentComboType = ["gridIdCombo", "formIdCombo"];

export const childComboType = [
  "gridColumnCombo",
  "comboIdCombo",
  "dataEntityCombo",
  "btnCombo",
  "componentCombo",
  "gridBtnCombo",
  "setProgramId",
];

/**
 * 해당 field의 부모 input을 찾는다.
 * @param {*} block
 * @param {*} fieldName
 * @returns
 */
export const findParentInput = (block, fieldName) => {
  const inputList = block.inputList;

  for (let i = 0; i < inputList.length; i++) {
    const fieldRow = inputList[i].fieldRow;

    for (let j = 0; j < fieldRow.length; j++) {
      const row = fieldRow[j];
      if (row.name === fieldName) {
        return [inputList[i], i, j];
      }
    }
  }
  return null;
};

/**
 * field를 제거하고 새로운 field를 추가한다.
 * @param {*} block
 * @param {*} parentInput
 * @param {*} fieldName
 * @param {*} options
 */
export const updateFieldRow = (block, parentInput, fieldName, options) => {
  const oldFieldRow = [...parentInput[0].fieldRow];
  const inputIndex = parentInput[1];
  const oldFieldIndex = parentInput[2];

  block.getInput(parentInput[0].name).removeField(fieldName);
  const newInput = block
    .getInput(parentInput[0].name)
    .appendField(new Blockly.FieldDropdown(options), fieldName);
  const newFieldRow = newInput.fieldRow;
  reSortFieldRow(block, oldFieldRow, newFieldRow, inputIndex, oldFieldIndex);
};

/**
 * 새로운 field를 추가한 후 자리를 재배치 한다.
 * @param {*} block
 * @param {*} oldFieldRow
 * @param {*} newFieldRow
 * @param {*} inputIndex
 * @param {*} oldFieldIndex
 */
const reSortFieldRow = (
  block,
  oldFieldRow,
  newFieldRow,
  inputIndex,
  oldFieldIndex
) => {
  oldFieldRow[oldFieldIndex] = newFieldRow[newFieldRow.length - 1];
  oldFieldRow.splice(oldFieldIndex, 1, newFieldRow[newFieldRow.length - 1]);
  block.inputList[inputIndex].fieldRow = oldFieldRow;
};

/**
 * Block Extension 등록
 */

parentComboType.map((combo) => {
  if (Blockly.Extensions.isRegistered(combo)) {
    Blockly.Extensions.unregister(combo);
  }
});

childComboType.map((combo) => {
  if (Blockly.Extensions.isRegistered(combo)) {
    Blockly.Extensions.unregister(combo);
  }
});

/**
 * gridId Combo box 설정
 */
Blockly.Extensions.register(
  "gridIdCombo",
  function (output, blockInfo, additionalInfo, execute) {
    if (!execute) {
      return;
    }

    if (output && output.page) {
      let options = [];
      let fieldName = "gridIdCombo";

      // ui builder에서 grid component만 가져오기
      let node = JsonUtils.findNodeByType(output.page, "grid");

      if (node.length > 0) {
        let nodeInfo = {};

        node.map((n, index) => {
          const gridOptions = n.propertyValue.gridOptions;
          const value = `${gridOptions.gridId}`;
          options.push([value, value]);
          // grid component 정보 저장
          nodeInfo[value] = n;
        });

        // gridIdCombo field가 있는 input을 찾은 후 field를 업데이트
        let parentInput = findParentInput(this, fieldName);
        if (parentInput) {
          updateFieldRow(this, parentInput, fieldName, options);
          // grid component 정보를 gridIdCombo field에 저장
          this.getField(fieldName).nodeInfo = nodeInfo;
        }

        // newValue가 gridIdCombo field의 option에 있는지 확인 후 리턴
        this.getField("gridIdCombo").doClassValidation_ = function (newValue) {
          const options = this.getOptions(true);
          const isValueValid = options.some((option) => option[1] === newValue);

          if (!isValueValid) {
            return null;
          }
          return newValue;
        };
      }
    }
  }
);

/**
 * gridColumn Combo box 설정
 */
Blockly.Extensions.register(
  "gridColumnCombo",
  function (output, blockInfo, additionalInfo, execute) {
    if (!execute) {
      return;
    }
    if (output && output.page) {
      // gridIdCombo field의 값 가져오기
      let gridId = this.getField("gridIdCombo").getValue();

      const parentField = this.getField("gridIdCombo");
      const fieldName = "gridColumnCombo";

      if (parentField && parentField.nodeInfo) {
        let options = [["Not Selected", "NONE"]];

        if (!parentField.nodeInfo[gridId]) {
          gridId = parentField.selectedOption[1];
        }

        // grid의 column 정보 가져와 options에 저장
        let columns =
          parentField.nodeInfo[gridId].propertyValue.gridOptions.columns;
        columns.map((n, index) => {
          const value = `"${n.name}"`;
          options.push([value, value]);
        });

        // 직접 추가한 dropdown item
        if (
          blockInfo &&
          blockInfo[fieldName] &&
          blockInfo[fieldName].additionalOption
        ) {
          options = [...options, ...blockInfo[fieldName].additionalOption];
        }

        // gridColumnCombo field가 있는 input을 찾은 후 field를 업데이트
        const parentInput = findParentInput(this, fieldName);
        if (parentInput) {
          updateFieldRow(this, parentInput, fieldName, options);
        }

        // newValue가 gridColumnCombo의 option에 있는지 확인 후 리턴
        this.getField("gridColumnCombo").doClassValidation_ = function (
          newValue
        ) {
          const options = this.getOptions(true);
          const isValueValid = options.some((option) => option[1] === newValue);

          if (!isValueValid) {
            return null;
          }
          return newValue;
        };
      }
    }
  }
);

/**
 * formId Combo box 설정
 */
Blockly.Extensions.register(
  "formIdCombo",
  function (output, blockInfo, additionalInfo, execute) {
    if (!execute) {
      return;
    }
    if (output && output.page) {
      let options = [];
      let fieldName = "formIdCombo";

      // ui builder에서 form component만 가져오기
      let node = JsonUtils.findNodeByType(output.page, "form");

      if (node.length > 0) {
        let nodeInfo = {};

        node.map((n, index) => {
          const value = n.propertyValue.id;
          options.push([value, value]);
          // form component 정보 저장
          nodeInfo[value] = n;
        });

        // comboIdCombo field가 해당 block에 있으면 "page"를 options에 추가
        if (this.getField("comboIdCombo")) {
          const value = "page";
          options.push([value, value]);
          nodeInfo[value] = output.page;
        }

        // formIdCombo field가 있는 input을 찾은 후 field를 업데이트
        let parentInput = findParentInput(this, fieldName);
        if (parentInput) {
          updateFieldRow(this, parentInput, fieldName, options);
          this.getField(fieldName).nodeInfo = nodeInfo;
          // 이벤트의 target이 form이면 해당 form으로 값 set
          if (
            additionalInfo.eventTarget &&
            additionalInfo.eventTarget.targetType === "form" &&
            additionalInfo.eventTarget.target
          ) {
            this.getField(fieldName).setValue(
              additionalInfo.eventTarget.target
            );
          }
        }

        // newValue가 formIdCombo의 option에 있는지 확인 후 리턴
        this.getField("formIdCombo").doClassValidation_ = function (newValue) {
          const options = this.getOptions(true);
          const isValueValid = options.some((option) => option[1] === newValue);

          if (!isValueValid) {
            return null;
          }
          return newValue;
        };
      }
    }
  }
);

/**
 * comboId Combo box 설정
 */
Blockly.Extensions.register(
  "comboIdCombo",
  function (output, blockInfo, additionalInfo, execute) {
    if (!execute) {
      return;
    }
    if (output && output.page) {
      let options = [];
      let fieldName = "comboIdCombo";

      // formIdCombo field의 값 가져오기
      let formId = this.getField("formIdCombo").getValue();

      const parentField = this.getField("formIdCombo");

      let node;

      if (parentField.nodeInfo) {
        if (!parentField.nodeInfo[formId]) {
          formId = parentField.selectedOption[1];
        }

        // select component 가져오기
        if (formId === "page") {
          node = JsonUtils.findComponentNodeByObject(output.page, "select");
        } else {
          node = JsonUtils.findComponentNodeByObject(
            parentField.nodeInfo[formId],
            "select"
          );
        }

        if (node.length > 0) {
          let nodeInfo = {};

          node.map((n, index) => {
            let value = n.propertyValue.id || n.compId;
            value = `"${value}"`;
            options.push([value, value]);
            nodeInfo[value] = n;
          });

          if (
            blockInfo &&
            blockInfo[fieldName] &&
            blockInfo[fieldName].additionalOption
          ) {
            options = [...options, ...blockInfo[fieldName].additionalOption];
          }

          // comboIdCombo field가 있는 input을 찾은 후 field를 업데이트
          let parentInput = findParentInput(this, fieldName);
          if (parentInput) {
            updateFieldRow(this, parentInput, fieldName, options);
            this.getField(fieldName).nodeInfo = nodeInfo;
          }

          // newValue가 comboIdCombo의 option에 있는지 확인 후 리턴
          this.getField("comboIdCombo").doClassValidation_ = function (
            newValue
          ) {
            const options = this.getOptions(true);
            const isValueValid = options.some(
              (option) => option[1] === newValue
            );

            if (!isValueValid) {
              return null;
            }
            return newValue;
          };
        }
      }
    }
  }
);

/**
 * dataEntity Combo box 설정
 */
Blockly.Extensions.register(
  "dataEntityCombo",
  function (output, blockInfo, additionalInfo, execute) {
    if (!execute) {
      return;
    }
    if (!ArrayUtils.isEmpty(additionalInfo.dataEntityOption)) {
      let fieldName = "dataEntityCombo";

      // dataEntityCombo field가 있는 input을 찾은 후 field를 업데이트
      let parentInput = findParentInput(this, fieldName);
      if (parentInput) {
        updateFieldRow(
          this,
          parentInput,
          fieldName,
          additionalInfo.dataEntityOption
        );
        this.dataEntity = additionalInfo.dataEntityOption;
      }

      // newValue가 dataEntityCombo의 option에 있는지 확인 후 리턴
      this.getField("dataEntityCombo").doClassValidation_ = function (
        newValue
      ) {
        const options = this.getOptions(true);
        const isValueValid = options.some((option) => option[1] === newValue);

        if (!isValueValid) {
          return null;
        }
        return newValue;
      };
    }
  }
);

/**
 * button Combo box 설정
 */
Blockly.Extensions.register(
  "btnCombo",
  function (output, blockInfo, additionalInfo, execute) {
    if (!execute) {
      return;
    }
    if (output && output.page) {
      let options = [];
      let fieldName = "btnCombo";

      let formId;
      let node;

      if (this.getField("formIdCombo")) {
        const parentField = this.getField("formIdCombo");

        formId = this.getField("formIdCombo").getValue();

        if (parentField.nodeInfo) {
          if (!parentField.nodeInfo[formId]) {
            formId = parentField.selectedOption[1];
          }
          // button component 가져오기
          node = JsonUtils.findComponentNodeByObject(
            parentField.nodeInfo[formId],
            "button"
          );
        }
      } else {
        formId = "page";
        // button component 가져오기

        node = JsonUtils.findComponentNodeByObject(output.page, "button");
      }

      if (node.length > 0) {
        let nodeInfo = {};

        node.map((n, index) => {
          const value = n.propertyValue.id || n.compId;
          options.push([value, value]);
          nodeInfo[value] = n;
        });

        // 직접 추가한 dropdown item
        if (
          blockInfo &&
          blockInfo[fieldName] &&
          blockInfo[fieldName].additionalOption
        ) {
          options = [...options, ...blockInfo[fieldName].additionalOption];
        }

        // btnCombo field가 있는 input을 찾은 후 field를 업데이트
        let parentInput = findParentInput(this, fieldName);
        if (parentInput) {
          updateFieldRow(this, parentInput, fieldName, options);
          this.getField(fieldName).nodeInfo = nodeInfo;
        }

        // newValue가 btnCombo의 option에 있는지 확인 후 리턴
        this.getField("btnCombo").doClassValidation_ = function (newValue) {
          const options = this.getOptions(true);
          const isValueValid = options.some((option) => option[1] === newValue);

          if (!isValueValid) {
            return null;
          }
          return newValue;
        };
      }
    }
  }
);

/**
 * component Combo box 설정
 */
Blockly.Extensions.register(
  "componentCombo",
  function (output, blockInfo, additionalInfo, execute) {
    if (!execute) {
      return;
    }
    if (output && output.page) {
      let options = [];
      let fieldName = "componentCombo";

      let formId = this.getField("formIdCombo").getValue();

      const parentField = this.getField("formIdCombo");

      let node;

      if (parentField.nodeInfo) {
        if (!parentField.nodeInfo[formId]) {
          formId = parentField.selectedOption[1];
        }

        // component 가져오기
        if (formId === "page") {
          node = JsonUtils.findComponentNode(output.page);
        } else {
          node = JsonUtils.findComponentNode(parentField.nodeInfo[formId]);
          if (parentField.nodeInfo[formId].propertyValue.hiddens) {
            node = [
              ...node,
              ...parentField.nodeInfo[formId].propertyValue.hiddens,
            ];
          }
        }

        if (node.length > 0) {
          let nodeInfo = {};

          node.map((n, index) => {
            let value = n.id || n.propertyValue.id || n.compId;
            value = `"${value}"`;
            options.push([value, value]);
            nodeInfo[value] = n;
            // Date Picker component 일 때
            if (n.propertyValue) {
              let value;
              if (n.propertyValue.startDateId) {
                value = n.propertyValue.startDateId;
                value = `"${value}"`;
                options.push([value, value]);
              }
              if (n.propertyValue.endDateId) {
                value = n.propertyValue.endDateId;
                value = `"${value}"`;
                options.push([value, value]);
              }
            }
          });

          // 직접 추가한 dropdown item
          if (
            blockInfo &&
            blockInfo[fieldName] &&
            blockInfo[fieldName].additionalOption
          ) {
            options = [...options, ...blockInfo[fieldName].additionalOption];
          }

          // componentCombo field가 있는 input을 찾은 후 field를 업데이트
          let parentInput = findParentInput(this, fieldName);
          if (parentInput) {
            updateFieldRow(this, parentInput, fieldName, options);
            this.getField(fieldName).nodeInfo = nodeInfo;
          }

          // newValue가 componentCombo의 option에 있는지 확인 후 리턴
          this.getField("componentCombo").doClassValidation_ = function (
            newValue
          ) {
            const options = this.getOptions(true);
            const isValueValid = options.some(
              (option) => option[1] === newValue
            );

            if (!isValueValid) {
              return null;
            }
            return newValue;
          };
        }
      }
    }
  }
);

/**
 * grid Button Combo box 설정
 */
Blockly.Extensions.register(
  "gridBtnCombo",
  function (output, blockInfo, additionalInfo, execute) {
    if (!execute) {
      return;
    }
    if (output && output.page) {
      // gridIdCombo field의 값 가져오기
      let gridId = this.getField("gridIdCombo").getValue();

      const parentField = this.getField("gridIdCombo");
      const fieldName = "gridBtnCombo";

      if (parentField && parentField.nodeInfo) {
        let options = [];

        if (!parentField.nodeInfo[gridId]) {
          gridId = parentField.selectedOption[1];
        }

        // grid의 button 정보 가져와 options에 저장
        const buttons =
          parentField.nodeInfo[gridId].propertyValue.gridOptions
            .additionalOptions.toolbarOptions.buttons;

        for (const key in buttons) {
          const value = buttons[key].id
            ? `"${buttons[key].id}"`
            : `"${buttons[key]}"`;
          options.push([value, value]);
        }

        const customButtons =
          parentField.nodeInfo[gridId].propertyValue.gridOptions
            .additionalOptions.toolbarOptions.customButton;

        for (const key in customButtons) {
          const value = `"${customButtons[key].id}"`;
          options.push([value, value]);
        }

        // 직접 추가한 dropdown item
        if (
          blockInfo &&
          blockInfo[fieldName] &&
          blockInfo[fieldName].additionalOption
        ) {
          options = [...options, ...blockInfo[fieldName].additionalOption];
        }

        // gridBtnCombo field가 있는 input을 찾은 후 field를 업데이트
        const parentInput = findParentInput(this, fieldName);
        if (parentInput) {
          if (options.length === 0) {
            options.push(["There are no buttons in the grid.", "null"]);
          }
          updateFieldRow(this, parentInput, fieldName, options);
        }

        // newValue가 gridBtnCombo의 option에 있는지 확인 후 리턴
        this.getField("gridBtnCombo").doClassValidation_ = function (newValue) {
          const options = this.getOptions(true);
          const isValueValid = options.some((option) => option[1] === newValue);

          if (!isValueValid) {
            return null;
          }
          return newValue;
        };
      }
    }
  }
);

Blockly.Extensions.register(
  "setProgramId",
  function (output, blockInfo, additionalInfo, execute) {
    if (!execute) {
      return;
    }
    if (output && output.page) {
      if (this.getField("programId")) {
        this.getField("programId").setValue(
          `"${output.page.propertyValue.programId}"`
        );
      }
    }
  }
);
