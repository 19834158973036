import { Enums } from "components/builder/BuilderEnum";
import {
  activateComponent,
  updateDataModel,
} from "components/builder/entity/reducers/EntityBuilderAction";
import UIReduxHelper from "components/builder/ui/editor/helper/UIReduxHelper";
import {
  setSearchConsoleCollapse,
  setSearchConsoleHidden,
} from "components/builder/ui/reducers/SearchConsoleAction";
import { setMemo } from "components/builder/ui/reducers/UIBuilderAction";
import { AppContext } from "components/common/AppContextProvider";
import Message from "components/common/Message";
import WijmoGrid from "components/common/element/WijmoGrid";
import CommonUtils from "components/common/utils/CommonUtils";
import StringUtils from "components/common/utils/StringUtils";
import { setDataModelEditable } from "page/dataModel/DataModelList";
import { ConsoleTabKey } from "page/popup/TotalSearchPopup";
import { useContext, useEffect, useRef, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ComponentService from "services/datamodel/DataModelService";
import ProgramService from "services/ui/ProgramService";

const columns = {
  [ConsoleTabKey.PROGRAM]: [
    {
      field: "programNm",
      headerName: "Program Name",
      width: 200,
      headerAlign: "center",
    },
    {
      field: "description",
      headerName: "Program Description",
      width: "*",
      headerAlign: "center",
    },
    {
      field: "programId",
      headerName: "Program ID",
      width: 200,
      headerAlign: "center",
    },
    {
      field: "dataModelNm",
      headerName: "DataModel Name",
      width: 200,
      headerAlign: "center",
    },
    {
      field: "logEntityNm",
      headerName: "Entity Logical Name",
      width: 200,
      headerAlign: "center",
      renderCell: (param) => {
        if (param.logEntityNm) {
          return param.logEntityNm;
        } else {
          return (
            <span style={{ color: "tomato" }}>
              There are no matching items.
            </span>
          );
        }
      },
    },
    {
      field: "physEntityNm",
      headerName: "Entity Physical Name",
      width: 200,
      headerAlign: "center",
      renderCell: (param) => {
        if (param.physEntityNm) {
          return param.physEntityNm;
        } else {
          return (
            <span style={{ color: "tomato" }}>
              There are no matching items.
            </span>
          );
        }
      },
    },
    {
      field: "updtUserId",
      headerName: "Update User",
      width: 150,
      headerAlign: "center",
      align: "left",
    },
    {
      field: "updtDt",
      headerName: "Update Date",
      width: 150,
      headerAlign: "center",
      renderCell: (param) => CommonUtils.getDate(param.updtDt, "datetime"),
      // valueGetter: (param) => CommonUtils.getDate(param.row.updtDt, "datetime"),
    },
  ],
  [ConsoleTabKey.PROGRAM_HISTORY]: [
    {
      field: "programNm",
      headerName: "Program Name",
      width: "*",
      headerAlign: "center",
    },
    {
      field: "description",
      headerName: "Program Description",
      width: "*",
      headerAlign: "center",
    },
    {
      field: "programId",
      headerName: "Program ID",
      width: "*",
      headerAlign: "center",
    },
    {
      field: "commitComment",
      headerName: "Comment",
      width: "*",
      headerAlign: "center",
    },
    {
      field: "updtUserId",
      headerName: "Update User",
      width: 200,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "updtDt",
      headerName: "Update Date",
      width: 200,
      headerAlign: "center",
      renderCell: (param) => CommonUtils.getDate(param.updtDt, "datetime"),
      // valueGetter: (param) => CommonUtils.getDate(param.row.updtDt, "datetime"),
    },
  ],
  [ConsoleTabKey.DATA_MODEL]: [
    {
      field: "dataModelNm",
      headerName: "DataModel Name",
      width: "*",
      headerAlign: "center",
    },
    {
      field: "description",
      headerName: "DataModel Description",
      width: "*",
      headerAlign: "center",
    },
    {
      field: "transactionNm",
      headerName: "Transaction Name",
      width: "*",
      headerAlign: "center",
      renderCell: (param) => {
        if (param.transactionNm) {
          return param.transactionNm;
        } else {
          return (
            <span style={{ color: "tomato" }}>
              There are no matching items.
            </span>
          );
        }
      },
    },
    {
      field: "procedures",
      headerName: "Procedure Name",
      width: "*",
      headerAlign: "center",
      renderCell: (param) => {
        if (param.procedures && param.procedures.length > 0) {
          const procedureNames = param.procedures.map((proc) => {
            return JSON.parse(proc).procedureName;
          });
          return procedureNames.join(" , ");
        } else {
          return (
            <span style={{ color: "tomato" }}>
              There are no matching items.
            </span>
          );
        }
      },
    },
    {
      field: "usrApiUrl",
      headerName: "API Url",
      width: "*",
      headerAlign: "center",
      renderCell: (param) => {
        if (param.usrApiUrl) {
          return param.usrApiUrl;
        } else {
          return (
            <span style={{ color: "tomato" }}>
              There are no matching items.
            </span>
          );
        }
      },
    },
    {
      field: "updtUserId",
      headerName: "Update User",
      width: 200,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "updtDt",
      headerName: "Update Date",
      width: 200,
      headerAlign: "center",
      renderCell: (param) => CommonUtils.getDate(param.updtDt, "datetime"),
      // valueGetter: (param) => CommonUtils.getDate(param.row.updtDt, "datetime"),
    },
  ],
  [ConsoleTabKey.ENTITY]: [
    {
      field: "dataModelNm",
      headerName: "DataModel Name",
      width: 200,
      headerAlign: "center",
    },
    {
      field: "logEntityNm",
      headerName: "Entity Logical Name",
      width: "*",
      headerAlign: "center",
      renderCell: (param) => {
        if (param.logEntityNm) {
          return param.logEntityNm;
        } else {
          return (
            <span style={{ color: "tomato" }}>
              There are no matching items.
            </span>
          );
        }
      },
    },
    {
      field: "physEntityNm",
      headerName: "Entity Physical Name",
      width: "*",
      headerAlign: "center",
      renderCell: (param) => {
        if (param.physEntityNm) {
          return param.physEntityNm;
        } else {
          return (
            <span style={{ color: "tomato" }}>
              There are no matching items.
            </span>
          );
        }
      },
    },
    {
      field: "tableNm",
      headerName: "Table Name",
      width: "*",
      headerAlign: "center",
      renderCell: (param) => {
        if (param.tableNm) {
          return param.tableNm;
        } else {
          return (
            <span style={{ color: "tomato" }}>
              There are no matching items.
            </span>
          );
        }
      },
    },
    {
      field: "updtUserId",
      headerName: "Update User",
      width: 200,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "updtDt",
      headerName: "Update Date",
      width: 200,
      headerAlign: "center",
      renderCell: (param) => CommonUtils.getDate(param.updtDt, "datetime"),
      // valueGetter: (param) => CommonUtils.getDate(param.row.updtDt, "datetime"),
    },
  ],
};

function SearchResultComponent() {
  const {
    isSearchConsoleHidden,
    searchType,
    searchList = [],
    searchTerm = "",
  } = useSelector((state) => state.searchConsole);

  const dispatch = useDispatch();
  const {
    component,
    dataModel: { setSearchTerm },
  } = useContext(AppContext);
  const navigate = useNavigate();
  const [componentHeight, setComponentHeight] = useState(400);
  const searchWrapperRef = useRef();
  const pageYRef = useRef();
  const titleHeight = useRef(25);
  const gridWrapperHeight = useRef();

  const onCloseConsole = (e) => {
    if (searchWrapperRef.current.clientHeight > titleHeight.current) {
      searchWrapperRef.current.style = `height:${titleHeight.current}px`;
      setTimeout(() => dispatch(setSearchConsoleHidden(true)), 500);
    } else {
      dispatch(setSearchConsoleHidden(true));
    }
  };

  useEffect(() => {
    const keyDownEvent = (e) => {
      if (e.keyCode === 27) {
        searchWrapperRef.current.style = `height:${titleHeight.current}px`;
      }
    };
    if (!isSearchConsoleHidden) {
      window.addEventListener("keydown", keyDownEvent);
    } else {
      window.removeEventListener("keydown", keyDownEvent);
    }
    return () => {
      window.removeEventListener("keydown", keyDownEvent);
    };
  }, [isSearchConsoleHidden]);

  const onRowDoubleClick = (data, e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    if (searchType === ConsoleTabKey.PROGRAM) {
      try {
        ProgramService.getProgram(
          data,
          (res) => {
            const { programContent, dataModelList, ...information } = res.data;
            const componentInfo = component.getPageComponent(); //Page Component
            const { memo, ...output } = JSON.parse(programContent);
            UIReduxHelper.loadTemplate(
              dispatch,
              componentInfo,
              JSON.stringify(output)
            );
            if (memo) dispatch(setMemo(memo));
            else dispatch(setMemo([]));
            information.programContent = JSON.parse(programContent);
            UIReduxHelper.updateInformation(dispatch, information);

            Message.alert(
              `'${information.programNm}' program has been loaded..`,
              Enums.MessageType.SUCCESS
            );
            dispatch(setSearchConsoleCollapse(true));
            navigate(
              `${Enums.BuilderPath.UI.MAIN + "/" + Enums.BuilderPath.UI.EDITOR}`
            );
          },
          () => {
            throw new Error("Error occured while loading program.");
          }
        );
      } catch (e) {
        Message.alert(e, Enums.MessageType.ERROR);
      }
    } else if (searchType === ConsoleTabKey.PROGRAM_HISTORY) {
      navigate(
        `${Enums.BuilderPath.HISTORY}/${data.programId}/${data.historyMstId}`
      );
      dispatch(setSearchConsoleCollapse(true));
    } else if (searchType === ConsoleTabKey.DATA_MODEL) {
      navigate(
        `${Enums.BuilderPath.ENTITY.MAIN + "/" + Enums.BuilderPath.ENTITY.LIST}`
      );

      dispatch(setSearchConsoleCollapse(true));

      setSearchTerm({
        dataModelNm: data.dataModelNm,
        description: data.description,
        dataModelType: data.dataModelType,
      });
    } else if (searchType === ConsoleTabKey.ENTITY) {
      dispatch(setSearchConsoleCollapse(true));
      ComponentService.getDataModel(
        {
          dataModelId: data.dataModelId,
        },
        (res) => {
          const dataModel = setDataModelEditable(res.data);
          dispatch(updateDataModel(dataModel));
          dispatch(activateComponent(dataModel));
          navigate(
            `${
              Enums.BuilderPath.ENTITY.MAIN +
              "/" +
              Enums.BuilderPath.ENTITY.CREATE
            }`
          );
        }
      );
    }
  };

  /**
   * 드래그 할때 그림자
   * @param {*} e
   */
  const onDragStart = (e) => {
    e.dataTransfer.setDragImage(e.target, e.pageX, 0);
  };

  /**
   * 드래그 이벤트
   * @param {*} e
   * @returns
   */
  const onDragComponent = (e) => {
    e.stopPropagation();
    const windowHeight = window.innerHeight;
    if (pageYRef.current === e.pageY) return false;
    else if (e.pageY) {
      pageYRef.current = e.pageY;
      let componentHeight = windowHeight - e.pageY;
      if (windowHeight - e.pageY < titleHeight.current) {
        componentHeight = titleHeight.current;
      } else if (e.pageY <= 50) {
        componentHeight = windowHeight - 50; //헤더 높이 까지만 설정
      }
      searchWrapperRef.current.style = `height:${componentHeight}px`;
      //그리드 래퍼 높이 재조정
      gridWrapperHeight.current.style = `height:${componentHeight - 30}px`;
    }
  };

  if (isSearchConsoleHidden) {
    return null;
  } else {
    return (
      <div
        className={`total-search-wrapper`}
        ref={searchWrapperRef}
        style={{
          width: `100%`,
          height: `${componentHeight}px`,
        }}
      >
        <div
          className="search-title"
          onDragStart={onDragStart}
          onDrag={onDragComponent}
          style={{ height: `${titleHeight.current}px` }}
          draggable
        >
          <div className="title">
            {` ' ${searchTerm} ' - `}
            {StringUtils.equalsIgnoreCase(searchType, ConsoleTabKey.PROGRAM)
              ? " Program Name "
              : StringUtils.equalsIgnoreCase(
                  searchType,
                  ConsoleTabKey.PROGRAM_HISTORY
                )
              ? " Program History "
              : StringUtils.equalsIgnoreCase(
                  searchType,
                  ConsoleTabKey.DATA_MODEL
                )
              ? " Data Model "
              : StringUtils.equalsIgnoreCase(searchType, ConsoleTabKey.ENTITY)
              ? " Entity "
              : ""}
            Result : {searchList?.length}
          </div>

          <div
            className="control-button"
            onClick={onCloseConsole}
            style={{ cursor: "pointer" }}
          >
            <AiOutlineClose size={15} />
          </div>
        </div>
        <div className="search-list-wrapper" ref={gridWrapperHeight}>
          {!StringUtils.isEmpty(searchType) && (
            <WijmoGrid
              columns={columns[searchType]}
              rows={searchList}
              onRowDoubleClick={onRowDoubleClick}
              rowHeight={25}
              style={{ height: "50vh" }}
            />
          )}
        </div>
      </div>
    );
  }
}

export default SearchResultComponent;
