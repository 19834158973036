import { Enums } from "components/builder/BuilderEnum";
import StringUtils from "components/common/utils/StringUtils";
import User from "components/common/utils/UserUtils";

export const TopNavShortKeyName = {
  [Enums.ShortKeyDefine.NEW_TEMP]: "New Screen",
  [Enums.ShortKeyDefine.STAND_TEMP]: "Standard Screen",
  [Enums.ShortKeyDefine.SHARE_TEMP]: "Share Screen",
  [Enums.ShortKeyDefine.LOAD]: "Load",
  [Enums.ShortKeyDefine.PREVIEW]: "Preview",
  [Enums.ShortKeyDefine.DOWNLOAD]: "Download",
  [Enums.ShortKeyDefine.NEW_CON]: "New Connection",
  [Enums.ShortKeyDefine.SAVE]: "Save",
  [Enums.ShortKeyDefine.SAVEAS]: "Save As",
  [Enums.ShortKeyDefine.DELETE]: "Delete",
  [Enums.ShortKeyDefine.DEPLOY]: "Deploy Program",
  [Enums.ShortKeyDefine.DOCUMENT]: "User Guide",
  [Enums.ShortKeyDefine.LOGOUT]: "Logout",
  [Enums.ShortKeyDefine.MY_INFO]: "My Info",
  [Enums.ShortKeyDefine.SETTING]: "Settings",
  [Enums.ShortKeyDefine.SEARCH]: "Unified Search",
};
export const EditorShortKeyName = {
  [Enums.ShortKeyDefine.UNDO]: "Undo",
  [Enums.ShortKeyDefine.REDO]: "Redo",
  [Enums.ShortKeyDefine.CUT]: "Cut",
  [Enums.ShortKeyDefine.COPY]: "Copy",
  [Enums.ShortKeyDefine.PASTE]: "Paste",
};

export const defaultShortKeySet = {
  [Enums.ShortKeyDefine.NEW_TEMP]: [18, 78],
  [Enums.ShortKeyDefine.STAND_TEMP]: [],
  [Enums.ShortKeyDefine.SHARE_TEMP]: [],
  [Enums.ShortKeyDefine.LOAD]: [],
  [Enums.ShortKeyDefine.PREVIEW]: [],
  [Enums.ShortKeyDefine.DOWNLOAD]: [],
  [Enums.ShortKeyDefine.NEW_CON]: [],
  [Enums.ShortKeyDefine.SAVE]: [18, 83],
  [Enums.ShortKeyDefine.SAVEAS]: [],
  [Enums.ShortKeyDefine.DEPLOY]: [18, 82],
  [Enums.ShortKeyDefine.DELETE]: [],
  [Enums.ShortKeyDefine.DOCUMENT]: [],
  [Enums.ShortKeyDefine.LOGOUT]: [],
  [Enums.ShortKeyDefine.MY_INFO]: [],
  [Enums.ShortKeyDefine.SETTING]: [],
  [Enums.ShortKeyDefine.UNDO]: [18, 90],
  [Enums.ShortKeyDefine.REDO]: [18, 89],
  [Enums.ShortKeyDefine.CUT]: [18, 88],
  [Enums.ShortKeyDefine.COPY]: [18, 67],
  [Enums.ShortKeyDefine.PASTE]: [18, 86],
  [Enums.ShortKeyDefine.SEARCH]: [18, 72],
};

export const KeyCodeSet = {
  16: "Shift",
  18: "Alt",
  32: "SpaceBar",
  65: "A",
  66: "B",
  67: "C",
  68: "D",
  69: "E",
  70: "F",
  71: "G",
  72: "H",
  73: "I",
  74: "J",
  75: "K",
  76: "L",
  77: "M",
  78: "N",
  79: "O",
  80: "P",
  81: "Q",
  82: "R",
  83: "S",
  84: "T",
  85: "U",
  86: "V",
  87: "W",
  88: "X",
  89: "Y",
  90: "Z",
  192: "`",
  48: "0",
  49: "1",
  50: "2",
  51: "3",
  52: "4",
  53: "5",
  54: "6",
  55: "7",
  56: "8",
  57: "9",
  189: "-",
  187: "=",
  8: "BackSpace",
  112: "F1",
  113: "F2",
  114: "F3",
  115: "F4",
  116: "F5",
  117: "F6",
  118: "F7",
  119: "F8",
  120: "F9",
  121: "F10",
  122: "F11",
  123: "F12",
  45: "Insert",
  46: "Delete",
  36: "Home",
  35: "End",
  33: "Page up",
  34: "Page Down",
  186: ";",
  222: "'",
  188: ",",
  190: ".",
  191: "/",
  220: "\\",
  97: "NUM 1",
  98: "NUM 2",
  99: "NUM 3",
  100: "NUM 4",
  101: "NUM 5",
  102: "NUM 6",
  103: "NUM 7",
  104: "NUM 8",
  105: "NUM 9",
};
/**
 * 단축키와 method를 매핑
 * @param {*} e 키 이벤트
 * @param {*} keyDefine 키 정의
 * @param {*} method 단축키 함수
 */
export const shortKeyBind = (e, keyDefine, method) => {
  const UserConfig = User.getConfiguration(
    Enums.ConfigurationDefine.SHORT_KEY_SET
  );
  if (UserConfig) {
    const keySet = UserConfig[keyDefine];
    const flag = testKeyEvent(e, keySet);

    if (flag) {
      e.preventDefault();
      method(e);
    }
  }
};

/**
 * 키배열과 인풋이 동일한지 확인
 * @param {*} e
 * @param {*} keySet
 * @returns
 */
const testKeyEvent = (e, keySet) => {
  if (!keySet || keySet.length === 0) {
    return false;
  } else {
    // 1. 특수키 다 뽑기
    const spKey = [
      keySet.includes(16),
      keySet.includes(17),
      keySet.includes(18),
    ];
    const spInput = [e.shiftKey, e.ctrlKey, e.altKey];
    if (
      StringUtils.equalsIgnoreType(
        JSON.stringify(spKey),
        JSON.stringify(spInput)
      ) &&
      keySet.at(-1) === e.keyCode
    ) {
      return true;
    } else {
      return false;
    }
  }
};

/**
 * 키 정의를 string으로
 * @param {*} keyDefine
 * @returns
 */
export const UserShortKey = (keyDefine) => {
  const userConfig = User.getConfiguration(
    Enums.ConfigurationDefine.SHORT_KEY_SET
  );
  if (userConfig && userConfig[keyDefine]) {
    return keyArrayToString(userConfig[keyDefine]);
  } else {
    return keyArrayToString(defaultShortKeySet[keyDefine]);
  }
};

/**
 * 키코드 배열을 string으로
 * @param {*} keyList
 * @returns
 */
export const keyArrayToString = (keyList) => {
  if (keyList && keyList.length > 0) {
    const value = keyList.map((key) => KeyCodeSet[key]);
    return value.join(" + ");
  } else {
    return "";
  }
};
