import { Autocomplete, Grid, TextField, Tooltip } from "@mui/material";
import { Enums } from "components/builder/BuilderEnum";
import Message from "components/common/Message";
import Popup from "components/common/Popup";
import MuiConfig from "components/common/config/MuiConfig";
import WijmoGrid from "components/common/element/WijmoGrid";
import Modal from "components/common/modal/UModal";
import UmodalTemplate from "components/common/modal/UModalTemplate";
import ObjectUtils from "components/common/utils/ObjectUtils";
import StringUtils from "components/common/utils/StringUtils";
import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { FaTrash } from "react-icons/fa";
import { MdOutlineAdd } from "react-icons/md";
import UserService from "services/common/UserService";

function AppManagerRegistPopup({ app, ...props }) {
  const [registerManagerList, setRegisterManagerList] = useState([]);
  const [userList, setUserList] = useState([]);
  const [selectedUser, setSelectedUser] = useState({});

  const columns = [
    {
      field: "state",
      headerName: "State",
      width: 80,
      renderCell: (param) => {
        return param.createdYn === "Y" ? (
          <Tooltip title="To be added">
            <MdOutlineAdd size={"16"} color="blue" />
          </Tooltip>
        ) : param.deletedYn === "Y" ? (
          <Tooltip title="To be deleted">
            <FaTrash size={"16"} color="tomato" />
          </Tooltip>
        ) : (
          <></>
        );
      },
      // renderCell: (param) =>
      //   param.row.createdYn === "Y" ? (
      //     <Tooltip title="추가 예정">
      //       <MdOutlineAdd size={"16"} color="blue" />
      //     </Tooltip>
      //   ) : param.row.deletedYn === "Y" ? (
      //     <Tooltip title="삭제 예정">
      //       <FaTrash size={"16"} color="tomato" />
      //     </Tooltip>
      //   ) : null,
    },
    {
      field: "userId",
      headerName: "User ID",
      width: 140,
    },
    {
      field: "userNm",
      headerName: "User Name",
      width: 120,
    },
    {
      field: "userAuthType",
      headerName: "Authorization",
      width: "*",
      renderCell: (param) => {
        const auth = param.userAuths.find((a) =>
          StringUtils.equalsIgnoreType(a.appId, app.appId)
        );
        return auth.userAuthName;
      },
    },
    {
      field: "delete",
      headerName: "Remarks",
      headerAlign: "center",
      width: 100,
      align: "center",
      renderCell: (param) => {
        if (param.deletedYn === "Y") {
          return (
            <Button
              size="sm"
              variant="outline-primary"
              onClick={(e) => onManagerDelete(e, param)}
            >
              Cancel
            </Button>
          );
        } else {
          return (
            <Button
              size="sm"
              variant="outline-danger"
              onClick={(e) => onManagerDelete(e, param)}
            >
              Delete
            </Button>
          );
        }
      },
    },
  ];

  useEffect(() => {
    getUserList();
  }, []);

  /**
   * 사용자 목록 호출
   */
  const getUserList = () => {
    UserService.getUserList({ appId: app.appId }, (res) => {
      setRegisterManagerList(res.data);
    });

    UserService.getAllUserList({}, (all) => {
      const userLabelList = all.data.map((user) => ({
        ...user,
        label: `${user.userId} | ${user.userNm}`,
      }));
      setUserList(userLabelList);
    });
  };

  const onSaveManager = (e) => {
    if (e) e.preventDefault();

    const body = {
      appUserList: registerManagerList,
    };
    UserService.updateList(body, (res) => {
      Message.alert(res.message, Enums.MessageType.SUCCESS);
      Popup.close();
    });
  };

  /**
   * 담당자 추가
   * @param {*} e
   */
  const onAddManager = (e) => {
    if (e) e.preventDefault();
    if (ObjectUtils.isEmpty(selectedUser)) {
      return Message.alert("Select Manager", Enums.MessageType.INFO);
    }

    const dup = registerManagerList.find(
      (u) => u.userMastRecId === selectedUser.userMastRecId
    );
    if (dup) {
      return Message.alert(
        "This user is already registered.",
        Enums.MessageType.INFO
      );
    } else {
      setRegisterManagerList([
        ...registerManagerList,
        {
          ...selectedUser,
          userAuths: [
            {
              appId: app.appId,
              userAuthType: "AM",
              userAuthName: "APP Manager",
            },
          ],
          requestYn: "N",
          createdYn: "Y",
        },
      ]);
    }
  };

  /**
   * 담당자 삭제
   * @param {*} e
   */
  const onManagerDelete = (e, _manager) => {
    if (e) e.preventDefault();
    const newList = [...registerManagerList];
    const targetIndex = newList.findIndex((m) => m.userId === _manager.userId);
    const manager = newList.find((m) => m.userId === _manager.userId);
    if (StringUtils.equalsIgnoreCase(_manager.createdYn, "Y")) {
      newList.splice(targetIndex, 1);
    } else if (StringUtils.equalsIgnoreCase(_manager.deletedYn, "Y")) {
      manager.deletedYn = "N";
      newList.splice(targetIndex, 1, manager);
    } else {
      manager.deletedYn = "Y";
      newList.splice(targetIndex, 1, manager);
    }
    setRegisterManagerList(newList);
  };

  return (
    <Modal>
      <Modal.Header title={`Register ${app.appNm} Manager`} />
      <Modal.Body>
        <UmodalTemplate>
          <Grid container spacing={2}>
            <Grid
              item
              xs={4}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              Search Manager
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={userList}
                size="small"
                onChange={(e, value) => setSelectedUser(value)}
                isOptionEqualToValue={(option, value) =>
                  option.userMastRecId === value.userMastRecId
                }
                renderInput={(params) => (
                  <TextField {...params} label="Enter User" />
                )}
              />
            </Grid>
            <Grid
              item
              xs={2}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button onClick={onAddManager}>Add</Button>
            </Grid>
            <Grid item xs={12} height={500}>
              <WijmoGrid
                columns={columns}
                rows={registerManagerList}
                getRowId={(row) => row.userId}
                headerHeight={30}
                hideFooter={true}
                {...MuiConfig.grid.options}
                pageSize={100}
                rowsPerPageOptions={[100]}
                style={{ height: "50vh" }}
              />
            </Grid>
          </Grid>
        </UmodalTemplate>
      </Modal.Body>
      <Modal.Footer>
        <Modal.Footer.Button onClick={onSaveManager}>Save</Modal.Footer.Button>
      </Modal.Footer>
    </Modal>
  );
}

export default AppManagerRegistPopup;
