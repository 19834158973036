import { Checkbox, FormControlLabel, Tooltip } from "@mui/material";
import { Enums } from "components/builder/BuilderEnum";
import Message from "components/common/Message";
import Modal from "components/common/modal/UModal";
import UmodalTemplate from "components/common/modal/UModalTemplate";
import ArrayUtils from "components/common/utils/ArrayUtils";
import { debounce } from "components/common/utils/InputUtils";
import ObjectUtils from "components/common/utils/ObjectUtils";
import StringUtils from "components/common/utils/StringUtils";
import React, { useState } from "react";
import { useEffect } from "react";
import { Col, Form, Row } from "react-bootstrap";
import CodeService from "services/common/CodeService";

function DataModelDeployPopup({
  onDeploy,
  output,
  onPopupInput,
  workspace,
  connection,
  ...props
}) {
  const [dataModelNm, setDataModelNm] = useState(output.dataModelNm);
  const [description, setDescription] = useState(output.description);
  const [dataModelType, setDataModelType] = useState(output.dataModelType);
  const [apiUrl, setApiUrl] = useState(output.apiUrl);
  const [isSaving, setIsSaving] = useState(false);
  const [dataModelTypeList, setDataModelTypeList] = useState([]);
  const [commitComment, setCommitComment] = useState("");
  const [releaseCommentYn, setReleaseCommentYn] = useState("N");

  let comboParams = { codeMstCd: "Z0014" };

  useEffect(() => {
    CodeService.getCodeCombo(comboParams, (res) => {
      setDataModelTypeList(res.data);
    });
  }, []);

  const onClickDeploy = () => {
    if (StringUtils.isEmpty(dataModelNm)) {
      return Message.alert("Please enter Model Name", Enums.MessageType.WARN);
    }
    //동일한 물리명이 나오지 않도록
    let breakFlag = false;
    if (output.dataModelEntities.length > 0) {
      for (const _entity of output.dataModelEntities) {
        breakFlag = false;
        //동일한 엔티티 필드 확인
        const { dataModelEntityFields } = _entity;
        for (const entityField of dataModelEntityFields) {
          if (entityField.autoNumberingType) {
            if (StringUtils.isEmpty(entityField.insertOption)) {
              breakFlag = true;
              Message.alert(
                `There is a missing part in the ${entityField.columnNm} field's auto-numbering setup.`,
                Enums.MessageType.INFO
              );
              break;
            }
            let insertOption = JSON.parse(entityField.insertOption);
            if (
              StringUtils.equalsIgnoreCase(entityField.autoNumberingType, "S")
            ) {
              if (
                StringUtils.isEmpty(
                  insertOption.arguments.find((i) => i.name === "type")
                    .defaultValue
                )
              ) {
                breakFlag = true;
                Message.alert(
                  `The ${entityField.columnNm} field's auto-numbering type is missing.`,
                  Enums.MessageType.INFO
                );
                break;
              }
            } else if (
              StringUtils.equalsIgnoreCase(entityField.autoNumberingType, "U")
            ) {
              if (StringUtils.isEmpty(insertOption.procedureName)) {
                breakFlag = true;
                Message.alert(
                  `The procedure name for the ${entityField.columnNm}  field's user numbering is missing.`,
                  Enums.MessageType.INFO
                );
                break;
              }
              let output = insertOption.arguments.find((a) =>
                StringUtils.equalsIgnoreCase(a.mode, "OUT")
              );
              if (StringUtils.isEmpty(output.name)) {
                breakFlag = true;
                Message.alert(
                  `The ${entityField.columnNm}  field's OUTPUT arguments variable name  is missing.`,
                  Enums.MessageType.INFO
                );
                break;
              }
            }
          }

          const samePhyFiels = dataModelEntityFields.filter(
            (f) =>
              StringUtils.equalsIgnoreType(
                f.physFieldNm,
                entityField.physFieldNm
              ) && !f.refEntityId
          );
          if (samePhyFiels.length > 1) {
            breakFlag = true;
            Message.alert(
              `There are fields (columns) in the ${_entity.physEntityNm} entity that use the same physical name. 
Please check the physical name`,
              Enums.MessageType.INFO
            );
            break;
          }
        }

        //동일한 엔티티명 확인
        const samePhysNameModels = output.dataModelEntities.filter((dme) => {
          return StringUtils.equalsIgnoreType(
            dme.physEntityNm,
            _entity.physEntityNm
          );
        });
        if (samePhysNameModels.length > 1) {
          breakFlag = true;
          Message.alert(
            `The ${_entity.physEntityNm}  entity name has been used multiple times. Please check the physical name.`,
            Enums.MessageType.INFO
          );
        }

        if (breakFlag) break;
      }
    }
    if (breakFlag) {
      return false;
    }
    if (
      StringUtils.equalsIgnoreCase(dataModelType, "s") &&
      StringUtils.isEmpty(apiUrl)
    ) {
      return Message.alert(
        "In the Service API, the API address is required.",
        Enums.MessageType.INFO
      );
    }
    if (StringUtils.isEmpty(commitComment)) {
      return Message.alert(
        "Please enter deploy comment.",
        Enums.MessageType.INFO
      );
    }

    const body = {
      ...output,
      dataModelNm,
      description,
      dataModelType,
      apiUrl,
      appId: workspace.appId,
      moduleCd: workspace.moduleCd,
      appReleaseId: workspace.appReleaseId,
      tenantId: workspace.tenantId,
      coCd: workspace.coCd,
      releaseCommentYn,
      commitComment,
      appApplyType: "Y",
      newDataModel: output.dataModelId ? "N" : "Y",
    };
    //자식이 배열만 있는지 JSON이 혼재되어있는지 명확한 구분 불가
    body.dataModelEntities = body.dataModelEntities.map((entity) => {
      const obj = { ...entity };
      //내용이 없으면 null로 진행
      obj.position = obj.position ? JSON.stringify(obj.position) : null; //position이 null인 경우는 아마 없을것.
      obj.relation = !ArrayUtils.isEmpty(obj.relation)
        ? JSON.stringify(obj.relation)
        : null;
      obj.orderby = !ArrayUtils.isEmpty(obj.orderby)
        ? JSON.stringify(obj.orderby)
        : null;

      obj.whereJoinTable = !ArrayUtils.isEmpty(obj.whereJoinTable)
        ? JSON.stringify(obj.whereJoinTable)
        : null;
      obj.parameterList = !ArrayUtils.isEmpty(obj.parameterList)
        ? JSON.stringify(obj.parameterList)
        : null;
      obj.remark = JSON.stringify(
        ObjectUtils.isEmpty(obj.remark) ? {} : obj.remark
      );
      obj.dataModelEntityFields = entity.dataModelEntityFields.map((field) => {
        const fieldObj = { ...field };
        //논리명 비어있을때 물리명으로 채워넣음
        if (StringUtils.isEmpty(fieldObj.logFieldNm)) {
          fieldObj.logFieldNm = fieldObj.physFieldNm;
        }

        //json to string
        if (field.fieldOption) {
          if (Object.keys(field.fieldOption).length > 0) {
            field.fieldOption = JSON.stringify(field.fieldOption);
          } else fieldObj.fieldOption = null;
        } else fieldObj.fieldOption = null;

        //각 연계된 ID 값은 새로 부여 되기 때문에 null처리
        fieldObj.refEntityId = null;
        fieldObj.refEntityFieldId = null;

        return fieldObj;
      });
      return obj;
    });

    setIsSaving(true);
    if (onDeploy)
      onDeploy(body, () => {
        setIsSaving(false);
      });
  };

  const debounceType = debounce((id, value) => {
    onPopupInput(id, value);
  }, 200);

  const onChangePropertValue = (e) => {
    debounceType(e.target.id, e.target.value);
  };

  const onChangeReleaseCommentYn = (e) => {
    let value = e.target.checked;
    if (value) {
      setReleaseCommentYn("Y");
    } else {
      setReleaseCommentYn("N");
    }
  };

  return (
    <Modal>
      <Modal.Header title="Deploy Data Model" />
      <Modal.Body>
        <UmodalTemplate>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Check deployment path</Form.Label>
              <Row>
                <Form.Group className="mb-3" as={Col}>
                  <Form.Label>Application</Form.Label>
                  <Form.Control disabled defaultValue={workspace.appNm} />
                </Form.Group>
                <Form.Group className="mb-3" as={Col}>
                  <Form.Label>Module</Form.Label>
                  <Form.Control disabled defaultValue={workspace.moduleNm} />
                </Form.Group>
                <Form.Group className="mb-3" as={Col}>
                  <Form.Label>Version</Form.Label>
                  <Form.Control disabled defaultValue={workspace.version} />
                </Form.Group>
              </Row>
              <Row>
                <Form.Group as={Col} className="mb-3">
                  <Form.Label>Tenant ID</Form.Label>
                  <Form.Control disabled defaultValue={workspace.tenantId} />
                </Form.Group>

                <Form.Group as={Col}>
                  <Form.Label>Company Code</Form.Label>
                  <Form.Control disabled defaultValue={workspace.coCd} />
                </Form.Group>
              </Row>
              <Row>
                <Form.Group className="mb-3" as={Col}>
                  <Form.Label>Connection Name</Form.Label>
                  <Form.Control
                    defaultValue={connection.connectionNm}
                    disabled={true}
                  />
                </Form.Group>
                <Form.Group className="mb-3" as={Col}>
                  <Form.Label>Connection HOST</Form.Label>
                  <Form.Control
                    defaultValue={connection.host}
                    disabled={true}
                  />
                </Form.Group>
              </Row>
            </Form.Group>
            <Form.Group>
              <Form.Label className="required">Data Model Name</Form.Label>
              <Form.Control
                id="dataModelNm"
                value={dataModelNm}
                onChange={(e) => {
                  onChangePropertValue(e);
                  setDataModelNm(e.currentTarget.value);
                }}
              />
            </Form.Group>
            <Form.Group className="mt-3">
              <Form.Label style={{ display: "flex" }}>Model Type</Form.Label>
              <Form.Control
                as={"select"}
                id="dataModelType"
                value={dataModelType}
                disabled
              >
                {dataModelTypeList.map((type) => (
                  <option value={type.id} key={type.id}>
                    {type.text}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group className="mt-3">
              <Form.Label
                className={
                  StringUtils.equalsIgnoreCase(dataModelType, "S")
                    ? "required"
                    : ""
                }
              >
                API Address
              </Form.Label>
              <Form.Control
                id="apiUrl"
                value={apiUrl}
                onChange={(e) => {
                  onChangePropertValue(e);
                  setApiUrl(e.currentTarget.value);
                }}
              />
            </Form.Group>
            <Form.Group className="mt-3">
              <Form.Label style={{ display: "flex" }}>Description</Form.Label>
              <Form.Control
                as={"textarea"}
                value={description}
                id="description"
                style={{ minHeight: "120px" }}
                onChange={(e) => {
                  setDescription(e.currentTarget.value);
                  onChangePropertValue(e);
                }}
              />
            </Form.Group>
            <Form.Group className="mt-3">
              <Form.Label className="required">Data Model Comment</Form.Label>
              <input
                type="text"
                id="programComment"
                name="programComment"
                className="form-control"
                max={120}
                placeholder={"Up to 120 characters"}
                value={commitComment}
                onChange={(e) => setCommitComment(e.target.value)}
              />
            </Form.Group>
            <Form.Group
              className="mb-3"
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Tooltip
                title="When deploying a new version, you can choose whether to include the revision details from the previous version."
                placement="left"
              >
                <FormControlLabel
                  label={"Include deployment comments in the revision"}
                  control={
                    <Checkbox
                      checked={releaseCommentYn === "Y"}
                      onChange={onChangeReleaseCommentYn}
                    />
                  }
                />
              </Tooltip>
            </Form.Group>
          </Form>
        </UmodalTemplate>
      </Modal.Body>
      <Modal.Footer>
        <Modal.Footer.ProgressButton
          onClick={onClickDeploy}
          doing={isSaving}
          doingText={"Deploying.."}
          variant={"success"}
        >
          Deploy
        </Modal.Footer.ProgressButton>
      </Modal.Footer>
    </Modal>
  );
}

export default DataModelDeployPopup;
