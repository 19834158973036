import Popup from "components/common/Popup";
import Modal from "components/common/modal/UModal";
import { useState } from "react";

import { TextField as MInput } from "@mui/material";
import MuiConfig from "components/common/config/MuiConfig";
import { StringUtils } from "components/common/utils/CommonUtils";
import User from "components/common/utils/UserUtils";
import CodeService from "services/common/CodeService";

import { Enums } from "components/builder/BuilderEnum";
import Message from "components/common/Message";
import WijmoGrid from "components/common/element/WijmoGrid";
import { Button } from "react-bootstrap";
import { BsSearch } from "react-icons/bs";

const MessageListPopup = (props) => {
  const [gridData, setGridData] = useState([]);

  const [inputs, setInputs] = useState({
    msgCd: "",
    msgText: "",
  });

  const onChange = (event) => {
    const fieldId = StringUtils.defaultString(
      event.target.name,
      event.target.id
    );
    setInputs({
      ...inputs,
      [fieldId]: event.target.value,
    });
  };

  const onKeyPress = (event) => {
    if (event.key === "Enter") {
      search();
    }
  };

  const search = () => {
    const deployConnection = User.getConnection(
      props.workspace.Info.tenantMstId
    );
    if (!deployConnection?.token) {
      // if (cb) cb();
      return Message.alert(
        "Authentication server token is not validated.\n Please reconnect.v",
        Enums.MessageType.ERROR
      );
    }

    let params = { ...deployConnection, ...inputs };
    params.language = User.getDefaultLangCd();

    /* Grid data */
    CodeService.getMessageList(params, (res) => {
      setGridData(res.data ? res.data : []);
    });
  };

  // useEffect(() => {
  //   search();
  // }, []);

  const columns = [
    { field: "msgCd", headerName: "Massage Code", width: 200 },
    { field: "msgText", headerName: "Message", width: "*" },
  ];

  const gridOption = { ...MuiConfig.grid.options };
  gridOption.pageSize = 10;
  return (
    <Modal>
      <Modal.Header {...props} />
      <Modal.Body>
        <div className="row mb-2">
          <div className="col-4">
            <MInput
              id="msgCd"
              label="Message Code"
              type="text"
              fullWidth
              size="small"
              className="xmall-input"
              color="primary"
              placeholder="Message Code"
              value={inputs.msgCd}
              onChange={onChange}
              onKeyPress={onKeyPress}
            />
          </div>
          <div className="col-6">
            <MInput
              id="msgText"
              label="Message"
              type="text"
              fullWidth
              size="small"
              className="xmall-input"
              color="primary"
              placeholder="Message"
              value={inputs.msgText}
              onChange={onChange}
              onKeyPress={onKeyPress}
            />
          </div>
          <div className="col-2">
            <Button variant="primary" onClick={search}>
              Search <BsSearch size="14" />
            </Button>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div style={{ height: 500, width: "100%" }}>
              <WijmoGrid
                getRowId={(row) => row.msgCd}
                rows={gridData}
                columns={columns}
                onRowDoubleClick={(data, event) => {
                  if (props.callbackFnc) props.callbackFnc.call(this, data);
                  event.defaultMuiPrevented = true;
                  Popup.close();
                }}
                style={{ height: `calc(50vh + 27px)` }}
                {...gridOption}
              />
            </div>
            <div className="col-3"></div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="fixed" {...props} />
    </Modal>
  );
};

export default MessageListPopup;
