import React, { Component } from "react";
import { connect } from "react-redux";
import { Scrollbar } from "smooth-scrollbar-react";
import { JsonUtils, ObjectUtils } from "components/common/utils/CommonUtils";
import * as Enums from "components/builder/BuilderEnum";
import Entity from "components/builder/entity/entityPropertiesTab/Entity";
import EntityField from "components/builder/entity/entityPropertiesTab/EntityField";
import DataModel from "components/builder/entity/entityPropertiesTab/DataModel";

class EntityPropertiesTab extends Component {
  //수정 필요
  shouldComponentUpdate(nextProps, nextState) {
    return this.props.component.compId !== nextProps.component.compId;
  }

  render() {
    const renderComponentDetails = () => {
      if (!ObjectUtils.isEmpty(this.props.component)) {
        const component = { ...this.props.component };

        if (component.type === Enums.EntityComponentType.DATA_MODEL) {
          return <DataModel.Properties output={this.props.output} />;
          //Entity를 선택한경우
        } else if (component.type === Enums.EntityComponentType.ENTITY) {
          return (
            <Entity.Properties
              compId={component.compId}
              componentInfo={component}
            />
          );
          //EntityField를 선택한경우
        } else if (component.type === Enums.EntityComponentType.ENTITY_FIELD) {
          //선택한 Field의 엔티티가 Trd 출신인지 판단.

          const entity = this.props.output.dataModelEntities.find((e) =>
            e.dataModelEntityFields.find((f) => f.compId === component.compId)
          );
          return (
            <EntityField.Properties
              isTrd={entity.remark?.tableMstId ? true : false}
            />
          );
        }
      } else {
        return (
          <div className="empty-properties">
            There are no selected Entity Components.
          </div>
        );
      }
    };
    return (
      <Scrollbar
        className="custom-class"
        plugins={{
          overscroll: {
            effect: "glow",
          },
        }}
      >
        <div className="properties-component-panel">
          <div>{renderComponentDetails()}</div>
        </div>
      </Scrollbar>
    );
  }
}

const dispatchUpdateProperty = (dispatch) => {
  return {};
};

export default connect((state) => {
  return {
    //임시 주석처리
    component: state.activedENTComponent,
    output: state.outputENT.output,
  };
}, dispatchUpdateProperty)(EntityPropertiesTab);
