import { CircularProgress } from "@mui/material";
import React from "react";

function ContentsLoading({ show, type = "bar", loadingText, ...props }) {
  if (show) {
    if (String(type).toLowerCase() === "bar") {
      return (
        <div className="load-wrapper">
          <div className="loadbar-wrapper">
            <div className="loadbar bar1" />
            <div className="loadbar bar2" />
            <div className="loadbar bar3" />
            <div className="loadbar bar4" />
            <div className="loadbar bar5" />
          </div>
          <div className="load-text"></div>
        </div>
      );
    } else if (String(type).toLowerCase() === "circle") {
      return (
        <div className="load-wrapper">
          <div className="circle">
            <CircularProgress color="inherit" size={13} />
            &nbsp;&nbsp;&nbsp; {loadingText || "Data Loading..."}
          </div>
        </div>
      );
    }
  } else {
    return <></>;
  }
}

export default ContentsLoading;
