import { Enums } from "components/builder/BuilderEnum";
import Message from "components/common/Message";
import Modal from "components/common/modal/UModal";
import UmodalTemplate from "components/common/modal/UModalTemplate";
import Popup from "components/common/Popup";
import StringUtils from "components/common/utils/StringUtils";
import React, { useEffect, useState } from "react";
import { useRef } from "react";
import {
  Button,
  Col,
  Form,
  FormGroup,
  FormLabel,
  InputGroup,
  Row,
} from "react-bootstrap";
import AppService from "services/common/AppService";
import styled from "styled-components";

const ApplyModuleBox = styled.div`
  min-height: 150px;
  overflow: auto;
`;

const ApplyModuleRow = styled.div`
  display: flex;
  height: 35px;
  border-bottom: 1px solid lightgray;
  & > div {
    display: flex;
    align-items: center;
    width: 100%;
  }
`;

//테넌트 일괄 적용 팝업
function TenantBatchApplyPopup({
  applicationList,
  appId: _appId,
  callback,
  ...props
}) {
  const [appId, setAppId] = useState(_appId);
  const [availableAppReleaseList, setAvailableAppReleaseList] = useState([]);
  const [selectedAppRelease, setSelectedAppRelease] = useState({});

  const [tenantId, setTenantId] = useState("");
  const [tenantNm, setTenantNm] = useState("");
  const [coCd, setCoCd] = useState("");
  const [coNm, setCoNm] = useState("");

  const [applyList, setApplyList] = useState([]);
  const [tenantInputMode, setTenantInputMode] = useState(true);
  const [tenantList, setTenantList] = useState([]);
  const [coInputMode, setCoInputMode] = useState(true);
  const [coList, setCoList] = useState([]);
  const tenantMstList = useRef([]);

  useEffect(() => {
    getAvailableAppReleaseList(appId);
    getTenantList(appId);
  }, []);

  const onAddApplyList = () => {
    if (!selectedAppRelease)
      return Message.alert(
        "Please select Module Version.",
        Enums.MessageType.INFO
      );
    if (applyList.indexOf(selectedAppRelease) > -1) {
      return Message.alert(
        "This item already Registered.",
        Enums.MessageType.INFO
      );
    } else {
      setApplyList([...applyList, selectedAppRelease]);
    }
  };

  const getAvailableAppReleaseList = (appId) => {
    AppService.getAvailableAppReleaseList({ appId }, (res) => {
      setAvailableAppReleaseList(res.data);
    });
  };

  const onChangeAppId = (e) => {
    setAppId(e.target.value);
    getTenantList(e.target.value);
    getAvailableAppReleaseList(e.target.value);
  };

  const getTenantList = (appId) => {
    if (!appId) return Message.alert("Please select Application");
    const body = {
      appId,
    };
    AppService.getTenantDistinctList(body, (res) => {
      tenantMstList.current = res.data;
      const list = [];
      for (const tenantMst of res.data) {
        if (tenantMst.tenantId === "*") continue;
        if (list.indexOf(tenantMst.tenantId) == -1) {
          list.push(tenantMst.tenantId);
        }
      }
      setTenantList(list);
    });
  };

  const onChangeTenantId = (e) => {
    const value = e.target.value;
    setTenantId(value);
    //값이 있는 부분은 자동 적용 | 셀렉트 모드에서는 기타내용 자동적용
    const tenantMst = tenantMstList.current.filter((t) => t.tenantId === value);
    if (tenantMst.length > 0) {
      setTenantNm(tenantMst[0].tenantNm);
      setCoList(tenantMst.map((t) => t.coCd));
    }
  };
  const onChangeCoCd = (e) => {
    const value = e.target.value;
    setCoCd(value);
    //값이 있는 부분은 자동 적용 | 셀렉트 모드에서는 기타내용 자동적용
    const tenantMst = tenantMstList.current.find(
      (t) => t.tenantId === tenantId && t.coCd === value
    );
    if (tenantMst) {
      setCoNm(tenantMst.coNm);
    }
  };

  const tenantBatchApply = () => {
    const appReleaseList = availableAppReleaseList.filter(
      (a) => applyList.indexOf(String(a.appReleaseId)) > -1
    );
    if (!appId || !tenantId || !tenantNm || !coCd || !coNm) {
      return Message.alert(
        "Please enter Required Field.",
        Enums.MessageType.ERROR
      );
    }
    if (appReleaseList.length === 0) {
      return Message.alert(
        "There is no selected Module version.",
        Enums.MessageType.ERROR
      );
    }
    const body = {
      appReleaseList,
      tenantId,
      coCd,
      tenantNm,
      coNm,
      appId,
    };

    AppService.saveTenantBatch(body, (res) => {
      Popup.close();
      if (callback) {
        callback();
      }
    });
  };

  return (
    <Modal>
      <Modal.Header title={"Add Tenant"} />
      <Modal.Body>
        <UmodalTemplate>
          <Row className="mb-3 gy-3">
            <FormGroup as={Col} xs={12}>
              <FormLabel className="required">Select Application</FormLabel>
              <Form.Select value={appId} onChange={onChangeAppId}>
                {applicationList.map((app) => {
                  return (
                    <option value={app.appId} key={app.appId}>
                      {app.appNm}
                    </option>
                  );
                })}
              </Form.Select>
            </FormGroup>
            <FormGroup as={Col} xs={6}>
              <FormLabel className="required">Tenant ID</FormLabel>
              {tenantInputMode ? (
                <InputGroup>
                  <Form.Control
                    value={tenantId}
                    onChange={(e) => setTenantId(e.currentTarget.value)}
                  />
                  <Button onClick={(e) => setTenantInputMode(false)}>
                    Select
                  </Button>
                </InputGroup>
              ) : (
                <InputGroup>
                  <Form.Select value={tenantId} onChange={onChangeTenantId}>
                    <option value="">Select</option>
                    {tenantList.map((tenant) => {
                      return (
                        <option value={tenant} key={tenant}>
                          {tenant}
                        </option>
                      );
                    })}
                  </Form.Select>
                  <Button onClick={(e) => setTenantInputMode(true)}>
                    Enter
                  </Button>
                </InputGroup>
              )}
            </FormGroup>
            <FormGroup as={Col} xs={6}>
              <FormLabel className="required">Tenant Name</FormLabel>
              <Form.Control
                disabled={!tenantInputMode}
                value={tenantNm}
                onChange={(e) => setTenantNm(e.currentTarget.value)}
              />
            </FormGroup>
            <FormGroup as={Col} xs={6}>
              <FormLabel className="required">Company Code</FormLabel>
              {coInputMode ? (
                <InputGroup>
                  <Form.Control value={coCd} onChange={onChangeCoCd} />
                  <Button
                    disabled={coList.length > 0 ? false : true}
                    onClick={(e) => setCoInputMode(false)}
                  >
                    Select
                  </Button>
                </InputGroup>
              ) : (
                <InputGroup>
                  <Form.Select value={coCd} onChange={onChangeCoCd}>
                    <option value="">Select</option>
                    {coList.map((co) => {
                      return (
                        <option value={co} key={co}>
                          {co}
                        </option>
                      );
                    })}
                  </Form.Select>
                  <Button onClick={(e) => setCoInputMode(true)}>Enter</Button>
                </InputGroup>
              )}
            </FormGroup>
            <FormGroup as={Col} xs={6}>
              <FormLabel className="required">Company Name</FormLabel>
              <Form.Control
                value={coNm}
                onChange={(e) => setCoNm(e.currentTarget.value)}
                disabled={!coInputMode}
              />
            </FormGroup>
            <FormGroup as={Col} xs={12}>
              <FormLabel>Add Module Version</FormLabel>
              <InputGroup>
                <Form.Select
                  value={selectedAppRelease}
                  onChange={(e) => setSelectedAppRelease(e.target.value)}
                >
                  <option value="">Select Application</option>
                  {availableAppReleaseList.map((release) => {
                    return (
                      <option
                        value={release.appReleaseId}
                        key={release.appReleaseId}
                      >
                        {release.moduleNm} ( {release.version} )
                      </option>
                    );
                  })}
                </Form.Select>
                <Button onClick={onAddApplyList}>Add</Button>
              </InputGroup>
            </FormGroup>
            <FormGroup as={Col} xs={12}>
              <FormLabel>Module List to Appy</FormLabel>
              <ApplyModuleBox className="form-control">
                <ApplyModuleRow>
                  <div>Module Name</div>
                  <div>Version</div>
                  <div>Remarks</div>
                </ApplyModuleRow>

                {applyList.map((appModuleId, idx) => {
                  const appModule = availableAppReleaseList.find((a) =>
                    StringUtils.equalsIgnoreType(a.appReleaseId, appModuleId)
                  );
                  return (
                    <ApplyModuleRow key={appModuleId}>
                      <div>{appModule.moduleNm}</div>
                      <div>{appModule.version}</div>
                      <div>
                        <Button
                          size="small"
                          variant="outline-danger"
                          onClick={(e) => {
                            const newList = [...applyList];
                            newList.splice(idx, 1);
                            setApplyList(newList);
                          }}
                        >
                          Delete
                        </Button>
                      </div>
                    </ApplyModuleRow>
                  );
                })}
              </ApplyModuleBox>
            </FormGroup>
          </Row>
        </UmodalTemplate>
      </Modal.Body>
      <Modal.Footer>
        <Modal.Footer.Button onClick={tenantBatchApply}>
          Save
        </Modal.Footer.Button>
      </Modal.Footer>
    </Modal>
  );
}

export default TenantBatchApplyPopup;
