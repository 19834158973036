/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from "react";
import Popup from "components/common/Popup";
import Modal from "components/common/modal/UModal";
import styled from "styled-components";
import "bootstrap/dist/css/bootstrap.min.css";

import DataModelService from "services/datamodel/DataModelService";
import StringUtils from "components/common/utils/StringUtils";
import * as Fa from "react-icons/fa";
import { Button, Col, Form, Row } from "react-bootstrap";
import ArrayUtils from "components/common/utils/ArrayUtils";
import produce from "immer";
import { MdLibraryAdd } from "react-icons/md";
import { BsPencilSquare, BsQuestionLg } from "react-icons/bs";
import {
  PropertyFieldSet,
  PropertyFieldSetContents,
} from "components/builder/ui/uiComponents/UIComponentStyle";
import ObjectUtils from "components/common/utils/ObjectUtils";
import ComboEntityValueSettingGuide from "page/popup/ComboEntityValueSettingGuide";
import UInputPopup from "components/common/element/UInputPopup";
import ExtendPopup from "./ExtendPopup";

const SelectEntityPopupStyle = styled.div`
  .fieldset-border {
    // border: 1px solid #7e7b7b;
    border: 1px solid #cfcfcf;
  }
  .or-btn-minus {
    width: 20px;
    padding: 0px;
    margin-left: 5px;
    font-size: 1rem;
  }
`;

/**
 * IconPopup : textarea을 확장해서 입력할 Popup
 *
 * 2022.03.11: init: songe.park
 * 2022.03.18: json, java등 다양한 언어 사용 가능하도록 수정
 *
 * @param {*} props
 * @returns value
 */
const SelectEntityPopup = (props) => {
  const {
    comboEntityId,
    comboEntityNm,
    comboEntityFilter,
    dataModelId,
    callbackFnc,
    operatorList,
  } = props;
  const [entityId, setEntityId] = useState(comboEntityId);
  const [entityNm, setEntityNm] = useState(comboEntityNm);
  const [filter, setFilter] = useState(comboEntityFilter || {});
  const [entityList, setEntityList] = useState({});
  const [dataBindingList, setDataBindingList] = useState([]);

  //initPopup: popup open 하고 실행
  const initPopup = () => {
    // AND절
    if (ObjectUtils.isEmpty(comboEntityFilter)) {
      setFilter([{}]);
    } else {
      let initItem = [...(comboEntityFilter || {})];
      if (ArrayUtils.isEmpty(initItem)) {
        initItem = [{}];
      }
      setFilter(initItem);
    }

    if (!StringUtils.isEmpty(entityId)) {
      selectDataBindingList(entityId);
    }

    DataModelService.getDataModelEntityList(
      { dataModelId: dataModelId },
      (res) => {
        setEntityList(
          res.data ? res.data : [{ id: "none", text: "[none] None Data" }]
        );
      }
    );
  };

  /**
   * Entity base Combo 일 경우  Entity 변경시 Filter 초기화 포함
   * @param {*} event
   */
  const onChangecomboEntityFilter = (event) => {
    const key = event.target.value;
    setEntityId(key);
    selectDataBindingList(key);
    const selectedItems = entityList.filter(
      (data) => String(data.entityId) === key
    );

    if (!ObjectUtils.isEmpty(selectedItems)) {
      setEntityNm(selectedItems[0].text);
    } else {
      setEntityNm("");
    }
  };

  /**
   * Data Model Entity Field List 조회(Data Binidng)
   * @param {*} entityId
   */
  const selectDataBindingList = (entityId) => {
    if (!StringUtils.isEmpty(entityId)) {
      DataModelService.getDataBindingList({ entityId: entityId }, (res) => {
        setDataBindingList(
          res.data ? res.data : [{ id: "none", text: "[none] None Data" }]
        );
      });
    }
  };

  /**
   *
   * @param {*} e
   * @param {*} title
   */
  const openDefaultPopup = (type, e, param, index, orIndex) => {
    const options = {
      keyDownEvent: false,
      effect: Popup.ScaleUp, //Effect.SlideFromTop(default)를 Effect.ScaleUp 로 변경
      style: {
        content: {
          minHeight: "200px",
        },
      },
    };
    const id = e.currentTarget.formTarget;
    const val = param[id];

    Popup.open(
      <ExtendPopup
        title="Value Detail"
        fieldType="javascript"
        defaultValue={val}
        callbackFnc={(popVal) => {
          const event = { target: { id: id, value: popVal } };
          if (type === "and") {
            onChangeParam(event, param, index);
          } else {
            onChangeParamOr(event, param, orIndex, index);
          }
        }}
      />,
      options
    );
  };

  /************************ AND절 ***********************/
  /**
   * Data 변경 시 DataBinding 처리 후 parameter 값을 변경해준다.
   *
   * @param {*} e
   * @param {*} param
   * @param {*} index
   */
  const onChangeDataBinding = (e, param, index) => {
    let newParam = { ...param };
    //id를 setting해준다.
    newParam.id = StringUtils.substringAfter(e.target.value, ".");
    changeParam(e, newParam, index);
  };

  const onChangeParam = (e, param, index) => {
    changeParam(e, param, index);
  };

  const changeParam = (e, param, index) => {
    const newParams = produce(filter, (draft) => {
      draft[index] = { ...param, [e.target.id]: e.target.value };
    });
    setFilter(newParams);
  };

  /* AND절 추가 버튼 부분 */
  const onInsertClick = (e, index) => {
    const newParams = produce(filter, (draft) => {
      draft.splice(index + 1, 0, {});
    });
    setFilter(newParams);
  };

  /* OR절 추가 버튼 부분 */
  const onOrInsertClick = (e, index) => {
    const newParams = produce(filter, (draft) => {
      draft.splice(index + 1, 0, {});
      draft[index + 1] = { or: [{}] };
    });
    setFilter(newParams);
  };

  /* AND절 삭제 버튼 부분 */
  const onDeleteClick = (e, index) => {
    const newParams = produce(filter, (draft) => {
      draft.splice(index, 1);
      if (draft.length === 0) {
        draft.splice(0, 0, {});
      }
    });
    setFilter(newParams);
  };

  /************************ OR절 ***********************/
  /**
   * Data 변경 시 DataBinding 처리 후 parameter 값을 변경해준다.
   *
   * @param {*} e
   * @param {*} param
   * @param {*} index
   */
  const onChangeDataBindingOr = (e, param, index, andIndex) => {
    let newParam = { ...param };
    //id를 setting해준다.
    newParam.id = StringUtils.substringAfter(e.target.value, ".");
    changeParamOr(e, newParam, index, andIndex);
  };

  const onChangeParamOr = (e, param, index, andIndex) => {
    changeParamOr(e, param, index, andIndex);
  };

  const changeParamOr = (e, param, index, andIndex) => {
    let isOrParams = false;
    const newOrParams = produce(filter[andIndex].or, (draft) => {
      draft[index] = { ...param, [e.target.id]: e.target.value };

      if (
        !StringUtils.isEmpty(draft[index].column) ||
        !StringUtils.isEmpty(draft[index].id) ||
        !StringUtils.isEmpty(draft[index].operator) ||
        !StringUtils.isEmpty(draft[index].default)
      ) {
        isOrParams = true;
      }
    });

    const newParams = produce(filter, (draft) => {
      draft[andIndex] = { ...filter[andIndex], or: newOrParams };

      if (isOrParams) {
        delete draft[andIndex].column;
        delete draft[andIndex].id;
        delete draft[andIndex].operator;
        delete draft[andIndex].default;
      }
    });

    setFilter(newParams);
    // onChangeSetRetItems(e, "params", newParams);
  };

  /**
   * onOrBtnClick: OR절 추가/삭제 버튼
   * @param {*} type
   * @param {*} e
   * @param {*} param
   * @param {*} index
   */
  const onOrBtnClick = (type, e, param, index, andIndex) => {
    //AND절 삭제
    if (type === "I") {
      const newOrParams = produce(filter[andIndex].or, (draft) => {
        draft.splice(index + 1, 0, {});
      });
      const newParams = produce(filter, (draft) => {
        draft[andIndex] = { ...filter[andIndex], or: newOrParams };
      });
      setFilter(newParams);
    }

    //OR절 삭제
    if (type === "D") {
      const newOrParams = produce(filter[andIndex].or, (draft) => {
        draft.splice(index, 1);
        if (draft.length === 0) {
          draft.splice(0, 0, {});
        }
      });
      const newParams = produce(filter, (draft) => {
        draft[andIndex] = { ...filter[andIndex], or: newOrParams };
      });
      setFilter(newParams);
    }
  };

  // closePopup: value값을 전달하고 popup 닫기
  const closePopup = (e) => {
    if (callbackFnc != null) {
      callbackFnc.call(this, entityId, entityNm, filter);
    }

    // Popup.textChange
    Popup.close();
  };
  const openDefaultHelp = (e) => {
    const options = {
      style: {
        content: {
          width: "40%",
        },
      },
    };
    Popup.open(
      <ComboEntityValueSettingGuide title="Combo Value Input Guide" />,
      options
    );
  };

  useEffect(() => {
    initPopup();
  }, []);

  return (
    <SelectEntityPopupStyle>
      <Modal>
        <Modal.Header title="Entity Setting" />
        <Modal.Body className="h-full">
          <Form.Group className="mb-5">
            <Form.Label>Entity</Form.Label>
            <Form.Select
              size="sm"
              id="column"
              onChange={onChangecomboEntityFilter}
              value={StringUtils.defaultString(entityId)}
            >
              <option value={""}>Select</option>
              {!ArrayUtils.isEmpty(entityList)
                ? entityList.map((option, optionIdx) => (
                    <option key={optionIdx} value={option.id}>
                      {option.text}
                    </option>
                  ))
                : ""}
            </Form.Select>
            <br />
            <Form.Label>WHERE</Form.Label>
            <Col>
              <Row className="mb-2">
                <Col xs={1}></Col>
                <Col>Column</Col>
                <Col>Condition</Col>
                <Col>Value</Col>
                <Col>
                  {!ArrayUtils.isEmpty(filter) &&
                  !ArrayUtils.isEmpty(filter[0].or) ? (
                    <React.Fragment>
                      <Button
                        onClick={(e) => onInsertClick(e, -1)}
                        size="sm"
                        className="mr-5"
                        variant={"outline-primary"}
                      >
                        +AND
                      </Button>
                      <Button
                        onClick={(e) => onOrInsertClick(e, -1)}
                        size="sm"
                        className="mr-5"
                        variant={"outline-primary"}
                      >
                        +OR
                      </Button>
                    </React.Fragment>
                  ) : (
                    ""
                  )}
                </Col>
              </Row>
              {!ArrayUtils.isEmpty(filter)
                ? filter.map((param, index) => {
                    if (ArrayUtils.isEmpty(param.or)) {
                      return (
                        <React.Fragment>
                          <Row
                            key={index}
                            className="pb-2"
                            style={{ alignItems: "center" }}
                          >
                            <Col xs={1}>
                              <span className={index === 0 ? "hide" : ""}>
                                AND
                              </span>
                            </Col>
                            <Col>
                              <Form.Select
                                size="sm"
                                id="column"
                                onChange={(e) =>
                                  onChangeDataBinding(e, param, index)
                                }
                                value={StringUtils.defaultString(param.column)}
                              >
                                <option value={""}>Select</option>
                                {!ArrayUtils.isEmpty(dataBindingList)
                                  ? dataBindingList.map((option, optionIdx) => (
                                      <option key={optionIdx} value={option.id}>
                                        {option.text}
                                      </option>
                                    ))
                                  : ""}
                              </Form.Select>
                            </Col>
                            <Col>
                              <Form.Select
                                size="sm"
                                id="operator"
                                onChange={(e) => onChangeParam(e, param, index)}
                                value={StringUtils.defaultString(
                                  param.operator
                                )}
                              >
                                <option value={""}>Select</option>
                                {!ArrayUtils.isEmpty(operatorList)
                                  ? operatorList.map((option, optionIdx) => (
                                      <option
                                        key={optionIdx}
                                        value={option.codeDtlCd}
                                      >
                                        {option.codeDtlNm}
                                      </option>
                                    ))
                                  : ""}
                              </Form.Select>
                            </Col>
                            <Col>
                              <UInputPopup
                                id="default"
                                fieldType="string"
                                defaultValue={StringUtils.defaultString(
                                  param.default
                                )}
                                onChange={(e) => onChangeParam(e, param, index)}
                                onClick={(e) =>
                                  openDefaultPopup("and", e, param, index)
                                }
                              />
                            </Col>
                            <Col>
                              <Button
                                onClick={(e) => onInsertClick(e, index)}
                                size="sm"
                                className="mr-5"
                                variant={"outline-primary"}
                              >
                                +AND
                              </Button>
                              <Button
                                onClick={(e) => onOrInsertClick(e, index)}
                                size="sm"
                                className="mr-5"
                                variant={"outline-primary"}
                              >
                                +OR
                              </Button>
                              <Button
                                onClick={(e) => onDeleteClick(e, index)}
                                size="sm"
                                variant={"outline-danger"}
                              >
                                <Fa.FaTrash size="16" />
                              </Button>
                            </Col>
                          </Row>
                        </React.Fragment>
                      );
                    } else {
                      return (
                        <React.Fragment>
                          <PropertyFieldSet>
                            <Row key={index}>
                              <Col xs={1}>AND</Col>
                              <Col>
                                <fieldset className="fieldset-border p-2 mb-2">
                                  <Form.Label>
                                    OR
                                    <Button
                                      onClick={(e) => onDeleteClick(e, index)}
                                      size="sm"
                                      variant={"outline-danger"}
                                      className="or-btn-minus"
                                    >
                                      <Fa.FaMinus size="10" />
                                    </Button>
                                  </Form.Label>
                                  <PropertyFieldSetContents>
                                    <Col>
                                      <Row className="mb-2">
                                        <Col>Column</Col>
                                        <Col>Condition</Col>
                                        <Col>Value</Col>
                                        <Col></Col>
                                      </Row>
                                      {param.or.map((orParam, orIndex) => {
                                        return (
                                          <Row
                                            key={orIndex}
                                            className="pb-2"
                                            style={{ alignItems: "center" }}
                                          >
                                            <Col>
                                              <Form.Select
                                                size="sm"
                                                id="column"
                                                onChange={(e) =>
                                                  onChangeDataBindingOr(
                                                    e,
                                                    orParam,
                                                    orIndex,
                                                    index
                                                  )
                                                }
                                                value={StringUtils.defaultString(
                                                  orParam.column
                                                )}
                                              >
                                                <option value={""}>
                                                  Select
                                                </option>
                                                {!ArrayUtils.isEmpty(
                                                  dataBindingList
                                                )
                                                  ? dataBindingList.map(
                                                      (option, optionIdx) => (
                                                        <option
                                                          key={optionIdx}
                                                          value={option.id}
                                                        >
                                                          {option.text}
                                                        </option>
                                                      )
                                                    )
                                                  : ""}
                                              </Form.Select>
                                            </Col>
                                            <Col>
                                              <Form.Select
                                                size="sm"
                                                id="operator"
                                                onChange={(e) =>
                                                  onChangeParamOr(
                                                    e,
                                                    orParam,
                                                    orIndex,
                                                    index
                                                  )
                                                }
                                                value={StringUtils.defaultString(
                                                  orParam.operator
                                                )}
                                              >
                                                <option value={""}>
                                                  Select
                                                </option>
                                                {!ArrayUtils.isEmpty(
                                                  operatorList
                                                )
                                                  ? operatorList.map(
                                                      (option, optionIdx) => (
                                                        <option
                                                          key={optionIdx}
                                                          value={
                                                            option.codeDtlCd
                                                          }
                                                        >
                                                          {option.codeDtlNm}
                                                        </option>
                                                      )
                                                    )
                                                  : ""}
                                              </Form.Select>
                                            </Col>
                                            <Col>
                                              <UInputPopup
                                                id="default"
                                                fieldType="string"
                                                defaultValue={StringUtils.defaultString(
                                                  orParam.default
                                                )}
                                                onChange={(e) =>
                                                  onChangeParamOr(
                                                    e,
                                                    orParam,
                                                    orIndex,
                                                    index
                                                  )
                                                }
                                                onClick={(e) =>
                                                  openDefaultPopup(
                                                    "or",
                                                    e,
                                                    orParam,
                                                    index,
                                                    orIndex
                                                  )
                                                }
                                              />
                                            </Col>
                                            <Col>
                                              <Button
                                                onClick={(e) =>
                                                  onOrBtnClick(
                                                    "I",
                                                    e,
                                                    orParam,
                                                    orIndex,
                                                    index
                                                  )
                                                }
                                                size="sm"
                                                className="mr-5"
                                                variant={"outline-primary"}
                                              >
                                                <MdLibraryAdd size="16" />
                                              </Button>
                                              <Button
                                                onClick={(e) =>
                                                  onOrBtnClick(
                                                    "D",
                                                    e,
                                                    orParam,
                                                    orIndex,
                                                    index
                                                  )
                                                }
                                                size="sm"
                                                variant={"outline-danger"}
                                              >
                                                <Fa.FaTrash size="16" />
                                              </Button>
                                            </Col>
                                          </Row>
                                        );
                                      })}
                                    </Col>
                                  </PropertyFieldSetContents>
                                </fieldset>
                              </Col>
                            </Row>
                          </PropertyFieldSet>
                        </React.Fragment>
                      );
                    }
                  })
                : ""}
            </Col>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer
          className="fixed"
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Modal.Footer.Button
            onClick={openDefaultHelp}
            variant="success"
            side="left"
          >
            <BsQuestionLg />
            "Value" Input Guide
          </Modal.Footer.Button>
          <Modal.Footer.Button
            type="button"
            className="btn btn-primary"
            onClick={closePopup}
          >
            <BsPencilSquare />
            &nbsp;Confirm
          </Modal.Footer.Button>
        </Modal.Footer>
      </Modal>
    </SelectEntityPopupStyle>
  );
};

export default SelectEntityPopup;
