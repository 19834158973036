import {
  DaafMessage,
  StringUtils,
} from "@alpha/com.bizentro.daaf.front.framework";
import ColorPicker from "components/builder/ui/ColorPicker";
import ObjectUtils from "components/common/utils/ObjectUtils";
import { WorkflowContext } from "page/workflow";
import { useEffect } from "react";
import { memo, useContext, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";

const BundleDefaultColor = {
  groupColor: "#ff4d00",
  textColor: "#FFFFFF",
};

const WorkflowBundlingTab = memo(({ callback, ...props }) => {
  const {
    bundle: {
      bundleEndNode,
      bundleStartNode,
      setBundlingMode,
      setBundleStartNode,
      setBundleEndNode,
      setBundleNodeList,
      selectedBundle,
      setSelectedBundle,
    },
  } = useContext(WorkflowContext);
  const [groupNm, setGroupNm] = useState("");
  const [color, setColor] = useState(BundleDefaultColor);

  useEffect(() => {
    if (ObjectUtils.isEmpty(selectedBundle)) {
      setGroupNm("");
      setColor(BundleDefaultColor);
    } else {
      setGroupNm(selectedBundle.propertyValue.groupNm);
      setColor({
        groupColor: selectedBundle.propertyValue.groupColor,
        textColor: selectedBundle.propertyValue.textColor,
      });
    }
  }, [selectedBundle]);

  const onClickBundle = () => {
    if (StringUtils.isEmpty(groupNm)) {
      return DaafMessage.alert("Enter the Group name", "warning");
    }

    if (callback) {
      setSelectedBundle({});
      callback({ color, groupNm });
    }
  };

  //번들 컬러 선택
  const onChangeStyle = (e) => {
    const _color = { ...color };
    _color[e.target.id] = e.target.value;
    setColor(_color);
  };

  //그룹 초기화
  const onClickBundleInit = (e) => {
    setBundleStartNode({});
    setBundleEndNode({});
    setBundleNodeList({});
    setGroupNm("");
  };

  return (
    <div className="workflow-bundle-tab-wrapper">
      <div className="modal-header">
        <div className="modal-title">Process Group Setting</div>
        <button className="btn-close" onClick={(e) => setBundlingMode(false)} />
      </div>
      <Form.Group className="mt-3 mb-3">
        {ObjectUtils.isEmpty(selectedBundle) && (
          <Row>
            <Col xs={1} className="hc vc">
              From
            </Col>
            <Col xs={5}>
              <Form.Control
                value={bundleStartNode?.data?.process.propertyValue.processNm}
                size="sm"
                readOnly
                onChange={(e) => {}}
                placeholder="Choose Start process node of group "
              />
            </Col>
            <Col xs={1} className="hc vc">
              To
            </Col>
            <Col xs={5}>
              {" "}
              <Form.Control
                value={bundleEndNode?.data?.process.propertyValue.processNm}
                size="sm"
                readOnly
                onChange={(e) => {}}
                placeholder={
                  bundleStartNode?.data?.process.propertyValue.processNm
                    ? "Choose End Process Node of group "
                    : ""
                }
              />
            </Col>
          </Row>
        )}
      </Form.Group>
      <Form.Group className="mb-3">
        <Row>
          <Col>
            <Form.Label className="required">Group Name</Form.Label>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col>
            <Form.Control
              value={groupNm}
              size="sm"
              placeholder="input group name..."
              onChange={(e) => setGroupNm(e.target.value)}
              onKeyDown={(e) => {
                if (StringUtils.equalsIgnoreCase(e.key, "Enter")) {
                  onClickBundle();
                }
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Label>Group Color</Form.Label>
          </Col>
          <Col>
            <Form.Label>Text Color</Form.Label>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col>
            <ColorPicker
              id="groupColor"
              defaultValue={color.groupColor}
              onBlur={onChangeStyle}
            />
          </Col>
          <Col>
            <ColorPicker
              id="textColor"
              defaultValue={color.textColor}
              onBlur={onChangeStyle}
            />
          </Col>
        </Row>
      </Form.Group>
      <div className="modal-footer">
        {ObjectUtils.isEmpty(selectedBundle) && (
          <Button
            onClick={onClickBundleInit}
            size="sm"
            variant="outline-secondary"
          >
            Init group choice
          </Button>
        )}

        <Button onClick={onClickBundle} size="sm">
          Confirm
        </Button>

        <Button
          onClick={(e) => setBundlingMode(false)}
          size="sm"
          variant="outline-secondary"
        >
          Close
        </Button>
      </div>
    </div>
  );
});

export default WorkflowBundlingTab;
