import React, { useState, useEffect } from "react";
import { Tabs, Tab } from "react-bootstrap";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useDispatch, useSelector } from "react-redux";
import { FaThList, FaCoins } from "react-icons/fa";

// import PropertiesTab from "components/builder/ui/PropertiesTab";
import MobilePropertiesTab from "components/builder/mobile/MobilePropertiesTab";
import ServiceComponentsTab from "components/builder/ui/ServiceComponentsTab";
import MobileComponentsTab from "components/builder/mobile/MobileComponentsTab";
import MobileEditor from "components/builder/mobile/editor/MobileEditor";
import UICustomDragLayer from "components/builder/ui/editor/UICustomDragLayer";
import { ObjectUtils, StringUtils } from "components/common/utils/CommonUtils";
import BuilderRightMenuPanel from "components/builder/mobile/BuilderRightMenuPanel";
import BuilderLeftMenuPanel from "components/builder/mobile/BuilderLeftMenuPanel";
import "css/builder/main.css";
import "css/builder/mobile.css";
import "css/builder/runtime.css";
import "css/builder/settings.css";
import { Enums } from "components/builder/BuilderEnum";
import { useNavigate } from "react-router-dom";
import MobileTree from "components/builder/mobile/tree/MobileTree";
import { setIsMobileEditor } from "components/builder/mobile/reducers/MobileAction"

const MobileBuilderMain = (props) => {
  const dispatch = useDispatch();
  const output = useSelector((state) => state.outputUI.output);
  const { isSidebarCollapsed } = useSelector((state) => state.menu);

  const [activedTabKey, setActivedTabKey] = useState("BasicComponents");

  const navigate = useNavigate();

  const handleTabSelect = (key) => {
    if (activedTabKey !== key) setActivedTabKey(key);
  };

  useEffect(() => {
    handleTabSelect("Properties");
    dispatch(setIsMobileEditor(true));
  }, []);

  // console.log("[Check re-rendering] ", "MobileBuilderMain has been re-rendered!!!");
  return (
    <React.Fragment>
      <DndProvider backend={HTML5Backend}>
        {StringUtils.equalsIgnoreCase(
          window.location.pathname,
          Enums.BuilderPath.MOBILE.MAIN + "/" + Enums.BuilderPath.MOBILE.EDITOR
        ) &&
          output.page && <MobileTree />}

        <BuilderLeftMenuPanel>
          <MobileComponentsTab />  
        </BuilderLeftMenuPanel>

        <div
          id="editPanel"
          className={`mobile-edit-panel ${isSidebarCollapsed ? "isCollapse" : ""}`}
        >
          {!ObjectUtils.isEmpty(output) ? <MobileEditor tabType="E" /> : ""}
        </div>
        <UICustomDragLayer />
        <BuilderRightMenuPanel>
          <Tabs
            fill
            activeKey={activedTabKey}
            id="controlled-tab"
            onSelect={handleTabSelect}
          >
            <Tab
              eventKey="Properties"
              title={
                <span>
                  <FaThList size="14" />
                  <span className="tab-name">Properties</span>
                </span>
              }
            >
              <MobilePropertiesTab navigate={navigate} />
            </Tab>
            <Tab
              eventKey="ServiceComponents"
              title={
                <span>
                  <FaCoins size="14" />
                  <span className="tab-name">Service</span>
                </span>
              }
            >
              <ServiceComponentsTab />
            </Tab>
          </Tabs>
        </BuilderRightMenuPanel>
      </DndProvider>
    </React.Fragment>
  );
};
export default MobileBuilderMain;