import {
  DaafModal,
  DaafModalTemplate,
  DaafPopup,
} from "@alpha/com.bizentro.daaf.front.framework";
import USelectbox from "components/common/element/USelectbox";
import StringUtils from "components/common/utils/StringUtils";
import produce from "immer";
import { useState } from "react";
import { Button, Col, Form, InputGroup, Row } from "react-bootstrap";
import ConnectTypeDescPopup from "../ConnectTypeDescPopup";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { Tooltip } from "@mui/material";
import { useEffect } from "react";
import CodeService from "services/common/CodeService";

const AppEnvAddPopup = ({ appEnv: _appEnv, ...props }) => {
  const [appEnv, setAppEnv] = useState({
    envName: "",
    envUrl: "",
    host: "",
    protocol: "HTTP",
    runtimeHost: "",
    runtimeProtocol: "HTTP",
    connectionType: "proxy",
    envType: "",
    envAccessToken: "",
    tenantId: "",
    coCd: "",
    dbType: "",
    systemUserId: "",
  });

  const [appEnvTypeList, setAppEnvTypeList] = useState([]);

  useEffect(() => {
    if (_appEnv) {
      setAppEnv(_appEnv);
    }
    CodeService.getCodeCombo({ codeMstCd: "Z0053" }, (res) => {
      setAppEnvTypeList(res.data);
    });
  }, []);

  const onChangeValue = (e) => {
    setAppEnv(
      produce(appEnv, (draft) => {
        draft[e.target.id] = e.target.value;
      })
    );
  };

  const onClickConfirm = () => {
    if (props.callback) {
      props.callback(appEnv);
    }
  };

  return (
    <DaafModal>
      <DaafModal.Header title="APP 환경 등록" />
      <DaafModal.Body>
        <DaafModalTemplate>
          <Row>
            <Form.Group as={Col} xs={12} className="mb-3">
              <Form.Label className="required">환경 이름</Form.Label>
              <Form.Control
                size="sm"
                id={"envName"}
                value={appEnv.envName}
                onChange={onChangeValue}
              />
            </Form.Group>
            <Form.Group as={Col} xs={12} className="mb-3">
              <Form.Label className="required">접근 토큰</Form.Label>
              <Form.Control
                size="sm"
                id={"envAccessToken"}
                value={appEnv.envAccessToken}
                onChange={onChangeValue}
              />
            </Form.Group>
            <Form.Group as={Col} xs={6} className="mb-3">
              <Form.Label className="required">테넌트 ID</Form.Label>
              <Form.Control
                size="sm"
                id={"tenantId"}
                value={appEnv.tenantId}
                onChange={onChangeValue}
              />
            </Form.Group>
            <Form.Group as={Col} xs={6} className="mb-3">
              <Form.Label className="required">회사 코드</Form.Label>
              <Form.Control
                size="sm"
                id={"coCd"}
                value={appEnv.coCd}
                onChange={onChangeValue}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label className="required">환경 유형</Form.Label>
              <USelectbox
                id="envType"
                onChange={onChangeValue}
                defaultValue={appEnv.envType}
                items={appEnvTypeList}
                options={{
                  matchCd: "id",
                  matchNm: "text",
                }}
              />
            </Form.Group>
            <Row>
              <Col xs={3}>
                <Form.Label className="required">서버 URL</Form.Label>
              </Col>
              <Col xs={9} />
            </Row>
            <Row className="mb-3">
              <Col xs={2}>
                <USelectbox
                  id="protocol"
                  onChange={onChangeValue}
                  defaultValue={StringUtils.defaultString(
                    appEnv.protocol,
                    "HTTP"
                  )}
                  items={[
                    { id: "HTTP", text: "HTTP" },
                    { id: "HTTPS", text: "HTTPS" },
                  ]}
                  options={{
                    matchCd: "id",
                    matchNm: "text",
                  }}
                  size="m"
                />
              </Col>
              <Col xs={6}>
                <input
                  type="text"
                  id="host"
                  className="form-control"
                  onChange={onChangeValue}
                  value={appEnv.host}
                />
              </Col>
              <Col xs={4}>
                <InputGroup>
                  <USelectbox
                    id="connectionType"
                    onChange={onChangeValue}
                    defaultValue={StringUtils.defaultString(
                      appEnv.connectionType,
                      "proxy"
                    )}
                    items={[
                      { id: "proxy", text: "DAAF Proxy 배포" },
                      { id: "direct", text: "직접 연결 배포" },
                    ]}
                    options={{
                      matchCd: "id",
                      matchNm: "text",
                    }}
                    size="m"
                  />

                  <Button
                    size={"sm"}
                    variant="outline-secondary"
                    onClick={(e) => {
                      DaafPopup.open(<ConnectTypeDescPopup />, {
                        style: { content: { width: "550px" } },
                      });
                    }}
                  >
                    <AiOutlineQuestionCircle />
                  </Button>
                </InputGroup>
              </Col>
            </Row>
            <Row>
              <Col xs={3}>
                <Form.Label>Runtime 서버</Form.Label>
              </Col>
              <Col xs={9}></Col>
            </Row>
            <Row className="mb-3">
              <Col xs={2}>
                <USelectbox
                  id="runtimeProtocol"
                  onChange={onChangeValue}
                  defaultValue={StringUtils.defaultString(
                    appEnv.runtimeProtocol,
                    "HTTP"
                  )}
                  items={[
                    { id: "HTTP", text: "HTTP" },
                    { id: "HTTPS", text: "HTTPS" },
                  ]}
                  options={{
                    matchCd: "id",
                    matchNm: "text",
                  }}
                  size="m"
                />
              </Col>
              <Col xs={10}>
                <InputGroup>
                  <Form.Control
                    type="text"
                    id="runtimeHost"
                    className="form-control"
                    onChange={onChangeValue}
                    value={appEnv.runtimeHost}
                  />
                  <Tooltip
                    title={
                      <>
                        Workflow Debugging 기능을 사용하기 위해서는 HTTPS
                        Runtime URL을 입력 하셔야 합니다.
                      </>
                    }
                    placement="top"
                  >
                    <Button size={"sm"} variant="outline-secondary">
                      <AiOutlineQuestionCircle />
                    </Button>
                  </Tooltip>
                </InputGroup>
              </Col>
            </Row>
            <Form.Group className="mb-3">
              <Form.Label className="required">접속 유저 ID</Form.Label>
              <input
                type="text"
                id="systemUserId"
                className="form-control"
                onChange={onChangeValue}
                value={appEnv.systemUserId}
              />
            </Form.Group>
          </Row>
        </DaafModalTemplate>
      </DaafModal.Body>
      <DaafModal.Footer>
        <DaafModal.Footer.Button onClick={onClickConfirm}>
          확인
        </DaafModal.Footer.Button>
      </DaafModal.Footer>
    </DaafModal>
  );
};

export default AppEnvAddPopup;
