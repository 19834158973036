import { Accordion, Button, Form, InputGroup, Row } from "react-bootstrap";
import React, { Fragment } from "react";
import * as ReactDOMServer from "react-dom/server";

import LayoutComponent from "components/builder/ui/uiComponents/layout/LayoutComponent";
import UITemplateHelper from "components/builder/ui/editor/helper/UITemplateHelper";
import {
  PropertiesHeader,
  PropertyLable,
  PropertyValue,
} from "components/builder/ui/uiComponents/UIComponentStyle";
import {
  StringUtils,
  JsonUtils,
  ObjectUtils,
  ArrayUtils,
  NumberUtils,
} from "components/common/utils/CommonUtils";
import UIComponentSection from "components/builder/ui/editor/UIComponentSection";
import { AppContext } from "components/common/AppContextProvider";
import USelectbox from "components/common/element/USelectbox";
import ColorPicker from "components/builder/ui/ColorPicker";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import UInputPopup from "components/common/element/UInputPopup";
import UTextarea from "components/common/element/UTextarea";
import Popup from "components/common/Popup";
import IconPopup from "page/popup/IconPopup";
import { ComponentSavePopupButton } from "page/popup/ComponentSavePopup";
import * as Fa from "react-icons/fa";
import UElementList from "components/common/element/UElementList";
import WidgetToolbarBtnPopup from "page/popup/WidgetToolbarBtnPopup";
import produce from "immer";
import { FaBook } from "react-icons/fa";
import TemplateSyntaxGuide from "page/popup/TemplateSyntaxGuide";
import UPopover from "components/common/element/UPopover";
import { Enums } from "components/builder/BuilderEnum";
import Message from "components/common/Message";

class WidgetContainer extends LayoutComponent {
  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      canvasWidth: 0,
    };
  }
  static contextType = AppContext;

  WIDGET_TOOLBAR_BUTTONS = [
    {
      id: "collapse",
      desc: "Collapse",
      icon: "fa-minus",
      type: "S",
    },
    // {
    //   id: "config",
    //   desc: "설정",
    //   icon: "fa-wrench",
    //   type: "S",
    // },
    {
      id: "refresh",
      desc: "Refresh",
      icon: "fa-redo",
      type: "S",
    },
    {
      id: "close",
      desc: "Close",
      icon: "fa-times",
      type: "S",
    },
  ];

  onChangeWidth = (e) => {
    this.onChange(e);
  };

  /**
   * 해당 Component의 Title
   * @param {*} child
   * @returns
   */
  renderComponentTitle = (child) => {
    return (
      <PropertiesHeader isMobile={this.props.mobile.isMobileEditor}>
        <div>
          {"Widget Components > "}
          {child}
        </div>
        <ComponentSavePopupButton
          getComponentCodeFromOutput={this.props.fn.getComponentCodeFromOutput}
          componentInfo={this.props.componentInfo}
        />
      </PropertiesHeader>
    );
  };

  getTemplateHtml = (property) => {
    let template = property.bodyTemplate;
    if (
      StringUtils.indexOf(template, "{icon}") > -1 &&
      !ObjectUtils.isEmpty(property.bodyIcon)
    ) {
      let IconTag = Fa[StringUtils.convertKebabToPascal(property.bodyIcon.id)];
      if (IconTag) {
        //size,color,title
        let iconSvg = ReactDOMServer.renderToString(
          <IconTag {...property.bodyIcon.style} />
        );
        template = template.replaceAll("{icon}", iconSvg);
      }
    }
    return template;
  };
  /**
   * Icon Popup Open
   * @param {*} e
   */
  openPopupIcon = (e) => {
    const popOptions = {
      effect: Popup.ScaleUp, //Effect.SlideFromTop(default)를 Effect.ScaleUp 로 변경
      style: {
        content: {
          height: "80%",
        },
      },
    };

    Popup.open(
      <IconPopup
        title="Search Icon"
        defaultValue={JsonUtils.defaultString(
          this.state.propertyValue.bodyIcon,
          "id"
        )}
        callbackFnc={(icon) => {
          this.onChangeBodyIcon({ target: { value: icon } });
          let el = document.getElementById("bodyIconId");
          el.value = icon;
          if (el) {
            el.value = icon;
            el.dispatchEvent(new Event("input", { target: el, bubbles: true }));
          }
        }}
      />,
      popOptions
    );
  };

  onChangeBodyIcon = (e) => {
    let newBodyIcon = null;

    if (!StringUtils.isEmpty(e.target.value)) {
      newBodyIcon = {};
      newBodyIcon.id = e.target.value;

      let IconTag = Fa[StringUtils.convertKebabToPascal(newBodyIcon.id)];
      if (IconTag) {
        const bodyIconStyle = JsonUtils.defaultString(
          this.state.propertyValue.bodyIcon,
          "style",
          {}
        );
        //size,color,title
        newBodyIcon.svg = ReactDOMServer.renderToString(
          <IconTag {...bodyIconStyle} />
        );
      }
    }
    this.onChangePropertyValues({ bodyIcon: newBodyIcon });
  };

  getWidgetButtons = () => {
    const newWiedgetButtons = produce(this.WIDGET_TOOLBAR_BUTTONS, (draft) => {
      let currButtons = this.state.propertyValue.buttons;
      draft.map((item, index) => {
        if (
          !ArrayUtils.isEmpty(currButtons) &&
          ArrayUtils.getIndex(currButtons, "id", item.id) > -1
        ) {
          draft[index].useYn = true;
        } else {
          draft[index].useYn = false;
        }
      });
    });
    return newWiedgetButtons;
  };

  openPopupSetButton = (e) => {
    const item = JsonUtils.parseJson(e.currentTarget.dataset.item);

    let buttons = this.state.propertyValue.buttons;
    if (!buttons)
      return Message.alert(
        "Button activation is required.",
        Enums.MessageType.WARN
      );
    const buttonIndex = ArrayUtils.getIndex(buttons, "id", item.id);
    const options = {
      keyDownEvent: false,
      effect: Popup.ScaleUp, //Effect.SlideFromTop(default)를 Effect.ScaleUp 로 변경
      style: {
        content: {
          width: "30%",
          height: "calc(auto + 300px)", //"680px",
        },
      },
    };

    let eventWorkspace = {};
    if (buttons[buttonIndex].eventWorkspace) {
      eventWorkspace = buttons[buttonIndex].eventWorkspace || {};
    }
    const originalOutput = buttons[buttonIndex]?.userFunction || "";

    /**
     * userFunction이 없기 때문에 팝업 열때 오류 발생
     * 강제 설정
     * */

    const data = {
      userFunction: "",
      ...buttons[buttonIndex],
    };

    const getEventInfo = () => {
      return {
        eventWorkspace: eventWorkspace?.userFunction,
        compId: this.props.componentInfo.compId,
        eventIndex: buttonIndex,
        eventCd: "widget.button.userevent",
        eventType: Enums.EventHandlerEventType.USR_EVENT_FUNCTION,
        builderEventType: "userFunction",
        targetType: "all",
        originalOutput: originalOutput,
        programType: this.props.output.page.propertyValue?.programType || "M",
      };
    };

    Popup.open(
      <WidgetToolbarBtnPopup
        title="Widget Toolbar Button Detail Settings"
        data={data}
        componentInfo={this.props.componentInfo}
        callbackFnc={(backItems) => {
          let btns = [...buttons];
          if (buttonIndex >= 0) {
            if (StringUtils.isEmpty(backItems.userFunction)) {
              delete btns[buttonIndex].userFunction;
            } else if (
              !ObjectUtils.isEmpty(eventWorkspace) &&
              originalOutput !== backItems.userFunction
            ) {
              //함수가 달라지는 경우에는 이벤트 빌더 삭제
              this.showEventChangeConfirmMessage(() => {
                btns = produce(btns, (draft) => {
                  draft[buttonIndex].userFunction = backItems.userFunction;
                  delete draft[buttonIndex].eventWorkspace;
                });
                this.onChangePropertyValue("buttons", btns);
              });
            } else btns[buttonIndex].userFunction = backItems.userFunction;
            this.onChangePropertyValue("buttons", btns);
          }
        }}
        eventWorkspace={eventWorkspace?.userFunction}
        getEventInfo={getEventInfo}
        setEventBuilder={() =>
          this.props.fn.onClickEventBuilder(getEventInfo())
        }
      />,
      options
    );
  };

  openPopupSTemplateHelp = (e) => {
    const options = {
      style: {
        content: {
          width: "40%",
          height: "calc(auto + 300px)", //"680px",
        },
      },
    };
    Popup.open(<TemplateSyntaxGuide title="Template Syntax" />, options);
  };

  /**
   * Properties tab panel을 Redering
   * @returns
   */
  renderPropertiesPanel = () => {
    return (
      <React.Fragment>
        {/* Title */}
        {this.renderComponentTitle("Widget Box")}
        <Accordion defaultActiveKey={[0, 1, 2, 3]} alwaysOpen>
          <Accordion.Item eventKey={0}>
            <Accordion.Header>Basic Info</Accordion.Header>
            <Accordion.Body>
              <PropertyLable>ID</PropertyLable>
              <PropertyValue>
                <input
                  type="text"
                  id="id"
                  defaultValue={StringUtils.defaultString(
                    this.state.propertyValue.id
                  )}
                  className="form-control form-control-sm"
                  onBlur={this.onChange}
                />
              </PropertyValue>
              <PropertyLable requried="true">Widget Name</PropertyLable>
              <PropertyValue>
                <input
                  type="text"
                  id="widgetName"
                  defaultValue={StringUtils.defaultString(
                    this.state.propertyValue.widgetName
                  )}
                  className="form-control form-control-sm"
                  onBlur={this.onChange}
                />
              </PropertyValue>
              <PropertyLable>Widget Description</PropertyLable>
              <PropertyValue>
                <UInputPopup
                  id="widgetDesc"
                  defaultValue={StringUtils.defaultString(
                    this.state.propertyValue.widgetDesc
                  )}
                  onClick={(e) => {
                    this.openExtendPopup(e, "widget Description");
                  }}
                  onBlur={this.onChange}
                />
              </PropertyValue>
              <PropertyLable>Widget Width</PropertyLable>
              <PropertyValue>
                over 1200px (Desktop or laptops)
                <USelectbox
                  type="common"
                  mstCd="Z0015"
                  id="widthLaptop"
                  defaultValue={StringUtils.defaultString(
                    this.state.propertyValue.widthLaptop
                  )}
                  onChange={this.onChangeWidth}
                />
              </PropertyValue>
              <PropertyLable />
              <PropertyValue>
                over 992px (Small laptops)
                <USelectbox
                  type="common"
                  mstCd="Z0015"
                  id="widthSmall"
                  defaultValue={StringUtils.defaultString(
                    this.state.propertyValue.widthSmall
                  )}
                  onChange={this.onChange}
                />
              </PropertyValue>
              <PropertyLable />
              <PropertyValue>
                over 768px (Tablets)
                <USelectbox
                  type="common"
                  mstCd="Z0015"
                  id="widthTablet"
                  defaultValue={StringUtils.defaultString(
                    this.state.propertyValue.widthTablet
                  )}
                  onChange={this.onChange}
                />
              </PropertyValue>
              <PropertyLable />
              <PropertyValue>
                below 768px (Phones)
                <USelectbox
                  type="common"
                  mstCd="Z0015"
                  id="widthPhone"
                  defaultValue={StringUtils.defaultString(
                    this.state.propertyValue.widthPhone
                  )}
                  onChange={this.onChange}
                />
              </PropertyValue>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey={1}>
            <Accordion.Header>
              Template Data Mapping and Pre/Post-processing
            </Accordion.Header>
            <Accordion.Body>
              <PropertyLable requried="true">Data Load Type</PropertyLable>
              <PropertyValue>
                <USelectbox
                  id="searchTp"
                  onChange={this.onChange}
                  defaultValue={StringUtils.defaultString(
                    this.state.propertyValue.searchTp,
                    "N"
                  )}
                  items={[
                    { id: "N", text: "Do not Load" },
                    { id: "ENTITY", text: "Data Model Entity" },
                    { id: "WORKFLOW", text: "Workflow" },
                    { id: "URL", text: "Enter URL" },
                  ]}
                  options={{ matchCd: "id", matchNm: "text" }}
                />
              </PropertyValue>

              {this.state.propertyValue.searchTp === "URL" ? (
                <React.Fragment>
                  <PropertyLable requried="true">URL</PropertyLable>
                  <PropertyValue>
                    <input
                      type="text"
                      id="url"
                      defaultValue={StringUtils.defaultString(
                        this.state.propertyValue.url
                      )}
                      onBlur={this.onChange}
                      className="form-control form-control-sm"
                    />
                  </PropertyValue>
                </React.Fragment>
              ) : this.state.propertyValue.searchTp === "ENTITY" ? (
                <React.Fragment>
                  <PropertyLable requried="true">Entity</PropertyLable>
                  <PropertyValue requried="true">
                    <USelectbox
                      type="search"
                      id="entityId"
                      defaultValue={StringUtils.defaultString(
                        this.state.propertyValue.entityId
                      )}
                      onChange={this.onChange}
                      url="/datamodel/getDataModelEntityList"
                      params={{
                        dataModelId: this.getDataModel(),
                      }}
                      options={{
                        matchId: "id",
                        matchNm: "text",
                      }}
                    />
                  </PropertyValue>
                </React.Fragment>
              ) : this.state.propertyValue.searchTp === "WORKFLOW" ? (
                <React.Fragment>
                  <PropertyLable requried="true">Workflow</PropertyLable>
                  <PropertyValue requried="true">
                    <InputGroup>
                      <input
                        type="text"
                        id="serviceName"
                        value={StringUtils.defaultString(
                          this.state.propertyValue.serviceName
                        )}
                        className="form-control form-control-sm"
                        onChange={() => {}}
                        onClick={this.onWorkflowSettingClick}
                        readOnly
                        aria-readonly
                        placeholder="Workflow Name"
                      />
                      <Button
                        variant="outline-secondary"
                        size="sm"
                        className={
                          this.props.mobile.isMobileEditor
                            ? "mobile-font-color"
                            : "light-font-color"
                        }
                        onClick={this.onWorkflowSettingClick}
                      >
                        select
                      </Button>
                      {this.state.propertyValue.serviceUid && (
                        <Button
                          variant="outline-secondary"
                          size="sm"
                          className={
                            this.props.mobile.isMobileEditor
                              ? "mobile-font-color"
                              : "light-font-color"
                          }
                          onClick={this.onWorkflowDetailClick}
                        >
                          details
                        </Button>
                      )}
                    </InputGroup>
                  </PropertyValue>
                </React.Fragment>
              ) : (
                ""
              )}
              <PropertyLable>Limit Data Count</PropertyLable>
              <PropertyValue>
                <InputGroup size="sm">
                  <BootstrapSwitchButton
                    id="dataLimit"
                    checked={this.state.propertyValue.dataLimit}
                    size="sm"
                    width={100}
                    onstyle="primary"
                    offstyle="dark"
                    onlabel="LIMIT"
                    offlabel="UNLIMIT"
                    onChange={(checked) => {
                      this.onChangePropertyValue("dataLimit", checked);
                    }}
                  />
                  {this.state.propertyValue.dataLimit === true ? (
                    <Fragment>
                      <InputGroup.Text style={{ marginLeft: "10px" }}>
                        Max Data
                      </InputGroup.Text>
                      <input
                        type="number"
                        id="dataLmitCnt"
                        defaultValue={this.state.propertyValue.dataLmitCnt}
                        onBlur={this.onChange}
                        className="form-control form-control-sm"
                      />
                    </Fragment>
                  ) : (
                    ""
                  )}
                </InputGroup>
              </PropertyValue>
              <PropertyLable style={{ width: "70%" }}>
                1.Pre-processing before Execution
              </PropertyLable>
              <PropertyValue style={{ width: "30%" }}>
                <InputGroup>
                  {this.renderEventTextArea(
                    "beforeRender",
                    "Pre-processing before Template Execution",
                    {
                      jsonValidation: "javascript",
                      rows: 1,
                      eventCd: "widget.template",
                      eventType: "before",
                    }
                  )}
                  <UPopover
                    title="Description of Pre-processing before Template Execution"
                    style={{ minWidth: "47px" }}
                    isMobileEditor={this.props.mobile.isMobileEditor}
                  >
                    <ul>
                      <li>
                        <font color="red">
                          Function that executes before rendering Template.
                        </font>
                      </li>
                      <li>
                        <strong>▣ Usage Example </strong>
                      </li>
                      <li>
                        1. To change the Template(change Option) based on the
                        conditions
                      </li>
                      <li>2. To show/hide Template based on the conditions</li>
                      <li>
                        3. To load Template data directly or to set the fixed
                        data based on conditions.
                      </li>
                    </ul>
                  </UPopover>
                </InputGroup>
              </PropertyValue>
              <PropertyLable style={{ width: "70%" }}>
                2.Pre-processing before Data Load
              </PropertyLable>
              <PropertyValue style={{ width: "30%" }}>
                <InputGroup>
                  {this.renderEventTextArea(
                    "beforeSubmit",
                    "Pre-processing before Data Load",
                    {
                      jsonValidation: "javascript",
                      rows: 1,
                      eventCd: "widget.data",
                      eventType: "before",
                    }
                  )}
                  <UPopover
                    title="Description of Pre-processing before Data Load"
                    style={{ minWidth: "47px" }}
                    isMobileEditor={this.props.mobile.isMobileEditor}
                  >
                    <ul>
                      <li>
                        <font color="red">
                          Function executes before loading Template Data.
                        </font>
                      </li>
                      <li>
                        <strong>▣ Usage Example </strong>
                      </li>
                      <li>1. To change Parameters</li>
                      <li>2. To change API URL based on conditions. </li>
                    </ul>
                  </UPopover>
                </InputGroup>
              </PropertyValue>
              <PropertyLable style={{ width: "70%" }}>
                3.Post-processing after Load
              </PropertyLable>
              <PropertyValue style={{ width: "30%" }}>
                <InputGroup>
                  {this.renderEventTextArea(
                    "afterSubmit",
                    "Post-processing after Load Data",
                    {
                      jsonValidation: "javascript",
                      rows: 1,
                      eventCd: "widget.data",
                      eventType: "after",
                    }
                  )}
                  <UPopover
                    title="Description of Post-processing after Load Data"
                    style={{ minWidth: "47px" }}
                    isMobileEditor={this.props.mobile.isMobileEditor}
                  >
                    <ul>
                      <li>
                        <font color="red">
                          Function that executes after loading Template data.
                        </font>
                      </li>
                      <li>
                        <strong>▣ Usage Example</strong>
                      </li>
                      <li>
                        1. Addition and modification of loaded Template data
                      </li>
                    </ul>
                  </UPopover>
                </InputGroup>
              </PropertyValue>
              <PropertyLable style={{ width: "70%" }}>
                4.Post-processing after Render
              </PropertyLable>
              <PropertyValue style={{ width: "30%" }}>
                <InputGroup>
                  {this.renderEventTextArea(
                    "afterRender",
                    "Post-processing after Template Render",
                    {
                      jsonValidation: "javascript",
                      rows: 1,
                      eventCd: "widget.template",
                      eventType: "after",
                    }
                  )}
                  <UPopover
                    title="Post-processing after Template Render"
                    style={{ minWidth: "47px" }}
                    isMobileEditor={this.props.mobile.isMobileEditor}
                  >
                    <ul>
                      <li>
                        <font color="red">
                          Function that executes after rendering Template.
                        </font>
                      </li>
                    </ul>
                  </UPopover>
                </InputGroup>
              </PropertyValue>
              <PropertyLable>Template</PropertyLable>
              <PropertyValue>
                <UTextarea
                  popTitle="Widget Body Template"
                  textareaId="bodyTemplate"
                  defaultValue={StringUtils.defaultString(
                    this.state.propertyValue.bodyTemplate
                  )}
                  onBlur={this.onChange}
                />
                <Form.Text>
                  <BootstrapSwitchButton
                    id="mappingType"
                    checked={
                      StringUtils.defaultString(
                        this.state.propertyValue.mappingType,
                        "A"
                      ) === "A"
                        ? true
                        : false
                    }
                    size="sm"
                    width={250}
                    onstyle="primary"
                    offstyle="dark"
                    onlabel="Display the template for each data item"
                    offlabel="Display Template Once"
                    onChange={(checked) => {
                      this.onChangePropertyValue(
                        "mappingType",
                        checked ? "A" : "J"
                      );
                    }}
                  />
                  {this.state.propertyValue.mappingType === "J" ? (
                    <>
                      <span>
                        ResultData($data) will change like this Json Format :
                        {`{ "data" : Result Data} `}
                      </span>
                      <br></br>
                    </>
                  ) : (
                    ""
                  )}
                  <Button
                    onClick={this.openPopupSTemplateHelp}
                    size="sm"
                    variant={"outline-secondary"}
                    className={
                      this.props.mobile.isMobileEditor
                        ? "mobile-font-color"
                        : "light-font-color"
                    }
                    style={{ marginTop: "4px", marginBottom: "6px" }}
                  >
                    <FaBook size="14" /> Template Syntax
                  </Button>
                  <br></br>
                </Form.Text>
              </PropertyValue>
              {StringUtils.indexOf(
                this.state.propertyValue.bodyTemplate,
                "{icon}"
              ) > -1 ? (
                <React.Fragment>
                  <PropertyLable>Body Icon</PropertyLable>
                  <PropertyValue>
                    {" "}
                    <UInputPopup
                      id="bodyIconId"
                      defaultValue={JsonUtils.defaultString(
                        this.state.propertyValue.bodyIcon,
                        "id"
                      )}
                      onChange={this.onChangeBodyIcon}
                      onClick={this.openPopupIcon}
                    />
                  </PropertyValue>
                </React.Fragment>
              ) : (
                ""
              )}
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey={2}>
            <Accordion.Header>Widget Box Settings</Accordion.Header>
            <Accordion.Body>
              <PropertyLable>Border</PropertyLable>
              <PropertyValue>
                <InputGroup style={{ gap: "10px" }}>
                  <div style={{ width: "130px" }}>
                    <USelectbox
                      id="line"
                      onChange={this.onChange}
                      defaultValue={StringUtils.defaultString(
                        this.state.propertyValue.line
                      )}
                      items={[
                        { id: "none", text: "None" },
                        { id: "1", text: "1px" },
                        { id: "2", text: "2px" },
                        { id: "3", text: "3px" },
                      ]}
                      options={{ matchCd: "id", matchNm: "text" }}
                    />
                  </div>
                  {this.state.propertyValue.line !== "none" ? (
                    <ColorPicker
                      id="lineColor"
                      defaultValue={StringUtils.defaultString(
                        this.state.propertyValue.lineColor
                      )}
                      onBlur={this.onChange}
                    />
                  ) : (
                    ""
                  )}
                </InputGroup>
              </PropertyValue>
              <PropertyLable>Border (TopLine)</PropertyLable>
              <PropertyValue>
                <InputGroup style={{ gap: "10px" }}>
                  <div style={{ width: "130px" }}>
                    <USelectbox
                      id="topLine"
                      onChange={this.onChange}
                      defaultValue={StringUtils.defaultString(
                        this.state.propertyValue.topLine
                      )}
                      items={[
                        { id: "none", text: "None" },
                        { id: "1", text: "1px" },
                        { id: "2", text: "2px" },
                        { id: "3", text: "3px" },
                      ]}
                      options={{ matchCd: "id", matchNm: "text" }}
                    />
                  </div>
                  {this.state.propertyValue.topLine !== "none" ? (
                    <ColorPicker
                      id="toplineColor"
                      defaultValue={StringUtils.defaultString(
                        this.state.propertyValue.toplineColor
                      )}
                      onBlur={this.onChange}
                    />
                  ) : (
                    ""
                  )}
                </InputGroup>
              </PropertyValue>
              <PropertyLable>Shadow</PropertyLable>
              <PropertyValue>
                <UInputPopup
                  id="boxShadow"
                  defaultValue={StringUtils.defaultString(
                    this.state.propertyValue.boxShadow
                  )}
                  onClick={this.openDefaultPopup}
                  onBlur={this.onChange}
                />
              </PropertyValue>
              <PropertyLable>BorderRadius</PropertyLable>
              <PropertyValue>
                <USelectbox
                  id="borderRadius"
                  onChange={this.onChange}
                  defaultValue={StringUtils.defaultString(
                    this.state.propertyValue.borderRadius
                  )}
                  items={[
                    { id: "none", text: "None" },
                    { id: "1", text: "1px Radius" },
                    { id: "2", text: "2px Radius" },
                    { id: "3", text: "3px Radius" },
                    { id: "4", text: "4px Radius" },
                    { id: "5", text: "5px Radius" },
                    { id: "6", text: "6px Radius" },
                    { id: "10", text: "10px Radius" },
                  ]}
                  options={{ matchCd: "id", matchNm: "text" }}
                />
              </PropertyValue>
              <PropertyLable>Header</PropertyLable>
              <PropertyValue>
                <BootstrapSwitchButton
                  id="header"
                  checked={this.state.propertyValue.header}
                  size="sm"
                  onstyle="primary"
                  offstyle="dark"
                  onlabel="Yes"
                  offlabel="No"
                  onChange={(checked) => {
                    this.onChangePropertyValue("header", checked);
                  }}
                />
              </PropertyValue>
              {this.state.propertyValue.header === true ? (
                <React.Fragment>
                  <PropertyLable> - Header Title</PropertyLable>
                  <PropertyValue>
                    <input
                      type="text"
                      id="title"
                      defaultValue={StringUtils.defaultString(
                        this.state.propertyValue.title
                      )}
                      className="form-control form-control-sm"
                      onBlur={this.onChange}
                    />
                  </PropertyValue>
                </React.Fragment>
              ) : (
                ""
              )}
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey={3}>
            <Accordion.Header>Buttons Setting</Accordion.Header>
            <Accordion.Body>
              <UElementList
                isDisplay={true}
                isMobileEditor={this.props.mobile.isMobileEditor}
                className="pt-2"
                id="buttons"
                onBlur={(pId, pValue) => {
                  var newWiedgetButtons = pValue.filter(function (item) {
                    return item.useYn === true;
                  });

                  this.onChangePropertyValue("buttons", newWiedgetButtons);
                }}
                data={this.getWidgetButtons()}
                cols={[
                  {
                    label: "ID",
                    type: "input",
                    id: "id",
                    className: "w-30p",
                    readonly: true,
                  },
                  {
                    label: "Description",
                    type: "input",
                    id: "desc",
                    className: "w-30p",
                    readonly: true,
                  },
                  {
                    label: "More",
                    type: "button",
                    id: "btnInfo",
                    className: "w-25p",
                    isShowIcon: true,
                    settings: {
                      readonlyTp: "input",
                      onClick: (e) => this.openPopupSetButton(e),
                    },
                  },
                  {
                    label: "Show",
                    type: "switch",
                    id: "useYn",
                    className: "w-15p",
                    onlabel: "Yes",
                    offlabel: "No",
                  },
                ]}
                options={{
                  isMulti: false,
                  isHeader: false,
                }}
              />
            </Accordion.Body>
          </Accordion.Item>{" "}
          {/* Style property */}
          {this.renderStylePanel("Widget", 4)}
        </Accordion>
      </React.Fragment>
    );
  };

  /**
   * Editor의 component를 Redering
   * << Layout editor props>>
   *   - compId - 현재 component의 고유 ID
   *   - componentInfo - drag & drop시 생성된 component object
   *   - style - dragging style이 포함된 style (사용자가 정의한 style은 각 component에서 적절히 적용해야함)
   *   - event="renderEditor" - 요청 구분
   * @returns
   */
  renderEditor = () => {
    let propertyValue = ObjectUtils.isEmpty(
      this.props.componentInfo.propertyValue
    )
      ? {}
      : this.props.componentInfo.propertyValue;

    let boxStyle = {
      ...this.props.style,
      ...propertyValue.style,
    };

    //widget body의 문자 때문에 widget의 크기가 변형되는걸 막기 위해 body의 max width를 화면에 맞게 지정한다.
    // 상위 <div id='editCanvas'>의 css - max-width: calc( 100vw - 50px - 360px - 2px - 1rem);
    // 여기에 widget의 % 와 간격 20px를 적용한다.
    let widgetBoxbodyMaxWidth;
    if (NumberUtils.isNumeric(propertyValue.widthLaptop)) {
      let parent = Number(propertyValue.widthLaptop) / 12;
      widgetBoxbodyMaxWidth =
        "calc( ((100vw - 50px - 360px - 2px - 1rem) * " + parent + ") - 20px)";
    }

    /**
     * UIComponentSection props
     * -item
     * -style
     * -className
     */
    return (
      <UIComponentSection
        item={this.props.componentInfo}
        style={UITemplateHelper.getWidgetBoxStyle(boxStyle, propertyValue)}
        className={`editor-base draggable editor-widget-containter ${this.getEditorClassName()}`}
        title="WIDGET BOX"
      >
        {UITemplateHelper.renderWidgetTemplate(
          propertyValue,
          {
            boxbodyStyle: { maxWidth: widgetBoxbodyMaxWidth },
          },
          this.props.children
        )}
      </UIComponentSection>
    );
  };
}
export default WidgetContainer;
