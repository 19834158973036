import { Enums } from "components/builder/BuilderEnum";
import FunctionalSpecification from "components/builder/programDesign/component/FunctionalSpecification";
import ProgramDesignOverview from "components/builder/programDesign/component/ProgramDesignOverview";
import ProgramDesignScreenLayout from "components/builder/programDesign/component/ProgramDesignScreenLayout";
import TechnicalSpecification from "components/builder/programDesign/component/TechnicalSpecification";
import {
  setDesign,
  setDesignLayoutColumn,
  setDesignRelatedTables,
  setUploadFile,
} from "components/builder/programDesign/reducer/ProgramDesignAction";
import Message from "components/common/Message";
import ArrayUtils from "components/common/utils/ArrayUtils";
import ObjectUtils from "components/common/utils/ObjectUtils";
import User from "components/common/utils/UserUtils";
import PageTemplate from "page/common/PageTemplate";
import { useEffect, useState } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { useDispatch, useSelector } from "react-redux";
import FileService from "services/programDesignService/FileService";
import ProgramDesignService from "services/programDesignService/ProgramDesignService";

const breadcrum = [{ name: "Program Design", active: true }];
const ProgramDesignBuilderMain = (props) => {
  const [designMst, setDesignMst] = useState({});
  const [designLayoutColumnList, setDesignLayoutColumnList] = useState([]);
  const [designUploadFile, setDesignUploadFile] = useState([]);
  const technicalDesign = useSelector((state) => state.technicalDesign);
  const workspace = useSelector((state) => state.workspace);

  const [flag, setFlag] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    getDesignMst();
  }, []);

  useEffect(() => {
    if (
      ObjectUtils.isEmpty(technicalDesign.design) &&
      ArrayUtils.isEmpty(technicalDesign.designLayoutColumn)
    ) {
      setFlag(true);
    } else {
      setFlag(false);
      setDesignLayoutColumnList(technicalDesign.designLayoutColumn);
      setDesignUploadFile(technicalDesign.designUploadFile);
    }
  }, [technicalDesign]);

  /**
   * Multilang Text 값을 찾아주는 함수
   * @param {String} multilangCd
   */
  const findMultilang = (multilangCd, multilangList) => {
    if (multilangList) {
      const multilang = multilangList.find(
        (item) => item.multilangCd === multilangCd
      );
      if (!ObjectUtils.isEmpty(multilang)) return multilang.multilangText;
      else return "";
    }
  };

  const tabContents = [
    {
      key: "overview",
      title: "Overview",
      program: (
        <ProgramDesignOverview
          designMst={designMst}
          setDesignMst={setDesignMst}
          flag={flag}
        />
      ),
    },
    {
      key: "screen layout",
      title: "Screen Layout",
      program: (
        <ProgramDesignScreenLayout
          designMst={designMst}
          setDesignMst={setDesignMst}
          designLayoutColumnList={designLayoutColumnList}
          setDesignLayoutColumnList={setDesignLayoutColumnList}
          designUploadFile={designUploadFile}
          setDesignUploadFile={setDesignUploadFile}
          flag={flag}
        />
      ),
    },
    {
      key: "functional specification",
      title: "Functional Specification",
      program: (
        <FunctionalSpecification
          designMst={designMst}
          setDesignMst={setDesignMst}
          findMultilang={findMultilang}
          flag={flag}
        />
      ),
    },
    {
      key: "technical specification",
      title: "Technical Specification",
      program: (
        <TechnicalSpecification
          designMst={designMst}
          setDesignMst={setDesignMst}
          findMultilang={findMultilang}
          flag={flag}
        />
      ),
    },
  ];

  const [activeTab, setActiveTab] = useState();

  // Design Mst 조회 후 redux에 저장
  const getDesignMst = () => {
    if (technicalDesign.design.designMstId) {
      const data = {
        designMstId: technicalDesign.design.designMstId,
        appId: workspace.appId,
      };
      ProgramDesignService.getDesignMst(data, (result) => {
        if (result.isError) {
          Message.alert(
            "An error occurred while get data.",
            Enums.MessageType.ERROR
          );
        } else {
          if (
            !ObjectUtils.isEmpty(result.data) &&
            !ArrayUtils.isEmpty(result.data.designMultilang)
          ) {
            const designMultilang = result.data.designMultilang.filter(
              (item) => item.langCd === User.getLanguage().toLowerCase()
            );
            result.data.designMultilang = designMultilang;
            dispatch(setDesign(result.data));
            setDesignMst(result.data);
          }
        }
      });
      ProgramDesignService.getLayoutColumnList(data, (result) => {
        if (result.isError) {
          Message.alert(
            "An error occurred while get data.",
            Enums.MessageType.ERROR
          );
        } else {
          if (!ArrayUtils.isEmpty(result.data.designLayoutColumnLang)) {
            const designLayoutColumnLang =
              result.data.designLayoutColumnLang.filter(
                (item) => item.langCd === User.getLanguage().toLowerCase()
              );
            result.data.designLayoutColumnLang = designLayoutColumnLang;
          }
          dispatch(setDesignLayoutColumn(result.data));
          setDesignLayoutColumnList(result.data);
        }
      });
      ProgramDesignService.getDesignRelatedTables(
        data,
        (res) => {
          dispatch(setDesignRelatedTables(res.data));
        },
        (err) => {
          console.log(err);
          dispatch(setDesignRelatedTables([]));
        }
      );
      FileService.getFileList(
        { attachId: "design/" + data.designMstId },
        (res) => {
          dispatch(setUploadFile(res.data));
        },
        (err) => {
          console.log(err);
          dispatch(setUploadFile([]));
        }
      );
    }
  };

  // tab 클릭
  const onClickTab = (key) => {
    setActiveTab(key);
    //dispatch(setDesign(designMst));
  };

  return (
    <>
      {(User.getAuthType() === "S" || User.getAuthType() === "AM") && (
        <PageTemplate breadcrum={breadcrum}>
          <PageTemplate.Box boxClass="mb-0">
            <div style={{ height: "100%" }}>
              <Tabs
                id="technicalTabs"
                activeKey={activeTab}
                // justify
                onSelect={(key) => onClickTab(key)}
              >
                {tabContents.map((content) => {
                  return (
                    <Tab
                      key={content.key}
                      eventKey={content.key}
                      //onClick={(e) => onClickTab(e, content)}
                      title={content.title}
                    >
                      {content.program}
                    </Tab>
                  );
                })}
              </Tabs>
            </div>
          </PageTemplate.Box>
        </PageTemplate>
      )}
    </>
  );
};

export default ProgramDesignBuilderMain;
