/* eslint-disable jsx-a11y/alt-text */
import React, { Fragment, useEffect, useState } from "react";

import Modal from "components/common/modal/UModal";

import UmodalTemplate from "components/common/modal/UModalTemplate";
import EventhandlerService from "services/eventhandler/EventhandlerService";
import ArrayUtils from "components/common/utils/ArrayUtils";
import StringUtils from "components/common/utils/StringUtils";
import JsonUtils from "components/common/utils/JsonUtils";
import { Enums } from "components/builder/BuilderEnum";
import { HiDocumentDuplicate } from "react-icons/hi";

const ComponentFncDocPopup = (props) => {
  const [eventFncMst, setEventFncMst] = useState([]);
  const [eventFncDtl, setEventFncDtl] = useState({});
  const [blockList, setBlockList] = useState([]);
  useEffect(() => {
    EventhandlerService.getFncMstList({}, (res) => {
      if (res && res.data) {
        let availableData = res.data.filter((mst) => mst.docsYn === "Y");
        setEventFncMst(availableData);
      }
    });
  }, [props]);

  /**
   * Component 클릭
   * @param {Event} e
   * @param {Object} dtl  eventFncDtlList
   */
  const onClickFncDtl = (e, dtl) => {
    setEventFncDtl(dtl);
    EventhandlerService.getBlockList({ fncDtlId: dtl.fncDtlId }, (res) => {
      if (res && res.data) {
        let availableData = res.data.filter((dtl) => dtl.docsYn === "Y");

        availableData.forEach((value, index) => {
          value.ref = React.createRef();
        });

        //재정렬
        availableData.sort((a, b) =>
          a.functionName.localeCompare(b.functionName)
        );

        setBlockList(availableData);
      } else {
        setBlockList([]);
      }
    });
  };

  /**
   * Function Summary 클릭시 상세 위치로 scroll
   * @param {Event} e
   * @param {Object} block  function 정보
   */
  const onClickFunction = (e, block) => {
    block.ref.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };

  return (
    <Modal>
      <Modal.Header title="Component Function" />
      <Modal.Body scrollToTopButton={true} scrollToTopButtonPos={50}>
        <UmodalTemplate>
          <div className="doc-page-wrap">
            <div className="event-fnc-mst">
              {eventFncMst.map((mst, index) => {
                const Icon =
                  Enums.EventCategoryIconEnums[mst.fncMstId] ||
                  HiDocumentDuplicate;
                return (
                  <div className="editor-widget-containter" key={mst.fncMstId}>
                    <div className="widget-header">
                      <div className="widget-title">
                        <Icon /> {mst.fncMstName}
                      </div>
                    </div>
                    <div className="widget-body">
                      {!ArrayUtils.isEmpty(mst.eventFncDtlList)
                        ? mst.eventFncDtlList.map((dtl, index) => {
                            return (
                              <div className="event-fnc-dtl" key={dtl.fncDtlId}>
                                <span
                                  onClick={(e) => {
                                    onClickFncDtl(e, dtl);
                                  }}
                                >
                                  {dtl.fncDtlName}
                                </span>
                              </div>
                            );
                          })
                        : ""}
                    </div>
                  </div>
                );
              })}
            </div>
            {!ArrayUtils.isEmpty(blockList) ? (
              <div className="fnc-doc">
                <h2>{eventFncDtl.fncDtlName}</h2>
                <span class="fnc-object-name">
                  {StringUtils.isEmpty(eventFncDtl.objectName)
                    ? ""
                    : "Object Name : " + eventFncDtl.objectName}
                </span>
                {StringUtils.isEmpty(eventFncDtl.objectProps) ? (
                  ""
                ) : (
                  <Fragment>
                    <div className="fnc-title">Property</div>
                    <div
                      className="fnc-props-summary"
                      dangerouslySetInnerHTML={{
                        __html: eventFncDtl.objectProps,
                      }}
                    ></div>
                  </Fragment>
                )}
                <div className="fnc-title">Method</div>
                <div className="fnc-block-summary">
                  <ul>
                    {blockList.map((block, bindex) => {
                      return (
                        <li key={bindex}>
                          <span
                            onClick={(e) => {
                              onClickFunction(e, block);
                            }}
                          >
                            {block.functionName}
                          </span>
                        </li>
                      );
                    })}
                  </ul>
                </div>
                {blockList.map((block, bindex) => {
                  let argsInfo = [];
                  let returnInfo = {};
                  if (!StringUtils.isEmpty(block.functionProps)) {
                    const functionProps = JsonUtils.parseJson(
                      block.functionProps
                    );
                    argsInfo = functionProps.args || [];
                    returnInfo = functionProps.return || {};
                  }
                  return (
                    <div className="fnc-block" key={bindex} ref={block.ref}>
                      <h5>
                        {block.functionName} : {block.blockName}
                      </h5>
                      <div className="doc-page-wrap">
                        <div className="event-desc pt-1">
                          <pre>{block.blockDesc}</pre>
                        </div>
                        {!StringUtils.isEmpty(block.codeExample) ? (
                          <Fragment>
                            Sample Code
                            <div className="event-code">
                              <pre>{block.codeExample}</pre>
                            </div>
                          </Fragment>
                        ) : (
                          ""
                        )}
                        {!ArrayUtils.isEmpty(argsInfo) ? (
                          <div className="event-params pb-1">
                            <span className="doc-title"> Parameters</span>
                            <ul>
                              {argsInfo.map((args, index) => {
                                return (
                                  <li key={index}>
                                    <span className="event-args-var">
                                      {args.name}
                                    </span>
                                    :
                                    <span className="event-args-type">
                                      {args.type}
                                    </span>
                                    <div className="event-args-desc">
                                      {args.desc}
                                    </div>
                                  </li>
                                );
                              })}
                            </ul>
                          </div>
                        ) : (
                          ""
                        )}
                        <div className="event-return pb-1 pt-1">
                          <span className="doc-title"> Returns</span>
                          <span className="event-return-type">
                            {StringUtils.isEmpty(returnInfo.type)
                              ? "void"
                              : returnInfo.type}
                          </span>
                          <div className="event-return-desc">
                            {StringUtils.defaultString(returnInfo.desc)}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              //method가 없는경우 UI component가 아님-속성(가이드)만 보여준다.
              <div
                className="fnc-props-summary"
                dangerouslySetInnerHTML={{
                  __html: eventFncDtl.objectProps,
                }}
              ></div>
            )}
          </div>
        </UmodalTemplate>
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
};

export default ComponentFncDocPopup;
