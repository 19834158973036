import React from "react";

import LayoutTemplate from "components/builder/ui/uiComponents/template/LayoutTemplate";
import UITemplateHelper from "components/builder/ui/editor/helper/UITemplateHelper";
import * as Enums from "components/builder/BuilderEnum";
import ObjectUtils from "components/common/utils/ObjectUtils";
import JsonUtils from "components/common/utils/JsonUtils";
import * as actions from "components/builder/ui/reducers/UIBuilderAction";
import produce from "immer";
import StringUtils from "components/common/utils/StringUtils";

class LoginTemplate extends LayoutTemplate {
  componentDidMount() {}

  createLayoutData(oldUiTemplate, uiTemplate, tempCom, props) {
    let loginOutwrapClass = "login_out_wrap builder";
    const fullImage = "fullType-image"; // 전체 화면형 이미지
    const custom = "user_custom"; // 사용자 지정

    /* 1. 신규 여부 */
    if (ObjectUtils.isEmpty(props.output.page.child)) {
      /* 신규화면일 경우 (빈 화면) */

      /* 2. Container 생성 (login_out_wrap) */
      let loginOutWrap = UITemplateHelper.getContainerTemplate(tempCom);
      let imageClass = this.getImageClass(uiTemplate);
      loginOutWrap.propertyValue.className = loginOutwrapClass + imageClass;
      loginOutWrap.propertyValue.id = "loginOutContainer";
      loginOutWrap.propertyValue.isLoginTmp = true;

      /* 3. Container 생성 (login_wrap) : 로그인 Form을 위해 생성 */
      let container = UITemplateHelper.getContainerTemplate(tempCom);
      container.propertyValue.className = "login_wrap";
      container.propertyValue.id = "loginContainer";
      container.propertyValue.isLoginTmp = true;

      /* 4. [전제화면형 이미지]가 아닐 경우, Image Components 추가 */
      if ([fullImage, custom].indexOf(uiTemplate) < 0) {
        let imageField = UITemplateHelper.getImageTemplate(tempCom);
        imageField.propertyValue.id = "loginImg";
        imageField.propertyValue.isLoginTmp = true;

        loginOutWrap.child.push(imageField);
      }

      /* 5. 로그인 Form 생성 */
      let template = this.createFormData(tempCom);

      container.child.push(template);
      loginOutWrap.child.push(container);

      return loginOutWrap;
    } else {
      /* Page 아래에 Child가 존재할 경우 */
      let imageField;
      let imageFlag;

      const changeOutput = produce(props.output, (draft) => {
        /* 2. Template 구분 변경 */
        JsonUtils.overrideNode(
          draft,
          "uiTemplate",
          oldUiTemplate,
          "uiTemplate",
          uiTemplate
        );

        /* 3. (login_out_wrap) Container class 변경 */
        JsonUtils.overrideNode(
          draft,
          "id",
          "loginOutContainer",
          "className",
          loginOutwrapClass + this.getImageClass(uiTemplate)
        );

        /* [전체 화면형 이미지]일 경우 */
        if (uiTemplate === fullImage) {
          imageField = JsonUtils.findNode(draft, "id", "loginImg");
          if (!ObjectUtils.isEmpty(imageField)) {
            JsonUtils.overrideNodeStyle(
              draft,
              "id",
              "loginOutContainer",
              "backgroundType",
              "image"
            );

            /* 4-1. Container에 Image URL 삽입 */
            JsonUtils.overrideNodeStyle(
              draft,
              "id",
              "loginOutContainer",
              "backgroundImage",
              "url(" + imageField.imageFile + ")"
            );

            /* 4-2. Container에 File Name 삽입 */
            JsonUtils.overrideNode(
              draft,
              "id",
              "loginOutContainer",
              "fileName",
              imageField.fileName
            );

            imageFlag = false;
          }
        } else if (
          oldUiTemplate === fullImage &&
          uiTemplate !== "user_custom"
        ) {
          /* 변경 전 [전체 화면형 이미지]이며, 변경 후 [사용자 지정]이 아닐 경우 */

          /* 4-1. backgroundIamge 가져오기 */
          const backgroundImageNode = JsonUtils.findNode(
            draft,
            "id",
            "loginOutContainer"
          );

          if (
            !ObjectUtils.isEmpty(backgroundImageNode) &&
            ObjectUtils.isEmpty(
              JsonUtils.findNodeById(props.output, "loginImg")
            )
          ) {
            imageField = UITemplateHelper.getImageTemplate(tempCom);
            //Image component 추가
            let imageFile = backgroundImageNode.style["backgroundImage"];
            imageFile = imageFile.replace("url(", "");
            imageFile = imageFile.replace(")", "");

            imageField.propertyValue.id = "loginImg";
            imageField.propertyValue.imageFile = imageFile;
            imageField.propertyValue.isLoginTmp = true;
            imageField.propertyValue.fileName = backgroundImageNode.fileName;

            // backgroundImage 제거
            JsonUtils.overrideNodeStyle(
              draft,
              "id",
              "loginOutContainer",
              "backgroundImage",
              ""
            );

            JsonUtils.overrideNode(
              draft,
              "id",
              "loginOutContainer",
              "fileName",
              ""
            );

            imageFlag = true;
          }
        }
      });
      props.dispatch(actions.updateOutput(changeOutput));

      if (imageFlag === true) {
        //image component 추가
        const targetComponent = JsonUtils.findNodeById(
          changeOutput,
          "loginContainer"
        );

        props.fn.createComponent(
          changeOutput,
          {},
          "B",
          imageField,
          targetComponent
        );
      } else if (imageFlag === false) {
        //image component제거
        props.fn.deleteComponent(
          JsonUtils.findNodeById(props.output, "loginImg"),
          changeOutput
        );
      }

      return null;
    }
  }

  createFormData(tempCom) {
    //form
    let newForm = UITemplateHelper.getFormTemplate(tempCom);
    newForm.propertyValue.formType = Enums.FormType.SAVE;
    newForm.propertyValue.className = "login_form";
    newForm.propertyValue.isLoginTmp = true;

    //Row
    let newRow = UITemplateHelper.getRowTemplate(tempCom);

    //오른쪽 Column 추가
    let textCol = UITemplateHelper.getColTemplate(tempCom);

    //1번째 Row - header ("로그인")
    let childRow = UITemplateHelper.getRowTemplate(tempCom);
    let childCol = UITemplateHelper.getColTemplate(tempCom);
    let field = UITemplateHelper.getHeadingTemplate(tempCom);

    childRow.propertyValue.className = "f_group";

    field.propertyValue.headingType = "H2";
    field.propertyValue.text = "LOGIN";
    field.propertyValue.className = "tit_login";
    field.propertyValue.layoutType = "N";
    field.propertyValue.style = { justifyContent: "center" };
    field.propertyValue.isLoginTmp = true;

    childCol.child.push(field);
    childRow.child.push(childCol);
    textCol.child.push(childRow);

    //2번째 Row - 아이디
    childRow = UITemplateHelper.getRowTemplate(tempCom);
    childCol = UITemplateHelper.getColTemplate(tempCom);
    field = UITemplateHelper.getInputTemplate(tempCom);

    childRow.propertyValue.className = "f_group";

    field.propertyValue.id = "userId";
    field.propertyValue.placeholder = "ID";
    field.propertyValue.formLabel = "ID";
    field.propertyValue.layoutType = "N";
    field.propertyValue.className = "text_login";
    field.propertyValue.isRequired = true;
    field.propertyValue.isLoginTmp = true;
    field.isLoginTmp = true;

    childCol.child.push(field);
    childRow.child.push(childCol);
    textCol.child.push(childRow);

    //3번째 Row - 비밀번호
    childRow = UITemplateHelper.getRowTemplate(tempCom);
    childCol = UITemplateHelper.getColTemplate(tempCom);
    field = UITemplateHelper.getInputTemplate(tempCom);

    childRow.propertyValue.className = "f_group";

    field.propertyValue.id = "password";
    field.propertyValue.type = "password";
    field.propertyValue.placeholder = "PASSWORD";
    field.propertyValue.formLabel = "PASSWORD";
    field.propertyValue.layoutType = "N";
    field.propertyValue.className = "text_login";
    field.propertyValue.isRequired = true;
    field.propertyValue.isLoginTmp = true;

    childCol.child.push(field);
    childRow.child.push(childCol);
    textCol.child.push(childRow);

    //4번째 Row - 회사명
    childRow = UITemplateHelper.getRowTemplate(tempCom);
    childCol = UITemplateHelper.getColTemplate(tempCom);
    field = UITemplateHelper.getSelectTemplate(tempCom);

    childRow.propertyValue.className = "f_group";

    field.propertyValue.id = "coCd";
    field.propertyValue.formLabel = "Company Name";
    field.propertyValue.layoutType = "N";
    field.propertyValue.comboType = "basic";
    field.propertyValue.searchTp = "URL";
    field.propertyValue.url = "/getDefaultCompList.do";
    field.propertyValue.className = "loginCombo";
    field.propertyValue.isRequired = true;
    field.propertyValue.isLoginTmp = true;

    childCol.child.push(field);
    childRow.child.push(childCol);
    textCol.child.push(childRow);

    //5번째 Row - 아이디 저장 or 비밀번호 초기화
    childRow = UITemplateHelper.getRowTemplate(tempCom);
    childCol = UITemplateHelper.getRowTemplate(tempCom);

    childRow.propertyValue.className = "f_group";
    childCol.propertyValue.className = "form_menu";

    //Checkbox
    let inputGroupCol = UITemplateHelper.getColTemplate(tempCom);
    field = UITemplateHelper.getCheckboxTemplate(tempCom);

    inputGroupCol.propertyValue.className = "chk_login";

    field.propertyValue.id = "saveId";
    field.propertyValue.label = "Remember ID";
    field.propertyValue.formLabel = "Remember ID";
    field.propertyValue.checkedValue = true;
    field.propertyValue.unCheckedValue = false;
    field.propertyValue.layoutType = "N";
    field.propertyValue.isLoginTmp = true;

    inputGroupCol.child.push(field);

    childCol.child.push(inputGroupCol);

    //HyperLink
    inputGroupCol = UITemplateHelper.getColTemplate(tempCom);
    field = UITemplateHelper.getHyperlinkTemplate(tempCom);

    inputGroupCol.propertyValue.className = "find";

    field.propertyValue.id = "btnInitPassword";
    field.propertyValue.text = "Init Password";
    field.propertyValue.style = { justifyContent: "right" };
    field.propertyValue.isLoginTmp = true;

    inputGroupCol.child.push(field);

    childCol.child.push(inputGroupCol);
    childRow.child.push(childCol);
    textCol.child.push(childRow);

    //6번째 Row - 버튼
    childRow = UITemplateHelper.getRowTemplate(tempCom);
    childCol = UITemplateHelper.getColTemplate(tempCom);
    field = UITemplateHelper.getButtonTemplate(tempCom);

    childRow.propertyValue.className = "f_group";

    field.propertyValue.id = "btnLogin";
    field.propertyValue.label = "LOGIN";
    field.propertyValue.layoutType = "N";
    field.propertyValue.className = "btn_submit";
    field.propertyValue.isLoginTmp = true;

    childCol.child.push(field);
    childRow.child.push(childCol);
    textCol.child.push(childRow);

    newRow.child.push(textCol);

    newForm.child.push(newRow);
    return newForm;
  }

  getImageClass(type) {
    let imageClass;
    if (type === "standard-image") {
      /** 표준형 테마 **/
      imageClass = " s1";
    } else if (type === "fullType-image") {
      /** 전체 이미지형 테마 **/
      imageClass = " s2";
    } else if (type === "boxType-image") {
      /** 박스형 테마 **/
      imageClass = " s3";
    } else {
      imageClass = "";
    }

    return imageClass;
  }
}

export default LoginTemplate;
