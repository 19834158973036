import { ReactFlowProvider } from "reactflow";
import BundleDetailBuilder from "./BundleDetailBuilder";

function BundleDetailMain({ ...props }) {
  return (
    <ReactFlowProvider>
      <BundleDetailBuilder {...props} />
    </ReactFlowProvider>
  );
}

export default BundleDetailMain;
