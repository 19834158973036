import React, { Fragment } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { AiOutlineFunction } from "react-icons/ai";
import { FaCode } from "react-icons/fa";
import UModalCodeEditor from "components/common/code/UModalCodeEditor";
import { StringUtils } from "components/common/utils/CommonUtils";
import Blockly from "blockly";
import { BsFiletypeJs, BsJournalCode } from "react-icons/bs";
import ComponentEventDocPopup from "page/popup/ComponentEventDocPopup";
import Popup from "../Popup";
import ComponentFncDocPopup from "page/popup/ComponentFncDocPopup";
import * as Effect from "components/common/modal/Effect";

class UModalJavascriptEditor extends UModalCodeEditor {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      activedTabKey: "eventBuilder",
      openJsFunction: false,
    };
    this.handleTabSelect = this.handleTabSelect.bind(this);
    this.makeSnapShot = this.makeSnapShot.bind(this);
    this.snapShotScrollEvent = this.snapShotScrollEvent.bind(this);
    this.clickEventDetail = this.clickEventDetail.bind(this);
    this.clickJsFunction = this.clickJsFunction.bind(this);
  }

  /**
   * 최초 로드시
   */
  componentDidMount = () => {
    this.initialize();

    //javascript
    this.extensions = this.EDITOR_EXTENSIONS["javascript"];

    //직접 Javascript 코드를 입력 했을 경우 "eventCode" tab, 아닐 경우 "event builder" tab
    //우선 eventCode로 하드코딩
    this.setState({ activedTabKey: "eventCode" });

    // Event Builder snap shot 설정 단계
    if (
      this.props.eventInfo.eventWorkspace &&
      this.props.eventInfo.eventWorkspace.blocks
    ) {
      this.makeSnapShot();
    }
  };

  componentWillUnmount = () => {
    if (this.blocklyWorkspace) {
      this.blocklyWorkspace.dispose();
    }
  };

  componentDidUpdate = (prevProps, prevState) => {
    // Tab이 변경되었을 경우 snap shot 초기화
    if (
      this.state.activedTabKey !== prevState.activedTabKey &&
      this.state.activedTabKey === "eventBuilder"
    ) {
      if (this.blocklyWorkspace) {
        this.blocklyWorkspace.dispose();
        this.makeSnapShot();
      }
    }

    // 화면의 크기가 변경되었을 경우 snap shot의 크기도 update
    if (this.state.isFullScreen !== prevState.isFullScreen) {
      if (this.blocklyWorkspace) {
        this.blocklyWorkspace.dispose();
        this.makeSnapShot();
      }
    }
  };

  // snap shot 생성
  makeSnapShot = () => {
    const eventWorkspace = this.props.eventInfo.eventWorkspace;
    if (eventWorkspace) {
      this.blocklyWorkspace = Blockly.inject(this.builderSnapShot, {
        toolbox: {
          kind: "flyoutToolbox",
          contents: [],
        },
        renderer: "ZELOS",
        move: {
          drag: true,
          wheel: false,
        },
        zoom: {
          controls: false,
          wheel: true,
          pinch: true,
          startScale: 0.7,
          maxScale: 2,
          minScale: 0.1,
        },
        scrollbars: true,
        readOnly: true,
      });

      if (this.state.isFullScreen) {
        this.blocklyWorkspace.setScale(0.7);
      } else {
        this.blocklyWorkspace.setScale(0.3);
      }
      this.snapShotScrollEvent(this.blocklyWorkspace);

      Blockly.serialization.workspaces.load(
        eventWorkspace,
        this.blocklyWorkspace
      );
      this.blocklyWorkspace.scrollCenter();

      this.blocklyWorkspace
        .getParentSvg()
        .setAttribute("style", "background-color : #1e1e1e");
      this.blocklyWorkspace.getParentSvg().setAttribute("id", "snapShot");
      this.setState({ activedTabKey: "eventBuilder" });
    }
  };

  // snap shot workspace scroll 이벤트
  snapShotScrollEvent = (blocklyWorkspace) => {
    blocklyWorkspace.getParentSvg().addEventListener("wheel", function (e) {
      let scale = blocklyWorkspace.getScale();
      if (e.deltaY > 0) {
        blocklyWorkspace.setScale((scale /= 1.2));
      } else {
        blocklyWorkspace.setScale((scale *= 1.2));
      }
    });
  };

  /**
   * event 상세 버튼 클릭 -> event 상세화면 open
   * @param event
   */
  clickEventDetail = (e) => {
    this.clickDocumentDetail(ComponentEventDocPopup);
  };
  /**
   * javascript function 버튼 클릭 -> Javascript function 화면open
   * @param event
   */
  clickJsFunction = (e) => {
    if (this.state.openJsFunction) {
      return;
    }
    this.setState({ openJsFunction: true });

    let effect = { ...Effect.SlideFromRight }; //Effect.SlideFromTop(default)를 Effect.ScaleUp 로 변경

    const width = 50;
    let translateX = ((50 - 0.5 * width) / width) * 100;
    effect.end.transform = "translateX(" + translateX + "%)";
    const options = {
      effect: effect,
      style: {
        content: {
          width: width + "%", //popup의 크기를 50% (default 60%)
        },
      },
      onCloseCallback: () => {
        this.setState({ openJsFunction: false });
      },
      disableDragging: true,
    };

    Popup.open(<ComponentFncDocPopup />, options);
  };

  /**
   * API 문서 연결 버튼
   * @override
   */
  renderApiButton = () => {
    return (
      <Fragment>
        <BsJournalCode
          size={19}
          className="editor-btn"
          title="Event Function Description"
          onClick={this.clickEventDetail}
        ></BsJournalCode>
        <BsFiletypeJs
          size={19}
          className="editor-btn"
          title="JavaScript Function Guide"
          onClick={this.clickJsFunction}
        ></BsFiletypeJs>
      </Fragment>
    );
  };

  /**
   * change Tab
   * @param {String} key
   */
  handleTabSelect = (key) => {
    if (this.state.activedTabKey !== key) this.setState({ activedTabKey: key });
  };

  render() {
    return (
      <div
        className={`event-editor use-builder  ${
          this.state.isFullScreen ? "fullscreen" : ""
        } ${this.state.editorTheme ? this.state.editorTheme : ""} ${
          this.state.openDocument ? "open-event-detail" : ""
        } ${this.state.openJsFunction ? "open-js-function" : ""}`}
      >
        <Tabs
          fill
          activeKey={this.state.activedTabKey}
          id="javascript-editor-tab"
          onSelect={this.handleTabSelect}
        >
          <Tab
            eventKey="eventBuilder"
            title={
              <span>
                <AiOutlineFunction size="16" />
                <span className="tab-name">Event Builder</span>
              </span>
            }
          >
            <div
              className="event-block-snapshot"
              style={{
                height: StringUtils.defaultString(
                  this.props.height,
                  this.DEFAULT_EDITOR_HEIGHT
                ),
              }}
            >
              <div
                id="builder-snap-shot"
                ref={(ref) => {
                  this.builderSnapShot = ref;
                }}
                style={{ height: "100%", width: "100%" }}
              ></div>
            </div>
          </Tab>
          <Tab
            eventKey="eventCode"
            title={
              <span>
                <FaCode size="16" />
                <span className="tab-name"> Javascript Code</span>
              </span>
            }
          >
            {/* code mirror */}
            {this.renderCodeMirror()}
          </Tab>
        </Tabs>
        {/* editor toolbar buttons */}
        {this.renderToolbarButtons(this.state.activedTabKey)}
      </div>
    );
  }
}

export default React.memo(UModalJavascriptEditor);
