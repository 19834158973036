import { CircularProgress, Tooltip } from "@mui/material";
import { Enums } from "components/builder/BuilderEnum";
import ModuleSelector from "components/common/element/ModuleSelector";
import Message from "components/common/Message";
import Modal from "components/common/modal/UModal";
import UmodalTemplate from "components/common/modal/UModalTemplate";
import Popup from "components/common/Popup";
import CommonUtils, {
  ArrayUtils,
  JsonUtils,
  ObjectUtils,
  StringUtils,
} from "components/common/utils/CommonUtils";
import React from "react";
import { useState } from "react";
import { Button, Col, Form, InputGroup, Row } from "react-bootstrap";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import AppService from "services/common/AppService";
import DataModelService from "services/datamodel/DataModelService";
import ProgramService from "services/ui/ProgramService";
import WorkflowService from "services/workflow/WorkflowService";

function AppReleasePopup({
  appList = [],
  callBack,
  moduleCreatePopup,
  ...props
}) {
  const [selectedApp, setSelectedApp] = useState("");
  const [selectedModule, setSelectedModule] = useState("");
  const [fromVersion, setFromVersion] = useState("");
  const [version, setVersion] = useState("");
  const [briefDesc, setBriefDesc] = useState("");
  const [releaseNotes, setReleaseNotes] = useState("");

  const [fromVersionList, setFromVersionList] = useState([]);

  const [isExpandModelSearch, setIsExpandModelSearch] = useState(false);
  const [optionIn, setOptionIn] = useState("*");
  const [optionNotIn, setOptionNotIn] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("Loading...");

  const onChangeApp = (e) => {
    setSelectedApp(e.currentTarget.value);
    setIsExpandModelSearch(false);
    setSelectedModule("");
    setOptionIn("");
    setOptionNotIn("");
  };

  const onChangeModule = (e) => {
    setSelectedModule(e.target.value);
    if (e.target.value) {
      setIsExpandModelSearch(true);
    }
    //기존에 정해진 prefix있으면 가져옴
    AppService.getAppModule(
      { moduleCd: e.target.value, appId: selectedApp },
      (res) => {
        if (res.data) {
          const moduleOption = JSON.parse(res.data.moduleOption);
          setOptionIn(moduleOption.in.toString());
          setOptionNotIn(moduleOption.notIn.toString());
        } else {
          setOptionIn(e.target.value);
          setOptionNotIn("");
        }
      }
    );
    //참조할 버전 목록
    AppService.getAvailableAppReleaseList(
      { appId: selectedApp, moduleCd: e.target.value },
      (res) => {
        setFromVersionList(res.data);
      }
    );
  };

  const onChangeTargetVersion = (e) => {
    setFromVersion(e.target.value);
  };
  const onGetReleaseNotes = (e) => {
    setIsLoading(true);
    setLoadingMessage("Collecting revision details.");
    const body = {
      appId: selectedApp,
      moduleCd: selectedModule,
      appReleaseId: fromVersion,
      tenantId: "*",
      coCd: "*",
    };

    ProgramService.getProgramHistoryCommentList(
      body,
      (res) => {
        let releaseNotesText = "▶ Program List\n";
        const historyMstList = res.data;
        if (historyMstList) {
          for (const historymst of historyMstList) {
            releaseNotesText += `\t● ${historymst.programId}\n`;
            if (historymst.commentList.length > 0) {
              for (const comment of historymst.commentList) {
                if (!StringUtils.isEmpty(comment.commitComment)) {
                  releaseNotesText += `\t\t - ${comment.commitComment}  |  ${
                    comment.updtUserId
                  }  |  ${CommonUtils.getDate(comment.updtDt)} \n`;
                }
              }
            }
          }
          setReleaseNotes(releaseNotesText);
        }
        setIsLoading(false);
        setLoadingMessage("Loading...");
      },
      () => {
        setIsLoading(false);
      }
    );
  };

  const onSaveRelease = (e) => {
    if (e) e.preventDefault();
    if (!selectedApp)
      return Message.alert("Please select Application", Enums.MessageType.WARN);
    if (!selectedModule)
      return Message.alert("Please select module.", Enums.MessageType.WARN);
    if (!version)
      return Message.alert("Please select version", Enums.MessageType.WARN);
    if (!briefDesc)
      return Message.alert(
        "Please enter version description.",
        Enums.MessageType.WARN
      );
    if (!releaseNotes)
      return Message.alert(
        "Please enter revision content.",
        Enums.MessageType.WARN
      );

    const body = {
      appId: selectedApp,
      moduleCd: selectedModule,
      moduleOption: JSON.stringify({
        in: StringUtils.isEmpty(optionIn.replace(/ /g, ""))
          ? ["*"]
          : optionIn.replace(/ /g, "").split(","),
        notIn: optionNotIn.replace(/ /g, "").split(","),
      }),
      useYn: "Y",
      version,
      fromVersion,
      briefDesc,
      releaseNotes,
    };
    //1. APP 저장
    //2. 데이터 모델 복사
    //3. 데이터 모델 가져오고
    //4. 프로그램 목록 가져옴
    //5. 각 프로그램 내용에 변경된 데이터 모델 내용 적용
    //6. 변경된 프로그램 목록 저장
    //7. 완료

    //실패시 롤백
    setIsLoading(true);
    setLoadingMessage("Creating an Application.");
    let newApp = {};
    let newDataModelObject = {};
    let newProgramList = [];
    let from = null;
    if (fromVersion) {
      from = fromVersionList.find((a) =>
        StringUtils.equalsIgnoreType(a.appReleaseId, fromVersion)
      );
    }

    //1. APP 저장
    saveRelease(body)
      .then((app) => {
        newApp = app;
        //2. 데이터 모델 복사 및 복사 목록 가져오기
        if (from) {
          return succeedDataModelList(app, from);
        } else {
          //참조 버전이 없으면 초기화 하고 종료
          callBack();
          Popup.close();
        }
      })
      .then((data) => {
        newDataModelObject = { ...data };
        //4. 이전버전 app의 프로그램 목록 가져옴
        return getPrevProgramList(from);
      })
      .then((programList) => {
        //5. 각 프로그램 내용에 변경된 데이터 모델 내용 적용
        setLoadingMessage("Save after applying new changes to the program.");
        newProgramList = ProgramService.succeedDataModelToProgramContent(
          newApp,
          newDataModelObject,
          programList
        );
        //6. 변경된 프로그램 목록 저장
        return succeedWorkflow(newApp, newProgramList);
      })
      .then(saveNewProgramList)
      .then((result) => {
        Message.alert("Deploy Successfully.", Enums.MessageType.SUCCESS);
        callBack();
        Popup.close();
      })
      .catch((err) => {
        //실패시 롤백
        setIsLoading(false);
        setLoadingMessage("Loading...");
        Message.alert(
          "Deployment has been stopped due to an error. " + err.message,
          Enums.MessageType.ERROR
        );
        //app 삭제
        if (newApp) AppService.deleteRelease(newApp);
        // 데이터 모델 삭제
        if (
          !ObjectUtils.isEmpty(newDataModelObject) &&
          Object.keys(newDataModelObject.dataModelMapper).length > 0
        ) {
          const dataModelIds = Object.values(
            newDataModelObject.dataModelMapper
          );
          DataModelService.deleteDataModelList({ dataModelIds });
        }
        //프로그램은 Program Content 패치를 FE 단에서 하기 때문에 별도로 롤백을 진행하지 않음
      });
  };

  const saveRelease = (body) => {
    return new Promise((resolve, reject) => {
      AppService.saveRelease(
        body,
        (res) => {
          resolve(res.data);
        },
        (err) => reject(err)
      );
    });
  };
  /**
   *
   * @param {*} appInfo
   * @returns {Array} 복사한 데이터 모델 목록
   */
  const succeedDataModelList = (appInfo, from) => {
    //2. 데이터 모델 복사
    //기존 버전의 데이터 모델 목록을 신규 버전으로 새로 저장한 목록을 불러옴
    setLoadingMessage("Create Data Model.");
    return new Promise((resolve, reject) => {
      const body = {
        from,
        to: appInfo,
      };
      DataModelService.succeedDataModelList(
        body,
        (res) => {
          resolve(res.data);
        },
        (err) => reject(err)
      );
    });
  };

  /**
   * 가져오고자할 버전의 프로그램 목록 호출
   * @param {*} from
   * @returns
   */
  const getPrevProgramList = (from) => {
    setLoadingMessage("Fetching the list of previous programs");
    return new Promise((resolve, reject) => {
      ProgramService.getProgramAllList(
        { ...from, tenantId: "*", coCd: "*" },
        (res) => {
          resolve(res.data);
        },
        (err) => reject(err)
      );
    });
  };

  /**
   * 데이터 모델 ID와 서비스 ID를 업데이트 한 프로그램 목록 저장
   * @param {*} programList
   * @returns
   */
  const saveNewProgramList = (programList) => {
    programList = programList.map((program) => {
      program.programContent = JSON.stringify(program.programContent);
      return program;
    });
    return new Promise((resolve, reject) => {
      ProgramService.saveProgramList(
        { programList },
        (res) => resolve(res.data),
        (err) => {
          reject(err);
        }
      );
    });
  };

  /**
   * 워크플로우 상속
   * @param {*} to
   * @param {*} programList
   * @returns
   */
  const succeedWorkflow = async (to, programList) => {
    setLoadingMessage("Applying the Workflow.");
    return new Promise(async (resolve, reject) => {
      const _programList = [];
      try {
        for (const program of programList) {
          const serviceUidList = JsonUtils.findNodeValues(
            program,
            "serviceUid"
          );
          if (ArrayUtils.isEmpty(serviceUidList)) {
            _programList.push(program);
          } else {
            await WorkflowService.succeedWorkflow(
              { to, serviceUidList },
              (res) => {
                const { serviceUidMapper } = res.data;
                const _wfSucceededProgram = ProgramService.succeedServiceUid(
                  serviceUidMapper,
                  program
                );
                _programList.push(_wfSucceededProgram);
              }
            );
          }
        }
        resolve(_programList);
      } catch (error) {
        reject(error);
      }
    });
  };

  /**
   * 모듈 만드는 팝업 오픈
   * @param {*} e
   */
  const onClickModulePopup = (e) => {
    if (moduleCreatePopup) {
      moduleCreatePopup(e, selectedApp);
    }
  };

  return (
    <Modal>
      {isLoading && (
        <div
          className="data-load-wrapper"
          style={{ background: "#d3d3d385", zIndex: 20 }}
        >
          <div className="data-load-box">
            <CircularProgress color="inherit" size={13} />
            &nbsp;&nbsp;&nbsp; {loadingMessage}
          </div>
        </div>
      )}

      <Modal.Header title="Deploy New Appliacation" />
      <Modal.Body>
        <UmodalTemplate>
          <Row className="gy-3">
            <Form.Group as={Col} xs={12}>
              <Form.Label className="required">Select Application</Form.Label>
              <Form.Select value={selectedApp} onChange={onChangeApp}>
                <option value="">Select</option>
                {appList.map((app) => {
                  return (
                    <option value={app.appId} key={app.appId}>
                      {app.appNm}
                    </option>
                  );
                })}
              </Form.Select>
            </Form.Group>
            <Form.Group as={Col} xs={12}>
              <Form.Label className="required">Select Module</Form.Label>
              <ModuleSelector
                type="u"
                value={selectedModule}
                onChange={onChangeModule}
                showEmpty={true}
                disabled={!selectedApp}
                placeholder={
                  selectedApp ? "Select" : "Please select an Application."
                }
                appId={selectedApp}
                moduleCreatable
                moduleCreateCb={onClickModulePopup}
              />
            </Form.Group>
            <Form.Group as={Col} xs={12}>
              <Form.Label
                onClick={(e) => setIsExpandModelSearch(!isExpandModelSearch)}
                style={{ cursor: "pointer" }}
              >
                DataModel Search Prefix {isExpandModelSearch ? "▲" : "▼"}
              </Form.Label>

              <Tooltip
                title={
                  <span style={{ fontSize: "larger" }}>
                    Enter the prefixes of the tables to include or exclude for
                    the data model search. If not set, the list will be called
                    according to each module code.
                  </span>
                }
                placement={"right"}
              >
                <span>
                  <AiOutlineQuestionCircle />
                </span>
              </Tooltip>
              {isExpandModelSearch ? (
                <Row className="gy-2">
                  <Col xs={3}>
                    <Form.Control disabled defaultValue={"Include"} />
                  </Col>
                  <Col xs={9}>
                    <Form.Control
                      placeholder="Enter Prefix to search (classify with ' , ' )"
                      value={optionIn}
                      onChange={(e) => setOptionIn(e.target.value)}
                    />
                  </Col>
                  <Col xs={3}>
                    <Form.Control disabled defaultValue={"Exclude"} />
                  </Col>
                  <Col xs={9}>
                    <Form.Control
                      placeholder="Enter Prefix NOT to search(classify with ' , ')"
                      value={optionNotIn}
                      onChange={(e) => setOptionNotIn(e.target.value)}
                    />
                  </Col>
                  {!optionIn && !optionNotIn && (
                    <Col xs={12} style={{ color: "tomato" }}>
                      Search All Table : Enter "*" <br />
                    </Col>
                  )}
                </Row>
              ) : (
                <></>
              )}
            </Form.Group>
            <Form.Group as={Col} xs={12}>
              <Form.Label className="required">Enter Version</Form.Label>
              <Form.Control
                placeholder={
                  selectedModule
                    ? "Enter Version.. ex)1.0 or 2.1 ..."
                    : "Please select Module"
                }
                disabled={!selectedModule}
                value={version}
                onChange={(e) => setVersion(e.currentTarget.value)}
              />
            </Form.Group>
            <Form.Group as={Col} xs={12}>
              <Form.Label>Select version to reference</Form.Label>
              <InputGroup>
                <Form.Select
                  disabled={!version}
                  value={fromVersion}
                  onChange={onChangeTargetVersion}
                >
                  <option value="">Select</option>
                  {fromVersionList.map((version) => {
                    return (
                      <option
                        value={version.appReleaseId}
                        key={version.appReleaseId}
                      >
                        Version : {version.version}{" "}
                        &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp; Deploy Date{" "}
                        {CommonUtils.getDate(version.releaseDate)}
                      </option>
                    );
                  })}
                </Form.Select>
                <Button onClick={onGetReleaseNotes}>
                  Load Revision Detail
                </Button>
              </InputGroup>
            </Form.Group>
            <Form.Group as={Col} xs={12}>
              <Form.Label className="required">Version Description</Form.Label>
              <Form.Control
                disabled={!version}
                maxLength={499}
                value={briefDesc}
                onChange={(e) => setBriefDesc(e.currentTarget.value)}
                placeholder="Enter the description of deploying version."
              />
            </Form.Group>
            <Form.Group as={Col} xs={12}>
              <Form.Label className="required">Revision Content</Form.Label>
              <Form.Control
                as="textarea"
                rows={10}
                style={{ resize: "none" }}
                value={releaseNotes}
                onChange={(e) => setReleaseNotes(e.currentTarget.value)}
              />
            </Form.Group>
          </Row>
        </UmodalTemplate>
      </Modal.Body>
      <Modal.Footer>
        <Modal.Footer.ProgressButton
          variant="success"
          onClick={onSaveRelease}
          doing={isLoading}
          doingText="Running"
        >
          Submit
        </Modal.Footer.ProgressButton>
      </Modal.Footer>
    </Modal>
  );
}

export default AppReleasePopup;
