import axios from "axios";
import { DateWhoColumns, Enums } from "components/builder/BuilderEnum";
import { camelCase } from "lodash";

const {
  BaseService,
  ArrayUtils,
  StringUtils,
  DaafMessage,
} = require("@alpha/com.bizentro.daaf.front.framework");

class TrdService extends BaseService {
  static url = process.env.REACT_APP_DATA_STUDIO_API_URL + "/trd";
  // static api = new BaseService(this.url + "/trd");

  static sendPost = async (api, param) => {
    return (await axios.post(this.url + api, param)).data;
  };

  /**
   * Trd 목록 호출
   * @param {*} data
   * @returns
   */
  static getTrdList = async (data) => {
    return this.sendPost("/getTrdList", data);
  };

  /**
   * 단일 TRD 호출
   * @param {*} data
   * @returns
   */
  static getTrd = (data) => {
    return this.sendPost("/getTrd", data);
  };

  /**
   * trd 정보에 맞춰서 테이블만 가져오는 함수
   * @param {*} trd
   * @returns
   */
  static getTableList = (trd) => {
    return this.sendPost("/getTableList", trd);
  };

  /**
   * 테이블 데이터에 맞춰서 필드(컬럼)목록과 릴레이션을 가져오는 함수
   * @param {*} trdTable
   * @returns
   */
  static getTableInfo = (trdTable) => {
    return this.sendPost("/getTableInfo", trdTable);
  };

  /**
   * 테이블 데이터에 맞춰서 필드(컬럼)목록과 릴레이션을 가져오는 함수
   * 테이블 복수개
   * @param {*} trdTable
   * @returns
   */
  static getTableListInfo = (trdTable) => {
    return this.sendPost("/getTableListInfo", trdTable);
  };

  /**
   * 테이블 정보를 DataModel Entity에 맞게 제작
   * @param {*} TrdTable
   * @returns
   */
  static convetTrdTableToEntity = (TrdTable) => {
    const { tablePhysicalNm, tableLogicalNm, tableMstId } = TrdTable;

    // DataModel Entity 설정
    const entity = {
      dataModelId: null,
      physEntityNm: tablePhysicalNm,
      logEntityNm: tableLogicalNm || tablePhysicalNm,
      tableNm: tablePhysicalNm,
      insertableYn: "Y",
      updatableYn: "Y",
      deletableYn: "Y",
      relation: [],
      whereJoinTable: null,
      entityType: "TABLE",
      type: Enums.EntityComponentType.ENTITY,
      remark: {
        tableMstId: tableMstId,
      },
    };

    return entity;
  };

  /**
   * 필드와 relation 정보를 DataModel Entity에 맞게 변환
   * @param {*} tableInfo
   */
  static convertFieldAndRelationForEntity = (tableInfo, entity) => {
    const { trdTableField, relation } = tableInfo;

    //필드의 모든 항목이 Element가 준비되지 않으면 리턴 시킴
    const isElementReady = trdTableField.every((f) => f.element);
    if (!isElementReady) {
      DaafMessage.alert(
        "해당 테이블의 컬럼에 Element가 매핑되지 않은 부분이 있습니다.",
        "warning"
      );
      return false;
    }
    /**
     * Datamodel relation으로 전환
     */
    const dmRelation = [];

    if (ArrayUtils.isArray(relation)) {
      for (const rel of relation) {
        const { joinColumns, targetTable } = rel;
        const relationObj = {
          type: "oneToMany",
          fetch: "LAZY",
          cascade: "ALL",
          targetEntity: targetTable.tablePhysicalNm,
          targetTable: targetTable.tablePhysicalNm,
          joinColumns: [],
        };

        // joinColumns setting
        for (const jc of joinColumns) {
          const obj = {};
          obj[jc.fromField.element.elementCd] = jc.toField.element.elementCd;
          relationObj.joinColumns.push(obj);
        }
        dmRelation.push(relationObj);
      }
    }

    const dataModelEntityFields = trdTableField.map((field) => {
      const obj = { ...field };
      const {
        pkYn,
        uniqueYn,
        notNullYn,
        autoIncrementYn,
        fieldDefaultValue,
        element,
      } = obj;

      const entityFieldPhysicalNm = element.elementCd;
      const entityFieldLogicalNm = element.elementNm;
      const domain = element.domain;
      const dataType = domain.dataType;

      let uiDisplayType = "";
      if (
        StringUtils.includesIgnoreCase(dataType, [
          "char",
          "nChar",
          "varChar",
          "nVarChar",
        ])
      ) {
        uiDisplayType = "string";
      } else if (
        StringUtils.includesIgnoreCase(dataType, [
          "integer",
          "smallInt",
          "bigInt",
          "number",
          "real",
          "float",
          "double",
          "decimal",
        ])
      ) {
        uiDisplayType = "number";
      } else if (
        StringUtils.includesIgnoreCase(dataType, [
          "date",
          "time",
          "timeZone",
          "timeStamp",
        ])
      ) {
        uiDisplayType = "date";
      }

      const isDateWhoColumn = StringUtils.includesIgnoreCase(
        entityFieldPhysicalNm,
        DateWhoColumns
      );

      const entityField = {
        compId: StringUtils.getUuid(),
        type: Enums.EntityComponentType.ENTITY_FIELD,
        physFieldNm: camelCase(entityFieldPhysicalNm),
        logFieldNm: entityFieldLogicalNm,
        columnNm: entityFieldPhysicalNm,
        columnType: `${String(domain.dataType).toUpperCase()}${
          domain.dataLength ? `(${domain.dataLength})` : ""
        }`,
        autoIncrementYn: autoIncrementYn,
        pkYn: pkYn,
        requiredYn: notNullYn,
        uniqueYn: uniqueYn,
        readonlyYn: "N",
        uiDisplayType: uiDisplayType,
        formula: "",
        fieldOption: {},
        alias: "",
        virtualYn: "N",
        insertableYn: "Y",
        insertDefault: isDateWhoColumn ? `{currentTime}` : fieldDefaultValue,
        updatableYn: "Y",
        updateDefault: isDateWhoColumn ? `{currentTime}` : fieldDefaultValue,
      };

      return entityField;
    });

    return { ...entity, relation: dmRelation, dataModelEntityFields };
  };

  /**
   * 테이블 데이터에 맞춰서 필드(컬럼)목록과 릴레이션을 가져오는 함수
   * 테이블 복수개
   * @param {*} trdTable
   * @returns
   */
  static getTableFieldList = (refFieldIdList) => {
    return this.sendPost("/getTableFieldList", refFieldIdList);
  };

  /**
   * appEnvId, tablePhysicalNm, (element)physicalNm 으로 (element)logicalNm 가져오는 함수
   * @param {*} data
   * @param {*} callbackFnc
   * @param {*} errCallbackFnc
   */
  static getTableColumnLabelName(data) {
    return this.sendPost("/getTableColumnLabelName", data);
  }

  /**
   * trd 정보 없이 app_env_id로만 table list 조회하는 함수 (importTableId는 null인 테이블만)
   * @param {*} data
   * @param {*} callbackFnc
   * @param {*} errCallbackFnc
   */
  static getTableListWithoutTrd(data) {
    return this.sendPost("/getTableListWithoutTrd", data);
  }
}

export default TrdService;
