import {
  Grid,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Modal from "components/common/modal/UModal";
import StringUtils from "components/common/utils/StringUtils";
import Message from "components/common/Message";
import { Button, Form } from "react-bootstrap";
import UmodalTemplate from "components/common/modal/UModalTemplate";
import ViewportService from "services/mobile/ViewportService";
import * as Enums from "components/builder/BuilderEnum";
import User from "components/common/utils/UserUtils";
import WijmoGrid from "components/common/element/WijmoGrid";
import { BiEditAlt } from "react-icons/bi";
import { RiDeleteBinLine } from "react-icons/ri";
import { MessageType } from "components/builder/BuilderEnum";

function ViewportEditPopup(props) {
  const { callbackFnc } = props;
  const [viewportId, setViewportId] = useState();
  const [deviceNm, setDeviceNm] = useState();
  const [screenWidth, setScreenWidth] = useState();
  const [screenHeight, setScreenHeight] = useState();
  const [sortSeq, setSortSeq] = useState();
  const [appViewportList, setAppViewportList] = useState();
  const [addFieldHidden, setAddFieldHidden] = useState(
    StringUtils.defaultString(props.addButtonHidden, true)
  );
  const [saveButtonNm, setSaveButtonNm] = useState();

  useEffect(() => {
    getAppViewportList();
  }, []);

  const getAppViewportList = () => {
    let params = {appId:props.appId};
    ViewportService.getAppViewportList(params, (res) => {
      setAppViewportList(res.data);
    });
  };


  const columns = [
    {
      field: "deviceNm",
      headerName: "Device Name"
    },
    {
      field: "screenWidth",
      headerName: "Width",
      width: 100
    },
    {
      field: "screenHeight",
      headerName: "Height",
      width: 100
    },
    {
      field: "sortSeq",
      headerName: "정렬 순서",
      width: 100
    },
    {
      field: "update",
      headerName: "수정",
      headerAlign: "center",
      align: "center",
      width: 80,
      renderCell: (param) => {
        return (
          <Button
            size="sm"
            variant="outline-success"
            onClick={(e) => onEdit(e, param)}
          >
            <BiEditAlt />
          </Button>
        );
      },
    },
    {
      field: "remark",
      headerName: "삭제",
      headerAlign: "center",
      align: "center",
      width: 80,
      renderCell: (param) => {
        return (
          <Button
            size="sm"
            variant="outline-danger"
            onClick={(e) => onDelete(e, param)}
          >
            <RiDeleteBinLine />
          </Button>
        );
      },
    },
  ];

  const validation = () => {
    if (StringUtils.isEmpty(deviceNm))
      return Message.alert("device 이름을 입력해주세요", Enums.MessageType.WARN);
    if (StringUtils.isEmpty(screenWidth))
      return Message.alert("width를 입력해주세요", Enums.MessageType.WARN);
    if (StringUtils.isEmpty(screenHeight))
      return Message.alert("height를 입력해주세요", Enums.MessageType.WARN);
    if (StringUtils.isEmpty(sortSeq))
      return Message.alert("정렬 순서를 입력해주세요", Enums.MessageType.WARN);
  };

  const onSave = () => {

    validation();
    
    const body = {
      viewportId,
      appId: props.appId,
      deviceNm,
      screenWidth,
      screenHeight,
      sortSeq,
      useYn: "Y",
      insertUserId: User.getId(),
      updtUserId: User.getId()
    };
    
    ViewportService.saveViewport(body, (res) => {
      // const { isSuccess, message } = res.data;

      getAppViewportList();
      setAddFieldHidden(true);
      clearField();

      // if (isSuccess === "Y") {
      //   Message.alert(message, Enums.MessageType.SUCCESS);
      //   // Popup.close();
      //   if (callbackFnc) {
      //     callbackFnc();
      //   }
      // } else {
      //   Message.alert(message, Enums.MessageType.ERROR);
      // }
    });
  };

  const onEdit = (e, app) => {
    if (e) e.preventDefault();
    setAddFieldHidden(false);
    setSaveButtonNm("Save");

    setViewportId(app.viewportId);
    setDeviceNm(app.deviceNm);
    setScreenHeight(app.screenHeight);
    setScreenWidth(app.screenWidth);
    setSortSeq(app.sortSeq);
  };

  const onDelete = (e, app) => {
    Message.confirm(`${app.deviceNm}을 삭제 하시겠습니까?`, () => {
      ViewportService.deleteViewport(app.viewportId, (res) => {
        Message.alert(res.message, MessageType.SUCCESS);
        setAddFieldHidden(true);
        clearField();
        getAppViewportList();
      });
    });
  };

  const onDisplayAddForm = () => {
    setAddFieldHidden(false);
    setSaveButtonNm("Add");
    clearField();
  };

  const onHiddenAddForm = () => {
    setAddFieldHidden(true);
    clearField();
  };

  const clearField = () => {
    setViewportId("");
    setDeviceNm("");
    setScreenHeight("");
    setScreenWidth("");
    setSortSeq("");
  }


  return (
    <Modal>
      <Modal.Header title="Viewport Edit" />
      <Modal.Body>
        <Form>
          <Button 
            className="mobile-viewport-add"
            hidden={!addFieldHidden}
            onClick={onDisplayAddForm}
          >
            Add custom device
          </Button>
          <UmodalTemplate
            hidden={addFieldHidden}
          >
            <Form.Group className="mb-3">
              <Form.Label className="required">Device</Form.Label>
              <Form.Control
                value={deviceNm}
                onChange={(e) => setDeviceNm(e.currentTarget.value)}
              />
              <Form.Text></Form.Text>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label className="required">Width</Form.Label>
              <Form.Control
                value={screenWidth}
                onChange={(e) => setScreenWidth(e.currentTarget.value)}
              />
              <Form.Text></Form.Text>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label className="required">Height</Form.Label>
              <Form.Control
                value={screenHeight}
                onChange={(e) => setScreenHeight(e.currentTarget.value)}
              />
              <Form.Text></Form.Text>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label className="required">정렬 순서</Form.Label>
              <Form.Control
                value={sortSeq}
                onChange={(e) => setSortSeq(e.currentTarget.value)}
              />
              <Form.Text></Form.Text>
            </Form.Group>
            <Form.Group className="mb-3">
              <Button 
                className="mobile-viewport-cancel"
                onClick={onHiddenAddForm}
              >
                Cancel
              </Button>
              <Button 
                className="mobile-viewport-add"
                onClick={onSave}
              >
                {saveButtonNm}
              </Button>
            </Form.Group>
          </UmodalTemplate>
          <Grid item xs={12} height={300}>
            <WijmoGrid
              columns={columns}
              rows={appViewportList}
              getRowId={(row) => row.viewportId}
              pageSize={100}
              rowsPerPageOptions={[100]}
              headerHeight={30}
              rowHeight={50}
              hideFooter={true}
            />
          </Grid>
        </Form>
      </Modal.Body>
      <Modal.Footer>
          {/* <Modal.Footer.Button onClick={onSaveViewport}>
              저장
          </Modal.Footer.Button> */}
      </Modal.Footer>
    </Modal>
  )
}

export default ViewportEditPopup;