import Modal from "components/common/modal/UModal";
import UModalTemplate from "components/common/modal/UModalTemplate";
import StringUtils from "components/common/utils/StringUtils";
import React from "react";
import { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";

function CodeReferencePopup({ codeType, ...props }) {
  const [selectedTab, setSelectedTab] = useState(codeType || "java");

  return (
    <Modal>
      <Modal.Header title="Workflow Code Guidelines" />
      <Modal.Body>
        <UModalTemplate>
          <h4>1. Example by Code Type</h4>
          <p>
            The execution result of the code is assigned to the Output Object,
            and the method to receive results by code type is as follows.
          </p>
          <Row className="mb-3">
            <Col xs={2} style={{ display: "flex", alignItems: "center" }}>
              <Form.Label>Select Code Type</Form.Label>
            </Col>
            <Col>
              <Form.Select
                value={selectedTab}
                onChange={(e) => setSelectedTab(e.target.value)}
                id={"codeType"}
              >
                <option value={"java"}>Java Expression Language</option>
                <option value={"batch"}>Window Batch Command</option>
                <option value={"shell"}>Linux/Unix Shell Script</option>
              </Form.Select>
            </Col>
          </Row>
          {StringUtils.equalsIgnoreCase(selectedTab, "java") ? (
            <>
              <p>
                The result of the last executed statement is assigned to the
                'Output Object'.
              </p>
              <Form.Label>Example - Code</Form.Label>
              <code>
                let message = resultMessage.get("message");
                <br />
                out.println(resultMessage);
                <br />
                {`if(message == null || message.isEmpty()){
    message = [...];
    out.println("Reset : "+message);
}`}
                <br />
                message.add(callProcedure.get("message"));
                <br />
                out.println("After Addition :"+message); <br />
                ## The message field value of the resultMessage entity,
                continuously adds messages. <br />
                resultMessage["message"] = message;
                <br />
                out.println(resultMessage); <br />
                <br />
                ## The value of the last statement is returned and assigned to
                the "Output Object." <br />
                resultMessage;
              </code>
              <Form.Label>Example - Return value (Output Object)</Form.Label>
              <code>
                # This is the value assigned to the Output Object.
                <br />
                resultMessage;
              </code>
              <br />
              <h4>2. Jexl Expression</h4>
              <p>
                Before the code is executed, some code characters can be
                dynamically changed through the Jexl expression.
              </p>
              <p>Expression : {`{\`Jexl Expression\`}`}</p>
              <code>
                {`//orderEntity.get("qty") * orderEntity.get("unitPrice") The execution result will be replaced `}
                <br />
                {`//let amount = 2500;`} <br />
                let amount ={" "}
                {`{\`orderEntity.get("qty") * orderEntity.get("unitPrice")\`}`};
              </code>
              <h4>3. Code Reference Contents</h4>
              <Form.Label>Math</Form.Label>
              <code>
                {`// Greater value = 10`}
                <br />
                {`Math.max(5, 10);`}
                <br />
                {`// Rounding Method`}
                <br />
                {`Math.round(5.7);`}
              </code>
              <div>
                <Button
                  side="left"
                  onClick={(e) => {
                    window.open(
                      "https://www.w3schools.com/java/java_math.asp",
                      "_blank"
                    );
                  }}
                >
                  Java Math Methods
                </Button>
              </div>
              <br />
              <Form.Label>Integer</Form.Label>
              <code>
                {`// Maximum value of data type Int`}
                <br />
                {`Integer.MAX_VALUE();`}
                <br />
                {`// Data Type Int -> String`}
                <br />
                {`var intToString = 10;`}
                <br />
                {`Integer.toString(intToString);`}
              </code>
              <div>
                <Button
                  side="left"
                  onClick={(e) => {
                    window.open(
                      "https://learn.microsoft.com/ko-kr/dotnet/api/java.lang.integer?view=net-android-34.0",
                      "_blank"
                    );
                  }}
                >
                  Java Integer Methods
                </Button>
              </div>
              <br />
              <Form.Label>String</Form.Label>
              <code>
                {`var firstName = "John"`}
                <br />
                {`var lastName = "Doe"`}
                <br />
                {`// firstName.concat(lastName) = John Doe`}
                <br />
                {`// string1.concat(string2) : The function that copies string2 and appends it to string1`}
                <br />
                {`var name = firstName.concat(lastName);`}
              </code>
              <div>
                <Button
                  side="left"
                  onClick={(e) => {
                    window.open(
                      "https://www.w3schools.com/java/java_ref_string.asp",
                      "_blank"
                    );
                  }}
                >
                  Java String Methods
                </Button>
              </div>
            </>
          ) : StringUtils.equalsIgnoreCase(selectedTab, "batch") ? (
            <>
              <p>
                All output generated from batch execution is assigned to the
                "Output Object."
              </p>
              <Form.Label>Example - Code</Form.Label>
              <code>
                ## The output content from all entered commands is combined and
                returned.
                <br />
                ## Example1 Move Folder Location cd C:\
                <br />
                <br />
                ## Example2 Command input with output ipconfig
                <br />
                <br />
                ## Example 3: Command input with output netstat -ano
                <br />
              </code>
              <Form.Label>Example - Return value (Output Object)</Form.Label>
              <code>
                {`Wireless LAN Adapter Wi-Fi:
Media State . . . . . . . . : Media Disconnected
Connection DNS Suffix. . . . :
Wireless LAN Adapter Local Area Connection* 1:
Media State . . . . . . . . : Media Disconnected
...(etc)

Active Connection

Protocol Local Address External Address State PID

TCP 0.0.0.0:135 0.0.0.0:0 LISTENING 1012
TCP 0.0.0.0:445 0.0.0.0:0 LISTENING 4
TCP 0.0.0.0:3000 0.0.0.0:0 LISTENING 12532
            
          ...(etc)
                `}
              </code>
            </>
          ) : (
            <></>
          )}
        </UModalTemplate>
      </Modal.Body>
      <Modal.Footer>
        {StringUtils.equalsIgnoreCase(selectedTab, "java") ? (
          <Modal.Footer.Button
            side="left"
            onClick={(e) => {
              window.open(
                "https://commons.apache.org/proper/commons-jexl/reference/syntax.html",
                "_blank"
              );
            }}
          >
            JEXL Official Website
          </Modal.Footer.Button>
        ) : (
          <></>
        )}
      </Modal.Footer>
    </Modal>
  );
}

export default CodeReferencePopup;
