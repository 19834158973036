import Api from "components/common/Api";

class ViewportService {
    static requestMapping = "/mobile";

  /**
   * viewport 목록 호출
   * @param {*} data
   * @param {*} callbackFnc
   * @param {*} errCallbackFnc
   */
  static getViewportList(data, callbackFnc, errCallbackFnc) {
    Api.post(
      this.requestMapping + "/viewportList",
      data,
      callbackFnc,
      errCallbackFnc
    );
  }

  /**
   * app에 등록된 viewport 목록 호출
   * @param {*} data
   * @param {*} callbackFnc
   * @param {*} errCallbackFnc
   */
  static getAppViewportList(data, callbackFnc, errCallbackFnc) {
    Api.post(
      this.requestMapping + "/appViewportList",
      data,
      callbackFnc,
      errCallbackFnc
    );
  }

  /**
   * viewport 저장
   * @param {*} data
   * @param {*} callbackFnc
   * @param {*} errCallbackFnc
   */
  static saveViewport(data, callbackFnc, errCallbackFnc) {
    Api.post(
      this.requestMapping + "/saveViewport",
      data,
      callbackFnc,
      errCallbackFnc
    );
  }

  /**
   * viewport 삭제
   * @param {*} data
   * @param {*} callbackFnc
   * @param {*} errCallbackFnc
   */
  static deleteViewport(id, callbackFnc, errCallbackFnc) {
    // Api.get(
    //   this.requestMapping + "/deleteViewport/"+data.viewportId,
    //   data,
    //   callbackFnc,
    //   errCallbackFnc
    // );
    Api.delete(
      this.requestMapping + "/deleteViewport",
      id,
      callbackFnc,
      errCallbackFnc
    )
  }
}
export default ViewportService;