/* eslint-disable jsx-a11y/alt-text */
import React, { Fragment } from "react";

import Modal from "components/common/modal/UModal";

import UmodalTemplate from "components/common/modal/UModalTemplate";

const TemplateSyntaxGuide = (props) => {
  return (
    <Modal>
      <Modal.Header title={props.title} />
      <Modal.Body>
        <UmodalTemplate>
          <Fragment>
            <h2 id="if--else">if / else</h2>
            <p>The 'if ~ else' usage is as follows.</p>
            <div>
              <pre>
                <code>
                  &lt;!-- If reqStatusCd value is 'CHG_REV', checkbox is show.
                  --/&gt; <br></br>
                  &#123;&#123;if reqStatusCd == "CHG_REV" &#125;&#125;<br></br>
                  {"    "}
                  &lt;input type="radio"
                  id="radio_$&#123;$item.getCurrTmplRowIndex()&#125;"
                  name="radio" /&gt;
                  <br></br>
                  &#123;&#123;/if&#125;&#125;<br></br>
                </code>
              </pre>
            </div>
            <pre>
              <code>
                &lt;!-- If messageCode value is Empty --/&gt; <br></br>
                &#123;&#123;if !$item.utils.isEmpty(messageCode) &#125;&#125;
                <br></br>
                {"    "}
                <span>&lt;li&gt; $&#123;messageCode&#125; &lt;/li&gt;</span>
                <br></br>
                &#123;&#123;else&#125;&#125;<br></br>
                {"    "}
                <span>&lt;li&gt; message code is empty &lt;/li&gt;</span>
                <br></br>
                &#123;&#123;/if&#125;&#125;<br></br>
              </code>
            </pre>
            <pre>
              <code>
                &lt;!-- Change the badge color based on the todo number. --/&gt;{" "}
                <br></br>
                &lt;span class="badge" style=" <br></br>
                &#123;&#123;if toDoCount &gt; 150&#125;&#125; <br></br>
                {"    "}
                background-color: #dd4b39;
                <br></br>
                &#123;&#123;else toDoCount &gt; 100&#125;&#125;<br></br>
                {"    "}
                background-color: #ded9d9;
                <br></br>
                &#123;&#123;else toDoCount &gt; 50&#125;&#125; <br></br>
                {"    "}
                background-color: #00a65a;
                <br></br>
                &#123;&#123;else&#125;&#125; <br></br> {"    "}
                background-color: #0073b7;
                <br></br>
                &#123;&#123;/if&#125;&#125; <br></br>float:
                right;"&gt;$&#123;toDoCount&#125;&lt;/span&gt;
              </code>
            </pre>
            <h2 id="html">html</h2>
            <p>Print html Tag as String.</p>
            <div>
              <pre>
                <code>
                  &lt;!-- Print by applying HTML TAG --/&gt; <br></br>
                  &#123;&#123;html <span>&lt;li&gt;</span>outputHtml
                  <span>&lt;/li&gt;</span>&#125;&#125;
                </code>
              </pre>
            </div>
            <div>
              <pre>
                <code>
                  &lt;!-- Display the image on the screen --/&gt; <br></br>
                  &#123;&#123;if !$item.utils.isEmpty(fileId)&#125;&#125;
                  <br></br>
                  {"    "}
                  &#123;&#123;html
                  $item.utils.getImageTagById(fileId,&#123;noImageType :
                  'base64' ,noImage:'data:image/gif;
                  base64,R0lGODlhAQABAAAAACwAAAAAAQABAAA='&#125;)&#125;&#125;
                  <br></br>
                  &#123;#123;/if&#125;&#125;
                </code>
              </pre>
            </div>
            <h2 id="each">each</h2>
            <p>
              Perform a loop for all elements in the collection. <br></br>
            </p>
            <div>
              <pre>
                <code>
                  &#123;&#123;each(i,payment) paymentList&#125;&#125;<br></br>
                  {"    "}
                  <span>&lt;li&gt;</span>$&#123;i + 1&#125; th Data
                  <span>&lt;/li&gt;</span>
                  <br></br>
                  {"    "}
                  <span>&lt;li&gt;</span>$&#123;payment.payItemNm&#125;
                  <span>&lt;/li&gt;</span>
                  <br></br>
                  &#123;&#123;/each&#125;&#125;
                </code>
              </pre>
            </div>
            <h2 id="html">Common and Util Function</h2>
            <p>
              Commonly used Common ($item.xxx) and Util functions
              ($item.utils.xxxx) are as follows.
            </p>
            <table className="doc-table">
              <thead>
                <tr>
                  <th style={{ width: "20%" }}>
                    <div>Function Name</div>
                  </th>
                  <th style={{ width: "20%" }}>
                    <div>Parameters</div>
                  </th>
                  <th>
                    <div>Description &amp; Example</div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div>getCurrTmplRowIndex</div>
                  </td>
                  <td>
                    <div>None</div>
                  </td>
                  <td>
                    <div>
                      Data's Current Row index<br></br>
                      <code>
                        $&#123;$item.<b>getCurrTmplRowIndex</b>()&#125;
                      </code>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div>defaultValue</div>
                  </td>
                  <td>
                    <div>
                      1.Value<br></br>2.Replace Value ('' if null)
                    </div>
                  </td>
                  <td>
                    <div>
                      - If briefDesc value is null, display appDesc. <br></br>
                      <code>
                        $&#123;$item.utils.<b>defaultValue</b>(briefDesc,
                        appDesc)&#125;
                      </code>
                      - If briefDesc value is null, display ''.
                      <br></br>(Since null or undefined may be displayed
                      depending on the data.)
                      <br></br>
                      <code>
                        $&#123;$item.utils.<b>defaultValue</b>(briefDesc)&#125;
                      </code>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div>setCurrentDateFormat</div>
                  </td>
                  <td>
                    <div>1.Date Value</div>
                  </td>
                  <td>
                    <div>
                      - Format reqDate according to the User's date format.{" "}
                      <br></br>
                      <code>
                        $&#123;$item.utils.Date.<b>setCurrentDateFormat</b>
                        (reqDate)&#125;
                      </code>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div>formatString</div>
                  </td>
                  <td>
                    <div>
                      1.Number Value<br></br>2.Decimal places
                    </div>
                  </td>
                  <td>
                    <div>
                      - Number Notation <br></br>
                      <code>
                        $&#123;$item.utils.Number.<b>formatString</b>
                        (amount)&#125;
                      </code>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            {/* <br></br>※ 기타 유틸 함수는 사용자 가이드 ☆ 실행 환경-JavaScript
            함수 > Utils 를 확인하십시요. (작업예정)
            <br></br>※ 사용자 정의 함수 사용가이드 작성 예정 */}
          </Fragment>
        </UmodalTemplate>
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
};

export default TemplateSyntaxGuide;
