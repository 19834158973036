import Popup from "components/common/Popup";
import NewServicePopup from "page/popup/workflow/NewServicePopup";
import React, { Component } from "react";
import { Form } from "react-bootstrap";
import {
  AiFillEdit,
  AiOutlineFileAdd,
  AiOutlineHistory,
  AiOutlinePartition,
  AiOutlineUnorderedList,
} from "react-icons/ai";
import { connect } from "react-redux";
import { Enums } from "components/builder/BuilderEnum.js";
import WorkflowReduxHelper from "components/builder/workflow/editor/helper/WorkflowReduxHelper.js";
import BuilderSidebarContextMenu from "components/builder/contextMenu/BuilderSidebarContextMenu";
import WorkflowService from "services/workflow/WorkflowService";
import { updateService } from "components/builder/workflow/reducer/WorkflowAction";
import { stopEvent } from "components/builder/ui/editor/handler/UIEditorEventHandler";
import {
  initCommand,
  initDo,
} from "components/builder/ui/reducers/CommandAction";
import ProgramService from "services/ui/ProgramService";
import { initDebug } from "components/builder/workflow/reducer/WorkflowDebugAction";
import { RxOpenInNewWindow } from "react-icons/rx";
import LocalStorageService from "services/common/LocalService";
import User from "components/common/utils/UserUtils";

class WorkflowMenu extends BuilderSidebarContextMenu {
  constructor(props) {
    super(props);
    this.state = {
      searchResultList: [],
      recentWorkList: [],
      dataList: [],
      dataLoading: false,
      searchTerm: "",
    };
    this.onClickNewService = this.onClickNewService.bind(this);
    this.onLoadProgram = this.onLoadProgram.bind(this);
  }

  componentDidMount() {
    this.getRecentMenuList(Enums.LocalStorageName.WORKFLOW_HISTORY);
  }

  onClickNewService() {
    this.goToPage(Enums.BuilderPath.WORKFLOW.BUILDER);

    const callbackFnc = (serviceInfo) => {
      this.props.createNewService(serviceInfo, this.props.workflow);
      Popup.close();
    };

    const options = {
      effect: Popup.ScaleUp, //Effect.SlideFromTop(default)를 Effect.ScaleUp 로 변경
      style: {
        content: {
          width: "600px",
        },
      },
    };
    Popup.open(<NewServicePopup callbackFnc={callbackFnc} />, options);
  }

  onLoadProgram(e, work, cb) {
    stopEvent(e);
    WorkflowService.getService(
      work,
      (res) => {
        const serviceDetail = WorkflowService.setData(res.data);
        this.props.updateService(serviceDetail);
        this.goToPage(Enums.BuilderPath.WORKFLOW.BUILDER);
        if (cb) cb();
      },
      () => {
        if (cb) cb();
      }
    );
  }

  /**새탭에서 열기 */
  onOpenNewTab = (e, workflow) => {
    e.stopPropagation();
    //사용자 정보
    LocalStorageService.set(Enums.LocalStorageName.WORKSPACE, {
      userId: User.getId(),
      workspace: this.workspace,
    });
    const url = `${window.location.protocol}//${window.location.hostname}:${window.location.port}`;
    window.open(
      `${url}/newTabRedirect/workflow/${workflow.serviceUid}`,
      "_blank"
    );
  };

  onLoadDataList() {
    //검색 후보 목록 호출
    this.setState({
      dataLoading: true,
    });
    return new Promise((resolve, reject) => {
      const body = {
        // moduleCd: moduleCd === "*" ? "" : moduleCd,
        appId: this.props.workspace.appId,
        moduleCd: this.props.workspace.moduleCd,
        appReleaseId: this.props.workspace.appReleaseId,
        tenantId: this.props.workspace.tenantId,
        coCd: this.props.workspace.coCd,
      };

      WorkflowService.getServiceList(
        body,
        (res) => {
          if (!res?.isError) {
            const { data } = res;
            const list = ProgramService.filterByTenantIdAndCoCd(
              data,
              body.tenantId,
              body.coCd,
              "workflowId"
            );
            resolve([...list]);
          }
        },
        () => {
          this.setState({
            dataLoading: false,
          });
        }
      );
    });
  }

  goToPage(url = "") {
    this.navigate(Enums.BuilderPath.WORKFLOW.MAIN + "/" + url);
    this.menuClose();
  }

  renderMenuContents() {
    return (
      <div>
        {this.renderTitle(AiOutlinePartition, "Workflow Builder")}
        <div className="menu-list">
          {this.renderContextMenu(AiFillEdit, "Service Editor", (e) =>
            this.goToPage(Enums.BuilderPath.WORKFLOW.BUILDER)
          )}
          {this.renderContextMenu(
            AiOutlineFileAdd,
            "New Service",
            this.onClickNewService
          )}
          {this.renderContextMenu(AiOutlineUnorderedList, "Service List", (e) =>
            this.goToPage(Enums.BuilderPath.WORKFLOW.LIST)
          )}
          {this.renderContextMenu(
            AiOutlineHistory,
            "Service Change List",
            (e) => {
              this.goToPage(Enums.BuilderPath.WORKFLOW.HISTORY);
            }
          )}
        </div>
        <hr />
        <div
          className="sub-title"
          onClick={(e) => this.navigate(Enums.BuilderPath.WORKFLOW.MAIN)}
        >
          Search Service
        </div>
        <div className="search-area">
          <Form.Control
            className="sub-input"
            placeholder="Enter Service name."
            onChange={(e) => this.onSearchData(e, ["serviceName", "serviceId"])}
          />
        </div>
        <div className="sub-list">
          {this.state.searchResultList.map((work) => {
            return (
              <div
                className="work"
                key={work.serviceUid}
                onClick={(e) => this.onLoadProgram(e, work)}
              >
                <div>{work.serviceName}</div>
                <button
                  type="button"
                  onClick={(e) => this.onOpenNewTab(e, work)}
                >
                  <RxOpenInNewWindow />
                </button>
              </div>
            );
          })}
        </div>
        <div className="sub-title">Recently Modified Program</div>
        <div className="sub-list">
          {this.renderRecentMenuList(Enums.LocalStorageName.WORKFLOW_HISTORY)}
        </div>
      </div>
    );
  }
}

const dispatchProps = (dispatch) => {
  return {
    createNewService: (serviceInfo, workflow) => {
      WorkflowReduxHelper.createNewService(dispatch, serviceInfo, workflow);
    },
    updateService: (service) => {
      if (window.location.pathname.includes(Enums.BuilderPath.WORKFLOW.MAIN)) {
        dispatch(initDo());
      } else {
        dispatch(initCommand());
      }
      dispatch(initDebug());
      dispatch(updateService(service));
    },
  };
};

export default connect((state) => {
  return {
    workflow: state.workflow,
  };
}, dispatchProps)(WorkflowMenu);
