import StringUtils from "components/common/utils/StringUtils";
import React, { useCallback, useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { useDrop, useDrag } from "react-dnd";
import { MdOutlineExpandLess, MdOutlineExpandMore } from "react-icons/md";
import { useSelector } from "react-redux";
import _ from "lodash";
import { CgChevronDoubleDownR } from "react-icons/cg";
import { Enums } from "components/builder/BuilderEnum";
import styled from "styled-components";
/** 트리에서 선택시 css 적용되는 부분 */
const TreeItem = styled.div`
  // background-color: ${(props) =>
    props.selected ? "#405163" : "transparent"};
  background-color: ${(props) => (props.selected ? "#e7f1ff" : "transparent")};
  color: ${(props) => (props.selected ? "#0c63e4" : "rgb(110, 110, 110)")};
  width: ${(props) => `calc(100% - 5px - ${props.step * 15}px)`};
  min-width: 150px;
  height: 22px;
  display: flex;
  align-items: center;
  gap: 8px;
  margin-left: ${(props) => props.step * 15}px;
  padding-left: 5px;
  border-radius: 5px;
  opacity: ${(props) => (props.isDragging ? 0 : 1)};
  position: relative;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  &:hover {
    // background-color: #3c3c3c;
    background-color: #a7c4ff;
    cursor: pointer;
  }
  & .extendIcon {
    width: 30px;
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    color: lightgray;
  }
`;

const DraggableTreeItem = ({
  moveItem,
  node,
  step,
  label,
  node_type,
  gridId, //그리드 일때만 사용
  ...props
}) => {
  const { component: activedComponent, treeNodeIds } = useSelector(
    (state) => state.activedUIComponent
  );

  const componentRef = useRef(null);
  const [isExtend, setIsExtend] = useState(false);
  const [hoverDirection, setHoverDirection] = useState("");

  useEffect(() => {
    if (treeNodeIds.length > 0) {
      setIsExtend(treeNodeIds.indexOf(node.compId) > -1);
    }
  }, [treeNodeIds]);

  const acceptType = () => {
    //그리드는 그리드 내부에서만 움직이도록 조정
    let acceptArr;
    if (
      StringUtils.equalsIgnoreType(node_type, Enums.ComponentType.GRID_COLUMN)
    ) {
      acceptArr = gridId;
    } else {
      acceptArr = Object.keys(Enums.ComponentType)
        .map((key) => Enums.ComponentType[key])
        .filter((value) => StringUtils.indexOf(value, "grid.") === -1);
    }
    return acceptArr;
  };

  /**
   * DND 이벤트
   * @param {*} dragTarget
   * @param {*} hoverTarget
   * @returns {[dragTarget,hoverTarget,direction]}
   */
  const CalculateDnd = (dragTarget, dragTargetOffset) => {
    if (!componentRef.current) return null;
    if (dragTarget.compId === node.compId) return null;
    const hoverBoundingRect = ReactDOM.findDOMNode(
      componentRef.current
    ).getBoundingClientRect();

    if (!dragTargetOffset) return null;

    /**
     * 1. hover 타겟 중앙기준
     * 2. 왼쪽에 놓으면 위치이동
     * 3. 오른쪽에 놓으면 안으로 이동
     * 4. 그리드 안에서는 inside 없음 무조건 upside
     * **/
    if (StringUtils.indexOf(node_type, "grid.") > -1) {
      return [dragTarget, node, "upside"];
    } else {
      const inputIntoHoverTargetZone = hoverBoundingRect.right - 30;
      const direction =
        inputIntoHoverTargetZone < dragTargetOffset.x ? "inside" : "upside";
      return [dragTarget, node, direction];
    }
  };

  const [{ isOver }, drop] = useDrop({
    accept: acceptType(),
    drop: (item, monitor) => {
      const CalcDND = CalculateDnd(item.node, monitor.getClientOffset());
      if (!CalcDND) {
        return null;
      } else {
        const [dragTarget, hoverTarget, direction] = CalcDND;
        //hover 타겟 중앙기준 왼쪽에 놓으면 위치이동, 오른쪽에 놓으면 안으로 이동
        if (moveItem) moveItem(dragTarget, hoverTarget, direction);
      }
    },
    canDrop: () => true,
    hover: (item, monitor) => {
      const CalcDND = CalculateDnd(item.node, monitor.getClientOffset());
      if (!CalcDND) {
        return null;
      } else {
        const [, , direction] = CalcDND;
        setHoverDirection(direction);
        if (StringUtils.equalsIgnoreCase(direction, "inside") && !isExtend) {
          setIsExtend(true);
        }
      }
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  });

  const [{ isDragging }, drag] = useDrag({
    //grid Column의 경우 gridID로 type을 제한하여 같은 그리드 안에서만 이동되도록 한다.
    type: StringUtils.equalsIgnoreType(
      node_type,
      Enums.ComponentType.GRID_COLUMN
    )
      ? gridId
      : node_type,
    item: {
      node: node,
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  useEffect(() => {
    if (isDragging && isExtend) {
      setIsExtend(false);
    }
  }, [isDragging]);

  useEffect(() => {
    if (!isOver) {
      setHoverDirection("");
    }
  }, [isOver]);

  const onClickExpandArrow = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsExtend(!isExtend);
  };

  const applyRef = useCallback((node) => {
    componentRef.current = node;
    drag(drop(node));
  }, []);

  return (
    <div
      style={{
        borderTop: StringUtils.equalsIgnoreCase(hoverDirection, "upside")
          ? "4px solid lightgray"
          : "",
      }}
    >
      <TreeItem
        ref={applyRef}
        style={{
          ...props.style,
        }}
        selected={StringUtils.equalsIgnoreType(
          activedComponent.compId,
          node.compId
        )}
        isDragging={isDragging}
        step={step}
        {...props}
      >
        <span onClick={onClickExpandArrow} style={{ cursor: "pointer" }}>
          {props.children &&
            (isExtend ? <MdOutlineExpandLess /> : <MdOutlineExpandMore />)}
        </span>
        <span>{label}</span>

        {StringUtils.indexOf(node_type, "grid.") === -1 &&
          StringUtils.equalsIgnoreCase(hoverDirection, "inside") && (
            <div className="extendIcon">
              <CgChevronDoubleDownR />
            </div>
          )}
      </TreeItem>
      {isExtend && props.children}
    </div>
  );
};

export default DraggableTreeItem;
