import EventHandlerHeader from "components/builder/eventhandler/EventHandlerHeader";
import EventHandlerBuilder from "components/builder/eventhandler/editor/layout/EventHandlerBuilder";
import EventHandlerLeftMenu from "components/builder/eventhandler/editor/layout/EventHandlerLeftMenu";
import React, { createContext, useEffect, useState } from "react";
import { Enums } from "components/builder/BuilderEnum";
import UIReduxHelper from "components/builder/ui/editor/helper/UIReduxHelper";
import JsonUtils from "components/common/utils/JsonUtils";
import produce from "immer";
import ArrayUtils from "components/common/utils/ArrayUtils";
import { useDispatch, useSelector } from "react-redux";
import Blockly from "blockly";
import {
  updateActivateEditorAttr,
  updateActivateProps,
} from "components/builder/ui/reducers/UIBuilderAction";
import { useRef } from "react";
import StringUtils from "components/common/utils/StringUtils";
import { useNavigate } from "react-router-dom";
import ObjectUtils from "components/common/utils/ObjectUtils";
import { updateEventWorkspacePath } from "components/builder/eventhandler/reducer/EventHandlerAction";
import Api from "components/common/Api";

export const EventHandlerContext = createContext({
  selectedFncDtl: {},
  setSelectedFncDtl: () => {},
  isTreeOpen: false,
  setIsTreeOpen: () => {},
  parameterInfo: {},
  setParameterInfo: () => {},
  updateComponent: () => {},
  blockInfo: [],
  setBlockInfo: () => {},
  blockAdditionalInfo: {},
  setBlockAdditionalInfo: () => {},
});

const EventHandlerBuilderMain = (props) => {
  //redux
  const eventHandler = useSelector((state) => state.eventHandler);
  const output = useSelector((state) => state.outputUI.output);
  const targetComponent = useSelector(
    (state) => state.activedUIComponent.component
  );
  //hook
  const dispatch = useDispatch();
  const navigate = useNavigate();
  //ref
  const workspaceRef = useRef();
  //state
  const [selectedFncDtl, setSelectedFncDtl] = useState({});
  const [isTreeOpen, setIsTreeOpen] = useState(false);
  const [parameterInfo, setParameterInfo] = useState({}); //선택된 이벤트 파라미터 정보
  const [blockInfo, setBlockInfo] = useState([]);
  const [blockAdditionalInfo, setBlockAdditionalInfo] = useState({});

  useEffect(() => {
    getDataEntityOptions();
  }, []);

  // data model로 data entity를 찾는 함수
  const getDataEntityOptions = () => {
    return new Promise((resolve, reject) => {
      resolve(getDataEntity(output, getDataModel(output, targetComponent)));
    });
  };

  // data model 찾는 함수
  const getDataModel = (output, targetComponent) => {
    if (
      targetComponent.type === Enums.ComponentType.GRID_CELL ||
      targetComponent.type === Enums.ComponentType.GRID_HEADER
    ) {
      return JsonUtils.findGridDataModel(output, targetComponent.gridId, true);
    }

    return JsonUtils.findDataModel(output, targetComponent.compId, true);
  };

  // data entity 찾은 후 block의 dropdown에 사용할 배열 생성 후 리턴
  const getDataEntity = (output, dataModelId) => {
    let options = [];

    // find data Entity
    Api.post(
      "/datamodel/getDataModelEntityList",
      { dataModelId: dataModelId },
      (res) => {
        if (res.data) {
          res.data.map((data) => {
            options.push([
              data.text,
              `${data.text},/${output.page.propertyValue.programId}/${dataModelId}/${data.entityId}/event`,
            ]);
          });
        }

        const additionalInfo = { ...blockAdditionalInfo };
        additionalInfo.dataEntityOption = options;
        additionalInfo.eventTarget = eventHandler.componentProp;
        setBlockAdditionalInfo(additionalInfo);
      }
    );
    return options;
  };

  /**
   * 현재 활성화된 컴포넌트를 업데이트
   * @param {*} component
   */
  const _updateActivateProps = (component) => {
    dispatch(updateActivateEditorAttr(component.editorAttr));
    dispatch(updateActivateProps(component.propertyValue));
  };

  /**
   * 작성완료 이벤트
   * UI 빌더로 이동 시 자동 저장 실행 & 커맨더 버튼 라인에서 작성완료 이벤트
   * 2개 동시 적용이기 때문에 메인 페이지에서 이벤트 설정
   * 선택된 컴포넌트 업데이트
   */
  const updateComponent = (e, cb) => {
    const { componentProp, output: handlerFunction } = eventHandler;
    const { compId, eventCd, builderEventType, componentType, componentInfo } =
      componentProp;

    const isEventIndex =
      componentProp.eventIndex !== undefined &&
      componentProp.eventIndex !== null;

    if (!ObjectUtils.isEmpty(output)) {
      const updatedUIOuput = produce(output, (draft) => {
        const targetNode = JsonUtils.findNode(draft, "compId", compId);
        const _targetNode = JsonUtils.findNode(output, "compId", compId);
        let tPropertyValue = targetNode.propertyValue;
        if (!ObjectUtils.isEmpty(_targetNode)) {
          /**
           * editorView 에 eventBuilder workspace 정보를 저장함
           * eventIndex가 있는 경우에는 배열 형태로 저장
           *  ex) eventWorkspace =  [
           *                           {"beforeSubmit":[workspace],"afterSubmit":[workspace]},
           *                           {"beforeSubmit":[workspace],"afterSubmit":[workspace]}
           *                            ....
           *                        ]
           * 없는 경우는 다이렉트로 적용
           *  ex) eventWorkspace =  {
           *                          "beforeSubmit":[workspace],
           *                          "afterSubmit":[workspace]
           *                          .....
           *                        }
           */
          const eventWorkspace = Blockly.serialization.workspaces.save(
            workspaceRef.current
          );
          if (targetNode.propertyValue && targetNode.viewerAttr) {
            /**
             * 오브젝트 타입으로 판단
             * button : propertyValue.form 배열 안에 각각 오브젝트 형태, 인덱스로 위치 추정
             *  form = [{}]
             * input : property 안에 [eventType] : '함수' 형태
             */
            const {
              type,
              viewerAttr: { object },
            } = targetNode;
            if (
              StringUtils.includesIgnoreCase(object, [
                "input",
                "select",
                "textarea",
                "radio",
                "singleDatePicker",
                "rangeDatePicker",
                "checkbox",
                "radio",
                "button", // button 은 Index가 있는 경우 방법이 달라짐
                "amchart3",
                "activeReport",
              ])
            ) {
              //Button
              //사용자 정의는 component의 properyValue에 들어감
              if (!isEventIndex) {
                targetNode.propertyValue[builderEventType] = handlerFunction;
                if (!targetNode.propertyValue.eventWorkspace)
                  targetNode.propertyValue.eventWorkspace = {};
                targetNode.propertyValue.eventWorkspace[builderEventType] =
                  eventWorkspace;
              } else {
                // 같은 Propery 속성안에 함수와 이벤트 워크스페이스 같이 집어 넣음
                targetNode.propertyValue.form[componentProp.eventIndex][
                  builderEventType
                ] = handlerFunction;
                if (
                  !targetNode.propertyValue.form[componentProp.eventIndex]
                    .eventWorkspace
                )
                  targetNode.propertyValue.form[
                    componentProp.eventIndex
                  ].eventWorkspace = {};
                targetNode.propertyValue.form[
                  componentProp.eventIndex
                ].eventWorkspace[builderEventType] = eventWorkspace;
              }
            } else if (
              StringUtils.includesIgnoreCase(targetNode.type, [
                Enums.ComponentType.COLUMN,
                Enums.ComponentType.ROW,
                Enums.ComponentType.WIDGET_CONTAINER,
                Enums.ComponentType.TREE,
                Enums.ComponentType.TAB_CONTAINER,
                Enums.ComponentType.TAB,
                Enums.ComponentType.STEP_CONTAINER,
                Enums.ComponentType.STEP,
              ])
            ) {
              //Layout Component
              if (isEventIndex) {
                if (
                  StringUtils.equalsIgnoreCase(
                    targetNode.type,
                    Enums.ComponentType.WIDGET_CONTAINER
                  )
                ) {
                  //위젯 컨테이너의 경우에는 buttons 항목에 함수가 저장된다.
                  let buttons =
                    targetNode.propertyValue.buttons[componentProp.eventIndex];
                  buttons[builderEventType] = handlerFunction;
                  if (!buttons.eventWorkspace) buttons.eventWorkspace = {};
                  buttons.eventWorkspace[builderEventType] = eventWorkspace;
                } else {
                  let form =
                    targetNode.propertyValue.form[componentProp.eventIndex];
                  form[builderEventType] = handlerFunction;
                  if (!form.eventWorkspace) form.eventWorkspace = {};
                  form.eventWorkspace[builderEventType] = eventWorkspace;
                }
              } else {
                if (
                  StringUtils.includesIgnoreCase(targetNode.type, [
                    Enums.ComponentType.STEP,
                    Enums.ComponentType.TAB,
                  ])
                ) {
                  targetNode.propertyValue.display = false;
                }

                tPropertyValue[builderEventType] = handlerFunction;
                if (!tPropertyValue.eventWorkspace)
                  tPropertyValue.eventWorkspace = {};
                tPropertyValue.eventWorkspace[builderEventType] =
                  eventWorkspace;
              }
            } else if (
              StringUtils.equalsIgnoreCase(type, Enums.ComponentType.GRID)
            ) {
              //그리드
              if (componentProp.eventCategory === "event") {
                /**
                 * 이벤트는 배열로 들어가있기 때문에 해당 Index를 찾아서 처리
                 */
                const tIndex =
                  targetNode.propertyValue.gridOptions.event.findIndex(
                    (e) => e.eventType === componentProp.builderEventType
                  );
                targetNode.propertyValue.gridOptions.event[tIndex][
                  componentProp.componentEventType
                ] = handlerFunction;
                if (
                  !targetNode.propertyValue.gridOptions.event[tIndex]
                    .eventWorkspace
                ) {
                  targetNode.propertyValue.gridOptions.event[
                    tIndex
                  ].eventWorkspace = {};
                }
                targetNode.propertyValue.gridOptions.event[
                  tIndex
                ].eventWorkspace[componentProp.componentEventType] =
                  eventWorkspace;
              } else if (componentProp.eventCategory === "toolbar") {
                if (
                  ArrayUtils.isArray(
                    targetNode.propertyValue.gridOptions.additionalOptions
                      .toolbarOptions.buttons
                  )
                ) {
                  //그리드 툴바 버튼 이벤트 추가
                  // targetNode.propertyValue.gridOptions.additionalOptions.toolbarOptions.buttons =
                  //   {
                  //     add: {
                  //       icon: "fa-plus-square",
                  //       name: "행추가",
                  //       id: "add",
                  //       useYn: true,
                  //     },
                  //     clone: {
                  //       icon: "fa-copy",
                  //       name: "행복사",
                  //       id: "clone",
                  //       useYn: true,
                  //     },
                  //     remove: {
                  //       icon: "fa-trash",
                  //       name: "행삭제",
                  //       id: "remove",
                  //       useYn: true,
                  //     },
                  //     undo: {
                  //       icon: "fa-times-circle",
                  //       name: "행취소",
                  //       id: "undo",
                  //       useYn: true,
                  //     },
                  //   };
                }

                if (
                  StringUtils.equalsIgnoreCase(
                    componentProp.buttonEventType,
                    "buttons"
                  )
                ) {
                  //buttons는 오브젝트 형태이기 때문에 아래와 같이 저장
                  targetNode.propertyValue.gridOptions.additionalOptions.toolbarOptions[
                    componentProp.buttonEventType
                  ][componentProp.buttonId][builderEventType] = handlerFunction;
                  targetNode.propertyValue.gridOptions.additionalOptions.toolbarOptions[
                    componentProp.buttonEventType
                  ][componentProp.buttonId].eventType =
                    componentProp.componentEventType;

                  if (
                    !targetNode.propertyValue.gridOptions.additionalOptions
                      .toolbarOptions[componentProp.buttonEventType][
                      componentProp.buttonId
                    ].eventWorkspace
                  ) {
                    targetNode.propertyValue.gridOptions.additionalOptions.toolbarOptions[
                      componentProp.buttonEventType
                    ][componentProp.buttonId].eventWorkspace = {};
                  }

                  targetNode.propertyValue.gridOptions.additionalOptions.toolbarOptions[
                    componentProp.buttonEventType
                  ][componentProp.buttonId].eventWorkspace[builderEventType] =
                    eventWorkspace;
                } else {
                  //customButtons는 배열 형태이기 때문에 인덱스를 찾아줘야 한다.
                  const buttonIndex =
                    _targetNode.propertyValue.gridOptions.additionalOptions.toolbarOptions[
                      componentProp.buttonEventType
                    ].findIndex((e) => e.id === componentProp.buttonId);
                  if (buttonIndex > -1) {
                    targetNode.propertyValue.gridOptions.additionalOptions.toolbarOptions[
                      componentProp.buttonEventType
                    ][buttonIndex][builderEventType] = handlerFunction;
                    targetNode.propertyValue.gridOptions.additionalOptions.toolbarOptions[
                      componentProp.buttonEventType
                    ][buttonIndex].eventType = componentProp.componentEventType;

                    if (
                      !targetNode.propertyValue.gridOptions.additionalOptions
                        .toolbarOptions[componentProp.buttonEventType][
                        buttonIndex
                      ].eventWorkspace
                    ) {
                      targetNode.propertyValue.gridOptions.additionalOptions.toolbarOptions[
                        componentProp.buttonEventType
                      ][buttonIndex].eventWorkspace = {};
                    }
                    targetNode.propertyValue.gridOptions.additionalOptions.toolbarOptions[
                      componentProp.buttonEventType
                    ][buttonIndex].eventWorkspace[builderEventType] =
                      eventWorkspace;
                  }
                }
              } else {
                //Column 편집 이벤트
                targetNode.propertyValue.gridOptions[builderEventType] =
                  handlerFunction;
                if (!targetNode.propertyValue.gridOptions.eventWorkspace) {
                  targetNode.propertyValue.gridOptions.eventWorkspace = {};
                }
                targetNode.propertyValue.gridOptions.eventWorkspace[
                  builderEventType
                ] = eventWorkspace;
              }
            } else {
              console.log("Undefined", targetNode);
            }
          } else if (
            componentType &&
            StringUtils.includes(componentType, [Enums.ComponentType.GRID_CELL])
          ) {
            //grid cell 타입
            if (
              StringUtils.equalsIgnoreCase(
                componentProp.eventType,
                "userWrapFnc"
              )
            ) {
              // userWrapFnc는 정의된 문서에 따라 앞뒤를 function으로 감싸준다.
              if (
                StringUtils.equalsIgnoreCase(
                  componentProp.eventCd,
                  "grid.cell.fnc.custom"
                )
              ) {
                targetNode[builderEventType] =
                  "function($colData, $colObject, $rowData, panel, utils){\n" +
                  handlerFunction +
                  "\n}";
              } else if (
                StringUtils.equalsIgnoreCase(
                  componentProp.eventCd,
                  "grid.cell.fnc.render"
                )
              ) {
                targetNode[builderEventType] =
                  "function($colData, $colObject, $rowData, columnSetting, s, e, utils,tooltip){\n" +
                  handlerFunction +
                  "\n}";
              } else {
                targetNode[builderEventType] = handlerFunction;
              }
            } else {
              targetNode[builderEventType] = handlerFunction;
            }
            if (!targetNode.eventWorkspace) targetNode.eventWorkspace = {};
            targetNode.eventWorkspace[builderEventType] = eventWorkspace;
          } else if (
            !StringUtils.isEmpty(targetNode.propertyValue.appGroupCd)
          ) {
            //App 그룹 코드가 있으면 페이지 컴포넌트라고 판단한다.
            targetNode.propertyValue[builderEventType] = handlerFunction;
            if (!targetNode.propertyValue.eventWorkspace)
              targetNode.propertyValue.eventWorkspace = {};
            targetNode.propertyValue.eventWorkspace[builderEventType] =
              eventWorkspace;
          }
        }
      });
      //output UI State 업데이트
      UIReduxHelper.updateOutput(updatedUIOuput, dispatch);

      //Activate UI State 업데이트
      const _t = JsonUtils.findNode(updatedUIOuput, "compId", compId);
      if (targetComponent.compId === _t.compId) _updateActivateProps(_t);
      else if (targetComponent.propertyValue.compId === _t.compId) {
        //Grid Cell 의 경우
        const updatedActivedComp = produce(componentInfo, (draft) => {
          draft.propertyValue = _t;
        });
        _updateActivateProps(updatedActivedComp);
      }
      //Event Handler Componentprop state 업데이트
      dispatch(
        updateEventWorkspacePath({
          ...eventHandler.componentProp,
          originalOutput: handlerFunction,
        })
      );
    }
    if (cb) cb();
  };

  const contextValue = {
    selectedFncDtl,
    setSelectedFncDtl: (fncDtl) => {
      if (isTreeOpen) setIsTreeOpen(!isTreeOpen);
      setSelectedFncDtl(fncDtl);
    },
    isTreeOpen,
    setIsTreeOpen,
    parameterInfo,
    setParameterInfo,
    blockInfo,
    setBlockInfo,
    blockAdditionalInfo,
    setBlockAdditionalInfo,
  };
  return (
    <EventHandlerContext.Provider value={contextValue}>
      <div className="eventhandler-wrapper">
        <EventHandlerHeader
          {...props.header}
          updateComponent={updateComponent}
          blocklyWorkspaceRef={workspaceRef}
          navigate={navigate}
        />
        <EventHandlerLeftMenu />
        <EventHandlerBuilder
          updateComponent={updateComponent}
          blocklyWorkspaceRef={workspaceRef}
        />
      </div>
    </EventHandlerContext.Provider>
  );
};

export default EventHandlerBuilderMain;
