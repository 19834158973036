import ObjectUtils from "components/common/utils/ObjectUtils";
import React, { memo } from "react";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { setSidebarCollapse } from "components/builder/ui/reducers/MenuAction";

const BuilderRightMenuPanel = memo((props) => {
  const dispatch = useDispatch();
  const { isSidebarCollapsed } = useSelector((state) => state.menu);
  const workspace = useSelector((state) => state.workspace);
  const onCollapseSidebar = () => {
    dispatch(setSidebarCollapse(!isSidebarCollapsed));
  };

  let svgStyle = { verticalAlign: "unset", color: "#fff" };
  return (
    <div className={`right-properties ${isSidebarCollapsed ? "collapsed" : ""}`}>
      <div className="collapse-button" onClick={onCollapseSidebar}>
        {!ObjectUtils.isEmpty(workspace) &&
          (isSidebarCollapsed ? (
            <BiChevronLeft style={svgStyle} size={12} />
          ) : (
            <BiChevronRight style={svgStyle} size={12} />
          ))}
      </div>
      {props.children}
    </div>
  );
});

export default BuilderRightMenuPanel;
