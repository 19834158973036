import Api from "components/common/Api";
import JsonUtils from "components/common/utils/JsonUtils";
/**
 * UI - builder 저장 및 조회
 * **/
class ProgramDesignService {
  static requestMapping = "/programDesign";

  /**
   * Technical Design 목록 호출
   * @param {*} data
   * @param {*} callbackFnc
   * @param {*} errCallbackFnc
   */
  static getTechnicalDesignList(data, callbackFnc, errCallbackFnc) {
    Api.post(
      this.requestMapping + "/getDesignMstList",
      data,
      callbackFnc,
      errCallbackFnc
    );
  }

  /**
   * 단일 Technical Design 조회
   * @param {*} data
   * @param {*} callbackFnc
   * @param {*} errCallbackFnc
   */
  static getDesignMst(data, callbackFnc, errCallbackFnc) {
    Api.post(
      this.requestMapping + "/getDesignMst",
      data,
      callbackFnc,
      errCallbackFnc
    );
  }

  /**

   * Design Mst 저장
   * @param {*} data
   * @param {*} callbackFnc
   * @param {*} errCallbackFnc
   */
  static saveDesignMst(data, callbackFnc, errCallbackFnc) {
    Api.post(
      this.requestMapping + "/saveDesignMst",
      data,
      callbackFnc,
      errCallbackFnc
    );
  }

  /**
   * Design Mst 삭제
   * @param {*} data
   * @param {*} callbackFnc
   * @param {*} errCallbackFnc
   */
  static deleteDesignMst(data, callbackFnc, errCallbackFnc) {
    Api.post(
      this.requestMapping + "/deleteDesignMst",
      data,
      callbackFnc,
      errCallbackFnc
    );
  }

  /**
   * Design Layout Column 저장
   * @param {*} data
   * @param {*} callbackFnc
   * @param {*} errCallbackFnc
   */
  static saveDesignLayoutColumn(data, callbackFnc, errCallbackFnc) {
    Api.post(
      this.requestMapping + "/saveDesignLayoutColumn",
      data,
      callbackFnc,
      errCallbackFnc
    );
  }

  /**
   *  Design Layout column list 가져오기
   * @param {*} data
   * @param {*} callbackFnc
   * @param {*} errCallbackFnc
   */
  static getLayoutColumnList(data, callbackFnc, errCallbackFnc) {
    Api.post(
      this.requestMapping + "/getLayoutColumnList",
      data,
      callbackFnc,
      errCallbackFnc
    );
  }

  /**
   *  DesignRelatedTables list 가져오기
   * @param {*} data
   * @param {*} callbackFnc
   * @param {*} errCallbackFnc
   */
  static getDesignRelatedTables(data, callbackFnc, errCallbackFnc) {
    Api.post(
      this.requestMapping + "/getDesignRelatedTables",
      data,
      callbackFnc,
      errCallbackFnc
    );
  }

  /**
   *  layout column 테이블 명 리스트 가져오기
   * @param {*} data
   * @param {*} callbackFnc
   * @param {*} errCallbackFnc
   */
  static getLayoutColumnTableNm(data, callbackFnc, errCallbackFnc) {
    Api.post(
      this.requestMapping + "/getLayoutColumnTableNm",
      data,
      callbackFnc,
      errCallbackFnc
    );
  }

  /**
   *  DesignRelatedTables list 저장
   * @param {*} data
   * @param {*} callbackFnc
   * @param {*} errCallbackFnc
   */
  static saveDesignRelatedTables(data, callbackFnc, errCallbackFnc) {
    Api.post(
      this.requestMapping + "/saveDesignRelatedTables",
      data,
      callbackFnc,
      errCallbackFnc
    );
  }
  /**
   * designLayoutColumn 삭제
   * @param {*} data
   * @param {*} callbackFnc
   * @param {*} errCallbackFnc
   */
  static deleteDesignLayoutColumn(data, callbackFnc, errCallbackFnc) {
    Api.post(
      this.requestMapping + "/deleteDesignLayoutColumn",
      data,
      callbackFnc,
      errCallbackFnc
    );
  }

  /**
   * design layout component 삭제
   * @param {*} data
   * @param {*} callbackFnc
   * @param {*} errCallbackFnc
   */
  static deleteDesignLayoutComponent(data, callbackFnc, errCallbackFnc) {
    Api.post(
      this.requestMapping + "/deleteDesignLayoutComponent",
      data,
      callbackFnc,
      errCallbackFnc
    );
  }
}
export default ProgramDesignService;
