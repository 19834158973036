import { Tooltip } from "@mui/material";
import { initCommand } from "components/builder/ui/reducers/CommandAction";
import Popup from "components/common/Popup";
import ArrayUtils from "components/common/utils/ArrayUtils";
import StringUtils from "components/common/utils/StringUtils";
import CSFileContentDetailPopup from "page/popup/workflow/CSFileContentDetailPopup";
import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { AiOutlineCode } from "react-icons/ai";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";
import { FaFileDownload } from "react-icons/fa";
import { MdEdit, MdOutlineShortText, MdSearch } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import {
  setCSService,
  setCsServiceLog,
  updateCSFileService,
  updateService,
} from "../reducer/WorkflowAction";
import { initDebug } from "../reducer/WorkflowDebugAction";

const WorkflowCsServiceList = () => {
  const { CSFileViewMode, CSFileInfo, output, serviceInfo, CSFileLogViewMode } =
    useSelector((state) => state.workflow);
  const [isListView, setIsListView] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (CSFileViewMode && !StringUtils.isEmpty(serviceInfo.serviceId)) {
      onUpdateService();
    }
  }, [output]);

  useEffect(() => {
    if (CSFileViewMode) {
      setIsListView(true);
    }
  }, [CSFileViewMode]);

  /**
   * service update
   * output 바뀔때마다 데이터 쪽도 업데이트 해줌
   */
  const onUpdateService = () => {
    dispatch(updateCSFileService());
  };

  /**
   * 선택한 서비스를 화면에 띄운다.
   * @param {*} data
   */
  const onDetailService = (service) => {
    dispatch(setCSService(service));
    dispatch(initDebug());
    dispatch(initCommand());
  };

  /**
   * 선택한 서비스의 로그를 열거나 닫는다.
   */
  const onDetailServiceLog = (data) => {
    dispatch(setCsServiceLog(!CSFileLogViewMode));
  };

  /**
   * 소스 코드 상세
   */
  const onDetailSourceCode = () => {
    Popup.open(<CSFileContentDetailPopup fileInfo={CSFileInfo} />, {
      style: { content: { width: "80vw" } },
    });
  };

  /**
   * cs 파일 다운로드
   */
  const onDownloadCode = () => {
    const blob = new Blob([CSFileInfo.fileContent], { type: "text/plain" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = `${CSFileInfo.fileNm}.${CSFileInfo.fileExtension}`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  let svgStyle = { verticalAlign: "unset", color: "#fff" };

  return (
    <>
      <div className={`workflow-cs-wrapper ${isListView ? "show" : "hidden"}`}>
        <div
          className={`resize-btn ${CSFileViewMode ? "show" : "hidden"}`}
          onClick={(e) => setIsListView(!isListView)}
        >
          {isListView ? (
            <BiChevronRight style={svgStyle} size={12} />
          ) : (
            <BiChevronLeft style={svgStyle} size={12} />
          )}
        </div>
        <div className="workflow-cs-title">
          <div className="file-name">
            {CSFileInfo.fileNm + "." + CSFileInfo.fileExtension}
          </div>
          <div style={{ display: "flex", gap: "5px" }}>
            <Button size="sm" variant="light" onClick={onDetailSourceCode}>
              <AiOutlineCode size={16} /> Source
            </Button>
            <Button size="sm" variant="light" onClick={onDownloadCode}>
              <FaFileDownload size={16} />
            </Button>
          </div>
        </div>

        <div className="workflow-cs-list">
          {!ArrayUtils.isEmpty(CSFileInfo.serviceList) &&
            CSFileInfo.serviceList.map((service, index) => {
              const { serviceInfo: sInfo } = service;
              return (
                <div
                  key={sInfo.serviceId}
                  className={`cs-service-wrapper mb-3 ${
                    serviceInfo.serviceId === sInfo.serviceId ? "selected" : ""
                  }`}
                >
                  <div className="service-number">{index + 1}.</div>
                  <div
                    className="service-name"
                    onClick={(e) => onDetailService(service)}
                  >
                    <div>{sInfo.serviceName}</div>
                  </div>
                  <div className="service-edit">
                    <Tooltip title="Details">
                      <Button
                        size="sm"
                        variant="secondary"
                        onClick={(e) => onDetailService(service)}
                      >
                        <MdEdit />
                      </Button>
                    </Tooltip>

                    <Tooltip title="Open Log">
                      <Button
                        size="sm"
                        variant="success"
                        onClick={onDetailServiceLog}
                      >
                        <MdOutlineShortText />
                      </Button>
                    </Tooltip>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </>
  );
};

export default WorkflowCsServiceList;
