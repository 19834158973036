import React, { useState, useCallback, useContext } from "react";
import { Button, ButtonGroup, ToggleButton } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { Scrollbar } from "smooth-scrollbar-react";

import { AppContext } from "components/common/AppContextProvider";
import * as Enums from "components/builder/BuilderEnum";
import {
  ArrayUtils,
  StringUtils,
  JsonUtils,
  ObjectUtils,
} from "components/common/utils/CommonUtils";

import * as actions from "components/builder/ui/reducers/UIBuilderAction";
import UIEditorSource from "components/builder/ui/editor/UIEditorSource";
import UIEditorDNDContainer from "components/builder/ui/editor/UIEditorDNDContainer";
import UIEditorChildRender from "components/builder/ui/editor/UIEditorChildRender";
import UITemplateHelper from "components/builder/ui/editor/helper/UITemplateHelper";
import * as Event from "components/builder/ui/editor/handler/UIEditorEventHandler";
import UIEditorFooter from "components/builder/ui/editor/render/UIEditorFooter";

import Page from "components/builder/ui/uiComponents/layout/Page";
import { EditorPanel } from "components/builder/ui/uiComponents/UIComponentStyle";
import UICommandButton from "components/builder/ui/UICommandButton";
import { useRef } from "react";
import { useEffect } from "react";
const UIEditor = (props) => {
  const workspace = useSelector((state) => state.workspace);
  const [tabType, setTabType] = useState(
    StringUtils.defaultString(props.tabType, "E")
  );
  const componentContext = useContext(AppContext).component;

  /* template 용 표준 base components - e.g) page, form, row, col... */
  const templateComponents =
    UITemplateHelper.getTemplateComponents(componentContext);
  /*template 용 표준 base components - Page component */
  const pageTemplate = UITemplateHelper.getTemplate(
    templateComponents,
    Enums.ComponentType.PAGE
  );
  /* for redux */
  const dispatch = useDispatch();

  const statOutput = useSelector((state) => state.outputUI.output);

  const activedComponent = useSelector(
    (state) => state.activedUIComponent.component
  );

  const components = useContext(AppContext).component.getComponentList("B");
  const pageCompnent = useContext(AppContext).component.getPageComponent();

  const editorPanelRef = useRef();

  //ESC 및 DELETE 키다운 이벤트 적용
  useEffect(() => {
    // editorPanelRef.current
    document.addEventListener("keydown", onKeyDownEsc);
    return () => {
      document.removeEventListener("keydown", onKeyDownEsc);
    };
  }, [activedComponent]);

  /**
   * output을 update 한다.
   * @param {*} changedOutput
   */
  const updateOutputSource = (changedOutput) => {
    if (!ObjectUtils.isEmpty(activedComponent)) {
      const activedComponentNode = JsonUtils.findNode(
        changedOutput,
        "compId",
        activedComponent.compId
      );
      dispatch(actions.updateActivateProps(activedComponentNode.propertyValue));
      dispatch(actions.updateOutput(changedOutput));
    }
  };

  /**
   * Drag & drap handle
   */
  const handleDrop = useCallback(
    (dropZone, item, templateComponents, output) => {
      dropZone.workspace = workspace;
      Event.handleDrop(
        dropZone,
        item,
        templateComponents,
        output,
        dispatch,
        componentContext
      );
    },
    [dispatch]
  );

  /**
   * Page click
   * @param {*} e
   */
  const onClick = (e) => {
    Event.handlePageClick(
      e,
      dispatch,
      activedComponent,
      statOutput,
      pageTemplate
    );
  };

  /**
   * Page Editor class
   * @returns
   */
  const getPageEditorClass = () => {
    let className = "page";
    return className;
  };

  /**
   * Page Penal Class
   * @returns
   */
  const getPagePanelClass = () => {
    let pageClass = "page-container";

    if (activedComponent.componentType === Enums.ComponentType.PAGE)
      pageClass += " active";

    if (!ObjectUtils.isEmpty(statOutput.page.propertyValue)) {
      /* popup */
      if (
        statOutput.page.propertyValue.programType ===
        Enums.ProgramType.POPUP_PROGRAM
      ) {
        const size = ObjectUtils.isEmpty(
          statOutput.page.propertyValue.popupOptions
        )
          ? "xl"
          : StringUtils.defaultString(
              statOutput.page.propertyValue.popupOptions.size,
              "xl"
            );
        pageClass +=
          " popup-container " + (size !== "fl" ? " modal-" + size : "");

        /* dashboard */
      } else {
        pageClass +=
          " page-type-" +
          StringUtils.defaultString(
            statOutput.page.propertyValue.programType,
            Enums.ProgramType.MENU_PROGRAM
          );
      }
    }
    return pageClass;
  };

  //Main page
  const pageChildCnt = ArrayUtils.isEmpty(statOutput["page"]["child"])
    ? 0
    : statOutput["page"]["child"].length;

  //footer
  const footerChildCnt =
    ObjectUtils.isEmpty(statOutput["page"]["footer"]) ||
    ArrayUtils.isEmpty(statOutput["page"]["footer"]["child"])
      ? 0
      : statOutput["page"]["footer"]["child"].length;

  const pagePropertyValue = statOutput.page.propertyValue || {};
  const programType = StringUtils.defaultString(
    pagePropertyValue.programType,
    "M"
  );

  /**
   * 키이벤트에서 사용하는,
   * 선택된 노드보다 상위로 포커싱 이동하는 로직
   * @param {*} e
   * @param {*} component
   */
  const _moveToParentsNode = (e, component) => {
    const compId = component?.compId;
    if (compId) {
      const parentNode = JsonUtils.findParentNodeByOnlyCompId(
        statOutput.page,
        compId
      );
      if (parentNode)
        Event.clickComponent(
          e,
          parentNode,
          components,
          activedComponent,
          dispatch
        );
    }
  };

  /**
   * 키다운 이벤트 전체
   * ESC 눌렀을 때 상위노드로 이동하는 로직
   * DELETE 눌렀을때 해당 노드 삭제하는 로직
   * @param {*} e
   */
  const onKeyDownEsc = (e) => {
    if (
      (editorPanelRef.current &&
        editorPanelRef.current.contains(document.activeElement)) ||
      document.activeElement === document.body
    ) {
      if (e && e.keyCode === 27) {
        if (!ObjectUtils.isEmpty(activedComponent)) {
          const { type } = activedComponent;
          let compId = activedComponent.compId;
          if (
            type &&
            StringUtils.includes(type, [
              Enums.ComponentType.GRID_CELL,
              Enums.ComponentType.GRID_HEADER,
            ])
          ) {
            //그리드 부모 찾아 주기
            compId = compId.split("-")[1];
          }
          _moveToParentsNode(e, { compId });
        }
      } else if (StringUtils.equalsIgnoreCase(e.key, "delete")) {
        Event.removeNode(
          e,
          activedComponent,
          statOutput,
          components,
          pageCompnent,
          dispatch
        );
        _moveToParentsNode(e, activedComponent);
      }
    }
  };

  return (
    <React.Fragment>
      <UICommandButton setTabType={setTabType} tabType={tabType} />
      {tabType === "E" ? (
        <React.Fragment>
          <EditorPanel
            className={`edit-panel-area ${getPagePanelClass()}`}
            programType={programType}
            pagePropertyValue={pagePropertyValue}
            id="editPage"
            onClick={onClick}
            // onKeyDown={onKeyDownEsc}
            // tabIndex={"0"}
            // style={{ outline: "none" }}
            ref={editorPanelRef}
          >
            <Page event="renderEditor" componentInfo={statOutput["page"]}>
              <div
                id="editCanvas"
                className={getPageEditorClass()}
                onMouseOver={Event.handlePageMouseOver}
                onMouseOut={Event.handlePageMouseOut}
              >
                {pageChildCnt > 0
                  ? statOutput["page"]["child"].map((row, index) => {
                      const currentPath = `${index}`;
                      return (
                        <React.Fragment key={index}>
                          <UIEditorDNDContainer
                            data={{
                              location: Enums.ComponentType.PAGE,
                              rootLocation: Enums.ComponentType.PAGE,
                              path: currentPath,
                              childrenCount: pageChildCnt,
                              propertyValue: {},
                            }}
                            onDrop={handleDrop}
                            path={currentPath}
                            templateComponents={templateComponents}
                          />
                          <UIEditorChildRender
                            key={row.compId}
                            data={row}
                            handleDrop={handleDrop}
                            templateComponents={templateComponents}
                            path={currentPath}
                            childrenCount={pageChildCnt}
                            rootLocation={Enums.ComponentType.PAGE}
                            // onKeyDown={onKeyDownEsc}
                          />
                        </React.Fragment>
                      );
                    })
                  : ""}
                {/* 빈 페이지에 컴포넌트 추가할때 사용하는 컴포넌트 */}
                <UIEditorDNDContainer
                  data={{
                    location: Enums.ComponentType.PAGE,
                    rootLocation: Enums.ComponentType.PAGE,
                    path: `${pageChildCnt}`,
                    childrenCount: pageChildCnt,
                    propertyValue: {},
                  }}
                  className={`${pageChildCnt === 0 ? "empty-child" : ""}`}
                  onDrop={handleDrop}
                  templateComponents={templateComponents}
                />
              </div>
            </Page>
          </EditorPanel>
          {footerChildCnt > 0 ? (
            <UIEditorFooter
              onDrop={handleDrop}
              pagePropertyValue={pagePropertyValue}
              templateComponents={templateComponents}
              child={statOutput["page"]["footer"]["child"]}
            />
          ) : (
            ""
          )}
        </React.Fragment>
      ) : (
        <UIEditorSource
          output={statOutput}
          updateOutputSource={updateOutputSource}
        />
      )}
    </React.Fragment>
  );
};
export default React.memo(UIEditor);
