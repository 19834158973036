import React, { useEffect, useState, useRef } from "react";
import CodeMirror from "@uiw/react-codemirror";
import { css } from "@codemirror/lang-css";
import { Button } from "react-bootstrap";
import StringUtils from "components/common/utils/StringUtils";
import Message from "components/common/Message";
import { Enums } from "components/builder/BuilderEnum";
import { Tooltip } from "@mui/material";
import SettingService from "services/common/SettingService";
import { useDispatch, useSelector } from "react-redux";
import StyleUtils from "components/common/utils/StyleUtils";
import { CodeMirrorBox, SearchBar } from "./StyleCustom";

function BuilderStyle() {
  const { style: styleRedux } = useSelector((state) => state.setting);
  const workspace = useSelector((state) => state.workspace);

  const dispatch = useDispatch();

  const [styleId, setStyleId] = useState(null);
  const [styleNm, setStyleNm] = useState("");
  const [styleCode, setStyleCode] = useState("");
  const fileRef = useRef();

  useEffect(() => {
    getBuilderDefaultStyle();
  }, []);

  const getBuilderDefaultStyle = () => {
    SettingService.getStyle({ builderDefaultYn: "Y" }, (res) => {
      const { data } = res;
      if (data) {
        setStyleId(data.styleId);
        setStyleNm(data.styleNm);
        setStyleCode(JSON.parse(data.styleCode));
      }
    });
  };

  const setCodeMirrorContents = (value) => {
    setStyleCode(value);
  };

  const onClickFileSearch = (e) => {
    e.stopPropagation();
    e.preventDefault();
    fileRef.current.click();
  };

  /**
   * CSS 파일 선택시
   * @param {*} e
   * @returns
   */
  const onChangeInputFile = (e) => {
    const file = e.target.files[0];
    if (!StringUtils.equalsIgnoreCase(file.type, "text/css")) {
      fileRef.current.value = null;
      setStyleNm("");
      return Message.alert(
        "Only style files are allowed.",
        Enums.MessageType.ERROR
      );
    }
    setStyleNm(file.name);
    const reader = new FileReader();

    reader.onload = () => {
      const resultString = reader.result;
      setCodeMirrorContents(resultString);
    };

    reader.readAsText(file);
  };

  const onChangeCodeMirror = (value, viewUpdate) => {
    setCodeMirrorContents(value);
  };

  /**
   * 스타일 적용
   * @param {*} e
   */
  const onApplyStlye = (e) => {
    StyleUtils.ApplyStyle(dispatch, {
      ...workspace,
      styleId,
      styleNm,
      styleCode,
      apply: true,
    });
  };

  const onApplyOffStyle = (e) => {
    StyleUtils.ApplyOffStyle(dispatch);
  };

  /**
   * 저장
   * @param {*} e
   */
  const onSaveCss = (e) => {
    e.preventDefault();
    const body = {
      styleId,
      styleNm,
      styleCode: JSON.stringify(styleCode),
      builderDefaultYn: "Y",
      defaultYn: "N",
    };

    if (StringUtils.isEmpty(body.styleNm)) {
      return Message.alert("Please enter file name.", Enums.MessageType.ERROR);
    }
    if (StringUtils.isEmpty(body.styleCode)) {
      return Message.alert("Please enter style code.", Enums.MessageType.ERROR);
    }
    SettingService.saveStyle(body, (res) => {
      Message.alert(
        "Saved and applied successfully.",
        Enums.MessageType.SUCCESS
      );
      setStyleId(res.data.styleId);
      setStyleNm(res.data.styleNm);
      setStyleCode(JSON.parse(res.data.styleCode));
    });
  };
  return (
    <>
      <div className="setting-header">Custom Style</div>
      <div className="setting-container custom">
        <SearchBar>
          <div className="search" style={{ height: "100%" }}>
            <div className="fileSearch">
              <span>Style Name</span>
              <input
                className="fileName"
                placeholder="Please enter the name of the CSS or select a file."
                value={styleNm}
                onChange={(e) => setStyleNm(e.target.value)}
              />
              <Tooltip title="Search file in the local system">
                <Button variant="outline-primary" onClick={onClickFileSearch}>
                  Search file
                </Button>
              </Tooltip>
            </div>
            <input
              ref={fileRef}
              type={"file"}
              accept="text/css"
              onChange={onChangeInputFile}
            />
          </div>
        </SearchBar>
        <CodeMirror
          style={{
            height: "100%",
            textAlign: "initial",
            color: "black",
            border: "1px solid lightgray",
            borderRadius: "5px",
          }}
          height={"100%"}
          value={styleCode}
          className="source-container"
          extensions={[css(true)]}
          autoFocus={false}
          editable={true}
          onChange={onChangeCodeMirror}
        />
      </div>
      <div className="setting-button split">
        <div>
          <Button variant="primary" onClick={onApplyStlye}>
            Apply Style
          </Button>
          {styleRedux.apply && (
            <Button variant="outline-secondary" onClick={onApplyOffStyle}>
              Cancel Style Application
            </Button>
          )}
        </div>
        <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
          <Button variant="success" onClick={onSaveCss}>
            Save & Apply
          </Button>
        </div>
      </div>
    </>
  );
}

export default BuilderStyle;
