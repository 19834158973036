import React, { Component } from "react";
import PropTypes from "prop-types";
import ProcessModal from "./ProcessModal";
import { Col, Form, Row } from "react-bootstrap";
import Modal from "components/common/modal/UModal";
import UModalTemplate from "components/common/modal/UModalTemplate";

/**
 * Iterator 안에서 지시자를 추가하는 팝업
 */
/*
    {
      "compId": "urvthq2466lg",
      "type": "process",
      "processType": "loopControlKeyword",
      "propertyValue": {
        "processNm": "지시자 명",
        "loopControlKeyword": "continue"
      }
    }
*/
class LoopControlKeyword extends ProcessModal {
  constructor(props) {
    super(props);
    this.state = {
      processNm: "",
      loopControlKeyword: "break",
    };
  }
  componentDidMount() {
    if (this.props.processInfo) {
      this.setState(this.props.processInfo);
    }
  }

  /**
   * 모달 렌더링 부분
   * @returns
   */
  renderModal() {
    return (
      <Modal>
        <Modal.Header title={`Loop Control`} />
        <Modal.Body>
          <UModalTemplate className={"workflow-modal-template"}>
            {this.renderProcessNm()}
            {this.renderBody()}
          </UModalTemplate>
        </Modal.Body>
        <Modal.Footer>{this.renderFooter()}</Modal.Footer>
      </Modal>
    );
  }

  renderProcessNm() {
    return (
      <Row className="mb-3">
        <Col xs={2} className="col-label">
          <Form.Label className="required">Iterator Control Name</Form.Label>
        </Col>
        <Col xs={10}>
          <Form.Control
            placeholder="Please enter Iterator Conrol Name"
            value={this.getProcessNm()}
            onChange={this.onChangeParam}
            id={"processNm"}
          />
        </Col>
      </Row>
    );
  }

  renderBody() {
    return (
      <>
        <Row className="mb-3">
          <Col xs={2} className="col-label">
            <Form.Label className="required">Control Type</Form.Label>
          </Col>
          <Col xs={10}>
            <Form.Select
              onChange={this.onChangeParam}
              id={"loopControlKeyword"}
              value={this.state.loopControlKeyword}
            >
              <option value={"continue"}>Continue</option>
              <option value={"break"}>Break</option>
            </Form.Select>
          </Col>
        </Row>
      </>
    );
  }
}

export default LoopControlKeyword;
