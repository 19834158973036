import React, { Fragment, useEffect } from "react";
import { Accordion, Button, InputGroup } from "react-bootstrap";
import BootstrapSwitchButton from "bootstrap-switch-button-react";

import FormComponent from "components/builder/ui/uiComponents/form/FormComponent";
import {
  PropertyLable,
  PropertyValue,
} from "components/builder/ui/uiComponents/UIComponentStyle";
import * as Enums from "components/builder/BuilderEnum";
import {
  JsonUtils,
  NumberUtils,
  StringUtils,
} from "components/common/utils/CommonUtils";
import UIComponentSection from "components/builder/ui/editor/UIComponentSection";
import Popup from "components/common/Popup";
import ExtendPopup from "page/popup/ExtendPopup";
import USelectbox from "components/common/element/USelectbox";
import { AiOutlineMinusCircle } from "react-icons/ai";
import { useState } from "react";
import { BsArrowReturnRight, BsThreeDots } from "react-icons/bs";
import DefaultInputPopup from "page/popup/DefaultInputPopup";
import { Checkbox, FormControlLabel } from "@mui/material";
import { FaAngleUp } from "react-icons/fa";

class Form extends FormComponent {
  constructor(props) {
    super(props);

    this.btnSwitchChange = this.btnSwitchChange.bind(this);
    this.openPopupExtend = this.openPopupExtend.bind(this);
    this.onChangeHiddenValue = this.onChangeHiddenValue.bind(this);
    this.onAddHiddenValue = this.onAddHiddenValue.bind(this);
    this.onRemoveHiddenValue = this.onRemoveHiddenValue.bind(this);
    this.onHiddenDefaultPopupOpen = this.onHiddenDefaultPopupOpen.bind(this);
    this.onChangeDataEntity = this.onChangeDataEntity.bind(this);
  }

  btnSwitchChange = (id, val) => {
    this.onChangePropertyValue(id, val);
  };

  openPopupExtend = (e) => {
    const options = {
      keyDownEvent: false,
      effect: Popup.ScaleUp, //Effect.SlideFromTop(default)를 Effect.ScaleUp 로 변경
      style: {
        content: {},
      },
    };

    Popup.open(
      <ExtendPopup
        title="Parameters Details"
        defaultValue={this.state.propertyValue.parameters}
        fieldType="json"
        callbackFnc={(val) => {
          if (val) {
            this.setInputValue("input", "parameters", val);
          }
        }}
      />,
      options
    );
  };

  onChangeHiddenValue = (e, idx) => {
    const prevValue = [...this.state.propertyValue.hiddens];
    const hiddentValus = [...prevValue];

    hiddentValus[idx] = {
      ...prevValue[idx],
      [e.target.name]: e.target.value,
    };
    this.onChange({
      target: {
        id: "hiddens",
        value: [...hiddentValus],
      },
    });
  };

  onAddHiddenValue = (e) => {
    e.preventDefault();
    const prevValue = this.state.propertyValue.hiddens
      ? this.state.propertyValue.hiddens
      : [];
    const hiddensValue = [
      ...prevValue,
      {
        id: "",
        dataBinding: "",
        default: "",
      },
    ];
    this.onChange({
      target: {
        id: "hiddens",
        value: [...hiddensValue],
      },
    });
  };
  onRemoveHiddenValue = (e, idx) => {
    const prevValue = [...this.state.propertyValue.hiddens];
    prevValue.splice(idx, 1);
    this.onChange({
      target: {
        id: "hiddens",
        value: [...prevValue],
      },
    });
  };
  onChangeDataEntity = (e) => {
    const formChanger = { [e.target.id]: e.target.value };
    if (e.target._data) {
      formChanger.entityType = e.target._data.entityType || "TABLE";
      if (
        StringUtils.includesIgnoreCase(formChanger.entityType, [
          "PROCEDURE",
          "FUNCTION",
        ])
      ) {
        formChanger.insertOption = this.settingInsertOption({
          parameterList: JSON.parse(e.target._data.parameterList),
          procedureName: e.target._data.tableNm,
        });
      } else {
        formChanger.insertOption = null;
      }
    }
    this.onChangePropertyValues(formChanger);
  };

  onHiddenDefaultPopupOpen = (e, idx) => {
    //팝업창 열기
    const options = {
      effect: Popup.ScaleUp, //Effect.SlideFromTop(default)를 Effect.ScaleUp 로 변경
      style: {
        content: {
          width: "45%",
        },
      },
    };
    Popup.open(
      <DefaultInputPopup
        id={"default"}
        workspace={this.props.workspace}
        onChooseDefaultValue={(e) => {
          const result = {
            target: {
              ...e.target,
              name: "default",
            },
          };
          this.onChangeHiddenValue(result, idx);
        }}
      />,
      options
    );
    if (e) e.preventDefault();
  };

  /**
   * Properties tab panel을 Redering
   * @returns
   */
  renderPropertiesPanel = () => {
    return (
      <React.Fragment>
        {/* Title */}
        {this.renderComponentTitle("Form")}
        <Accordion defaultActiveKey={[0, 1, 2]} alwaysOpen>
          <Accordion.Item eventKey={0}>
            <Accordion.Header>Basic Info</Accordion.Header>
            <Accordion.Body>
              <PropertyLable requried="true">ID</PropertyLable>
              <PropertyValue>
                <input
                  type="text"
                  id="id"
                  defaultValue={StringUtils.defaultString(
                    this.state.propertyValue.id
                  )}
                  className="form-control form-control-sm"
                  onBlur={this.onChange}
                />
              </PropertyValue>
              <PropertyLable>Data Entity</PropertyLable>
              <PropertyValue>
                <USelectbox
                  type="search"
                  id="dataModelEntityId"
                  defaultValue={StringUtils.defaultString(
                    this.state.propertyValue.dataModelEntityId
                  )}
                  onChange={this.onChangeDataEntity}
                  url="/datamodel/getDataModelEntityList"
                  params={{
                    dataModelId: this.getDataModel(),
                  }}
                  options={{
                    matchId: "id",
                    matchNm: "text",
                  }}
                />
              </PropertyValue>
              <PropertyLable requried="true">Form Type</PropertyLable>
              <PropertyValue>
                <USelectbox
                  id="formType"
                  onChange={this.onChange}
                  defaultValue={StringUtils.defaultString(
                    this.state.propertyValue.formType,
                    Enums.FormType.SAVE
                  )}
                  items={[
                    { id: Enums.FormType.SEARCH, text: "Search Form" },
                    { id: Enums.FormType.SAVE, text: "Save Form" },
                  ]}
                  options={{ matchCd: "id", matchNm: "text" }}
                />
              </PropertyValue>{" "}
              <PropertyLable>Progress</PropertyLable>
              <PropertyValue>
                <BootstrapSwitchButton
                  id="isProgress"
                  checked={StringUtils.defaultString(
                    this.state.propertyValue.isProgress,
                    false
                  )}
                  size="sm"
                  onstyle="primary"
                  offstyle="dark"
                  onlabel="Yes"
                  offlabel="No"
                  onChange={(val) => this.btnSwitchChange("isProgress", val)}
                />
              </PropertyValue>
              <PropertyLable>Accordian Settings</PropertyLable>
              <PropertyValue>
                <InputGroup size="sm">
                  <BootstrapSwitchButton
                    id="isCollapse"
                    checked={StringUtils.defaultString(
                      this.state.propertyValue.isCollapse,
                      false
                    )}
                    size="sm"
                    onstyle="primary"
                    offstyle="dark"
                    onlabel="Yes"
                    offlabel="No"
                    onChange={(val) => this.btnSwitchChange("isCollapse", val)}
                  />
                  {/* {this.state.propertyValue.isCollapse === true ? (
                    <Fragment>
                      <InputGroup.Text> Default</InputGroup.Text>
                      <USelectbox
                        id="defaultCollapse"
                        onChange={this.onChange}
                        defaultValue={StringUtils.defaultString(
                          this.state.propertyValue.defaultCollapse,
                          "expand"
                        )}
                        items={[
                          { id: "expand", text: "펼치기" },
                          {
                            id: "collapse",
                            text: "접기",
                          },
                        ]}
                        options={{ matchCd: "id", matchNm: "text" }}
                      />
                    </Fragment>
                  ) : (
                    ""
                  )} */}
                </InputGroup>
              </PropertyValue>
              {this.state.propertyValue.isCollapse === true ? (
                <Fragment>
                  {this.state.propertyValue.formType ===
                  Enums.FormType.SEARCH ? (
                    <Fragment>
                      <PropertyLable>Accordian Options</PropertyLable>
                      <PropertyValue>
                        <USelectbox
                          id="collapseOption"
                          type="common"
                          mstCd="Z0046"
                          onChange={this.onChange}
                          defaultValue={StringUtils.defaultString(
                            this.state.propertyValue.collapseOption,
                            "manual"
                          )}
                        />
                      </PropertyValue>
                      {this.state.propertyValue.collapseOption === "manualn" ||
                      this.state.propertyValue.collapseOption === "autonn" ? (
                        <Fragment>
                          <PropertyLable>
                            <BsArrowReturnRight></BsArrowReturnRight> Excluded
                            Rows
                          </PropertyLable>
                          <PropertyValue>
                            <InputGroup size="sm">
                              <input
                                type="number"
                                id="exceptRowNo"
                                defaultValue={NumberUtils.defaultValue(
                                  this.state.propertyValue.exceptRowNo,
                                  1
                                )}
                                onBlur={this.onChange}
                                className="form-control form-control-sm"
                              />
                              <InputGroup.Text>until this row</InputGroup.Text>
                            </InputGroup>
                          </PropertyValue>
                        </Fragment>
                      ) : (
                        ""
                      )}
                    </Fragment>
                  ) : (
                    ""
                  )}
                </Fragment>
              ) : (
                ""
              )}
              <PropertyLable>ALL Disabled</PropertyLable>
              <PropertyValue>
                <BootstrapSwitchButton
                  id="isAllDisabled"
                  checked={StringUtils.defaultString(
                    this.state.propertyValue.isAllDisabled,
                    false
                  )}
                  size="sm"
                  onstyle="primary"
                  offstyle="dark"
                  onlabel="Yes"
                  offlabel="No"
                  onChange={(val) => this.btnSwitchChange("isAllDisabled", val)}
                />
              </PropertyValue>
              {this.state.propertyValue.formType === Enums.FormType.SAVE ? (
                <Fragment>
                  <PropertyLable>Grid Refresh</PropertyLable>
                  <PropertyValue>
                    <FormControlLabel
                      control={
                        <Checkbox
                          id="afterSubmitGridRefresh"
                          checked={StringUtils.defaultString(
                            this.state.propertyValue.afterSubmitGridRefresh,
                            true
                          )}
                          onChange={(e) =>
                            this.onChangePropertyValue(
                              "afterSubmitGridRefresh",
                              e.target.checked
                            )
                          }
                          size="small"
                        />
                      }
                      label={
                        <span style={{ fontSize: "11px", fontWeight: "100" }}>
                          Auto-refresh grid in Form after saving Data
                        </span>
                      }
                    />
                  </PropertyValue>
                </Fragment>
              ) : (
                ""
              )}
            </Accordion.Body>
          </Accordion.Item>

          {/* Initial Data Lookup Event Accordion */}
          <Accordion.Item eventKey={1}>
            <Accordion.Header>Hiddens</Accordion.Header>
            <Accordion.Body>
              <PropertyLable></PropertyLable>
              <PropertyValue
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <Button
                  variant="outline-secondary"
                  className={
                    this.props.mobile.isMobileEditor
                      ? "mobile-font-color"
                      : "light-font-color"
                  }
                  size="sm"
                  onClick={this.onAddHiddenValue}
                >
                  add Hidden value
                </Button>
              </PropertyValue>

              {this.state.propertyValue.hiddens?.length > 0 && (
                <>
                  <PropertyLable className="w-30p">ID</PropertyLable>
                  <PropertyLable className="pl-5 w-30p">
                    Data Binding
                  </PropertyLable>
                  <PropertyLable className="pl-5 w-30p">
                    Default Value
                  </PropertyLable>
                  {this.state.propertyValue.hiddens.map((hidden, idx) => (
                    <React.Fragment key={idx}>
                      <PropertyValue className="pl-0 w-30p">
                        <HiddenInput
                          type="text"
                          name="id"
                          defaultValue={JsonUtils.defaultString(hidden, "id")}
                          className="form-control form-control-sm"
                          onBlur={(e) => this.onChangeHiddenValue(e, idx)}
                        />
                      </PropertyValue>
                      <PropertyValue className="w-30p">
                        <USelectbox
                          type="entityField"
                          name="dataBinding"
                          defaultValue={JsonUtils.defaultString(
                            hidden,
                            "dataBinding"
                          )}
                          onChange={(e) => this.onChangeHiddenValue(e, idx)}
                          entityId={
                            this.state.propertyValue.dataModelEntityId
                              ? this.state.propertyValue.dataModelEntityId
                              : ""
                          }
                          options={{
                            matchId: "id",
                            matchNm: "text",
                          }}
                        />
                      </PropertyValue>
                      <PropertyValue className="w-30p">
                        <HiddenInput
                          type="text"
                          name="default"
                          defaultValue={JsonUtils.defaultString(
                            hidden,
                            "default"
                          )}
                          className="form-control form-control-sm"
                          onBlur={(e) => this.onChangeHiddenValue(e, idx)}
                          popup={(e) => this.onHiddenDefaultPopupOpen(e, idx)}
                        />
                      </PropertyValue>
                      <PropertyLable
                        className="pl-5 w-10p"
                        style={{ display: "flex", alignItems: "center" }}
                        onClick={(e) => this.onRemoveHiddenValue(e, idx)}
                      >
                        <AiOutlineMinusCircle
                          size={20}
                          style={{ cursor: "pointer" }}
                        />
                      </PropertyLable>
                    </React.Fragment>
                  ))}
                </>
              )}
            </Accordion.Body>
          </Accordion.Item>
          {/* <Accordion.Item eventKey={2}>
            <Accordion.Header>Initial Data Lookup Event</Accordion.Header>
            <Accordion.Body>
              <PropertyLable className="w-full">Before Data Load</PropertyLable>
              <PropertyValue className="w-full">
                <UTextarea
                  popTitle="Before Data Load 상세"
                  textareaId="beforeLoad"
                  defaultValue={StringUtils.defaultString(
                    this.state.propertyValue.beforeLoad,
                    ""
                  )}
                  onBlur={this.onChange}
                />
              </PropertyValue>

              <PropertyLable className="w-full">After Data Load</PropertyLable>
              <PropertyValue className="w-full">
                <UTextarea
                  popTitle="After Data Load 상세"
                  textareaId="afterLoad"
                  defaultValue={StringUtils.defaultString(
                    this.state.propertyValue.afterLoad
                  )}
                  onBlur={this.onChange}
                />
              </PropertyValue>
            </Accordion.Body>
          </Accordion.Item> */}

          {/* Style property */}
          {this.renderStylePanel("Form", 99)}
        </Accordion>
      </React.Fragment>
    );
  };

  /**
   * Editor의 component를 Redering
   * <<form editor props>>
   *   - compId - 현재 component의 고유 ID
   *   - dropComponentInfo - drag & drop시 생성된 component object
   *   - style - dragging style이 포함된 style (사용자가 정의한 style은 각 component에서 적절히 적용해야함)
   *   - event="renderEditor" - 요청 구분
   * @returns
   */
  renderEditor = () => {
    return (
      <UIComponentSection
        item={this.props.componentInfo}
        style={this.props.style}
        className={`editor-form ${this.getEditorClassName()} ${
          StringUtils.isEmpty(this.state.propertyValue.className)
            ? ""
            : this.state.propertyValue.className
        }`}
        id={`${
          StringUtils.isEmpty(this.state.propertyValue.id)
            ? ""
            : this.state.propertyValue.id
        }`}
      >
        {/* <MCircularProgress /> */}
        {this.props.children}
        {this.props.componentInfo.propertyValue.formType ===
          Enums.FormType.SEARCH &&
        this.props.componentInfo.propertyValue.isCollapse === true ? (
          <button type="button" className="btn form-collapse-btn">
            <FaAngleUp />
          </button>
        ) : (
          ""
        )}
      </UIComponentSection>
    );
  };
}
export default Form;

const HiddenInput = ({
  type,
  name,
  defaultValue,
  value,
  className,
  onBlur,
  popup,
  ...props
}) => {
  const [inputValue, setInputValue] = useState(defaultValue);
  useEffect(() => {
    setInputValue(defaultValue);
  }, [defaultValue]);

  if (popup) {
    return (
      <div
        className="input-container"
        style={{ width: "100%", display: "flex", borderCollapse: "collapse" }}
      >
        <input
          type={type}
          name={name}
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          className={className}
          onBlur={onBlur}
          style={{
            width: "calc(100% - 20px)",
            borderRight: "none",
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
          }}
          {...props}
        />
        <button
          className="btn btn-sm"
          onClick={(e) => popup.call(this, e)}
          formTarget={name}
          style={{
            width: "20px",
            background: "lightgray",
            borderRadius: 0,
            borderTopRightRadius: 2,
            borderBottomRightRadius: 2,
          }}
        >
          <BsThreeDots size={8} />
        </button>
      </div>
    );
  } else {
    return (
      <input
        type={type}
        name={name}
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        className={className}
        onBlur={onBlur}
        {...props}
      />
    );
  }
};
