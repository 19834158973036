import React, { Fragment } from "react";
import { Accordion, InputGroup } from "react-bootstrap";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import { FaPlus, FaFilter, FaCaretDown } from "react-icons/fa";
import { BsPinAngleFill } from "react-icons/bs";
import { MdSettings } from "react-icons/md";
import { Checkbox as MCheckbox } from "@mui/material";

import {
  PropertyLable,
  PropertyValue,
} from "components/builder/ui/uiComponents/UIComponentStyle";
import Grid from "components/builder/ui/uiComponents/grid/Grid";
import UIComponentSection from "components/builder/ui/editor/UIComponentSection";
import * as Enums from "components/builder/BuilderEnum";

import { StringUtils, ObjectUtils } from "components/common/utils/CommonUtils";
import { AppContext } from "components/common/AppContextProvider";
import UITemplateHelper from "components/builder/ui/editor/helper/UITemplateHelper";
import USelectbox from "components/common/element/USelectbox";
import UPopover from "components/common/element/UPopover";

class GridHeader extends Grid {
  constructor(props) {
    super(props);
    //불필요하면 constructor 지우세요.

    this.fnClickAdd = this.fnClickAdd.bind(this);
    this.getDefaultAlign = this.getDefaultAlign.bind(this);
  }

  static contextType = AppContext;

  /* Row 추가 */
  fnClickAdd = (e) => {
    const componentContext = this.context.component;
    /* template 용 표준 base components - e.g) page, form, row, col... */
    const templateComponents =
      UITemplateHelper.getTemplateComponents(componentContext);
    /* Template에 있는 Grid Components 불러오기 */
    const gridTemplate = UITemplateHelper.getGridTemplate(
      templateComponents,
      Enums.ComponentType.GRID
    );
    let columnDefault = { ...gridTemplate.propertyValue.gridColumns }; //database에 등록한 default 항목

    //신규 추가 항목
    let columnNew = {
      title: "New Column",
      gridId: this.state.propertyValue.gridId,
    };

    const newPropertyValue = Object.assign(columnDefault, columnNew); //병합
    this.props.fn.addComponent(newPropertyValue, "A"); //"B" : Before, "A" : After (default)
  };

  getDefaultAlign = (args, id) => {
    let dataType = "";
    if (StringUtils.isEmpty(id)) {
      id = "className";
      dataType = args.dataType || "";
      if (dataType === "") {
        return;
      }
    } else {
      dataType = StringUtils.defaultString(args.state.propertyValue.dataType);
    }
    if (dataType.includes("numeric") || dataType.includes("number")) {
      return "header-right";
    } else {
      return "header-left";
    }
  };

  /**
   * Properties tab panel을 Redering
   * @returns
   */
  renderPropertiesPanel = () => {
    return (
      <React.Fragment>
        {/* Title */}
        {this.renderComponentTitle("Grid > Header", false)}
        <Accordion defaultActiveKey={[0, 1, 2]} alwaysOpen>
          <Accordion.Item eventKey={0}>
            <Accordion.Header>Basic Info</Accordion.Header>
            <Accordion.Body>
              <PropertyLable requried="true">Header ID</PropertyLable>
              <PropertyValue>
                <input
                  type="text"
                  id="name"
                  defaultValue={StringUtils.defaultString(
                    this.state.propertyValue.name
                  )}
                  className="form-control form-control-sm float-left"
                  onBlur={(e) => {
                    if (
                      ObjectUtils.isEmpty(
                        this.props.componentInfo.gridOptions.dataModelEntityId
                      )
                    ) {
                      this.onChangePropertyValues({
                        [e.target.id]: e.target.value,
                        data: e.target.value,
                      });
                    } else {
                      this.onChange(e);
                    }
                  }}
                  style={{ width: "89%" }}
                />
                <button
                  style={{ color: "#ffffff" }}
                  id="btnAdd"
                  className="btn btn-sm btn-add float-left"
                  onClick={this.fnClickAdd}
                >
                  <FaPlus />
                </button>
              </PropertyValue>
              <PropertyLable requried="true">Header Title</PropertyLable>
              <PropertyValue>
                <input
                  type="text"
                  id="title"
                  defaultValue={StringUtils.defaultString(
                    this.state.propertyValue.title
                  )}
                  className="form-control form-control-sm"
                  onBlur={this.onChange}
                />
              </PropertyValue>
              <PropertyLable>Header Align</PropertyLable>
              <PropertyValue>
                <USelectbox
                  type="common"
                  mstCd="Z0034"
                  id="className"
                  defaultValue={StringUtils.defaultString(
                    this.state.propertyValue.className,
                    this.getDefaultAlign(this, "className")
                  )}
                  onChange={this.onChange}
                />
              </PropertyValue>
              <PropertyLable>Description</PropertyLable>
              <PropertyValue>
                <input
                  type="text"
                  id="description"
                  defaultValue={StringUtils.defaultString(
                    this.state.propertyValue.description
                  )}
                  className="form-control form-control-sm"
                  onBlur={this.onChange}
                />
              </PropertyValue>
              <PropertyLable>Display</PropertyLable>
              <PropertyValue>
                <BootstrapSwitchButton
                  id="visible"
                  checked={StringUtils.defaultString(
                    this.state.propertyValue.visible
                  )}
                  size="sm"
                  onstyle="primary"
                  offstyle="dark"
                  onlabel="Yes"
                  offlabel="No"
                  onChange={(value) =>
                    this.onChangePropertyValue("visible", value)
                  }
                />
              </PropertyValue>
              <PropertyLable>Width</PropertyLable>
              <PropertyValue>
                <InputGroup>
                  <input
                    type="text"
                    id="width"
                    defaultValue={StringUtils.defaultString(
                      this.state.propertyValue.width
                    )}
                    className="form-control form-control-sm"
                    onBlur={this.onChange}
                  />
                  <UPopover title="Usage Example">
                    <ul>
                      <li>▣ * : Equal Division</li>
                      <li>
                        ▣ 2*, 3* : Double or triple the size of the equal split
                      </li>
                      <li>
                        ▣ 100, 200 : 100px, 200px
                        <br /> - <font color="red">Number only</font>
                        <br /> - If the sum of all header widths exceeds the
                        screen size, a horizontal scroll bar appears at the
                        bottom of the grid.
                      </li>
                    </ul>
                  </UPopover>
                </InputGroup>
              </PropertyValue>
            </Accordion.Body>
          </Accordion.Item>

          {/* 일반 설정 */}
          <Accordion.Item eventKey={1}>
            <Accordion.Header>Default Setting</Accordion.Header>
            <Accordion.Body>
              <PropertyLable className="w-33p">Allow Sorting</PropertyLable>
              <PropertyLable className="w-33p">Allow Resizing</PropertyLable>
              <PropertyLable className="w-33p">
                Allow Moving Header
              </PropertyLable>
              <PropertyValue className="w-33p">
                <BootstrapSwitchButton
                  id="orderable"
                  checked={StringUtils.defaultString(
                    this.state.propertyValue.orderable
                  )}
                  size="sm"
                  onstyle="primary"
                  offstyle="dark"
                  onlabel="Yes"
                  offlabel="No"
                  onChange={(value) =>
                    this.onChangePropertyValue("orderable", value)
                  }
                />
              </PropertyValue>
              <PropertyValue className="w-33p">
                <BootstrapSwitchButton
                  id="resizable"
                  checked={StringUtils.defaultString(
                    this.state.propertyValue.resizable
                  )}
                  size="sm"
                  onstyle="primary"
                  offstyle="dark"
                  onlabel="Yes"
                  offlabel="No"
                  onChange={(value) =>
                    this.onChangePropertyValue("resizable", value)
                  }
                />
              </PropertyValue>
              <PropertyValue className="w-33p">
                <BootstrapSwitchButton
                  id="reorder"
                  checked={StringUtils.defaultString(
                    this.state.propertyValue.reorder
                  )}
                  size="sm"
                  onstyle="primary"
                  offstyle="dark"
                  onlabel="Yes"
                  offlabel="No"
                  onChange={(value) =>
                    this.onChangePropertyValue("reorder", value)
                  }
                />
              </PropertyValue>
              <PropertyLable className="w-33p">
                Allow Custom Setting
              </PropertyLable>
              <PropertyLable className="w-33p"></PropertyLable>
              <PropertyLable className="w-33p"></PropertyLable>
              <PropertyValue className="w-33p">
                <BootstrapSwitchButton
                  id="allowChangeSettings"
                  checked={StringUtils.defaultString(
                    this.state.propertyValue.allowChangeSettings
                  )}
                  size="sm"
                  onstyle="primary"
                  offstyle="dark"
                  onlabel="Yes"
                  offlabel="No"
                  onChange={(value) =>
                    this.onChangePropertyValue("allowChangeSettings", value)
                  }
                />
              </PropertyValue>
            </Accordion.Body>
          </Accordion.Item>

          {/* Header Merge */}
          <Accordion.Item eventKey={2}>
            <Accordion.Header>Header Merge</Accordion.Header>
            <Accordion.Body>
              <PropertyLable>
                1<sup>st</sup> Group ID
              </PropertyLable>
              <PropertyValue>
                <input
                  type="text"
                  id="groupName"
                  defaultValue={StringUtils.defaultString(
                    this.state.propertyValue.groupName
                  )}
                  className="form-control form-control-sm"
                  onBlur={this.onChange}
                />
              </PropertyValue>
              <PropertyLable>
                1<sup>st</sup> Group Title
              </PropertyLable>
              <PropertyValue>
                <input
                  type="text"
                  id="groupTitle"
                  defaultValue={StringUtils.defaultString(
                    this.state.propertyValue.groupTitle
                  )}
                  className="form-control form-control-sm"
                  onBlur={this.onChange}
                />
              </PropertyValue>
              {!StringUtils.isEmpty(this.state.propertyValue.groupName) ? (
                <>
                  <PropertyLable>Header Align</PropertyLable>
                  <PropertyValue>
                    <InputGroup>
                      <USelectbox
                        type="common"
                        mstCd="Z0034"
                        id="groupClassName"
                        defaultValue={StringUtils.defaultString(
                          this.state.propertyValue.groupClassName,
                          this.getDefaultAlign(this, "className")
                        )}
                        onChange={this.onChange}
                      />
                      <UPopover title="Default Setting">
                        <ul>
                          <li>
                            If the group is the same, the sorting of the first
                            column in the group is applied.
                          </li>
                        </ul>
                      </UPopover>
                    </InputGroup>
                  </PropertyValue>
                </>
              ) : (
                ""
              )}

              <PropertyLable>
                2<sup>nd</sup> Group ID
              </PropertyLable>
              <PropertyValue>
                <input
                  type="text"
                  id="groupName2"
                  defaultValue={StringUtils.defaultString(
                    this.state.propertyValue.groupName2
                  )}
                  className="form-control form-control-sm"
                  onBlur={this.onChange}
                />
              </PropertyValue>
              <PropertyLable>
                2<sup>nd</sup>
                <span style={{ fontSize: "11.4px" }}> Group Title</span>
              </PropertyLable>
              <PropertyValue>
                <input
                  type="text"
                  id="groupTitle2"
                  defaultValue={StringUtils.defaultString(
                    this.state.propertyValue.groupTitle2
                  )}
                  className="form-control form-control-sm"
                  onBlur={this.onChange}
                />
              </PropertyValue>
              {!StringUtils.isEmpty(this.state.propertyValue.groupName2) ? (
                <>
                  <PropertyLable>Header Align</PropertyLable>
                  <PropertyValue>
                    <InputGroup>
                      <USelectbox
                        type="common"
                        mstCd="Z0034"
                        id="groupClassName2"
                        defaultValue={StringUtils.defaultString(
                          this.state.propertyValue.groupClassName2,
                          this.getDefaultAlign(this, "className")
                        )}
                        onChange={this.onChange}
                      />
                      <UPopover title="Usage Example">
                        <ul>
                          <li>
                            If the group is the same, the sorting of the first
                            column in the group is applied.
                          </li>
                        </ul>
                      </UPopover>
                    </InputGroup>
                  </PropertyValue>
                </>
              ) : (
                ""
              )}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </React.Fragment>
    );
  };

  /**
   * Editor의 component를 Redering
   * <<Grid editor props>>
   *   - compId - 현재 component의 고유 ID
   *   - componentInfo - drag & drop시 생성된 component object
   *   - style - dragging style이 포함된 style (사용자가 정의한 style은 각 component에서 적절히 적용해야함)
   *   - event="renderEditor" - 요청 구분
   * @returns
   */
  renderEditor = () => {
    //GridCell,GridHeader 은 this.state.propertyValue 대신 this.props.componentInfo.propertyValue를 사용해야함. (editorAttr 마찬가지)
    const propertyValue = this.props.componentInfo.propertyValue || {};
    const editorAttr = this.props.componentInfo.editorAttr || {};
    const gridOptions = this.props.componentInfo.gridOptions || {};
    let colStyle = this.props.style || {};

    if (gridOptions.headerHeight) {
      colStyle.height = gridOptions.headerHeight + "px";
    }
    return (
      <UIComponentSection
        item={this.props.componentInfo}
        style={colStyle}
        noneToolbarTitle={true} //toolbar title 제거
        className={`editor-base draggable editor-grid-header ${StringUtils.defaultString(
          editorAttr.className
        )} ${propertyValue.visible ? "" : "grid-column-hide"}`}
      >
        <div className={`grid-header-title `}>
          {editorAttr.checkbox ? (
            <MCheckbox size="small" color="primary" defaultChecked={false} />
          ) : (
            <Fragment>
              <label
                className={`grid-label 
            ${propertyValue.required ? "required" : ""} ${
                  propertyValue.className
                }`}
              >
                {StringUtils.defaultString(propertyValue.title)}
              </label>
              <div className="grid-header-icon">
                {propertyValue.colvis ? (
                  <MdSettings size={"14"} className="grid-colvis" />
                ) : (
                  <Fragment>
                    {gridOptions.orderable && propertyValue.orderable ? (
                      <FaCaretDown size={"14"} />
                    ) : (
                      ""
                    )}
                    {gridOptions.pinning ? <BsPinAngleFill size={"10"} /> : ""}
                    {gridOptions.isFilter ? <FaFilter size={"8"} /> : ""}{" "}
                  </Fragment>
                )}
              </div>
            </Fragment>
          )}
        </div>
      </UIComponentSection>
    );
  };
}
export default GridHeader;
