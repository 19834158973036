import React, { Fragment, useEffect, useState } from "react";
import Modal from "components/common/modal/UModal";
import UmodalTemplate from "components/common/modal/UModalTemplate";
import StringUtils from "components/common/utils/StringUtils";
import EventhandlerService from "services/eventhandler/EventhandlerService";
import ArrayUtils from "components/common/utils/ArrayUtils";
import * as Enums from "components/builder/BuilderEnum";

const ComponentEventDocPopup = (props) => {
  const { eventType, eventCd, targetType, programType } = props.eventInfo; //props;
  const [loaded, setLoaded] = useState(false);
  const [eventHandlerMst, setEventHandlerMst] = useState([]);
  const [eventArgs, setEventArgs] = useState([]);
  const [pageVariables, setPageVariables] = useState([]);

  useEffect(() => {
    if (
      !StringUtils.isEmpty(eventType) &&
      !StringUtils.isEmpty(eventCd) &&
      !StringUtils.isEmpty(targetType) &&
      !StringUtils.isEmpty(programType)
    ) {
      const body = { eventType, eventCd, targetType, programType };
      EventhandlerService.getEventHandlerInfo(body, (res) => {
        setLoaded(true);
        setEventHandlerMst(res.data || []);
        setEventArgsVariables(res.data);
      });
    } else {
      setLoaded(true);
    }
  }, [props]);

  /**
   * Event argument 정보를 args (인다) 와 global variable 로 분리
   * @param {*} eventData
   * @returns
   */
  const setEventArgsVariables = (eventData) => {
    if (!eventData || !eventData.eventHandlerArgsList) return;

    let filteredArgs = eventData.eventHandlerArgsList.filter(
      (arg) => arg.argsVariableFlag === "A"
    );
    let filteredVar = eventData.eventHandlerArgsList.filter(
      (arg) => arg.argsVariableFlag === "G"
    );
    const combinedVariables = filteredVar.concat(Enums.EventGlobalVariables);

    setEventArgs(filteredArgs);
    setPageVariables(combinedVariables);
  };

  const getFunctionNameJoin = () => {
    if (ArrayUtils.isEmpty(eventArgs)) return "";

    let newArg = eventArgs.map((arg) => {
      return arg.argsVariable;
    });
    return newArg.join(", ");
  };

  return (
    <Modal>
      <Modal.Header title="Event Detail Explanation" />
      <Modal.Body>
        <UmodalTemplate>
          <Fragment>
            {loaded === false ? (
              <p>Loading Event Information...</p>
            ) : eventHandlerMst.length === 0 ? (
              <p>Event Information is Empty.</p>
            ) : (
              <Fragment>
                <h5>{eventHandlerMst.eventName} Processing Event</h5>
                <div className="doc-page-wrap">
                  <div
                    className="event-desc pt-1"
                    dangerouslySetInnerHTML={{
                      __html: eventHandlerMst.eventDesc,
                    }}
                  ></div>

                  <div className="event-code">
                    function ({getFunctionNameJoin()})
                  </div>
                  {!ArrayUtils.isEmpty(eventArgs) ? (
                    <div className="event-params pb-1">
                      <span className="doc-title"> Parameters</span>
                      <ul>
                        {eventArgs.map((args, index) => {
                          return (
                            <li key={index}>
                              <span className="event-args-var">
                                {args.argsVariable}
                              </span>
                              :
                              <span className="event-args-type">
                                {args.argsType}
                              </span>
                              -
                              <span className="event-args-name">
                                {args.argsName}
                              </span>
                              <div
                                className="event-args-desc"
                                dangerouslySetInnerHTML={{
                                  __html: args.argsDesc,
                                }}
                              ></div>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="event-return pb-1 pt-1">
                    <span className="doc-title"> Returns</span>
                    <span className="event-return-type">
                      {eventHandlerMst.returnType === "none"
                        ? "void"
                        : eventHandlerMst.returnType}
                    </span>
                    <div className="event-return-desc">
                      {eventHandlerMst.returnDesc}
                    </div>
                  </div>
                  {!ArrayUtils.isEmpty(pageVariables) ? (
                    <div className="event-params  pt-1">
                      <span className="doc-title"> Global Variables</span>
                      <ul>
                        {pageVariables.map((variable, index) => {
                          return (
                            <li key={index}>
                              <span className="event-args-var">
                                {variable.argsVariable}
                              </span>
                              (
                              <span className="event-args-type">
                                {variable.argsType}
                              </span>
                              ) :
                              <span className="event-args-name">
                                {variable.argsName}
                              </span>
                              <div
                                className="event-args-desc"
                                dangerouslySetInnerHTML={{
                                  __html: variable.argsDesc,
                                }}
                              />
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </Fragment>
            )}
          </Fragment>
        </UmodalTemplate>
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
};

export default ComponentEventDocPopup;
