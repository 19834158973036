import {
  ArrayUtils,
  ObjectUtils,
  StringUtils,
} from "@alpha/com.bizentro.daaf.front.framework";
import { Enums } from "components/builder/BuilderEnum";
import {
  updateEventOutput,
  updateEventWorkspace,
} from "components/builder/eventhandler/reducer/EventHandlerAction";
import UIReduxHelper from "components/builder/ui/editor/helper/UIReduxHelper";
import { setMemo } from "components/builder/ui/reducers/UIBuilderAction";
import AppContextProvider, {
  AppContext,
} from "components/common/AppContextProvider";
import User from "components/common/utils/UserUtils";
import "css/mobile/login-setting.css";
import PageTemplate from "page/common/PageTemplate";
import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import LayoutSettingService from "services/mobile/LayoutSettingService";
import ProgramService from "services/ui/ProgramService";

const LoginSetting = () => {
  const LAYOUT_TYPE = "L"; //L : 로그인
  const breadcrum = [
    {
      name: "Login Setting",
      url: Enums.BuilderPath.UI.MAIN + "/" + Enums.BuilderPath.UI.LIST,
      active: true,
    },
  ];

  const workspace = useSelector((state) => state.workspace);
  const output = useSelector((state) => state.outputUI.output);

  const [loginTmplList, setLoginTmplList] = useState([]);
  const [appLayoutLogin, setAppLayoutLogin] = useState({});
  const [loginCustomLayout, setLoginCustomLayout] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const { component } = useContext(AppContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    //선택된 데이터 가져오기
    LayoutSettingService.getAppLayoutList({ appId: workspace.appId }, (res) => {
      let loginData =
        res.data.find((item) => item.layoutType === LAYOUT_TYPE) || "";
      setAppLayoutLogin(loginData);
    });

    //데이터 없을 경우 u_program 생성, loginCustomLayout 데이터 받아옴
    const tmpProgram = {
      appId: workspace.appId,
      moduleCd: workspace.moduleCd,
      appReleaseId: workspace.appReleaseId,
      tenantId: "*",
      coCd: "*",
      programId: "MOBILE_PROGRAM",
      programNm: "MOBILE_PROGRAM",
      templateSaveYn: "N",
      templateShareYn: "N",
      appApplyType: "Y",
      useYn: "Y",
      programType: LAYOUT_TYPE,
      insertUserId: User.getId(), //추후 이쪽 데이터 수정해야될 거 같음.
      updtUserId: User.getId(),
      builderType: "M",
    };
    LayoutSettingService.getProgramInfo(tmpProgram, (res) => {
      setLoginCustomLayout(res.data[0]);
    });

    //템플릿 데이터 가져오기
    LayoutSettingService.getAllLayoutTmplList({}, (res) => {
      const tmpLoginTmplList = res.data.filter(
        (item) => item.layoutTmplType === LAYOUT_TYPE
      );
      setLoginTmplList(tmpLoginTmplList);
    });
  }, []);

  /**
   * Custom Editor로 이동 시 실행 함수
   */
  const moveToCustomEditor = (program) => {
    setIsLoading(true);

    if (typeof program === "undefined") {
      const componentInfo = component.getPageComponent();
      componentInfo.programType = Enums.MobileProgramType.LOGIN_PROGRAM;
      componentInfo.builderType = Enums.BuilderType.MOBILE;
      UIReduxHelper.createTypePage(dispatch, componentInfo);
      updateEventWorkspace({});
      updateEventOutput("");
    } else {
      ProgramService.getProgram(
        program,
        (res) => {
          const { programContent, dataModelList, ...information } = res.data;
          const componentInfo = component.getPageComponent(); //Page Component
          const { memo, ...output } = JSON.parse(programContent);
          UIReduxHelper.loadTemplate(
            dispatch,
            componentInfo,
            JSON.stringify(output)
          );
          information.programContent = JSON.parse(programContent);
          dispatch(updateEventWorkspace({}));
          dispatch(updateEventOutput(""));
          UIReduxHelper.updateInformation(dispatch, information);
          if (memo) dispatch(setMemo(memo));
          else dispatch(setMemo([]));
        },
        () => {
          throw new Error("프로그램을 호출 중 오류가 발생하였습니다.");
        }
      );
    }
    navigate(
      Enums.BuilderPath.MOBILE.MAIN + "/" + Enums.BuilderPath.MOBILE.EDITOR
    );
  };

  /**
   * 로그인 템플릿 클릭 이벤트
   * @param {*} item
   */
  const onClickLoginTmpl = (item, custom) => {
    const tmpSelectedTmpl = { ...appLayoutLogin };

    if (custom === "Y") {
      // custom일 경우
      setAppLayoutLogin(loginCustomLayout);
      tmpSelectedTmpl.layoutId = loginCustomLayout.programUid;
      tmpSelectedTmpl.appId = workspace.appId;
      tmpSelectedTmpl.customYn = "Y";
      tmpSelectedTmpl.layoutType = LAYOUT_TYPE;
    } else {
      //커스텀 아닐 경우
      tmpSelectedTmpl.layoutId = item.layoutTmplId;
      tmpSelectedTmpl.appId = workspace.appId;
      tmpSelectedTmpl.customYn = "N";
      tmpSelectedTmpl.layoutType = LAYOUT_TYPE;
    }

    //데이터 저장
    LayoutSettingService.saveAppLayoutData(tmpSelectedTmpl, (res) => {
      if (StringUtils.isEmpty(tmpSelectedTmpl.appLayoutId)) {
      }
      tmpSelectedTmpl.appLayoutId = res.data.appLayoutId;
      setAppLayoutLogin(tmpSelectedTmpl);
    });
  };

  /**
   * 로그인 템플릿 render 함수
   * @param {*} item
   * @returns
   */
  const renderLoginTmpl = (item, custom) => {
    if (ObjectUtils.isEmpty(item)) {
      return;
    }
    if (item.defaultYn === "Y") {
      //템플릿 중에서도 default
      return (
        <Col
          sm={3}
          className={
            appLayoutLogin.layoutId === item.layoutTmplId
              ? "layout-selected"
              : "layout-sample"
          }
          onClick={(e) => {
            onClickLoginTmpl(item, custom);
          }}
        >
          <div
            style={{
              fontSize: "25px",
              fontWeight: "600",
              textAlign: "center",
              marginTop: "80px",
            }}
          >
            BIZENTRO
          </div>
          <div
            style={{
              border: "1px solid lightgray",
              height: "45px",
              width: "170px",
              borderRadius: "10px",
              margin: "20px auto 10px auto",
              color: "lightgray",
              padding: "10px",
              fontSize: "14px",
            }}
          >
            ID
          </div>
          <div
            style={{
              border: "1px solid lightgray",
              height: "45px",
              width: "170px",
              borderRadius: "10px",
              marginTop: "10px",
              margin: "auto",
              color: "lightgray",
              padding: "10px",
              fontSize: "14px",
            }}
          >
            Password
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              marginRight: "25px",
              marginTop: "3px",
              color: "gray",
            }}
          >
            <input type="checkbox" style={{ marginRight: "3px" }} />
            Remember ID
          </div>
          <div
            style={{
              backgroundColor: "indigo",
              borderRadius: "10px",
              color: "white",
              height: "40px",
              width: "170px",
              margin: "auto",
              marginTop: "10px",
              textAlign: "center",
              padding: "7px",
            }}
          >
            Login
          </div>
        </Col>
      );
    } else {
      //custom
      return (
        <Col
          sm={3}
          className={
            appLayoutLogin.layoutId === item.programUid
              ? "layout-selected"
              : "layout-sample"
          }
          onClick={(e) => {
            onClickLoginTmpl(item, custom);
          }}
        ></Col>
      );
    }
  };

  return (
    <React.Fragment>
      <DndProvider backend={HTML5Backend}>
        <div>
          <PageTemplate breadcrum={breadcrum}>
            <PageTemplate.Box boxClass="mb-0">
              <div className="login-container">
                <Row className="ls-container">
                  <Col sm={2} style={{ marginLeft: "6rem" }}>
                    <div className="ls-header">Select Login Template</div>
                    <div className="ls-description">
                      This is Login Page Template. You can set details by click
                      ‘Move to Mobile Editor‘ button.
                    </div>
                  </Col>
                  <Col sm={7}>
                    <div className="ls-content">
                      <Row
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        {!ArrayUtils.isEmpty(loginTmplList)
                          ? loginTmplList.map((item) => {
                              return renderLoginTmpl(item, "N");
                            })
                          : ""}
                        {renderLoginTmpl(loginCustomLayout, "Y")}
                      </Row>
                      <Row>
                        <Col style={{ position: "relative", height: "10px" }}>
                          <Button
                            variant="outline-primary"
                            style={{
                              float: "right",
                              width: "150px",
                              marginTop: "10px",
                            }}
                            onClick={(e) =>
                              moveToCustomEditor(loginCustomLayout)
                            }
                          >
                            Move to Mobile Editor
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </div>
            </PageTemplate.Box>
          </PageTemplate>
        </div>
      </DndProvider>
    </React.Fragment>
  );
};

export default LoginSetting;
