import React, { PureComponent } from "react";
import StringUtils from "components/common/utils/StringUtils";
import UModalManager from "components/common/modal/UModalManager";
import { Button as RButton } from "react-bootstrap";
import { CircularProgress } from "@mui/material";
import { GoMoveToTop } from "react-icons/go";

class UModal extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return <React.Fragment>{this.props.children}</React.Fragment>;
  }
}

class UModalBody extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showButton: false,
    };

    //추후 theme적용 해야함 우선 dark theme로 hard-coding..
    this.theme = "skin-dark";

    //top으로 이동 버튼
    this.scrollToTopButton = props.scrollToTopButton;
    //top으로 이동 버튼의 display base 위치
    this.scrollToTopButtonPos = props.scrollToTopButtonPos || 50;

    this.listenScrollEvent = this.listenScrollEvent.bind(this);
    this.scrollToTop = this.scrollToTop.bind(this);

    this.scrollerRef = React.createRef();
  }

  /**
   * modal body scroll event가 있을 경우 event listener에 추가
   */
  componentDidMount() {
    if (this.scrollToTopButton === true) {
      this.scrollerRef.current.addEventListener(
        "scroll",
        this.listenScrollEvent
      );
    }
  }

  /**
   * event listener 제거
   */
  componentWillUnmount() {
    if (this.scrollToTopButton === true) {
      this.scrollerRef.current.removeEventListener(
        "scroll",
        this.listenScrollEvent
      );
    }
  }

  listenScrollEvent = (e) => {
    //console.log(e.target.scrollTop);
    this.setState({
      showButton: e.target.scrollTop > this.scrollToTopButtonPos,
    });
  };

  scrollToTop = () => {
    this.scrollerRef.current.scroll({
      top: 0,
      behavior: "smooth",
    });
  };

  render() {
    return (
      <div
        className={`modal-body ${this.theme} ${StringUtils.defaultString(
          this.props.className
        )}`}
        style={{ ...this.props.style }}
        onScroll={(e) => {
          this.scrollToTopButton === true ?? this.listenScrollEvent(e);
        }}
        ref={this.scrollerRef}
      >
        {this.props.children}
        {this.state.showButton === true ? (
          <div className="scroll-top-container">
            <button onClick={this.scrollToTop} type="button">
              <GoMoveToTop size={16} />
            </button>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

class UModalFooter extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }
  static Button({ side = "right", onClick, variant = "primary", ...props }) {
    const onClickButton = (e) => {
      if (onClick) {
        onClick(e);
      }
    };
    return (
      <RButton
        style={{ float: side }}
        onClick={onClickButton}
        variant={variant}
      >
        {props.children}
      </RButton>
    );
  }
  static ProgressButton = ({
    onClick,
    doing,
    doingText = "Running",
    variant = "primary",
    side = "right",
    disabled,
    ...props
  }) => {
    const onClickButton = (e) => {
      e.preventDefault();
      if (onClick) {
        onClick(e);
      }
    };

    return (
      <RButton
        onClick={onClickButton}
        disabled={doing || disabled}
        variant={variant}
        style={{
          display: "flex",
          alignItems: "center",
          gap: "5px",
          float: side,
        }}
      >
        {doing ? (
          <>
            <CircularProgress color="inherit" size={13} />
            {doingText}
          </>
        ) : (
          props.children
        )}
      </RButton>
    );
  };

  render() {
    const close =
      this.props.invisibleClose !== true ? (
        <button
          type="button"
          className="btn btn-default"
          onClick={(e) => {
            if (typeof this.props.onClose === "function") {
              this.props.onClose.call(this);
            } else {
              UModalManager.close(this.props.programId, this.props.callback);
            }
          }}
          style={{ float: "right" }}
        >
          {StringUtils.isEmpty(this.props.closeText)
            ? "Close"
            : this.props.closeText}
        </button>
      ) : (
        ""
      );

    return (
      <div
        className={`modal-footer ${StringUtils.defaultString(
          this.props.className
        )} ${this.props.fixed ? "fixed" : ""}`}
        style={{
          ...this.props.style,
          display: "block",
          justifyContent: "normal",
        }}
      >
        {close}
        {this.props.children}
      </div>
    );
  }
}

class UModalHeader extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div
        className={`modal-header ${StringUtils.defaultString(
          this.props.className
        )}`}
        style={{ ...this.props.style }}
      >
        <div className="modal-title">{this.props.title}</div>
        <button
          type="button"
          className="btn-close"
          aria-label="Close"
          onClick={(e) => {
            if (typeof this.props.onClose === "function") {
              this.props.onClose.call(this);
            } else {
              UModalManager.close(this.props.programId, this.props.callback);
            }
          }}
        ></button>
      </div>
    );
  }
}
export default Object.assign(UModal, {
  Body: UModalBody,
  Header: UModalHeader,
  Footer: UModalFooter,
});
