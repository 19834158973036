import Element from "./Element.entity";

class TrdTableField {
  fieldId = null;
  pkYn = null;
  uniqueYn = null;
  notNullYn = null;
  autoIncrementYn = null;
  extra = null;
  fieldDefaultValue = null;
  refTableId = null;
  refFieldId = null;
  logicalNm = null;
  physicalNm = null;
  sortSeq = null;
  element = new Element();
  isNull = true;

  constructor(tableField) {
    if (tableField) {
      this.fieldId = tableField.fieldId;
      this.pkYn = tableField.pkYn;
      this.uniqueYn = tableField.uniqueYn;
      this.notNullYn = tableField.notNullYn;
      this.autoIncrementYn = tableField.autoIncrementYn;
      this.extra = tableField.extra;
      this.fieldDefaultValue = tableField.fieldDefaultValue;
      this.refTableId = tableField.refTableId;
      this.refFieldId = tableField.refFieldId;
      this.logicalNm = tableField.logicalNm;
      this.physicalNm = tableField.physicalNm;
      this.sortSeq = tableField.sortSeq;
      this.element = new Element(tableField.element);
      this.isNull = false;
    } else {
      this.isNull = true;
    }
  }

  getFieldId() {
    return this.fieldId;
  }

  setFieldId(value) {
    this.fieldId = value;
  }

  getPkYn() {
    return this.pkYn;
  }

  setPkYn(value) {
    this.pkYn = value;
  }

  getUniqueYn() {
    return this.uniqueYn;
  }

  setUniqueYn(value) {
    this.uniqueYn = value;
  }

  getNotNullYn() {
    return this.notNullYn;
  }

  setNotNullYn(value) {
    this.notNullYn = value;
  }

  getAutoIncrementYn() {
    return this.autoIncrementYn;
  }

  setAutoIncrementYn(value) {
    this.autoIncrementYn = value;
  }

  getExtra() {
    return this.extra;
  }

  setExtra(value) {
    this.extra = value;
  }

  getFieldDefaultValue() {
    return this.fieldDefaultValue;
  }

  setFieldDefaultValue(value) {
    this.fieldDefaultValue = value;
  }

  getRefTableId() {
    return this.refTableId;
  }

  setRefTableId(value) {
    this.refTableId = value;
  }

  getRefFieldId() {
    return this.refFieldId;
  }

  setRefFieldId(value) {
    this.refFieldId = value;
  }

  getLogicalNm() {
    return this.logicalNm;
  }

  setLogicalNm(value) {
    this.logicalNm = value;
  }

  getPhysicalNm() {
    return this.physicalNm;
  }

  setPhysicalNm(value) {
    this.physicalNm = value;
  }

  getSortSeq() {
    return this.sortSeq;
  }

  setSortSeq(value) {
    this.sortSeq = value;
  }

  getElement() {
    return this.element;
  }

  setElement(value) {
    this.element = value;
  }

  toJSON() {
    return {
      fieldId: this.fieldId,
      pkYn: this.pkYn,
      uniqueYn: this.uniqueYn,
      notNullYn: this.notNullYn,
      autoIncrementYn: this.autoIncrementYn,
      extra: this.extra,
      fieldDefaultValue: this.fieldDefaultValue,
      refTableId: this.refTableId,
      refFieldId: this.refFieldId,
      logicalNm: this.logicalNm,
      physicalNm: this.physicalNm,
      sortSeq: this.sortSeq,
      element: this.element ? this.element.toJSON() : null,
    };
  }
}

export default TrdTableField;
