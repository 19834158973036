import { Enums } from "components/builder/BuilderEnum";
import { AdminSettingContext } from "page/adminSetting";
import PageTemplate from "page/common/PageTemplate";
import React, { useContext, useEffect } from "react";
import { useState } from "react";

import styled from "styled-components";

const AdminSettingContatiner = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
export const AdminSettingContents = styled.div`
  width: 100%;
  height: 100%;
`;
export const AdminSettingSearchBar = styled.div`
  display: flex;
  gap: 10px;
`;
export const AdminSettingButton = styled.div`
  height: 30px;
  display: flex;
  gap: 10px;
  justify-content: flex-end;
`;

const basicBreadcrum = [
  {
    name: "System Mangement(Admin)",
    url: Enums.BuilderPath.ADMIN_SETTING,
    active: false,
  },
];

function AdminSettingMain() {
  const { selectedMenu } = useContext(AdminSettingContext);
  const [breadcrum, setBreadcrum] = useState(basicBreadcrum);

  useEffect(() => {
    const newBreadcrum = [...breadcrum];
    breadcrum[0].active = false;
    if (selectedMenu && breadcrum.length > 1) {
      newBreadcrum[1] = {
        name: selectedMenu.name,
        url: "",
        active: true,
        onClick: () => {},
      };
    } else {
      newBreadcrum.push({
        name: selectedMenu.name,
        url: "",
        active: true,
        onClick: () => {},
      });
    }
    setBreadcrum(newBreadcrum);
  }, [selectedMenu]);

  return (
    <PageTemplate breadcrum={breadcrum}>
      <PageTemplate.Box boxClass="mb-0">
        <AdminSettingContatiner>
          <selectedMenu.component />
        </AdminSettingContatiner>
      </PageTemplate.Box>
    </PageTemplate>
  );
}

export default AdminSettingMain;
