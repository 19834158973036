import { Tab, Tabs } from "@mui/material";
import { Enums } from "components/builder/BuilderEnum";
import Message from "components/common/Message";
import Popup from "components/common/Popup";
import MuiConfig from "components/common/config/MuiConfig";
import WijmoGrid from "components/common/element/WijmoGrid";
import Modal from "components/common/modal/UModal";
import UModalTemplate from "components/common/modal/UModalTemplate";
import CommonUtils from "components/common/utils/CommonUtils";
import StringUtils from "components/common/utils/StringUtils";
import User from "components/common/utils/UserUtils";
import { useEffect, useState } from "react";
import SettingService from "services/common/SettingService";
import ComponentService from "services/datamodel/DataModelService";

const column = [
  {
    field: "reservedWord",
    headerName: "Reserved Words",
    align: "left",
    width: 150,
  },
  {
    field: "reservedWordNm",
    headerName: "Name",
    width: 130,
  },
  {
    field: "description",
    headerName: "Description",
    width: 350,
  },
  {
    field: "autoNumbering",
    headerName: "Auto Numbering",
    width: 150,
    renderCell: (params) => {
      if (params.autoNumberingType === "S") {
        return "Standard Numbering";
      } else if (params.autoNumberingType === "U") {
        return "User Custom Numbering";
      }
    },
  },
];

const TabKey = {
  DEFAULT: "default",
  GLOBAL: "globalVariable",
};

/**
 * 기본입력값 팝업
 * @param {Object} props
 * @param {funtion} props.onChooseDefaultValue : 기본값 선택 이벤트 더블클릭, 클릭 후 선택 포함 : return {target : {id, value}}
 * @param {String} props.id : 기본값이 들어갈 인풋 아이디
 * @param {Boolean} props.dateYn : 날짜 여부 true : 날짜 ({currentTime})만 노출 , false : 날짜외 전부 노출 , 기본값 false
 * @param {Boolean} props.autoNumbering : 채번 여부 default false
 * @param {Object} props.workspace : 채번 여부 default false
 * @returns
 */
function DefaultInputPopup({
  onChooseDefaultValue,
  id,
  dateYn = false,
  autoNumbering = false,
  workspace,
  ...props
}) {
  const [selectedTab, setSelectedTab] = useState(TabKey.DEFAULT);
  const [defaultValueList, setDefaultValueList] = useState([]);
  const [variableList, setVariableList] = useState([]);
  const [selectedDV, setSelectedDV] = useState({
    target: {
      id,
      value: "",
    },
  });
  const useGlobalVariable =
    CommonUtils.getAppConfig(workspace, "globalVariableUseYn") === "Y"
      ? true
      : false;

  useEffect(() => {
    ComponentService.getReservedWordList({}, (res) => {
      let filtered = [];
      //날짜 포함 여부
      if (dateYn) {
        filtered = res.data.filter(
          (item) => item.reservedWord.indexOf("currentTime") > -1
        );
      } else {
        filtered = res.data.filter(
          (item) => item.reservedWord.indexOf("currentTime") === -1
        );
      }
      //채번 포함여부
      if (!autoNumbering) {
        filtered = filtered.filter((item) =>
          StringUtils.isEmpty(item.autoNumberingType)
        );
      }
      setDefaultValueList(filtered);
    });
    getVariableList();
  }, []);

  const getVariableList = () => {
    if (workspace.appId) {
      SettingService.getVariableList(
        { appId: workspace.appId },
        (res) => {
          const vList = [...res.data];
          const gloabalVariableObject = User.getGlobalVariable(
            workspace.tenantMstId
          );

          vList.forEach((v) => {
            v.jsVariable = `variable.${v.variableEntity}.${v.variableField}`;
            if (gloabalVariableObject) {
              if (
                gloabalVariableObject[v.variableEntity] &&
                gloabalVariableObject[v.variableEntity][v.variableField]
              ) {
                v.userValue =
                  gloabalVariableObject[v.variableEntity][v.variableField];
              }
            }
          });

          setVariableList(vList);
        },
        (err) => {
          Message.alert(err.message, Enums.MessageType.ERROR);
        }
      );
    }
  };

  const onClickOk = (e) => {
    if (onChooseDefaultValue) {
      onChooseDefaultValue(selectedDV);
    }
    e.preventDefault();
    Popup.close();
  };

  const onRowClick = (data, event) => {
    if (selectedDV.target.value === data.reservedWord) {
      //더블 클릭 느낌으로 대체
      if (onChooseDefaultValue) {
        onChooseDefaultValue(selectedDV);
        Popup.close();
      }
    } else {
      setSelectedDV({
        target: {
          ...selectedDV.target,
          value: data.reservedWord,
          autoNumberingType: data.autoNumberingType,
        },
      });
    }
  };

  const varColumns = [
    {
      field: "variable",
      headerName: "Global Variable",
      width: "*",
    },
    {
      field: "jsVariable",
      headerName: "Javascript Usage",
      width: "*",
    },
    {
      field: "variableEntity",
      headerName: "Entity Name",
      width: "*",
    },
    {
      field: "variableField",
      headerName: "Field Name",
      width: "*",
    },
    {
      field: "userValue",
      headerName: "User Specific value",
      width: "*",
    },
  ];

  return (
    <Modal>
      <Modal.Header title={"Select Default Value"} />
      <Modal.Body>
        <UModalTemplate style={{ height: "600px" }}>
          <Tabs
            value={selectedTab}
            onChange={(e, value) => {
              setSelectedTab(value);
            }}
          >
            <Tab
              label={"Default Value"}
              value={TabKey.DEFAULT}
              style={{ fontWeight: "bold", fontSize: "15px" }}
            />
            {useGlobalVariable && (
              <Tab
                label={"Global Variable"}
                value={TabKey.GLOBAL}
                style={{ fontWeight: "bold", fontSize: "15px" }}
              />
            )}
          </Tabs>
          <TabPanel value={selectedTab} tabKey={TabKey.DEFAULT}>
            <WijmoGrid
              columns={column}
              getRowId={(row) => row.reservedWordId}
              rows={defaultValueList}
              {...MuiConfig.grid.options}
              pageSize={20}
              //   onRowDoubleClick={onRowDoubleClick}
              onRowClick={onRowClick}
              rowsPerPageOptions={[20]}
              style={{ height: `50vh` }}
            />
          </TabPanel>
          <TabPanel value={selectedTab} tabKey={TabKey.GLOBAL}>
            <WijmoGrid
              selectMode="Row"
              columns={varColumns}
              rows={variableList}
              style={{ height: `50vh` }}
              onRowClick={(data) =>
                setSelectedDV({
                  target: {
                    id,
                    value: data.variable,
                  },
                })
              }
              onRowDoubleClick={(data) => {
                onChooseDefaultValue({
                  target: {
                    id,
                    value: data.variable,
                  },
                });
              }}
              emptyMessage={"No Registered App Version"}
            />
          </TabPanel>
        </UModalTemplate>
      </Modal.Body>
      <Modal.Footer>
        <Modal.Footer.Button onClick={onClickOk}>Select</Modal.Footer.Button>
      </Modal.Footer>
    </Modal>
  );
}

export default DefaultInputPopup;

function TabPanel(props) {
  const { children, value, tabKey, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== tabKey}
      id={`simple-tabpanel-${tabKey}`}
      aria-labelledby={`simple-tab-${tabKey}`}
      {...other}
      style={{
        marginTop: "5px",
      }}
    >
      {props.children}
    </div>
  );
}
