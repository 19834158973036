import WijmoGrid from "components/common/element/WijmoGrid";
import CommonUtils from "components/common/utils/CommonUtils";
import {
  AdminSettingButton,
  AdminSettingContents,
} from "components/setting/config/AdminSettingMain";
import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import CodeService from "services/common/CodeService";
import styled from "styled-components";

const columns = [
  {
    field: "majorCd",
    headerName: "Major Code",
    width: 150,
  },
  {
    field: "majorNm",
    headerName: "Code Name",
    width: 150,
  },
  {
    field: "type",
    headerName: "Code Type",
    width: 150,
  },
  {
    field: "moduleCd",
    headerName: "Module Code",
    width: 150,
  },
  {
    field: "updtUserId",
    headerName: "Update User",
    width: 150,
  },
  {
    field: "updtDt",
    headerName: "Update Date",
    width: 150,
    valueGetter: (params) =>
      `${CommonUtils.getDate(params.row.updtDt, "datetime")}`,
  },
];

const CodeManageBox = styled(AdminSettingContents)`
  width: 100%;
`;

function MajorCodeManage() {
  const [codeList, setCodeList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pageSize, setPageSize] = useState(20);

  useEffect(() => {
    getCodeList();
    return () => {
      setCodeList([]);
    };
  }, []);

  const getCodeList = () => {
    setIsLoading(true);
    CodeService.getMajorList({}, (res) => {
      setIsLoading(false);
      setCodeList(res.data);
    });
  };

  return (
    <>
      <CodeManageBox>
        <WijmoGrid
          isLoading={isLoading}
          columns={columns}
          rows={codeList}
          getRowId={(row) => row.majorCd}
          pageSize={pageSize}
          rowsPerPageOptions={[pageSize]}
        />
      </CodeManageBox>
      <AdminSettingButton>
        <Button>Save</Button>
      </AdminSettingButton>
    </>
  );
}

export default MajorCodeManage;
