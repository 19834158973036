import { memo } from "react";
// import "css/builder/TechnicalDesign.css";

import { Enums } from "components/builder/BuilderEnum";
import ProgramDesignHeader from "components/builder/programDesign/component/ProgramDesignHeader";
import StringUtils from "components/common/utils/StringUtils";
import { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";

const ProgramDesign = memo(({ ...props }) => {
  const [currentPath, setCurrentPath] = useState();
  const location = useLocation();

  useEffect(() => {
    const CurrentPath = location.pathname.split("/")[2];
    setCurrentPath(CurrentPath);
  }, [location]);

  return (
    <>
      {StringUtils.equalsIgnoreCase(
        currentPath,
        Enums.BuilderPath.PROGRAM_DESIGN
      ) ? (
        <>TEST</>
      ) : (
        <ProgramDesignHeader {...props.header} />
      )}

      <Outlet />
    </>
  );
});

export default ProgramDesign;
