import Api from "components/common/Api";
import MuiConfig from "components/common/config/MuiConfig";
import WijmoGrid from "components/common/element/WijmoGrid";
import CommonUtils from "components/common/utils/CommonUtils";
import {
  AdminSettingButton,
  AdminSettingContents,
} from "components/setting/config/AdminSettingMain";
import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import ProgramService from "services/ui/ProgramService";
import styled from "styled-components";

const columns = [
  { field: "templateId", headerName: "Field Id", width: 150 },
  {
    field: "templateNm",
    headerName: "Template Name",
    width: "2*",
  },
  {
    field: "templateType",
    headerName: "Template Type",
    width: 150,
  },
  {
    field: "moduleCd",
    headerName: "Module Code",
    width: 150,
  },
  {
    field: "updateUserId",
    headerName: "Update User",
    width: 150,
  },
  {
    field: "updateDt",
    headerName: "Update Date",
    width: "*",
    renderCell: (params) =>
      `${CommonUtils.getDate(params.updateDt, "datetime")}`,
    // valueGetter: (params) =>
    //   `${CommonUtils.getDate(params.row.updateDt, "datetime")}`,
  },
];

const TemplateBox = styled(AdminSettingContents)``;

function TemplateManage() {
  const [templateList, setTemplateList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getTemplateList();
    return () => {
      setTemplateList([]);
    };
  }, []);

  const getTemplateList = async () => {
    let tmpList = [];
    let shareList = [];
    setIsLoading(true);
    await Api.post("/popup/getTemplateList", { useYn: "Y" }, (res) => {
      tmpList = [...res.data];
    });
    await ProgramService.getShareProgramList({}, (res) => {
      shareList = res.data.map((program) => {
        const obj = {};
        obj.templateNm = program.programNm;
        obj.templateType = program.programTypeNm;
        obj.moduleCd = program.moduleCd;
        obj.templateId = program.programId;
        obj.updateUserId = program.updtUserId;
        obj.updateDt = program.updtDt;
        obj.shared = true;
        obj.remark = "";
        return obj;
      });
      setIsLoading(false);
      setTemplateList([...tmpList, ...shareList]);
    });
  };

  return (
    <>
      <TemplateBox>
        <WijmoGrid
          isLoading={isLoading}
          columns={columns}
          rows={templateList}
          getRowId={(row) => row.templateId}
          {...MuiConfig.grid.options}
          pageSize={10}
          rowsPerPageOptions={[10]}
          style={{ height: "70vh" }}
        />
      </TemplateBox>
      <AdminSettingButton>
        <Button>Submit</Button>
      </AdminSettingButton>
    </>
  );
}

export default TemplateManage;
