import {
  Box,
  Checkbox,
  Chip,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import { Enums } from "components/builder/BuilderEnum";
import { AppContext } from "components/common/AppContextProvider";
import Message from "components/common/Message";
import StringUtils from "components/common/utils/StringUtils";
import User from "components/common/utils/UserUtils";
import PageTemplate from "page/common/PageTemplate";
import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { FaEdit, FaList } from "react-icons/fa";
import { MdOutlinePassword } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import AppService from "services/common/AppService";
import CodeService from "services/common/CodeService";
import UserService from "services/common/UserService";

function UserDetail() {
  const param = useParams();
  const navigate = useNavigate();
  const applicationList = useContext(AppContext).application.list;

  const [userId, setUserId] = useState("");
  const [userNm, setUserNm] = useState("");
  const [userEngNm, setUserEngNm] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [pwd, setPwd] = useState("");
  const [pwdConfirm, setPwdConfirm] = useState("");
  const [defaultLangCd, setDefaultLangCd] = useState("KO");
  const [useYn, setUseYn] = useState("");
  const [userAuthType, setUserAuthType] = useState("");
  const [availableModuleCodes, setAvailableModuleCodes] = useState([]);
  const [appId, setAppId] = useState(
    StringUtils.equalsIgnoreType(param.appId, -1) || !param.appId
      ? null
      : param.appId
  );
  const app = applicationList.find((a) =>
    StringUtils.equalsIgnoreType(a.appId, appId)
  );

  const [user, setUser] = useState({});

  const [isUserDev, setIsUserDev] = useState(true);
  const [isMine, setIsMine] = useState(false);
  const [authList, setAuthList] = useState([]);

  useEffect(() => {
    CodeService.getCodeCombo({ codeMstCd: "Z0019" }, (res) => {
      let limitIndex = res.data.findIndex(
        (c) => c.id === User.getAuthType(appId)
      );
      res.data.splice(limitIndex + 1);
      setAuthList(res.data);
    });
    const userMastRecId = StringUtils.decData(param.userMastRecId);
    if (!userMastRecId) {
      Message.alert(
        "User information does not exist. Please try again.",
        Enums.MessageType.ERROR
      );
      return navigate(Enums.BuilderPath.USER.MAIN);
    }
    UserService.getUser(
      {
        userMastRecId,
        appId: appId,
      },
      (res) => {
        if (res.data) {
          setUser(res.data);
          setUserId(res.data.userId);
          setUserNm(res.data.userNm);
          setUserEngNm(res.data.userEngNm);
          setUserEmail(res.data.userEmail);
          // setPwd(res.data.pwd);
          setDefaultLangCd(res.data.defaultLangCd);
          setUseYn(res.data.useYn);
          setIsMine(
            res.data.userId === User.getId() &&
              res.data.userMastRecId === User.getMastRecId()
          );

          const auth = res.data.userAuths.find((a) =>
            StringUtils.equalsIgnoreType(a.appId, appId)
          );
          if (auth) {
            setUserAuthType(auth.userAuthType);
            setAvailableModuleCodes(
              auth.availableModuleCodes?.map((m) => m.moduleCd)
            );
          }
        } else {
          Message.confirm(
            "User information does not exist. This will take you back to the previous page.",
            () => {
              navigate(-1);
            }
          );
        }
      }
    );
    if (StringUtils.equalsIgnoreCase(User.getAuthType(appId), "d")) {
      setIsUserDev(true);
    } else {
      setIsUserDev(false);
    }

    return () => {
      setAuthList([]);
    };
  }, []);

  const onMoveToList = () => {
    if (isUserDev) {
      navigate(Enums.BuilderPath.USER.MAIN);
    } else {
      navigate(`${Enums.BuilderPath.USER.MAIN}/list/${param.appId}`);
    }
  };

  /**
   * 저장
   * @returns
   */
  const updateUser = () => {
    if (StringUtils.isEmpty(userId))
      return Message.alert("Please enter User ID", Enums.MessageType.WARN);
    if (StringUtils.isEmpty(userNm))
      return Message.alert("Please enter User Name", Enums.MessageType.WARN);
    if (StringUtils.isEmpty(userEmail))
      return Message.alert("Please enter User Email", Enums.MessageType.WARN);

    //비밀번호 수정한 경우에만 검증 단계
    if (isMine && !StringUtils.isEmpty(pwd)) {
      if (pwd !== pwdConfirm)
        return Message.alert("Password is Invalid.", Enums.MessageType.WARN);
      if (StringUtils.isEmpty(pwdConfirm))
        return Message.alert(
          "Please Enter 'Confirm password' field",
          Enums.MessageType.WARN
        );
    }

    if (StringUtils.isEmpty(useYn))
      return Message.alert(
        "Please select Usage Status.",
        Enums.MessageType.WARN
      );

    const body = {
      ...user,
      userNm,
      userEngNm,
      userEmail,
      defaultLangCd,
      pwd,
      useYn,
      appId: appId,
      userAuthType,
      availableModuleCodes,
    };

    Message.confirm("Would you like to modify the information?", () => {
      UserService.update(body, (res) => {
        const { isSuccess, message } = res.data;
        if (isSuccess === "Y") {
          Message.alert(
            "User Info has been modified",
            Enums.MessageType.SUCCESS
          );
          if (appId && !isUserDev) {
            navigate(`${Enums.BuilderPath.USER.MAIN}/list/${appId}`);
          }
        } else {
          Message.alert(message, Enums.MessageType.ERROR);
        }
      });
    });
  };

  /**
   * 담당 태그 클릭 이벤트
   * @param {*} values
   */
  const onClickTag = (values) => {
    setAvailableModuleCodes(values);
  };

  /**
   * 권한 설정
   * @param {*} e
   */
  const onChangeUserAuthType = (e) => {
    const value = e.target.value;
    setUserAuthType(value);
    if (value === "AM") {
      setAvailableModuleCodes(["*"]);
    }
  };

  /**
   * 비밀번호 초기화
   */
  const onInitialPassword = () => {
    Message.confirm(
      <>
        Resetting the password.
        <br />
        Do you want to continue?
      </>,
      () => {
        UserService.initalPassword({ userId: user.userId }, (res) => {
          Message.confirm(
            <>
              Password has been reset.
              <br />
              {res.message}
            </>
          );
        });
      }
    );
  };

  let breadcrum = [
    {
      name: "User Details",
      subName: userNm + "-" + userId,
      active: true,
    },
  ];

  return (
    <PageTemplate breadcrum={breadcrum}>
      <PageTemplate.Box boxClass="mb-0">
        <div className="box-control box-line button-group">
          {!StringUtils.includes(User.getAuthType(appId), ["D", "AM"]) && (
            <Button
              variant="outline-secondary"
              size="sm"
              onClick={onInitialPassword}
            >
              <MdOutlinePassword /> Reset Password
            </Button>
          )}{" "}
          <Button variant="outline-dark" size="sm" onClick={updateUser}>
            <FaEdit /> Edit
          </Button>{" "}
          <Button variant="outline-dark" size="sm" onClick={onMoveToList}>
            <FaList /> List
          </Button>
        </div>
        <Form>
          <Row className="mt-4">
            <Col sm="6" style={{ paddingRight: "20px" }}>
              <Form.Group className="mb-3">
                <Form.Label className="required">User ID</Form.Label>
                <Form.Control value={userId} readOnly={true} disabled={true} />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label className="required">User Name</Form.Label>
                <Form.Control
                  value={userNm}
                  onChange={(e) => setUserNm(e.currentTarget.value)}
                />
                <Form.Text></Form.Text>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label className="required">User English name</Form.Label>
                <Form.Control
                  value={userEngNm}
                  onChange={(e) => setUserEngNm(e.currentTarget.value)}
                />
                <Form.Text></Form.Text>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label className="required">User Email</Form.Label>
                <Form.Control
                  value={userEmail}
                  onChange={(e) => setUserEmail(e.currentTarget.value)}
                />
                <Form.Text></Form.Text>
              </Form.Group>
              {isMine ? (
                <>
                  <Form.Group className="mb-3">
                    <Form.Label className="required">Password</Form.Label>
                    <Form.Control
                      value={pwd}
                      type="password"
                      onChange={(e) => setPwd(e.currentTarget.value)}
                    />
                    <Form.Text></Form.Text>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label className="required">
                      Confirm password
                    </Form.Label>
                    <Form.Control
                      value={pwdConfirm}
                      type="password"
                      onChange={(e) => setPwdConfirm(e.currentTarget.value)}
                    />
                    <Form.Text></Form.Text>
                  </Form.Group>
                </>
              ) : null}
            </Col>

            <Col sm="6" style={{ paddingLeft: "20px" }}>
              {appId && (
                <>
                  <Form.Group className="mb-3">
                    <Form.Label className="required">
                      {app.appNm} Authorization
                    </Form.Label>
                    <Form.Select
                      value={userAuthType}
                      onChange={onChangeUserAuthType}
                    >
                      {authList.map((auth) => {
                        return (
                          <option key={auth.id} value={auth.id}>
                            {auth.text}
                          </option>
                        );
                      })}
                    </Form.Select>
                    <Form.Text></Form.Text>
                  </Form.Group>
                  {!StringUtils.equalsIgnoreCase(userAuthType, "AM") &&
                    !StringUtils.equalsIgnoreCase(userAuthType, "S") && (
                      <Form.Group className="mb-3">
                        <Form.Label className="required">
                          {app.appNm} Module Setting
                        </Form.Label>
                        <MultiCheckSelectBox
                          selected={availableModuleCodes}
                          onClickTag={(values) => onClickTag(values)}
                          readOnly={isUserDev}
                          disabled={isUserDev}
                          app={app}
                        />
                      </Form.Group>
                    )}
                </>
              )}

              <Form.Group className="mb-3">
                <Form.Label>Language</Form.Label>
                <Form.Select
                  value={defaultLangCd}
                  onChange={(e) => setDefaultLangCd(e.target.value)}
                >
                  <option value={"KO"}>Korean</option>
                  <option value={"EN"}>English</option>
                  <option value={"ZH"}>Chinese</option>
                  <option value={"JA"}>Japanese</option>
                  <option value={"VN"}>Vietnamese</option>
                </Form.Select>
                <Form.Text></Form.Text>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Usage Status</Form.Label>
                <Form.Select
                  value={useYn}
                  onChange={(e) => setUseYn(e.target.value)}
                >
                  <option value={""}>Select</option>
                  <option value={"Y"}>Yes</option>
                  <option value={"N"}>No</option>
                </Form.Select>
                <Form.Text></Form.Text>
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </PageTemplate.Box>
    </PageTemplate>
  );
}

export default UserDetail;

export const MultiCheckSelectBox = (props) => {
  const {
    onClickTag,
    selected = [],
    disabled = true,
    app,
    ...otherProps
  } = props;
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [userModuleList, setUserModuleList] = useState([]);
  const [pModuleList, setpModuleList] = useState([]);

  useEffect(() => {
    let _my = [];
    getModuleList()
      .then((list) => {
        if (list) {
          for (const _module of list) {
            const myModule = selected.find(
              (moduleCd) => moduleCd === _module.moduleCd
            );
            if (myModule) {
              _my.push(_module);
            }
          }
          setSelectedOptions(_my);
          return list;
        } else {
          return false;
        }
      })
      .then(getUserModuleList)
      .then(({ moduleList, userAvailableModuleList }) => {
        // setpModuleList(userAvailableModuleList);
        const myModule = moduleList.filter((_m) =>
          userAvailableModuleList.find((_my) => _my.moduleCd === _m.moduleCd)
        );
        setpModuleList(myModule);
      });

    return () => {
      setSelectedOptions([]);
      setUserModuleList([]);
      setpModuleList([]);
    };
  }, [selected]);

  const getModuleList = () => {
    return new Promise((resolve, reject) => {
      AppService.getAppModuleList({ appId: app.appId }, (res) => {
        resolve(res.data);
      });
    });
  };
  const getUserModuleList = (list) => {
    return new Promise((resolve, reject) => {
      if (userModuleList.length > 0) {
        resolve({ moduleList: list, userAvailableModuleList: userModuleList });
      } else {
        AppService.getUserAuthList({ appId: app.appId }, (res) => {
          setUserModuleList(res.data);
          resolve({ moduleList: list, userAvailableModuleList: res.data });
        });
      }
    });
  };

  const onClickOption = (e) => {
    const values = e.target.value;
    const moduleCds = values.map((v) => v.moduleCd);
    onClickTag(moduleCds);
  };

  return (
    <FormControl
      style={{
        background: disabled ? "#e9e9e9" : "white",
      }}
      fullWidth
      size="small"
    >
      <InputLabel id="multiselect-label">Select Module</InputLabel>
      <Select
        labelId="multiselect-label"
        disabled={disabled}
        size="small"
        multiple
        value={selectedOptions}
        onChange={onClickOption}
        input={<OutlinedInput label="Tag" />}
        displayEmpty
        inputProps={{ "aria-label": "Without label" }}
        renderValue={(selectedOptions) => {
          return (
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              {selectedOptions.map((_s) => (
                <Chip key={_s.moduleCd} label={_s.moduleNm} size={"small"} />
              ))}
            </Box>
          );
        }}
        {...otherProps}
      >
        {pModuleList.map((_module) => {
          const checked = selectedOptions.find(
            (_s) => _s.moduleCd === _module.moduleCd
          )
            ? true
            : false;

          return (
            <MenuItem key={_module.codeDtlId} value={_module}>
              <Checkbox checked={checked} />
              <ListItemText primary={_module.moduleNm} />
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};
