import ActionType from "components/builder/BuilderActionType";

export function setIsMobileEditor(flag) {
  return {
    type: ActionType.MOBILE.IS_MOBILE_EDITOR,
    payload: flag,
  };
}

export function setMobileScreenWidth(value) {
  return {
    type: ActionType.MOBILE.SCREEN_WIDTH,
    payload: value,
  };
}

export function setMobileScreenHeight(value) {
  return {
    type: ActionType.MOBILE.SCREEN_HEIGHT,
    payload: value,
  };
}

export function setAppLayoutList(value) {
  return {
    type: ActionType.MOBILE.SET_APP_LAYOUT,
    payload: value,
  };
}
