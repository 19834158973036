import React from "react";
import ProcessModal from "./process/ProcessModal";
import { Col, Form, Row } from "react-bootstrap";
import CodeMirror from "@uiw/react-codemirror";
import { java } from "@codemirror/lang-java";
import produce from "immer";
import UModal from "components/common/modal/UModal";
import Popup from "components/common/Popup";
import CodeReferencePopup from "./CodeReferencePopup";
import StringUtils from "components/common/utils/StringUtils";
import CodeMirrorWrapper from "components/common/element/CodeMirrorWrapper";

class CodePopup extends ProcessModal {
  constructor(props) {
    super(props);
    this.changeViewSource = this.changeViewSource.bind(this);
    this.onOpenReferencePopup = this.onOpenReferencePopup.bind(this);
    this.state = {
      processNm: "",
      code: "",
      codeType: "java",
      entityVariable: "",
      entityNm: "",
      outputEncoding: "UTF-8",
    };
  }

  componentDidMount() {
    if (this.props.processInfo) {
      const pInfo = { ...this.props.processInfo };
      if (!pInfo.outputEncoding) {
        pInfo.outputEncoding = this.state.outputEncoding;
      }
      this.setState(pInfo);
    }
  }

  changeViewSource(value, viewUpdate) {
    this.setState(
      produce(this.state, (draft) => {
        draft.code = value;
      })
    );
  }

  onOpenReferencePopup() {
    Popup.open(<CodeReferencePopup codeType={this.state.codeType} />, {
      style: { content: { width: "650px" } },
    });
  }

  onValidationCheck() {
    if (StringUtils.isEmpty(this.state.code)) {
      return this.WarnMessage("Please enter Code");
    }
    return true;
  }

  renderFooter() {
    return (
      <>
        <UModal.Footer.Button
          variant="success"
          side="left"
          onClick={this.onOpenReferencePopup}
        >
          Code Guidelines
        </UModal.Footer.Button>
        <UModal.Footer.Button onClick={this.validateCommonParam}>
          Confirm
        </UModal.Footer.Button>
      </>
    );
  }

  renderBody() {
    return (
      <>
        <Row className="mb-3">
          <Col xs={2} className="col-label">
            <Form.Label>Code Type</Form.Label>
          </Col>
          <Col xs={10}>
            <Form.Select
              value={this.state.codeType}
              onChange={this.onChangeParam}
              id={"codeType"}
            >
              <option value={"java"}>Java Expression Language</option>
              <option value={"batch"}>Window Batch Command</option>
              <option value={"shell"}>Linux/Unix Shell Script</option>
            </Form.Select>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col xs={2} className="col-label">
            <Form.Label> Ouput Object</Form.Label>
          </Col>
          <Col xs={5}>
            <Form.Control
              id={"entityVariable"}
              placeholder="Output Object Variable Name"
              onChange={this.onChangeParam}
              value={this.state.entityVariable}
            />
          </Col>
          <Col xs={5}>
            <Form.Control
              id={"entityNm"}
              placeholder="Output Object Name"
              onChange={this.onChangeParam}
              value={this.state.entityNm}
            />
          </Col>
        </Row>
        <Row className="mb-3">
          <Col xs={2} className="col-label">
            <Form.Label>Ouput Encoding</Form.Label>
          </Col>
          <Col xs={10}>
            <Form.Select
              id={"outputEncoding"}
              onChange={this.onChangeParam}
              value={this.state.outputEncoding}
            >
              <option value="UTF-8">UTF-8</option>
              <option value="EUC-KR">EUC-KR</option>
              <option value="MS949">MS949</option>
            </Form.Select>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col xs={2} className="col-label" style={{ alignItems: "normal" }}>
            <Form.Label className="required">Code</Form.Label>
          </Col>
          <Col xs={10} style={{ minHeight: "400px" }}>
            <CodeMirrorWrapper>
              <CodeMirror
                value={this.state.code}
                height="400px"
                className="source-container"
                extensions={[java(true)]}
                autoFocus={false}
                onChange={(value, viewUpdate) =>
                  this.changeViewSource(value, viewUpdate)
                }
              />
            </CodeMirrorWrapper>
          </Col>
        </Row>
      </>
    );
  }
}

CodePopup.propTypes = {};

export default CodePopup;
