import { Tooltip } from "@mui/material";
import StringUtils from "components/common/utils/StringUtils";
import React, { useState } from "react";
import { useEffect } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";

const ColorPicker = ({
  defaultValue,
  onBlur,
  hideTextfield,
  id,
  colorStyle,
  textStyle,
  style,
  ...props
}) => {
  const [color, setcolor] = useState(defaultValue);
  const [colorCode, setColorCode] = useState(defaultValue);

  useEffect(() => {
    colorValidation(defaultValue);
  }, [defaultValue]);

  const onBlurColor = (e) => {
    if (onBlur) {
      onBlur(e);
    }
  };
  const onBlurText = (e) => {
    if (onBlur) {
      onBlur({
        target: {
          id: id,
          value: e.target.value,
        },
      });
    }
  };
  const colorValidation = (value) => {
    if (StringUtils.isEmpty(value)) {
      setcolor("#ffffff");
      setColorCode("");
    } else {
      setcolor(value);
      setColorCode(value);
    }
  };

  if (hideTextfield) {
    return (
      <Form.Control
        type="color"
        id={id}
        value={color}
        onChange={(e) => setcolor(e.target.value)}
        onBlur={onBlurColor}
        style={{ ...colorStyle }}
      />
    );
  } else {
    return (
      <div style={{ display: "flex", gap: "10px", ...style }}>
        <Form.Control
          type="color"
          id={id}
          value={color}
          onChange={(e) => {
            setcolor(e.target.value);
            setColorCode(e.target.value);
          }}
          onBlur={onBlurColor}
          style={{ ...colorStyle }}
        />
        <InputGroup>
          <Form.Control
            className="form-control form-control-sm"
            value={colorCode}
            onChange={(e) => {
              colorValidation(e.target.value);
            }}
            onBlur={onBlurText}
            style={{ ...textStyle }}
            placeholder={"#ColorCode"}
          />
        </InputGroup>
      </div>
    );
  }
};

export default ColorPicker;
