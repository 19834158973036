import { Enums } from "components/builder/BuilderEnum";
import Message from "components/common/Message";
import Modal from "components/common/modal/UModal";
import UmodalTemplate from "components/common/modal/UModalTemplate";
import Popup from "components/common/Popup";
import ObjectUtils from "components/common/utils/ObjectUtils";
import StringUtils from "components/common/utils/StringUtils";
import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Form, InputGroup, Row } from "react-bootstrap";
import { FaTrash } from "react-icons/fa";
import CodeService from "services/common/CodeService";

export function InsertOptionSPopup({ insertOption, onSave, ...props }) {
  const [type, setType] = useState(
    ObjectUtils.isEmpty(insertOption)
      ? ""
      : insertOption.arguments.find((i) => i.name === "type").defaultValue
  );
  const [date, setDate] = useState(
    ObjectUtils.isEmpty(insertOption)
      ? ""
      : insertOption.arguments.find((i) => i.name === "temp_date").defaultValue
  );

  const onChangeDate = (e) => {
    setDate(e.target.value);
  };

  const onConfirmInsertOption = () => {
    if (!type) {
      return Message.alert(
        "Please enter Numbering Type",
        Enums.MessageType.ERROR
      );
    }
    if (onSave) {
      onSave({
        target: {
          id: "insertOption",
          value: JSON.stringify({
            arguments: [
              {
                mode: "IN",
                name: "tenantId",
                type: "STRING",
              },
              {
                mode: "IN",
                name: "coCd",
                type: "STRING",
              },
              {
                mode: "IN",
                name: "type",
                type: "STRING",
                defaultValue: type,
              },
              {
                mode: "IN",
                name: "temp_date",
                type: "STRING",
                defaultValue: date,
              },
              {
                mode: "IN",
                name: "usr_id",
                type: "STRING",
                defaultValue: "{userId}",
              },
              {
                mode: "IN",
                name: "curr_dt",
                type: "STRING",
                defaultValue: "{currentTime}",
              },
              {
                mode: "OUT",
                name: "message",
                type: "STRING",
              },
            ],
            procedureName: "usp_z_auto_numbering",
          }),
        },
      });
    }
    Popup.close();
  };

  return (
    <Modal>
      <Modal.Header title={"Standard Numbering Detail Setting"} />
      <Modal.Body>
        <Form>
          <UmodalTemplate>
            <Form.Group className="mb-3">
              <Form.Label className="required">Numbering Type</Form.Label>
              <Form.Control
                value={type}
                onChange={(e) => setType(e.currentTarget.value)}
              />
              <Form.Text></Form.Text>
            </Form.Group>
            <Form.Group>
              <Form.Label>Reference Date</Form.Label>
              <Form.Control value={date} onChange={onChangeDate} />
              <Form.Text></Form.Text>
            </Form.Group>
          </UmodalTemplate>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Modal.Footer.Button onClick={onConfirmInsertOption}>
          Confirm
        </Modal.Footer.Button>
      </Modal.Footer>
    </Modal>
  );
}

export function InsertOptionUPopup({ insertOption, onSave, ...props }) {
  const [args, setArgs] = useState(
    insertOption.arguments || [
      {
        mode: "IN",
        name: "tenantId",
        type: "STRING",
      },
      {
        mode: "IN",
        name: "coCd",
        type: "STRING",
      },
      {
        mode: "OUT",
        name: "",
        type: "STRING",
      },
    ]
  );
  const [argumentTypes, setArgumentTypes] = useState([]);
  const [procedureName, setProcedureName] = useState(
    insertOption.procedureName || ""
  );

  useEffect(() => {
    CodeService.getCodeCombo({ codeMstCd: "Z0036" }, (res) => {
      setArgumentTypes(res.data);
    });
  }, []);

  const onConfirmInsertOption = () => {
    if (!procedureName) {
      return Message.alert(
        "Please enter Procedure Name",
        Enums.MessageType.ERROR
      );
    }

    //중복된 인수 확인
    for (const arg of args) {
      const count = args.filter((contents) =>
        StringUtils.equalsIgnoreType(contents.name, arg.name)
      ).length;
      if (count > 1) {
        return Message.alert(
          "Duplicated Arguments exist.",
          Enums.MessageType.ERROR
        );
      }
      //OUT Argument 변수명 확인
      if (
        StringUtils.equalsIgnoreCase(arg.mode, "OUT") &&
        StringUtils.isEmpty(arg.name)
      ) {
        return Message.alert(
          "Please enter Procedure Output Argument Variable Name",
          Enums.MessageType.ERROR
        );
      }
    }
    //비어있는 인수 삭제
    let list = args.filter((arg) => !StringUtils.isEmpty(arg.name));

    if (onSave) {
      onSave({
        target: {
          id: "insertOption",
          value: JSON.stringify({
            arguments: list,
            procedureName,
          }),
        },
      });
    }
    Popup.close();
  };
  const addArgs = (e) => {
    e.preventDefault();
    const inArgs = args.filter((a) => a.mode === "IN");
    const outArgs = args.filter((a) => a.mode === "OUT");
    setArgs([
      ...inArgs,
      {
        mode: "IN",
        name: "",
        type: "STRING",
      },
      ...outArgs,
    ]);
  };

  const onChangeArgs = (e, idx) => {
    let newArgs = [...args];
    if (StringUtils.equalsIgnoreCase(e.target.id, "arg_mode")) {
      newArgs[idx].mode = e.currentTarget.value;
    } else if (StringUtils.equalsIgnoreCase(e.target.id, "arg_name")) {
      newArgs[idx].name = e.currentTarget.value;
    } else if (StringUtils.equalsIgnoreCase(e.target.id, "arg_type")) {
      newArgs[idx].type = e.currentTarget.value;
    }
    setArgs(newArgs);
  };

  const onDeleteArgs = (e, idx) => {
    e.preventDefault();
    let newArgs = [...args];
    newArgs.splice(idx, 1);
    setArgs(newArgs);
  };

  return (
    <Modal>
      <Modal.Header title={"User Custom Numbering Detail Setting"} />
      <Modal.Body>
        <Row className="mb-3"></Row>
        <Row className="mb-3">
          <Col xs={8}>
            <Form.Group>
              <Form.Label className="required">Procedure Name</Form.Label>
              <Form.Control
                value={procedureName}
                onChange={(e) => setProcedureName(e.currentTarget.value)}
                placeholder="Please enter Procedure Name..."
              />
              <Form.Text></Form.Text>
            </Form.Group>
          </Col>

          <Col xs={4} style={{ display: "flex", alignItems: "flex-end" }}>
            <Button onClick={addArgs}>Add Argument</Button>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col style={{ display: "flex", justifyContent: "center" }} xs={4}>
            Mode
          </Col>
          <Col style={{ display: "flex", justifyContent: "center" }} xs={3}>
            Type
          </Col>
          <Col style={{ display: "flex", justifyContent: "center" }}>
            Argument Variable Name
          </Col>
        </Row>
        {args.map((arg, idx) => {
          let disable = false;
          if (
            StringUtils.equalsIgnoreCase(arg.name, "tenantId") ||
            StringUtils.equalsIgnoreCase(arg.name, "cocd")
          ) {
            disable = true;
          }

          return (
            <Row key={idx} className="mb-3">
              <Col xs={4}>
                <Form.Control
                  type="text"
                  value={arg.mode}
                  disabled={true}
                  onChange={() => {}}
                />
              </Col>
              <Col xs={3}>
                <Form.Select
                  id="arg_type"
                  value={arg.type}
                  disabled={disable}
                  onChange={(e) => onChangeArgs(e, idx)}
                >
                  {argumentTypes.map((type) => {
                    return (
                      <option key={type.id} value={type.id}>
                        {type.text}
                      </option>
                    );
                  })}
                </Form.Select>
              </Col>
              <Col>
                <InputGroup>
                  <Form.Control
                    value={arg.name}
                    id={"arg_name"}
                    disabled={disable}
                    onChange={(e) => onChangeArgs(e, idx)}
                  />
                  <Button
                    variant="danger"
                    disabled={
                      disable || StringUtils.equalsIgnoreCase(arg.mode, "OUT")
                    }
                    onClick={(e) => onDeleteArgs(e, idx)}
                  >
                    <FaTrash size="16" />
                  </Button>
                </InputGroup>
              </Col>
            </Row>
          );
        })}
      </Modal.Body>
      <Modal.Footer>
        <Modal.Footer.Button onClick={onConfirmInsertOption}>
          Confirm
        </Modal.Footer.Button>
      </Modal.Footer>
    </Modal>
  );
}
