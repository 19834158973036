import { Enums } from "components/builder/BuilderEnum";
import Api from "components/common/Api";
import ObjectUtils from "components/common/utils/ObjectUtils";
import User from "components/common/utils/UserUtils";
import LocalStorageService from "services/common/LocalService";

class WorkflowService {
  static requestMapping = "/workflow";

  /**
   * 프로세스 목록
   * @param {*} data
   * @param {*} callbackFnc
   * @param {*} errorCallback
   */
  static getProcessList(data, callbackFnc, errorCallback) {
    Api.post(
      this.requestMapping + "/getProcessList",
      data,
      callbackFnc,
      errorCallback
    );
  }
  /**
   * 서비스 저장
   * @param {*} data
   * @param {*} callbackFnc
   * @param {*} errorCallback
   */
  static saveService(data, callbackFnc, errorCallback) {
    const body = {
      ...data,
      convertLog: data.convertLog
        ? typeof data.convertLog === "string"
          ? data.convertLog
          : JSON.stringify(data.convertLog)
        : null,
      serviceContent:
        typeof data.serviceContent === "string"
          ? data.serviceContent
          : JSON.stringify(data.serviceContent),
      serviceComment:
        typeof data.serviceComment === "string"
          ? data.serviceComment
          : JSON.stringify(data.serviceComment),
      serviceMemo:
        typeof data.serviceMemo === "string"
          ? data.serviceMemo
          : JSON.stringify(data.serviceMemo),
    };
    Api.post(
      this.requestMapping + "/saveService",
      body,
      (res) => {
        res.data = this.setData(res.data);
        callbackFnc(res);
      },
      errorCallback
    );
  }
  /**
   * 서비스 목록 호출
   * @param {*} data
   * @param {*} callbackFnc
   * @param {*} errorCallback
   */
  static getServiceList(data, callbackFnc, errorCallback) {
    Api.post(
      this.requestMapping + "/getServiceList",
      data,
      callbackFnc,
      errorCallback
    );
  }
  /**
   * 서비스 삭제
   * @param {*} data
   * @param {*} callbackFnc
   * @param {*} errorCallback
   */
  static deleteService(data, callbackFnc, errorCallback) {
    Api.post(
      this.requestMapping + "/deleteService",
      data,
      callbackFnc,
      errorCallback
    );
  }
  /**
   * 서비스 디테일 호출
   * @param {*} data
   * @param {*} callbackFnc
   * @param {*} errorCallback
   */
  static getService(data, callbackFnc, errorCallback) {
    Api.post(
      this.requestMapping + "/getService",
      data,
      callbackFnc,
      errorCallback
    );
  }

  /**
   * service ID를 사용하여 서비스 호출
   * @param {*} data
   * @param {*} callbackFnc
   * @param {*} errorCallback
   */
  static getWorkflowByServiceId(data, callbackFnc, errorCallback) {
    Api.post(
      this.requestMapping + "/getWorkflowByServiceId",
      data,
      callbackFnc,
      errorCallback
    );
  }

  /**
   * 서비스 배포
   * @param {*} data
   * @param {*} callbackFnc
   * @param {*} errorCallback
   */
  static deployService(data, callbackFnc, errorCallback) {
    const body = {
      ...data,
      convertLog: data.convertLog
        ? typeof data.convertLog === "string"
          ? data.convertLog
          : JSON.stringify(data.convertLog)
        : null,
      serviceContent:
        typeof data.serviceContent === "string"
          ? data.serviceContent
          : JSON.stringify(data.serviceContent),
      serviceComment:
        typeof data.serviceComment === "string"
          ? data.serviceComment
          : JSON.stringify(data.serviceComment),
      serviceMemo:
        typeof data.serviceMemo === "string"
          ? data.serviceMemo
          : JSON.stringify(data.serviceMemo),
    };
    Api.post(
      this.requestMapping + "/deployService",
      body,
      (res) => {
        res.data = this.setData(res.data);
        callbackFnc(res);
      },
      errorCallback
    );
  }

  /**
   * 서비스 직접 배포
   * @param {*} data
   * @param {*} callbackFnc
   * @param {*} errorCallback
   */
  static deployDirectService(data, callbackFnc, errorCallback) {
    const _modulCd = data.moduleCd === "*" ? "all" : data.moduleCd;
    if (typeof data.resourceWf !== "string") {
      data.resourceWf = JSON.stringify(data.resourceWf);
    }
    Api.directPost(
      `/${_modulCd}/WorkflowDeploy`,
      data,
      callbackFnc,
      errorCallback
    );
  }

  /**
   * 서비스 용 발리데이션 룰 호출
   * @param {*} data
   * @param {*} callbackFnc
   * @param {*} errorCallback
   */
  static getServiceValidationRule(data, callbackFnc, errorCallback) {
    Api.post(
      this.requestMapping + "/getServiceValidationRule",
      data,
      callbackFnc,
      errorCallback
    );
  }

  /**
   * 서비스 이력 목록 호출
   * @param {*} data
   * @param {*} callbackFnc
   * @param {*} errorCallback
   */
  static getServiceHistoryList(data, callbackFnc, errorCallback) {
    Api.post(
      this.requestMapping + "/getServiceHistoryList",
      data,
      callbackFnc,
      errorCallback
    );
  }

  /**
   * 서비스 상세 목록 호출
   * @param {*} data
   * @param {*} callbackFnc
   * @param {*} errorCallback
   */
  static getHistoryDetailList(data, callbackFnc, errorCallback) {
    Api.post(
      this.requestMapping + "/getHistoryDetailList",
      data,
      callbackFnc,
      errorCallback
    );
  }

  /**
   * 서비스 디버깅 또는 테스트 실행 중 로그 내용을 불러옴
   * @param {*} data
   * @param {*} callbackFnc
   * @param {*} errorCallback
   */
  static getLog(data, callbackFnc, errorCallback) {
    Api.post(this.requestMapping + "/getLog", data, callbackFnc, errorCallback);
  }

  /**
   * 워크플로우 상속
   * 프로그램 내보내기시 사용
   * @param {*} data
   * @param {*} callbackFnc
   * @param {*} errorCallback
   */
  static succeedWorkflow = async (data, callbackFnc, errorCallback) => {
    await Api.post(
      this.requestMapping + "/succeedWorkflow",
      data,
      callbackFnc,
      errorCallback
    );
  };

  /**
   * 워크플로우 삭제
   * 버전 삭제시 사용
   * @param {*} data
   * @param {*} callbackFnc
   * @param {*} errorCallback
   */
  static deleteByAppReleaseId = async (data, callbackFnc, errorCallback) => {
    await Api.post(
      this.requestMapping + "/deleteServiceByAppReleaseId",
      data,
      callbackFnc,
      errorCallback
    );
  };

  /**
   * Service History 가져옴
   * @param {*} data
   * @param {*} callbackFnc
   * @param {*} errorCallback
   */
  static getServiceHistory(data, callbackFnc, errorCallback) {
    Api.post(
      this.requestMapping + "/getServiceHistory",
      data,
      callbackFnc,
      errorCallback
    );
  }
  /**
   * ElementTab에서 사용할 serviceList를 불러옴
   */
  static getElementTabServiceList = async (
    data,
    callbackFnc,
    errorCallback
  ) => {
    await Api.post(
      this.requestMapping + "/getElementTabServiceList",
      data,
      callbackFnc,
      errorCallback
    );
  };

  /**
   * 런타임 서버에 CS 파일을 보내서 Workflow Service
   * @param {*} data
   * @param {*} callbackFnc
   * @param {*} errorCallback
   */
  static convertCSFileToWorkflow = (data, callbackFnc, errorCallback) => {
    Api.post(
      this.requestMapping + "/convertCSFileToWorkflow",
      data,
      callbackFnc,
      errorCallback
    );
  };

  /**
   * CS 파일에서 나온 결과물을 저장함
   * @param {*} data
   * @param {*} callbackFnc
   * @param {*} errorCallback
   */
  static uploadMultiServiceFromCsFile = (data, callbackFnc, errorCallback) => {
    Api.post(
      this.requestMapping + "/uploadMultiServiceFromCsFile",
      data,
      callbackFnc,
      errorCallback
    );
  };

  /**
   * CS에 업로드된 파일 목록 호출
   * @param {*} data
   * @param {*} callbackFnc
   * @param {*} errorCallback
   */
  static getCSFileList = (data, callbackFnc, errorCallback) => {
    Api.post(
      this.requestMapping + "/getCSFileList",
      data,
      callbackFnc,
      errorCallback
    );
  };

  /**
   * CS 파일 및 서비스 저장
   * @param {*} data
   * @param {*} callbackFnc
   * @param {*} errorCallback
   */
  static saveCSWorkflow = (data, callbackFnc, errorCallback) => {
    Api.post(
      this.requestMapping + "/saveCSWorkflow",
      data,
      callbackFnc,
      errorCallback
    );
  };

  /**
   * CS 파일 삭제
   * @param {*} data
   * @param {*} callbackFnc
   * @param {*} errorCallback
   */
  static deleteFile = (data, callbackFnc, errorCallback) => {
    Api.post(
      this.requestMapping + "/deleteFile",
      data,
      callbackFnc,
      errorCallback
    );
  };

  /**
   * CS 파일 삭제
   * @param {*} data
   * @param {*} callbackFnc
   * @param {*} errorCallback
   */
  static getCsFileDetail = (data, callbackFnc, errorCallback) => {
    Api.post(
      this.requestMapping + "/getCsFileDetail",
      data,
      callbackFnc,
      errorCallback
    );
  };

  /**
   * Query Validate 실행
   * @param {*} data
   * @param {*} callbackFnc
   * @param {*} errorCallback
   */
  static validateQuery = (data, callbackFnc, errorCallback) => {
    Api.post(
      this.requestMapping + "/validateQuery",
      data,
      callbackFnc,
      errorCallback
    );
  };

  /**
   * 불러온 서비스를 rendering 가능한 구조로 변경
   * @param {*} _responseData
   * @returns
   */
  static setData = (_responseData) => {
    const serviceDetail = _responseData;
    delete serviceDetail.userId;
    serviceDetail.serviceContent = JSON.parse(serviceDetail.serviceContent);
    serviceDetail.serviceComment = JSON.parse(serviceDetail.serviceComment);
    serviceDetail.serviceMemo = serviceDetail.serviceMemo
      ? JSON.parse(serviceDetail.serviceMemo)
      : [];

    return serviceDetail;
  };

  /**
   * 불러온 서비스를 rendering 가능한 구조로 변경
   * @param {*} _responseData
   * @returns
   */
  static setCsFileServiceToWorkflowData = (_responseData, workspace) => {
    const { tenantId, coCd, appReleaseId, appId, moduleCd } = workspace;
    const serviceDetail = {};
    const { serviceContent, serviceComment, serviceMemo, ...otherInfo } =
      _responseData;
    serviceDetail.serviceContent = JSON.parse(serviceContent);
    serviceDetail.serviceComment = { process: [], connector: [] };
    serviceDetail.serviceMemo = serviceMemo ? JSON.parse(serviceMemo) : [];

    return {
      ...serviceDetail,
      serviceInfo: {
        ...otherInfo,
        tenantId,
        coCd,
        appReleaseId,
        appId,
        moduleCd,
        convertLog: ObjectUtils.isObject(_responseData.convertLog)
          ? _responseData.convertLog
          : JSON.parse(_responseData.convertLog),
        userId: User.getId(),
      },
    };
  };

  /**
   * 특정 서비스(WF)를 참조하는 다른 WF 찾기
   * @param {*} data
   * @param {*} callback
   * @param {*} errorCallback
   */
  static getWorkflowUsingService = (data, callback, errorCallback) => {
    Api.post(
      this.requestMapping + "/getWorkflowUsingService",
      data,
      callback,
      errorCallback
    );
  };

  /**
   * Quert Node 작성시 Quert Syntax 체크 기능
   * @param {*} data
   * @param {*} callback
   * @param {*} errorCallback
   */
  static getQuerySyntaxVaildate = (data, callback, errorCallback) => {
    Api.post(
      this.requestMapping + "/getQuerySyntaxVaildate",
      data,
      callback,
      errorCallback
    );
  };

  /**
   * 로컬 스토리지 저장
   * @param {*} service
   */
  static localStorageSave(service) {
    const thisTarget = {
      appReleaseId: service.appReleaseId,
      serviceUid: service.serviceUid,
      serviceName: service.serviceName,
    };

    const saveAsData = () => {
      const body = {
        userId: User.getId(),
        list: [thisTarget],
      };
      LocalStorageService.set(Enums.LocalStorageName.WORKFLOW_HISTORY, body);
    };

    const localHistStorage = LocalStorageService.get(
      Enums.LocalStorageName.WORKFLOW_HISTORY
    );
    if (localHistStorage) {
      if (localHistStorage.list.length > 10) localHistStorage.list.splice(10);
      //기존에 동일한 프로그램 아이디가 저장되어있으면 해당 인덱스 삭제
      const isDupIndex = localHistStorage.list.findIndex(
        (p) => p.serviceUid === thisTarget.serviceUid
      );
      if (isDupIndex > -1) localHistStorage.list.splice(isDupIndex, 1);
      localHistStorage.list.splice(0, 0, thisTarget);
      LocalStorageService.set(
        Enums.LocalStorageName.WORKFLOW_HISTORY,
        localHistStorage
      );
    } else {
      saveAsData();
    }
  }
}

export default WorkflowService;
