import React from "react";
import { connect } from "react-redux";

import BuilderToolbarButton from "components/builder/BuilderToolbarButton";
import * as Enums from "components/builder/BuilderEnum";
import Message from "components/common/Message";
import { AppContext } from "components/common/AppContextProvider";
import UIReduxHelper from "components/builder/ui/editor/helper/UIReduxHelper";

import Popup from "components/common/Popup";
import TemplatePopup from "page/popup/TemplatePopup";

import {
  JsonUtils,
  ObjectUtils,
  StringUtils,
} from "components/common/utils/CommonUtils";
import ProgramService from "services/ui/ProgramService";
import ProgramListPopup from "page/popup/ProgramListPopup";
import produce from "immer";
import User from "components/common/utils/UserUtils";
import {
  shortKeyBind,
  UserShortKey,
} from "components/setting/section/shortKey/ShortKeyConfig";
import { flushSync } from "react-dom";
import ProgramExportPopup from "page/popup/ProgramExportPopup";
import { setMemo } from "components/builder/ui/reducers/UIBuilderAction";
import BuilderHeader, {
  HeaderMapDispatchToProps,
  HeaderMapStateToProps,
} from "components/builder/BuilderHeader";
import {
  updateEventWorkspace,
  updateEventOutput,
} from "../eventhandler/reducer/EventHandlerAction";
import ComponentService from "services/datamodel/DataModelService";
import UITemplateHelper from "./editor/helper/UITemplateHelper";
import NamingUtils from "components/common/utils/NamingUtils";
import SavePopup from "page/popup/ui/SavePopup";
import DeployPopup from "page/popup/ui/DeployPopup";
import LocalStorageService from "services/common/LocalService";

class UIBuilderHeader extends BuilderHeader {
  constructor(props) {
    super(props);
    this.toolbarStyle = props.toolbarStyle || "menu";
    this.shortKeyConfig = User.getConfiguration(
      Enums.ConfigurationDefine.SHORT_KEY_SET
    );

    this.openNewBlank = this.openNewBlank.bind(this);
    this.openNewStandardTmpl = this.openNewStandardTmpl.bind(this);
    this.openNewSharedTmpl = this.openNewSharedTmpl.bind(this);
    this.open = this.open.bind(this);
    this.download = this.download.bind(this);
    // this.save = this.save.bind(this);
    this.setBodyForSave = this.setBodyForSave.bind(this);
    // this.deploy = this.deploy.bind(this);
    this.export = this.export.bind(this);
    this.remove = this.remove.bind(this);
    this.rollback = this.rollback.bind(this);
    // this._programSave = this._programSave.bind(this);

    this.getEditorMode = this.getEditorMode.bind(this);
    this.setting = this.setting.bind(this);
    this.setDropdownEvent = this.setDropdownEvent.bind(this);

    this.state = {
      showNewDropdown: false,
      showEditDropdown: false,
      editorMode: this.getEditorMode(),
      editor: window.location.pathname.indexOf("/ui") > -1,
    };
  }
  static contextType = AppContext;

  handleKeyUp = (event) => {
    const shortSave = () => {
      if (this.props.information?.programId) {
        //기 저장된 항목이면 바로 저장
        const shortKeySaveBody = {
          ...this.props.information,
          ...this.props.output.page.propertyValue,
          ...this.props.workspace,
          description: this.props.output.page.propertyValue.programDesc,
          programType: this.props.output.page.propertyValue.programType || "M",
          committer: User.getMastRecId(),
          commitComment: "Quick Save",
          appApplyType: "N",
        };
        this.callbackSaveFnc(shortKeySaveBody);
      } else {
        this.save();
      }
    };

    //단축키 매핑
    if (this.shortKeyConfig) {
      //새로운 화면
      shortKeyBind(event, Enums.ShortKeyDefine.NEW_TEMP, this.openNewBlank);
      //표준화면
      shortKeyBind(
        event,
        Enums.ShortKeyDefine.STAND_TEMP,
        this.openNewStandardTmpl
      );
      //공유화면
      shortKeyBind(
        event,
        Enums.ShortKeyDefine.SHARE_TEMP,
        this.openNewSharedTmpl
      );
      //불러오기
      shortKeyBind(event, Enums.ShortKeyDefine.LOAD, this.open);
      //다운로드
      shortKeyBind(event, Enums.ShortKeyDefine.DOWNLOAD, this.download);
      //저장
      shortKeyBind(event, Enums.ShortKeyDefine.SAVE, shortSave);
      //프로그램 배포
      shortKeyBind(event, Enums.ShortKeyDefine.DEPLOY, this.deploy);
      //다른이름 저장
      shortKeyBind(event, Enums.ShortKeyDefine.SAVEAS, (e) =>
        this.save(e, true)
      );
      //삭제
      shortKeyBind(event, Enums.ShortKeyDefine.DELETE, this.remove);
    } else {
      if (event.altKey && event.keyCode === 78) {
        /* alt + N  ==> 새로운 화면*/
        this.openNewBlank();
      } else if (event.altKey && event.keyCode === 83) {
        /* alt + s ==> 저장*/
        shortSave();
      } else if (event.altKey && event.keyCode === 82) {
        this.deploy();
      }
    }
  };

  componentDidMount() {
    this.headerInit();
  }
  componentWillUnmount() {
    this.setState({
      showConnectDropdown: false,
      showUserDropdown: false,
      showWorkspaceDropdown: false,
    });
    window.removeEventListener("keyup", this.handleKeyUp);
    window.removeEventListener("resize", this.onWindowResize);
  }

  /**
   * editor mode , editor, preview
   * @returns
   */
  getEditorMode = () => {
    return window.location.pathname === Enums.BuilderPath.PREVIEW
      ? Enums.EditMode.PREVIEW
      : Enums.EditMode.EDITOR;
  };

  /**
   * editor mode 전환
   * @returns
   */
  getEditorModeSwitch = () => {
    return this.getEditorMode() === Enums.EditMode.EDITOR
      ? Enums.EditMode.PREVIEW
      : Enums.EditMode.EDITOR;
  };

  /**
   * toolbar button event controll
   * - editor mode에만 허용된 기능
   * - output data가 있을 경우에만 허용되는 기능
   * @param {*} restric
   * @returns
   */
  controllEvent = (validation) => {
    if (!this.getNowScreenEditor()) return false;
    let isValid = true;
    for (const key in validation) {
      if (key === "mode") {
        if (validation[key] !== this.getEditorMode()) {
          isValid = false;
          break;
        }
      } else if (key === "checkOutput") {
        if (
          ObjectUtils.isEmpty(this.props.output) ||
          ObjectUtils.isEmpty(this.props.output.page)
        ) {
          Message.alert("No Data Exists.", Enums.MessageType.ERROR);
          isValid = false;
          break;
        }
      }
    }

    return isValid;
  };

  /**
   * 새로운 화면
   * @param {Event} event
   * @returns
   */
  openNewBlank = (event) => {
    if (!this.controllEvent({ mode: Enums.EditMode.EDITOR })) return false;

    /**
     * TO-DO 작업중이거나 변경된게 있으면 경고 메시지 후 진행되도록 보완 필요
     */

    this.setState({ showNewDropdown: false });
    const componentInfo = this.context.component.getPageComponent(); //Page Component
    this.props.createPage(componentInfo);
    this.props.updateEventWorkspace({});
    this.props.updateEventOutput("");
    this.props.updateInformation(null);
    if (event) event.preventDefault();
  };

  /**
   * 표준 Template
   * @param {Event} event
   * @returns
   */
  openNewStandardTmpl = (event) => {
    if (!this.controllEvent({ mode: Enums.EditMode.EDITOR })) return false;
    /**
     * TO-DO 작업중이거나 변경된게 있으면 경고 메시지 후 진행되도록 보완 필요
     */
    this.openTmplPopup("StandardTmpl");
  };

  /**
   * 공유 Template
   * @param {Event} event
   * @returns
   */
  openNewSharedTmpl = (event) => {
    if (!this.controllEvent({ mode: Enums.EditMode.EDITOR })) return false;
    /**
     * TO-DO 작업중이거나 변경된게 있으면 경고 메시지 후 진행되도록 보완 필요
     */
    this.openTmplPopup("ShareTmpl");
  };

  /**
   * NewStandardTmpl / NewSharedTempl 병행 open Popup function
   * @param {*} templateType
   * @returns
   */
  openTmplPopup = (templateType) => {
    this.setState({ showNewDropdown: false });

    const popupTitle = "Create New";
    const options = {
      effect: Popup.ScaleUp, //Effect.SlideFromTop(default)를 Effect.ScaleUp 로 변경
      style: {
        content: {
          width: "80%", //popup의 크기를 50% (default 60%)
          height: "800px",
        },
      },
    };
    Popup.open(
      <TemplatePopup
        title={popupTitle}
        callbackFnc={this.callbackNewTmplFnc}
        templateType={templateType}
      />,
      options
    );
  };

  /**
   * Template popup Callback function
   * @param {*} tmplOutput
   */
  callbackNewTmplFnc = (tmplOutput) => {
    if (StringUtils.isEmpty(tmplOutput)) {
      Message.alert("No Template data exists.", Enums.MessageType.ERROR);
      return false;
    }
    try {
      const componentInfo = this.context.component.getPageComponent(); //Page Component
      this.props.applyTemplate(componentInfo, tmplOutput);
      this.props.updateEventWorkspace({});
      this.props.updateEventOutput("");
      this.props.updateInformation(null);
    } catch (e) {
      Message.alert(e, Enums.MessageType.ERROR);
    }
  };
  /**
   * 불러오기 버튼 클릭
   * @param {Event} event
   */
  open = (event) => {
    if (!this.controllEvent({ mode: Enums.EditMode.EDITOR })) return false;
    /**
     * TO-DO 작업중이거나 변경된게 있으면 경고 메시지 후 진행되도록 보완 필요
     */
    if (event) event.preventDefault();
    //팝업창 열기
    const popupTitle = <>Load Program</>;
    const options = {
      effect: Popup.ScaleUp, //Effect.SlideFromTop(default)를 Effect.ScaleUp 로 변경
      style: {
        content: {
          width: "70%", //popup의 크기를 50% (default 60%)
          // height:'90%'
        },
      },
    };

    Popup.open(
      <ProgramListPopup
        workspace={this.context.workspace.Info}
        title={popupTitle}
        callbackFnc={this.callbackLoadFnc}
      />,
      options
    );
  };

  /**
   * 불러오기 Popup Callback function
   * @param {*} args editor-data
   * **/
  callbackLoadFnc = (args) => {
    try {
      ProgramService.getProgram(
        args,
        (res) => {
          const { programContent, dataModelList, ...information } = res.data;
          const componentInfo = this.context.component.getPageComponent(); //Page Component
          const { memo, ...output } = JSON.parse(programContent);
          this.props.loadTemplate(componentInfo, JSON.stringify(output));
          if (memo) this.props.setMemo(memo);
          else this.props.setMemo([]);
          information.programContent = JSON.parse(programContent);
          this.props.updateEventWorkspace({});
          this.props.updateEventOutput("");
          this.props.updateInformation(information);
          Message.alert(
            `'${information.programNm}'program has been loaded.`,
            Enums.MessageType.SUCCESS
          );
        },
        () => {
          throw new Error("Error occured while loading program.");
        }
      );
    } catch (e) {
      Message.alert(e, Enums.MessageType.ERROR);
    }
    Popup.close();
  };

  /**
   * 다운로드 버튼 클릭
   * @param {Event} event
   * @returns
   */
  download = (event) => {
    if (!this.controllEvent({ mode: Enums.EditMode.EDITOR, checkOutput: true }))
      return false;

    event.preventDefault();
    Message.alert("Coming Soon.", "info");
  };

  /**
   * 저장 버튼 클릭
   * @param {Event} event
   */
  save = (event, saveAS = false, deploy = false) => {
    if (!this.controllEvent({ mode: Enums.EditMode.EDITOR, checkOutput: true }))
      return false;
    this.setState({ showEditDropdown: false });
    //팝업창 열기
    const popupTitle = "Save Program";
    const options = {
      effect: Popup.ScaleUp, //Effect.SlideFromTop(default)를 Effect.ScaleUp 로 변경
      style: {
        content: {
          width: "30%", //popup의 크기를 50% (default 60%)
        },
      },
    };
    Popup.open(
      <SavePopup
        saveAs={saveAS}
        title={popupTitle}
        callbackFnc={(data, cb) => this.callbackSaveFnc(data, cb, saveAS)}
        information={this.props.information}
        output={this.props.output}
        updatePageByProps={this.props.updatePageByProps}
        workspace={this.props.workspace}
        deploy={deploy}
      />,
      options
    );
    if (event) event.preventDefault();
  };

  /**
   * 저장 버튼 클릭
   * @param {Event} event
   */
  deploy = (event) => {
    if (!this.controllEvent({ mode: Enums.EditMode.EDITOR, checkOutput: true }))
      return false;

    if (ObjectUtils.isEmpty(this.context.connection.Info)) {
      Message.alert(
        "Setting up connections before deployment.",
        Enums.MessageType.INFO
      );
      return this.context.connection.openPopup();
    }
    //팝업창 열기
    const popupTitle = "Deploy";
    const options = {
      effect: Popup.ScaleUp, //Effect.SlideFromTop(default)를 Effect.ScaleUp 로 변경
      style: {
        content: {
          width: "30%", //popup의 크기를 50% (default 60%)
        },
      },
    };
    Popup.open(
      <DeployPopup
        title={popupTitle}
        information={this.props.information}
        output={this.props.output}
        updatePageByProps={this.props.updatePageByProps}
        callbackDeployFnc={this.callbackDeployFnc}
        workspace={this.props.workspace}
        connection={this.context.connection.Info}
      />,
      options
    );
    if (event) event.preventDefault();
  };

  /**
   * 로컬 스토리지 저장
   * @param {*} program
   */
  localStorageSave(program) {
    const thisTarget = {
      appReleaseId: this.props.workspace.appReleaseId,
      programUid: program.programUid,
      programNm: program.programNm,
    };

    const saveAsData = () => {
      const body = {
        userId: User.getId(),
        list: [thisTarget],
      };
      LocalStorageService.set(Enums.LocalStorageName.PROGRAM_HISTORY, body);
    };

    const localProgramHistStorage = LocalStorageService.get(
      Enums.LocalStorageName.PROGRAM_HISTORY
    );
    if (localProgramHistStorage) {
      // const localProgramHist = JSON.parse(localProgramHistStorage);
      //길이가 6개 이상이면 삭제
      if (localProgramHistStorage.list.length > 10)
        localProgramHistStorage.list.splice(10);
      //기존에 동일한 프로그램 아이디가 저장되어있으면 해당 인덱스 삭제
      const isDupIndex = localProgramHistStorage.list.findIndex(
        (p) => p.programUid === thisTarget.programUid
      );
      if (isDupIndex > -1) localProgramHistStorage.list.splice(isDupIndex, 1);
      localProgramHistStorage.list.splice(0, 0, thisTarget);
      LocalStorageService.set(
        Enums.LocalStorageName.PROGRAM_HISTORY,
        localProgramHistStorage
      );
    } else {
      saveAsData();
    }
  }

  /**
   * 프로그램 저장용 바디 생성
   * @param {*} args
   * @param {{saveAs:Boolean,idUpdate:Boolean }} config
   * @returns
   */
  setBodyForSave(args, { saveAs = false, idUpdate = true }) {
    const body = {
      ...args,
      dataModelId: this.props.output.page.propertyValue.dataModelId || null,
      programType: this.props.output.page.propertyValue.programType || "M",
      builderType:
        this.props.output.page.propertyValue.builderType ||
        Enums.BuilderType.UI,
    };
    const newOutputWithNewCompId = produce(this.props.output, (draft) => {
      draft.memo = this.props.memo;
      if (idUpdate) JsonUtils.updateCompId(draft);
      if (saveAs) {
        const [, changedIdObject] = NamingUtils.updateElementIdAndGetPrevData(
          draft,
          this.props.information.programId,
          body.programId
        );
        NamingUtils.replaceOldIdToNewId(draft, changedIdObject);
      }
    });
    body.programContent = JSON.stringify(newOutputWithNewCompId);
    body.tenantMstId = this.props.workspace.tenantMstId;
    return body;
  }

  _programSave = (body, cb) => {
    /**
     * 저장 후 리턴 받은 결과를 처리하는 로직
     * @param {*} res
     */
    const ResponseParser = (res) => {
      const { isError, data } = res;

      if (isError) {
        if (res.message) {
          Message.alert(res.message, Enums.MessageType.ERROR);
        } else {
          Message.alert(
            "Error occuered while saving program. \n Please try again.",
            Enums.MessageType.WARN
          );
        }
      } else {
        if (
          //실행중인 화면이 페이지일때는 프로퍼티 뷰를 리렌더링 해줘야함
          StringUtils.equalsIgnoreType(
            Enums.ComponentType.PAGE,
            this.props.activeComponent.componentType
          )
        ) {
          const templateComponents = UITemplateHelper.getTemplateComponents(
            this.context.component
          );
          /*template 용 표준 base components - Page component */
          const pageTemplate = UITemplateHelper.getTemplate(
            templateComponents,
            Enums.ComponentType.PAGE
          );

          this.props.initActiveComponent(
            JSON.parse(data.programContent),
            pageTemplate
          );
        }
        this.props.updateEventOutput("");
        this.props.updateInformation(data);
        //저장한 프로그램 locaStorage에 저장
        this.localStorageSave(data);

        Popup.close();
      }
    };
    ProgramService.saveProgram(
      body,
      (res) => {
        ResponseParser(res);
        if (res.forceRerender) this.forceUpdate();
        if (cb) cb(res);
      },
      (err) => {
        Message.alert(err, Enums.MessageType.ERROR);
        // if (cb) cb(err);
      }
    );
  };

  /**
   * 저장 Popup Callback function
   * @param {*} output
   */
  callbackSaveFnc = async (args, cb, saveAs) => {
    /**
     * 저장 후에 실행되는 항목
     * 리턴되는 데이터를 Redux에 덮어 씌운다.
     * @param {*} res
     */

    /**
     * 저장할 바디 생성
     */

    if (this.props.information && !saveAs) {
      //update
      if (
        JSON.stringify(this.props.information.programContent) ===
        JSON.stringify({ ...this.props.output, memo: [...this.props.memo] })
      ) {
        //불러온 값과 같으면 저장하지 않음
        Message.alert("No additional contents", Enums.MessageType.INFO);
        if (cb) cb();
      } else {
        const saveBody = this.setBodyForSave(args, { saveAs, idUpdate: false });
        saveBody.programId.trim();
        this._programSave(saveBody, (res) => {
          Message.alert("Saved successfully.", "info");
          if (cb) cb();
        });
      }
    } else {
      //save
      //saveAs
      try {
        const saveBody = this.setBodyForSave(args, { saveAs });
        saveBody.programId.trim();
        saveBody.newProgram = "Y";
        //pageId 새로 부여
        this._programSave(saveBody, () => {
          Message.alert("Saved successfully.", "info");
          cb();
        });
      } catch (error) {
        if (cb) cb();
        console.log(error);
      }
    }
  };
  /**
   * 저장 Popup deploy Callback function
   * @param {*} output
   */
  callbackDeployFnc = (args, cb) => {
    const deployConnection = User.getConnection(
      this.props.workspace.tenantMstId
    );
    if (!deployConnection?.token) {
      if (cb) cb();
      return Message.alert(
        "Authentication server token is not validated.\n Please reconnect.",
        Enums.MessageType.ERROR
      );
    }
    //userId가 deploy서버 connectionID랑 param 명이 같기에 별도로 분리
    const { userId, ...connectionInfo } = deployConnection;
    const saveBody = this.setBodyForSave(args, { idUpdate: false });
    saveBody.appApplyType = "Y";
    saveBody.commitComment = "[Server Deploy] " + args.commitComment;
    saveBody.tenantId = this.props.workspace.tenantId;
    saveBody.coCd = this.props.workspace.coCd;
    saveBody.tenantMstId = this.props.workspace.tenantMstId;

    if (
      StringUtils.equalsIgnoreCase(deployConnection.connectionType, "direct")
    ) {
      saveBody.connectionInfo = { ...connectionInfo };
      saveBody.userId = userId;

      const _deploy = () => {
        ProgramService.directDeploy(
          saveBody,
          (res) => {
            saveBody.appApplyType = "N";
            this._programSave(saveBody, () => {
              Message.alert(
                "Program has been deployed and saved successfully.",
                Enums.MessageType.SUCCESS
              );
              cb();
            });
          },
          (err) => {
            console.log(err);
            Message.alert("Direct deployment failed", Enums.MessageType.ERROR);
            if (cb) cb();
          }
        );
      };

      if (saveBody.deployWithDmYn === "Y") {
        let _dpDM = {};
        const dataModel = {};
        //데이터 모델 직접 배포
        ComponentService.getDataModel(
          { dataModelId: saveBody.dataModelId },
          (res) => {
            if (!res.isError) {
              _dpDM = res.data;
              dataModel.dataModelId = _dpDM.dataModelId;
              dataModel.dataModelType = _dpDM.dataModelType;
              dataModel.dataModelType = _dpDM.dataModelType;
              dataModel.description = _dpDM.description;
              ComponentService.getUsrTnxList(
                { dataModelId: saveBody.dataModelId },
                (tnxRes) => {
                  _dpDM.dataModelUsrTnxs = tnxRes.data;
                  dataModel.resourceDm = JSON.stringify({ dataModel: [_dpDM] });
                  saveBody.dataModel = dataModel;
                  _deploy();
                },
                cb
              );
            }
          },
          cb
        );
      } else {
        _deploy();
      }
    } else {
      saveBody.accessToken = connectionInfo.token;
      saveBody.connectionUserId = userId;
      saveBody.host = connectionInfo.host;
      saveBody.protocol = connectionInfo.protocol;
      this._programSave(saveBody, (res) => {
        if (!res.isError && res.data) {
          Message.alert(
            "Program has been deployed and saved successfully.",
            Enums.MessageType.SUCCESS
          );
        }
        cb();
      });
    }
  };
  /**
   * 내보내기 버튼 클릭
   * @param {Event} event
   */
  export = (event) => {
    if (!this.controllEvent({ mode: Enums.EditMode.EDITOR, checkOutput: true }))
      return false;

    if (event) event.preventDefault();
    // return Message.alert("개발 중인 기능 입니다.", "info");
    if (!this.props.information) {
      return Message.alert(
        "This is not a saved program. Please save first.",
        Enums.MessageType.INFO
      );
    }
    if (
      this.props.information.tenantId !== "*" ||
      this.props.information.coCd !== "*"
    ) {
      return Message.alert(
        "Only standard programs can be exported.",
        Enums.MessageType.INFO
      );
    }

    const options = {
      effect: Popup.ScaleUp, //Effect.SlideFromTop(default)를 Effect.ScaleUp 로 변경
      style: {
        content: {
          width: "350px", //popup의 크기를 50% (default 60%)
        },
      },
    };
    Popup.open(
      <ProgramExportPopup
        appList={this.context.application.availableList}
        program={this.props.information}
      />,
      options
    );
  };

  /**
   * 삭제 버튼 클릭
   * @param {Event} event
   */
  remove = (event) => {
    event.preventDefault();
    if (!this.controllEvent({ mode: Enums.EditMode.EDITOR, checkOutput: true }))
      return false;
    if (this.props.information) {
      const { information } = this.props;
      Message.confirm(
        `'${information.programNm}' Are you sure to delete the program?`,
        () => {
          ProgramService.deleteProgram(
            {
              ...information,
              programContent: JSON.stringify(this.props.output),
              commitComment: "Delete",
              committer: User.getMastRecId(),
              deleteYn: "Y",
            },
            () => {
              Message.alert("Program deleted", Enums.MessageType.SUCCESS);
              this.openNewBlank();
              this.props.updateEventWorkspace({});
              this.props.updateEventOutput("");
              this.props.updateInformation(null);
            }
          );
        }
      );
    }
    // Message.alert("개발 중인 기능 입니다.", "info");
  };

  getButtonClass = () => {
    if (this.getNowScreenEditor()) {
      return this.getEditorMode() === Enums.EditMode.PREVIEW ? "disabled" : "";
    } else {
      return "disabled";
    }
  };
  /**
   * 현재 보고 있는 화면이 에디터용 화면인지 확인
   * **/
  getNowScreenEditor() {
    return window.location.pathname.indexOf("/ui") > -1;
  }

  setting() {
    Message.alert("Coming Soon..", "info");
  }

  setDropdownEvent(_state, _buttonId, editorScreenOnly = true) {
    /**
     * @param {Event} event
     * @returns
     */
    const showDropdown = (event) => {
      if (
        editorScreenOnly &&
        !this.controllEvent({ mode: Enums.EditMode.EDITOR })
      )
        return false;
      event.preventDefault();

      const newState = {
        showNewDropdown: false,
        showEditDropdown: false,
        showConnectDropdown: false,
      };
      newState[_state] = !this.state[_state];

      if (newState[_state]) {
        this.setState(newState, () => {
          document.addEventListener("click", closeDropdown);
        });
      } else {
        this.setState(newState, () => {
          document.removeEventListener("click", closeDropdown);
        });
      }
    };
    /**
     * dropdown menu close event
     * @param {Event} event
     */
    const closeDropdown = (event) => {
      const {
        target: {
          dataset: { buttonId },
        },
      } = event;
      if (
        buttonId !== _buttonId &&
        this.dropdownMenu != null &&
        !this.dropdownMenu.contains(event.target)
      ) {
        const newState = produce(this.state, (draft) => {
          draft[_state] = false;
        });
        this.setState(newState, () => {
          document.removeEventListener("click", closeDropdown);
        });
      }
    };

    return showDropdown;
  }

  rollback = () => {};

  renderHeader() {
    const NewTemplDropdownEvent = this.setDropdownEvent(
      "showNewDropdown",
      "new"
    );
    const EditDropdownEvent = this.setDropdownEvent("showEditDropdown", "edit");
    return (
      <>
        {!ObjectUtils.isEmpty(this.props.workspace) ? (
          <>
            <li>
              <BuilderToolbarButton
                style={this.toolbarStyle}
                className={this.getButtonClass()}
                buttonId="new"
                onClick={NewTemplDropdownEvent}
                iconOnly={this.state.mini}
                tooltipTitle="New"
              />
              {this.state.showNewDropdown ? (
                <div
                  className="toolbar-dropdown"
                  ref={(element) => {
                    this.dropdownMenu = element;
                  }}
                >
                  <ul>
                    <li
                      className="dropdown-item"
                      role="button"
                      onClick={this.openNewBlank}
                      style={{ width: "250px" }}
                    >
                      New (Blank)
                      <span>{UserShortKey(Enums.ShortKeyDefine.NEW_TEMP)}</span>
                    </li>
                    <li
                      className="dropdown-item"
                      role="button"
                      onClick={this.openNewStandardTmpl}
                    >
                      Standard Page Template
                      <span>
                        {UserShortKey(Enums.ShortKeyDefine.STAND_TEMP)}
                      </span>
                    </li>
                    <li
                      className="dropdown-item"
                      role="button"
                      onClick={this.openNewSharedTmpl}
                    >
                      Shared Page Template
                      <span>
                        {UserShortKey(Enums.ShortKeyDefine.SHARE_TEMP)}
                      </span>
                    </li>
                  </ul>
                </div>
              ) : null}
            </li>

            <li>
              <BuilderToolbarButton
                style={this.toolbarStyle}
                className={this.getButtonClass()}
                buttonId="open"
                onClick={this.open}
                iconOnly={this.state.mini}
                tooltipTitle={`Open ${UserShortKey(Enums.ShortKeyDefine.LOAD)}`}
              />
            </li>

            <li>
              <BuilderToolbarButton
                className={this.getButtonClass()}
                style={this.toolbarStyle}
                buttonId="divider"
              />
            </li>
            <li>
              <BuilderToolbarButton
                style={this.toolbarStyle}
                buttonId="divider"
              />
            </li>
            <li>
              <BuilderToolbarButton
                style={this.toolbarStyle}
                className={this.getButtonClass()}
                buttonId="edit"
                onClick={EditDropdownEvent}
                iconOnly={this.state.mini}
                tooltipTitle={"Edit"}
              />
              {this.state.showEditDropdown ? (
                <div
                  className="toolbar-dropdown"
                  ref={(element) => {
                    this.dropdownMenu = element;
                  }}
                >
                  <ul>
                    <li
                      className="dropdown-item"
                      role="button"
                      onClick={this.save}
                      style={{ width: "250px" }}
                    >
                      Save
                      <span>{UserShortKey(Enums.ShortKeyDefine.SAVE)}</span>
                    </li>
                    <li
                      className="dropdown-item"
                      role="button"
                      onClick={(e) => this.save(e, true)}
                    >
                      Save as
                      <span>{UserShortKey(Enums.ShortKeyDefine.SAVEAS)}</span>
                    </li>
                    <li
                      className="dropdown-item"
                      role="button"
                      onClick={this.export}
                      style={{ width: "250px" }}
                    >
                      Export
                    </li>
                    <li
                      className="dropdown-item"
                      role="button"
                      onClick={this.remove}
                    >
                      Delete
                    </li>
                  </ul>
                </div>
              ) : null}
            </li>
            <li>
              <BuilderToolbarButton
                style={this.toolbarStyle}
                className={
                  this.getNowScreenEditor() ? "" : this.getButtonClass()
                }
                buttonId={"deploy"}
                onClick={this.deploy}
                iconOnly={this.state.mini}
                tooltipTitle={`Deploy ${UserShortKey(
                  Enums.ShortKeyDefine.DEPLOY
                )}`}
              />
            </li>
          </>
        ) : (
          <></>
        )}
      </>
    );
  }
}

const dispatchNewBlankPage = (dispatch) => {
  return {
    createPage: (componentInfo) =>
      UIReduxHelper.createPage(dispatch, componentInfo),
    applyTemplate: (componentInfo, tmplOutput) =>
      UIReduxHelper.applyTemplate(dispatch, componentInfo, tmplOutput),
    loadTemplate: (componentInfo, tmplOutput) =>
      UIReduxHelper.loadTemplate(dispatch, componentInfo, tmplOutput),
    updateInformation: (information) =>
      UIReduxHelper.updateInformation(dispatch, information),
    updatePageByProps: (output, propertyValue) =>
      UIReduxHelper.updatePageByProps(dispatch, output, null, propertyValue),
    setMemo: (memo) => {
      dispatch(setMemo(memo));
    },
    initActiveComponent: (output, pageTemplate) => {
      flushSync(() => {
        UIReduxHelper.initActivateComponent(dispatch);
      });
      const activedPageComponent = produce(pageTemplate, (draft) => {
        draft.propertyValue = output.page.propertyValue;
        draft.compId = output.page.compId;
      });
      UIReduxHelper.activateComponent(activedPageComponent, dispatch);
    },

    ...HeaderMapDispatchToProps(dispatch),

    updateEventWorkspace: (workspace) => {
      dispatch(updateEventWorkspace(workspace));
    },
    updateEventOutput: (output) => {
      dispatch(updateEventOutput(output));
    },
  };
};

export default connect((state) => {
  return {
    output: state.outputUI.output,
    memo: state.outputUI.memo,
    information: state.outputUI.information,
    activeComponent: state.activedUIComponent.component,
    workspace: state.workspace,
    ...HeaderMapStateToProps(state),
  };
}, dispatchNewBlankPage)(UIBuilderHeader);
