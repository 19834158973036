import { Enums } from "components/builder/BuilderEnum";
import { stopEvent } from "components/builder/ui/editor/handler/UIEditorEventHandler";
import UModal from "components/common/modal/UModal";
import ArrayUtils from "components/common/utils/ArrayUtils";
import JsonUtils from "components/common/utils/JsonUtils";
import User from "components/common/utils/UserUtils";
import { WorkflowContext } from "page/workflow";
import { useCallback, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactFlow, {
  Background,
  ConnectionMode,
  MarkerType,
  MiniMap,
  useEdgesState,
  useNodesState,
  useOnViewportChange,
} from "reactflow";
import LocalStorageService from "services/common/LocalService";
import {
  Connector_popup_width,
  edgeTypes,
  nodeTypes,
} from "../WorkflowBuilder";
import StringUtils from "components/common/utils/StringUtils";
import ObjectUtils from "components/common/utils/ObjectUtils";
import { useRef } from "react";
import ConnectorPopup from "page/popup/workflow/ConnectorPopup";
import Popup from "components/common/Popup";
import Message from "components/common/Message";
import { getConnectorPosition } from "../editor/render/WorkflowRenderUtils";
import WorkflowReduxHelper from "../editor/helper/WorkflowReduxHelper";
import produce from "immer";
import ConnectorValidationPopup from "page/popup/workflow/ConnectorValidationPopup";
import ConnectorConditionPopup from "page/popup/workflow/ConnectorConditionPopup";
import { updateBundle, updateBundleViewport } from "../reducer/WorkflowAction";
import { MdClose, MdEdit } from "react-icons/md";
import { Button } from "react-bootstrap";
import WorkflowNodeList from "./WorkflowNodeList";
import useWorkflowDropEvent from "../editor/render/useWorkflowDropEvent";
import { FaExpand } from "react-icons/fa";
import { IoMdContract } from "react-icons/io";

/**
 * Workflow Builder랑 동일한 함수는 HOC로 처리할 것...
 * 현재 할 여유가 없음...
 * @param {*} param0
 * @returns
 */
function BundleDetailBuilder({ ...props }) {
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [reactFlowInstance, setReactFlowInstance] = useState(null);
  const [bundleColor, setBundleColor] = useState("");
  const [textColor, setTextColor] = useState("");
  const workflow = useSelector((state) => state.workflow);
  const debug = useSelector((state) => state.workflowDebug);
  const dispatch = useDispatch();
  const {
    bundle: {
      selectedBundle,
      setOpenBundleDetailPopup,
      setSelectedBundle,
      openBundleDetailPopup,
      setBundleModalExpand,
      bundleModalExpand,
      setBundleNodeList,
      setBundlingMode,
    },
    debug: { debugExpressionMode, setDebugExpressionMode },
  } = useContext(WorkflowContext);
  const [bundle, setBundle] = useState({});

  const debuggingBundle = useRef({});
  const nodePositionChangeRef = useRef();
  const nodeDimensionChangeRef = useRef();
  const inDebounce = useRef();
  const reactFlowWrapper = useRef();

  const userTheme = LocalStorageService.get(
    Enums.LocalStorageName.EDITOR_THEME
  );
  const [editorTheme, setEditorTheme] = useState(
    userTheme
      ? userTheme.userId === User.getId()
        ? userTheme.theme
        : "light"
      : "light"
  );

  const {
    trace,
    isDebugging,
    process: debugProcess,
  } = useSelector((state) => state.workflowDebug);

  //뷰포트 기억할때 쓰는 함수
  const debounceScroll = (func, delay) => {
    if (inDebounce.current) clearTimeout(inDebounce.current);
    inDebounce.current = setTimeout(() => func(), delay);
  };

  const {
    onDropIterator,
    onDropCondition,
    onDropProcess,
    onDropService,
    onDropCode,
  } = useWorkflowDropEvent();

  /**
   * 뷰포트 바뀔때 redux에 저장
   */
  const viewPortLogger = useOnViewportChange({
    onEnd: useCallback(
      (viewport) =>
        debounceScroll(() => {
          dispatch(updateBundleViewport(viewport));
        }, 250),
      []
    ),
  });

  /**
   * 디버깅시 트래킹
   */
  useEffect(() => {
    if (isDebugging) {
      const bundleList = workflow.output.bundle;
      if (ArrayUtils.isEmpty(bundleList)) return false;
      bundleList.forEach((process) => {
        const isDebuggingBundle = process.propertyValue.nodeList.find(
          (bn) => bn.bundleCompId === debugProcess.compId
        );
        if (isDebuggingBundle) {
          if (!process.propertyValue.expand) {
            setSelectedBundle(process);
            setOpenBundleDetailPopup(true);
            debuggingBundle.current = process;
          }
        } else {
          if (debuggingBundle.current.compId === process.compId) {
            setSelectedBundle({});
            setOpenBundleDetailPopup(false);
            debuggingBundle.current = {};
          }
        }
      });
    }
  }, [debugProcess]);

  /**
   * 번들 선택시 초기설정
   */
  useEffect(() => {
    if (!ObjectUtils.isEmpty(selectedBundle)) {
      initBundleNode();
    }
  }, [selectedBundle, workflow]);

  /**
   * 팝업 오픈시 뷰포트 설정
   */
  useEffect(() => {
    //뷰포트 지정
    if (openBundleDetailPopup && reactFlowInstance) {
      reactFlowInstance.setViewport(
        bundle.viewport || {
          x: 0,
          y: 0,
          zoom: workflow.output.service.viewport.zoom,
        }
      );
    }
  }, [openBundleDetailPopup]);

  /**
   * 모달 정보 초기화
   */
  const initModalInfo = () => {
    setBundleColor("");
    setNodes([]);
    setEdges([]);
    setSelectedBundle({});
  };

  /**
   * 번들 정보 초기세팅
   */
  const initBundleNode = () => {
    const bundleNode = workflow.output.bundle.find(
      (b) => b.compId === selectedBundle.compId
    );
    if (!ObjectUtils.isEmpty(bundleNode)) {
      setBundle(bundleNode);
      const bundleChildNode = bundleNode.propertyValue.nodeList.map((node) => {
        const origianlNode = JsonUtils.findNode(
          workflow,
          "compId",
          node.bundleCompId
        );
        return origianlNode;
      });
      //색 지정
      setBundleColor(bundleNode.propertyValue.groupColor);
      setTextColor(bundleNode.propertyValue.textColor);

      render(bundleChildNode);
    }
  };

  /**
   * 렌더링 함수
   */
  const render = (bundleNodeList) => {
    const nodeList = [];
    const edgeList = [];

    const renderNode = (processList) => {
      processList.forEach((process) => {
        //그룹핑 중인지 여부 판단
        const isBundling = bundleNodeList.find(
          (n) => n.compId === process.compId
        );

        const node = {
          id: process.compId,
          type: process.type,
          position: process.position,
          style: process.style,
          data: {
            process: process,
            editorTheme,
            isBundling: isBundling ? true : false,
          },
        };
        nodeList.push(node);
        addIteratorProcess(process);
      });
    };
    /**
     * 커넥터 렌더링
     * @param {Object} param
     * @param {Array} param.connectorList
     * @param {Object} param.iterator
     */
    const renderConnector = ({ connectorList, iterator }) => {
      connectorList.forEach((connector) => {
        const { edgeDetailInfo } = connector;

        let fromCompId = connector.processFrom;
        let toCompId = connector.processTo;
        //1. 커넥터 노드 세팅
        const node = {
          id: connector.compId,
          type: Enums.WorkflowNodeType.CONNECTOR,
          position: connector.position,
          data: {
            connector: connector,
          },
        };
        if (iterator) {
          node.parentNode = iterator.compId;
          node.extent = "parent";
        }
        nodeList.push(node);

        //2. 커넥터 노드로 소스 to 커넥터, 커넥터 to 타겟 선 연결
        const edge = {
          id: connector.compId,
          type: "connect",
          sourceHandle: "d",
          targetHandle: "a",
          style: {
            strokeWidth: 2,
            stroke: editorTheme === "light" ? "#3c3c3c" : "lightgray",
          },
          markerEnd: {
            type: MarkerType.ArrowClosed,
            width: 10,
            height: 10,
            color: editorTheme === "light" ? "#3c3c3c" : "lightgray",
          },
        };

        //디버그 트레이스에 포함되어있는지 확인
        if (debug.isDebugging) {
          if (!ArrayUtils.isEmpty(debug.trace)) {
            for (const [index, item] of debug.trace.entries()) {
              if (
                index + 1 < debug.trace.length &&
                connector.processFrom === item.compId
              ) {
                if (debug.trace[index + 1].compId === connector.processTo)
                  edge.animated = true;
              }
            }
          }
        }
        const fromEdge = { ...edge };
        fromEdge.id = "To" + fromEdge.id;
        fromEdge.source = fromCompId;
        fromEdge.target = connector.compId;

        const toEdge = { ...edge };
        toEdge.id = "From" + toEdge.id;
        toEdge.source = connector.compId;
        toEdge.target = toCompId;

        fromEdge.sourceHandle = edgeDetailInfo.from.sourceHandle;
        fromEdge.targetHandle = edgeDetailInfo.from.targetHandle;
        toEdge.sourceHandle = edgeDetailInfo.to.sourceHandle;
        toEdge.targetHandle = edgeDetailInfo.to.targetHandle;

        //주석에서 풀면 엣지가 중복되어 생성됨. 원인파악 못함
        if (!edgeList.find((e) => e.id === fromEdge.id))
          edgeList.push(fromEdge);
        if (!edgeList.find((e) => e.id === toEdge.id)) {
          edgeList.push(toEdge);
        }
      });
    };

    /**
     * 이터레이터 자녀 추가하는 로직
     * @param {*} process
     */
    const addIteratorProcess = (process) => {
      if (
        StringUtils.equalsIgnoreCase(
          process.type,
          Enums.WorkflowNodeType.ITERATOR
        )
      ) {
        if (!ObjectUtils.isEmpty(process.child)) {
          for (const _childNode of process.child.process) {
            const childNode = {
              id: _childNode.compId,
              type: _childNode.type,
              position: _childNode.position,
              style: _childNode.style,
              parentNode: process.compId,
              extent: "parent",
              data: {
                process: _childNode,
                editorTheme,
              },
            };
            nodeList.push(childNode);
            if (
              StringUtils.equalsIgnoreCase(
                _childNode.type,
                Enums.WorkflowNodeType.ITERATOR
              )
            ) {
              addIteratorProcess(_childNode);
            }
          }
          renderConnector({
            connectorList: process.child.connector,
            iterator: process,
          });
        }
      }
    };

    const processList = bundleNodeList.filter(
      (bn) => bn.type !== Enums.WorkflowNodeType.CONNECTOR
    );
    const connectorList = bundleNodeList.filter(
      (bn) => bn.type === Enums.WorkflowNodeType.CONNECTOR
    );

    renderNode(processList);
    renderConnector({ connectorList });

    setEdges(edgeList);
    setNodes(nodeList);
  };

  /**
   * 모달 닫기
   */
  const onCloseModal = (e) => {
    stopEvent(e);
    setOpenBundleDetailPopup(false);
    initModalInfo();
  };

  const onNodeCustomChange = (nodeChange) => {
    onNodesChange(nodeChange);
    let changedEntities = [];
    if (!ArrayUtils.isEmpty(nodeChange)) {
      //사이즈(Dimensions) 값이 바뀐 것인지 확인
      //Dimensions 변경은 단일 대상으로만 이루어 지고, 동시에 포지션 변경도 일어날 수 있기 때문에 포지션과 처리방식이 다름
      const dimensionsChangedInfo = nodeChange.find(
        (nc) => nc.type === "dimensions"
      );
      if (dimensionsChangedInfo) {
        const positionChangedInfo = nodeChange.find(
          (nc) => nc.type === "position"
        );
        if (
          dimensionsChangedInfo?.updateStyle === true &&
          dimensionsChangedInfo?.resizing === true
        ) {
          //바뀐 포지션 값도 임시 저장
          nodeDimensionChangeRef.current = dimensionsChangedInfo;
          nodePositionChangeRef.current = positionChangedInfo;
        } else if (dimensionsChangedInfo?.resizing === false) {
          if (nodeDimensionChangeRef.current) {
            changedEntities = getChangedNode("dimensions");
            nodeDimensionChangeRef.current = null;
            nodePositionChangeRef.current = null;
          }
        }
      } else {
        if (nodeChange[0].dragging === true) {
          nodePositionChangeRef.current = nodeChange;
        } else if (nodeChange[0].dragging === false) {
          if (nodePositionChangeRef.current) {
            changedEntities = getChangedNode("position");
            // if (isLowerNodeMove) {
            //   const lowerNodeList = getLowerNodeChanged(
            //     changedEntities,
            //     workflow,
            //     nodes
            //   );
            //   changedEntities = [...changedEntities, ...lowerNodeList];
            // }

            nodePositionChangeRef.current = null;
          }
        }
      }
      if (changedEntities.length > 0) {
        WorkflowReduxHelper.updateNodes(dispatch, changedEntities, workflow);
      }
    }
  };

  /**
   * 노드 연결 이벤트
   */
  const onConnect = useCallback(
    (connection) => {
      // return getSourceEntity(connection.source, workflow.output);
      const { source, target } = connection;
      /**
       * 메세지 프로세스는 Source로서 사용되지 못한다.
       * Iterator의 반복문 제어는 Source가 될수 없다.
       */
      const sourceNode = nodes.find((n) => n.id === source);
      const targetNode = nodes.find((n) => n.id === target);
      if (
        StringUtils.equalsIgnoreCase(
          sourceNode.data.process?.processType,
          Enums.WorkflowProcessType.MESSAGE
        )
      ) {
        return Message.alert(
          "Message cannot be connected to other nodes.",
          Enums.MessageType.WARN
        );
      }
      if (
        StringUtils.equalsIgnoreCase(
          sourceNode.data.process?.processType,
          Enums.WorkflowProcessType.LOOP_CONTROL_KEYWORD
        )
      ) {
        return Message.alert(
          "Loop Control Node cannt be connected to other nodes",
          Enums.MessageType.WARN
        );
      }
      if (
        StringUtils.equalsIgnoreCase(
          sourceNode.type,
          Enums.WorkflowNodeType.CONNECTOR
        ) &&
        sourceNode.type === targetNode.type
      ) {
        return Message.alert(
          "Cannot connect between connectors",
          Enums.MessageType.WARN
        );
      }
      if (source === target) return false;
      const connectorNodes = nodes.filter(
        (c) => c.type === Enums.WorkflowNodeType.CONNECTOR
      );
      //이미 연결된 엣지인지 확인
      const isEdgeConnected = edges.find(
        (c) => c.source === source && c.target === target
      );
      //이미 연결된
      const isConnectorConnected = connectorNodes.find(
        (c) =>
          c.data.connector.processFrom === source &&
          c.data.connector.processTo === target
      );
      //커넥터로부터의 연결을 수정하는 경우
      const FromConnector = connectorNodes.find((c) => c.id === source);
      const ToConnector = connectorNodes.find((c) => c.id === target);
      //1. 커넥터가 연결된 경우
      if (
        (isEdgeConnected || isConnectorConnected) &&
        !(FromConnector || ToConnector)
      ) {
        let connector;
        if (isEdgeConnected) {
          connector = JsonUtils.findNode(
            workflow,
            "compId",
            isEdgeConnected.id
          );
        } else {
          connector = JsonUtils.findNode(
            workflow,
            "compId",
            isConnectorConnected.data.connector.compId
          );
        }
        const _newConnection = getConnectorPosition({
          sourceNode,
          targetNode,
          connection,
          connector,
        });
        connector = { ...connector, ..._newConnection };
        WorkflowReduxHelper.updateNodes(dispatch, [connector], workflow);
      } else if (FromConnector || ToConnector) {
        // 커넥터 노드는 가상 노드이기 때문에 nodes와 Edge에서 원천 노드를 찾아서 업데이트 한다.
        if (
          StringUtils.equalsIgnoreCase(
            sourceNode.type,
            Enums.WorkflowNodeType.CONNECTOR
          )
        ) {
          const conCompId = FromConnector.id;
          //수정할 커넥터 찾기
          const connector = connectorNodes.find((c) => c.id === source).data
            .connector;
          let newConnector = produce(connector, (draft) => {
            //edgeInfo 삭제 및 edgeDetailInfo 추가
            if (!draft.edgeDetailInfo) draft.edgeDetailInfo = {};
            draft.edgeDetailInfo.to = connection;
            // draft.edgeDetailInfo.from = connection;
            if (!draft.edgeDetailInfo.from)
              draft.edgeDetailInfo.from = {
                ...draft.edgeInfo,
                target: conCompId,
              };
            // delete draft.edgeInfo;
            if (FromConnector.data.connector.processTo !== target) {
              draft.processTo = target;
            }
            // draft.edgeDetailInfo = newEdgeDetailInfo;
          });

          WorkflowReduxHelper.updateNodes(dispatch, [newConnector], workflow);
        } else if (FromConnector) {
          const conCompId = FromConnector.id;
          //수정할 커넥터 찾기
          const connector = connectorNodes.find((c) => c.id === source).data
            .connector;
          let newConnector = produce(connector, (draft) => {
            //edgeInfo 삭제 및 edgeDetailInfo 추가
            if (!draft.edgeDetailInfo) draft.edgeDetailInfo = {};
            draft.edgeDetailInfo.to = connection;
            // draft.edgeDetailInfo.from = connection;
            if (!draft.edgeDetailInfo.from)
              draft.edgeDetailInfo.from = {
                ...draft.edgeInfo,
                target: conCompId,
              };
            delete draft.edgeInfo;
            if (FromConnector.data.connector.processTo !== target) {
              draft.processTo = target;
            }
            // draft.edgeDetailInfo = newEdgeDetailInfo;
          });

          WorkflowReduxHelper.updateNodes(dispatch, [newConnector], workflow);
        } else {
          // 임의의 프로세스로 부터 커넥터로의 연결을 수정하는 경우
          const conCompId = ToConnector.id;
          //실제 원천 Source : Connector에 연결된 원천 Source
          //수정할 커넥터 찾기
          const connector = connectorNodes.find((c) => c.id === target).data
            .connector;
          let newConnector = produce(connector, (draft) => {
            if (!draft.edgeDetailInfo) draft.edgeDetailInfo = {};
            draft.edgeDetailInfo.from = connection;
            if (!draft.edgeDetailInfo.to)
              draft.edgeDetailInfo.to = {
                ...draft.edgeInfo,
                target: conCompId,
              };

            //edgeInfo 삭제 및 edgeDetailInfo 추가
            if (ToConnector.data.connector.processFrom !== source) {
              draft.processFrom = source;
            }
            delete draft.edgeInfo;
            // draft.edgeDetailInfo = newEdgeDetailInfo;
          });
          WorkflowReduxHelper.updateNodes(dispatch, [newConnector], workflow);
        }
      } else {
        //같은 이터레이터 안에서만 연결됨
        const fromNode = JsonUtils.findNode(nodes, "id", source);
        const toNode = JsonUtils.findNode(nodes, "id", target);
        if (fromNode.parentNode !== toNode.parentNode) {
          const fromParents = JsonUtils.findNode(
            nodes,
            "id",
            fromNode.parentNode
          );
          const toParents = JsonUtils.findNode(nodes, "id", toNode.parentNode);
          if (
            StringUtils.includes(Enums.WorkflowNodeType.ITERATOR, [
              fromParents.type,
              toParents.type,
            ])
          )
            return Message.alert(
              "Only same-level nodes can be connected.",
              Enums.MessageType.WARN
            );
        }

        let flags = "";
        //fromNode가 condition일 경우 해당 노드에서 나온 connector의 개수가 2개일 경우, 알림 -> return;
        if (fromNode.type === Enums.WorkflowNodeType.CONDITION) {
          const fromConditionNodes = connectorNodes.filter(
            (item) => item.data.connector.processFrom === fromNode.id
          );
          if (fromConditionNodes.length === 2) {
            return Message.alert(
              "Condition node can connect to a maximum of 2 connectors.",
              Enums.MessageType.WARN
            );
          } else if (fromConditionNodes.length === 1) {
            flags = fromConditionNodes[0].data.connector.propertyValue.filter
              ? false
              : true;
          }
        }

        let iterator = findParentIterator(source);

        //새로운 커넥터 추가
        const callbackFnc = (connectorInfo) => {
          Popup.close();

          const body = {
            processFrom: source,
            processTo: target,
            compId: StringUtils.getUuid(),
            type: Enums.WorkflowNodeType.CONNECTOR,
            edgeInfo: connection,
          };
          if (connectorInfo) {
            body.propertyValue = connectorInfo;
          }

          //커넥터가 추가된다면 해당 커넥터의 좌표를 계산
          const sourceComp = JsonUtils.findNode(
            workflow.output.service.child,
            "compId",
            source
          );
          const targetComp = JsonUtils.findNode(
            workflow.output.service.child,
            "compId",
            target
          );
          const { position, edgeDetailInfo } = getConnectorPosition({
            sourceNode: sourceComp,
            targetNode: targetComp,
            connection,
            connector: body,
          });
          body.position = position;
          body.edgeDetailInfo = edgeDetailInfo;
          delete body.edgeInfo;

          if (ObjectUtils.isEmpty(iterator)) {
            //일반 노드
            WorkflowReduxHelper.addConnector(dispatch, body, workflow);
          } else {
            //이터레이터 안의 노드
            WorkflowReduxHelper.addIteratorConnector(
              dispatch,
              body,
              iterator,
              workflow
            );
          }
        };
        const options = {
          effect: Popup.ScaleUp,
          style: {
            content: {
              width: Connector_popup_width.other,
            },
          },
        };

        //소스가 Validation 인지 확인
        const sourceNode = JsonUtils.findNode(
          workflow.output,
          "compId",
          source
        );
        const isFromValidation = StringUtils.equalsIgnoreCase(
          sourceNode.processType,
          "EntityValidation"
        );
        if (fromNode.type === Enums.WorkflowNodeType.CONDITION) {
          if (flags === "") {
            //fromNode가 condition(IF 문) 일 경우 true/false 팝업
            Popup.open(
              <ConnectorConditionPopup
                callbackFnc={callbackFnc}
                connection={connection}
                workflow={workflow.output}
                iterator={iterator}
              />,
              {
                effect: Popup.ScaleUp,
                style: {
                  content: {
                    width: Connector_popup_width.EntityValidation,
                  },
                },
              }
            );
          } else {
            const body = {
              processFrom: source,
              processTo: target,
              compId: StringUtils.getUuid(),
              type: Enums.WorkflowNodeType.CONNECTOR,
              edgeInfo: connection,
              propertyValue: {
                filter: flags,
                connectorNm: `${flags ? "TRUE" : "FALSE"}`,
              },
            };
            //커넥터가 추가된다면 해당 커넥터의 좌표를 계산
            const sourceComp = JsonUtils.findNode(
              workflow.output.service.child,
              "compId",
              source
            );
            const targetComp = JsonUtils.findNode(
              workflow.output.service.child,
              "compId",
              target
            );
            const { position, edgeDetailInfo } = getConnectorPosition({
              sourceNode: sourceComp,
              targetNode: targetComp,
              connection,
              connector: body,
            });
            body.position = position;
            body.edgeDetailInfo = edgeDetailInfo;
            delete body.edgeInfo;

            if (ObjectUtils.isEmpty(iterator)) {
              //일반 노드
              WorkflowReduxHelper.addConnector(dispatch, body, workflow);
            } else {
              //이터레이터 안의 노드
              WorkflowReduxHelper.addIteratorConnector(
                dispatch,
                body,
                iterator,
                workflow
              );
            }
          }
        } else if (isFromValidation) {
          Popup.open(
            <ConnectorValidationPopup
              callbackFnc={callbackFnc}
              connection={connection}
              workflow={workflow.output}
              iterator={iterator}
            />,
            {
              effect: Popup.ScaleUp,
              style: {
                content: {
                  width: Connector_popup_width.EntityValidation,
                },
              },
            }
          );
        } else {
          Popup.open(
            <ConnectorPopup
              callbackFnc={callbackFnc}
              connection={connection}
              sourceCompId={source}
              targetCompId={target}
              workflow={workflow.output}
              iterator={iterator}
              nodes={nodes}
              edges={edges}
              compId={source}
              connector={true}
            />,
            options
          );
        }
      }
      return false;
    },
    [setEdges, workflow.output, nodes, edges]
  );

  /**
   * 바뀐 노드 목록 리턴
   * onChangeNode에서 사용
   * @param {String} key
   * @returns {Array}
   */
  const getChangedNode = (key) => {
    let changedEntities = [];
    if (StringUtils.equalsIgnoreCase(key, "dimensions")) {
      //포지션과 스타일 둘다 적용
      let changedContents = {};
      const { id } = nodeDimensionChangeRef.current;
      changedContents = JsonUtils.findNode(workflow, "compId", id);

      changedContents = produce(changedContents, (draft) => {
        JsonUtils.overrideNode(
          draft,
          "compId",
          id,
          "style",
          nodeDimensionChangeRef.current.dimensions
        );

        //노드 사이즈 변경하면서 포지션 변경이 있는 경우 바뀐 포지션 적용
        const changedPosition = nodePositionChangeRef.current;
        if (changedPosition?.position) {
          JsonUtils.overrideNode(
            draft,
            "compId",
            id,
            "position",
            changedPosition.position
          );
        }
      });
      changedEntities.push(changedContents);
      return changedEntities;
    } else {
      //포지션만 적용
      changedEntities = nodePositionChangeRef.current.map((_nodeInfo) => {
        let changedContents = {};
        const { id } = _nodeInfo;
        changedContents = JsonUtils.findNode(workflow, "compId", id);
        changedContents = produce(changedContents, (draft) => {
          if (StringUtils.equalsIgnoreCase(key, "dimensions")) {
            JsonUtils.overrideNode(
              draft,
              "compId",
              id,
              "style",
              _nodeInfo[key]
            );
          } else {
            JsonUtils.overrideNode(draft, "compId", id, key, _nodeInfo[key]);
          }
        });
        return changedContents;
      });
    }

    return changedEntities;
  };

  /**
   * 연결하는 노드가 Iterator 안의 노드인지 확인
   * @param {*} compId
   * @returns
   */
  const findParentIterator = (compId) => {
    const nodeInfo = JsonUtils.findNode(nodes, "id", compId);
    if (nodeInfo.parentNode) {
      return JsonUtils.findNode(nodes, "compId", nodeInfo.parentNode);
    } else {
      return null;
    }
  };
  /**
   * Flow Panel 위에서 드래그 되었을때 이벤트
   */
  const onDragOver = useCallback((event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  }, []);

  /**
   * 드랍 이벤트
   */
  const onDrop = useCallback(
    async (event) => {
      event.preventDefault();
      const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
      const position = reactFlowInstance.project({
        x: event.clientX - reactFlowBounds.left,
        y: event.clientY - reactFlowBounds.top,
      });
      const _NodeData = event.dataTransfer.getData("application/reactflow");
      // check if the dropped element is valid
      if (!_NodeData) return false; //데이터가 없는 경우
      const NodeData = JSON.parse(_NodeData);
      const { type } = NodeData;
      if (StringUtils.equalsIgnoreCase(Enums.WorkflowNodeType.PROCESS, type)) {
        onDropProcess(position, { nodes, edges, bundle });
      } else if (
        StringUtils.equalsIgnoreCase(Enums.WorkflowNodeType.SERVICE, type)
      ) {
        onDropService(position, { nodes, edges, bundle });
      } else if (
        StringUtils.equalsIgnoreCase(Enums.WorkflowNodeType.ITERATOR, type)
      ) {
        onDropIterator(position, { nodes, edges, bundle });
      } else if (
        StringUtils.equalsIgnoreCase(Enums.WorkflowNodeType.CONDITION, type)
      ) {
        onDropCondition(position, { nodes, edges, bundle });
      } else if (
        StringUtils.equalsIgnoreCase(Enums.WorkflowNodeType.CODE, type)
      ) {
        onDropCode(position, { nodes, edges, bundle });
      } else if (
        StringUtils.equalsIgnoreCase(Enums.WorkflowNodeType.MEMO, type)
      ) {
        Message.alert(
          "Messages for individual Group are under development.",
          Enums.MessageType.INFO
        );
        // 그룹 내에 별도의 메모를 넣지 않음
        // onDropMemo(position);
      }
    },
    [reactFlowInstance, workflow, nodes, edges]
  );

  const onMaxModal = (e) => {
    setBundleModalExpand(!bundleModalExpand);
  };

  const modalStyle = bundleModalExpand
    ? {
        width: "calc(100% - 300px)",
        height: "800px",
        position: "absolute",
        top: "100px",
        right: openBundleDetailPopup
          ? StringUtils.isEmpty(debugExpressionMode)
            ? "30px"
            : "460px"
          : "-100%",
      }
    : {
        width: "700px",
        height: "550px",
        position: "absolute",
        top: "100px",
        right: openBundleDetailPopup
          ? StringUtils.isEmpty(debugExpressionMode)
            ? "150px"
            : "460px"
          : "-100%",
      };

  //번들 정보 수정
  const onEditBundleInfo = (e) => {
    stopEvent(e);
    setBundlingMode(true);
    setSelectedBundle(bundle);
    const bundleNodeList = {};
    bundle.propertyValue.nodeList.forEach((node) => {
      bundleNodeList[node.bundleCompId] = true;
    });
    setBundleNodeList(bundleNodeList);
  };

  return (
    <div
      className="reactflow-wrapper workflow"
      ref={reactFlowWrapper}
      style={modalStyle}
    >
      <div
        className="bundle-detail-popup"
        style={{ border: `1px solid ${bundleColor}` }}
      >
        <div
          className="popup-header"
          style={{
            background: bundleColor,
            color: textColor,
            display: "flex",
            justifyContent: "space-between",
            height: "30px",
            padding: "0 15px",
            alignItems: "center",
          }}
        >
          <div>{bundle?.propertyValue?.groupNm} Group</div>
          <div style={{ display: "flex" }}>
            <Button
              size="sm"
              variant="link"
              onClick={onEditBundleInfo}
              style={{ color: textColor }}
            >
              <MdEdit size={20} />
            </Button>
            <Button
              size="sm"
              variant="link"
              onClick={onMaxModal}
              style={{ color: textColor }}
            >
              {bundleModalExpand ? (
                <IoMdContract size={20} />
              ) : (
                <FaExpand size={20} />
              )}
            </Button>
            <Button
              size="sm"
              variant="link"
              onClick={onCloseModal}
              style={{ color: textColor }}
            >
              <MdClose size={20} />
            </Button>
          </div>
        </div>
        <div
          style={{
            height: bundleModalExpand ? "750px" : "500px",
            position: "relative",
          }}
        >
          <ReactFlow
            nodes={nodes}
            nodeTypes={nodeTypes}
            edges={edges}
            maxZoom={5}
            minZoom={0.2}
            onNodesChange={onNodeCustomChange}
            onInit={setReactFlowInstance}
            zoomOnDoubleClick
            snapToGrid
            onConnect={onConnect}
            edgeTypes={edgeTypes}
            multiSelectionKeyCode={"Control"}
            connectionMode={ConnectionMode.Loose}
            onDragOver={onDragOver}
            onDrop={onDrop}
          >
            <Background
              style={{
                background: editorTheme === "light" ? "white" : "#282828",
              }}
            />
            {/* <MiniMap
              zoomable
              pannable
              style={{
                outline: "1px solid gray",
                borderRadius: "5px",
                boxShadow: "1px 1px 3px black",
                top: `calc(100% - 200px)`,
                left: -12,
              }}
              nodeColor={"#3c3c3c"}
              onNodeClick={onNodeClick}
              maskColor={"#D3D3D3cc"}
              position={"top-left"}
            /> */}
          </ReactFlow>
        </div>
      </div>
    </div>
  );
}

export default BundleDetailBuilder;
