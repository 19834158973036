import ActionType from "components/builder/BuilderActionType";
import produce from "immer";

const initialState = {
  design: {},
  designLayoutColumn: [],
  multilang: [],
  designRelatedTables: [],
};

export default function TechnicalDesignReducer(state = initialState, action) {
  switch (action.type) {
    case ActionType.PROGRAM_DESIGN.SET_DESIGN:
      return produce(state, (draft) => {
        draft.design = action.payload;
        draft.multilang = action.payload.multilang;
      });
    case ActionType.PROGRAM_DESIGN.SET_DESIGN_LAYOUT_COLUMN:
      return produce(state, (draft) => {
        draft.designLayoutColumn = action.payload;
      });
    case ActionType.PROGRAM_DESIGN.SET_DESIGN_RELATED_TABLES:
      return produce(state, (draft) => {
        draft.designRelatedTables = action.payload;
      });
    case ActionType.PROGRAM_DESIGN.SET_FILE:
      return produce(state, (draft) => {
        draft.designUploadFile = action.payload;
      });
    default:
      return state;
  }
}
