import { json } from "@codemirror/lang-json";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  ButtonGroup,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import CodeMirror from "@uiw/react-codemirror";
import * as Enums from "components/builder/BuilderEnum";
import {
  updateEventOutput,
  updateEventWorkspace,
} from "components/builder/eventhandler/reducer/EventHandlerAction";
import {
  updateInformation,
  updateOutput,
} from "components/builder/ui/reducers/UIBuilderAction";
import Message from "components/common/Message";
import CommonUtils, { StringUtils } from "components/common/utils/CommonUtils";
import User from "components/common/utils/UserUtils";
import "moment/locale/ko";
import { useEffect, useState } from "react";
import { HiOutlineClipboardCopy } from "react-icons/hi";
import { MdExpandMore } from "react-icons/md";
import { RiFileCopyLine } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ProgramService from "services/ui/ProgramService";
import styled from "styled-components";

const AccordionHeader = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 50px;
  gap: 5px;
`;
const AccordionInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
`;
const AccordionContentsWrapper = styled.div`
  width: 100%;
  max-height: 600px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 5px;
`;
const EditorViewer = styled.div`
  max-height: 500px;
  width: 73vw;
  overflow-y: auto;
`;
const ControllButton = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

/**
 * 프로그램 이력 상세에서 각 컨텐츠 아코디언
 * 2024.01.02 : kiyoung.park 확장 여부로 각 컨텐츠 불러오는 방식으로 교체
 *          사유 : 이력 목록 호출시 데이터 과부하로 느려짐
 * @param {*} param0
 * @returns
 */
function ProgramHistoryAccordionContents({ program, onCompareHistory }) {
  const {
    commitComment,
    insertDt,
    templateShareYn,
    updtUserId,
    // programContent,
    programHistoryId,
    appApplyType,
  } = program;
  const { tenantMstId } = useSelector((state) => state.workspace);
  const { isMobileEditor } = useSelector((state) => state.mobile);
  const [isExpanded, setIsExpanded] = useState(false);
  const [programContent, setProgramContent] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (StringUtils.isEmpty(programContent) && isExpanded) getProgramDetail();
  }, [isExpanded]);

  const getProgramDetail = () => {
    ProgramService.getProgramHistoryDetail({ programHistoryId }, (res) => {
      setProgramContent(res.data.programContent);
    });
  };

  /**
   * 열기 === 해당 파일 불러오기
   */
  const onCopyCodeToClipBoard = () => {
    Message.confirm(
      "Would you like to fetch the specified commit? If there is a project in progress, it will be removed.",
      () => {
        let _new = { ...program };
        if (programContent !== "")
          _new.programContent = JSON.parse(programContent);
        dispatch(updateEventWorkspace({}));
        dispatch(updateEventOutput(""));
        dispatch(updateOutput(_new.programContent));
        dispatch(updateInformation(_new));
        if (isMobileEditor) {
          navigate(
            Enums.BuilderPath.MOBILE.MAIN +
              "/" +
              Enums.BuilderPath.MOBILE.EDITOR
          );
        } else {
          navigate(
            Enums.BuilderPath.UI.MAIN + "/" + Enums.BuilderPath.UI.EDITOR
          );
        }
      }
    );
  };

  /**
   * 복원 ==> 해당 파일을 최신버전으로 저장
   */
  const onCopyCodeToCurrentEditor = async () => {
    Message.confirm(
      "Would you like to overwrite the program with this version?",
      () => {
        ProgramService.recoverProgram({ ...program }, (res) => {
          const body = {
            ...res.data,
            ...program,
            programId: program.programId,
            programContent,
            programType:
              JSON.parse(programContent).page.propertyValue.programType || "M",
            appApplyType: "N",
            useYn: "Y",
            committer: User.getMastRecId(),
            commitComment: `${CommonUtils.getDate(
              program.insertDt,
              "dateTime"
            )} Overwrite`,
            tenantMstId,
          };
          ProgramService.saveProgram(body, () => {
            Message.alert("Restored Successfully.", Enums.MessageType.SUCCESS);
          });
        });
      }
    );
  };

  return (
    <Accordion
      onChange={(e, expanded) => {
        setIsExpanded(expanded);
      }}
    >
      <AccordionSummary
        expandIcon={<MdExpandMore />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <AccordionHeader>
          <span>{commitComment}</span>
          <AccordionInfo>
            <div>
              {updtUserId} / {CommonUtils.getTZTime(insertDt).fromNow()}
            </div>
            <div>
              <ButtonGroup
                variant="outlined"
                aria-label="outlined button group"
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={templateShareYn === "Y" ? true : false}
                      onClick={(e) => e.stopPropagation()}
                    />
                  }
                  label="Share Template"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={appApplyType === "Y" ? true : false}
                      onClick={(e) => e.stopPropagation()}
                    />
                  }
                  label="Apply in App"
                />
              </ButtonGroup>
            </div>
          </AccordionInfo>
        </AccordionHeader>
      </AccordionSummary>
      <AccordionDetails>
        <AccordionContentsWrapper>
          <ControllButton>
            <div>Save Date : {CommonUtils.getDate(insertDt, "dateTime")}</div>
            <ButtonGroup>
              <Button
                onClick={onCopyCodeToClipBoard}
                style={{ fontSize: "12px" }}
              >
                <RiFileCopyLine fontSize={"15px"} /> Open
              </Button>
              <Button
                onClick={onCopyCodeToCurrentEditor}
                style={{ fontSize: "12px" }}
              >
                <HiOutlineClipboardCopy fontSize={"15px"} /> Overwrite
              </Button>
            </ButtonGroup>
          </ControllButton>
          <EditorViewer>
            {!StringUtils.isEmpty(programContent) && (
              <CodeMirror
                value={JSON.stringify(JSON.parse(programContent), null, 2)}
                className="source-container"
                height="500px"
                extensions={[json(true)]}
                autoFocus={false}
                editable={false}
              />
            )}
          </EditorViewer>
        </AccordionContentsWrapper>
      </AccordionDetails>
    </Accordion>
  );
}

export default ProgramHistoryAccordionContents;
