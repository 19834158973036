import MuiConfig from "components/common/config/MuiConfig";
import WijmoGrid from "components/common/element/WijmoGrid";
import Modal from "components/common/modal/UModal";
import UmodalTemplate from "components/common/modal/UModalTemplate";
import CommonUtils from "components/common/utils/CommonUtils";

const UnremovableEntityProgramListPopup = ({ programList }) => {
  const columns = [
    { field: "programId", headerName: "Program ID", width: 150 },
    {
      field: "tenantId",
      headerName: "Tenant ID",
      width: 130,
    },
    {
      field: "coCd",
      headerName: "Company Code",
      width: 130,
    },
    {
      field: "programNm",
      headerName: "Program Name",
      width: "*",
    },
    {
      field: "updtUserId",
      headerName: "Update User",
      width: 125,
    },
    {
      field: "updtDt",
      headerName: "Update Date",
      width: 125,
      renderCell: (params) => `${CommonUtils.getDate(params.updtDt)}`,
    },
  ];

  return (
    <Modal>
      <Modal.Header title={"Active Program List"} />
      <Modal.Body>
        <UmodalTemplate>
          <div style={{ height: 310 }}>
            <WijmoGrid
              columns={columns}
              getRowId={(row) => row.programId}
              rows={programList}
              {...MuiConfig.grid.options}
              pageSize={20}
              rowsPerPageOptions={[20]}
              style={{ height: `calc(30vh + 20px)` }}
            />
          </div>
          <span className="blink warning-text">
            You cannot delete this program because it is currently in use.
            Please modify or delete the associated model in the active program
            and try again.
          </span>
        </UmodalTemplate>
      </Modal.Body>
      <Modal.Footer />
    </Modal>
  );
};

export default UnremovableEntityProgramListPopup;
