import LayoutComponent from "components/builder/ui/uiComponents/layout/LayoutComponent";
import {
  PropertyLable,
  PropertyValue,
} from "components/builder/ui/uiComponents/UIComponentStyle";
import { Accordion } from "react-bootstrap";
import React from "react";

import { StringUtils, ObjectUtils } from "components/common/utils/CommonUtils";
import UIComponentSection from "components/builder/ui/editor/UIComponentSection";
import USelectbox from "components/common/element/USelectbox";
class Column extends LayoutComponent {
  constructor(props) {
    super(props);
    //불필요하면 constructor 지우세요.
  }

  /**
   * style - width 변경시
   * - column은  flex-basis: 0; flex-grow: 1;이기 때문에 width가 적용되지 않음
   * 따라서 flex-grow를 0으로 flex-basis를 입력한 width로 적용 ==> flex : "0 0 88px"
   * @param {Event} event
   */
  onChangeCompWidth = (event) => {
    const newStyle = {
      ...this.state.propertyValue.style,
      [event.target.id]: event.target.value,
    };

    if (!StringUtils.isEmpty(event.target.value)) {
      newStyle.display = "flex";
      newStyle.flex = "0 0 " + event.target.value;
    } else {
      delete newStyle["display"];
      delete newStyle["flex"];
      delete newStyle["width"];
    }
    this.onChangePropertyValue("style", newStyle);
  };

  onChangeWidth = (e) => {
    this.onChange(e);
    /*
    let textList = e.target.innerText.split("\n");
    document.getElementById("width").value = textList[e.target.value];
    this.onChangePropertyStyle("width", textList[e.target.value]);
    */
  };

  /**
   * 정렬 변경시
   * - Column의 경우 align을 적용 시키기 위해서는 display를 flex로 변경해야된다.
   * @param {Event} event
   */
  onChangeAlignment = (event) => {
    const newStyle = {
      ...this.state.propertyValue.style,
      [event.target.id]: event.target.value,
      textAlign: event.target.value,
    };
    if (event.target.value === "unset") {
      delete newStyle["display"];
      delete newStyle["justifyContent"];
      delete newStyle["textAlign"];
    } else {
      newStyle["display"] = "flex";
    }

    this.onChangePropertyValue("style", newStyle);
  };

  /**
   * Properties tab panel을 Redering
   * @returns
   */
  renderPropertiesPanel = () => {
    return (
      <React.Fragment>
        {/* Title */}
        {this.renderComponentTitle("Column")}
        <Accordion defaultActiveKey={[0, 1]} alwaysOpen>
          <Accordion.Item eventKey={0}>
            <Accordion.Header>Basic Info</Accordion.Header>
            <Accordion.Body>
              <PropertyLable>ID</PropertyLable>
              <PropertyValue>
                <input
                  type="text"
                  id="id"
                  defaultValue={StringUtils.defaultString(
                    this.state.propertyValue.id
                  )}
                  className="form-control form-control-sm"
                  onBlur={this.onChange}
                />
              </PropertyValue>
              <PropertyLable>Width</PropertyLable>
              <PropertyValue>
                Over 1200px (Desktop or laptops)
                <USelectbox
                  type="common"
                  mstCd="Z0015"
                  id="widthLaptop"
                  defaultValue={StringUtils.defaultString(
                    this.state.propertyValue.widthLaptop
                  )}
                  onChange={this.onChangeWidth}
                />
              </PropertyValue>
              <PropertyLable />
              <PropertyValue>
                Over 992px (Small laptops)
                <USelectbox
                  type="common"
                  mstCd="Z0015"
                  id="widthSmall"
                  defaultValue={StringUtils.defaultString(
                    this.state.propertyValue.widthSmall
                  )}
                  onChange={this.onChange}
                />
              </PropertyValue>
              <PropertyLable />
              <PropertyValue>
                Over 768px (Tablets)
                <USelectbox
                  type="common"
                  mstCd="Z0015"
                  id="widthTablet"
                  defaultValue={StringUtils.defaultString(
                    this.state.propertyValue.widthTablet
                  )}
                  onChange={this.onChange}
                />
              </PropertyValue>
              <PropertyLable />
              <PropertyValue>
                Below 768px (Phones)
                <USelectbox
                  type="common"
                  mstCd="Z0015"
                  id="widthPhone"
                  defaultValue={StringUtils.defaultString(
                    this.state.propertyValue.widthPhone
                  )}
                  onChange={this.onChange}
                />
              </PropertyValue>
            </Accordion.Body>
          </Accordion.Item>

          {/* Click Event property */}
          {this.renderClickEvent("Column", 1)}

          {/* Style property */}
          {this.renderStylePanel("Column", 2)}
        </Accordion>
      </React.Fragment>
    );
  };

  /**
   * Editor의 component를 Redering
   * << Layout editor props>>
   *   - compId - 현재 component의 고유 ID
   *   - componentInfo - drag & drop시 생성된 component object
   *   - style - dragging style이 포함된 style (사용자가 정의한 style은 각 component에서 적절히 적용해야함)
   *   - event="renderEditor" - 요청 구분
   * @returns
   */
  renderEditor = () => {
    /**
     * UIComponentSection props
     * -item
     * -style
     * -className
     */
    return (
      <UIComponentSection
        item={this.props.componentInfo}
        style={this.props.style}
        className={`editor-base draggable editor-column ${StringUtils.defaultString(
          this.state.editorAttr.className,
          "builder-column"
        )} ${StringUtils.defaultString(this.state.propertyValue.className)} ${
          this.props.isLast === true ? "isLast" : ""
        }`}
      >
        {this.props.children}
      </UIComponentSection>
    );
  };
}
export default Column;
