import { Checkbox, FormControlLabel, Tooltip } from "@mui/material";
import * as Enums from "components/builder/BuilderEnum";
import Message from "components/common/Message";
import Modal from "components/common/modal/UModal";
import JsonUtils from "components/common/utils/JsonUtils";
import ObjectUtils from "components/common/utils/ObjectUtils";
import StringUtils from "components/common/utils/StringUtils";
import User from "components/common/utils/UserUtils";
import produce from "immer";
import { useState } from "react";
import { Form } from "react-bootstrap";
import withProgramSavePopup from "./withProgramSavePopup";

function DeployPopup(props) {
  const {
    title,
    information,
    output,
    saveAs,
    updatePageByProps,
    callbackDeployFnc,
    workspace,
    programId,
    programNm,
    description,
    renderSavePath,
    renderCommonInput,
  } = props;

  const [commitComment, setCommitComment] = useState("");
  const [releaseCommentYn, setReleaseCommentYn] = useState("N");
  const [deployWithDmYn, setDeployWithDmYn] = useState("N");

  const [isDeploying, setIsDeploying] = useState(false);

  const inputValueValidation = () => {
    if (StringUtils.isEmpty(programId)) {
      Message.alert("Please set Program ID.", Enums.MessageType.WARN);
      return false;
    }
    if (StringUtils.isEmpty(programNm)) {
      Message.alert("Please set Program Name.", Enums.MessageType.WARN);
      return false;
    }
    if (StringUtils.isEmpty(commitComment)) {
      Message.alert("Please enter save comment.", Enums.MessageType.WARN);
      return false;
    }
    //기존에 저장된 프로그램을 다름이름으로 저장할때 같은 아이디로 저장 안되도록 방지
    if (saveAs && information.programId === programId) {
      Message.alert(
        "ID Duplicates with exists program.",
        Enums.MessageType.WARN
      );
      return false;
    }

    return {
      appId: workspace.appId,
      moduleCd: workspace.moduleCd,
      appReleaseId: workspace.appReleaseId,
      tenantId: workspace.tenantId,
      coCd: workspace.coCd,
      programId,
      programNm,
      description,
      releaseCommentYn,
      deployWithDmYn,
      templateSaveYn: "Y",
      templateShareYn: information?.templateShareYn || "N",
      appApplyType: "N",
      useYn: "Y",
      committer: User.getMastRecId(),
      commitComment,
    };
  };

  const setUpdateProperties = () => {
    return {
      programId,
      programDesc: description,
      programName: programNm,
    };
  };

  // combobox load
  const deployData = (event) => {
    event.preventDefault();
    const inputValue = inputValueValidation();
    if (inputValue) {
      Message.confirm(
        "Would you like to deploy to the selected server?",
        () => {
          SaveTransaction(setIsDeploying, callbackDeployFnc);
        }
      );
    }
  };

  const SaveTransaction = (setIsLoading, callback) => {
    updateProperty({
      ...output.page.propertyValue,
      ...setUpdateProperties(),
    });
    setIsLoading(true);
    setTimeout(() => {
      callback.call(this, inputValueValidation(), () => setIsLoading(false));
    }, 1000);
  };

  const updateProperty = (propertyValue) => {
    const compId = output.page.compId;
    const changedOutput = produce(output, (draft) => {
      const targetNode = JsonUtils.overrideNode(
        draft,
        "compId",
        compId,
        "propertyValue",
        propertyValue
      );
      if (ObjectUtils.isEmpty(targetNode)) {
        console.log("Could not find target node !!!!");
        return false;
      }
    });
    if (updatePageByProps) {
      updatePageByProps(changedOutput, propertyValue);
    }
  };

  const onChangeReleaseCommentYn = (e) => {
    let value = e.target.checked;
    if (value) {
      setReleaseCommentYn("Y");
    } else {
      setReleaseCommentYn("N");
    }
  };
  const onChangeDeployWithDmYn = (e) => {
    let value = e.target.checked;
    if (value) {
      setDeployWithDmYn("Y");
    } else {
      setDeployWithDmYn("N");
    }
  };

  return (
    <Modal>
      {/*
       * Modal.Header props
       *   - title
       */}
      <Modal.Header title={title} />
      <Modal.Body>
        <Form>
          {renderSavePath()}
          {renderCommonInput()}
          <Form.Group className="mb-3">
            <Form.Label className="required">Program deploy comment</Form.Label>
            <input
              type="text"
              id="programComment"
              name="programComment"
              className="form-control"
              max={120}
              placeholder={"Up to 120 characters"}
              value={commitComment}
              onChange={(e) => setCommitComment(e.target.value)}
            />
          </Form.Group>
          <Form.Group
            className="mb-3"
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Tooltip
              title="By deploying the Data Model separately and excluding it during the program deployment, you can reduce the time required for deployment."
              placement="left"
            >
              <FormControlLabel
                label={"Deploy including Data Model"}
                control={
                  <Checkbox
                    checked={deployWithDmYn === "Y"}
                    onChange={onChangeDeployWithDmYn}
                  />
                }
              />
            </Tooltip>
            <Tooltip
              title="Select if you want to include changes from the previous version."
              placement="left"
            >
              <FormControlLabel
                label={"Include deployment comments in the revision"}
                control={
                  <Checkbox
                    checked={releaseCommentYn === "Y"}
                    onChange={onChangeReleaseCommentYn}
                  />
                }
              />
            </Tooltip>
          </Form.Group>
        </Form>
      </Modal.Body>

      {/*
       * Modal.Footer props
       *   - invisibleClose : Close 버튼을 보이지 않게 (default empty or false)
       *   - closeText : close 버튼명  (default "Close") -->  닫기,Close,취소,Cancel 을 상황에 따라 넣을수 있음.
       *   - ex closeText="취소"
       *
       * Popup 수동 Close
       * import Popup from "components/common/Popup";
       *   - Popup.close();
       *
       */}
      <Modal.Footer
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Modal.Footer.ProgressButton
          onClick={deployData}
          variant="success"
          doing={isDeploying}
          doingText={"Deploying..."}
        >
          Deploy to Server
        </Modal.Footer.ProgressButton>
      </Modal.Footer>
    </Modal>
  );
}

export default withProgramSavePopup(DeployPopup);
