import { Grid, TextField } from "@mui/material";
import { Enums, MessageType } from "components/builder/BuilderEnum";
import Message from "components/common/Message";
import Popup from "components/common/Popup";
import WijmoGrid from "components/common/element/WijmoGrid";
import CommonUtils from "components/common/utils/CommonUtils";
import {
  AdminSettingButton,
  AdminSettingContents,
  AdminSettingSearchBar,
} from "components/setting/config/AdminSettingMain";
import DbPatchRegisterPopup from "page/popup/app/DbPatchRegisterPopup";
import { useEffect, useState } from "react";
import { useRef } from "react";
import { Button } from "react-bootstrap";
import { BiEditAlt } from "react-icons/bi";
import { RiDeleteBinLine } from "react-icons/ri";
import DbPatchService from "services/common/DbPatchService";
import styled from "styled-components";

const ComponentManageBox = styled(AdminSettingContents)`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
const SearchBar = styled(AdminSettingSearchBar)`
  display: flex;
  gap: 15px;
  max-height: 36px;
`;

function DbPatchInsert() {
  const [appVersionList, setAppVersionList] = useState([]);

  const [patchList, setPatchList] = useState([]);
  //      현재상태, set함수
  const [dbPatchNo, setDbPatchNo] = useState("");

  const [databaseType, setDatabaseType] = useState("");

  const initialList = useRef([]);

  useEffect(() => {
    getAppVersionList();
    getDbPatchList();
    return () => {
      setPatchList([]);
    };
  }, []);
  //useEffect에는 두 개의 파라미터가 필요
  //useEffect(실행할 함수 , deps) -> if deps가 없다면 컴포넌트가 처음 나타날 때에만 함수가 호출됨.
  //useEffect 안에서 사용되는 상태 props는 deps에 넣어주는게 맞음
  //deps가 변경될 경우 useEffect의 함수가 실행됨.

  const columns = [
    {
      field: "dbPatchNo",
      headerName: "Patch Number",
      headerAlign: "center",
      width: 130,
    },
    {
      field: "appVersion",
      headerName: "App version",
      headerAlign: "center",
      align: "center",
      width: 150,
    },
    {
      field: "databaseType",
      headerName: "DB Type",
      headerAlign: "center",
      align: "center",
      width: 150,
    },
    {
      field: "patchBeginDate",
      headerName: "Patch Begin Date",
      headerAlign: "center",
      align: "center",
      width: 200,
      renderCell: (params) => {
        return <>{CommonUtils.getDate(params.patchBeginDate)}</>;
      },
    },
    {
      field: "patchNotes",
      headerName: "Patch Note",
      headerAlign: "center",
      align: "left",
      width: "2*",
    },
    {
      field: "updtUserId",
      headerName: "Update User",
      headerAlign: "center",
      align: "left",
      width: 150,
    },
    {
      field: "updtDt",
      headerName: "Update Date",
      headerAlign: "center",
      align: "center",
      width: 200,
      renderCell: (params) => {
        return <>{CommonUtils.getDate(params.updtDt)}</>;
      },
      // valueGetter: (params) => CommonUtils.getDate(params.row.updtDt),
    },
    {
      field: "detail",
      headerName: "Details",
      headerAlign: "center",
      align: "center",
      width: 100,
      renderCell: (params) => {
        return (
          <Button
            size="sm"
            variant="outline-success"
            onClick={(e) => openPatchDetail(e, { params })}
          >
            <BiEditAlt />
          </Button>
        );
      },
    },
    {
      field: "remark",
      headerName: "delete",
      headerAlign: "center",
      align: "center",
      width: 100,
      renderCell: (param) => {
        return (
          <Button
            size="sm"
            variant="outline-danger"
            onClick={(e) => deleteDbPatch(e, param)}
          >
            <RiDeleteBinLine />
          </Button>
        );
      },
    },
  ];

  /**
   * 패치번호 검색
   * @param {*} e
   */
  const onChangePatchNo = (e) => {
    setDbPatchNo(e.currentTarget.value);
    const value = e.currentTarget.value;
    if (value) {
      let newDbPatchList = [...initialList.current];
      newDbPatchList = newDbPatchList.filter(
        (a) => a.dbPatchNo.toString() === value
      );
      setPatchList(newDbPatchList);
    } else {
      setPatchList([...initialList.current]);
    }
  };

  const deleteDbPatch = (e, dbPatch) => {
    Message.confirm(`Are you sure to delete ${dbPatch.dbPatchNo} ?`, () => {
      DbPatchService.deleteDbPatch(dbPatch, (res) => {
        if (res.data === true) {
          Message.alert(`Delete Successfully.`, Enums.MessageType.SUCCESS);
        } else {
          Message.alert(`Delete Failure.`, Enums.MessageType.ERROR);
        }
        getDbPatchList();
      });
    });
  };

  const getDbPatchList = (e) => {
    if (e) e.preventDefault();
    const body = {
      dbPatchNo,
    };

    //배열 비구조화 할당
    DbPatchService.getDBPatchList({ body }, (res) => {
      //getDBPatchList를 통해 가져와진 결과값 res
      setPatchList(res.data); //setPatchList res.data의 값으로 위 useState 선언부의 patchList를 변경해줌.
      initialList.current = res.data;
    });
  };

  const getAppVersionList = (e) => {
    DbPatchService.getAppVersionList({}, (res) => {
      setAppVersionList(res.data);
    });
  };

  const openPatchDetail = (e, rowData) => {
    if (e) e.preventDefault();

    const options = {
      effect: Popup.ScaleUp, //Effect.SlideFromTop(default)를 Effect.ScaleUp 로 변경
      style: {
        content: {
          width: "600px",
        },
      },
    };
    const saveAfter = (body) => {
      getDbPatchList();
    };

    Popup.open(
      <DbPatchRegisterPopup
        dbPatch={rowData}
        appVersionList={appVersionList}
        saveCallback={saveAfter}
      />,
      options
    );
  };

  return (
    <ComponentManageBox>
      <SearchBar>
        <Grid container spacing={2}>
          <Grid item xs={1}>
            <TextField
              fullWidth
              size="small"
              label="Patch Number"
              value={dbPatchNo}
              onChange={onChangePatchNo}
            />
          </Grid>
          <Grid item xs={1}>
            <Button>Search</Button>
          </Grid>
        </Grid>
      </SearchBar>
      <WijmoGrid
        columns={columns}
        rows={patchList}
        style={{ height: "60vh" }}
      />
      <AdminSettingButton>
        <Button variant="success" onClick={openPatchDetail}>
          Add new patch
        </Button>
      </AdminSettingButton>
    </ComponentManageBox>
  );
}

export default DbPatchInsert;
