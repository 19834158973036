/* eslint-disable jsx-a11y/alt-text */
import React, { Fragment } from "react";

import Modal from "components/common/modal/UModal";

import UmodalTemplate from "components/common/modal/UModalTemplate";

const ComboEntityValueSettingGuide = (props) => {
  return (
    <Modal>
      <Modal.Header title={props.title} />
      <Modal.Body>
        <UmodalTemplate>
          <Fragment>
            <h4 id="if--else">Combo(Auto Complete) Search Value</h4>
            <p>
              If the input field is empty, the value entered by the user in the
              Combo will be applied as a search condition.
            </p>
            <h4 id="if--else">Specify Fixed Value</h4>
            <p>
              If you enter a fixed string/number in the 'value' input field, it
              will be applied to the Combo search condition.
            </p>
            <h4 id="html">Apply the entered value of the Element</h4>
            <p>
              The following is an example of applying an element's input value
              to a Combo search condition.<br></br>
              <font color="red">※ Caution</font> : Since the Combo is generated
              before other elements, the value of other elements may be empty
              during the initial Combo Data loading. Therefore, apply this
              feature only to <font color="red">Autocomplete Combos </font> that
              do not retrieve Combo Data after the initial page load.
            </p>
            <div>
              <pre>
                <code>
                  SearchForm.$("#roleId") /*. Apply the value of the roleId
                  element within the SearchForm. */
                </code>
                <code>
                  page.$("#roleId") /* Apply the value of the roleId element
                  within the Page. */
                </code>
              </pre>
            </div>
            <h4 id="html">
              Apply specific Grid Column value (Grid AutoComplete Combo)
            </h4>
            <p>
              An example of applying a specific Grid Column value to the Combo
              search condition is as follows.<br></br>
              <font color="red">※ Caution</font> : This function is only
              applicable to <font color="red">autocomplete combos </font> that
              do not query Combo Data after the initial loading of the screen
              (Grid).
            </p>
            <div>
              <pre>
                <code>
                  /* Apply Grid column "usrRoleNm" Value. */<br></br> &#123;
                  "colName" : "usrRoleNm"&#125;
                </code>
              </pre>
            </div>
            <h4 id="each">Dynamic value assignment in JavaScript</h4>
            <p>
              Applying a specific value to a Combo search condition using
              JavaScript" can be illustrated with the following example:
            </p>
            <div>
              <pre>
                <code>(new Date()).getDate(); /* Today's Date */</code>
                <code>
                  page.getParam("roleId") /* Apply the roleId value passed as a
                  parameter. */
                </code>
                <code>
                  function()&#123;<br></br>
                  {"    "}/* When the roleId input is ADMIN, the value is 'Y';
                  otherwise, it is 'N'.*/
                  <br></br>
                  {"    "}if(SearchForm.$("#roleId") === "ADMIN")&#123;
                  <br></br>
                  {"        "}return "Y"; <br></br>
                  {"    "}&#125;else&#123;
                  <br></br>
                  {"        "}return "N"; <br></br>
                  {"    "}&#125;<br></br>&#125;
                </code>
              </pre>
            </div>
            <h4 id="html">User Info Applying</h4>
            <p>
              The following user information is common data provided by the
              Framework, and each Application can further define user session
              information.
            </p>
            <div>
              <table className="doc-table" style={{ width: "100%" }}>
                <thead>
                  <tr>
                    <th style={{ width: "20%" }}>
                      <div>Value Name</div>
                    </th>
                    <th>
                      <div>Value Explanation &amp; Example</div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <div>variable.coCd</div>
                    </td>
                    <td>
                      <div>User Company Code</div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div>variable.userId</div>
                    </td>
                    <td>
                      <div>User ID</div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div>variable.userName</div>
                    </td>
                    <td>
                      <div>User Name</div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div>variable.language</div>
                    </td>
                    <td>
                      <div>User Language</div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Fragment>
        </UmodalTemplate>
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
};

export default ComboEntityValueSettingGuide;
