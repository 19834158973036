/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from "react";
import { BsPencilSquare } from "react-icons/bs";
import Popup from "components/common/Popup";
import Modal from "components/common/modal/UModal";
import styled from "styled-components";
import CodeMirror from "@uiw/react-codemirror";
import { javascript } from "@codemirror/lang-javascript";

import StringUtils from "components/common/utils/StringUtils";
import UmodalTemplate from "components/common/modal/UModalTemplate";
import { Col, Form, Row } from "react-bootstrap";
import UInputPopup from "components/common/element/UInputPopup";
import ProgramListPopup from "page/popup/ProgramListPopup";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import USelectbox from "components/common/element/USelectbox";
import UTextarea from "components/common/element/UTextarea";
import produce from "immer";
import UModalJavascriptEditor from "components/common/code/UModalJavascriptEditor";
import { useRef } from "react";

const MesFunPopupStyle = styled.div`
  .edit-source {
    border: 1px solid #ddd;
  }
`;

/**
 * StepDetailConfigPopup : step 상세 설정 popup
 *
 * 2022.04.28: init: donghoon
 * 2024.01.02 : Code Mirror 기능 사용 유지 위한 state -> Ref로 관리 하도록 수정
 * @param {*} props
 * @returns value
 */
const StepDetailConfigPopup = (props) => {
  const {
    modalTitle,
    callbackFnc,
    item,
    containerProps,
    workspace,
    onClickEventBuilder,
  } = props;

  const [popItems, setPopItems] = useState({});

  const [programId, setProgramId] = useState("");
  const [url, setUrl] = useState("");
  const [id, setId] = useState("");
  const [title, setTitle] = useState("");
  const [display, setDisplay] = useState("");
  const [isReload, setIsReload] = useState(false);
  // const [displayFnc, setDisplayFnc] = useState("");
  const displayFncRef = useRef("");
  const [description, setDescription] = useState("");
  const [buttons, setButtons] = useState("");
  const [addButtons, setAddButtons] = useState([]);

  const javascriptExt = [javascript(true)];
  const [extensions, setExtensions] = useState(javascriptExt);

  // closePopup: value값을 전달하고 popup 닫기
  const closePopup = (e) => {
    let retValue = { ...popItems };
    if (containerProps.stepWizardType === "S") {
      retValue.programId = programId;
      retValue.url = url;
    } else {
      delete retValue.programId;
      delete retValue.url;
    }
    retValue.id = id;
    retValue.title = title;
    retValue.isReload = isReload;
    retValue.display = display;
    retValue.displayFnc = displayFncRef.current;
    retValue.description = description;
    retValue.buttons = buttons;
    retValue.addButtons = addButtons;

    callbackFnc.call(this, retValue);
    Popup.close();
  };
  const onChangeProgramId = (e) => {
    setProgramId(e.target.value.toUpperCase());
  };
  const onChangeUrl = (e) => {
    setUrl(e.target.value);
  };
  const onChangeId = (e) => {
    setId(e.target.value);
  };
  const onChangeTitle = (e) => {
    setTitle(e.target.value);
  };
  const onChangeIsReload = (e) => {
    setIsReload(e.target.value);
  };
  const onChangeDisplay = (e) => {
    setDisplay(e.target.value);
  };
  const onChangeDisplayFnc = (e) => {
    displayFncRef.current = e.target.value;
  };
  const onDescription = (e) => {
    setDescription(e.target.value);
  };
  const onButtons = (e) => {
    setButtons(e.target.value);
  };
  useEffect(() => {
    let initItem = { ...(item || {}) };

    setProgramId(initItem.programId);
    setUrl(initItem.url);
    setId(initItem.id);
    setTitle(initItem.title);
    setIsReload(initItem.isReload || false);
    setDisplay(initItem.display);
    displayFncRef.current = initItem.displayFnc;
    setDescription(initItem.description);
    setButtons(initItem.buttons);
    setAddButtons(initItem.addButtons);
    setPopItems(initItem);
  }, [item]);

  const onChangeAddButtons = (e, result, index) => {
    const newAddButtons = produce(addButtons, (draft) => {
      draft[index] = { ...result, [e.target.id]: e.target.value };
    });
    //setResultMapping(newResultMapping);
    //onChangeSetRetItems(e, "resultMapping", newResultMapping);
  };

  const onInsertClick = (e, result, index) => {
    const newAddButtons = produce(addButtons, (draft) => {
      draft.splice(index + 1, 0, {});
    });
    //setResultMapping(newResultMapping);
    //onChangeSetRetItems(e, "resultMapping", newResultMapping);
  };

  const onDeleteClick = (e, result, index) => {
    const newAddButtons = produce(addButtons, (draft) => {
      draft.splice(index, 1);
      if (draft.length === 0) {
        draft.splice(0, 0, {});
      }
    });
    //setResultMapping(newResultMapping);
    //onChangeSetRetItems(e, "resultMapping", newResultMapping);
  };

  const openProgramPopup = (e) => {
    const options = {
      style: {
        content: {
          width: "60%", //popup의 크기를 50% (default 60%)
          height: "750px",
        },
      },
    };

    Popup.open(
      <ProgramListPopup
        workspace={workspace}
        title="Load Tab Program"
        params={{
          programType: {
            readonly: true,
            value: "T",
          },
        }}
        callbackFnc={(data) => {
          setProgramId(data.programId);
          let el = document.getElementById("programId");
          if (el) {
            el.value = data.programId;
            el.dispatchEvent(new Event("input", { target: el, bubbles: true }));
          }
          Popup.close();
        }}
      />,
      options
    );
  };

  return (
    <MesFunPopupStyle>
      <Modal>
        <Modal.Header title={modalTitle} />
        <Modal.Body>
          <UmodalTemplate>
            <Row className="mb-3">
              <Form.Group as={Col}>
                <Form.Label>Step ID</Form.Label>
                <input
                  type="text"
                  id="id"
                  defaultValue={StringUtils.defaultString(id)}
                  className="form-control form-control-sm"
                  onChange={onChangeId}
                />
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label>Step Title</Form.Label>
                <input
                  type="text"
                  id="title"
                  defaultValue={StringUtils.defaultString(title)}
                  className="form-control form-control-sm"
                  onChange={onChangeTitle}
                />
              </Form.Group>
            </Row>
            {containerProps.stepWizardType === "S" ? (
              <Row className="mb-3">
                <Form.Group as={Col}>
                  <Form.Label>Program ID</Form.Label>
                  <UInputPopup
                    id="programId"
                    placeholder="Please select Program ID"
                    size="default"
                    defaultValue={StringUtils.defaultString(programId)}
                    onClick={openProgramPopup}
                    onChange={onChangeProgramId}
                  />
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label>Call URL</Form.Label>
                  <input
                    type="text"
                    id="url"
                    name="url"
                    placeholder="Please Enter URL"
                    className="form-control"
                    value={StringUtils.defaultString(url)}
                    onChange={onChangeUrl}
                  />
                </Form.Group>
                <Form.Text id="programHelpBlock" muted>
                  Please enter Program ID or Call URL
                </Form.Text>
              </Row>
            ) : (
              ""
            )}
            <Row className="mb-3">
              <Form.Group as={Col}>
                <Form.Label>Step Description</Form.Label>
                <UTextarea
                  popTitle="Step Description"
                  textareaId="description"
                  defaultValue={StringUtils.defaultString(description)}
                  onChange={onDescription}
                />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col}>
                <Form.Label>Standard Button</Form.Label>
                <USelectbox
                  id="buttons"
                  onChange={onButtons}
                  defaultValue={StringUtils.defaultString(buttons)}
                  items={[
                    { id: "all", text: "All (Previous Button + Next Button)" },
                    { id: "none", text: "No Button" },
                    { id: "prev", text: "Previous Button" },
                    { id: "next", text: "Next Button" },
                  ]}
                  options={{ matchCd: "id", matchNm: "text" }}
                />
              </Form.Group>
              {containerProps.stepWizardType === "S" ? (
                <Form.Group as={Col}>
                  <Form.Label>Refresh</Form.Label>
                  <Row>
                    <Col>
                      <BootstrapSwitchButton
                        id="isReload"
                        checked={isReload !== false}
                        size="sm"
                        width={250}
                        onstyle="primary"
                        offstyle="dark"
                        onlabel="Always refresh when Page Open"
                        offlabel="Do not Refresh when Page Opened"
                        onChange={(value) =>
                          onChangeIsReload({
                            target: { id: "isReload", value: value },
                          })
                        }
                      />
                    </Col>
                  </Row>
                </Form.Group>
              ) : (
                ""
              )}
            </Row>
            <Form.Group className="mb-3">
              <Form.Label>Display</Form.Label>
              <Row>
                <Col>
                  <BootstrapSwitchButton
                    id="display"
                    checked={display !== false}
                    size="sm"
                    width={150}
                    onstyle="primary"
                    offstyle="dark"
                    onlabel="Always Display"
                    offlabel="Display By Condition"
                    onChange={(value) =>
                      onChangeDisplay({
                        target: { id: "display", value: value },
                      })
                    }
                  />
                </Col>
              </Row>
            </Form.Group>
            {display === false ? (
              <Form.Group className="mb-3">
                <Form.Label>Display Condition User Function</Form.Label>
                <UModalJavascriptEditor
                  height="200px"
                  defaultValue={StringUtils.defaultString(
                    displayFncRef.current
                  )}
                  eventInfo={props.getEventInfo()}
                  onClickEventBuilder={onClickEventBuilder}
                  onChange={(value, viewUpdate) => {
                    onChangeDisplayFnc({
                      target: { id: "displayFnc", value: value },
                    });
                  }}
                ></UModalJavascriptEditor>
                <Form.Text id="fncHelpBlock" muted>
                  Activate (return true) , Deactivate (return false)
                </Form.Text>
              </Form.Group>
            ) : (
              ""
            )}
          </UmodalTemplate>
        </Modal.Body>
        <Modal.Footer>
          <Modal.Footer.Button
            type="button"
            className="btn btn-primary"
            onClick={closePopup}
          >
            <BsPencilSquare />
            &nbsp;Confirm
          </Modal.Footer.Button>
        </Modal.Footer>
      </Modal>
    </MesFunPopupStyle>
  );
};

export default StepDetailConfigPopup;
