import ActionType from "components/builder/BuilderActionType";

/**
 * 필요한 Action 알아서 만드시오
 */

/**
 * Design 세팅
 * @param {*} breakpoint
 * @returns
 */
export const setDesign = (design) => {
  return {
    type: ActionType.PROGRAM_DESIGN.SET_DESIGN,
    payload: design,
  };
};

/**
 * Design Layout Column 세팅
 * @param {*} breakpoint
 * @returns
 */
export const setDesignLayoutColumn = (designLayoutColumn) => {
  return {
    type: ActionType.PROGRAM_DESIGN.SET_DESIGN_LAYOUT_COLUMN,
    payload: designLayoutColumn,
  };
};

/**
 * Design Related Tables 세팅
 * @param {*} breakpoint
 * @returns
 */
export const setDesignRelatedTables = (setDesignRelatedTables) => {
  return {
    type: ActionType.PROGRAM_DESIGN.SET_DESIGN_RELATED_TABLES,
    payload: setDesignRelatedTables,
  };
};

/**
 * Design Upload File 세팅
 * @param {*} breakpoint
 * @returns
 */
export const setUploadFile = (data) => {
  return {
    type: ActionType.PROGRAM_DESIGN.SET_FILE,
    payload: data,
  };
};
