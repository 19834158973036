import { InputLabel, Select, TextField } from "@mui/material";
import * as Enums from "components/builder/BuilderEnum";
import { AppContext } from "components/common/AppContextProvider";
import MuiConfig from "components/common/config/MuiConfig";
import MSelectbox from "components/common/element/MSelectbox";
import WijmoGrid from "components/common/element/WijmoGrid";
import CommonUtils, {
  ObjectUtils,
  StringUtils,
} from "components/common/utils/CommonUtils";
import PageTemplate from "page/common/PageTemplate";
import { useContext, useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { BsSearch } from "react-icons/bs";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ProgramService from "services/ui/ProgramService";

function MobileProgramHistoryMain() {
  const workspace = useSelector((state) => state.workspace);
  const [programList, setProgramList] = useState([]);
  const [programId, setprogramId] = useState("");
  const [programNm, setProgramNm] = useState("");
  const [programType, setProgramType] = useState("");
  // const [totalCount, setTotalCount] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [isLoading, setIsLoading] = useState(false);
  const [status, setStatus] = useState("A");
  // const CalledPage = useRef([]);
  const {
    code: { getCodeList },
    workspace: { openPopup },
  } = useContext(AppContext); //프로그램 유형
  const navigate = useNavigate();

  const breadcrum = [
    {
      name: "Mobile 프로그램 이력",
      url: Enums.BuilderPath.HISTORY,
      active: true,
    },
  ];

  useEffect(() => {
    if (!ObjectUtils.isEmpty(workspace)) {
      getProgramList();
    }
    return () => {
      setProgramList([]);
    };
  }, [workspace]);

  const getProgramList = () => {
    setIsLoading(true);
    const body = {
      appId: workspace.appId,
      moduleCd: workspace.moduleCd,
      appReleaseId: workspace.appReleaseId,
      tenantId: workspace.tenantId,
      coCd: workspace.coCd,
      builderType: "M",
      programId,
      programNm,

      programType,
      status: status,
    };

    ProgramService.getHistoryProgramList(
      body,
      (res) => {
        if (!res?.isError) {
          const { data } = res;
          const list = ProgramService.filterByTenantIdAndCoCd(
            data,
            workspace.tenantId,
            workspace.coCd
          );
          setProgramList(list);
          setIsLoading(false);
        }
      },
      () => {
        setIsLoading(false);
      }
    );
  };

  const columns = [
    { field: "programId", headerName: "프로그램 아이디", width: 200 },
    {
      field: "programNm",
      headerName: "프로그램 명",
      width: "*",
    },
    {
      field: "programNm",
      headerName: "프로그램 명",
      width: "*",
    },
    // {
    //   field: "programCompareView",
    //   headerName: "이력 비교",
    //   width: 100,
    //   align: "center",
    //   renderCell: (param) => {
    //     return (
    //       <Button
    //         variant="outline-secondary"
    //         size={"sm"}
    //         onClick={(e) => {
    //           stopEvent(e);
    //           const { programId, historyMstId } = param;
    //           navigate(`${programId}/${historyMstId}/compare`);
    //         }}
    //       >
    //         <IoIosGitCompare />
    //       </Button>
    //     );
    //   },
    // },
    {
      field: "mobileProgramTypeNm",
      headerName: "화면 구분",
      width: 120,
    },
    {
      field: "status",
      headerName: "상태",
      width: 120,
      renderCell: (params) => {
        const { status } = params;
        if (StringUtils.equalsIgnoreCase(status, "R")) {
          return "삭제 됨";
        } else if (StringUtils.equalsIgnoreCase(status, "A")) {
          return "사용 중";
        }
      },
      // valueGetter: (params) => {
      //   const { status } = params.row;
      //   if (StringUtils.equalsIgnoreCase(status, "R")) {
      //     return "삭제 됨";
      //   } else if (StringUtils.equalsIgnoreCase(status, "A")) {
      //     return "사용 중";
      //   }
      // },
    },
    {
      field: "updtUserId",
      headerName: "최종 수정자",
      width: 200,
    },
    {
      field: "lastUpdtDt",
      headerName: "최종 수정일시",
      width: 200,
      renderCell: (params) =>
        `${CommonUtils.getDate(params.updtDt, "dateTime")}`,
    },
  ];

  const onMoveToDetail = (historyMst) => {
    const { programId, historyMstId } = historyMst;
    navigate(`${programId}/${historyMstId}`);
  };

  const onKeyDown = (e) => {
    if (e.keyCode === 13) {
      getProgramList();
    }
  };

  return (
    <PageTemplate breadcrum={breadcrum}>
      <PageTemplate.Box boxClass="mb-0">
        {ObjectUtils.isEmpty(workspace) ? (
          <>
            <div className="workspace-empty-alert">
              <div className="alert-msg">워크 스페이스 설정이 필요 합니다.</div>
              <Button onClick={() => openPopup()}>팝업 열기</Button>
            </div>
          </>
        ) : (
          <>
            <Form>
              <Row className="pb-3 pt-3">
                <Col>
                  <TextField
                    size="small"
                    label="프로그램 ID"
                    placeholder="프로그램 ID"
                    fullWidth={true}
                    value={programId}
                    onChange={(e) => setprogramId(e.currentTarget.value)}
                    onKeyDown={onKeyDown}
                  />
                </Col>
                <Col>
                  <TextField
                    size="small"
                    label="프로그램"
                    placeholder="프로그램명"
                    fullWidth={true}
                    value={programNm}
                    onChange={(e) => setProgramNm(e.currentTarget.value)}
                    onKeyDown={onKeyDown}
                  />
                </Col>
                <Col>
                  <MSelectbox
                    size="small"
                    fullWidth={true}
                    data={getCodeList("Z0054")}
                    options={{ matchCd: "codeDtlCd", matchNm: "codeDtlNm" }}
                  >
                    <InputLabel id="module-label">화면 구분</InputLabel>
                    <Select
                      name="programType"
                      labelId="module-label"
                      label="화면 구분"
                      fullWidth
                      color="primary"
                      value={programType}
                      onChange={(e) => setProgramType(e.target.value)}
                    ></Select>
                  </MSelectbox>
                </Col>
                <Col>
                  <MSelectbox
                    size="small"
                    fullWidth={true}
                    data={[
                      { id: "*", text: "전체" },
                      { id: "A", text: "사용 중" },
                      { id: "R", text: "삭제 됨" },
                    ]}
                    options={{ matchCd: "id", matchNm: "text" }}
                  >
                    <InputLabel id="module-label">상태</InputLabel>
                    <Select
                      name="programType"
                      labelId="module-label"
                      label="상태"
                      fullWidth
                      color="primary"
                      value={status}
                      onChange={(e) => setStatus(e.target.value)}
                    ></Select>
                  </MSelectbox>
                </Col>
                <Col>
                  <Button variant="primary" onClick={getProgramList}>
                    검색 <BsSearch size="14" />
                  </Button>
                </Col>
              </Row>
            </Form>
            <Row style={{ height: "calc(100% - 70px)" }}>
              <Col xs={12}>
                <WijmoGrid
                  getRowId={(row) => row.historyMstId}
                  rows={programList}
                  columns={columns}
                  onRowClick={(data, event) => onMoveToDetail(data)}
                  {...MuiConfig.grid.options}
                  rowsPerPageOptions={[pageSize]}
                  pageSize={pageSize}
                  isLoading={isLoading}
                  style={{ height: "70vh" }}
                />
              </Col>
            </Row>
          </>
        )}
      </PageTemplate.Box>
    </PageTemplate>
  );
}

export default MobileProgramHistoryMain;
