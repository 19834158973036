/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import { BsPencilSquare } from "react-icons/bs";
import Popup from "components/common/Popup";
import Modal from "components/common/modal/UModal";
import styled from "styled-components";
import "bootstrap/dist/css/bootstrap.min.css";
import produce from "immer";

import {
  PropertyLable,
  PropertyValue,
} from "components/builder/ui/uiComponents/UIComponentStyle";
import StringUtils from "components/common/utils/StringUtils";
import ObjectUtils from "components/common/utils/ObjectUtils";
import { Col, Form, Row } from "react-bootstrap";
import ArrayUtils from "components/common/utils/ArrayUtils";
import DataModelService from "services/datamodel/DataModelService";
import ComponentService from "services/common/ComponentService";

export const GridToolbarBtnPopupStyle = styled.div`
  .table-bottom {
    border-bottom: 1px solid #c9d3dd;
  }
  .table-header {
    border-bottom: 2px solid #c9d3dd;
    border-top: 1px solid #dadada;
    height: 35px;
    align-items: center;
    background: #f5f5f5;
    margin-left: 0;
    margin-right: 0;
  }
`;

const UserTransactionSPPopup = (props) => {
  const { title, callbackFnc, propertyValue, entityId } = props;

  //codemirror

  const [tnxHandleOption, setTnxHandleOption] = useState({});
  const [usrTnxHandleOption, setUsrTnxHandleOption] = useState({});
  const [dataBindingList, setDataBindingList] = useState([]);

  useEffect(() => {
    initPopup();
  }, []);

  const initPopup = () => {
    //user Transaction에 등록된 Option정보를 읽어온다.
    if (ObjectUtils.isEmpty(tnxHandleOption) && propertyValue.transaction) {
      let param = {
        dataModelUsrTnxId: propertyValue.transaction,
      };
      ComponentService.selectDataModelUsrTnx(param, (res) => {
        assignTnxHandleOption(res.data);
      });
    }
  };

  const assignTnxHandleOption = (data) => {
    //Stored procedure 호출
    if (data) {
      // Argument에 Binding할 Field를 조회 한다.
      if (ArrayUtils.isEmpty(dataBindingList)) selectDataBindingList(entityId);

      try {
        let parseTnxHandleOption = JSON.parse(data.tnxHandleOption) || {};
        setTnxHandleOption(parseTnxHandleOption);
        convertUsrTnxHandleOption(parseTnxHandleOption);
      } catch (e) {
        setTnxHandleOption({});
        setUsrTnxHandleOption({});
        console.log("an occured Error during parsing Option JSON -" + e);
      }
    } else {
      setTnxHandleOption({});
      setUsrTnxHandleOption({});
    }
  };

  /**
   * User Transaction Option 정보를 기반으로 Builder에서 사용할수 있는 형태로 변환
   * @param {*} tnxHandleOption
   */
  const convertUsrTnxHandleOption = (tnxHandleOption) => {
    let newUsrTnxHandleOption = {};
    newUsrTnxHandleOption.procedureName = tnxHandleOption.procedureName;
    //db 기준으로
    let args = [];
    tnxHandleOption.arguments.map((arg, index) => {
      let newArgs = { ...arg };
      //사용자 설정 정보
      if (
        !ObjectUtils.isEmpty(propertyValue.tnxHandleOption) &&
        !ArrayUtils.isEmpty(propertyValue.tnxHandleOption.arguments) &&
        !ObjectUtils.isEmpty(propertyValue.tnxHandleOption.arguments[index])
      ) {
        newArgs.dataBinding = StringUtils.defaultString(
          propertyValue.tnxHandleOption.arguments[index].dataBinding
        );
        newArgs.defaultValue = StringUtils.defaultString(
          propertyValue.tnxHandleOption.arguments[index].defaultValue
        );
      } else {
        newArgs.dataBinding = "";
        newArgs.defaultValue = "";
      }
      args.push(newArgs);
    });
    newUsrTnxHandleOption.arguments = args;
    setUsrTnxHandleOption(newUsrTnxHandleOption);
  };

  const onChangeProcDataBinding = (e, arg, index) => {
    let newArg = { ...arg };
    //id를 setting해준다.
    newArg.dataBinding = StringUtils.substringAfter(e.target.value, ".");
    changeProcArgs(e, newArg, index);
  };

  const onChangeProcArgs = (e, arg, index) => {
    let newArg = { ...arg, [e.target.id]: e.target.value };
    changeProcArgs(e, newArg, index);
  };

  const changeProcArgs = (e, arg, index) => {
    const newArguments = produce(usrTnxHandleOption.arguments, (draft) => {
      draft[index] = { ...arg };
    });
    setUsrTnxHandleOption({
      ...usrTnxHandleOption,
      ...{ arguments: newArguments },
    });
  };

  /**
   * closePopup: value값을 전달하고 popup 닫기
   * @param {*} e
   */
  const closePopup = (e) => {
    if (callbackFnc) {
      callbackFnc.call(this, usrTnxHandleOption);
    }
    Popup.close();
  };

  /**
   * Data Model Entity Field List 조회(Data Binidng)
   * @param {*} entityId
   */
  const selectDataBindingList = (entityId) => {
    if (!StringUtils.isEmpty(entityId)) {
      DataModelService.getDataBindingList({ entityId: entityId }, (res) => {
        setDataBindingList(
          res.data ? res.data : [{ id: "none", text: "[none] None Data" }]
        );
      });
    }
  };

  return (
    <GridToolbarBtnPopupStyle>
      <Modal>
        <Modal.Header title={title} />
        <Modal.Body className="popup-panel">
          <div className="w-full flex-wrap">
            <PropertyLable requried="true" className="float-left">
              Stored Procedure Name
            </PropertyLable>
            <PropertyValue className="float-left">
              <input
                type="text"
                id="procedureName"
                readOnly={true}
                defaultValue={StringUtils.defaultString(
                  usrTnxHandleOption.procedureName
                )}
                className="form-control form-control-sm"
              />
            </PropertyValue>
          </div>
          <div className="w-full">
            <PropertyValue className="float-left w-full">
              <Form.Group className="mb-3 table-bottom">
                <Col>
                  <Row className="mb-2 table-header">
                    <Col>Argument</Col>
                    <Col>Type</Col>
                    <Col>Mode</Col>
                    <Col>Data Binding</Col>
                    <Col>Fixed Value</Col>
                  </Row>
                  {!ArrayUtils.isEmpty(usrTnxHandleOption.arguments)
                    ? usrTnxHandleOption.arguments.map((argument, index) => {
                        return (
                          <Row
                            key={index}
                            className="pb-2"
                            style={{ alignItems: "center" }}
                          >
                            <Col>
                              <input
                                type="text"
                                id="name"
                                className="form-control form-select-sm"
                                defaultValue={argument.name}
                                readOnly={true}
                              />
                            </Col>
                            <Col>
                              <input
                                type="text"
                                id="type"
                                className="form-control form-select-sm"
                                defaultValue={argument.type}
                                readOnly={true}
                              />
                            </Col>
                            <Col>
                              <input
                                type="text"
                                id="mode"
                                className="form-control form-select-sm"
                                defaultValue={argument.mode}
                                readOnly={true}
                              />
                            </Col>
                            <Col>
                              {argument.name !== "tenantId" &&
                              argument.name !== "coCd" ? (
                                <Form.Select
                                  size="sm"
                                  id="dataBinding"
                                  onChange={(e) =>
                                    onChangeProcDataBinding(e, argument, index)
                                  }
                                  value={StringUtils.defaultString(
                                    argument.dataBinding
                                  )}
                                >
                                  <option value={""}>Select</option>
                                  {!ArrayUtils.isEmpty(dataBindingList)
                                    ? dataBindingList.map(
                                        (option, optionIdx) => (
                                          <option
                                            key={optionIdx}
                                            value={option.id}
                                          >
                                            {option.text}
                                          </option>
                                        )
                                      )
                                    : ""}
                                </Form.Select>
                              ) : (
                                "N/A"
                              )}
                            </Col>
                            <Col>
                              {argument.name !== "tenantId" &&
                              argument.name !== "coCd" &&
                              argument.mode !== "OUT" ? (
                                <input
                                  type="text"
                                  id="defaultValue"
                                  className="form-control form-select-sm"
                                  defaultValue={StringUtils.defaultString(
                                    argument.defaultValue
                                  )}
                                  onChange={(e) =>
                                    onChangeProcArgs(e, argument, index)
                                  }
                                />
                              ) : (
                                ""
                              )}
                            </Col>
                          </Row>
                        );
                      })
                    : ""}
                </Col>
              </Form.Group>
            </PropertyValue>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Modal.Footer.Button onClick={closePopup}>
            <BsPencilSquare />
            &nbsp;Confirm
          </Modal.Footer.Button>
        </Modal.Footer>
      </Modal>
    </GridToolbarBtnPopupStyle>
  );
};

export default UserTransactionSPPopup;
