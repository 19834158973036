import { CircularProgress } from "@mui/material";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import { Enums } from "components/builder/BuilderEnum";
import { AppContext } from "components/common/AppContextProvider";
import Message from "components/common/Message";
import Popup from "components/common/Popup";
import CommonUtils from "components/common/utils/CommonUtils";
import ObjectUtils from "components/common/utils/ObjectUtils";
import {
  AppConfig,
  AppConfigDesc,
  AppConfigName,
  AppConfigSwitch,
  AppDesc,
} from "components/setting/SettingMenu";
import WorkflowListPopup from "page/popup/workflow/WorkflowListPopup";
import { useContext, useEffect, useState } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import { MdSearch } from "react-icons/md";
import AppService from "services/common/AppService";
import ConnectionService from "services/common/ConnectionService";
import AppGloabalVariableManage from "./AppGloabalVariableManage";

function AppGeneral() {
  const [defaultValueUseYn, setDefaultValueUseYn] = useState({
    configValue: "N",
  });
  const [defaultValueWorkflow, setDefaultValueWorkflow] = useState({});
  const [globalVariableUseYn, setGlobalVariableUseYn] = useState({
    configValue: "N",
  });
  const {
    workspace: { Info: workspace, openPopup: workspaceOpen },
  } = useContext(AppContext);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getAppConfig();
  }, []);

  const getAppConfig = () => {
    let defaultValuConfig = CommonUtils.getAppConfig(
      workspace,
      "defaultValueUseYn",
      true
    );
    let globaclVariableConfig = CommonUtils.getAppConfig(
      workspace,
      "globalVariableUseYn",
      true
    );
    if (defaultValuConfig) {
      setDefaultValueUseYn(defaultValuConfig);
      setDefaultValueWorkflow(JSON.parse(defaultValuConfig.remark));
    }
    if (globaclVariableConfig) {
      setGlobalVariableUseYn(globaclVariableConfig);
    }
  };

  const onClickWfSearch = () => {
    const wfCallback = async (wf) => {
      //Runtime 정보 업데이트
      const result = await applyRuntime({
        dtlCd: "WORKFLOW_FOR_DEFAULT_VALUE",
        useYn: "Y",
        reference: wf.serviceUid,
        dtlNm: "Workflow For Default Input Value",
        dtlNmDesc: "Workflow For Default Input Value",
      });
      if (result) {
        setDefaultValueWorkflow(wf);
        //builder 정보 업데이트
        AppService.setAppConfig(
          {
            ...defaultValueUseYn,
            appMst: workspace,
            remark: JSON.stringify(wf),
          },
          (res) => {
            setDefaultValueUseYn(res.data);
          }
        );
        Popup.close();
      }
    };

    Popup.open(
      <WorkflowListPopup workspace={workspace} callbackFnc={wfCallback} />,
      {
        style: { content: { width: "1200px" } },
      }
    );
  };

  const onChangeDefaultValue = async (flag) => {
    let useYn = flag ? "Y" : "N";

    const result = await applyRuntime({
      dtlCd: "USING_DEFAULT_INPUT_VALUE",
      useYn: "Y",
      reference: useYn,
      dtlNm: "Use of default user input values",
      dtlNmDesc: "Use of default user input values",
    });
    if (result) {
      AppService.setAppConfig(
        {
          ...defaultValueUseYn,
          appMst: workspace,
          configCd: "defaultValueUseYn",
          configValue: useYn,
        },
        (res) => {
          setDefaultValueUseYn(res.data);
        }
      );
    }
  };

  const onChangeGlobalVariable = async (flag) => {
    let useYn = flag ? "Y" : "N";

    const result = await applyRuntime({
      dtlCd: "USING_GLOBAL_VARIABLES",
      useYn: "Y",
      reference: useYn,
      dtlNm: "Use of Global Variable Workflow",
      dtlNmDesc: "Use of Global Variable Workflow",
    });
    if (result) {
      AppService.setAppConfig(
        {
          ...globalVariableUseYn,
          appMst: workspace,
          configCd: "globalVariableUseYn",
          configValue: useYn,
        },
        (res) => {
          setGlobalVariableUseYn(res.data);
        }
      );
    }
  };

  const applyRuntime = ({ dtlCd, useYn, reference, dtlNm, dtlNmDesc }) => {
    const { connection, message, isConnected } =
      ConnectionService.validConnection(workspace);
    setIsLoading(true);
    return new Promise((resolve, reject) => {
      if (!isConnected) {
        Message.alert(message, Enums.MessageType.INFO);
        resolve(false);
      }

      //Runtime 정보 업데이트
      AppService.setConfigForRuntime(
        {
          ...connection,
          dtlCd: dtlCd,
          mstId: "DF0001",
          dtlNm: dtlNm,
          dtlNmDesc: dtlNmDesc,
          useYn: useYn,
          reference: reference,
          accessToken: connection.token,
        },
        (res) => {
          resolve(true);
          setIsLoading(false);
        },
        (err) => {
          Message.alert(err.message, Enums.MessageType.ERROR);
          resolve(false);
          setIsLoading(false);
        }
      );
    });
  };

  return (
    <>
      {isLoading && (
        <div className="data-load-wrapper">
          <div className="data-load-box">
            <CircularProgress color="inherit" size={13} />
            &nbsp;&nbsp;&nbsp; Applying...
          </div>
        </div>
      )}

      <div className="setting-header">Application Genaral Setting</div>
      <div
        className="setting-container"
        style={{ height: "100%", padding: "0 25px" }}
      >
        {ObjectUtils.isEmpty(workspace) ? (
          <div className="workspace-empty-alert">
            <div className="alert-msg">Workspace setting Required </div>
            <Button onClick={workspaceOpen}>Open Popup</Button>
          </div>
        ) : (
          <>
            {!ConnectionService.validConnection(workspace).isConnected && (
              <AppConfigName>
                <span className="blink" style={{ color: "tomato" }}>
                  Connect Server berfore set Configuration
                </span>
              </AppConfigName>
            )}

            <AppConfig>
              <AppConfigName>Use App default input value</AppConfigName>
              <AppConfigDesc>
                <AppDesc>
                  It determines whether to use the default input value settings
                  for the runtime application.
                </AppDesc>
                <AppConfigSwitch>
                  <BootstrapSwitchButton
                    onlabel="Yes"
                    offlabel="No"
                    offstyle="dark"
                    disabled={
                      !ConnectionService.validConnection(workspace).isConnected
                    }
                    checked={
                      defaultValueUseYn.configValue === "Y" ? true : false
                    }
                    onChange={(value) => {
                      onChangeDefaultValue(value);
                    }}
                  />
                </AppConfigSwitch>
              </AppConfigDesc>
              {defaultValueUseYn.configValue === "Y" && (
                <div
                  style={{
                    paddingLeft: "5%",
                    display: "flex",
                    gap: "15px",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                >
                  <span>Workflow for default value</span>
                  <div>
                    <InputGroup>
                      <Form.Control
                        style={{ minWidth: "300px" }}
                        value={
                          !ObjectUtils.isEmpty(defaultValueWorkflow)
                            ? `${defaultValueWorkflow.serviceName} ( ${defaultValueWorkflow.serviceId} ) `
                            : ""
                        }
                        readOnly
                      />
                      <Button
                        size="sm"
                        onClick={onClickWfSearch}
                        disabled={
                          !ConnectionService.validConnection(workspace)
                            .isConnected
                        }
                      >
                        <MdSearch />
                      </Button>
                    </InputGroup>
                  </div>
                </div>
              )}
            </AppConfig>
            <AppConfig>
              <AppConfigName>Use App Global Variable</AppConfigName>
              <AppConfigDesc>
                <AppDesc>
                  It determines whether to use custom global variables in the
                  runtime application.
                </AppDesc>
                <AppConfigSwitch>
                  <BootstrapSwitchButton
                    onlabel="Yes"
                    offlabel="No"
                    offstyle="dark"
                    disabled={
                      !ConnectionService.validConnection(workspace).isConnected
                    }
                    checked={
                      globalVariableUseYn.configValue === "Y" ? true : false
                    }
                    onChange={onChangeGlobalVariable}
                  />
                </AppConfigSwitch>
              </AppConfigDesc>
              {globalVariableUseYn.configValue === "Y" && (
                <div style={{ paddingLeft: "0 5%" }}>
                  <AppGloabalVariableManage />
                </div>
              )}
            </AppConfig>
          </>
        )}
      </div>
    </>
  );
}

export default AppGeneral;
