import { Enums } from "components/builder/BuilderEnum";
import Message from "components/common/Message";
import Popup from "components/common/Popup";
import WijmoGrid from "components/common/element/WijmoGrid";
import ObjectUtils from "components/common/utils/ObjectUtils";
import StringUtils from "components/common/utils/StringUtils";
import { SettingSearchBar } from "components/setting/composition/Contents";
import ConnectionAddPopup from "components/setting/section/connection/ConnectionAddPopup";
import { useEffect, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import ConnectionService from "services/common/ConnectionService";
import styled from "styled-components";

const columns = [
  { field: "connectionId", headerName: "Connection ID", align: "center" },
  // {
  //   field: "tenantId",
  //   headerName: "테넌트 ID",
  //   width: 150,
  // },
  // {
  //   field: "coCd",
  //   headerName: "회사 코드",
  //   width: 150,
  // },
  {
    field: "connectionNm",
    headerName: "Connection Name",
    width: "*",
  },
  {
    field: "protocol",
    headerName: "Schema",
    width: 200,
  },
  {
    field: "host",
    headerName: "Host",
    width: "*",
  },
  {
    field: "userId",
    headerName: "System ID",
    width: 200,
  },
];

const SearchBar = styled(SettingSearchBar)`
  height: 30px;
  justify-content: flex-end;
  & input {
    width: 300px;
  }
`;

function ConnectionList({ title, ...props }) {
  const [dpList, setDpList] = useState();
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedRow, setSelectedRow] = useState({});
  const connectionList = useRef([]);

  useEffect(() => {
    getConnectionList();
  }, []);

  const getConnectionList = () => {
    ConnectionService.getAllConnectionList({}, (res) => {
      if (!res.isError) {
        connectionList.current = res.data;
        filterList(searchTerm);
      }
    });
  };

  const filterList = (value) => {
    if (!StringUtils.isEmpty(value)) {
      const LowerValue = String(value).toLocaleLowerCase();
      const result = connectionList.current.filter(
        (c) =>
          c.tenantId.toLocaleLowerCase().indexOf(LowerValue) > -1 ||
          c.connectionNm.toLocaleLowerCase().indexOf(LowerValue) > -1 ||
          c.host.toLocaleLowerCase().indexOf(LowerValue) > -1 ||
          c.coCd.toLocaleLowerCase().indexOf(LowerValue) > -1 ||
          c.protocol.toLocaleLowerCase().indexOf(LowerValue) > -1
      );
      setDpList(result);
    } else {
      setDpList(connectionList.current);
    }
  };

  const onSearch = () => {};
  const onChangeSearchTerm = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    filterList(value);
  };

  const onClickRow = (data, e) => {
    setSelectedRow(data);
  };

  const onDeleteConection = () => {
    Message.confirm(
      "Are you sure to delete all the Connection Information?",
      () => {
        ConnectionService.delete(selectedRow, (res) => {
          const { isError, data } = res;
          if (isError) {
            Message.alert(
              "An error occurred during Communication.",
              Enums.MessageType.ERROR
            );
          } else {
            Message.alert(data.message, Enums.MessageType.INFO);
            getConnectionList();
          }
        });
      }
    );
  };

  /**
   * 저장
   * @returns
   */
  const onSaveConnect = (body, cb) => {
    ConnectionService.save(
      body,
      (res) => {
        if (!res.isError) {
          Popup.close();
          getConnectionList();
          if (cb) cb();
        }
      },
      (error) => {
        Message.alert("Connection Error has occured", Enums.MessageType.ERROR);
      }
    );
  };

  const openConnectionAdd = (event, connection) => {
    //팝업창 열기
    const options = {
      effect: Popup.ScaleUp, //Effect.SlideFromTop(default)를 Effect.ScaleUp 로 변경
      style: {
        content: {
          width: "30%", //popup의 크기를 50% (default 60%)
        },
      },
    };
    Popup.open(
      <ConnectionAddPopup
        saveConnection={onSaveConnect}
        connection={connection}
      />,
      options
    );
    if (event) event.preventDefault();
  };

  const onEditConnection = (e) => {
    openConnectionAdd(e, selectedRow);
  };

  return (
    <>
      <div className="setting-header">{title}</div>
      <div className="setting-container">
        <SearchBar>
          <span>Search Connection</span>
          <input
            value={searchTerm}
            onChange={onChangeSearchTerm}
            placeholder="tenantID, coCd , connection, schema, host"
          />
          <Button onClick={onSearch}>Search</Button>
        </SearchBar>
        <WijmoGrid
          columns={columns}
          rows={dpList}
          onRowClick={onClickRow}
          style={{ height: "55vh" }}
        />
      </div>
      <div className="setting-button split">
        <div>
          <Button onClick={openConnectionAdd}>New Connection</Button>
        </div>
        <div>
          <Button variant="success" onClick={onEditConnection}>
            Edit
          </Button>
          <Button
            disabled={ObjectUtils.isEmpty(selectedRow)}
            variant="outline-danger"
            onClick={onDeleteConection}
          >
            Delete
          </Button>
        </div>
      </div>
    </>
  );
}

export default ConnectionList;
