import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import BoardService from "services/prototype/BoardService";
import Message from "components/common/Message";

const BoardForm = () => {
  const [inputs, setInputs] = useState({
    type: "",
    title: "",
    contents: "",
    memberNo: "",
  });

  const navigate = useNavigate();

  const { type, title, contents, memberNo } = inputs;

  const onChange = (event) => {
    setInputs({
      ...inputs,
      [event.target.name]: event.target.value,
    });
  };

  const createBoard = (event) => {
    event.preventDefault();

    //console.log("board => " + JSON.stringify(board));
    Message.confirm("Are you sure you want to save?", () => {
      /* Service 생성후 호출 */
      BoardService.createBoard(inputs, (res) => {
        Message.alert("Saved Successfully.", "info");
        navigate("/board/");
      });
      /* API 직접 호출
    Api.post("/prototype/board", board, (res) => {
      navigate("/board/");
    });
    */
    });
  };

  const cancel = (event) => {
    navigate("/board/");
  };

  return (
    <div className="content">
      <div className="row">
        <div className="card col-md-6 offset-md-3 offset-md-3">
          <h3 className="text-center">Please write a new post</h3>
          <div className="card-body">
            <form>
              <div className="form-group">
                <label> Type </label>
                <select
                  placeholder="type"
                  name="type"
                  className="form-control"
                  value={type}
                  onChange={onChange}
                >
                  <option value="1">Forum</option>
                  <option value="2">Q&A</option>
                </select>
              </div>
              <div className="form-group">
                <label> Title </label>
                <input
                  type="text"
                  placeholder="title"
                  name="title"
                  className="form-control"
                  value={title}
                  onChange={onChange}
                />
              </div>
              <div className="form-group">
                <label> Contents </label>
                <textarea
                  placeholder="contents"
                  name="contents"
                  className="form-control"
                  value={contents}
                  onChange={onChange}
                />
              </div>
              <div className="form-group">
                <label> MemberNo </label>
                <input
                  placeholder="memberNo"
                  name="memberNo"
                  className="form-control"
                  value={memberNo}
                  onChange={onChange}
                />
              </div>
              <button className="btn btn-success" onClick={createBoard}>
                Save
              </button>
              <button
                className="btn btn-danger"
                onClick={cancel}
                style={{ marginLeft: "10px" }}
              >
                Cancel
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BoardForm;
