import BootstrapSwitchButton from "bootstrap-switch-button-react";
import { Enums } from "components/builder/BuilderEnum";
import Message from "components/common/Message";
import StringUtils from "components/common/utils/StringUtils";
import User from "components/common/utils/UserUtils";
import { ContentsItem } from "components/setting/composition/Contents";
import React, { useEffect } from "react";
import { useState } from "react";
import { Button } from "react-bootstrap";
import ConnectionService from "services/common/ConnectionService";
import SettingService from "services/common/SettingService";

function ConnectionSetting({ title, ...props }) {
  const [ConnectionSetting, setConnectionSetting] = useState({
    autoConnection: false,
    autoConnectionTarget: "",
    autoConnectionPw: "",
  });

  const [connectionList, setConnectionList] = useState([]);

  useEffect(() => {
    const userConfig = User.getConfiguration(
      Enums.ConfigurationDefine.CONNECTION
    );
    setConnectionSetting({
      ...userConfig,
    });

    ConnectionService.getConnectionList({}, (res) => {
      setConnectionList(res.data.content);
    });
  }, []);

  const onChangeAutoApply = (boolean) => {
    setConnectionSetting({
      ...ConnectionSetting,
      autoConnection: boolean,
    });
  };

  const onPropertyChange = (e) => {
    const newSetting = { ...ConnectionSetting };
    newSetting[e.target.id] = e.target.value;
    setConnectionSetting(newSetting);
  };

  const onSave = () => {
    if (ConnectionSetting.autoConnection) {
      if (StringUtils.isEmpty(ConnectionSetting.autoConnectionTarget)) {
        return Message.alert(
          "Please select the target for auto connection.",
          Enums.MessageType.WARN
        );
      }
      if (StringUtils.isEmpty(ConnectionSetting.autoConnectionPw)) {
      }
    }

    const body = {
      configurationCode: Enums.ConfigurationDefine.CONNECTION,
      configurationValue: JSON.stringify(ConnectionSetting),
    };
    SettingService.saveUserConfig(body, (res) => {
      const key = res.data.configurationCode;
      const value = JSON.parse(res.data.configurationValue);
      User.overrideConfig(key, value);
      Message.alert("Save Successfully", Enums.MessageType.SUCCESS);
    });
  };

  return (
    <>
      <div className="setting-header">{title}</div>
      <div className="setting-container">
        <ContentsItem title={"Server Auto Connection Status"}>
          <BootstrapSwitchButton
            id="isReadonly"
            checked={ConnectionSetting.autoConnection}
            onstyle="primary"
            offstyle="dark"
            onlabel="Yes"
            offlabel="No"
            size="sm"
            onChange={onChangeAutoApply}
          />
        </ContentsItem>
        <ContentsItem
          title={"Connection Target Setting"}
          disabled={!ConnectionSetting.autoConnection}
        >
          <select
            id="autoConnectionTarget"
            onChange={onPropertyChange}
            value={ConnectionSetting.autoConnectionTarget}
          >
            <option value="">Select</option>
            {connectionList.map((opt, idx) => {
              return (
                <option key={opt.connectionId} value={opt.connectionId}>
                  {opt.connectionNm}
                </option>
              );
            })}
          </select>
        </ContentsItem>
        <ContentsItem
          title={"Connection Info Setting"}
          disabled={!ConnectionSetting.autoConnection}
        >
          <span>Password</span>
          <input
            id="autoConnectionPw"
            type={"password"}
            autoComplete={"off"}
            value={ConnectionSetting.autoConnectionPw}
            onChange={onPropertyChange}
          />
        </ContentsItem>
      </div>
      <div className="setting-button">
        <Button onClick={onSave}>Save</Button>
      </div>
    </>
  );
}

export default ConnectionSetting;
