import Api from "components/common/Api";

class EncryptTokenService {
  static requestMapping = "/mobile";

  /**
   * Token 암호화
   * @param {*} data
   * @param {*} callbackFnc
   * @param {*} errCallbackFnc
   */
  static encryptToken(data, callbackFnc, errCallbackFnc) {
    Api.post(
      this.requestMapping + "/encryptToken",
      data,
      callbackFnc,
      errCallbackFnc
    );
  }
}
export default EncryptTokenService;
