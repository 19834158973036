import { Enums, MessageType } from "components/builder/BuilderEnum";
import UIReduxHelper from "components/builder/ui/editor/helper/UIReduxHelper";
import { setSidebarCollapse } from "components/builder/ui/reducers/MenuAction";
import Message from "components/common/Message";
import Popup from "components/common/Popup";
import ProgressSpinner from "components/common/ProgressSpinner";
import CommonUtils, {
  ArrayUtils,
  ObjectUtils,
  StringUtils,
} from "components/common/utils/CommonUtils";
import SessionUtils from "components/common/utils/SessionUtils";
import User from "components/common/utils/UserUtils";
import WorkspacePopup from "page/popup/workspace/WorkspacePopup";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AppService from "services/common/AppService";
import CodeService from "services/common/CodeService";
import ComponentService from "services/common/ComponentService";
import StyledContentLoader from "styled-content-loader";

import * as wjcCore from "@grapecity/wijmo";
import Blockly from "blockly";
import { javascriptGenerator } from "blockly/javascript";
import {
  initActivedEntity,
  initDataModel,
} from "components/builder/entity/reducers/EntityBuilderAction";
import "components/builder/eventhandler/editor/render";
import BlockCallBackMutator from "components/builder/eventhandler/editor/render/init/blockMutator/BlockCallBackMutator";
import { setIsInitEventWorkspace } from "components/builder/eventhandler/reducer/EventHandlerAction";
import { initSetting } from "components/builder/ui/reducers/SettingAction";
import {
  initActive,
  initOutput,
} from "components/builder/ui/reducers/UIBuilderAction";
import { initWorkflow } from "components/builder/workflow/reducer/WorkflowAction";
import {
  initBreakpoint,
  initDebug,
} from "components/builder/workflow/reducer/WorkflowDebugAction";
import StyleUtils from "components/common/utils/StyleUtils";
import ConnectPopup from "page/popup/ConnectPopup";
import ConnectionService from "services/common/ConnectionService";
import {
  default as LocalService,
  default as LocalStorageService,
} from "services/common/LocalService";
import SettingService from "services/common/SettingService";
import EventhandlerService from "services/eventhandler/EventhandlerService";
import ProgramService from "services/ui/ProgramService";

import { Api } from "@alpha/com.bizentro.daaf.front.framework";

import { Core } from "@mescius/activereportsjs";

export const AppContext = React.createContext({
  dataModel: {
    list: [],
    setList: () => {},
    type: "",
    setType: () => {},
    searchTerm: {},
    setSearchTerm: () => {},
  },
  workspace: {
    Info: {},
    openPopup: () => {},
    setPrevListShortCut: () => {},
  },
  application: {
    list: [],
    setList: () => {},
    availableList: [],
  },
  module: {
    list: [],
  },
  connection: {
    Info: {},
    setInfo: () => {},
    list: [],
    setList: () => {},
    openPopup: () => {},
    isConnecting: false,
    setIsConnecting: () => {},
  },
  code: { codes: [], getCodeList: () => {} },
  component: {
    components: [],
    getComponentList: () => {},
    getComponentInfo: () => {},
    getPageComponent: () => {},
  },
  serviceComponent: {
    serviceComponents: [],
    getSvcComponent: () => {},
    refreshSvcComponent: () => {},
  },
  format: {
    list: [],
    refreshFormatList: () => {},
  },
  eventBuilder: {
    setEventBuilder: () => {},
    blockListContainer: [],
  },
});

const AppContextProvider = ({ children }) => {
  const [codes, setCodes] = useState([]);
  const [components, setComponents] = useState([]);
  const [serviceComponents, setServiceComponents] = useState([]);
  const [dataModels, setDataModels] = useState([]);
  const [dataModelSearchTerm, setDataModelSearchTerm] = useState({});
  const [dataModelType, setDataModelType] = useState("");
  const [AppList, setAppList] = useState([]);
  const [connectionList, setConnectionList] = useState([]);
  const [connectionInfo, setConnectionInfo] = useState({});
  const [formatList, setFormatList] = useState([]);
  const [blockList, setBlockList] = useState([]); //이벤트 핸들러 블록 목록
  const [isLoading, setIsLoading] = useState(true);
  const [isConnecting, setIsConnecting] = useState(false);

  const workspace = useSelector((state) => state.workspace);
  const eventHandler = useSelector((state) => state.eventHandler);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const contextFormat = useContext(AppContext).format;

  Api.setReqInterceptor(
    (config) => {
      return config;
    },
    (error) => {
      return error;
    }
  );

  Api.setResInterceptor((response) => {
    return response;
  });

  /**
   * 워크스페이스 설정 전 App Init
   * @returns
   */
  const initAppContext = async () => {
    setIsLoading(true);
    return new Promise((resolve, reject) => {
      initCodeList()
        .then(initComponentList)
        .then(initAppList)
        // .then(initModuleList)
        .then(initConnectionList)
        .then((list) => {
          resolve(true);
        })
        // .then(InitEventBuilderBlockList)
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
          reject(err);
        });
    });
  };

  /**
   * 코드리스트 초기설정
   * @returns {Promise}
   */
  const initCodeList = () => {
    return new Promise((resolve, reject) => {
      CodeService.getCodeList({}, (res) => {
        setCodes(res.data);
        resolve(true);
      });
    });
  };

  /**
   * 컴포넌트 목록 설정
   * @returns {Promise}
   */
  const initComponentList = () => {
    return new Promise((resolve, reject) => {
      ComponentService.getComponentList({}, (res) => {
        setComponents(res.data);
        resolve(true);
      });
    });
  };
  /**
   * Application 목록 초기화
   * @returns
   */
  const initAppList = () => {
    return new Promise((resolve, reject) => {
      AppService.getAppList({}, (res) => {
        setAppList(res.data);
        resolve(true);
      });
    });
  };
  /**
   * 각 계정별 연결 목록 초기화
   * @returns
   */
  const initConnectionList = () => {
    return new Promise((resolve, reject) => {
      ConnectionService.getAllConnectionList({}, (res) => {
        if (!res.isError) {
          setConnectionList(res.data);
          resolve(true);
        }
      });
    });
  };

  /**
   * Wijmo 라이선스 키 가져오기
   */
  const initWijmoLicenseKey = () => {
    return new Promise((resolve, reject) => {
      CodeService.getCodeCombo({ codeMstCd: "Z0043" }, (res) => {
        if (!ArrayUtils.isEmpty(res.data)) {
          res.data.forEach((data) => {
            if (data.id === "WijmoKey") {
              wjcCore.setLicenseKey(data.text);
            } else if (data.id === "ActiveReportsKey") {
              Core.setLicenseKey(data.text);
            }
          });
        }
        resolve(true);
      });
    });
  };

  /**
   * EventBuilder BlockList 호출
   * Blockly에 블럭 타입 정의 용이기 때문에 모든 블럭을 호출해서 라이브러리 등록함
   */
  const InitEventBuilderBlockList = () => {
    new Promise((resolve, reject) => {
      // return resolve(true);
      EventhandlerService.getBlockList({}, (res) => {
        setBlockList(res.data);
        blockInit(res.data);
        resolve(true);
      });
    });
  };

  /**
   * 워크스페이스 설정 후 App Init
   */
  const initAppContextAfterWorkspaceSet = () => {
    document.title = `Daaf Builder ${AppService.getWorkspaceName(workspace)}`;
    //serviceComponent 정보 조회
    ComponentService.getServiceComponentList(
      {
        appId: workspace.appId,
      },
      (res) => {
        setServiceComponents(res.data);
      }
    );
    setBuilderDefaultStyle();
    StyleUtils.init();
    SettingService.getStyleList({ ...workspace, defaultYn: "Y" }, (res) => {
      if (res.data) {
        const list = ProgramService.sortByTenantIdAndCoCd(res.data);
        for (const _style of list) {
          const styleObj = { ..._style };
          styleObj.styleCode = JSON.parse(styleObj.styleCode);
          styleObj.apply = true;
          let cd = "";
          if (styleObj.tenantId === "*" && styleObj.coCd === "*") {
            cd = "standard";
          } else if (styleObj.coCd === "*") {
            cd = "tenant";
          } else {
            cd = "co";
          }
          StyleUtils.ApplyStyle(dispatch, styleObj, `style_${cd}`);
        }
      }
    });
    setConnectionInfo(User.getConnection(workspace.tenantMstId) || {});
    dispatch(setSidebarCollapse(false));
    dispatch(initActive());
    dispatch(initOutput());
    dispatch(initActivedEntity());
    dispatch(initDataModel());
    dispatch(initSetting());
    dispatch(initWorkflow());
    dispatch(initBreakpoint());
    dispatch(initDebug());
    setIsLoading(false);
    refreshFormatList();
    InitEventBuilderBlockList();
  };

  /**
   * 특정 Master code에 해당되는 Code list를 filtering한다.
   * @param {String} codeMstCd
   * @param {boolean} master 정보 포함 여부 (default false)
   * @returns {Array} code list
   */
  const getCodeList = (codeMstCd, containMst) => {
    const codeDtl = codes.filter((code) => code.codeMstCd === codeMstCd);
    return ArrayUtils.isEmpty(codeDtl)
      ? []
      : containMst === true
      ? codeDtl[0]
      : codeDtl[0].codeDtl;
  };

  /**
   * 특정 Master component에 해당되는 component list를 filtering한다.
   * @param {String} componentMstId
   * @returns {Array} component list
   */
  const getComponentList = (componentGroupCd, containMst) => {
    if (containMst === true) {
      return components.filter(
        (component) => component.componentGroupCd === componentGroupCd
      );
    } else {
      let filteredComps = [];
      components.forEach((component) => {
        if (
          component.componentGroupCd === componentGroupCd &&
          component.componentDtl
        ) {
          filteredComps = [...filteredComps, ...component.componentDtl];
        }
      });
      return filteredComps;
    }
  };

  /**
   * 대상 Component 상세 정보
   * @param {Sring} componentGroupCd
   * @param {*} componentDtlId
   * @returns
   */
  const getComponentInfo = (componentGroupCd, componentDtlId) => {
    let component = getComponentList(componentGroupCd, false);

    return ArrayUtils.isEmpty(component)
      ? {}
      : component[
          component.findIndex((data) => {
            return StringUtils.equalsIgnoreType(
              data.componentDtlId,
              componentDtlId
            );
          })
        ];
  };

  /**
   * Get Page component
   * @returns pageComponent
   */
  const getPageComponent = () => {
    let component = getComponentList("P", false);
    return ArrayUtils.isEmpty(component) ? null : component[0];
  };

  /**
   * 서비스 컴포넌트를 가져온다
   * default *
   * @param {String} moduleCd
   * @returns {Array}
   */
  const getSvcComponent = (moduleCd) => {
    let svcList = [];
    if (moduleCd) {
      svcList = [...serviceComponents].filter((_s) => _s.moduleCd === moduleCd);
    } else {
      svcList = [...serviceComponents];
    }

    return svcList;
  };
  const refreshSvcComponent = () => {
    ComponentService.getServiceComponentList(
      {
        appId: workspace.appId,
      },
      (res) => {
        setServiceComponents(res.data);
      }
    );
  };

  /**
   * 특정 Master code에 해당되는 Code list를 filtering한다.
   * @param {String} codeMstCd
   * @param {boolean} master 정보 포함 여부 (default false)
   * @returns {Array} code list
   */
  const refreshFormatList = () => {
    // UNIERP 일때aks 동작하도록
    if (workspace.appGroupCd === Enums.AppType.UNIERP) {
      const deployConnection = User.getConnection(workspace.tenantMstId);
      if (!deployConnection?.token) {
        return;
      }

      let params = { ...deployConnection, appGroupCd: workspace.appGroupCd };
      CodeService.getNumberFormatList(params, (res) => {
        setFormatList(res.data);
      });
    }
  };

  /**
   * 사용자가 사용가능한 APP목록만 보여준다.
   * @param {*} appIds
   * @return {[Object]}
   */
  const getAvailableAppList = () => {
    if (StringUtils.equalsIgnoreCase(User.getAuthType(), "S")) {
      const availList = [...AppList];
      for (const avail of availList) {
        avail.deployable = true;
      }
      return availList;
    } else {
      const appIds = User.selectAvailableApps();
      const tmpAppList = [...AppList];
      const availList = tmpAppList.filter(
        (a) =>
          appIds.indexOf(a.appId) > -1 ||
          CommonUtils.getAppConfig(a, "appAccessibleYn") === "Y"
      );
      for (const avail of availList) {
        if (StringUtils.equalsIgnoreCase(User.getAuthType(avail.appId), "AM")) {
          avail.deployable = true;
        } else {
          avail.deployable = false;
        }
      }
      return availList;
    }
  };

  /**
   * 이전에 접속한 Workspace 설정하는 함수
   * Local Storage 정리
   * @param {*} workspace
   * @returns
   */
  const setPrevListShortCut = (workspace) => {
    if (!workspace) return false;
    const prevWorkspaces = LocalService.get(
      Enums.LocalStorageName.WORKSPACE_HISTORY
    );
    if (
      prevWorkspaces &&
      StringUtils.equalsIgnoreType(prevWorkspaces.userId, User.getId())
    ) {
      const prevList = [...prevWorkspaces.list];
      const dupIndex = prevList.findIndex((w) =>
        StringUtils.equalsIgnoreType(w.tenantMstId, workspace.tenantMstId)
      );
      if (dupIndex > -1) prevList.splice(dupIndex, 1);
      if (prevList.length > 4) prevList.splice(-1);

      prevWorkspaces.list = [workspace, ...prevList];
      LocalService.set(
        Enums.LocalStorageName.WORKSPACE_HISTORY,
        prevWorkspaces
      );
    } else {
      LocalService.set(Enums.LocalStorageName.WORKSPACE_HISTORY, {
        userId: User.getId(),
        list: [workspace],
      });
    }
  };

  /**
   * 로컬스토리지 초기화
   * 스토리지 flag가 없거나 false 이면 초기화 시켜버림
   */
  const initialLocalStorage = () => {
    //2024-11-04 패치시 데이터 초기화
    const patchCode = "20241105Patch";
    const patchInfo = window.localStorage.getItem(
      Enums.LocalStorageName.STORAGE_INIIALIZE
    );

    if (!StringUtils.equalsIgnoreCase(patchInfo, patchCode)) {
      Message.confirm({
        title: "Patch Notice",
        desc: (
          <>
            With the update to the app configuration settings, user assistant
            settings have been reset.
            <br />
            [Patch No : {patchCode}]
          </>
        ),
      });
      window.localStorage.clear();
      window.localStorage.setItem(
        Enums.LocalStorageName.STORAGE_INIIALIZE,
        patchCode
      );
    }
  };

  useEffect(() => {
    initialLocalStorage();
    if (!SessionUtils.LoggedCheck()) {
      navigate(Enums.BuilderPath.LOGIN);
    } else {
      initWijmoLicenseKey();
      initAppContext().then(() => {
        if (!ObjectUtils.isEmpty(workspace)) {
          initAppContextAfterWorkspaceSet();
        } else {
          const autoPath = User.getConfiguration(
            Enums.ConfigurationDefine.AUTO_PATH
          );
          //리다이렉트로 오는 경우 로컬 스토리지 정보를 받아서 바로 이동
          const redirectPath = LocalStorageService.get(
            Enums.LocalStorageName.WORKSPACE
          );
          if (redirectPath) {
            WorkspaceCallback(redirectPath.workspace);
          } else if (autoPath) {
            //아이디가 같으면 기본 설정 경로로 이동
            //App Config 동기화
            AppService.getAppConfig(autoPath, (res) => {
              if (!autoPath.config) autoPath.config = {};
              autoPath.config = res.data;
              WorkspaceCallback(autoPath);
            });
          } else {
            openWorkspacePopup();
            setIsLoading(false);
          }
        }
      });
    }
  }, [workspace]);

  /**
   *
   * @param {*} workspace
   */
  const WorkspaceCallback = (workspace) => {
    setPrevListShortCut(workspace);
    UIReduxHelper.setWorkspace(dispatch, workspace);
  };
  /**
   * 워크스페이스 설정 팝업 오픈
   * @returns
   */
  const openWorkspacePopup = () => {
    const popupDup = document.getElementById("_Modal_workspacePopup");
    if (popupDup) {
      return false;
    }
    const goToSetting = () => {
      navigate(Enums.BuilderPath.SETTING);
      Popup.close();
    };
    const options = {
      effect: Popup.ScaleUp, //Effect.SlideFromTop(default)를 Effect.ScaleUp 로 변경
      style: {
        content: {
          width: "950px", //popup의 크기를 50% (default 60%)
        },
      },
    };
    Popup.open(
      <WorkspacePopup
        programId={"workspacePopup"}
        callback={WorkspaceCallback}
        goToSetting={goToSetting}
      />,
      options
    );
  };

  /**
   * 연결 설정 성공 후 콜백
   * @param {*} data
   * @param {*} cb
   */
  const callbackConnectionFnc = (data, cb) => {
    if (data.connectionType === "proxy") {
      ConnectionService.connect(
        data,
        (res) => {
          const { flag, text } = ConnectionService.reponseValidator(
            res,
            workspace.tenantMstId
          );
          if (flag) {
            const { connectionInfo, ...otherInfo } = res.data;

            const userConnection = {
              ...connectionInfo,
              ...otherInfo,
              userId: data.systemUserId,
            };
            User.setConnection(workspace.tenantMstId, userConnection);
            setIsConnecting(false);

            setConnectionInfo(userConnection);
            let newList = [...connectionList];
            if (!StringUtils.isEmpty(userConnection.connectionId)) {
              newList.splice(
                newList.findIndex(
                  (_c) => _c.connectionId === data.connectionId
                ),
                1
              );
            }
            setConnectionList([userConnection, ...newList]);

            /* 연결 결과 정보 connectionResult */
            /**
             * tenantId
             * coCd
             * host
             * token
             * userId -> unierp
             * protocol
             * step
             * otpKey
             *
             */

            refreshFormatList();

            Message.alert(
              "Connect and saved successfully.",
              Enums.MessageType.SUCCESS
            );
            Popup.close();
            if (
              userConnection &&
              !StringUtils.isEmpty(userConnection.patchType)
            ) {
              Message.confirm(
                "Connected server has Framework Patch Info. Would you like to Patch?",
                () => {
                  ConnectionService.patchSoftware(userConnection, (res) => {
                    cb();
                    Message.alert(
                      "Framework Patch Completed Successfully",
                      MessageType.SUCCESS
                    );
                  });
                }
              );
            } else {
              cb();
            }
          } else {
            cb();
          }
        },
        (err) => {
          console.log(err);
          cb();
        }
      );
    } else {
      // return console.log(data);
      ConnectionService.directConnect(
        data,
        (connectRes) => {
          const { flag, text } = ConnectionService.reponseValidator(
            connectRes.data,
            workspace.tenantMstId
          );
          if (flag) {
            ConnectionService.save(
              data,
              (saveResponse) => {
                const userConnection = {
                  ...saveResponse.data,
                  ...connectRes.data.data,
                  userId: data.systemUserId,
                };
                User.setConnection(workspace.tenantMstId, userConnection);
                setIsConnecting(false);

                setConnectionInfo(userConnection);
                let newList = [...connectionList];
                if (!StringUtils.isEmpty(userConnection.connectionId)) {
                  newList.splice(
                    newList.findIndex(
                      (_c) => _c.connectionId === data.connectionId
                    ),
                    1
                  );
                }
                setConnectionList([userConnection, ...newList]);
                refreshFormatList();
                Message.alert(
                  "Connected and Saved Successfully.",
                  Enums.MessageType.SUCCESS
                );
                Popup.close();
              },
              cb
            );
          } else {
            Message.alert(text, Enums.MessageType.ERROR);
            cb();
          }
        },
        (err) => {
          console.log(err);
          cb();
        }
      );
    }
  };

  /**
   * 연결 정보 삭제
   * @param {*} e
   * @param {*} data
   */
  const deleteConnectionFnc = (e, data) => {
    if (e) e.preventDefault();
    ConnectionService.delete(
      data,
      (res) => {
        const { data } = res;
        Message.alert(data.message, Enums.MessageType.INFO);
        Popup.close();
        ConnectionService.getConnectionList({}, (res) => {
          if (!res.isError) {
            setConnectionList(res.data.content);
          }
        });
      },
      (error) => {
        console.log(error);
        Message.alert(
          "Error occured while processing.",
          Enums.MessageType.ERROR
        );
      }
    );
  };

  /**
   * 연결 종료
   * @param {*} e
   */
  const disconnection = (e) => {
    e.preventDefault();
    User.disconnect(workspace.tenantMstId);
    setConnectionInfo();
  };

  /**
   * 연결 설정 팝업 오픈
   */
  const connectionPopupOpen = (_connection) => {
    //팝업창 열기
    const popupTitle = "Connection Setting";
    const options = {
      effect: Popup.ScaleUp, //Effect.SlideFromTop(default)를 Effect.ScaleUp 로 변경
      style: {
        content: {
          width: "640px", //popup의 크기를 50% (default 60%)
        },
      },
    };
    Popup.open(
      <ConnectPopup
        title={popupTitle}
        callbackFnc={callbackConnectionFnc}
        connection={_connection}
        deleteConnectionFnc={deleteConnectionFnc}
        disconnection={disconnection}
        programId={"connectionPopup"}
        workspace={workspace}
        connectionList={connectionList}
        contextFormat={contextFormat}
      />,
      options
    );
  };

  /**
   * 전체 모듈에 등록된 기본스타일 적용
   */
  const setBuilderDefaultStyle = () => {
    SettingService.getBuidlerDefaultStyle({ builderDefaultYn: "Y" }, (res) => {
      if (res.data) {
        const styleObj = { ...res.data };
        styleObj.styleCode = JSON.parse(styleObj.styleCode);
        styleObj.apply = true;
        StyleUtils.ApplyDefaultStyle(styleObj, "BuilderDefaultStyle");
      }
    });
  };

  /**
   * Event Builder Block 초기화
   */
  const blockInit = (blockList) => {
    if (!eventHandler.isInit) {
      const jsonGenerator = new Blockly.Generator("JSON");
      jsonGenerator.scrub_ = function (block, code, thisOnly) {
        const nextBlock =
          block.nextConnection && block.nextConnection.targetBlock();
        if (nextBlock && !thisOnly) {
          return code + jsonGenerator.blockToCode(nextBlock);
        }
        return code;
      };

      jsonGenerator.forBlock["readOnlyTextBlock"] = function (block) {
        const textValue = block.getFieldValue("readOnlyInput1");
        const code = textValue[0];
        return code;
      };

      const blockObjectBeforeDefine = blockList.map((block) => {
        if (!StringUtils.isEmpty(block.blockOutput)) {
          jsonGenerator.forBlock[block.blockName] = eval(
            "(" + block.blockOutput + ")"
          );
        }
        Blockly.Msg[StringUtils.toUpperCase(block.blockName) + "_TITLE"] =
          block.blockName;

        javascriptGenerator.forBlock[block.blockName] = eval(
          "(" + block.blockOutput + ")"
        );

        return {
          type: block.blockName,
          message0: block.blockTitle,
          args0: JSON.parse(block.blockPropty),
          tooltip: block.blockDesc,
          ...JSON.parse(block.blockOption),
        };
      });
      //블럭 설정 정의
      const block = Blockly.common.createBlockDefinitionsFromJsonArray(
        blockObjectBeforeDefine
      );
      //블럭 정의
      Blockly.common.defineBlocks(block);
      // 블럭 Mutator 정의
      BlockCallBackMutator(blockList);
      dispatch(setIsInitEventWorkspace(true));
    }
  };

  if (isLoading) {
    return (
      <AppContext.Provider value={{ loading: "Loading..." }}>
        <StyledContentLoader>
          {React.Children.map(children, (child) => {
            if (child.props.applyLoader === true) {
              return child;
            }
          })}
        </StyledContentLoader>
        <ProgressSpinner />
      </AppContext.Provider>
    );
  } else {
    return (
      <AppContext.Provider
        value={{
          dataModel: {
            list: dataModels,
            type: dataModelType,
            searchTerm: dataModelSearchTerm,
            setList: setDataModels,
            setType: setDataModelType,
            setSearchTerm: setDataModelSearchTerm,
          },
          code: { codes, getCodeList },
          workspace: {
            Info: workspace,
            openPopup: openWorkspacePopup,
            setPrevList: setPrevListShortCut,
          },
          application: {
            list: AppList,
            setList: setAppList,
            availableList: getAvailableAppList(),
          },
          connection: {
            Info: connectionInfo,
            setInfo: setConnectionInfo,
            list: connectionList,
            setList: setConnectionList,
            openPopup: connectionPopupOpen,
            isConnecting,
            setIsConnecting,
          },
          // workflow: {
          //   deploy: workflowDeploy,
          //   deployPopup: workflowDeployPopupOpen,
          // },
          component: {
            components,
            getComponentList,
            getComponentInfo,
            getPageComponent,
          },
          serviceComponent: {
            serviceComponents,
            getSvcComponent,
            refreshSvcComponent,
          },
          format: {
            list: formatList,
            refreshFormatList,
          },
          eventBuilder: {
            blockListContainer: blockList,
          },
        }}
      >
        {children}
      </AppContext.Provider>
    );
  }
};

export default AppContextProvider;
