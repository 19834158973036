import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import CommonUtils, {
  ObjectUtils,
  StringUtils,
} from "components/common/utils/CommonUtils";
import React, { useEffect } from "react";
import { Button, ButtonGroup } from "react-bootstrap";
import { HiOutlineClipboardCopy } from "react-icons/hi";
import { MdExpandMore } from "react-icons/md";
import { RiFileCopyLine } from "react-icons/ri";
import styled from "styled-components";
import CodeMirror from "@uiw/react-codemirror";
import { json } from "@codemirror/lang-json";
import { useState } from "react";
import Message from "components/common/Message";
import { useDispatch, useSelector } from "react-redux";
import DataModelService from "services/datamodel/DataModelService";
import { Enums } from "components/builder/BuilderEnum";
import { setDataModelEditable } from "page/dataModel/DataModelList";
import { useNavigate } from "react-router-dom";
import {
  activateComponent,
  updateDataModel,
} from "components/builder/entity/reducers/EntityBuilderAction";
import { initCommand } from "components/builder/ui/reducers/CommandAction";

const AccordionHeader = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 50px;
  gap: 5px;
`;
const AccordionInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
`;
const AccordionContentsWrapper = styled.div`
  width: 100%;
  max-height: 600px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 5px;
`;
const EditorViewer = styled.div`
  max-height: 500px;
  width: 73vw;
  overflow-y: auto;
`;
const ControllButton = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  & .tab {
    display: flex;
    align-items: center;
    gap: 20px;
    font-weight: 500;
  }
`;

function DataModelHistoryAccordionContents({
  dataModelHistory: {
    dataModelHistoryId,
    commitComment,
    updtUserId,
    insertDt,
    appApplyType,
  },
}) {
  const [tabKey, setTabKey] = useState("dataModel");
  const DMObject = useSelector((state) => state.outputENT.output);
  const [isExpanded, setIsExpanded] = useState(false);
  const [dataModelContent, setDataModelContent] = useState("");
  const [dataModelUsrTnx, setDataModelUsrTnx] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (StringUtils.isEmpty(dataModelContent) && isExpanded) getContentDetail();
  }, [isExpanded]);

  /**
   * 코드 상세 내역 호출
   */
  const getContentDetail = () => {
    DataModelService.getDataModelHistory({ dataModelHistoryId }, (res) => {
      const { dataModelContent: dmc, dataModelUsrTnx: tnx } = res.data;
      setDataModelContent(dmc);
      setDataModelUsrTnx(tnx);
    });
  };

  /**
   * 클립보드로 복사
   */
  const onCopyCodeToClipBoard = () => {
    const _open = () => {
      const dataModel = setDataModelEditable(JSON.parse(dataModelContent));
      dispatch(updateDataModel(dataModel));
      dispatch(initCommand());
      dispatch(activateComponent(dataModel));
      navigate(
        Enums.BuilderPath.ENTITY.MAIN + "/" + Enums.BuilderPath.ENTITY.CREATE
      );
    };

    if (ObjectUtils.isEmpty(DMObject)) {
      Message.confirm(
        "The data model you are working on will be closed. Do you want to proceed?",
        () => {
          _open();
        }
      );
    } else {
      _open();
    }
  };

  /**
   * 데이터 모델 복원
   */
  const onCopyCodeToCurrentEditor = () => {
    Message.confirm(
      <>
        Overwriting the Data Model.
        <br /> The latest existing saved data will be overwritten. Do you want
        to proceed?
      </>,
      () => {
        const deleteDate = (obj) => {
          delete obj.updtDt;
          delete obj.insertDt;
        };
        const dmData = JSON.parse(dataModelContent);
        const body = {
          ...dmData,
          releaseCommentYn: "N",
          appApplyType: "N",
          useYn: "Y",
          newDataModel: "N",
        };

        //자식이 배열만 있는지 JSON이 혼재되어있는지 명확한 구분 불가
        body.dataModelEntities = body.dataModelEntities.map((entity) => {
          const obj = { ...entity };
          deleteDate(obj);
          //내용이 없으면 null로 진행
          obj.dataModelEntityFields = entity.dataModelEntityFields.map(
            (field) => {
              const fieldObj = { ...field };
              deleteDate(fieldObj);
              return fieldObj;
            }
          );
          return obj;
        });
        //날짜데이터 파싱 오류 때문에 해당 오브젝트 삭제
        deleteDate(body);
        DataModelService.saveDataModel(body, (res) => {
          if (!res.isError) {
            Message.alert("Overwrite Successfully.", Enums.MessageType.SUCCESS);
          }
        });
      }
    );
  };

  return (
    <Accordion
      onChange={(e, expanded) => {
        setIsExpanded(expanded);
      }}
    >
      <AccordionSummary
        expandIcon={<MdExpandMore />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <AccordionHeader>
          <span>{commitComment}</span>
          <AccordionInfo>
            <div>
              {updtUserId} / {CommonUtils.getTZTime(insertDt).fromNow()}
            </div>
            <div>
              <ButtonGroup
                variant="outlined"
                aria-label="outlined button group"
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={appApplyType === "Y" ? true : false}
                      onClick={(e) => e.stopPropagation()}
                    />
                  }
                  label="Apply in App"
                />
              </ButtonGroup>
            </div>
          </AccordionInfo>
        </AccordionHeader>
      </AccordionSummary>
      <AccordionDetails>
        <div></div>
        <AccordionContentsWrapper>
          <ControllButton>
            <div className="tab">
              <ButtonGroup>
                <Button
                  id="dataModel"
                  variant={
                    tabKey === "dataModel" ? "primary" : "outline-primary"
                  }
                  onClick={(e) => setTabKey(e.target.id)}
                >
                  Data Model
                </Button>
                {dataModelUsrTnx && (
                  <Button
                    id="tnx"
                    variant={tabKey === "tnx" ? "success" : "outline-success"}
                    onClick={(e) => setTabKey(e.target.id)}
                  >
                    User Transaction
                  </Button>
                )}
              </ButtonGroup>
              Save Date: {CommonUtils.getDate(insertDt, "dateTime")}
            </div>
            <ButtonGroup>
              <Button
                onClick={onCopyCodeToClipBoard}
                style={{ fontSize: "12px" }}
                variant="outline-primary"
              >
                <RiFileCopyLine fontSize={"15px"} /> Open
              </Button>
              <Button
                onClick={onCopyCodeToCurrentEditor}
                style={{ fontSize: "12px" }}
                variant="outline-success"
              >
                <HiOutlineClipboardCopy fontSize={"15px"} /> Overwrite
              </Button>
            </ButtonGroup>
          </ControllButton>

          <EditorViewer>
            {StringUtils.equalsIgnoreCase(tabKey, "dataModel")
              ? !StringUtils.isEmpty(dataModelContent) && (
                  <CodeMirror
                    value={JSON.stringify(
                      JSON.parse(dataModelContent),
                      null,
                      2
                    )}
                    className="source-container"
                    height="500px"
                    extensions={[json(true)]}
                    autoFocus={false}
                    editable={false}
                  />
                )
              : !StringUtils.isEmpty(dataModelUsrTnx) && (
                  <CodeMirror
                    value={JSON.stringify(JSON.parse(dataModelUsrTnx), null, 2)}
                    className="source-container"
                    height="500px"
                    extensions={[json(true)]}
                    autoFocus={false}
                    editable={false}
                  />
                )}
          </EditorViewer>
        </AccordionContentsWrapper>
      </AccordionDetails>
    </Accordion>
  );
}

export default DataModelHistoryAccordionContents;
