import Modal from "components/common/modal/UModal";
import UModalTemplate from "components/common/modal/UModalTemplate";
import React from "react";
import { useState } from "react";
import { Form } from "react-bootstrap";

/**
 * 서비스 카테고리 이름 업데이트 팝업
 * @param {*} param0
 * @returns
 */
function SvcChangePopup({ component, ...props }) {
  const [changedName, setChangedName] = useState(
    component.svcComponentMstNm || component.svcComponentDtlNm
  );

  const onClickConfirm = (e) => {
    if (props.callback) {
      props.callback(changedName);
    }
  };

  return (
    <Modal>
      <Modal.Header title="이름 변경" />
      <Modal.Body>
        <UModalTemplate>
          <Form.Control
            value={changedName}
            onChange={(e) => setChangedName(e.target.value)}
            onKeyDown={(e) => (e.key === "Enter" ? onClickConfirm : e)}
          />
        </UModalTemplate>
      </Modal.Body>
      <Modal.Footer>
        <Modal.Footer.Button onClick={onClickConfirm}>확인</Modal.Footer.Button>
      </Modal.Footer>
    </Modal>
  );
}

export default SvcChangePopup;
