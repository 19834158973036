import React, { useState, useCallback, useContext } from "react";
import { Button, ButtonGroup, Form, ToggleButton } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { Scrollbar } from "smooth-scrollbar-react";
import Popup from "components/common/Popup";
import ViewportEditPopup from "page/popup/mobile/ViewportEditPopup";

import { AppContext } from "components/common/AppContextProvider";
import * as Enums from "components/builder/BuilderEnum";
import {
  ArrayUtils,
  StringUtils,
  JsonUtils,
  ObjectUtils,
} from "components/common/utils/CommonUtils";

import ViewportService from "services/mobile/ViewportService";

import * as actions from "components/builder/ui/reducers/UIBuilderAction";
import UIEditorSource from "components/builder/ui/editor/UIEditorSource";
import UIEditorDNDContainer from "components/builder/ui/editor/UIEditorDNDContainer";
import UIEditorChildRender from "components/builder/ui/editor/UIEditorChildRender";
import UITemplateHelper from "components/builder/ui/editor/helper/UITemplateHelper";
import * as Event from "components/builder/ui/editor/handler/UIEditorEventHandler";
import UIEditorFooter from "components/builder/ui/editor/render/UIEditorFooter";

import Page from "components/builder/mobile/mobileComponents/layout/Page";
// import Page from "components/builder/ui/uiComponents/layout/Page";
// import { EditorPanel } from "components/builder/ui/uiComponents/UIComponentStyle";
import {
  EditorPanel,
  FilterPanel,
} from "components/builder/mobile/mobileComponents/MobileComponentStyle";
import MobileCommandButton from "components/builder/mobile/MobileCommandButton";
import { useRef, useEffect } from "react";
import { IoMdSettings } from "react-icons/io";
import { FaTimes } from "react-icons/fa";
import { FiRotateCw } from "react-icons/fi";
import { BiArrowBack } from "react-icons/bi";
import {
  setAppLayoutList,
  setMobileScreenWidth,
  setMobileScreenHeight,
} from "components/builder/mobile/reducers/MobileAction";
import produce from "immer";
import Message from "components/common/Message";
import LayoutSettingService from "services/mobile/LayoutSettingService";
import MobileBuilderPreview from "page/builder/mobile/MobileBuilderPreview";

const MobileEditor = (props) => {
  const dispatch = useDispatch();
  const workspace = useSelector((state) => state.workspace);
  const [tabType, setTabType] = useState(
    StringUtils.defaultString(props.tabType, "E")
  );

  const [viewportList, setViewportList] = useState([]);
  const [screenWidth, setScreenWidth] = useState();
  const [screenHeight, setScreenHeight] = useState();
  const [selectViewportId, setSelectViewportId] = useState();
  // const output = useSelector((state) => state.outputUI);
  const statOutput = useSelector((state) => state.outputUI.output);
  const mobileInfo = useSelector((state) => state.mobile);
  const [layoutList, setLayoutList] = useState();

  useEffect(() => {
    getViewportList();
    getAppLayoutInfo();
  }, []);

  useEffect(() => {
    if (
      statOutput.page.propertyValue?.programType ===
      Enums.MobileProgramType.TOP_NAV
    ) {
      setScreenHeight(mobileInfo.screenHeight);
      // } else {
      //   selectViewPort([]);
    }
  }, [mobileInfo, statOutput]);

  const getViewportList = () => {
    let params = { appId: workspace.appId };
    ViewportService.getViewportList(params, (res) => {
      setViewportList(res.data);
      selectViewPort(res.data);
    });
  };

  /**
   * 기존 저장된 appLayout 데이터 불러오기
   */
  const getAppLayoutInfo = () => {
    LayoutSettingService.getCustomProgramList(
      { appId: workspace.appId },
      (res) => {
        dispatch(setAppLayoutList(res.data));
      }
    );
  };

  /**
   * 선택된 viewport 찾기
   * 뷰포트에서 수정된 값을 바로 반영하기 위한 로직
   * @param {*} paramList
   */
  const selectViewPort = (paramList) => {
    let vpList = [...paramList];
    if (ArrayUtils.isEmpty(vpList) && !ArrayUtils.isEmpty(viewportList)) {
      vpList = viewportList;
    }
    for (const viewport of vpList) {
      if (
        ObjectUtils.isEmpty(selectViewportId) ||
        StringUtils.equalsIgnoreType(viewport.viewportId, selectViewportId)
      ) {
        setSelectViewportId(viewport.viewportId);
        setScreenWidth(viewport.screenWidth ? viewport.screenWidth : 0);

        if (
          statOutput.page.propertyValue?.programType ===
          Enums.MobileProgramType.TOP_NAV
        ) {
          setScreenHeight(85);
        } else {
          setScreenHeight(viewport.screenHeight ? viewport.screenHeight : 0);
          dispatch(
            setMobileScreenHeight(
              viewport.screenHeight ? viewport.screenHeight : 0
            )
          );
        }

        dispatch(
          setMobileScreenWidth(viewport.screenWidth ? viewport.screenWidth : 0)
        );
        break;
      }
    }
  };

  const [localSettings, setLocalSettings] = useState({
    lineDisplay: true,
    dropZoneDisplay: true,
    titleDisplay: true,
    toolbarDisplay: true,
    watermarkDisplay: true,
  });

  const componentContext = useContext(AppContext).component;

  /* template 용 표준 base components - e.g) page, form, row, col... */
  const templateComponents =
    UITemplateHelper.getTemplateComponents(componentContext);
  /*template 용 표준 base components - Page component */
  const pageTemplate = UITemplateHelper.getTemplate(
    templateComponents,
    Enums.ComponentType.PAGE
  );

  const pageProgramId =
    ObjectUtils.isEmpty(statOutput["page"]) ||
    ObjectUtils.isEmpty(statOutput["page"]["propertyValue"])
      ? ""
      : statOutput["page"]["propertyValue"]["programId"];

  const activedComponent = useSelector(
    (state) => state.activedUIComponent.component
  );

  const components = useContext(AppContext).component.getComponentList("B");
  const pageCompnent = useContext(AppContext).component.getPageComponent();

  const editorPanelRef = useRef();
  const filterPanelRef = useRef();

  //ESC 및 DELETE 키다운 이벤트 적용
  useEffect(() => {
    // editorPanelRef.current
    document.addEventListener("keydown", onKeyDownEsc);
    return () => {
      document.removeEventListener("keydown", onKeyDownEsc);
    };
  }, [activedComponent]);

  /**
   * change editor local settings
   * @param {*} key
   * @param {*} value
   */
  const changeSettings = (key, value) => {
    setLocalSettings({ ...localSettings, ...{ [key]: value } });
  };

  /**
   * output을 update 한다.
   * @param {*} changedOutput
   */
  const updateOutputSource = (changedOutput) => {
    if (!ObjectUtils.isEmpty(activedComponent)) {
      const activedComponentNode = JsonUtils.findNode(
        changedOutput,
        "compId",
        activedComponent.compId
      );
      dispatch(actions.updateActivateProps(activedComponentNode.propertyValue));
      dispatch(actions.updateOutput(changedOutput));
    }
  };

  /**
   * Drag & drap handle
   */
  const handleDrop = useCallback(
    (dropZone, item, templateComponents, output) => {
      dropZone.workspace = workspace;
      // 모바일 default옵션 분기처리
      // react 불변성 때문에 produce 사용
      const newItem = produce(item, (draft) => {
        draft.isMobile = true;
      });
      Event.handleDrop(dropZone, newItem, templateComponents, output, dispatch);
    },
    [dispatch]
  );

  /**
   * Page click
   * @param {*} e
   */
  const onClick = (e) => {
    Event.handlePageClick(
      e,
      dispatch,
      activedComponent,
      statOutput,
      pageTemplate
    );
  };

  /**
   * Page Editor class
   * @returns
   */
  const getPageEditorClass = () => {
    let className = "page";
    Object.keys(localSettings).forEach(function (option) {
      if (localSettings[option] === false) {
        className += " none-" + option;
      }
    });

    return className;
  };

  /**
   * Page Filter class
   * @returns
   */
  const getPageFilterEditorClass = () => {
    let className = "page";
    Object.keys(localSettings).forEach(function (option) {
      if (localSettings[option] === false) {
        className += " none-" + option;
      }
    });

    return className;
  };

  /**
   * Page Penal Class
   * @returns
   */
  const getPagePanelClass = () => {
    let pageClass = "page-container";

    if (activedComponent.componentType === Enums.ComponentType.PAGE)
      pageClass += " active";

    if (!ObjectUtils.isEmpty(statOutput.page.propertyValue)) {
      /* popup */
      if (
        statOutput.page.propertyValue.programType ===
        Enums.ProgramType.POPUP_PROGRAM
      ) {
        const size = ObjectUtils.isEmpty(
          statOutput.page.propertyValue.popupOptions
        )
          ? "xl"
          : StringUtils.defaultString(
              statOutput.page.propertyValue.popupOptions.size,
              "xl"
            );
        pageClass +=
          " popup-container " + (size !== "fl" ? " modal-" + size : "");

        /* dashboard */
      } else {
        pageClass +=
          " page-type-" +
          StringUtils.defaultString(
            statOutput.page.propertyValue.programType,
            Enums.ProgramType.MENU_PROGRAM
          );
      }
    }
    return pageClass;
  };

  /**
   * Page Penal Class
   * @returns
   */
  const getPageFilterPanelClass = () => {
    let pageClass = "page-container";

    if (activedComponent.componentType === Enums.ComponentType.PAGE)
      pageClass += " active";

    if (!ObjectUtils.isEmpty(statOutput.page.propertyValue)) {
      pageClass +=
        " page-type-" +
        StringUtils.defaultString(
          statOutput.page.propertyValue.programType,
          Enums.ProgramType.MENU_PROGRAM
        );
      // }
    }
    return pageClass;
  };

  //Main page
  const pageChildCnt = ArrayUtils.isEmpty(statOutput["page"]["child"])
    ? 0
    : statOutput["page"]["child"].length;

  //footer
  const footerChildCnt =
    ObjectUtils.isEmpty(statOutput["page"]["footer"]) ||
    ArrayUtils.isEmpty(statOutput["page"]["footer"]["child"])
      ? 0
      : statOutput["page"]["footer"]["child"].length;

  //Filter page
  const filterChildCnt =
    ObjectUtils.isEmpty(statOutput["page"]["filter"]) ||
    ArrayUtils.isEmpty(statOutput["page"]["filter"]["child"])
      ? 0
      : statOutput["page"]["filter"]["child"].length;

  const pagePropertyValue = statOutput.page.propertyValue || {};
  // const [pagePropertyValue, setPagePropertyValue] = useState({});
  const programType = StringUtils.defaultString(
    pagePropertyValue.programType,
    "M"
  );

  /**
   * 키이벤트에서 사용하는,
   * 선택된 노드보다 상위로 포커싱 이동하는 로직
   * @param {*} e
   * @param {*} component
   */
  const _moveToParentsNode = (e, component) => {
    const compId = component?.compId;
    if (compId) {
      const parentNode = JsonUtils.findParentNodeByOnlyCompId(
        statOutput.page,
        compId
      );
      if (parentNode)
        Event.clickComponent(
          e,
          parentNode,
          components,
          activedComponent,
          dispatch
        );
    }
  };

  /**
   * 키다운 이벤트 전체
   * ESC 눌렀을 때 상위노드로 이동하는 로직
   * DELETE 눌렀을때 해당 노드 삭제하는 로직
   * @param {*} e
   */
  const onKeyDownEsc = (e) => {
    if (
      (editorPanelRef.current &&
        editorPanelRef.current.contains(document.activeElement)) ||
      document.activeElement === document.body
    ) {
      if (e && e.keyCode === 27) {
        if (!ObjectUtils.isEmpty(activedComponent)) {
          const { type } = activedComponent;
          let compId = activedComponent.compId;
          if (
            type &&
            StringUtils.includes(type, [
              Enums.ComponentType.GRID_CELL,
              Enums.ComponentType.GRID_HEADER,
            ])
          ) {
            //그리드 부모 찾아 주기
            compId = compId.split("-")[1];
          }
          _moveToParentsNode(e, { compId });
        }
      } else if (StringUtils.equalsIgnoreCase(e.key, "delete")) {
        Event.removeNode(
          e,
          activedComponent,
          statOutput,
          components,
          pageCompnent,
          dispatch
        );
        _moveToParentsNode(e, activedComponent);
      }
    }
  };

  const onChangeView = (e) => {
    for (const viewport of viewportList) {
      if (StringUtils.equalsIgnoreType(viewport.viewportId, e.target.value)) {
        setSelectViewportId(viewport.viewportId);
        setScreenWidth(viewport.screenWidth);
        dispatch(setMobileScreenWidth(viewport.screenWidth));
      }
    }
  };

  const onRotation = () => {
    if (
      !StringUtils.isEmpty(programType) &&
      programType === Enums.MobileProgramType.TOP_NAV
    ) {
      Message.alert(
        "상단 네비게이션은 돌릴 수 없습니다.",
        Enums.MessageType.WARN
      );
      return;
    }
    setScreenWidth(screenHeight);
    setScreenHeight(screenWidth);
    dispatch(setMobileScreenWidth(screenHeight));
    dispatch(setMobileScreenHeight(screenWidth));
  };

  const onClosePopup = () => {
    getViewportList();
  };

  const openViewportEditPopup = () => {
    Popup.open(
      <ViewportEditPopup
        appId={workspace.appId}
        callbackFnc={(e, d) => {
          Popup.close();
        }}
      />,
      {
        onCloseCallback: onClosePopup,
        style: { content: { width: "700px" } },
      }
    );
  };

  console.log("Mobile Editor refresh");
  return (
    <React.Fragment>
      <MobileCommandButton setTabType={setTabType} tabType={tabType} />
      <React.Fragment>
        <div className="viewport-tab">
          <Form.Select id="viewportSelect" size="sm" onChange={onChangeView}>
            {viewportList.map((viewport) => (
              <option key={viewport.viewportId} value={viewport.viewportId}>
                {viewport.deviceNm}
              </option>
            ))}
          </Form.Select>
          <input
            type="text"
            disabled
            id="width"
            className="mobile-viewport-width"
            value={screenWidth}
          />
          <FaTimes />
          <input
            type="text"
            disabled
            id="height"
            className="mobile-viewport-height"
            value={screenHeight}
          />
          <Button size="sm" onClick={onRotation} className="mx-2">
            <FiRotateCw />
          </Button>
          <Button size="sm" onClick={openViewportEditPopup}>
            <IoMdSettings />
          </Button>
        </div>
      </React.Fragment>
      {tabType === "E" ? (
        <React.Fragment>
          <div className="wrapper-mobile-edit-panel">
            <EditorPanel
              className={`mobile-edit-panel-area ${getPagePanelClass()}`}
              style={{ width: screenWidth, height: screenHeight }}
              programType={programType}
              pagePropertyValue={pagePropertyValue}
              id="editPage"
              onClick={onClick}
              // onKeyDown={onKeyDownEsc}
              // tabIndex={"0"}
              // style={{ outline: "none" }}
              ref={editorPanelRef}
            >
              <Page event="renderEditor" componentInfo={statOutput["page"]}>
                <div
                  id="editCanvas"
                  className={getPageEditorClass()}
                  onMouseOver={Event.handlePageMouseOver}
                  onMouseOut={Event.handlePageMouseOut}
                >
                  {pageChildCnt > 0
                    ? statOutput["page"]["child"].map((row, index) => {
                        const currentPath = `${index}`;
                        return (
                          <React.Fragment key={index}>
                            <UIEditorDNDContainer
                              data={{
                                location: Enums.ComponentType.PAGE,
                                rootLocation: Enums.ComponentType.PAGE,
                                path: currentPath,
                                childrenCount: pageChildCnt,
                              }}
                              onDrop={handleDrop}
                              path={currentPath}
                              templateComponents={templateComponents}
                            />
                            <UIEditorChildRender
                              key={row.compId}
                              data={row}
                              handleDrop={handleDrop}
                              templateComponents={templateComponents}
                              path={currentPath}
                              childrenCount={pageChildCnt}
                              rootLocation={Enums.ComponentType.PAGE}
                              // onKeyDown={onKeyDownEsc}
                            />
                          </React.Fragment>
                        );
                      })
                    : ""}
                  <UIEditorDNDContainer
                    data={{
                      location: Enums.ComponentType.PAGE,
                      rootLocation: Enums.ComponentType.PAGE,
                      path: `${pageChildCnt}`,
                      childrenCount: pageChildCnt,
                    }}
                    className={`${pageChildCnt === 0 ? "empty-child" : ""}`}
                    onDrop={handleDrop}
                    templateComponents={templateComponents}
                  />
                </div>
              </Page>
              <div
                className="modal-overlay"
                id="modalOverlay"
                style={{ width: screenWidth, height: screenHeight }}
              >
                <div className="modal-wrapper" id="modalWrapper" tabIndex="-1">
                  <FilterPanel
                    className={`mobile-filter-panel-area ${getPageFilterPanelClass()}`}
                    style={{ width: screenWidth * 0.8, height: screenHeight }}
                    id="filterPanel"
                    onClick={onClick}
                    // onKeyDown={onKeyDownEsc}
                    // tabIndex={"0"}
                    // style={{ outline: "none" }}
                    ref={filterPanelRef}
                    tabIndex="0"
                  >
                    {" "}
                    <Page
                      event="renderEditor"
                      componentInfo={statOutput["page"]["filter"]}
                    >
                      <div id="filterHeader" className={`filter-pannel-header`}>
                        <button
                          className={`filter-pannel-hidden`}
                          onClick={(e) => {
                            const modalOverlay =
                              document.getElementById("modalOverlay");
                            const modalWrapper =
                              document.getElementById("modalWrapper");

                            // filterPanel.style.display = 'none';
                            modalOverlay.style.visibility = "hidden";
                            modalWrapper.style.visibility = "hidden";
                            // filterPanel.classList.add("Animate");
                          }}
                        >
                          <BiArrowBack /> Filter
                        </button>
                      </div>

                      <div
                        id="filterCanvas"
                        className={getPageFilterEditorClass()}
                        onMouseOver={Event.handlePageMouseOver}
                        onMouseOut={Event.handlePageMouseOut}
                      >
                        {filterChildCnt > 0
                          ? statOutput["page"]["filter"]["child"].map(
                              (row, index) => {
                                const currentPath = `${index}`;
                                return (
                                  <React.Fragment key={index}>
                                    <UIEditorDNDContainer
                                      data={{
                                        location: Enums.ComponentType.FILTER,
                                        rootLocation:
                                          Enums.ComponentType.FILTER,
                                        path: currentPath,
                                        childrenCount: filterChildCnt,
                                      }}
                                      onDrop={handleDrop}
                                      path={currentPath}
                                      templateComponents={templateComponents}
                                    />
                                    <UIEditorChildRender
                                      key={row.compId}
                                      data={row}
                                      handleDrop={handleDrop}
                                      templateComponents={templateComponents}
                                      path={currentPath}
                                      childrenCount={filterChildCnt}
                                      rootLocation={Enums.ComponentType.FILTER}
                                      // onKeyDown={onKeyDownEsc}
                                    />
                                  </React.Fragment>
                                );
                              }
                            )
                          : ""}
                        <UIEditorDNDContainer
                          data={{
                            location: Enums.ComponentType.FILTER,
                            rootLocation: Enums.ComponentType.FILTER,
                            path: `${filterChildCnt}`,
                            childrenCount: filterChildCnt,
                          }}
                          className={`${
                            filterChildCnt === 0 ? "empty-child" : ""
                          }`}
                          onDrop={handleDrop}
                          templateComponents={templateComponents}
                        />
                      </div>
                    </Page>
                  </FilterPanel>
                </div>
              </div>
            </EditorPanel>
            {footerChildCnt > 0 ? (
              <UIEditorFooter
                style={{ width: screenWidth }}
                onDrop={handleDrop}
                pagePropertyValue={pagePropertyValue}
                templateComponents={templateComponents}
                child={statOutput["page"]["footer"]["child"]}
              />
            ) : (
              ""
            )}
          </div>
        </React.Fragment>
      ) : tabType === "S" ? (
        <UIEditorSource
          output={statOutput}
          updateOutputSource={updateOutputSource}
        />
      ) : (
        <MobileBuilderPreview
          output={statOutput}
          style={{ width: screenWidth, height: screenHeight }}
          updateOutputSource={updateOutputSource}
        />
      )}
    </React.Fragment>
  );
};
export default React.memo(MobileEditor);
