import { Enums } from "components/builder/BuilderEnum";
import BuilderHeaderComponent from "components/builder/BuilderHeaderComponent";
import { updateService } from "components/builder/workflow/reducer/WorkflowAction";
import WorkflowHeader from "components/builder/workflow/WorkflowHeader";
import { AppContext } from "components/common/AppContextProvider";
import Message from "components/common/Message";
import Popup from "components/common/Popup";
import ObjectUtils from "components/common/utils/ObjectUtils";
import StringUtils from "components/common/utils/StringUtils";
import produce from "immer";
import ContentsLoading from "page/common/ContentsLoading";
import ServiceDeployPopup from "page/popup/workflow/ServiceDeployPopup";
import { createContext, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation } from "react-router-dom";
import WorkflowService from "services/workflow/WorkflowService";

export const WorkflowContext = createContext({
  deploy: () => {},
  deployPopup: () => {},
  bundle: {
    bundleStartNode: {},
    bundleEndNode: {},
    bundlingMode: false,
    setBundleStartNode: () => {},
    setBundleEndNode: () => {},
    setBundlingMode: () => {},
    bundleNodeList: {},
    setBundleNodeList: () => {},
    selectedBundle: {},
    setSelectedBundle: () => {},
    openBundleDetailPopup: false,
    setOpenBundleDetailPopup: () => {},
    bundleModalExpand: false,
    setBundleModalExpand: () => {},
  },
  debug: {
    debugExpressionMode: "",
    setDebugExpressionMode: () => {},
  },
});

const Workflow = ({ ...props }) => {
  const dispatch = useDispatch();
  const workspace = useSelector((state) => state.workspace);
  const workflow = useSelector((state) => state.workflow);

  const {
    connection: { openPopup: connectionPopupOpen, Info: connection },
  } = useContext(AppContext);
  const location = useLocation();
  const [currentPath, setCurrentPath] = useState();

  const [bundleStartNode, setBundleStartNode] = useState({});
  const [bundleEndNode, setBundleEndNode] = useState({});
  const [bundlingMode, setBundlingMode] = useState(false);
  //compId : true 형태로 저장해서 useRender에서 사용함
  const [bundleNodeList, setBundleNodeList] = useState({}); //번들링시 선택된 노드 목록
  const [selectedBundle, setSelectedBundle] = useState({}); //선택된 번들 -> 번들 정보 수정시 사용
  const [openBundleDetailPopup, setOpenBundleDetailPopup] = useState(false); //번들 디테일(모달) 오픈 여부
  const [debugExpressionMode, setDebugExpressionMode] = useState("");
  const [bundleModalExpand, setBundleModalExpand] = useState(false);

  useEffect(() => {
    const CurrentPath = location.pathname.split("/")[2];
    setCurrentPath(CurrentPath);
    return () => {};
  }, [location]);

  // Remove the resizeObserver error
  useEffect(() => {
    const errorHandler = (e) => {
      if (
        e.message.includes(
          "ResizeObserver loop completed with undelivered notifications" ||
            "ResizeObserver loop limit exceeded"
        )
      ) {
        const resizeObserverErr = document.getElementById(
          "webpack-dev-server-client-overlay"
        );
        if (resizeObserverErr) {
          resizeObserverErr.style.display = "none";
        }
      }
    };
    window.addEventListener("error", errorHandler);

    return () => {
      window.removeEventListener("error", errorHandler);
    };
  }, []);

  /**
   * Workflow 디플로이 팝업
   * 헤더와 커맨더 버튼에서 동시사용하기 때문에 컨텍스트로 이전
   * @returns
   */
  const workflowDeployPopupOpen = () => {
    if (ObjectUtils.isEmpty(connection)) {
      Message.alert(
        "Setting up connections before deployment.",
        Enums.MessageType.INFO
      );
      return connectionPopupOpen();
    }

    /**
     * 새 워크플로우가 아니고 workflow.serviceInfo.insertUserId 가 없는경우에는 POPUP OPEN
     * 이미 다른 모듈에 배포된 워크플로우를 재배포시 저장할 수 없도록 오류 메시지 추가
     */

    if (
      !StringUtils.isEmpty(workflow.serviceInfo.insertUserId) &&
      !StringUtils.equalsIgnoreCase(
        workflow.serviceInfo.moduleCd,
        workspace.moduleCd
      )
    ) {
      Message.alert(
        "Workflows that have been deployed from other modules cannot be redeployed.",
        Enums.MessageType.WARN
      );
      return;
    } else {
      Popup.open(
        <ServiceDeployPopup
          callbackFnc={workflowDeploy}
          workflow={workflow}
          workspace={workspace}
        />,
        {
          effect: Popup.ScaleUp,
          style: {
            content: {
              width: "600px",
            },
          },
        }
      );
    }
  };

  /**
   * 워크플로우 디플로이
   * @param {*} body
   * @param {*} cb
   * @returns
   */
  const workflowDeploy = (body, cb) => {
    if (!connection?.token) {
      if (cb) cb();
      return Message.alert(
        "Authentication server token is not validated.\n Please reconnect.",
        Enums.MessageType.ERROR
      );
    }
    //userId가 deploy서버 connectionID랑 param 명이 같기에 별도로 분리
    const { userId, ...connectionInfo } = connection;

    if (connection.connectionType === "direct") {
      // if (!body.serviceUid) {
      WorkflowService.saveService(
        body,
        (res) => {
          if (!res.isError) {
            const resData = produce(res.data, (draft) => {
              draft.prevService = workflow.prevService;
            });
            dispatch(updateService(resData, workflow));
            WorkflowService.localStorageSave(res.data);

            const dBody = {
              ...res.data,
              resourceWf: res.data.serviceContent,
              connectionInfo,
              userId,
            };
            WorkflowService.deployDirectService(
              dBody,
              (res) => {
                Message.alert(
                  "Service has been saved and deployed.",
                  Enums.MessageType.SUCCESS
                );
                cb(true);
              },
              (err) => {
                console.log(err);
                cb(false);
              }
            );
          }
        },
        cb
      );
      // }else{

      // }
    } else {
      body = {
        ...body,
        ...connectionInfo,
        connectionUserId: userId,
      };
      WorkflowService.deployService(
        body,
        (res) => {
          if (!res.isError) {
            const resData = produce(res.data, (draft) => {
              draft.prevService = workflow.prevService;
            });
            dispatch(updateService(resData, workflow));
            WorkflowService.localStorageSave(res.data);
            Message.alert(
              "Service has been deployed.",
              Enums.MessageType.SUCCESS
            );
            cb(true);
          }
        },
        (err) => {
          cb(false);
        }
      );
    }
  };

  /**
   * 그루핑 모드함수
   * 그룹핑 해제할때 기등록된 항목 초기화
   * @param {*} mode
   */
  const setBundle = (mode) => {
    if (!mode) {
      setBundleStartNode({});
      setBundleEndNode({});
      setBundleNodeList({});
    }
    setBundlingMode(mode);
  };

  const contextValue = {
    deploy: workflowDeploy,
    deployPopup: workflowDeployPopupOpen,
    bundle: {
      bundleStartNode,
      bundleEndNode,
      bundlingMode,
      setBundleStartNode,
      setBundleEndNode,
      setBundlingMode: setBundle,
      bundleNodeList,
      setBundleNodeList,
      selectedBundle,
      setSelectedBundle,
      openBundleDetailPopup,
      setOpenBundleDetailPopup,
      bundleModalExpand,
      setBundleModalExpand,
    },
    debug: {
      debugExpressionMode,
      setDebugExpressionMode,
    },
  };

  return (
    <WorkflowContext.Provider value={contextValue}>
      {StringUtils.equalsIgnoreCase(
        Enums.BuilderPath.WORKFLOW.BUILDER,
        currentPath
      ) ? (
        <WorkflowHeader
          {...props.header}
          deploy={workflowDeploy}
          deployPopup={workflowDeployPopupOpen}
        />
      ) : (
        <BuilderHeaderComponent {...props.header} />
      )}
      <Outlet />
    </WorkflowContext.Provider>
  );
};

export default Workflow;
