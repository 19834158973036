import Modal from "components/common/modal/UModal";
import UModalTemplate from "components/common/modal/UModalTemplate";
import React from "react";

function IteratorOptionDescPopup() {
  return (
    <Modal>
      <Modal.Header title="Iterator Condition Explanation" />
      <Modal.Body>
        <UModalTemplate>
          <p>Iterator Condition</p>
          <p>Processing for three types is possible.</p>
          <p>
            <strong>
              1) List (array, multiple data) - repeats for each item in the
              List.
            </strong>
            <br />
            <span>
              - The value of the Iterator variable is the Row Data of the List
              (Map - Single Data).
            </span>
          </p>
          <p>
            <strong>
              2) Map (Single-Data) - repeats for the count of fields in the
              data.
            </strong>
            <br />
            <span>
              - The value of the Iterator variable is the Field ID & Field Value
              of the Map.
              <br />
              ("Iterator variable".getKey(), "Iterator variable". getValue())
            </span>
          </p>
          <p>
            <strong>
              3) Number - Repeat from 0 to "Iterator Condition" count.
            </strong>
            <br />
            <span>
              - The value of the Iterator variable is the repeating index.
            </span>
          </p>
        </UModalTemplate>
      </Modal.Body>
    </Modal>
  );
}

export default IteratorOptionDescPopup;
