import { Enums } from "components/builder/BuilderEnum";
import Message from "components/common/Message";
import Modal from "components/common/modal/UModal";
import UmodalTemplate from "components/common/modal/UModalTemplate";
import StringUtils from "components/common/utils/StringUtils";
import React from "react";
import { useState } from "react";
import { Form } from "react-bootstrap";

function EntityAddPopup({ onOk, entity, output, ...props }) {
  const [physEntityNm, setPhysEntityNm] = useState(entity.physEntityNm);

  const onConfirm = (e) => {
    if (StringUtils.isEmpty(physEntityNm)) {
      return Message.alert(
        "Please enter Physical Name",
        Enums.MessageType.WARN
      );
    }

    if (
      output.dataModelEntities.find((dme) =>
        StringUtils.equalsIgnoreType(dme.physEntityNm, physEntityNm)
      )
    ) {
      return Message.alert(
        "An entity with the same physical name already exists.",
        Enums.MessageType.WARN
      );
    }
    e.preventDefault();
    e.stopPropagation();
    if (onOk) {
      onOk(physEntityNm);
    }
  };

  const onKeyDown = (e) => {
    if (e.keyCode === 13) {
      onConfirm();
    } else {
      return true;
    }
  };

  return (
    <Modal>
      <Modal.Header title={"Enter New Table Physical Name"} />
      <Modal.Body>
        <UmodalTemplate>
          <Form onSubmit={onConfirm}>
            <Form.Group className="mt-3 ">
              <Form.Label className="required">
                New Entity Physical Name
              </Form.Label>
              <Form.Control
                value={physEntityNm}
                onChange={(e) => setPhysEntityNm(e.currentTarget.value)}
                onKeyDown={onKeyDown}
              />
            </Form.Group>
          </Form>
        </UmodalTemplate>
      </Modal.Body>
      <Modal.Footer>
        <Modal.Footer.Button onClick={onConfirm}>Save</Modal.Footer.Button>
      </Modal.Footer>
    </Modal>
  );
}

export default EntityAddPopup;
