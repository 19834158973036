import React from "react";

function UModalTemplate({ className, style, hidden, ...props }) {
  return (
    <div style={style} className={`modal-template ${className || ""}`} hidden={hidden}>
      {props.children}
    </div>
  );
}

export default UModalTemplate;
