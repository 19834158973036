/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from "react";
import { BsPencilSquare } from "react-icons/bs";
import Popup from "components/common/Popup";
import Modal from "components/common/modal/UModal";
import styled from "styled-components";
import "bootstrap/dist/css/bootstrap.min.css";
import CodeMirror from "@uiw/react-codemirror";
import { javascript } from "@codemirror/lang-javascript";
import { json } from "@codemirror/lang-json";
import { java } from "@codemirror/lang-java";
import { html } from "@codemirror/lang-html";
import { css } from "@codemirror/lang-css";

import DataModelService from "services/datamodel/DataModelService";
import StringUtils from "components/common/utils/StringUtils";
import { Button, Col, Form, Row } from "react-bootstrap";
import ArrayUtils from "components/common/utils/ArrayUtils";
import { MdLibraryAdd } from "react-icons/md";
import { FaTrash } from "react-icons/fa";
import produce from "immer";
import ObjectUtils from "components/common/utils/ObjectUtils";
import {
  PropertyLable,
  PropertyValue,
} from "components/builder/ui/uiComponents/UIComponentStyle";
import { GridToolbarBtnPopupStyle } from "./UserTransactionSPPopup";
import UInputPopup from "components/common/element/UInputPopup";
import DefaultInputPopup from "./DefaultInputPopup";
import CodeService from "services/common/CodeService";
import MessageListPopup from "./MessageListPopup";
import { Checkbox } from "@mui/material";
import Message from "components/common/Message";
import UModalJavascriptEditor from "components/common/code/UModalJavascriptEditor";
import { useRef } from "react";

const MesFunPopupStyle = styled.div`
  .edit-source {
    border: 1px solid #ddd;
  }
`;

/**
 * MesFunPopup : Message와 Function 입력 Popup
 *
 * 2022.04.28: init: songe.park
 * 2024.01.02 : Code Mirror 기능 사용 유지 위한 state -> Ref로 관리 하도록 수정
 * @param {*} props
 * @returns value
 */
const MesFunPopup = (props) => {
  const {
    title,
    msgOptions,
    fnOptions,
    callbackFnc,
    isShowMsgCd,
    isShowFnc,
    isInputMapping,
    isOutputMapping,
    setRetItems,
    entityId,
    item,
    codeList,
    isShowOperator,
    extraDataBindingList,
    isProcEntity,
    workspace,
    onClickEventBuilder,
    output,
    eventWorkspace,
    targetItem,
  } = props;

  const [msgData, setMsgData] = useState("");
  // const [fnData, setFnData] = useState("");
  // const [afterDataLoad, setAfterDataLoad] = useState("");
  const [params, setParams] = useState([]);
  const [dataBindingList, setDataBindingList] = useState([]);
  const [resultMapping, setResultMapping] = useState([]);

  //Data Ref
  const FnDataRef = useRef("");
  const afterDataLoadRef = useRef("");

  //codemirror - MesFunions
  const javascriptExt = [javascript(true)];
  const jsonExt = [json(true)];
  const javaExt = [java(true)];
  const htmlExt = [html(true)];
  const cssExt = [css(true)];
  const [extensions, setExtensions] = useState(javascriptExt);

  //Procedure Parameter Info
  const [insertOption, setInsertOption] = useState({});
  const [argumentTypes, setArgumentTypes] = useState([]);

  //initPopup: popup open 하고 실행
  const initPopup = () => {
    setMsgData(msgOptions.value);
    // setFnData(fnOptions.value);
    // setAfterDataLoad(fnOptions.afterDataLoad);
    FnDataRef.current = fnOptions.value;
    afterDataLoadRef.current = fnOptions.afterDataLoad;
    selectDataBindingList(entityId);

    let initItem = { ...(item || {}) };

    if (ObjectUtils.isEmpty(initItem.params)) {
      initItem.params = [{}];
    }
    if (ObjectUtils.isEmpty(initItem.resultMapping)) {
      initItem.resultMapping = [{}];
    }
    setParams(initItem.params);
    setResultMapping(initItem.resultMapping);
    if (isProcEntity) {
      initParameterList();
    }
  };
  const initParameterList = () => {
    setInsertOption(props.insertOption);
    CodeService.getCodeCombo({ codeMstCd: "Z0036" }, (res) => {
      setArgumentTypes(res.data);
    });
  };

  // closePopup: value값을 전달하고 popup 닫기
  const closePopup = (e) => {
    callbackFnc.call(this, {
      [msgOptions.id]: msgData,
      [fnOptions.id]: FnDataRef.current,
      // [fnOptions.id]: fnData,
      // afterDataLoad: afterDataLoad,
      afterDataLoad: afterDataLoadRef.current,
      params: params,
      resultMapping: resultMapping,
      insertOption: insertOption,
    });
    Popup.close();
  };

  /* *************** 전처리 START ************************ */
  const onChangeDataBinding = (e, param, index) => {
    let newParam = { ...param };
    //id를 setting해준다.
    newParam.id = StringUtils.substringAfter(e.target.value, ".");
    changeParam(e, newParam, index);
  };

  const onChangeParam = (e, param, index) => {
    changeParam(e, param, index);
  };

  const changeParam = (e, param, index) => {
    const newParams = produce(params, (draft) => {
      draft[index] = { ...param, [e.target.id]: e.target.value };
    });
    setParams(newParams);
    onChangeSetRetItems(e, "params", newParams);
  };

  const onInsertClick = (e, param, index) => {
    const newParams = produce(params, (draft) => {
      draft.splice(index + 1, 0, {});
    });
    setParams(newParams);
    onChangeSetRetItems(e, "params", newParams);
  };

  const onDeleteClick = (e, param, index) => {
    const newParams = produce(params, (draft) => {
      draft.splice(index, 1);
      if (draft.length === 0) {
        draft.splice(0, 0, {});
      }
    });
    setParams(newParams);
    onChangeSetRetItems(e, "params", newParams);
  };
  /* *************** 전처리 END ************************ */

  /* *************** 후처리 START ************************ */
  const onChangeResultMapping = (e, result, index) => {
    const newResultMapping = produce(resultMapping, (draft) => {
      draft[index] = { ...result, [e.target.id]: e.target.value };
    });
    setResultMapping(newResultMapping);
    onChangeSetRetItems(e, "resultMapping", newResultMapping);
  };

  const onInsertClickResultMapping = (e, result, index) => {
    const newResultMapping = produce(resultMapping, (draft) => {
      draft.splice(index + 1, 0, {});
    });
    setResultMapping(newResultMapping);
    onChangeSetRetItems(e, "resultMapping", newResultMapping);
  };

  const onDeleteClickResultMapping = (e, result, index) => {
    const newResultMapping = produce(resultMapping, (draft) => {
      draft.splice(index, 1);
      if (draft.length === 0) {
        draft.splice(0, 0, {});
      }
    });
    setResultMapping(newResultMapping);
    onChangeSetRetItems(e, "resultMapping", newResultMapping);
  };
  /* *************** 후처리 END ************************ */

  /**
   * setRetItems : popupHandleConfigPopup에서 재 items 설정을 위해
   * @param {*} e
   * @param {*} pId
   * @param {*} pItem
   */
  const onChangeSetRetItems = (e, pId, pItem) => {
    if (setRetItems) {
      if (!StringUtils.isEmpty(pId)) {
        setRetItems.call(this, pId, pItem);
      } else {
        setRetItems.call(this, e.target.id, e.target.value);
      }
    }
  };

  /**
   * Data Model Entity Field List 조회(Data Binidng)
   * @param {*} entityId
   */
  const selectDataBindingList = (entityId) => {
    if (!StringUtils.isEmpty(entityId)) {
      DataModelService.getDataBindingList({ entityId: entityId }, (res) => {
        setDataBindingList(
          res.data ? res.data : [{ id: "none", text: "[none] None Data" }]
        );
      });
    }
  };

  const onChangeInsertOptionBinding = (e, idx) => {
    const newInsertOption = { ...insertOption };
    let newArguments = [...newInsertOption.arguments];
    const newArgument = produce(newArguments[idx], (draft) => {
      draft[e.target.id] = e.target.value;
    });
    newArguments[idx] = newArgument;
    newInsertOption.arguments = newArguments;
    setInsertOption(newInsertOption);
  };

  /**
   * Message List Popup
   * @param {*} e
   */
  const openMessagePopup = (pTarget, e) => {
    const popOptions = {
      effect: Popup.ScaleUp, //Effect.SlideFromTop(default)를 Effect.ScaleUp 로 변경
      style: {
        content: {
          height: "700px",
        },
      },
    };

    Popup.open(
      <MessageListPopup
        workspace={workspace}
        callbackFnc={(result) => {
          let el = document.getElementById(pTarget);
          el.value = result.msgCd;
          setMsgData(result.msgCd);
          onChangeSetRetItems(e, pTarget, result.msgCd);
          // doInputChangeEvent(el, "input");
          // onBlurValue(pTarget, result.msgCd);
        }}
      />,
      popOptions
    );
  };

  useEffect(() => {
    initPopup();
  }, []);
  return (
    <MesFunPopupStyle>
      <Modal>
        <Modal.Header title={title} />
        <Modal.Body>
          {isShowMsgCd ? (
            <React.Fragment>
              <div className="float-left w-20p">Confirm Message Code</div>
              <div className="float-left w-30p">
                <UInputPopup
                  id={msgOptions.id}
                  defaultValue={StringUtils.defaultString(msgData, "")}
                  onBlur={(e) => {
                    setMsgData(e.target.value);
                    onChangeSetRetItems(e, e.target.id, e.target.value);
                  }}
                  onClick={(e) => openMessagePopup(msgOptions.id, e)}
                />
              </div>
            </React.Fragment>
          ) : (
            ""
          )}

          {isInputMapping ? (
            <React.Fragment>
              <Form.Group className="mb-3">
                <Form.Label>Input Mapping</Form.Label>
                <Col>
                  <Row className="mb-2">
                    <Col>Data Binding (From)</Col>
                    <Col>ID (To)</Col>
                    {isShowOperator ? <Col>Condition</Col> : ""}
                    <Col>Fixed Value</Col>
                    <Col lg={1}>Optional?</Col>
                    <Col></Col>
                  </Row>
                  {!ArrayUtils.isEmpty(params)
                    ? params.map((param, index) => {
                        return (
                          <Row
                            key={index}
                            className="pb-2"
                            style={{ alignItems: "center" }}
                          >
                            <Col>
                              <Form.Select
                                size="sm"
                                id="dataBinding"
                                onChange={(e) =>
                                  onChangeDataBinding(e, param, index)
                                }
                                value={StringUtils.defaultString(
                                  param.dataBinding
                                )}
                              >
                                <option value={""}>Select</option>
                                {!ArrayUtils.isEmpty(dataBindingList)
                                  ? dataBindingList.map((option, optionIdx) => (
                                      <option key={optionIdx} value={option.id}>
                                        {option.text}
                                      </option>
                                    ))
                                  : ""}
                              </Form.Select>
                            </Col>
                            <Col>
                              <input
                                type="text"
                                id="id"
                                className="form-control form-select-sm"
                                defaultValue={param.id}
                                onChange={(e) => onChangeParam(e, param, index)}
                              />
                            </Col>
                            {isShowOperator ? (
                              <Col>
                                <Form.Select
                                  size="sm"
                                  id="searchOperator"
                                  onChange={(e) =>
                                    onChangeParam(e, param, index)
                                  }
                                  value={StringUtils.defaultString(
                                    param.searchOperator
                                  )}
                                >
                                  <option value={""}>Select</option>
                                  {!ArrayUtils.isEmpty(
                                    codeList.getCodeList("Z0026")
                                  )
                                    ? codeList
                                        .getCodeList("Z0026")
                                        .map((option, optionIdx) => (
                                          <option
                                            key={optionIdx}
                                            value={option.codeDtlCd}
                                          >
                                            {option.codeDtlNm}
                                          </option>
                                        ))
                                    : ""}
                                </Form.Select>
                              </Col>
                            ) : (
                              ""
                            )}
                            <Col>
                              <input
                                type="text"
                                id="default"
                                className="form-control form-select-sm"
                                defaultValue={param.default}
                                onChange={(e) => onChangeParam(e, param, index)}
                              />
                            </Col>
                            <Col lg={1}>
                              <Checkbox
                                id="optional"
                                value="Y"
                                defaultChecked={
                                  param.optional === "Y" ? true : false
                                }
                                onClick={(e) =>
                                  onChangeParam(
                                    {
                                      target: {
                                        id: "optional",
                                        value: e.target.checked ? "Y" : "N",
                                      },
                                    },
                                    param,
                                    index
                                  )
                                }
                              />
                            </Col>
                            <Col>
                              <Button
                                onClick={(e) => onInsertClick(e, param, index)}
                                size="sm"
                                className="mr-5"
                                variant={"outline-primary"}
                              >
                                <MdLibraryAdd size="16" />
                              </Button>
                              <Button
                                onClick={(e) => onDeleteClick(e, param, index)}
                                size="sm"
                                variant={"outline-danger"}
                              >
                                <FaTrash size="16" />
                              </Button>
                            </Col>
                          </Row>
                        );
                      })
                    : ""}
                </Col>
              </Form.Group>
            </React.Fragment>
          ) : (
            ""
          )}

          {isOutputMapping ? (
            <Form.Group className="mb-3">
              <Form.Label>Output Mapping</Form.Label>
              <Col>
                <Row className="mb-2">
                  <Col>Output Column ID (From)</Col>
                  <Col>Data Binding Column (To)</Col>
                  <Col></Col>
                </Row>
                {!ArrayUtils.isEmpty(resultMapping)
                  ? resultMapping.map((mapping, index) => {
                      return (
                        <Row
                          key={index}
                          className="pb-2"
                          style={{ alignItems: "center" }}
                        >
                          <Col>
                            <input
                              type="text"
                              id="id"
                              className="form-control form-select-sm"
                              defaultValue={mapping.id}
                              onBlur={(e) =>
                                onChangeResultMapping(e, mapping, index)
                              }
                            />
                          </Col>
                          <Col>
                            <Form.Select
                              size="sm"
                              id="dataBinding"
                              onChange={(e) =>
                                onChangeResultMapping(e, mapping, index)
                              }
                              value={StringUtils.defaultString(
                                mapping.dataBinding
                              )}
                            >
                              <option value={""}>Select</option>
                              {!ArrayUtils.isEmpty(dataBindingList)
                                ? dataBindingList.map((option, optionIdx) => (
                                    <option key={optionIdx} value={option.id}>
                                      {option.text}
                                    </option>
                                  ))
                                : ""}
                            </Form.Select>
                          </Col>
                          <Col>
                            <Button
                              onClick={(e) =>
                                onInsertClickResultMapping(e, mapping, index)
                              }
                              size="sm"
                              className="mr-5"
                              variant={"outline-primary"}
                            >
                              <MdLibraryAdd size="16" />
                            </Button>
                            <Button
                              onClick={(e) =>
                                onDeleteClickResultMapping(e, mapping, index)
                              }
                              size="sm"
                              variant={"outline-danger"}
                            >
                              <FaTrash size="16" />
                            </Button>
                          </Col>
                        </Row>
                      );
                    })
                  : ""}
              </Col>
            </Form.Group>
          ) : (
            ""
          )}

          {isShowFnc ? (
            <React.Fragment>
              {props.isProcEntity && msgOptions.id === "beforeMsgCd" && (
                <ProcedureParamterInput
                  insertOption={insertOption}
                  onChangeInsertOptionBinding={onChangeInsertOptionBinding}
                  dataBindingList={dataBindingList}
                  extraDataBindingList={extraDataBindingList}
                  argumentTypes={argumentTypes}
                />
              )}
              {props.isBtnSchTargetGrid ? (
                <React.Fragment>
                  <PropertyLable className="float-left w-full">
                    Function executes after Grid Data Load
                  </PropertyLable>
                  <PropertyValue className="float-left w-full">
                    <UModalJavascriptEditor
                      height="300px"
                      defaultValue={StringUtils.defaultString(
                        afterDataLoadRef.current
                      )}
                      onClickEventBuilder={(e) =>
                        onClickEventBuilder("afterDataLoad")
                      }
                      eventInfo={props.getEventInfo("afterDataLoad")}
                      onChange={(value, viewUpdate) => {
                        // setAfterDataLoad(value);
                        afterDataLoadRef.current = value;
                        onChangeSetRetItems(null, "afterDataLoad", value);
                      }}
                      output={output}
                      workspace={workspace}
                      targetItem={targetItem}
                    />
                  </PropertyValue>
                  <br />
                  <PropertyLable className="float-left w-full">
                    Function executes after mapping data to the grid.
                  </PropertyLable>
                </React.Fragment>
              ) : (
                <PropertyLable className="float-left w-full mt-3">
                  User Function
                </PropertyLable>
              )}
              <PropertyValue className="float-left w-full">
                <UModalJavascriptEditor
                  height={
                    !props.isBtnSchTargetGrid
                      ? "450px"
                      : StringUtils.isEmpty(fnOptions.height)
                      ? "450px"
                      : fnOptions.height
                  }
                  defaultValue={StringUtils.defaultString(FnDataRef.current)}
                  onClickEventBuilder={(e) => onClickEventBuilder()}
                  eventInfo={props.getEventInfo()}
                  onChange={(value, viewUpdate) => {
                    // setFnData(value);
                    FnDataRef.current = value;
                    onChangeSetRetItems(null, fnOptions.id, value);
                  }}
                  output={output}
                  workspace={workspace}
                  targetItem={targetItem}
                />
              </PropertyValue>
            </React.Fragment>
          ) : (
            ""
          )}
        </Modal.Body>
        <Modal.Footer>
          <Modal.Footer.Button
            type="button"
            className="btn btn-primary"
            onClick={closePopup}
          >
            <BsPencilSquare />
            &nbsp;Confirm
          </Modal.Footer.Button>
        </Modal.Footer>
      </Modal>
    </MesFunPopupStyle>
  );
};

export default MesFunPopup;

/**
 * 프로시져 또는 함수형 엔티티 파라미터 입력 폼
 * 2023.02.20  init: kiyoung.park
 * @param {*} param0
 * @returns
 */
const ProcedureParamterInput = ({
  insertOption,
  onChangeInsertOptionBinding,
  dataBindingList, //폼 데이터 모델 바인딩 목록
  extraDataBindingList, //DM이 없을때 사용할 수 있는ㄷ 폼의 인풋 데이터
  argumentTypes,
  ...props
}) => {
  const onChangeDefaultValue = (e, argument, index) => {
    onChangeInsertOptionBinding(
      { target: { id: "defaultValue", value: e.target.value } },
      index
    );
  };

  const onChangeAugumentType = (e, index) => {
    onChangeInsertOptionBinding(e, index);
  };

  /**
   * Default popup
   * @param {*} e
   */
  const openDefaultPopup = (e, argument, index) => {
    //팝업창 열기
    const options = {
      effect: Popup.ScaleUp, //Effect.SlideFromTop(default)를 Effect.ScaleUp 로 변경
      style: {
        content: {
          width: "45%",
        },
      },
    };

    let id = StringUtils.defaultString(e.target.id, e.currentTarget.formTarget);

    Popup.open(
      <DefaultInputPopup
        id={id}
        onChooseDefaultValue={(result) => {
          //set element value
          let el = document.getElementById(result.target.id);
          el.value = result.target.value;
          let eventObj = new Event("input", { target: el, bubbles: true });
          el.dispatchEvent(eventObj);
          onChangeDefaultValue(
            {
              target: {
                name: "defaultValue",
                value: el.value,
              },
            },
            argument,
            index
          );
        }}
      />,
      options
    );
    if (e) e.preventDefault();
  };

  return (
    <GridToolbarBtnPopupStyle>
      <div className="w-full flex-wrap">
        <PropertyLable requried="true" className="float-left">
          Stored Procedure Name
        </PropertyLable>
        <PropertyValue className="float-left">
          <input
            type="text"
            id="procedureName"
            readOnly={true}
            className="form-control form-control-sm"
            defaultValue={insertOption.procedureName}
          />
        </PropertyValue>
      </div>
      <div className="w-full">
        <PropertyValue className="float-left w-full">
          <Form.Group className="mb-3 table-bottom">
            <Col>
              <Row className="mb-2 table-header">
                <Col>Parameters</Col>
                <Col>Type</Col>
                <Col>Data Binding</Col>
                <Col>Fixed Value</Col>
              </Row>
              {!ArrayUtils.isEmpty(insertOption.arguments)
                ? insertOption.arguments.map((argument = {}, index) => {
                    return (
                      <Row
                        key={index}
                        className="pb-2"
                        style={{ alignItems: "center" }}
                      >
                        <Col>
                          <input
                            type="text"
                            id="name"
                            className="form-control form-select-sm"
                            defaultValue={argument.name}
                            readOnly={true}
                          />
                        </Col>
                        <Col>
                          <Form.Select
                            id="type"
                            value={argument.type}
                            onChange={(e) => onChangeAugumentType(e, index)}
                            disabled={
                              argument.name === "tenantId" ||
                              argument.name === "coCd"
                            }
                          >
                            {argumentTypes.map((type) => {
                              return (
                                <option key={type.id} value={type.id}>
                                  {type.text}
                                </option>
                              );
                            })}
                          </Form.Select>
                        </Col>

                        <Col>
                          {argument.name !== "tenantId" &&
                            argument.name !== "coCd" && (
                              <Form.Select
                                size="sm"
                                id="dataBinding"
                                value={StringUtils.defaultString(
                                  argument.dataBinding
                                )}
                                onChange={(e) =>
                                  onChangeInsertOptionBinding(e, index)
                                }
                              >
                                <option value={""}>Select</option>
                                {!ArrayUtils.isEmpty(dataBindingList)
                                  ? dataBindingList.map((option, optionIdx) => (
                                      <option key={optionIdx} value={option.id}>
                                        {option.text}
                                      </option>
                                    ))
                                  : ""}
                                {!ArrayUtils.isEmpty(extraDataBindingList) ? (
                                  extraDataBindingList.map((option, idx) => {
                                    return (
                                      <option key={option.id} value={option.id}>
                                        {option.text}
                                      </option>
                                    );
                                  })
                                ) : (
                                  <></>
                                )}
                              </Form.Select>
                            )}
                        </Col>
                        <Col>
                          {argument.name !== "tenantId" &&
                          argument.name !== "coCd" &&
                          argument.mode !== "OUT" ? (
                            <UInputPopup
                              name="defaultValue"
                              id={`defaultValue${index}`}
                              defaultValue={StringUtils.defaultString(
                                argument.defaultValue
                              )}
                              onClick={(e) =>
                                openDefaultPopup(e, argument, index)
                              }
                              onChange={(e) =>
                                onChangeDefaultValue(e, argument, index)
                              }
                            />
                          ) : (
                            ""
                          )}
                        </Col>
                      </Row>
                    );
                  })
                : ""}
            </Col>
          </Form.Group>
        </PropertyValue>
      </div>
    </GridToolbarBtnPopupStyle>
  );
};
