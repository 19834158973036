import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from "@mui/lab";
import { CircularProgress, TextField } from "@mui/material";
import { Enums } from "components/builder/BuilderEnum";
import DataModelHistoryAccordionContents from "components/builder/entity/history/DataModelHistoryAccordionContents";
import CommonUtils, { StringUtils } from "components/common/utils/CommonUtils";
import produce from "immer";
import moment from "moment";
import PageTemplate from "page/common/PageTemplate";
import { useEffect, useRef, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { BsSearch } from "react-icons/bs";
import { FaList } from "react-icons/fa";
import { FiMoreHorizontal } from "react-icons/fi";
import { MdOutlineUpdate } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import DataModelService from "services/datamodel/DataModelService";
import styled from "styled-components";

const MoreButtonLine = styled.div`
  width: 100%;
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const LoadingTemp = styled.div`
  background-color: #3c3c3c55;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  z-index: 10;
`;

const initSearchTerm = {
  workerId: null,
  commitComment: null,
  dateFrom: null,
  dateTo: null,
};

function DataModelHistoryDetail() {
  const { dataModelId, historyMstId } = useParams(); // => historyMstId 로 변경됨
  const navigate = useNavigate();
  const breadcrum = [
    { name: "Data Model History", url: Enums.BuilderPath.HISTORY },
    { name: "Data Model History Details", subName: dataModelId, active: true },
  ];

  const [isLast, setIsLast] = useState(false);
  const [historyList, setHistoryList] = useState([]);
  const [displayList, setDisplayList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [searchTerm, setSearchTerm] = useState({ ...initSearchTerm });
  const [insertDt, setInsertDt] = useState("");

  const searchTermRef = useRef({ ...initSearchTerm });

  const pageNumber = useRef(0);

  useEffect(() => {
    searchList();
    return () => {
      setHistoryList([]);
      setDisplayList([]);
    };
  }, [historyMstId]);

  /**
   * 목록으로 이동
   */
  const onMoveToList = () => {
    navigate(
      Enums.BuilderPath.ENTITY.MAIN + "/" + Enums.BuilderPath.ENTITY.HISTORY
    );
  };

  /**
   * 데이터 모델 목록 호출
   * @param {*} pageNumber
   * @param {*} init
   */
  const getDataModelHistoryList = (pageNumber, init) => {
    const body = {
      historyMstId,
      pageNumber,
      ...searchTermRef.current,
    };
    setIsLoading(true);
    DataModelService.getDataModelHistoryList(
      body,
      (res) => {
        const {
          data: { content: _historyList, last },
        } = res;

        if (_historyList.length > 0) {
          //separate with commit date in displayList
          let newList = [];
          if (init) {
            newList = [..._historyList];
          } else {
            newList = [...historyList, ..._historyList];
          }

          const wholeList = [];
          let tmpDate = "";
          let tmpList = [];
          newList.map((_program, idx) => {
            if (
              CommonUtils.getTZTime(_program.insertDt).get("y") ===
                CommonUtils.getTZTime(tmpDate).get("y") &&
              CommonUtils.getTZTime(_program.insertDt).get("month") ===
                CommonUtils.getTZTime(tmpDate).get("month") &&
              CommonUtils.getTZTime(_program.insertDt).get("d") ===
                CommonUtils.getTZTime(tmpDate).get("d")
            ) {
              // same day with tmpDate
              tmpList.push(_program);
            } else {
              // diff day with tmpDate
              if (tmpList.length > 0) {
                wholeList.push(tmpList);
              }
              tmpList = [];
              tmpList.push(_program);
              tmpDate = _program.insertDt;
            }

            if (idx === newList.length - 1 && tmpList.length > 0) {
              wholeList.push(tmpList);
            }
          });
          setHistoryList(_historyList);
          setDisplayList(wholeList);
        }
        setIsLast(last);
        setIsLoading(false);
      },
      (err) => {
        setIsLoading(false);
      }
    );
  };

  /**
   * More(더보기) 버튼 이벤트
   */
  const onClickNextPage = () => {
    pageNumber.current = pageNumber.current + 1;
    getDataModelHistoryList(pageNumber.current);
  };

  const onChangeSearchTerm = (e) => {
    setSearchTerm(
      produce(searchTerm, (draft) => {
        draft[e.target.id] = e.target.value;
      })
    );
    searchTermRef.current[e.target.id] = e.target.value;
  };

  /**
   * 검색 초기화
   */
  const initSearch = () => {
    searchTermRef.current = { ...initSearchTerm };
    setSearchTerm({ ...initSearchTerm });
    searchList();
  };

  /**
   * 검색날짜 이벤트
   */
  const onChangeDate = (e) => {
    const from = moment(e.target.value);
    const to = moment(e.target.value);
    from.set("hour", 0);
    from.set("minutes", 0);
    from.set("seconds", 0);
    to.set("hour", 23);
    to.set("minutes", 59);
    to.set("seconds", 59);
    setInsertDt(e.target.value);

    setSearchTerm(
      produce(searchTerm, (draft) => {
        if (StringUtils.equalsIgnoreCase(e.target.id, "insertDt")) {
          draft.dateFrom = from;
          draft.dateTo = to;
          searchTermRef.current.dateFrom = from;
          searchTermRef.current.dateTo = to;
        }
      })
    );
  };

  const searchList = () => {
    pageNumber.current = 0;
    getDataModelHistoryList(pageNumber.current, true);
  };

  return (
    <PageTemplate breadcrum={breadcrum}>
      <PageTemplate.Box boxClass="mb-0">
        <div className="box-control box-line">
          <Button variant="outline-dark" size="sm" onClick={onMoveToList}>
            <FaList /> List
          </Button>
        </div>
        {isLoading && (
          <LoadingTemp>
            <CircularProgress size={20} /> Loading...
          </LoadingTemp>
        )}
        <Form>
          <Row className="pb-3 pt-3">
            <Col xs={1} />
            <Col>
              <TextField
                size="small"
                label="Commit Content"
                placeholder="Commit Content"
                fullWidth={true}
                id="commitComment"
                value={searchTerm.commitComment || ""}
                onChange={onChangeSearchTerm}
              />
            </Col>
            <Col>
              <TextField
                size="small"
                label="Worker ID"
                placeholder="Worker ID"
                fullWidth={true}
                id="workerId"
                value={searchTerm.workerId || ""}
                onChange={onChangeSearchTerm}
              />
            </Col>
            <Col>
              <input
                className="history-date-picker"
                type="date"
                id="insertDt"
                value={insertDt}
                onChange={onChangeDate}
              />
            </Col>
            <Col style={{ display: "flex", gap: "10px" }}>
              <Button variant="primary" onClick={searchList}>
                Search <BsSearch size="14" />
              </Button>
              <Button variant="secondary" onClick={initSearch}>
                Init Search <BsSearch size="14" />
              </Button>
            </Col>
          </Row>
        </Form>
        <div style={{ maxHeight: "calc(100% - 200px)", overflow: "auto" }}>
          <Timeline align="left" position="right">
            {displayList.map((_dateProgram, idx) => {
              return (
                <TimelineItem key={idx}>
                  <TimelineOppositeContent
                    sx={{ flex: 0.1 }}
                    color="textSecondary"
                  >
                    <div style={{ marginTop: "7px" }}>
                      {CommonUtils.getDate(_dateProgram[0].insertDt)}
                    </div>
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineDot color="primary" style={{ fontSize: "larger" }}>
                      <MdOutlineUpdate />
                    </TimelineDot>
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>
                    {_dateProgram.map((_history) => {
                      return (
                        <DataModelHistoryAccordionContents
                          dataModelHistory={_history}
                          key={_history.dataModelHistoryId}
                        />
                      );
                    })}
                  </TimelineContent>
                </TimelineItem>
              );
            })}
          </Timeline>
        </div>
        {!isLast && (
          <MoreButtonLine>
            <Button variant="outline-dark" onClick={onClickNextPage}>
              <FiMoreHorizontal /> More
            </Button>
          </MoreButtonLine>
        )}
      </PageTemplate.Box>
    </PageTemplate>
  );
}

export default DataModelHistoryDetail;
