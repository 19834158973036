import { debounce } from "components/common/utils/InputUtils";
import produce from "immer";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Button, Col, Form, Row, Table } from "react-bootstrap";
import { MdDelete } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import {
  setDesign,
  setDesignRelatedTables,
} from "../reducer/ProgramDesignAction";
import Popup from "components/common/Popup";
import TrdTableListPopup from "page/popup/workflow/TrdTableListPopup";
import TrdService from "services/trd/TrdService";
import CommonUtils, {
  ArrayUtils,
  StringUtils,
} from "components/common/utils/CommonUtils";
import AppService from "services/common/AppService";
import { Enums } from "components/builder/BuilderEnum";

const TableInfo = ({ designMst, flag, setDesignMst, ...props }) => {
  const [rows, setRows] = useState([]);
  const [appEnvList, setAppEnvList] = useState([]);
  const [envId, setEnvId] = useState("");

  const rowList = useRef([]);

  const workspace = useSelector((state) => state.workspace);
  const relatedTable = useSelector(
    (state) => state.technicalDesign.designRelatedTables
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (designMst && designMst.designMstId) {
      setRows(relatedTable);
      setEnvId(designMst.appEnvId);
    } else {
      setRows([]);
      dispatch(setDesignRelatedTables([]));
      setEnvId(appEnvList[0]?.appEnvId);
    }
  }, [designMst]);

  useEffect(() => {
    getAppEnvList(workspace.appId);
  }, []);

  useEffect(() => {
    if (flag) {
      setRows([]);
    }
  }, [flag]);

  useEffect(() => {
    onChangeEnv();
  }, [envId]);

  const getAppEnvList = (value) => {
    AppService.getAppEnvList({ appId: value }, (res) => {
      if (res.data) {
        //개발 환경 우선
        const devList = res.data.filter((env) => {
          delete env.appMst;
          return env.envType === Enums.EnvType.DEV;
        });
        setAppEnvList(devList);
      }
    });
  };

  const onChangeEnv = () => {
    let newDesignMst = { ...designMst };
    newDesignMst.appEnvId = envId;
    setDesignMst(newDesignMst);
    dispatch(setDesign(newDesignMst));
  };

  const columns = [
    {
      field: "tableLogicalNm",
      headerTitle: "Table Name",
      style: { width: "35%" },
      renderCell: (row, index) => {
        return (
          <Form.Control
            id={"tableLogicalNm"}
            size="sm"
            value={row.tableLogicalNm}
            onChange={(e) => onChange(e, row, index)}
            disabled
          />
        );
      },
    },
    {
      field: "tablePhysicalNm",
      headerTitle: "Table ID",
      style: { width: "35%" },
      renderCell: (row, index) => {
        return (
          <Form.Control
            id={"tablePhysicalNm"}
            size="sm"
            value={row.tablePhysicalNm}
            onChange={(e) => onChange(e, row, index)}
            disabled
          />
        );
      },
    },
    {
      field: "transactionType",
      headerTitle: "CRUD",
      style: { width: "200px" },
      renderCell: (row, index) => {
        return (
          <Form.Control
            id={"transactionType"}
            size="sm"
            value={row.transactionType}
            onChange={(e) => onChange(e, row, index)}
          />
        );
      },
    },
    {
      field: "deleteRow",
      headerTitle: "Delete",
      style: { width: "50px" },
      renderCell: (row, index) => {
        return (
          <div>
            <Button
              variant="outline-danger"
              size="sm"
              onClick={(e) => deleteRow(index)}
            >
              <MdDelete />
            </Button>
          </div>
        );
      },
    },
  ];

  const callbackFnc = useCallback(
    async (entity) => {
      const newField = {
        tableLogicalNm: entity.tableLogicalNm || entity.tablePhysicalNm,
        tablePhysicalNm: entity.tablePhysicalNm,
        transactionType: "R",
      };
      // 중복제거
      if (
        rowList.current.findIndex((row) =>
          StringUtils.equalsIgnoreCase(
            newField.tablePhysicalNm,
            row.tablePhysicalNm
          )
        ) < 0
      ) {
        rowList.current = [...rowList.current, newField];
        setRows(rowList.current);
        dispatch(setDesignRelatedTables(rowList.current));
      }
    },
    [rows]
  );

  const addRow = () => {
    rowList.current = [...rows];
    if (CommonUtils.getAppConfig(workspace, "trdUseYn") === "Y") {
      TrdService.getTrdList({ appEnvId: envId })
        .then((res) => {
          if (!res.isError) {
            Popup.open(
              <TrdTableListPopup
                workspace={workspace}
                callbackFnc={callbackFnc}
                title={"TRD Table 선택"}
                trdList={res.data}
                envId={envId}
              />,
              { style: { content: { width: "350px" } } }
            );
          }
        })
        .catch((err) => console.log("trd list not found"));
    }
  };

  const deleteRow = (index) => {
    let newRows = [...rows];
    newRows.splice(index, 1);
    setRows(newRows);
    dispatch(setDesignRelatedTables(newRows));
  };

  const debounceType = debounce((newRows) => {
    dispatch(setDesignRelatedTables(newRows));
  }, 400);

  const onChange = (e, row, index) => {
    const newRowData = { ...row };
    newRowData[e.target.id] = e.target.value;
    const newRows = produce(rows, (draft) => {
      draft[index] = newRowData;
    });
    setRows(newRows);
    debounceType(newRows);
  };

  return (
    <Col>
      <div>
        <Row
          style={{
            display: "flex",
            justifyContent: "right",
            alignItems: "center",
          }}
        >
          <Col xs={1}>
            <Form.Label style={{ marginBottom: 0 }}>Dev Environment</Form.Label>
          </Col>
          <Col xs={2}>
            <Form.Select
              value={envId}
              onChange={(e) => setEnvId(e.target.value)}
            >
              {appEnvList.map((app) => {
                return (
                  <option value={app.appEnvId} key={app.appEnvId}>
                    {app.envName}
                  </option>
                );
              })}
            </Form.Select>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="content-title">Table Info</div>
          </Col>
          <Col>
            <Button
              className="add-btn"
              variant="outline-primary"
              onClick={addRow}
              size="sm"
            >
              {CommonUtils.getAppConfig(workspace, "trdUseYn") === "Y"
                ? "Add Row From TRD"
                : null || "Add Row"}
            </Button>
          </Col>
        </Row>

        <Table bordered>
          <thead>
            <tr>
              <th style={{ width: "50px" }}></th>
              {columns.map((column, index) => {
                return (
                  <th style={{ width: "auto", ...column.style }} key={index}>
                    {column.headerTitle}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {!ArrayUtils.isEmpty(rows) &&
              rows.map((row, rowIndex) => {
                return (
                  <tr key={rowIndex}>
                    <td>{rowIndex + 1}</td>
                    {columns.map((column, colIndex) => {
                      if (column.renderCell) {
                        return (
                          <td key={colIndex}>
                            {column.renderCell(row, rowIndex)}
                          </td>
                        );
                      } else {
                        return <td key={colIndex}>{row[column.field]}</td>;
                      }
                    })}
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </div>
    </Col>
  );
};

export default TableInfo;
