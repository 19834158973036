import Message from "components/common/Message";
import Modal from "components/common/modal/UModal";
import { useEffect, useState } from "react";
// import UTextarea from "components/common/element/UTextarea";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import * as Enums from "components/builder/BuilderEnum";
import UmodalTemplate from "components/common/modal/UModalTemplate";
import JsonUtils from "components/common/utils/JsonUtils";
import ObjectUtils from "components/common/utils/ObjectUtils";
import StringUtils from "components/common/utils/StringUtils";
import User from "components/common/utils/UserUtils";
import produce from "immer";
import { Form } from "react-bootstrap";
import withProgramSavePopup from "./withProgramSavePopup";

function SavePopup(props) {
  const {
    title,
    callbackFnc,
    information,
    output,
    saveAs,
    updatePageByProps,
    workspace,
    programId,
    programNm,
    description,
    renderSavePath,
    renderCommonInput,
  } = props;

  const [isSaving, setIsSaving] = useState(false);
  const [templateShareYn, setTemplateShareYn] = useState(false);

  useEffect(() => {
    if (information) {
      setTemplateShareYn(information.templateShareYn === "Y" ? true : false);
    }
  }, []);

  const inputValueValidation = () => {
    if (StringUtils.isEmpty(programId)) {
      Message.alert("Please set Program ID.", Enums.MessageType.WARN);
      return false;
    }
    if (StringUtils.isEmpty(programNm)) {
      Message.alert("Please set Program Name.", Enums.MessageType.WARN);
      return false;
    }
    //기존에 저장된 프로그램을 다름이름으로 저장할때 같은 아이디로 저장 안되도록 방지
    if (saveAs && information.programId === programId) {
      Message.alert(
        "ID Duplicates with exists program.",
        Enums.MessageType.WARN
      );
      return false;
    }

    return {
      appId: workspace.appId,
      moduleCd: workspace.moduleCd,
      appReleaseId: workspace.appReleaseId,
      tenantId: workspace.tenantId,
      coCd: workspace.coCd,
      programId,
      programNm,
      description,
      templateSaveYn: "Y",
      templateShareYn: templateShareYn ? "Y" : "N",
      appApplyType: "N",
      useYn: "Y",
      committer: User.getMastRecId(),
      commitComment: "",
      releaseCommentYn: "",
    };
  };

  const setUpdateProperties = () => {
    return {
      programId,
      programDesc: description,
      programName: programNm,
    };
  };

  const saveData = (event) => {
    if (event) event.preventDefault();
    const inputValue = inputValueValidation();
    if (inputValue) {
      SaveTransaction(setIsSaving, callbackFnc);
    }
  };

  const SaveTransaction = (setIsLoading, callback) => {
    updateProperty({
      ...output.page.propertyValue,
      ...setUpdateProperties(),
    });
    setIsLoading(true);
    setTimeout(() => {
      callback.call(this, inputValueValidation(), () => setIsLoading(false));
    }, 1000);
  };

  const btnTemplateShare = (value) => {
    setTemplateShareYn(value);
  };

  const updateProperty = (propertyValue) => {
    const compId = output.page.compId;
    const changedOutput = produce(output, (draft) => {
      const targetNode = JsonUtils.overrideNode(
        draft,
        "compId",
        compId,
        "propertyValue",
        propertyValue
      );
      if (ObjectUtils.isEmpty(targetNode)) {
        console.log("Could not find target node !!!!");
        return false;
      }
    });
    if (updatePageByProps) {
      updatePageByProps(changedOutput, propertyValue);
    }
  };

  return (
    <Modal>
      {/*
       * Modal.Header props
       *   - title
       */}
      <Modal.Header title={title} />
      <Modal.Body>
        <Form>
          <UmodalTemplate>
            {renderSavePath()}
            {renderCommonInput()}
            <Form.Group className="mb-3">
              <Form.Label>Share Template</Form.Label>
              <div>
                <BootstrapSwitchButton
                  id="templateShareYn"
                  checked={StringUtils.defaultString(templateShareYn, false)}
                  size="sm"
                  onstyle="primary"
                  offstyle="dark"
                  onlabel="Yes"
                  offlabel="No"
                  onChange={btnTemplateShare}
                />
              </div>
            </Form.Group>
          </UmodalTemplate>
        </Form>
      </Modal.Body>
      <Modal.Footer
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Modal.Footer.ProgressButton
          onClick={saveData}
          variant="primary"
          doing={isSaving}
          doingText={"Saving..."}
        >
          Save Program
        </Modal.Footer.ProgressButton>
      </Modal.Footer>
    </Modal>
  );
}

export default withProgramSavePopup(SavePopup);
