import {
  DaafModal,
  DaafModalTemplate,
  DaafPopup,
} from "@alpha/com.bizentro.daaf.front.framework";
import { Checkbox } from "@mui/material";
import { Enums } from "components/builder/BuilderEnum";
import { stopEvent } from "components/builder/ui/editor/handler/UIEditorEventHandler";
import WijmoGrid from "components/common/element/WijmoGrid";
import ArrayUtils from "components/common/utils/ArrayUtils";
import JsonUtils from "components/common/utils/JsonUtils";
import ObjectUtils from "components/common/utils/ObjectUtils";
import React from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import { Accordion, Col, Row } from "react-bootstrap";
import WorkflowService from "services/workflow/WorkflowService";

function GlobalWorkflowPopup({ ...props }) {
  const [globalWorkflowList, setGlobalWorkflowList] = useState([]);
  const [selectedWorkflow, setSelectedWorkflow] = useState({});
  const [returnObjectEntityList, setReturnObjectEntityList] = useState([]);
  const globalWorkflowListRef = useRef([]);

  useEffect(() => {
    getGlobalServiceList();
  }, []);

  const getGlobalServiceList = () => {
    WorkflowService.getServiceList(
      { serviceType: "G", appId: props.appId },
      (res) => {
        globalWorkflowListRef.current = res.data;
        setGlobalWorkflowList([...res.data]);
        if (!ArrayUtils.isEmpty(res.data)) {
          onRowClick(res.data[0]);
        }
      }
    );
  };

  const onRowClick = (data) => {
    setSelectedWorkflow(data);
    if (!ObjectUtils.isEmpty(data)) {
      WorkflowService.getService(data, (res) => {
        const entityList = getReturnObjectFromService(res.data);

        //기본값은 전부 체크되어있는 걸로
        setReturnObjectEntityList(entityList);
      });
    }
  };

  /**
   * 서비스에서 리턴오브젝트를 가져오는 로직
   * @param {*} serviceObj
   * @returns
   */
  const getReturnObjectFromService = (serviceObj) => {
    const service = JSON.parse(serviceObj.serviceContent);
    const endProcess = service.service.child.process?.find(
      (p) => p.processType === Enums.WorkflowProcessType.END_PROCESS
    );
    let wfRetObject = [];
    if (service && endProcess.propertyValue.returnObject?.length > 0) {
      wfRetObject.push(...endProcess.propertyValue.returnObject);
    }
    wfRetObject.forEach((entity) => {
      let entityFieldList = entity.entityFieldList || [];
      if (entity.processType === Enums.WorkflowProcessType.ENTITY_DEFINITION) {
        const process = JsonUtils.findNode(
          service,
          "compId",
          entity.definitionCompId
        );
        if (!ObjectUtils.isEmpty(process)) {
          entityFieldList = process.propertyValue.entityFieldList;
        }
      }
      entity.entityFieldList = entityFieldList;
    });

    return wfRetObject;
  };

  const onClickConfirm = () => {
    if (props.callback) {
      props.callback(returnObjectEntityList);
    }
  };

  const columns = [
    {
      field: "serviceId",
      headerName: "Service ID",
      width: "*",
    },
    {
      field: "serviceNm",
      headerName: "Service Name",
    },
  ];

  return (
    <DaafModal>
      <DaafModal.Header title="Select Global Workflow" />
      <DaafModal.Body>
        <DaafModalTemplate>
          <Row>
            <Col xs={6}>
              <WijmoGrid
                columns={columns}
                rows={globalWorkflowList}
                style={{ height: "500px" }}
                headersVisibility={"Column"}
                onRowClick={onRowClick}
              />
            </Col>
            <Col xs={6}>
              <div
                style={{
                  border: "1px solid lightgray",
                  height: "500px",
                  width: "100%",
                  borderRadius: "3px",
                  overflow: "auto",
                }}
              >
                <div
                  style={{
                    height: "38px",
                    borderBottom: "1px solid lightgray",
                    paddingLeft: "15px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {ObjectUtils.isEmpty(selectedWorkflow)
                    ? "Choose Global Service"
                    : `${selectedWorkflow?.serviceName} (${selectedWorkflow?.serviceId})`}
                </div>
                <Accordion alwaysOpen>
                  {returnObjectEntityList.map((entity, index) => {
                    return (
                      <Accordion.Item
                        key={entity.entityVariable}
                        eventKey={entity.entityVariable}
                      >
                        <Accordion.Header>
                          {`  ${entity.entityVariable} ${
                            entity.entityNm ? `( ${entity.entityNm} )` : ""
                          }`}
                        </Accordion.Header>
                        <Accordion.Body>
                          {entity.entityFieldList.map((field) => {
                            return (
                              <div key={field.fieldId}>
                                {`${field.fieldId} ${
                                  field.fieldNm ? `( ${field.fieldNm} )` : ""
                                }`}
                              </div>
                            );
                          })}
                        </Accordion.Body>
                      </Accordion.Item>
                    );
                  })}
                </Accordion>
              </div>
            </Col>
          </Row>
        </DaafModalTemplate>
      </DaafModal.Body>
      <DaafModal.Footer>
        <DaafModal.Footer.Button onClick={onClickConfirm}>
          Add Variable{" "}
        </DaafModal.Footer.Button>
      </DaafModal.Footer>
    </DaafModal>
  );
}

export default GlobalWorkflowPopup;
