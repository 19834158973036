import { PropertiesHeader } from "components/builder/ui/uiComponents/UIComponentStyle";
import { debounce } from "components/common/utils/InputUtils";
import React, { Component, useCallback, useEffect, useState } from "react";
import { Accordion, Button, Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import CodeService from "services/common/CodeService";
import { Scrollbar } from "smooth-scrollbar-react";
import EntityReduxHelper from "components/builder/entity/editor/helper/EntityReduxHelper";
import {
  PropertyLable,
  PropertyValue,
} from "components/builder/entity/entityComponents/EntityComponentStyle";

class DataModel extends Component {
  constructor(props) {
    super(props);
    //불필요하면 constructor 지우세요.
  }
}
class DataModelEditor extends Component {
  constructor(props) {
    super(props);
    //불필요하면 constructor 지우세요.
  }
  render() {
    return <React.Fragment>{this.props.children}</React.Fragment>;
  }
}

// class DataModelProperties extends Component {
//   constructor(props) {
//     super(props);
//     //불필요하면 constructor 지우세요.
//   }
//   render() {
//     return <div>Entity Properties</div>;
//   }
// }

const DataModelProperties = (props) => {
  /*
    dataModelNm
    moduleCd - 모듈 코드
    description  - 설명
    dataModelType - 모델 구분
    apiUrl - api 주소
    entity - 엔티티 명
    useYn  사용 여부
  */
  const [dataModelNm, setDataModelNm] = useState("");
  const [moduleCd, setModuleCd] = useState("");
  const [description, setDescription] = useState("");
  const [dataModelType, setDataModelType] = useState("");
  const [apiUrl, setApiUrl] = useState("");
  const [entity, setEntity] = useState("");
  const [useYn, setUseYn] = useState("Y");

  const [dataModelTypeList, setDataModelTypeList] = useState([]);

  const output = useSelector((state) => state.outputENT.output);
  const dispatch = useDispatch();

  useEffect(() => {
    if (output) {
      setDataModelNm(output.dataModelNm || "");
      setModuleCd(output.moduleCd || "");
      setDescription(output.description || "");
      setDataModelType(output.dataModelType || "");
      setApiUrl(output.apiUrl || "");
      setEntity(output.entity || "");
      setUseYn(output.useYn || "");
    }
  }, [output]);

  useEffect(() => {
    let comboParams = { codeMstCd: "Z0014" };

    CodeService.getCodeCombo(comboParams, (res) => {
      setDataModelTypeList(res.data);
    });
  }, []);

  // const { dataModelEntities, ...entity } = output;
  // //actived component update

  const debounceType = debounce((id, value) => {
    EntityReduxHelper.updateDataModelProperty(dispatch, output, { id, value });
    EntityReduxHelper.activateComponentProperty(dispatch, { id, value });
  }, 200);

  const onChangePropertValue = (e) => {
    debounceType(e.target.id, e.target.value);
  };

  return (
    <>
      <Scrollbar
        className="custom-class"
        plugins={{
          overscroll: {
            effect: "glow",
          },
        }}
      >
        <PropertiesHeader>Data Model</PropertiesHeader>
        <Accordion defaultActiveKey={[0]} alwaysOpen>
          <Accordion.Item key={0} eventKey={0}>
            <Accordion.Header>Data Model Definition</Accordion.Header>
            <Accordion.Body>
              <PropertyLable>Data Model Name</PropertyLable>
              <PropertyValue>
                <input
                  className="form-control form-control-sm"
                  id="dataModelNm"
                  value={dataModelNm}
                  onChange={(e) => {
                    onChangePropertValue(e);
                    setDataModelNm(e.currentTarget.value);
                  }}
                />
              </PropertyValue>
              <PropertyLable>Model Type</PropertyLable>
              <PropertyValue>
                <Form.Select
                  id="dataModelType"
                  value={dataModelType}
                  size="sm"
                  disabled
                >
                  {dataModelTypeList.map((type) => (
                    <option key={type.id} value={type.id}>
                      {type.text}
                    </option>
                  ))}
                </Form.Select>
              </PropertyValue>
              <PropertyLable>API Url</PropertyLable>
              <PropertyValue>
                <input
                  className="form-control form-control-sm"
                  id="apiUrl"
                  value={apiUrl}
                  onChange={(e) => {
                    onChangePropertValue(e);
                    setApiUrl(e.currentTarget.value);
                  }}
                />
              </PropertyValue>

              <PropertyLable>Description</PropertyLable>
              <PropertyValue>
                <Form.Control
                  as={"textarea"}
                  value={description}
                  id="description"
                  style={{ minHeight: "150px" }}
                  onChange={(e) => {
                    setDescription(e.currentTarget.value);
                    onChangePropertValue(e);
                  }}
                />
              </PropertyValue>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Scrollbar>
    </>
  );
};

export default Object.assign(DataModel, {
  Editor: DataModelEditor,
  Properties: DataModelProperties,
});
